<template>
  <div class="sub-account-wrap" v-if="hasSubAccounts">
    <div class="button">
      <button @click="addAccountShow" v-if="hasCreateSubAccount">Add</button>
    </div>
    <a-table
      :columns="columns"
      :data-source="subAccountLists"
      bordered
      :rowKey="
        (record, index) => {
          return index;
        }
      "
      :pagination="false"
      :loading="isLoading"
    >
      <template slot="name" slot-scope="text, record">
        <span>{{ record.truename }}</span>
      </template>
      <template slot="username" slot-scope="text, record">
        <span>{{ record.uname }}</span>
      </template>
      <template slot="createtime" slot-scope="text, record">
        <span>{{ record.createtime }}</span>
      </template>
      <template slot="action" slot-scope="text, record">
        <img
          src="@/assets/edit.svg"
          alt
          style="margin-right: 10px"
          @click="editPopupShow(record)"
          v-if="hasModifySubAccountPassword"
        />
        <img
          src="@/assets/delete.png"
          alt
          @click="deletePopupShow(record)"
          v-if="hasDeleteSubAccount"
        />
      </template>
    </a-table>
    <!-- 新增一个账户 -->
    <Modal
      :isVisible="isVisibleShow"
      title="Add An Account"
      @close-popup="hideClosePopup"
      width="394px"
      submitText="Confirm"
      @submit="addAccountSubmit"
    >
      <template #content>
        <div class="item">
          <span>Role Type</span>
          <a-select
            @change="(val) => (addAccountObj.role = val)"
            :defaultValue="currentAct + 1"
          >
            <a-select-option :value="1">Operator</a-select-option>
            <a-select-option :value="2">Business Personnel</a-select-option>
            <a-select-option :value="3">Bookkeeper</a-select-option>
          </a-select>
        </div>
        <div class="item">
          <span>Name</span>
          <input
            type="text"
            placeholder="Person's real name"
            v-model="addAccountObj.name"
          />
        </div>
        <div class="item">
          <span>Username</span>
          <input
            type="text"
            placeholder="Username required for login"
            v-model="addAccountObj.username"
          />
        </div>
        <div class="item">
          <span>Password</span>
          <input
            type="text"
            placeholder="Password required for login"
            v-model="addAccountObj.password"
          />
        </div>
        <div class="item">
          <span>Confirm Password</span>
          <input
            type="text"
            placeholder="Confirm password"
            v-model="addAccountObj.confirmPassword"
          />
        </div>
      </template>
    </Modal>
    <!-- 编辑 -->
    <Modal
      :isVisible="isEditShow"
      title="Edit A Account"
      @close-popup="hideEditClosePopup"
      width="394px"
      submitText="Confirm"
      @submit="editAccountSubmit"
    >
      <template #content>
        <div class="item">
          <span>Password</span>
          <input
            type="text"
            placeholder="Password required for login"
            v-model="editObj.password"
          />
        </div>
        <div class="item">
          <span>Confirm Password</span>
          <input
            type="text"
            placeholder="Confirm password"
            v-model="editObj.confirmPassword"
          />
        </div>
      </template>
    </Modal>
    <!-- 删除 -->
    <Modal
      :isVisible="isDeleteShow"
      title
      @close-popup="hideDeleteClosePopup"
      width="394px"
      submitText="Confirm"
      @submit="deleteAccountSubmit"
    >
      <template #content>
        <p>Are you sure to delete?</p>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import axios from "@/utils/request";
import inspectUserPermissions from "@/utils/permissionsJudge";
const columns = [
  {
    title: "Name",
    dataIndex: "Name",
    key: "Name",
    scopedSlots: { customRender: "name" },
    align: "center",
  },
  {
    title: "Username",
    dataIndex: "Username",
    key: "Username",
    scopedSlots: { customRender: "username" },
    align: "center",
  },
  {
    title: "Created Time",
    dataIndex: "Createtime",
    key: "Createtime",
    scopedSlots: { customRender: "createtime" },
    align: "center",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    scopedSlots: { customRender: "action" },
    align: "center",
  },
];
export default {
  name: "sub-acount",
  props: {
    currentAct: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      columns,
      isLoading: false,
      isVisibleShow: false,
      addAccountObj: {
        name: "",
        username: "",
        password: "",
        confirmPassword: "",
        role: null,
      },
      isEditShow: false,
      editObj: {
        password: "",
        confirmPassword: "",
      },
      isDeleteShow: false,
      deleteId: "",
      editPasswordId: "",
      subAccountLists: [],
      // 是否有创建子账户的权限
      hasCreateSubAccount: true,
      // 是否有获取子账户信息的权限
      hasSubAccounts: true,
      // 是否有删除子账户的权限
      hasDeleteSubAccount: true,
      // 是否有修改子账户密码的权限
      hasModifySubAccountPassword: true,
    };
  },
  watch: {
    currentAct: {
      handler: function(newVal, oldVal) {
        console.log(newVal);
        if (newVal != oldVal) {
          this._getSubAccountData();
        }
      },
    },
  },
  created() {
    this._getSubAccountData();
  },
  mounted() {
    let authorityJudgment = this.$store.state.authorityJudgment;
    this.hasCreateSubAccount = inspectUserPermissions(
      "user/createSubAccount",
      authorityJudgment
    );
    this.hasSubAccounts = inspectUserPermissions(
      "user/subAccounts",
      authorityJudgment
    );
    this.hasDeleteSubAccount = inspectUserPermissions(
      "user/deleteSubAccount",
      authorityJudgment
    );
    this.hasModifySubAccountPassword = inspectUserPermissions(
      "user/modifySubAccountPassword",
      authorityJudgment
    );
  },
  methods: {
    addAccountShow() {
      this.isVisibleShow = true;
    },
    hideClosePopup() {
      this.isVisibleShow = false;
    },
    addAccountSubmit() {
      if (this.addAccountObj.password != this.addAccountObj.confirmPassword) {
        this.$message.error("Inconsistent passwords!!!");
        return;
      }
      axios
        .post("/api_admin.php?a=user/createSubAccount", {
          truename: this.addAccountObj.name,
          uname: this.addAccountObj.username,
          password: this.addAccountObj.password,
          role: this.addAccountObj.role || this.currentAct + 1, // Operator = 1 Business Personnel	 = 2 Bookkeeper = 3
          confirmPassword: this.addAccountObj.confirmPassword,
        })
        .then((res) => {
          if (res.code == 0) {
            this._getSubAccountData();
            this.addAccountObj.name = "";
            this.addAccountObj.username = "";
            this.addAccountObj.password = "";
            this.addAccountObj.confirmPassword = "";
            this.addAccountObj.role = null;
            this.isVisibleShow = false;
          } else {
            this.$message.error(res.msg || "error");
          }
        });
    },
    hideEditClosePopup() {
      this.isEditShow = false;
    },
    editPopupShow(record) {
      this.editPasswordId = record.uid;
      this.isEditShow = true;
    },
    editAccountSubmit() {
      let passwordReg = /^(?![^a-zA-Z]+$)(?!\D+$)/;
      if (this.editObj.password != this.editObj.confirmPassword) {
        this.$message.error("Inconsistent passwords!!!");
        return;
      }

      if (!passwordReg.test(this.editObj.password)) {
        this.$message.error("Please enter the correct password");
        return;
      }

      if (this.editObj.password.length < 8) {
        this.$message.error("The password must be at least 8 digits long");
        return;
      }

      // axios.post('/api_admin.php?a=user/checkRegUser', {
      //   email: this.editObj.password,
      //   uname: 'dtcdtc@163.com'
      // }).then(res => {
      //   if(res.code === 0) {

      //   }else {
      //     this.$message.error(res.msg || 'Sorry, server is busy. please try later.')
      //   }
      // })

      axios
        .post("/api_admin.php?a=user/modifySubAccountPassword", {
          id: this.editPasswordId,
          password: this.editObj.password,
          repassword: this.editObj.confirmPassword,
        })
        .then((res) => {
          if (res.code == 0) {
            this._getSubAccountData();
            this.editObj.password = "";
            this.editObj.confirmPassword = "";
            this.isEditShow = false;
          }
        });
    },
    deletePopupShow(record) {
      this.deleteId = record.uid;
      this.isDeleteShow = true;
    },
    hideDeleteClosePopup() {
      this.isDeleteShow = false;
    },
    deleteAccountSubmit() {
      axios
        .post("/api_admin.php?a=user/deleteSubAccount", {
          id: this.deleteId,
        })
        .then((res) => {
          if (res.code == 0) {
            this._getSubAccountData();
            this.isDeleteShow = false;
          } else {
            this.$message.error(res.msg || "error");
          }
        });
    },
    _getSubAccountData() {
      this.isLoading = true;
      axios
        .get("/api_admin.php?a=user/subAccounts", {
          params: {
            role: this.currentAct + 1,
          },
        })
        .then((res) => {
          if (res.code == 0) {
            this.isLoading = false;
            this.subAccountLists = res.data;
          } else {
            this.isLoading = false;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.sub-account-wrap {
  margin-top: 22px;
  .button {
    text-align: right;
    button {
      width: 92px;
      height: 2.8571rem;
      background: #2898ff;
      border-radius: 6px;
      font-size: 1.1429rem;
      color: #ffffff;
      line-height: 20px;
      border: none;
      cursor: pointer;
      margin-bottom: 16px;
      &:hover {
        background-color: #52b1ff;
      }
    }
  }
  img {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
  .item {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 32px;
    }
    span {
      font-size: 1.1429rem;
      font-family: "dtc-bold";
      color: #333333;
      line-height: 30px;
    }
    input {
      width: 100%;
      height: 32px;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #e6e6e6;
      margin-top: 6px;
      padding-left: 10px;
      outline: none;
    }
    .ant-select {
      width: 100%;
      margin-top: 6px;
      /deep/ .ant-select-selection {
        border-color: #e6e6e6;
        &.ant-select-open {
          box-shadow: 0 0 0 2px rgb(223 211 207 / 20%);
        }
      }
    }
  }
}
/deep/ .ant-table-column-title {
  color: #666;
}
</style>
