<template>
  <div class="modal" v-if="isVisible">
    <div class="mask"></div>
    <div class="modal-content" :style="{ width, height }">
      <div class="title" v-if="showTitle">
        <h2>{{ title }}</h2>
      </div>
      <img
        src="@/assets/close.svg"
        alt
        @click.stop="close('x')"
        class="close-modal"
        v-if="closeAble"
      />
      <a-spin :spinning="loading">
        <div class="items modal-content-items">
          <slot name="content"></slot>
        </div>
        <div v-if="showBtn" :class="`${btnAlign} btn-submit-close`">
          <a-button
            class="btn-cancel"
            type="link"
            @click.stop="close('cancel')"
            v-if="isShowCancel"
            >{{ cancelText }}</a-button
          >
          <a-button
            class="btn-ok"
            type="primary"
            @click="submit"
            :disabled="disabled"
            v-if="isShowSubmit"
            >{{ submitText }}</a-button
          >
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      // required: true
    },
    submitText: {
      type: String,
    },
    cancelText: {
      type: String,
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    showBtn: {
      type: Boolean,
      default: true,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    isShowCancel: {
      type: Boolean,
      default: false,
    },
    isShowSubmit: {
      type: Boolean,
      default: true,
    },
    btnAlign: {
      type: String,
      default: "center",
    },
    closeAble: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      hash: new Date().getTime() + "_" + Math.random() + "_" + Math.random(),
    };
  },
  methods: {
    close(type) {
      this.$emit("close-popup", type);
    },
    submit() {
      this.$emit("submit");
    },
  },
  watch: {
    isVisible: {
      handler: function(newVal, oldVal) {
        if (newVal === true) {
          if (!this.$root.displayModals.includes(this.hash)) {
            this.$root.displayModals.push(this.hash);
          }
        } else {
          if (this.$root.displayModals.includes(this.hash)) {
            this.$root.displayModals.splice(
              this.$root.displayModals.indexOf(this.hash),
              1
            );
          }
        }
        // 防止windows下页面会哆嗦一下
        if (this.$root.displayModals.length > 0) {
          document.body.style.position = "relative";
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.position = "";
          document.body.style.overflow = "";
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.modal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1010;
  display: flex;
  justify-content: center;
  align-items: center;

  .mask {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }

  > .modal-content {
    width: 810px;
    overflow-y: auto;
    overflow-x: hidden;
    background: #ffffff;
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.09);
    border-radius: 12px;
    position: relative;
    // top: 5vh;
    // left: 50%;
    // transform: translate(-50%, -50%);
    padding: 15px 20px 20px;

    /deep/ .ant-spin-container {
      min-height: auto !important;
    }

    > .title {
      // display: flex;
      // justify-content: flex-start;
      // align-items: center;
      position: relative;
      // top: -9px;
      height: 45px;

      h2 {
        font-size: 1.2857rem;
        font-family: "dtc-bold";
        color: #333333;
      }
    }

    > .close-modal {
      cursor: pointer;
      transition: transform 0.3s;
      position: absolute;
      width: 24px;
      box-sizing: border-box;
      height: 24px;
      top: 9px;
      right: 9px;
      z-index: 102;
      padding: 5px;

      // background-color: #00000011;
      // border-radius: 99px;
      &:hover {
        transform: scale(1.2);
      }
    }

    .modal-content-items {
      max-height: 70vh;
      overflow-y: auto;
      width: calc(100% + 15px);
      padding: 0px;
      margin: 0;
      box-sizing: border-box;
      padding-right: 15px;
      height: fit-content;
      padding-bottom: 1px;

      &::-webkit-scrollbar {
        position: relative;
        width: 10px;
        background-color: #f8f8f8;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: #ddd;
        border-radius: 10px;
      }
    }

    /deep/ .btn-submit-close {
      margin-top: 1.5714rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;

      &.right {
        justify-content: flex-end;
      }

      &.left {
        justify-content: flex-start;
      }

      .btn-ok {
        height: 2.5714rem;
        background: #3762d6;
        border-radius: 4px;
        font-size: 1.1429rem;
        color: #ffffff;
        line-height: 1.4286rem;
        cursor: pointer;
        border: none;
        min-width: 7.1429rem;
        &:hover {
          opacity: 0.85;
        }
        &:disabled,
        &[disabled] {
          color: rgba(0, 0, 0, 0.25);
          background-color: #f5f5f5;
          border-color: #d9d9d9;
          text-shadow: none;
          box-shadow: none;
          cursor: not-allowed;
        }
      }

      .btn-cancel {
        font-size: 1rem;
        color: #3762d6;
        margin-right: 1.4286rem;
        border: none;
        &:hover {
          opacity: 0.8;
          background-color: none;
        }
      }
    }
  }
}
</style>
