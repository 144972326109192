
<template>
    <a-spin class="content-block" :class="{ empty: false }" :spinning="isLoading">
        <div class="content-block-title font-bold">TOP 10 Referring URLs</div>
        <div class="toggle">
            <div
                :class="{ active: timeToggle == 'week' }"
                @click="timeToggle = 'week'; getData()"
            >Week</div>
            <div
                :class="{ active: timeToggle == 'month' }"
                @click="timeToggle = 'month'; getData()"
            >Month</div>
        </div>
        <div class="table">
            <div class="thead font-bold">
                <div class="td" style="width:850px">URL</div>
                <div class="td" style="width:150px">
                    <SortIcon :sort="sortClick" @change="val => toggleSort('click', val)">Clicks</SortIcon>
                </div>
                <div class="td" style="width:156px">
                    <SortIcon :sort="sortComm" @change="val => toggleSort('comm', val)">Commissions</SortIcon>
                </div>
            </div>
            <div class="tbody" style="min-height:210px">
                <a-empty v-if="list.length == 0 && !isLoading" />
                <div class="tr" v-for="item in list" :key="item.key" v-else-if="!isLoading">
                    <div class="td" style="width:850px">{{ item.url }}</div>
                    <div class="td" style="width:150px">{{ item.clicks | toThousands(true) }}</div>
                    <div class="td" style="width:156px">{{ item.aff_cmsn | toThousands }}</div>
                </div>
            </div>
        </div>
    </a-spin>
</template>

<script>
import axios from "@/utils/request";
import SortIcon from '../../../components/SortIcon'
export default {
    components: { SortIcon },
    props: [
        'medium'
    ],
    watch: {
        medium: {
            immediate: true,
            handler: function (newV, oldV) {
                if (newV && newV.apply_status == 'adopt') {
                    this.isApplied = true
                    this.getData()
                }
            }
        }
    },
    data() {
        return {
            list: [],
            sortClick: 'desc',
            sortComm: null,
            timeToggle: 'week',
            isLoading: false,
            isApplied: false,

        }
    },
    methods: {
        toggleSort(type, val) {
            if (val == 'desc' && type == 'comm') {
                this.sortComm = 'desc'
                this.sortClick = null
            } else {
                this.sortComm = null
                this.sortClick = 'desc'
            }
            this.getData()
        },
        getData() {
            if (!this.isApplied) {
                return;
            }
            this.isLoading = true;
            axios.get("/api_admin.php?a=report/call", {
                params: {
                    report_name: "dtcForTopReferUrls",
                    site_id: this.medium.site_id,
                    start_date: moment().add(-1, this.timeToggle).format('MM/DD/YYYY'),
                    end_date: moment().format('MM/DD/YYYY'),
                    sort_field: this.sortClick ? 'clicks' : 'aff_cmsn'
                },
            }).then((res) => {
                this.isLoading = false;
                if (res.code != 0) {
                    return;
                }
                this.list = res.data.data.map(item => ({
                    ...item,
                    url: item.url.replace('https://www.linkdtc.com/', 'https://app.partnerboost.com/').replace('index.php?mod=lhdeal&op=index&', 'track?'),
                    key: Math.random() + '_' + Math.random()
                }))
            }).catch(e => {
                console.log(e)
                this.isLoading = false;
            });

        }
    }
}
</script>

<style lang="less" scoped>
.toggle {
    width: 167px;
    height: 32px;
    border-radius: 6px;
    float: left;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    left: 0;
    top: 65px;
    z-index: 1;
    div {
        width: 50px;
        height: 32px;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &.active {
            width: 80px;
            cursor: default;
            background: #ffffff;
            box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
        }
    }
}
</style>