<template>
  <div class="campaign-steps-node__shape">
    <div v-if="nodeStatus === 1" class="campaign-steps-node__future"></div>
    <div v-else-if="nodeStatus === 2" class="campaign-steps-node__current">
      <div></div>
    </div>
    <div v-else-if="nodeStatus === 3" class="campaign-steps-node__done">
      <a-icon
        type="check-circle"
        class="campaign-steps-node__doneicon"
        theme="filled"
      />
    </div>
  </div>
</template>

<script>
const defaultLogo = require("@/assets/default.png");
import moment from "moment-timezone";
import axios from "@/utils/request";
// import Modal from "../../../components/Modal.vue";

export default {
  components: {},
  props: {
    nodeStatus: {
      type: Number,
      default: 1,
    },
  },
  computed: {},
  data() {
    return {
      countryMap: {},
      statByLocal: "today",
      errorPlaceHolder: null,
      isShowUpdate: false,
      newStatus: "",
      isLoadingModal: false,
    };
  },
  watch: {},
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.campaign-steps {
  display: flex;
  align-items: center;
  margin-top: 68px;
  &-node {
    position: relative;
    &__text {
      font-size: 1.1429rem;
      position: absolute;
      width: 80px;
      top: -28px;
      left: -16px;
      color: #999999;
      &.current {
        color: #999999;
      }
      &.done {
        color: #05c15f;
      }
    }
    &__current {
      width: 22px;
      height: 22px;
      background: #ddecff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      > div {
        width: 10px;
        height: 10px;
        background: #3762d6;
        border-radius: 50%;
      }
    }
    &__done {
      > i {
        color: #3762d6;
        font-size: 24px;
      }
    }
    &__future {
      width: 22px;
      height: 22px;
      background: #ddecff;
      border-radius: 50%;
    }
  }
  &-line {
    width: 172px;
    height: 2px;
    background: #e1e2e8;
    margin-left: -1px;
    &.active {
      background: #3762d6;
    }
  }
}
</style>
