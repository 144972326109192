<template>
  <div class="error">
    <NavHeader :showLogo="false"></NavHeader>

    <div class="error-404">
      <div class="h1">Oops!</div>

      <div class="h3">We can't seem to find the page you're looking for.</div>

      <div class="tips">Shall we take you to our homepage?</div>
      <router-link to="/"> <div class="link">Yes, please.</div> </router-link>
    </div>
  </div>
</template>

<script>
import NavHeader from "../components/NavHeader.vue";
export default {
  name: "error-404",
  components: {
    NavHeader,
  },
  created() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.h1 {
  font-size: 58px;
  font-family: "dtc-bold";
  color: #333333;
  line-height: 2.8571rem;
  margin: 170px 0 60px 0;
}
.h3 {
  font-size: 30px;
  font-family: "dtc-bold";
  color: #333333;
  line-height: 2.8571rem;
  width: 600px;
}
.tips {
  margin: 100px 0 30px 0;
  font-size: 1.4286rem;
  color: #999999;
  line-height: 2.8571rem;
}
.link {
  text-align: center;
  width: 192px;
  height: 46px;
  background: #3762d6;
  border-radius: 6px;
  font-size: 1.4286rem;
  color: #ffffff;
  line-height: 46px;
}

.error-404 {
  width: 1200px;
  margin: 0 auto;
}
.error {
  height: 100vh;
  background: url("~@/assets/404.png") right top no-repeat;
  background-position-y: 60px;
  background-size: contain;
}
</style>
