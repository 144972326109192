<template>
  <div class="cuslink">
    <Header collection="Tools"></Header>
    <div class="container">
      <div class="headers"></div>
      <div class="content">
        <div class="comm-tables">
          <div class="comm-header">
            <div class="comm-header-left">
              <div>
                1. You can only generate links for brands you have joined
              </div>
              <div>
                2. In custom links, you may add or place brand name after
                “&new=“
              </div>
              <div>
                3. Please make sure to check brand status before promoting to
                avoid any invalid links
              </div>
              <div class="recieve-list">
                <a-select
                  placeholder="Select your channel"
                  :getPopupContainer="
                    (triggerNode) => {
                      return triggerNode.parentNode || document.body;
                    }
                  "
                  v-model="siteId"
                >
                  <a-select-option
                    v-for="(site, index) in channels"
                    :key="`site-${index}`"
                    :value="site.medium_id"
                  >
                    {{ site.medium_id }}
                  </a-select-option>
                </a-select>

                <a-button type="primary" @click="exportData">Export</a-button>
              </div>
            </div>
            <!-- <div class="comm-header-right">
              <a-input placeholder="Search for Brand Name or ID"></a-input>
            </div> -->
          </div>
          <div class="comm-list">
            <a-table
              class="comtable"
              :columns="columns"
              :data-source="dataLists"
              :rowKey="(record, index) => record.id"
              :loading="isLoading"
              :pagination="false"
            >
              <div class="comm-status" slot="status" slot-scope="text, record">
                <span
                  class="comm-status-download"
                  style="cursor: pointer;"
                  @click="downFile(record)"
                  v-if="text === 'Download'"
                  >{{ text }}</span
                >
                <span class="comm-status-preparing" v-else>{{ text }}</span>
                <!-- <span class="comm-status-download" v-else>{{ text }}</span> -->
                <!-- <span
                  class="comm-status-downloading"
                  v-show="text === 'Downloading'"
                  >{{ text }}</span
                >
                <span class="comm-status-nodata" v-show="text === 'No Data'">{{
                  text
                }}</span>
                <span class="comm-status-error" v-show="text === 'Error'">{{
                  text
                }}</span> -->
              </div>
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/public/Header.vue";
import axios from "@/utils/request";
import apis from "@/api/const";

export default {
  components: {
    Header,
  },
  data() {
    let columns = [
      {
        title: "Id",
        dataIndex: "id",
        width: 160,
        align: "center",
      },
      {
        title: "Creation time",
        dataIndex: "created_at",
        width: 300,
        align: "center",
      },
      {
        title: "Update time",
        dataIndex: "updated_at",
        width: 300,
        align: "center",
      },
      {
        title: "Download",
        dataIndex: "status_text",
        align: "center",
        scopedSlots: { customRender: "status" },
      },
    ];
    return {
      columns,
      dataLists: [],
      currentPage: 1,
      channels: [],
      isLoading: false,
      siteList: [],
      siteId: undefined,
    };
  },
  created() {
    this.getSiteList();
    this.getDataList();
  },
  mounted() {
    this.getchannels();
  },
  methods: {
    downFile(item) {
      window.location.href = item.down_url;
    },
    getchannels() {
      axios
        .get(apis.channel.index, {
          params: {},
        })
        .then((resp) => {
          if (resp.code === "0200") {
            this.channels = resp.payload.filter((e) => e.status == "1");
          }
        });
    },
    exportData() {
      if (!this.siteId) {
        this.$message.error("Please select the site");
        return;
      }
      axios
        .get(apis.tools.slef_link_export, {
          params: {
            medium_id: this.siteId,
          },
        })
        .then((res) => {
          this.isLoading = false;
          if (res.code === "0200") {
            this.$message.success("Export success");
            this.getDataList();
          } else {
            this.$message.error(res.msg || "");
          }
        });
    },
    // 获取列表
    getDataList() {
      this.isLoading = true;
      axios
        .get(apis.tools.slef_link_list, {
          params: {},
        })
        .then((res) => {
          this.isLoading = false;
          if (res.code === "0200") {
            this.dataLists = res.payload;
            // this.totalCount = parseInt(res.payload.pagination.total);
          } else {
            this.$message.error(res.msg || "");
          }
        });
    },
    // 获取列表
    getSiteList() {
      //   this.isLoading = true;
      axios
        .get(apis.tools.slef_link_site, {
          params: {},
        })
        .then((res) => {
          //   this.isLoading = false;
          if (res.code === "0200") {
            this.siteList = res.payload;
            // this.totalCount = parseInt(res.payload.pagination.total);
          } else {
            this.$message.error(res.msg || "");
          }
        });
    },
    // 切换下一页
    paginationChange(page, pageSize) {
      //   this.currentPage = page;
      //   this.getDataList();
    },
  },
  beforeDestroy() {
    if (this.onWindowResize) {
      window.onresize = null;
    }
  },
};
</script>

<style lang="less" scoped>
.cuslink {
  .headers {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 0.8571rem;
  }
  .content {
    .comm {
      &-actions {
        display: flex;
        justify-content: center;
        img {
          width: 1.1429rem;
          height: 1.1429rem;
          cursor: pointer;
          &:hover {
            opacity: 0.85;
          }
          + img {
            margin-left: 1rem;
          }
        }
      }
      &-status {
        color: #fff;
        &-download {
          background: #2ad57b;
        }
        &-preparing {
          color: #ffffff;
          background: #ffc55a;
        }
        span {
          padding: 0.2857rem 0.5714rem;
          border-radius: 4px;
          font-size: 0.8571rem;
        }
      }
      &-platforms {
        display: flex;
        justify-content: center;
        img {
          width: 1.4286rem;
          height: 1.4286rem;
          margin-right: 0.2857rem;
        }
      }
    }
    .comm-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      background: #fff;
      padding: 1.1429rem;
      border-radius: 16px 16px 0 0;
      flex-direction: column;
      .recieve-list {
        width: 600px;
        margin-top: 20px;
        > div {
          width: 360px;
          margin-right: 30px;
        }
      }
      .recieve-add {
        width: 500px;
        height: 2.8571rem;
        background: #f5f9fe;
        border-radius: 4px;
        border: 1px solid #c9cdd3;
        color: #3762d6;
      }
    }
    .comm-header-left {
      > div {
        font-size: 1rem;
        line-height: 24px;
      }
    }
    .comm-header-more {
      color: #333333;
      cursor: pointer;
      i {
        font-size: 0.7143rem;
      }
    }
    .tb-total {
      padding: 16px;
      display: flex;
      justify-content: flex-end;
      &-text {
        color: #666666;
      }
      &-money {
        color: #3762d6;
      }
    }
  }
}
/deep/ .ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 0;
}
</style>
