<template>
  <div class="editcard">
    <div class="editcard-head">
      <div>
        <span>{{ title }}</span
        ><a-button type="link" @click="goFun"> Edit </a-button>
      </div>
    </div>
    <div class="editcard-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    goFun() {
      this.$emit("parent-fun");
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.editcard {
  background: #fff;
  border-radius: 12px;
  padding: 0px 32px 24px;
  margin-top: 16px;
  &-head {
    > div {
      margin-bottom: 0;
      color: #333333;
      font-size: 1.1429rem;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
