<template>
  <div>
    <div v-show="isShowAlert">
      <a-alert v-if="$store.state.userInfo.is_check_account" banner closable>
        <p slot="message">
          In order to apply campaigns, please click
          <router-link to="/account">here</router-link>
          to fill in your basic information.
        </p>
      </a-alert>

      <a-alert
        v-if="$store.state.showNotice"
        banner
        closable
        @close="handleClose"
      >
        <div slot="message">
          Due to the Chinese New Year holiday, the withdrawal payment for
          February will be postponed until before the 27th. Thank you for your
          understanding and support!
        </div>
      </a-alert>

      <a-alert
        v-if="$store.state.userInfo.has_channel_status == 0"
        banner
        closable
      >
        <p slot="message">
          You will be able to apply to brands and campaigns once your
          <router-link to="/account/channels">channel(s)</router-link>
          have been approved.
        </p>
      </a-alert>
    </div>
    <div class="header">
      <div class="header-left">
        {{ collection }}
      </div>
      <div class="header-right">
        <img src="@/assets/home/icon-question.svg" @click="onShow" />

        <a-dropdown
          :getPopupContainer="
            (triggerNode) => {
              return triggerNode.parentNode || document.body;
            }
          "
        >
          <div class="header-right-avatar">
            <a-avatar
              :size="36"
              v-if="$store.state.userInfo.avatar"
              :src="$store.state.userInfo.avatar"
            />
            <img v-else src="@/assets/home/logo.svg" />
          </div>
          <a-menu slot="overlay">
            <a-menu-item>
              <a href="javascript:;" class="username">{{
                $store.state.userInfo.display_name ||
                  $store.state.userInfo.email
              }}</a>
            </a-menu-item>
            <a-menu-item class="logout" @click="logout">
              <a href="javascript:;"
                ><a-icon type="logout" />&nbsp;&nbsp;Logout</a
              >
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
      <a-modal
        :title="null"
        :visible="visible"
        width="420px"
        :footer="null"
        centered
        @cancel="onHide"
      >
        <p class="v-msg">
          For any problems or questions, please reach out at
          <a href="mailto:support@linkprefer.com">support@linkprefer.com</a>
        </p>
      </a-modal>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    collection: {
      type: String,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    isShowAlert() {
      if (
        this.$store.state.isActMenu.indexOf("campaign") > -1 ||
        this.$store.state.isActMenu.indexOf("brands") > -1
      ) {
        return false;
      }
      return true;
    },
  },
  mounted() {},
  methods: {
    handleClose() {
      console.log("1111");
      const now = new Date().getTime();
      sessionStorage.setItem("login-time", now);
      this.$store.commit("setShowNotice", false);
    },
    onHide() {
      this.visible = false;
    },
    onShow() {
      this.visible = true;
    },
    logout() {
      localStorage.removeItem("saveToken");
      this.$store.commit("setToken", null);
      this.$router.push("/login");
      sessionStorage.removeItem("login-time");
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  justify-content: space-between;
  height: 52px;
  align-items: center;
  position: relative;
  &-alerts {
    top: -20px;
    position: absolute;
    width: 100%;
    z-index: 9;
    > div {
      //   margin-top: 2px;
    }
  }
  &-left {
    font-size: 2rem;
    color: #333333;
    font-family: "prefer-bold";
  }
  &-right {
    display: flex;
    align-items: center;
    > img {
      width: 50px;
      height: 50px;
      margin-right: 16px;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
    &-avatar {
      height: 36px;
      width: 36px;
      cursor: pointer;
    }
  }
  /deep/ .ant-dropdown {
    li {
      padding: 5px 20px;
    }
    .logout {
      a {
        color: #3762d6;
      }
    }
  }
}
.v-msg {
  text-align: center;
  padding: 55px 50px 30px;
  font-size: 1.1429rem;
  font-family: Helvetica;
  color: #333333;
  line-height: 29px;
}
/deep/ .ant-alert-warning .ant-alert-icon {
  color: #fe7150;
}
/deep/.ant-alert-warning {
  background-color: #fff6f4;
}
/deep/.ant-alert-message {
  color: #fe7150;
}

/deep/ .ant-modal-close-x {
  width: 50px;
  height: 50px;
}
/deep/.ant-dropdown .ant-dropdown-menu {
  width: 10.4286rem;
  min-height: 10.4286rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  li {
    padding: 16px 20px;
  }
  .ant-dropdown-menu-item-active {
    background-color: #f6f9ff;
  }
}
.username {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
