var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"campgrid",style:({ opacity: _vm.item.show_status === 'ended' ? '0.6' : 'unset' })},[_c('div',{staticClass:"campgrid-icon"},[_c('img',{attrs:{"src":_vm.item.cover_image}}),_c('span',{staticClass:"campgrid-icon-tips",class:{
        avaliable: _vm.formatStatusText(_vm.item.show_status),
        expired: _vm.item.show_status === 'expired',
        pending: _vm.item.show_status === 'pending',
        declined: _vm.item.show_status === 'declined',
        approved: _vm.item.show_status === 'approved',
        cancelled: _vm.item.show_status === 'cancelled',
        ended: _vm.item.show_status === 'ended',
      }},[_vm._v(_vm._s(_vm.item.show_status_text))])]),_c('ul',{staticClass:"campgrid-detail"},[_c('li',[_c('span',{staticClass:"detail-title _ellipsis"},[_vm._v("Campaign Name")]),_c('a-tooltip',{attrs:{"placement":"top"}},[(_vm.item.campaign_name.length > 24)?_c('template',{slot:"title"},[_c('span',[_vm._v(_vm._s(_vm.item.campaign_name))])]):_vm._e(),_c('span',{staticClass:"detail-text"},[_vm._v(_vm._s(_vm.item.campaign_name))])],2)],1),_c('li',[_c('span',{staticClass:"detail-title _ellipsis",staticStyle:{"width":"40%"}},[_vm._v("Payout Type")]),_c('TypeItem',{attrs:{"item":_vm.item}})],1),_c('li',[_c('span',{staticClass:"detail-title"},[_vm._v("Platform")]),_c('span',{staticClass:"detail-platform"},_vm._l((_vm.item.plat_list),function(item,index){return _c('img',{key:("plat-list-" + index),attrs:{"src":item.icon}})}),0)]),_c('li',[_c('span',{staticClass:"detail-title"},[_vm._v("Task Format")]),_c('span',{staticClass:"detail-format"},_vm._l((_vm.item.format_list),function(fot,index){return _c('a-tooltip',{key:("format-list-" + index),attrs:{"title":fot.full_name}},[_c('img',{attrs:{"src":fot.icon}})])}),1)]),_c('li',[_c('span',{staticClass:"detail-title"},[_vm._v("Incentive")]),_c('span',{staticClass:"detail-money"},[_vm._v(_vm._s(_vm.item.currency_sign)+_vm._s(_vm.item.incentives))])])]),_c('div',{staticClass:"campgrid-action"},[_c('a-button',{staticClass:"active-btn",attrs:{"type":"primary"},on:{"click":_vm.gotoDetail}},[_vm._v(" Apply Now ")]),_c('a-button',{staticClass:"negative-btn",on:{"click":_vm.gotoDetail}},[_vm._v(" View Details ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }