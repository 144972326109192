var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{staticClass:"content-block",class:{ empty: false },attrs:{"spinning":_vm.isLoading}},[_c('div',{staticClass:"content-block-title font-bold"},[_vm._v("Activicy Charts")]),_c('div',{staticClass:"left-nav font-bold"},[_c('div',{class:{ active: _vm.navToggle == 'clicks' },on:{"click":function($event){return _vm.toggleNav(0)}}},[_vm._v(" Clicks ")]),_c('div',{class:{ active: _vm.navToggle == 'commissions' },on:{"click":function($event){return _vm.toggleNav(1)}}},[_vm._v(" Commissions ")]),_c('div',{class:{ active: _vm.navToggle == 'gross' },on:{"click":function($event){return _vm.toggleNav(2)}}},[_vm._v(" Gross/Net Sales ")]),_c('div',{class:{ active: _vm.navToggle == 'number' },on:{"click":function($event){return _vm.toggleNav(3)}}},[_vm._v(" Number of Orders ")]),_c('div',{class:{ active: _vm.navToggle == 'voids' },on:{"click":function($event){return _vm.toggleNav(4)}}},[_vm._v(" Voids ")])]),_c('div',{staticClass:"right-canvas"},[(!_vm.isApplied)?_c('a-empty'):[_c('div',{style:({
          marginTop: _vm.rightPos + 'px',
        })},[_c('canvas',{ref:"canvas",attrs:{"id":"chart-clicks","width":"962","height":"291"}})]),_c('div',[_c('canvas',{attrs:{"id":"chart-comm","width":"962","height":"291"}})]),_c('div',[_c('div',{staticClass:"legend"},[_c('div',[_vm._v("Gross Sales")]),_c('div',[_vm._v("Net Sales")])]),_c('canvas',{attrs:{"id":"chart-sales","width":"962","height":"291"}})]),_c('div',[_c('canvas',{attrs:{"id":"chart-orders","width":"962","height":"291"}})]),_c('div',[_c('canvas',{attrs:{"id":"chart-voids","width":"962","height":"291"}})])]],2),_c('div',{staticClass:"toggle"},[_c('div',{class:{ active: _vm.timeToggle == 'week' },on:{"click":function($event){_vm.timeToggle = 'week';
        _vm.getData();}}},[_vm._v(" Week ")]),_c('div',{class:{ active: _vm.timeToggle == 'month' },on:{"click":function($event){_vm.timeToggle = 'month';
        _vm.getData();}}},[_vm._v(" Month ")])]),_c('div',{staticClass:"chart-tips",style:({
      left: _vm.chartTip.left,
      top: _vm.chartTip.top,
      display: _vm.chartTip.display,
    })},[_c('span',[_vm._v(_vm._s(_vm.chartTip.title))]),_c('span',{class:{ 'color-blue-light': _vm.chartTip.value2 !== null }},[_vm._v(_vm._s(_vm.chartTip.value))]),(_vm.chartTip.value2 !== null)?_c('span',{staticClass:"color-red-light"},[_vm._v(_vm._s(_vm.chartTip.value2))]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }