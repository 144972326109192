<template>
  <div class="settliment-wrap">
    <Brands collection="My Account" subset="Balance Details"></Brands>
    <div class="header-search">
      <span class="rage">Date Range</span>
      <div class="date-search">
        <a-range-picker @change="onChangeDate" class="range-date" format="L" />
        <div class="btn">
          <div class="search" @click="search">Search</div>
          <div class="reset" @click="reset">Reset</div>
        </div>
      </div>
    </div>
    <div class="toggle-content">
      <div class="toggle">
        <button
          v-for="(item, index) in btnLists"
          :key="index"
          :class="index === isToggle ? 'active' : ''"
          @click="btnChange(index)"
        >
          {{ item.name }}
        </button>
      </div>
      <SettlementOrder
        :time_end="time_end"
        :time_start="time_start"
        v-show="isToggle === 0"
        ref="order"
      />
      <DepositHistory
        v-show="isToggle === 1"
        :time_end="time_end"
        :time_start="time_start"
        ref="history"
        :warning="isWarningFirstDeposit"
        @makeFirstDeposit="makeFirstDeposit"
      />
      <Bill
        v-show="isToggle === 2"
        :time_end="time_end"
        :time_start="time_start"
        ref="balance"
      />
    </div>
    <div
      class="charge-card"
      v-show="isToggle == 1"
      v-if="hasDepositCreate && isWarningFirstDeposit == 'NO'"
    >
      <MakeDeposit ref="makeDeposit"></MakeDeposit>
    </div>
    <div class="payment-success-wrap" v-if="isSuccessWrap">
      <div class="mask"></div>
      <div class="content-wrap">
        <a-icon type="loading" class="loading" />
      </div>
    </div>
    <Loading v-if="isLoading"></Loading>
  </div>
</template>

<script>
import Brands from "./../../components/Brands.vue";
import SettlementOrder from "./components/SettlementOrder.vue";
import DepositHistory from "./components/DepositHistory.vue";
import MakeDeposit from "./components/MakeDeposit.vue";
import Bill from "./components/Bill.vue";
import axios from "@/utils/request";
import inspectUserPermissions from "@/utils/permissionsJudge";
export default {
  name: "settliment",
  components: {
    Brands,
    SettlementOrder,
    DepositHistory,
    Bill,
    MakeDeposit,
  },
  data() {
    return {
      btnLists: [
        {
          id: 0,
          name: "Payment History",
        },
        {
          id: 1,
          name: "Deposit History",
        },
        {
          id: 2,
          name: "Bill History",
        },
      ],
      isToggle: 0,
      time_start: "",
      time_end: "",
      isSuccessWrap: false,
      hasBalanceHistory: true,
      hasDepositHistory: true,
      hasDepositCreate: true,
      isWarningFirstDeposit: "CHECKING",
      isLoading: false,
    };
  },
  created() {
    if (this.$route.query.deposit ?? "") {
      this.isToggle = 1;

      this.onPayed();
    } else if (this.$route.query.show_deposit) {
      this.isToggle = 1;
    }

    if (!this.hasBalanceHistory) {
      this.btnLists = [];
      this.btnLists = [
        {
          id: 0,
          name: "Payment History",
        },
        {
          id: 1,
          name: "Deposit history",
        },
      ];
    }
    if (!this.hasDepositHistory) {
      this.btnLists = [];
      this.btnLists = [
        {
          id: 0,
          name: "Payment History",
        },
        {
          id: 2,
          name: "Bill History",
        },
      ];
    }
    if (!this.hasBalanceHistory && !this.hasDepositHistory) {
      this.btnLists = [];
      this.btnLists = [
        {
          id: 0,
          name: "Payment History",
        },
      ];
    }

    this.checkWarningFirstDeposit();
  },
  mounted() {
    let authorityJudgment = this.$store.state.authorityJudgment;
    this.hasBalanceHistory = inspectUserPermissions(
      "account/balanceHistory",
      authorityJudgment
    );
    this.hasDepositHistory = inspectUserPermissions(
      "account/depositHistory",
      authorityJudgment
    );
    this.hasDepositCreate = inspectUserPermissions(
      "account/createDeposit",
      authorityJudgment
    );
  },
  methods: {
    checkWarningFirstDeposit() {
      axios
        .get("/api_admin.php?a=account/my_warnings")
        .then((res) => {
          res.data.forEach((item) => {
            if (item.warning_type == "TYPE_NEW_BRAND_PREPAID_DEPOSIT") {
              this.isWarningFirstDeposit = "YES";
            }
          });
          if (this.isWarningFirstDeposit == "CHECKING") {
            this.isWarningFirstDeposit = "NO";
          }
        })
        .catch(() => {
          this.isWarningFirstDeposit = "NO";
        });
    },
    onPayed() {
      let { PayerID, token, type } = this.$route.query;
      if (type == "success") {
        this.isSuccessWrap = true;
        this.paymentSuccessCallback(token, PayerID);
      } else if (type == "cancel") {
        this.paymentErrorCallback(token, PayerID);
      }
    },
    makeFirstDeposit() {
      this.isLoading = true;
      axios
        .post("/api_admin.php?a=account/paymentToken", {
          amount: 100,
        })
        .then((res) => {
          if (res.code === 0) {
            axios
              .post("/api_admin.php?a=account/createDeposit", {
                amount: 100,
                token: res.data,
              })
              .then((res) => {
                if (res.code === 0) {
                  window.location.href = res.data.payment_url;
                } else {
                  this.isLoading = false;
                  this.$message.error(res.msg || "");
                }
              })
              .catch((e) => {
                this.isLoading = false;
              });
          } else {
            this.isLoading = false;
            this.$message.error(res.msg || "");
          }
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    paymentSuccessCallback(token, PayerID) {
      axios
        .post("/api_admin.php?a=payment/callback", {
          token: token,
          PayerID: PayerID,
          state: "success",
        })
        .then((res) => {
          if (res.code == 0) {
            this.refreshUserInfo().then(() => {
              this.isSuccessWrap = false;
              this.$router.replace("/account/balance_details");
            });
          } else {
            this.isSuccessWrap = false;
          }
        });
    },
    paymentErrorCallback(token) {
      this.$message.error("payment cancel!!");
      axios
        .post("/api_admin.php?a=payment/callback", {
          token: token,
          state: "cancel",
        })
        .then((res) => {
          this.isSuccessWrap = false;
          this.$router.replace("/account/balance_details");
          console.log(res);
        });
    },
    onChangeDate(date, dateString) {
      this.time_start = dateString[0];
      this.time_end = dateString[1];
    },
    btnChange(index) {
      this.isToggle = index;
    },
    search() {
      if (this.isToggle === 0) {
        this.$refs.order._getWithdrawLists();
      } else if (this.isToggle === 1) {
        this.$refs.history._getDepositHistory();
      } else if (this.isToggle === 2) {
        this.$refs.balance._getBalanceHistory();
      }
    },
    reset() {
      this.time_start = "";
      this.time_end = "";
    },
  },
};
</script>

<style lang="less" scoped>
.settliment-wrap {
  .header-search {
    padding: 12px 30px 30px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;

    .rage {
      font-size: 1.1429rem;
      font-family: "dtc-bold";
      color: #333333;
      line-height: 30px;
    }

    .date-search {
      display: flex;
      justify-content: space-between;
      margin-top: 6px;

      .range-date {
        width: 262px;
        height: 2.8571rem;

        /deep/ .ant-input {
          height: 2.8571rem;
          background: #fafafa;
          border-radius: 6px;
          border: 1px solid #e6e6e6;
        }
      }

      .btn {
        display: flex;

        .search {
          width: 116px;
          height: 2.8571rem;
          background: #2898ff;
          border-radius: 6px;
          line-height: 2.8571rem;
          text-align: center;
          font-size: 1.1429rem;
          color: #ffffff;
          margin-right: 20px;
          cursor: pointer;
        }

        .reset {
          width: 116px;
          height: 2.8571rem;
          border-radius: 6px;
          border: 1px solid #dddddd;
          font-size: 1.1429rem;
          color: #333333;
          line-height: 2.8571rem;
          text-align: center;
          cursor: pointer;
          margin-right: 14px;
        }
      }
    }
  }

  .toggle-content {
    margin-top: 22px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 20px 44px 30px 30px;
    box-sizing: border-box;
    margin-bottom: 80px;

    .toggle {
      button {
        height: 2.8571rem;
        margin-right: 16px;
        border-radius: 6px;
        border: 0px;
        padding: 9px 27px;
        background: rgba(0, 0, 0, 0.04);
        cursor: pointer;
        font-size: 1.1429rem;
        color: #999999;
        line-height: 20px;

        &:last-child {
          margin-right: 0px;
        }
      }

      .active {
        background: #f2f9ff;
        color: #2898ff;
        border: 0px;
      }
    }
  }
}

.payment-success-wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99;

  .mask {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .content-wrap {
    width: 600px;
    height: 500px;
    // background-color: #fff;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 6px;
    text-align: center;

    .loading {
      color: #fff;
      font-size: 110px;
    }
  }
}
</style>
