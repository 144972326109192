<template>
  <div
    class="item medium-card"
    :class="{
      simple: !showStats,
      'hide-give-bonus': !showGiveBonus || !showStatusSelect,
    }"
    v-if="item"
  >
    <div class="item-head">
      <router-link :to="`/publisher/detail/${item.medium_id}`">
        <img
          :src="errorPlaceHolder || logo"
          :alt="item.title"
          class="logo"
          @error="onLogoError"
        />
      </router-link>
      <a-avatar v-if="flag" size="small" :src="flag" class="flag" />

      <template v-if="showStats">
        <div
          class="info"
          :class="{ active: tabActive == 'info' }"
          @click="$emit('tabActiveChange', 'info')"
        >
          <img src="@/assets/medium-info.svg" alt />
        </div>
        <div
          class="stat"
          :class="{ active: tabActive == 'stat' }"
          @click="$emit('tabActiveChange', 'stat')"
        >
          <img src="@/assets/medium-stat.svg" alt />
        </div>
      </template>
      <div
        v-if="checkAble"
        class="checkbox"
        :class="{ checked }"
        @click="$emit('checkChange', item.site_id, !checked)"
      >
        <div class="checkbox-border">
          <img src="@/assets/check-box-checked.svg" alt />
        </div>
      </div>
      <img
        src="@/assets/medium-msg.svg"
        alt
        class="medium-msg"
        @click="openChat"
      />
    </div>

    <div class="item-page1" v-show="tabActive == 'info'">
      <div v-if="showStatusSelect">
        <a-select
          placeholder="Select"
          size="small"
          v-model="newStatus"
          style="width:8.5714rem"
          @change="handleNewStatusChange"
        >
          <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
          <a-select-option value="Approved">Approved</a-select-option>
          <a-select-option value="Declined" v-if="item.status != 'terminated'"
            >Declined</a-select-option
          >
          <a-select-option value="Pending" v-if="item.status == 'verifing'"
            >Pending</a-select-option
          >
          <a-select-option value="Removed" v-if="item.status == 'adopt'"
            >Removed</a-select-option
          >
        </a-select>
      </div>
      <div>
        <span>Publisher ID:</span>
        {{ item.medium_id }}
      </div>
      <div class="host desc">
        <a :href="item.host" target="_blank">{{ item.host }}</a>
        <span>?</span>
        <span>
          <span>{{ item.host }}</span>
        </span>
      </div>
      <div class="commission-rule">
        <span>Commission Rule:</span>
        Per Sale {{ item.aff_ba }}
      </div>
      <div>
        <span>Country:</span>
        {{ countryMap[item.country] ? countryMap[item.country].name_en : "" }}
      </div>
      <div v-if="checkAble">
        <span>Applied:</span>
        {{ applied }}
      </div>
      <div v-if="showGiveBonus">
        <a
          @click="item.status == 'adopt' && $emit('giveBounus', item)"
          class="give-bonus"
          :class="{ disabled: item.status != 'adopt' }"
          >Give A Bonus</a
        >
      </div>
    </div>

    <div class="item-page2" v-show="tabActive == 'stat'" v-if="item.stats">
      <div class="stat-option">
        <a-select
          @change="
            (val) => {
              $emit('statByChange', val);
              return false;
            }
          "
          placeholder="Select"
          v-model="statByLocal"
          size="small"
          style="width: 8.5714rem"
        >
          <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
          <a-select-option value="today">Today</a-select-option>
          <a-select-option value="this_month">This Month</a-select-option>
          <a-select-option value="prev_month">Prev Month</a-select-option>
          <a-select-option value="total">Total</a-select-option>
        </a-select>
      </div>
      <div class="stat-info">
        <div>{{ item.stats.hits | toThousands(true) }}</div>
        <div>Hits</div>
      </div>
      <div class="stat-info">
        <div>{{ item.stats.gross_sales | toThousands }}</div>
        <div>Gross Sales</div>
      </div>
      <div class="stat-info">
        <div>{{ item.stats.commission | toThousands }}</div>
        <div>Commission</div>
      </div>
      <div class="stat-info">
        <div>{{ item.stats.epc | toThousands }}</div>
        <div>EPC</div>
      </div>
    </div>

    <Modal
      :isVisible="isShowUpdate"
      title="Mass Action Confirmation"
      :showBtn="false"
      @close-popup="isShowUpdate = false"
      width="365px"
      class="batch-update-modal"
    >
      <template #content>
        <div class="update-info">
          <div class="color-primary font-bold">1 Publisher</div>
          <div>{{ item.title }} - {{ item.medium_id }}</div>

          <div class="color-primary font-bold">Status Change</div>
          <div>Set status to: {{ newStatus }}</div>
        </div>
        <div style="display: flex; justify-content: center;padding-left:15px">
          <a-button
            type="default"
            @click="isShowUpdate = false"
            style="margin-right: 60px;"
            size="large"
            >Cancel</a-button
          >
          <a-button
            type="primary"
            size="large"
            style="font-size:16px"
            @click="batchEdit"
            >Confirm</a-button
          >
        </div>
      </template>
    </Modal>

    <Loading v-if="isLoadingModal"></Loading>
  </div>
</template>

<script>
const defaultLogo = require("@/assets/default.png");
import moment from "moment-timezone";
import axios from "@/utils/request";
import Modal from "../../../components/Modal.vue";

export default {
  components: { Modal },
  props: {
    item: {
      type: Object,
      default: () => null,
    },
    checkAble: {
      type: Boolean,
      default: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    tabActive: {
      type: String,
      default: "info",
    },
    statBy: {
      type: String,
      default: "today",
    },
    showStats: {
      type: Boolean,
      default: false,
    },
    showGiveBonus: {
      type: Boolean,
      default: false,
    },
    showStatusSelect: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    applied() {
      if (
        this.item.status == "adopt" ||
        this.item.status == "refuse" ||
        this.item.status == "terminated"
      ) {
        return moment.unix(this.item.created_time).format("MM/DD/yyyy");
      }
      return "Net Yet"; //this.status
    },
    status() {
      return this.item.status
        ? {
            adopt: "Approved",
            refuse: "Declined",
            verifing: "Pending",
            terminated: "Removed",
          }[this.item.status]
        : "Not Yet";
    },
    logo() {
      let logo = this.item.logo;
      if (!logo) {
        logo = defaultLogo;
      } else if (
        this.item.logo.indexOf("http") !== 0 &&
        this.item.logo.indexOf("//") !== 0
      ) {
        logo = this.APP_CONFIG.OSS_HOST + logo;
      }
      return logo;
    },
    flag() {
      try {
        return require("@/assets/flag/" + this.item.country + ".png");
      } catch (e) {
        return null;
      }
    },
  },
  data() {
    return {
      countryMap: {},
      statByLocal: "today",
      errorPlaceHolder: null,
      isShowUpdate: false,
      newStatus: "",
      isLoadingModal: false,
    };
  },
  watch: {
    statBy(newV, oldV) {
      this.statByLocal = newV;
    },
    isShowUpdate(newV, oldV) {
      if (!newV) {
        this.newStatus = {
          adopt: "Approved",
          refuse: "Declined",
          verifing: "Pending",
          terminated: "Removed",
        }[this.item.status];
      }
    },
    "item.status"(newV, oldV) {
      this.newStatus = {
        adopt: "Approved",
        refuse: "Declined",
        verifing: "Pending",
        terminated: "Removed",
      }[newV];
    },
  },
  created() {
    this.$store.state.commonConfig.countries.forEach((item) => {
      this.countryMap[item.id] = item;
    });
    this.newStatus = {
      adopt: "Approved",
      refuse: "Declined",
      verifing: "Pending",
      terminated: "Removed",
    }[this.item.status];
  },
  methods: {
    onLogoError() {
      this.errorPlaceHolder = defaultLogo;
    },
    openChat() {
      this.$emit("openChat");
      this.$store.commit("setChatMediumId", this.item.medium_id);
    },
    batchEdit() {
      let newStatus = {
        Approved: "adopt",
        Declined: "refuse",
        Pending: "verifing",
        Removed: "terminated",
      }[this.newStatus];
      this.isLoadingModal = true;
      axios
        .post("/api_admin.php?a=medium/edit", {
          site_ids: this.item.site_id,
          status: newStatus,
        })
        .then((res) => {
          this.isLoadingModal = false;
          this.isShowUpdate = false;
          this.newStatus = "";
          if (res.code == 0) {
            this.$nextTick(() => {
              this.$emit("statusChange", this.item, newStatus);
            });
          }
        })
        .catch((e) => {
          this.isLoadingModal = false;
        });
    },
    handleNewStatusChange(val) {
      let oldStatus = {
        adopt: "Approved",
        refuse: "Declined",
        verifing: "Pending",
        terminated: "Removed",
      }[this.item.status];
      this.isShowUpdate = val != oldStatus;
    },
  },
};
</script>

<style lang="less" scoped>
.item {
  // width: calc(97px + 262px);
  width: 262px;
  height: auto;
  background: #f2f9ff;
  border-radius: 12px;
  box-sizing: border-box;
  position: relative;

  &.simple {
    // height: calc(296px + 35px);
    .item-page1 {
      border-radius: 12px;
    }

    .item-head {
      height: calc(138px);
    }

    .medium-msg {
      top: 145px;
    }
  }

  .medium-msg {
    width: 30px;
    height: 30px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 180px;
  }

  .item-head {
    position: relative;
    width: 100%;
    height: 172px;
    box-sizing: border-box;
    float: left;

    a {
      display: block;
      width: 234px;
      height: 110px;
      display: flex;
      margin: 14px;
      justify-content: center;
      align-content: center;
    }

    .logo {
      display: block;
      max-width: 100%;
      max-height: 100%;
      border-radius: 12px;
    }

    .flag {
      position: absolute;
      width: 18px;
      height: 18px;
      border-radius: 18px;
      display: block;
      left: 20px;
      top: 20px;
      line-height: 18px;
      font-size: 0.8571rem;
    }

    .info,
    .stat {
      width: calc(50% - 1px);
      height: 34px;
      float: left;
      text-align: center;
      cursor: pointer;

      img {
        display: inline-block;
      }

      &.active {
        background: #ffffff;
        border-radius: 12px 12px 0px 0px;
      }
    }

    .info {
      margin-left: 1px;
    }

    .checkbox {
      cursor: pointer;
      width: 36px;
      height: 36px;
      position: absolute;
      right: 4px;
      top: 4px;
      user-select: none;
      padding: 6px;
      box-sizing: border-box;
      background-color: #ffffff01;

      img {
        display: none;
      }

      .checkbox-border {
        width: 24px;
        height: 24px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #dddddd;
      }

      &:hover {
        .checkbox-border {
          border-color: rgba(40, 152, 255, 1);
        }
      }

      &.checked {
        .checkbox-border {
          border: none;
        }

        img {
          height: 24px;
          width: 24px;
          display: block;
        }
      }
    }
  }

  .item-page1,
  .item-page2 {
    // width: calc(97px + 260px);
    width: 260px;
    // height: calc(35px * 7 + 5px);
    background-color: #fff;
    float: left;
    margin: 0 1px 1px 1px;
    border-radius: 0 0 12px 12px;
  }

  .item-page1 {
    padding-top: 5px;

    > div {
      width: 100%;
      height: 35px;
      box-sizing: border-box;
      padding: 0 17px;
      line-height: 35px;

      > span {
        font-family: "dtc-bold";
      }

      .give-bonus {
        font-family: "dtc-bold";

        &.disabled {
          color: #eaeaea;
          cursor: not-allowed;
        }
      }

      &.host {
        // width: calc(97px + 235px);
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
          display: inline-block;
          // width: calc(97px + 235px);
          height: 35px;
          width: 205px;
          overflow: hidden;
          text-overflow: ellipsis;
          color: rgba(40, 152, 255, 1);
        }

        &.desc {
          cursor: default;
          position: relative;

          &:hover > span > span {
            display: block !important;
          }

          > span:first-of-type {
            display: inline-block;
            height: 20px;
            width: 20px;
            transform: scale(0.6);
            font-size: 1rem;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 400;
            background-color: #2898ff;
            border-radius: 999px;
            text-align: center;
            line-height: 20px;
            vertical-align: middle;
            color: #fff;
          }

          > span:last-child {
            position: absolute;
            height: 0px;
            width: 0px;
            display: inline-block;

            span {
              font-family: dtc;
              color: #2898ff;
              background-color: rgba(62, 61, 64, 0.9);
              position: absolute;
              left: 0px;
              top: 15px;
              padding: 8px;
              width: 200px;
              word-wrap: break-word;
              box-sizing: border-box;
              border-radius: 4px;
              color: #fff;
              font-size: 0.8571rem;
              line-height: 15px;
              z-index: 1;
              font-weight: 400;
              height: fit-content;
              display: none;

              &::before {
                display: block;
                content: " ";
                border: 5px solid rgba(62, 61, 64, 0.9);
                border-top-color: rgba(0, 0, 0, 0);
                border-right-color: rgba(0, 0, 0, 0);
                height: 10px;
                box-sizing: border-box;
                width: 10px;
                border-bottom-left-radius: 1px;
                position: absolute;
                top: -5px;
                left: 98px;
                transform: rotate(135deg);
              }
            }
          }
        }
      }

      &.commission-rule {
        // height: 70px;
      }
    }
  }

  .item-page2 {
    .stat-option {
      width: 100%;
      box-sizing: border-box;
      float: left;
      height: 50px;
      padding-top: 11px;
      padding-left: 17px;
      padding-right: 17px;
      display: flex;
      justify-content: space-between;
    }

    .stat-info {
      float: left;
      width: 50%;
      height: 50px;
      text-align: center;
      line-height: 25px;
      vertical-align: middle;

      > div:first-child {
        font-family: "dtc-bold";
      }

      > div:last-child {
        color: #666;
      }
    }
  }
}

.batch-update-modal {
  /deep/ .update-info {
    padding-bottom: 20px;

    > div {
      + div {
        margin-top: 4px;
      }

      font-size: 1rem;

      &.font-bold {
        font-size: 1.1429rem;
        margin-top: 20px;
      }

      &:first-child {
        margin-top: 0 !important;
      }
    }
  }
}
</style>
