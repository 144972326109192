<template>
  <div id="app">
    <a-config-provider :getPopupContainer="getPopupContainer">
      <router-view></router-view>
    </a-config-provider>
  </div>
</template>
<script>
export default {
  methods: {
    getPopupContainer(el, dialogContext) {
      if (dialogContext) {
        return dialogContext.getDialogWrap();
      } else {
        return document.body;
      }
    },
  },
};
</script>
<style lang="less">
.clearboth {
  clear: both;
}
.ant-popover.floating-medium-card {
  .ant-spin-nested-loading {
    height: 296px;
    width: 262px;
  }
  .ant-popover-content {
    margin-left: -5px;
    border-radius: 12px;
  }
  .ant-popover-arrow {
    margin-left: -5px;
    border-bottom-color: rgba(212, 53, 1, 0.3);
    border-left-color: rgba(212, 53, 1, 0.3);
  }
  .ant-popover-inner {
    border-radius: 12px;
    .ant-popover-inner-content {
      border-radius: 12px;
      background-color: #fff;
      height: 296px;
      width: 262px;
      padding: 0;
      .medium-card {
        margin: 0;
      }
    }
  }
}

.ant-calendar-range-picker-separator {
  vertical-align: middle !important;
}
.color-blue {
  color: #2898ff;
}
.color-blue-light {
  color: #74bcff;
}
.color-primary,
.color-red {
  color: #2898ff;
}
.color-red-light {
  color: #ffa485;
}
.icon-down {
  color: #333333 !important;
  font-size: 1rem;
}
.ant-select-selection,
.ant-select-selection--single {
  background: #ffffff !important;
}
.ant-select-sm {
  font-size: 12px !important;
}

* {
  scrollbar-color: #c9c9c9;
  scrollbar-width: thin;
}
::-webkit-scrollbar-thumb {
  background: #c9c9c9;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #9b9b9b;
}
::-webkit-scrollbar {
  height: 3px;
  width: 3px;
}
.scrollbar-none {
  scrollbar-width: none;
}
.scrollbar-none::-webkit-scrollbar-thumb {
  background: transparent;
  width: 0;
  height: 0;
}
.scrollbar-hover:hover::-webkit-scrollbar-thumb {
  background: #c9c9c9;
}
._cursor {
  cursor: pointer;
}
._ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
