<template>
  <div class="communicatio-wrap">
    <ul class="toggle-li">
      <li
        v-for="(item, index) in btnLists"
        :key="index"
        :class="index === act ? 'active' : ''"
        @click="toggleAct(index)"
      >
        {{ item.name }}
      </li>
    </ul>

    <div class="program" v-show="act === 0">
      <p>
        Give a general description of your affiliate program, products,
        commissions offered and average sale amount. This description allows
        publishers to learn more about you as a brand.
      </p>
      <textarea v-model="programText"></textarea>
      <div class="update">
        <button @click="update(0)" v-if="hasUpdateCommunicationsInfo">
          Update
        </button>
      </div>
    </div>
    <div class="agreement" v-show="act === 1">
      <p>
        Update your Program Agreement below. You need to specify a summary of
        the changes, and the effective date for the new agreement. Please note
        that an email alert will be sent to all of your current publishers each
        time the agreement is changed
      </p>
      <h6>Your Current Agreement</h6>
      <textarea v-model="agreementText"></textarea>
      <h6>Your New Agreement</h6>
      <button style="margin-bottom: 16px;" @click="copyCurrent">
        Copy Current
      </button>
      <textarea
        style="margin-bottom: 12px"
        v-model="copyAgreementText"
      ></textarea>
      <p>
        Please make sure to add a good Summary of Changes, it helps your
        publishers in identifying what changes were made.
      </p>
      <h6>Effective Date</h6>
      <a-range-picker
        @change="onChangeAgreement"
        :default-value="[
          agreement_effect_start_time,
          agreement_effect_end_time,
        ]"
        format="L"
      />
      <div class="tips">
        Acceptable Date Range: January 25, 2022 - February 17, 2022
      </div>
      <div class="update">
        <button @click="update(1)" v-if="hasUpdateCommunicationsInfo">
          Update
        </button>
      </div>
    </div>
    <div class="keywords" v-show="act === 2">
      <p>
        Keywords allow publishers to search for your brand account and products.
        Please add as many descriptive terms (at least three) as possible. The
        more keywords you have, the more likely an publisher will find you
        quickly and easily. Keywords can be changed/updated at anytime.
      </p>
      <textarea v-model="keywordsText"></textarea>
      <div class="update">
        <button @click="update(2)" v-if="hasUpdateCommunicationsInfo">
          Update
        </button>
      </div>
    </div>
    <div class="responders" v-show="act === 3">
      <p>
        PartnerBoost sends an email to publishers when they apply to your
        program. Other emails are generated when you decline or approve the
        publisher. Edit the emails below or use our default wording.
      </p>
      <div class="media-apply-declined-approve">
        <div class="btn-left">
          <button
            v-for="(item, index) in mediaOpinionBtn"
            :key="index"
            :class="mediaAct === index ? 'media-act' : ''"
            @click="mediaOpinionToggle(index)"
          >
            {{ item.name }}
          </button>
        </div>
        <div class="right">
          <textarea
            v-model="apply_mail_content"
            v-show="mediaAct === 0"
          ></textarea>
          <textarea
            v-model="declined_mail_content"
            v-show="mediaAct === 1"
          ></textarea>
          <textarea
            v-model="approve_mail_content"
            v-show="mediaAct === 2"
          ></textarea>
        </div>
      </div>
      <div class="update">
        <button @click="update(3)" v-if="hasUpdateCommunicationsInfo">
          Update
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/utils/request";
import moment from "moment-timezone";
import inspectUserPermissions from "@/utils/permissionsJudge";
export default {
  name: "communication",
  data() {
    return {
      btnLists: [
        {
          id: 0,
          name: "Program Bio",
        },
        {
          id: 1,
          name: "Publisher Agreement",
        },
        {
          id: 2,
          name: "Keywords",
        },
        {
          id: 3,
          name: "Email Responders",
        },
      ],
      mediaOpinionBtn: [
        {
          id: 0,
          name: "Apply",
        },
        {
          id: 1,
          name: "Declined",
        },
        {
          id: 2,
          name: "Approve",
        },
      ],
      act: this.$route.query.act ? this.$route.query.act : 0,
      mediaAct: 0,
      apply_mail_content: "",
      declined_mail_content: "",
      approve_mail_content: "",
      programText: "",
      agreementText: "",
      copyAgreementText: "",
      keywordsText: "",
      agreement_effect_start_time: "",
      agreement_effect_end_time: "",
      // 是否有更新Communications信息的权限
      hasUpdateCommunicationsInfo: true,
    };
  },
  computed: {
    accountDetails() {
      return this.$store.state.accountDetails;
    },
  },
  created() {
    if (this.accountDetails.merchant) {
      this.programText = this.accountDetails.merchant.site_desc;
      this.agreementText = this.accountDetails.merchant.program_agreement;
      this.keywordsText = this.accountDetails.keywords.join(",");
      this.apply_mail_content = this.accountDetails.merchant.apply_mail_content;
      this.declined_mail_content = this.accountDetails.merchant.declined_mail_content;
      this.approve_mail_content = this.accountDetails.merchant.approve_mail_content;
      this.agreement_effect_start_time = this.filterTime(
        this.accountDetails.merchant.agreement_effect_start_time
      );
      this.agreement_effect_end_time = this.filterTime(
        this.accountDetails.merchant.agreement_effect_end_time
      );
    }
  },
  mounted() {
    let authorityJudgment = this.$store.state.authorityJudgment;
    this.hasUpdateCommunicationsInfo = inspectUserPermissions(
      "user/updateCommunicationsInfo",
      authorityJudgment
    );
  },
  methods: {
    toggleAct(index) {
      this.act = index;
      console.log(this.act, "mediaAct");
    },
    onChangeAgreement(date, dateString) {
      this.agreement_effect_start_time = dateString[0];
      this.agreement_effect_end_time = dateString[1];
    },
    // 媒体理由切换按钮
    mediaOpinionToggle(index) {
      this.mediaAct = index;
    },
    copyCurrent() {
      this.copyAgreementText = this.agreementText;
    },
    update(index) {
      if (index == 0) {
        axios
          .post("/api_admin.php?a=user/updateCommunicationsInfo", {
            type: "program_bio",
            site_desc: this.programText,
          })
          .then((res) => {
            if (res.code == 0) {
              this.$message.success("update success!!!");
            }
          });
      } else if (index == 1) {
        axios
          .post("/api_admin.php?a=user/updateCommunicationsInfo", {
            type: "affiliate_agreement",
            program_agreement: this.copyAgreementText,
            agreement_effect_start_time: this.agreement_effect_start_time,
            agreement_effect_end_time: this.agreement_effect_end_time,
          })
          .then((res) => {
            if (res.code == 0) {
              this.$message.success("update success!!!");
              this.agreementText = this.copyAgreementText;
              this.copyAgreementText = "";
            }
          });
      } else if (index == 2) {
        axios
          .post("/api_admin.php?a=user/updateCommunicationsInfo", {
            type: "keywords",
            keywords: this.keywordsText,
          })
          .then((res) => {
            if (res.code == 0) {
              this.$message.success("update success!!!");
            }
          });
      } else if (index == 3) {
        axios
          .post("/api_admin.php?a=user/updateCommunicationsInfo", {
            type: "email_responders",
            apply_mail_content: this.apply_mail_content,
            declined_mail_content: this.declined_mail_content,
            approve_mail_content: this.approve_mail_content,
          })
          .then((res) => {
            if (res.code == 0) {
              this.$message.success("update success!!!");
            }
          });
      }
    },
    filterTime(time) {
      if (time) {
        return moment.unix(time).format("L");
      }
      return "";
    },
  },
};
</script>

<style lang="less" scoped>
.communicatio-wrap {
  .toggle-li {
    display: flex;
    padding: 0px;
    margin: 0px;
    padding-bottom: 16px;
    border-bottom: 1px solid #eee;
    li {
      list-style-type: none;
      font-size: 1.1429rem;
      color: #333333;
      line-height: 23px;
      cursor: pointer;
      margin-right: 100px;
      &:last-child {
        margin-right: 0px;
      }
      &:first-child {
        margin-left: 2.8571rem;
      }
    }
    .active {
      color: #2898ff;
      position: relative;
      &::before {
        content: "";
        width: 50%;
        height: 2px;
        background: #2898ff;
        position: absolute;
        bottom: -16px;
        left: 25%;
      }
    }
  }
  .program {
    margin-top: 23px;
    p {
      font-size: 1rem;
      color: #333333;
      line-height: 18px;
      margin-bottom: 16px;
    }
    textarea {
      width: 100%;
      height: 181px;
      border-radius: 6px;
      border: 1px solid #e6e6e6;
      outline: none;
      resize: none;
      padding: 10px;
    }
    .update {
      margin-top: 30px;
      text-align: right;
      button {
        width: 116px;
        height: 2.8571rem;
        background: #2898ff;
        border-radius: 6px;
        font-size: 1.1429rem;
        color: #ffffff;
        line-height: 20px;
        border: none;
        cursor: pointer;
      }
    }
  }
  .agreement {
    margin-top: 23px;
    p {
      font-size: 1rem;
      color: #333333;
      line-height: 18px;
      margin-bottom: 16px;
    }
    h6 {
      font-size: 1rem;
      font-family: "dtc-bold";
      line-height: 18px;
      margin-bottom: 12px;
    }
    textarea {
      padding: 10px;
      width: 100%;
      height: 181px;
      border-radius: 6px;
      border: 1px solid #e6e6e6;
      margin-bottom: 20px;
      outline: none;
      resize: none;
    }
    button {
      background: #2898ff;
      border-radius: 6px;
      font-size: 1.1429rem;
      color: #ffffff;
      line-height: 20px;
      padding: 9px 21px;
      border: none;
      cursor: pointer;
    }
    .tips {
      font-size: 1rem;
      color: #999999;
      line-height: 18px;
      margin: 12px auto 30px;
    }
    .update {
      text-align: right;
    }
  }
  .keywords {
    margin-top: 23px;
    p {
      font-size: 1rem;
      color: #333333;
      line-height: 18px;
      margin-bottom: 16px;
    }
    textarea {
      width: 100%;
      height: 181px;
      border-radius: 6px;
      border: 1px solid #e6e6e6;
      outline: none;
      resize: none;
      padding: 10px;
    }
    .update {
      margin-top: 30px;
      text-align: right;
      button {
        width: 116px;
        height: 2.8571rem;
        background: #2898ff;
        border-radius: 6px;
        font-size: 1.1429rem;
        color: #ffffff;
        line-height: 20px;
        border: none;
        cursor: pointer;
      }
    }
  }
  .responders {
    margin-top: 23px;
    p {
      font-size: 1rem;
      color: #333333;
      line-height: 18px;
      margin-bottom: 16px;
    }
    .media-apply-declined-approve {
      width: 100%;
      height: 226px;
      border-radius: 6px;
      border: 1px solid #dddddd;
      margin-bottom: 20px;
      display: flex;
      .btn-left {
        width: 136px;
        margin-right: 20px;
        button {
          width: 100%;
          height: 75px;
          background: #f5f5f5;
          border: 1px solid #dddddd;
          border-bottom: 0px;
          box-sizing: border-box;
          border-left: 0px;
          cursor: pointer;
          font-size: 1.2857rem;
          font-family: "dtc-bold";
          color: #333333;
          &:first-child {
            border-top: 0px;
            border-top-left-radius: 6px;
          }
          &:last-child {
            border-bottom-left-radius: 6px;
            border-bottom: 1px solid #dddddd !important;
          }
        }
        .media-act {
          background-color: #fff;
          border-right: 0px;
        }
      }
      .right {
        width: calc(100% - 156px);
        textarea {
          width: 100%;
          height: 100% !important;
          border: 0px;
          outline: none;
          resize: none;
          padding: 20px 20px 20px 0px;
          box-sizing: border-box;
          font-size: 1.1429rem;
          color: #333333;
          line-height: 20px;
          border-top-right-radius: 16px;
          border-bottom-right-radius: 16px;
        }
      }
    }
    .update {
      margin-top: 30px;
      text-align: right;
      button {
        width: 116px;
        height: 2.8571rem;
        background: #2898ff;
        border-radius: 6px;
        color: #ffffff;
        line-height: 20px;
        border: none;
        cursor: pointer;
      }
    }
  }
}
</style>
