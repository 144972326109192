<template>
  <div class="json-wrap">
    <VueJsonPretty :data="data" />
  </div>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

export default {
  components: {
    VueJsonPretty,
  },
  props: {
    data: {
      type: Object,
      default: () => null,
    },
  },
};
</script>
<style lang="less" scoped>
.json-wrap {
  border-radius: 16px;
  padding: 8px 12px;
  border: 1px solid #f5f5f5;
  margin: 12px 0;
}
/deep/ .vjs-tree {
  font-family: "prefer";
}
</style>
