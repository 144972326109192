<template>
  <div class="child-details">
    <p>
      PartnerBoost sends an email to publishers when they apply to your program.
      Other emails are generated when you decline or approve the publisher. Edit
      the emails below or use our default wording.
    </p>
    <div class="media-apply-declined-approve">
      <div class="btn-left">
        <div
          v-for="(item, index) in mediaOpinionBtn"
          :key="index"
          :class="mediaAct === index ? 'media-act' : ''"
          @click="mediaAct = index"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="right">
        <textarea
          v-model="apply_mail_content"
          v-show="mediaAct === 0"
        ></textarea>
        <textarea
          v-model="declined_mail_content"
          v-show="mediaAct === 1"
        ></textarea>
        <textarea
          v-model="approve_mail_content"
          v-show="mediaAct === 2"
        ></textarea>
      </div>
    </div>
    <div class="update">
      <button @click="update(3)" v-if="hasUpdateCommunicationsInfo">
        Update
      </button>
    </div>
    <Loading v-if="isLoadingModal"></Loading>
  </div>
</template>

<script>
import Modal from "../../../components/Modal.vue";
import inspectUserPermissions from "@/utils/permissionsJudge";
import axios from "@/utils/request";

export default {
  name: "account-edit",
  components: {
    Modal,
  },
  data() {
    return {
      mediaOpinionBtn: [
        {
          id: 0,
          name: "Apply",
        },
        {
          id: 1,
          name: "Declined",
        },
        {
          id: 2,
          name: "Approve",
        },
      ],
      mediaAct: 0,
      apply_mail_content: "",
      declined_mail_content: "",
      approve_mail_content: "",

      // 是否有更新Communications信息的权限
      hasUpdateCommunicationsInfo: true,
      isLoadingModal: false,
    };
  },
  mounted() {
    this.apply_mail_content = this.$store.state.accountDetails.merchant.apply_mail_content;
    this.declined_mail_content = this.$store.state.accountDetails.merchant.declined_mail_content;
    this.approve_mail_content = this.$store.state.accountDetails.merchant.approve_mail_content;

    let authorityJudgment = this.$store.state.authorityJudgment;
    this.hasUpdateCommunicationsInfo = inspectUserPermissions(
      "user/updateCommunicationsInfo",
      authorityJudgment
    );
  },
  methods: {
    update() {
      this.isLoadingModal = true;
      axios
        .post("/api_admin.php?a=user/updateCommunicationsInfo", {
          type: "email_responders",
          apply_mail_content: this.apply_mail_content,
          declined_mail_content: this.declined_mail_content,
          approve_mail_content: this.approve_mail_content,
        })
        .then((res) => {
          if (res.code == 0) {
            this.$message.success("update success!!!");
            this.$store.dispatch("updateAccountDetails", {
              merchant: {
                apply_mail_content: this.apply_mail_content,
                declined_mail_content: this.declined_mail_content,
                approve_mail_content: this.approve_mail_content,
              },
            });
          }
        })
        .then(() => {
          this.isLoadingModal = false;
        })
        .catch((e) => {
          this.isLoadingModal = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.child-details {
  width: 948px;
  height: 650px;
  background: #ffffff;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: flex-start;
}

p {
  font-size: 1rem;
  color: #333333;
  line-height: 22px;
  margin-bottom: 2.8571rem;
}

.media-apply-declined-approve {
  width: 100%;
  border-radius: 6px;
  display: flex;
  flex-direction: column;

  .btn-left {
    width: 100%;
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: dtc-bold;

    div {
      height: 37px;
      background: #fff;
      border-radius: 4px 4px 0px 0px;
      box-sizing: border-box;
      padding: 0 24px;
      line-height: 37px;
      cursor: pointer;
      user-select: none;
    }

    .media-act {
      background-color: #f2f9ff;
      color: #2898ff;
    }
  }

  .right {
    width: 100%;
    height: 252px;
    background: #f2f9ff;
    border-radius: 0px 4px 4px 4px;
    padding: 24px;
    padding-right: 16px;
    box-sizing: border-box;

    textarea {
      width: 100%;
      height: 100% !important;
      border: 0px;
      outline: none;
      resize: none;
      box-sizing: border-box;
      font-size: 1.1429rem;
      color: #333333;
      line-height: 20px;
      border-radius: 0px 4px 4px 4px;
      background: #f2f9ff;
      padding-right: 12px;

      &::-webkit-scrollbar {
        position: relative;
        width: 6px;
        background-color: #f2f9ff;
        border-radius: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background: #2896ff80;
        border-radius: 6px;
      }
    }
  }
}

.update {
  margin-top: 30px;
  text-align: right;
  width: 100%;

  button {
    width: 92px;
    height: 2.8571rem;
    background: #2898ff;
    border-radius: 6px;
    color: #ffffff;
    line-height: 20px;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: #52b1ff;
    }
  }
}
</style>
