<template>
  <div class="list">
    <Brands collection="Publishers" subset="Publisher Info"></Brands>
    <div class="content" v-if="pageActive">
      <DetailInfo :medium="medium" @statusChange="statusChange" />
      <div class="toggle-btn">
        <div class="button">
          <a
            :class="{ active: activeType == 'stats' }"
            @click="activeType = 'stats'"
            >Statistics</a
          >
        </div>
        <div class="button">
          <a
            :class="{ active: activeType == 'norms' }"
            @click="activeType = 'norms'"
            >Private Commission Rules</a
          >
        </div>
      </div>
      <DetailNorms v-show="activeType == 'norms'" :medium="medium" />
      <DetailSummary v-show="activeType == 'stats'" :medium="medium" />
      <DetailChart v-show="activeType == 'stats'" :medium="medium" />
      <DetailReferer v-show="activeType == 'stats'" :medium="medium" />
      <DetailCreative v-show="activeType == 'stats'" :medium="medium" />
    </div>
    <Loading v-if="isLoadingModal"></Loading>
  </div>
</template>

<script>
import axios from "@/utils/request";
import Brands from "./../../components/Brands.vue";
import Modal from "../../components/Modal.vue";
import DetailInfo from "./components/DetailInfo.vue";
import DetailCreative from "./components/DetailCreative.vue";
import DetailSummary from "./components/DetailSummary.vue";
import DetailReferer from "./components/DetailReferer.vue";
import DetailChart from "./components/DetailChart.vue";
import DetailNorms from "./components/DetailNorms.vue";
const defaultLogo = require("@/assets/default.png");

export default {
  name: "publisher_detail",
  props: ["id"],
  components: {
    Brands,
    Modal,
    DetailInfo,
    DetailCreative,
    DetailSummary,
    DetailReferer,
    DetailChart,
    DetailNorms,
  },
  data() {
    return {
      medium: null,
      isLoadingModal: false,
      activeType: "stats",
      pageActive: true,
    };
  },
  mounted() {
    this.isLoadingModal = true;
    axios
      .get("/api_admin.php?a=medium/detail", {
        params: { medium_id: this.id },
      })
      .then((res) => {
        this.isLoadingModal = false;
        if (res.code === 0) {
          let flag;
          try {
            flag = require("@/assets/flag/" + res.data.country + ".png");
          } catch (e) {
            console.log(e);
            flag = undefined;
          }

          let logo = res.data.logo;
          if (!logo) {
            logo = defaultLogo;
          } else if (
            res.data.logo.indexOf("http") !== 0 &&
            res.data.logo.indexOf("//") !== 0
          ) {
            logo = this.APP_CONFIG.OSS_HOST + logo;
          }

          this.medium = { ...res.data, logo, flag };
        } else {
          this.$message.error(
            res.msg || "Sorry, server is busy. please try later."
          );
        }
      })
      .catch((e) => {
        console.log(e);
        this.isLoadingModal = false;
      });
  },
  methods: {
    statusChange(status) {
      this.medium.apply_status = status;
      this.pageActive = false;
      this.$nextTick(() => {
        this.pageActive = true;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  padding-bottom: 60px;

  .toggle-btn {
    margin-top: 32px;
    margin-bottom: 22px;

    .button {
      padding: 0px;
      border: none;
      margin-right: 18px;
      border-radius: 6px;
      display: inline-block;

      a {
        display: inline-block;
        padding: 9px 22px;
        background: rgba(0, 0, 0, 0.04);
        border-radius: 6px;
        font-size: 1.1429rem;
        color: #999999;
        line-height: 20px;
      }

      .active {
        color: #2898ff;
        background: #f2f9ff;
      }
    }
  }

  /deep/ .content-block {
    width: 100%;
    padding: 28px 21px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    margin-bottom: 22px;
    position: relative;

    &.ant-spin-spinning {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }

    &.empty.ant-spin-spinning {
      justify-content: center;
      align-items: center;
    }

    .content-block-title {
      margin-bottom: 24px;
      height: 23px;
      font-size: 1.2857rem;
      color: #333333;
      line-height: 23px;
    }

    .table {
      border: 1px solid #eee;
      border-radius: 6px;
      box-sizing: border-box;
      height: max-content;
      width: 100%;

      .thead {
        background-color: #f9f9f9;
        height: 66px;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        border-bottom: 1px solid #eee;
        border-radius: 6px 6px 0 0;

        .td {
          font-size: 1.1429rem;
          font-family: "dtc";
          // font-family: "prefer-bold";
          align-items: center !important;
        }
      }

      .tbody {
        .ant-empty {
          padding: 2.8571rem 0;
        }
      }

      .tr {
        min-height: 66px;
        height: max-content;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        align-items: stretch;
        text-align: center;
        border-bottom: 1px solid #eee;

        &:last-child {
          border-bottom: unset;
          min-height: 43px;
        }

        &:hover {
          background-color: #f2f9ff;
        }
      }

      .td {
        font-size: 1rem;
        display: flex;
        min-height: 65px;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        position: relative;
        word-break: break-all;
        padding: 0 8px;
      }
    }
  }
}
</style>
