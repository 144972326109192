<template>
  <div class="flowrep">
    <Header collection="Reporting"></Header>
    <div class="container">
      <div class="headers"></div>
      <div class="content">
        <div class="comm-tables">
          <div class="comm-header">
            <div class="comm-header-left">
              <a-select placeholder="Select gender">
                <a-select-option value="Male"> Male </a-select-option>
                <a-select-option value="Female"> Female </a-select-option>
                <a-select-option value="All"> All </a-select-option>
              </a-select>
              <a-range-picker @change="onChange" />
              <div class="header-input">
                <span>Advertiser:</span><a-input></a-input>
              </div>
              <a-select placeholder="Select gender">
                <a-select-option value="Male"> Male </a-select-option>
                <a-select-option value="Female"> Female </a-select-option>
                <a-select-option value="All"> All </a-select-option>
              </a-select>
              <div class="header-input">
                <span>Order ID:</span><a-input></a-input>
              </div>
              <a-select placeholder="Select gender">
                <a-select-option value="Male"> Male </a-select-option>
                <a-select-option value="Female"> Female </a-select-option>
                <a-select-option value="All"> All </a-select-option>
              </a-select>
              <div class="header-input">
                <span>Cross-device:</span
                ><a-select placeholder="Select gender">
                  <a-select-option value="Male"> Male </a-select-option>
                  <a-select-option value="Female"> Female </a-select-option>
                  <a-select-option value="All"> All </a-select-option>
                </a-select>
              </div>
            </div>
            <div class="comm-header-rigth">
              <a-button type="primary">View</a-button>
              <a-button>Reset</a-button>
              <!-- <a-button type="primary">Download List</a-button> -->
            </div>
          </div>
          <div class="comm-list">
            <a-table
              class="comtable"
              :columns="columns"
              :data-source="dataLists"
              :rowKey="(record, index) => record.id"
              :loading="isLoading"
              :pagination="false"
              :scroll="{ x: '100%' }"
            >
            </a-table>
            <div class="footer-pagination">
              <a-pagination
                v-model="currentPage"
                :total="102"
                show-less-items
                @change="paginationChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/public/Header.vue";
import axios from "@/utils/request";

export default {
  filters: {
    filterStaus(status) {
      if (status == "new") {
        return "Unpaid";
      } else if (status == "paid") {
        return "Paid";
      } else {
        return "Void";
      }
    },
  },
  components: {
    Header,
    // Modal
  },
  data() {
    let columns = [
      {
        title: "No",
        dataIndex: "uname",
        width: 40,
        align: "center",
      },
      {
        title: "Date Initiate",
        dataIndex: "plat_list",
        width: 140,
        align: "center",
      },
      {
        title: "Advertiser",
        dataIndex: "Date",
        width: 140,
        align: "center",
      },
      {
        title: "LID",
        dataIndex: "Date",
        width: 140,
        align: "center",
      },
      {
        title: "Order No",
        dataIndex: "Date",
        width: 140,
        align: "center",
      },
      {
        title: "UID(optional)",
        dataIndex: "Date",
        width: 140,
        align: "center",
      },
      {
        title: "Order Amount",
        dataIndex: "Date",
        width: 140,
        align: "center",
      },
      {
        title: "Currency",
        dataIndex: "Date",
        width: 140,
        align: "center",
      },
      {
        title: "Reason To Submit",
        dataIndex: "Date",
        width: 140,
        align: "center",
      },
      {
        title: "Comment",
        dataIndex: "Date",
        width: 140,
        align: "center",
      },
      {
        title: "Submit Time",
        dataIndex: "Date",
        width: 140,
        align: "center",
      },
      {
        title: "Status",
        dataIndex: "Date",
        width: 140,
        align: "center",
      },
      {
        title: "Result Note",
        dataIndex: "Date",
        width: 140,
        align: "center",
      },
      {
        title: "Payment ID",
        dataIndex: "Date",
        width: 140,
        align: "center",
      },
      {
        title: "Operation",
        dataIndex: "Date",
        width: 140,
        align: "center",
      },
    ];
    return {
      columns,
      dataLists: [
        {
          uname: "xxxxq",
          plat_list: "xxxxq",
          country: "xxxxq",
          contents_total: "contents_total",
          followers: "2020-11-28",
          status: "Verifying",
        },
        {
          uname: "xxxxq",
          plat_list: "xxxxq",
          country: "xxxxq",
          contents_total: "contents_total",
          followers: "2020-11-28",
          status: "Approved",
        },
        {
          uname: "xxxxq",
          plat_list: "xxxxq",
          country: "xxxxq",
          contents_total: "contents_total",
          followers: "2020-11-28",
          status: "Rejected",
        },
      ],
      currentPage: 1,
      isLoading: false,
    };
  },
  mounted() {},
  methods: {
    // 切换下一页
    paginationChange(page, pageSize) {
      //   this.currentPage = page;
      //   this.getDataList();
    },
  },
  beforeDestroy() {
    if (this.onWindowResize) {
      window.onresize = null;
    }
  },
};
</script>

<style lang="less" scoped>
.flowrep {
  .headers {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 0.8571rem;
  }
  .content {
    .comm {
      &-header {
        flex-direction: column;
      }
    }
    .comm-header-left {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      align-items: flex-start;
      > div,
      > span {
        margin-right: 1.7143rem;
        margin-bottom: 1rem;
      }
      .header-input {
        width: 280px;
        display: flex;
        align-items: center;
        > span {
          width: 7.1429rem;
          margin-right: 0.8571rem;
        }
      }
      .ant-input {
        height: 2.8571rem;
      }
    }
    .comm-header-rigth {
      margin-top: 0.7143rem;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      > button {
        margin-left: 1.1429rem;
      }
    }
    .first-row-1 {
      text-align: left;
      padding-left: 2.1429rem;
    }
    .first-row-2 {
      text-align: left;
      padding-left: 1.4286rem;
    }
    .first-row-3 {
      text-align: left;
      padding-left: 2.1429rem;
    }
    .first-row-4 {
      text-align: left;
      padding-left: 2.1429rem;
    }
  }
  //   /deep/ .ant-table-thead > tr > th {
  //     border: 1px solid #fff;
  //     font-size: 0.9286rem;
  //   }
  //   /deep/ .ant-table-thead > tr:first-child > th:first-child,
  //   /deep/ .ant-table-thead > tr:last-child > th:last-child {
  //     border-left: none;
  //   }
  /deep/ .ant-select {
    width: 14.2857rem;
  }
  /deep/.ant-calendar-picker-input.ant-input {
    height: 2.8571rem;
    width: 260px;
  }
}
</style>
