<template>
  <div
    class="nav-header"
    :style="{ backgroundColor: isLogin && isChecked ? '#F2F9FF' : '#fff' }"
  >
    <div class="wrap">
      <div class="logo" @click="gohome">
        <img
          v-if="showLogo"
          src="@/assets/home/logo.png"
          style="margin-right:12px;"
        />
        <img src="@/assets/lplogo.png" alt />
      </div>
      <div class="account-right" ref="account-right" v-if="isLogin && userName">
        <a-popover
          placement="bottom"
          trigger="hover"
          overlayClassName="account-dropdown"
          :getPopupContainer="() => $refs['account-right']"
        >
          <template slot="content">
            <template
              v-if="merchantInfo && Object.keys(merchantInfo).length > 0"
            >
              <p class="row">{{ merchantInfo.sitename }}</p>
              <p class="row">ID: {{ merchantInfo.id }}</p>
              <p class="row">Balance: ${{ merchantInfo.balance_amount }}</p>
            </template>
            <p class="logout" @click="logout">LOGOUT</p>
          </template>
          <span style="cursor: pointer">
            {{ userName }}
            <a-icon type="caret-down" class="c-down" style="color: #333" />
          </span>
        </a-popover>
        <div
          class="ticket-icon"
          @click="goTicket"
          v-if="$store.state.accountDetails.merchant"
        >
          <!-- <img src="@/assets/ticket-icon.svg" alt class="message-icon" /> -->
          Help
        </div>
        <router-link
          :to="{ name: 'message' }"
          v-if="$store.state.accountDetails.merchant"
          class="msg-list"
        >
          <img
            src="@/assets/header-message-black.png"
            alt
            class="message-icon"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import CONFIG from "@/utils/config";

export default {
  name: "nav-header",
  props: {
    message: {
      type: Boolean,
      default: true,
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showAccountInfo: true,
    };
  },
  computed: {
    isLogin() {
      return Object.keys(this.$store.state.accountInfo).length > 0;
    },
    isChecked() {
      return (
        Object.keys(this.$store.state.accountInfo).length > 0 &&
        this.$store.state.accountInfo.apply_step == 100
      );
    },
    userName() {
      return (
        this.$store.state.accountDetails.uname ||
        this.$store.state.accountInfo.uname
      );
    },
    merchantInfo() {
      return (
        this.$store.state.accountDetails.merchant ||
        this.$store.state.accountInfo.merchant_info
      );
    },
  },
  methods: {
    goTicket() {
      this.$cookies.set(
        "auth_token",
        this.$store.state.token,
        "1d",
        "/",
        ".partnerboost.com",
        true,
        "None"
      );
      window.location.href = CONFIG.WWW_HOST.replace("app", "www") + "/tickets";
    },
    logout() {
      this.$store.commit("clearToken");
      this.$router.push({ name: "login" });
    },
    gohome() {
      let path = this.$route.path;
      if (path.indexOf("/sign") > -1) {
        this.$store.commit("clearToken");
        this.$router.push({ name: "login" });
        return;
      } else if (this.$store.state.accountInfo.apply_step == 100) {
        this.$router.push({ name: "dashboard" });
      } else if (this.$store.state.accountInfo.apply_step == 6) {
        this.$router.replace({ name: "processing" });
      } else if (this.$store.state.accountInfo.apply_step == 5) {
        this.$router.replace({ name: "payment" });
      } else if (this.$store.state.accountInfo.apply_step == 4) {
        this.$router.replace({ name: "tracking_test" });
      } else if (this.$store.state.accountInfo.apply_step == 3) {
        this.$router.replace({ name: "media" });
      } else if (this.$store.state.accountInfo.apply_step == 2) {
        this.$router.replace({ name: "information" });
      } else if (this.$store.state.accountInfo.apply_step == 1) {
        this.$router.replace({ name: "commission" });
      } else {
        this.$router.replace("/");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.nav-header {
  width: 100%;
  min-width: 1200px;
  height: 60px;
  background: #f2f9ff;

  .wrap {
    width: 1200px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      color: #2898ff;
      font-family: "dtc-bold";
      cursor: pointer;

      img {
        height: 24px;
        padding: 0;
      }
    }

    .account-right {
      color: #333;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      font-size: 1.1429rem;

      > * {
        margin-left: 15px;
      }

      /deep/ .account-dropdown {
        .ant-popover-arrow {
          display: none;
        }

        .ant-popover-inner {
          background: #f2f9ff;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
          border-radius: 8px;
        }

        .ant-popover-inner-content {
          .row {
            color: #333;
            line-height: 26px;
            height: 26px;
            vertical-align: middle;
            box-sizing: border-box;
            margin-bottom: 0;
            font-size: 1rem;
            white-space: nowrap;
            width: 140px;
          }

          .logout {
            text-align: center;
            height: 15px;
            color: #2898ff;
            line-height: 15px;
            margin-bottom: 0;
            font-size: 0.8571rem;
            margin-top: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.message-icon,
.logo {
  cursor: pointer;
}

.msg-list {
  height: 20px;
  width: 20px;

  img {
    display: block;
  }
}

.message-icon {
  width: 20px;
  height: 20px;
}

.ticket-icon {
  cursor: pointer;
}
</style>
