<template>
  <div class="flowrep">
    <Header collection="Reporting"></Header>
    <div class="container">
      <div class="headers"></div>
      <div class="content">
        <div class="comm-tables">
          <div class="comm-header">
            <div class="comm-header-left">
              <a-select
                allowClear
                placeholder="Performance by"
                v-model="groupby"
              >
                <a-select-option value="dayly"> Daily </a-select-option>
                <a-select-option value="monthly"> Monthly </a-select-option>
                <a-select-option value="yearly"> Yearly </a-select-option>
                <a-select-option value="mid"> Brand </a-select-option>
                <a-select-option value="mediumid"> Channel </a-select-option>
              </a-select>
              <a-range-picker separator="-" @change="onDateChange" />
              <div class="header-input">
                <span>Brand:</span>
                <a-auto-complete
                  class="form-location"
                  style="width: 200px"
                  placeholder="Brand Name"
                  @select="onAdverSelect"
                  @search="onAdverSearch"
                  v-model="m_id"
                >
                  <template slot="dataSource">
                    <a-select-option
                      v-for="item in adverList"
                      :key="item.m_id"
                      :text="item.m_id"
                    >
                      {{ item.m_id }}
                    </a-select-option>
                  </template></a-auto-complete
                >
              </div>
              <a-select allowClear placeholder="Channels" v-model="site_id">
                <a-select-option
                  :value="item.id"
                  v-for="item in channels"
                  :key="`sites-${item.id}`"
                >
                  {{ item.title }} ({{ item.medium_id }})
                </a-select-option>
                <!-- <a-select-option value="Female"> Female </a-select-option>
                <a-select-option value="All"> All </a-select-option> -->
              </a-select>
            </div>
            <div class="comm-header-rigth">
              <a-button type="primary" @click="getDataList">Search</a-button>
              <a-button type="primary" @click="toDownload"
                >Export Excel</a-button
              >
            </div>
          </div>
          <div class="comm-list">
            <a-table
              class="comtable"
              :columns="columns"
              :data-source="dataLists"
              :rowKey="(record, index) => index"
              :loading="isLoading"
              :pagination="false"
              :scroll="{ x: '100%' }"
              @change="handleTableChange"
            >
              <div slot="EPCTitle">
                <a-popover>
                  <template slot="content">
                    Earnings per 100 clicks
                  </template>
                  <div
                    style="white-space: nowrap;display: flex;align-items:center;"
                  >
                    EPC
                    <img
                      class="question-img"
                      src="@/assets/question.png"
                      alt=""
                    />
                  </div>
                </a-popover>
              </div>
              <div slot="pop-eo">
                No. of Approved Orders
              </div>
              <div slot="pop-er1">
                Order Success Rate
              </div>
              <div slot="pop-er">
                Success Rate
              </div>
              <div slot="pop-tsa">
                Total Sale Amount
              </div>
              <div slot="pop-tesa">
                Total Approved Sale Amount
              </div>
              <div slot="pop-tc">
                Total Commission
              </div>
              <div slot="pop-tec" class="tec-popover">
                Total Approved Commission
              </div>
              <div slot="pop-tec1">
                Total Estimated Commission
              </div>
              <div slot="pop-tcc">
                Total Confirmed Commission
              </div>
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/public/Header.vue";
import axios from "@/utils/request";
import apis from "@/api/const";

export default {
  components: {
    Header,
  },
  data() {
    let columns = [
      {
        title: () => {
          let res = "Date";
          switch (this.groupby) {
            case "mid":
              res = "Brand";
              break;
            case "mediumid":
              res = "Channel";
              break;
            default:
              break;
          }
          return res;
        },
        dataIndex: "date",
        width: 90,
        align: "center",
        customRender: (text, row, index) => {
          if (index === 0) {
            return {
              children: <div class="first-row-1">Total</div>,
            };
          }
          return text;
        },
      },
      {
        title: "Visits",
        children: [
          {
            title: "Impressions",
            dataIndex: "views",
            width: 120,
            sorter: true,
            sortOrder: false,
          },
          {
            title: "Clicks",
            dataIndex: "total_click",
            width: 80,
            sorter: true,
            sortOrder: false,
          },
          {
            dataIndex: "epc",
            width: 70,
            sorter: true,
            sortOrder: false,

            slots: { title: "EPCTitle" },
            customRender: (text) => this.toThousandsAndFixed2(text),
          },
        ],
      },
      {
        title: "CPS (Sales)",
        children: [
          {
            title: "No. of Orders",
            dataIndex: "cps_total_order",
            width: 120,
            sorter: true,
            sortOrder: false,
          },
          {
            dataIndex: "man_cps_total_effective_order",
            width: 180,
            slots: { title: "pop-eo" },
            sorter: true,
            sortOrder: false,
          },
          {
            dataIndex: "sum_order_effective_rate",
            width: 170,
            slots: { title: "pop-er1" },
            sorter: true,
            sortOrder: false,

            customRender: (text) => this.toThousandsAndFixed2(text) + "%",
          },
          {
            dataIndex: "cps_total_amount",
            width: 150,
            slots: { title: "pop-tsa" },
            sorter: true,
            sortOrder: false,
          },
          {
            dataIndex: "cps_total_effective_amount",
            width: 210,
            slots: { title: "pop-tesa" },
            sorter: true,
            sortOrder: false,

            customRender: (text) => this.toThousandsAndFixed2(text),
          },
          {
            dataIndex: "cps_total_effective_amount_rate",
            width: 130,
            slots: { title: "pop-er" },
            sorter: true,
            sortOrder: false,

            customRender: (text) => this.toThousandsAndFixed2(text) + "%",
          },
          {
            dataIndex: "cps_total_aff",
            width: 150,
            slots: { title: "pop-tc" },
            sorter: true,
            sortOrder: false,
          },
          {
            dataIndex: "cps_total_effective_aff",
            width: 210,
            slots: { title: "pop-tec" },
            sorter: true,
            sortOrder: false,

            customRender: (text) => this.toThousandsAndFixed2(text),
          },
          {
            dataIndex: "total_effective_aff_rate",
            width: 130,
            slots: { title: "pop-er" },
            sorter: true,
            sortOrder: false,

            customRender: (text) => this.toThousandsAndFixed2(text) + "%",
          },
        ],
      },
      {
        dataIndex: "pre_cps_total_aff",
        width: 210,
        slots: { title: "pop-tec1" },
        sorter: true,
        sortOrder: false,
      },
      {
        dataIndex: "pre_cps_total_effective_aff",
        width: 210,
        slots: { title: "pop-tcc" },
        sorter: true,
        sortOrder: false,

        customRender: (text) => this.toThousandsAndFixed2(text),
      },
    ];
    return {
      columns,
      channels: [],
      dataLists: [],
      realList: [],
      adverList: [],
      isLoading: false,
      groupby: undefined,
      export: "0",
      start_time: "",
      end_time: "",
      site_id: undefined,
      m_id: "",
      sort_field: "date",
      sort_desc: "asc",
    };
  },
  mounted() {
    this.getDataList();
    this.getChannelList();
  },
  methods: {
    getChannelList() {
      axios.get(apis.channel.index).then((resp) => {
        if (resp.code === "0200") {
          this.channels = resp.payload?.filter((e) => e.status == "1");
        }
      });
    },
    onAdverSearch(searchText) {
      let adData = {
        params: {
          keywords: searchText,
        },
      };
      axios.get(apis.reporting.advertisers, adData).then((res) => {
        // this.isLoading = false;
        if (res.code === "0200") {
          this.adverList = res.payload;
        } else {
          this.$message.error(res.msg || "");
        }
      });
    },
    onAdverSelect(value, option) {
      this.country_name = option.data.attrs.text;
    },
    onDateChange(value, dateString) {
      if (dateString.length === 2) {
        this.start_time = dateString[0];
        this.end_time = dateString[1];
      }
    },
    repeatMap(datas, sorter, order) {
      let list = datas.map((item) => {
        let result = item.children;
        if (result) {
          result = this.repeatMap(item.children, sorter, order);
        }
        return item?.dataIndex == sorter?.columnKey
          ? { ...item, children: result, sortOrder: order }
          : { ...item, children: result, sortOrder: false };
      });
      return list;
    },
    findOrder(sorter, order) {
      let columns = this.columns;
      let list = this.repeatMap(columns, sorter, order);
      this.columns = [...list];
      console.log(list);
    },
    setOrder(sorter) {
      let order = sorter?.order || false;
      this.findOrder(sorter, order);
    },
    handleTableChange(pagination, filters, sorter) {
      console.log(sorter);
      this.setOrder(sorter); //自定义排序

      if (sorter.column) {
        this.sort_field = sorter.columnKey;
        this.sort_desc = sorter?.order == "ascend" ? "asc" : "desc";
      } else {
        this.sort_field = "date";
        this.sort_desc = "asc";
      }
      this.getDataList();
    },
    // 获取列表
    getDataList() {
      this.isLoading = true;
      let configs = {
        params: {
          start_time: this.start_time,
          end_time: this.end_time,
          groupby: this.groupby || "",
          export: this.export,
          site_id: this.site_id,
          m_id: this.m_id,
          sort_field: this.sort_field,
          sort_desc: this.sort_desc,
        },
      };
      if (this.export === "1") {
        configs.responseType = "blob";
      }
      axios.get(apis.reporting.traffic, configs).then((res) => {
        this.isLoading = false;
        if (this.export === "1") {
          this.export = "0";
          if (!this.realList.length) {
            this.$message.error("There is no data to export");
          } else {
            const filename = `TrafficReporting-${this.start_time}-${this.end_time}.xlsx`;
            this.fileDownloader(res, filename);
          }
        } else {
          if (res.code === "0200") {
            this.realList = res.payload.list;
            if (res.payload.list.length > 0) {
              this.dataLists = [res.payload.total, ...res.payload.list];
            } else {
              this.dataLists = res.payload.list;
            }
          } else {
            this.$message.error(res.msg || "");
          }
        }
      });
    },
    toDownload() {
      this.export = "1";
      this.getDataList();
    },
  },
  beforeDestroy() {
    if (this.onWindowResize) {
      window.onresize = null;
    }
  },
};
</script>

<style lang="less" scoped>
.flowrep {
  .headers {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 0.8571rem;
  }
  .content {
    .comm {
      &-actions {
        display: flex;
        justify-content: center;
        img {
          width: 1.1429rem;
          height: 1.1429rem;
          cursor: pointer;
          &:hover {
            opacity: 0.85;
          }
          + img {
            margin-left: 1rem;
          }
        }
      }
      &-status {
        &-verifying {
          color: #999999;
          background: #eeeeee;
        }
        &-approved {
          color: #ffffff;
          background: #2ad57b;
        }
        &-rejected {
          color: #ffffff;
          background: #f64a47;
        }
        span {
          padding: 0.2857rem 0.5714rem;
          border-radius: 4px;
          font-size: 0.8571rem;
        }
      }
      &-platforms {
        display: flex;
        justify-content: center;
        img {
          width: 1.4286rem;
          height: 1.4286rem;
          margin-right: 0.2857rem;
        }
      }
    }
    .comm-header {
      display: flex;
    }
    .comm-header-left {
      flex: 1;
      display: flex;
      gap: 24px;
      flex-wrap: wrap;
      align-content: space-between;
      .header-input {
        display: flex;
        align-items: center;
        > span {
          margin-right: 0.8571rem;
          font-size: 1rem;
        }
      }
      .ant-input {
        height: 2.8571rem;
      }
    }
    .comm-header-rigth {
      width: 17.5rem;
      display: flex;
      > button {
        margin-right: 1.1429rem;
      }
    }
    .first-row-1 {
      text-align: left;
      padding-left: 2.1429rem;
    }
  }
  /deep/ .ant-table-thead > tr > th {
    border: 1px solid #fff;
    font-size: 0.9286rem;
    background-color: #f6f9ff;
  }
  /deep/ .ant-table-thead > tr:first-child > th:first-child,
  /deep/ .ant-table-thead > tr:last-child > th:last-child {
    border-left: none;
  }
  /deep/ .ant-select {
    width: 14.2857rem;
  }
  /deep/.ant-calendar-picker-input.ant-input {
    height: 2.8571rem;
    width: 260px;
  }
  /deep/ .ant-table-row:first-child {
    background: #f1f1f1;
  }
  /deep/.ant-select-search__field {
    height: 2.8571rem !important;
  }

  /deep/ .ant-table-content .ant-table-placeholder {
    border: 0;
  }
  /deep/ .ant-popover-inner {
    box-shadow: 0 0px 12px 0 rgb(102, 118, 186, 0.1);
  }

  /deep/ .ant-table-column-has-sorters:hover {
    background-color: #eef4ff !important;
  }

  /deep/ .ant-table-thead > tr:first-child > th:last-child {
    border-top-right-radius: unset;
  }

  /deep/ .ant-table-thead > tr > th .ant-table-column-sorter {
    padding-bottom: 5px;
  }
}
.question-img {
  height: 14px;
  margin-left: 2px;
}
</style>
