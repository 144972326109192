<template>
  <div class="make-depsit">
    <div class="row">
      <div class="card-auto-deposit">
        <div class="comming-soon" v-if="commingSoon">
          <p><span>Auto deposit</span></p>
          <div>
            <img src="@/assets/image-auto-deposit-comming.png" alt="" />
            <p>Coming Soon!</p>
            <p>We will launch the auto-deposit feature very soon.</p>
          </div>
        </div>
        <div class="empty-box" v-else-if="automaticInfoLoading">
          <Loading position="relative"></Loading>
        </div>
        <div class="deposit-unopen" v-else-if="!automaticInfo">
          <p><span>Auto deposit</span></p>
          <p>
            Make your program run smoother, and your life easier, by setting up
            Auto-Deposit!
          </p>
          <p>
            Current and prospective Affiliates love Auto-Deposit enabled
            programs. You have protections in place to help prevent your account
            from going offline - which means less disruption to Affiliate sales
            and commissions!
          </p>
          <a-button size="large" type="primary" @click="showAutomaticDeposit">
            Start Automatic Deposit
          </a-button>
        </div>
        <div
          v-else
          :class="
            `${automaticInfo.status} ${
              automaticInfo.last_fail_time > 0 ? 'deposit-fail' : 'deposit-open'
            }`
          "
        >
          <p>
            <span
              >Auto deposit
              <template v-if="automaticInfo.last_fail_time > 0"
                >failed!</template
              >
              <template v-else
                >is {{ automaticInfo.status | ucfirst }}</template
              >
            </span>
          </p>
          <p>Current Settings</p>
          <p>
            Low Balance Trigger is <span>${{ automaticInfo.low_balance }}</span>
          </p>
          <p>
            When triggered, deposit
            <span>${{ automaticInfo.recharge_balance }}</span> into my Merchant
            Account.
          </p>
          <p>
            Paypal account for automatic debit:
            <span v-if="automaticForm.paymentSourceInfo.account"
              >{{ automaticForm.paymentSourceInfo.account }}&nbsp;</span
            >
            <template v-else>Not set</template>
          </p>
          <a-button size="large" type="primary" @click="showAutomaticDeposit">
            Automatic Deposit Setting
          </a-button>
        </div>
      </div>

      <div class="card-hand-deposit">
        <div>Manual deposit</div>
        <div>
          <span class="title"> Amount </span>
          <a-input
            v-model="amount"
            prefix="$"
            size="large"
            @input="amountInput"
            placeholder="50 minimum"
          ></a-input>
        </div>
        <div style="margin-top: 20px">
          <span class="title"> Payment Method </span>
          <a-input placeholder="Paypal" disabled size="large"></a-input>
        </div>
        <div>
          <a-button
            size="large"
            type="primary"
            ghost
            @click="makeDepositSubmit"
          >
            Make Deposit
          </a-button>
        </div>
      </div>
    </div>

    <Modal
      :isVisible="isAutomaticDepositVisible"
      title="Auto Deposit"
      @close-popup="hideAutomaticDeposit"
      width="765px"
      :showBtn="false"
      class="auto-form"
    >
      <template #content>
        <a-spin :spinning="isAutomaticDepositLoading">
          <div class="auto-item">
            <div class="title">Auto Deposit</div>
            <div class="item-content">
              <a-switch
                v-model="automaticForm.statusSwitch"
                @change="(val) => (automaticForm.status = val ? 'on' : 'off')"
              />
            </div>
          </div>
          <div class="auto-item">
            <div class="title">Low Balance Trigger</div>
            <div class="item-content">
              <a-input
                size="large"
                prefix="$"
                v-model="automaticForm.low_balance"
              ></a-input
              >($50 minimum)
            </div>
          </div>
          <div class="auto-item">
            <div class="title">When triggered, deposit</div>
            <div class="item-content">
              <a-input
                size="large"
                prefix="$"
                v-model="automaticForm.recharge_balance"
              ></a-input>
              <span style="color: #333">into my Merchant Account</span> ($50
              minimum deposit)
              <span class="more">
                <span>...</span>
                <span>
                  <span
                    >(when the auto-deposited amount is insuffcient to cover the
                    Total Spend, the recharge value will automatically adjusted
                    to maintain the minimum balance of the account)</span
                  >
                </span>
              </span>
            </div>
          </div>
          <div class="auto-item auto-btn">
            <a-button
              @click="modifyDepositAutomatic"
              size="large"
              type="primary"
              style="margin-left: auto"
              >&emsp;Update Setting&emsp;</a-button
            >
          </div>
          <div class="payment-source-info">
            <p>Set up a Paypal Account for Automatic Debit</p>
            <p>
              This paypal account will be charged to pay your Publisher
              commissions.
            </p>
            <p>
              account in use:
              <span>{{ automaticForm.paymentSourceInfo.account || "" }}</span>
              <a
                @click="clearSavedAutomaticInfo"
                class="a-icon"
                v-if="automaticForm.paymentSourceInfo.account"
              >
                <a-icon type="delete" />
              </a>
            </p>
            <div id="paypal-button"></div>
          </div>
        </a-spin>
      </template>
    </Modal>
    <Loading v-if="isLoading"></Loading>
  </div>
</template>
<script>
import axios from "@/utils/request";
import braintree from "braintree-web";
import Modal from "@/components/Modal.vue";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      commingSoon: true,
      isLoading: false,
      amount: 100,
      isAutomaticDepositVisible: false,
      isAutomaticDepositLoading: false,
      automaticInfo: null,
      automaticInfoLoading: true,
      automaticForm: {
        statusSwitch: true,
        status: "on",
        low_balance: 50,
        recharge_balance: 50,
        paymentSourceInfo: {
          type: null,
          account: null,
        },
      },
      myDeviceData: null,
      myPaymentInfo: null,
    };
  },
  mounted() {
    this.getAutomaticInfo();
  },
  methods: {
    amountInput(event) {
      let val = this.amount.match(/\d+\.?\d{0,2}/);
      if (val == null) {
        this.amount = "";
      } else if (val < 0) {
        this.amount = 0;
      } else if (val > 50000) {
        this.amount = 50000;
      } else {
        this.amount = val[0];
      }
      console.log(val);
      // this.amount = val;
    },
    hideAutomaticDeposit() {
      this.isAutomaticDepositVisible = false;
      this.updateAutomaticForm();
    },
    async showAutomaticDeposit() {
      let that = this;
      this.isAutomaticDepositVisible = true;
      this.isAutomaticDepositLoading = true;
      let clientInstance;
      this.$nextTick(async () => {
        const clientAuth = await axios
          .get("api.php?mod=pay&op=getBtClientToken")
          .then((res) => res.data)
          .catch((e) => {
            console.log(e);
          });
        braintree.client
          .create({
            authorization: clientAuth,
          })
          .then(function(_clientInstance) {
            clientInstance = _clientInstance;
            return braintree.dataCollector.create({
              client: clientInstance,
            });
          })
          .then(function(dataCollectorInstance) {
            // At this point, you should access the dataCollectorInstance.deviceData value and provide it
            // to your server, e.g. by injecting it into your form as a hidden input
            that.myDeviceData = dataCollectorInstance.deviceData;
            console.log(that.myDeviceData);
            // Create a PayPal Checkout component.
            return braintree.paypalCheckout.create({
              client: clientInstance,
            });
          })
          .then(function(paypalCheckoutInstance) {
            return paypalCheckoutInstance.loadPayPalSDK({
              vault: true,
            });
          })
          .then(function(paypalCheckoutInstance) {
            return paypal
              .Buttons({
                fundingSource: paypal.FUNDING.PAYPAL,
                style: {
                  layout: "vertical",
                  color: "blue",
                  shape: "rect",
                  label: "paypal",
                },
                createBillingAgreement: function() {
                  return paypalCheckoutInstance.createPayment({
                    flow: "vault",
                    billingAgreementDescription: "Your agreement description",
                    enableShippingAddress: false,
                  });
                },

                onApprove: function(data, actions) {
                  return paypalCheckoutInstance
                    .tokenizePayment(data)
                    .then(function(payload) {
                      // Submit `payload.nonce` to your server
                      that.myPaymentInfo = payload;
                      that.automaticForm.paymentSourceInfo = {
                        type: "paypal",
                        account: payload.details.email,
                      };
                    });
                },

                onCancel: function(data) {
                  console.log(
                    "PayPal payment canceled",
                    JSON.stringify(data, 0, 2)
                  );
                },

                onError: function(err) {
                  console.error("PayPal error", err);
                },
              })
              .render("#paypal-button");
          })
          .then(function() {
            // The PayPal button will be rendered in an html element with the ID
            // `paypal-button`. This function will be called when the PayPal button
            // is set up and ready to be used
            that.isAutomaticDepositLoading = false;
          })
          .catch(function(err) {
            // Handle component creation error
          });
      });
    },
    clearSavedAutomaticInfo() {
      this.isLoading = true;
      axios
        .post("api_admin.php?a=account/modifyDepositAutomatic", {
          clear: true,
        })
        .then((res) => {
          if (res.code == 0) {
            this.$message.success("Clear successful");
            this.automaticInfo = res.data;
            this.updateAutomaticForm();
            this.hideAutomaticDeposit();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((e) => {
          console.log(e);
          this.$message.error("Clear failed");
        });
      this.isLoading = false;
    },
    modifyDepositAutomatic() {
      // 检查low_balance和recharge_balance都不能小于50
      let low_balance = parseFloat(this.automaticForm.low_balance);
      let recharge_balance = parseFloat(this.automaticForm.recharge_balance);
      if (isNaN(low_balance) || isNaN(recharge_balance)) {
        this.$message.error(
          "Low balance and recharge balance must be a number"
        );
        return;
      }
      if (low_balance < 50 || recharge_balance < 50) {
        this.$message.error(
          "Low balance and recharge balance must be greater than or equal to 50"
        );
        return;
      }
      // myPaymentInfo必须设置
      if (
        !this.myPaymentInfo &&
        !this.automaticForm.paymentSourceInfo.account
      ) {
        this.$message.error("Payment channel is not set");
        return;
      }
      this.isLoading = true;
      axios
        .post("api_admin.php?a=account/modifyDepositAutomatic", {
          data: {
            status: this.automaticForm.status,
            low_balance: low_balance,
            recharge_balance: recharge_balance,
            payment_source: "Paypal",
            payment_info: this.myPaymentInfo,
            device_info: this.myDeviceData,
          },
        })
        .then((res) => {
          this.isLoading = false;
          if (res.code == 0) {
            this.$message.success("Modify successful");
            this.automaticInfo = res.data;
            this.updateAutomaticForm();
            this.hideAutomaticDeposit();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((e) => {
          this.isLoading = false;
          console.log(e);
          this.$message.error("Modify failed");
        });
    },
    updateAutomaticForm() {
      if (this.automaticInfo) {
        this.automaticForm = {
          statusSwitch: this.automaticInfo.status == "on",
          status: this.automaticInfo.status,
          low_balance: this.automaticInfo.low_balance,
          recharge_balance: this.automaticInfo.recharge_balance,
          paymentSourceInfo: {
            type: this.automaticInfo.payment_source,
            account: this.automaticInfo.payment_account,
          },
        };
      } else {
        this.automaticForm = {
          statusSwitch: true,
          status: "on",
          low_balance: 50,
          recharge_balance: 50,
          paymentSourceInfo: {
            type: null,
            account: null,
          },
        };
      }
    },
    getAutomaticInfo() {
      this.automaticInfoLoading = true;
      axios.get("api_admin.php?a=account/automatic").then((res) => {
        this.automaticInfo = res.data;
        this.updateAutomaticForm();
        this.automaticInfoLoading = false;
      });
    },
    makeDepositSubmit() {
      if (isNaN(parseFloat(this.amount)) || parseFloat(this.amount) <= 0) {
        this.$message.error("Amount must be a number and greater than 0");
        return;
      }
      if (parseFloat(this.amount) < 10) {
        this.$message.error("Amount must be greater than 10");
        return;
      }
      this.isLoading = true;
      axios
        .post("/api_admin.php?a=account/paymentToken", {
          amount: this.amount,
        })
        .then((res) => {
          if (res.code === 0) {
            this._createDeposit(res.data);
          } else {
            this.isLoading = false;
            this.$message.error(res.msg || "");
          }
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    _createDeposit(token) {
      axios
        .post("/api_admin.php?a=account/createDeposit", {
          amount: this.amount,
          token: token,
        })
        .then((res) => {
          if (res.code === 0) {
            window.location.href = res.data.payment_url;
          } else {
            this.isLoading = false;
            this.$message.error(res.msg || "");
          }
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.row {
  position: relative;
  top: -60px;

  .card-auto-deposit {
    width: calc(50% - 30px);
    float: left;
    height: 275px;
    box-sizing: border-box;
    border-radius: 12px;
    overflow: hidden;

    .empty-box {
      height: 275px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      // background-color: #bbb;
    }

    .ant-btn {
      margin-top: auto;
      // margin-left: auto;
    }

    .comming-soon,
    .deposit-unopen,
    .deposit-open,
    .deposit-fail {
      width: 100%;
      height: 275px;
      box-sizing: border-box;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      p {
        color: #333;
        margin-bottom: 0.5em;
        font-size: 1.1429rem;

        span,
        a {
          font-family: dtc-bold;
          color: #2898ff;
        }

        &:first-child {
          font-size: 22px;
          font-family: dtc-bold;
          color: #2898ff;
          line-height: 28px;
          margin-bottom: 20px;
        }
      }
    }

    .comming-soon {
      background: #2898ff14;

      > div {
        text-align: center;
        width: 100%;

        img {
          height: 77px;
          margin-bottom: 30px;
        }

        p {
          color: #2898ff;

          &:nth-child(2) {
            font-family: dtc-bold;
            font-size: 1.2857rem;
          }

          &:nth-child(3) {
            font-size: 1.1429rem;
          }
        }
      }
    }

    .deposit-unopen {
      background: #2898ff14;

      p {
        margin-bottom: 1em;

        &:nth-child(2) {
          color: #2898ff;
        }

        &:nth-child(3) {
          font-size: 1rem;
        }
      }
    }

    .deposit-open {
      background: #2898ff14;

      p {
        &:nth-child(2) {
          font-family: dtc-bold;
        }
      }

      &.off {
        background: #f8f8f8;

        p:first-child {
          span {
            color: #333;
          }
        }
      }
    }

    .deposit-fail {
      background: #ff444408;

      p {
        &:first-child {
          span {
            color: #ff4444;
          }
        }

        &:nth-child(2) {
          font-family: dtc-bold;
        }
      }
    }
  }

  .card-hand-deposit {
    width: calc(50% - 30px);
    height: 275px;
    box-sizing: border-box;
    float: right;
    border-radius: 12px;
    overflow: hidden;
    background: #f8f8f8;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    div {
      color: #333;
      margin-bottom: 0.5em;
      font-size: 1.1429rem;
      height: 28px;
      line-height: 28px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &:first-child {
        font-size: 22px;
        font-family: dtc-bold;
        color: #333;
        margin-bottom: 20px;
      }

      > span {
        min-width: 200px;
      }

      /deep/.ant-input-lg {
        width: 200px;
      }

      &:last-child {
        margin-top: auto;
      }
    }
  }

  &::after {
    display: block;
    content: " ";
    width: 1px;
    height: 1px;
    clear: both;
  }
}

.auto-form {
  .auto-item {
    font-size: 1.1429rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;

    &.auto-btn {
      margin-top: 8px;
    }

    .title {
      width: 205px;
      font-family: dtc-bold;
      text-align: left;
    }

    .item-content {
      width: calc(100% - 205px);

      .ant-input-affix-wrapper {
        width: 116px;
        margin-right: 10px;
      }

      color: #999;

      .more {
        &:hover > span > span {
          display: block !important;
        }

        > span:first-of-type {
          display: inline-block;
          font-size: 1rem;
          line-height: 20px;
          color: #2898ff;
          cursor: default;
        }

        > span:last-child {
          position: absolute;
          height: 0px;
          width: 0px;
          display: inline-block;

          span {
            color: #333;
            background-color: #f1f8ff;
            position: absolute;
            right: -9px;
            top: 2.8571rem;
            padding: 8px 12px;
            width: 293px;
            word-wrap: break-word;
            box-sizing: border-box;
            border-radius: 4px;
            font-size: 0.8571rem;
            line-height: 15px;
            z-index: 1;
            font-weight: 400;
            height: fit-content;
            border: 1px solid #2898ff;
            display: none;

            &::before {
              display: block;
              content: " ";
              border: 1px solid #f1f8ff;
              border-top-color: #2898ff;
              border-right-color: #2898ff;
              background-color: #f1f8ff;
              height: 10px;
              box-sizing: border-box;
              width: 10px;
              border-bottom-left-radius: 1px;
              border-top-right-radius: 2px;
              position: absolute;
              top: -5px;
              right: 8px;
              transform: rotate(-45deg);
            }
          }
        }
      }
    }
  }

  .payment-source-info {
    width: 100%;
    height: 203px;
    background: #f8f8f8;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    #paypal-button {
      width: 200px;
      margin-left: auto;
      margin-top: auto;
    }

    p {
      color: #999;
      font-size: 1rem;

      .a-icon {
        margin-left: 10px;
      }

      span {
        color: #2898ff;
      }

      &:first-child {
        height: 20px;
        font-size: 1.1429rem;
        font-family: dtc-bold;
        color: #2898ff;
        line-height: 20px;
        margin-bottom: 12px;
      }

      &:nth-child(2) {
        margin-bottom: 30px;
      }
    }
  }
}
</style>
