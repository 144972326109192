<template>
  <div class="reportapi">
    <Header collection="Tools"></Header>
    <div class="container">
      <div class="headers">
        <div class="headers-title">Request Information</div>
        <ul class="headers-list">
          <li class="headers-item">
            <span>URL:</span>
            <div>
              https://www.linkprefer.com/api.php?mod=medium&op=user_click
            </div>
          </li>
          <li class="headers-item">
            <span>Return Format:</span>
            <div>Json/xml</div>
          </li>
          <li class="headers-item">
            <span>HTTP request method:</span>
            <div>GET/POST</div>
          </li>
          <CopyToken />
        </ul>
      </div>
      <div class="content">
        <div class="comm-tables">
          <div class="comm-header">
            <div class="comm-header-left">
              <div>Request Parameter</div>
            </div>
          </div>
          <div class="comm-list">
            <a-table
              class="comtable"
              :columns="columns"
              :data-source="dataList1"
              :rowKey="(record, index) => record.name"
              :loading="isLoading"
              :pagination="false"
            >
              <div class="api-title" slot="apititle" slot-scope="text">
                {{ text }}
              </div>
            </a-table>
          </div>
        </div>
        <div class="comm-tables">
          <div class="comm-header">
            <div class="comm-header-left">
              <div>Return Parameter</div>
            </div>
          </div>
          <div class="comm-list">
            <a-table
              class="comtable"
              :columns="columns"
              :data-source="dataList2"
              :rowKey="(record, index) => record.name"
              :loading="isLoading"
              :pagination="false"
            >
              <div class="api-title" slot="apititle" slot-scope="text">
                {{ text }}
              </div>
            </a-table>
          </div>
        </div>
        <div class="comm-tables">
          <div class="comm-header">
            <div class="comm-header-left">
              <div>Result Code</div>
            </div>
          </div>
          <div class="comm-list">
            <a-table
              class="comtable"
              :columns="columns"
              :data-source="dataList3"
              :rowKey="(record, index) => record.name"
              :loading="isLoading"
              :pagination="false"
            >
              <div class="api-title" slot="apititle" slot-scope="text">
                {{ text }}
              </div>
            </a-table>
          </div>
        </div>
        <div class="json-list">
          <div class="json-title">Return Format</div>
          <JsonPretty :data="jsonStr" />
          <XmlPretty :dataSource="xmlStr" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/public/Header.vue";
import axios from "@/utils/request";
import CopyToken from "@/components/CopyToken.vue";
import JsonPretty from "@/components/JsonPretty.vue";
import XmlPretty from "@/components/XmlPretty.vue";

export default {
  filters: {
    filterStaus(status) {
      if (status == "new") {
        return "Unpaid";
      } else if (status == "paid") {
        return "Paid";
      } else {
        return "Void";
      }
    },
  },
  components: {
    Header,
    CopyToken,
    JsonPretty,
    XmlPretty,
  },
  data() {
    let columns = [
      {
        title: "Name",
        dataIndex: "name",
        width: 160,
        align: "left",
        scopedSlots: { customRender: "apititle" },
      },
      {
        title: "Description",
        dataIndex: "description",
        width: 1110,
        align: "left",
      },
    ];
    return {
      columns,
      dataList1: [
        {
          name: "token",
          description:
            "The unique identifier of the website to obtain information {required}",
        },
        {
          name: "begin_date",
          description: "Begin date Format: 2015-01-01 10:00:00 {Required}",
        },
        {
          name: "end_date",
          description: "End date Format: 2015-01-01 10:10:00 {Required}",
        },
      ],
      dataList2: [
        {
          name: "total_page",
          description: "Total number of pages",
        },
        {
          name: "total_items",
          description: "Total number of clicks",
        },
        {
          name: "mid",
          description: "Merchant ID",
        },
        {
          name: "merchant_name",
          description: "Merchant name",
        },
        {
          name: "tagcode",
          description: "Custom tag",
        },
        {
          name: "click_time",
          description:
            "The time the customer clicks your link, in UTC+8 timezone",
        },
        {
          name: "click_ref",
          description: "A unique transaction reference identification",
        },
      ],
      dataList3: [
        {
          name: "200",
          description: "Success",
        },
        {
          name: "1000",
          description: "Missing token",
        },
        {
          name: "1001",
          description: "Invalid token",
        },
        {
          name: "1002",
          description: "Missing Param begin_date or end_date",
        },
        {
          name: "1003",
          description: "Begin Date > End Date",
        },
        {
          name: "1004",
          description: "The time interval cannot exceed 1 hour",
        },
        {
          name: "1005",
          description: "Only 15 requests in 60 seconds",
        },
      ],
      dataList4: [
        {
          name: "xxxxx",
          description: "xxxxxxx",
        },
      ],
      currentPage: 1,
      isLoading: false,
      jsonStr: {
        status: 200,
        msg: "Success",
        payload: {
          total: {
            total_page: 1,
            total_items: 45,
            per_page: 2000,
            nowpage: 1,
          },
          list: [
            {
              click_time: 1677637737,
              tagcode: "1cxgjw",
              click_ref: "lh_ctgasz",
              mcid: "lbcn",
              mid: "6531",
              merchant_name: "LUDWIG BECK",
            },
          ],
        },
      },
      xmlStr: `<?xml version="1.0" encoding="UTF-8"?>
<response>
  <status>200</status>
  <msg>Success</msg>
  <payload>
    <total>
      <total_page>1</total_page>
      <total_items>45</total_items>
      <per_page>2000</per_page>
      <nowpage>1</nowpage>
    </total>
    <list>
      <item>
        <click_time>1677637737</click_time>
        <tagcode>1cxgjw</tagcode>
        <click_ref>lh_ctgasz</click_ref>
        <mcid>lbcn</mcid>
        <mid>6531</mid>
        <merchant_name>LUDWIG BECK</merchant_name>
      </item>
    </list>
  </payload>
</response>
`,
    };
  },
  mounted() {},
  methods: {
    html2Escape(sHtml) {
      return sHtml.replace(/[<>&"]/g, function (c) {
        return { "<": "&lt;", ">": "&gt;", "&": "&amp;", '"': "&quot;" }[c];
      });
    },
    escape2Html(str) {
      var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
      return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function (all, t) {
        return arrEntities[t];
      });
    },
    onSelectChange() {},
    // 切换下一页
    paginationChange(page, pageSize) {
      //   this.currentPage = page;
      //   this.getDataList();
    },
  },
  beforeDestroy() {
    if (this.onWindowResize) {
      window.onresize = null;
    }
  },
};
</script>

<style lang="less" scoped>
.reportapi {
  .headers {
    display: flex;
    width: 100%;
    margin-top: 0.8571rem;
    flex-direction: column;
    background: #ffffff;
    border-radius: 16px;
    padding: 1.7143rem;
    &-title {
      font-size: 1.1429rem;
      color: #3762d6;
      font-family: "prefer-bold";
    }
    &-list {
    }
    &-item {
      margin-top: 16px;
      span {
        color: #999999;
      }
      div {
        margin-top: 3px;
        font-family: "prefer-bold";
      }
    }
  }
  .content {
    margin-top: 1.4286rem;
    .comm-tables {
      margin-top: 1.4286rem;
    }
    .comm-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      background: #fff;
      padding: 1.1429rem;
      border-radius: 16px 16px 0 0;
      flex-direction: column;
    }
    .comm-header-left {
      > div {
        font-size: 1.1429rem;
        color: #3762d6;
        font-family: "prefer-bold";
      }
    }
    .api-title {
      font-family: "prefer-bold";
    }
    .json-list {
      background: #ffffff;
      border-radius: 16px;
      padding: 1.7143rem;
      margin-top: 20px;
    }
    .json-title {
      color: #3762d6;
      font-size: 1.1429rem;
      font-family: "prefer-bold";
    }
  }
}

/deep/ .ant-table-tbody > tr > td {
  padding-left: 24px;
}
/deep/ .ant-table-thead > tr > th {
  padding-left: 24px;
}
.comm-header {
  padding-left: 24px !important;
}
</style>
