<template>
  <a-spin
    class="content-block detail-creative"
    :class="{ empty: false }"
    :spinning="isLoadingCount > 0"
  >
    <div class="content-block-title font-bold">Top Creatives</div>
    <div class="toggle">
      <div
        :class="{ active: timeToggle == 'week' }"
        @click="
          timeToggle = 'week';
          getData();
        "
      >
        Week
      </div>
      <div
        :class="{ active: timeToggle == 'month' }"
        @click="
          timeToggle = 'month';
          getData();
        "
      >
        Month
      </div>
    </div>
    <a-select
      class="toggle-select"
      placeholder="Select"
      v-model="sortby"
      size="large"
      @change="getData"
    >
      <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
      <a-select-option value="clicks">Sort By Clicks</a-select-option>
      <a-select-option value="aff_cmsn">Sort By Commissions</a-select-option>
    </a-select>
    <div class="content-list">
      <div class="card">
        <div class="font-bold">Top Banner</div>
        <a-empty v-if="!image.content" />
        <template v-else>
          <img :src="image.image_url" alt />
          <div>{{ image.title }}</div>
          <div>{{ sortby | ucfirst }}: {{ image.stats }}</div>
        </template>
      </div>
      <div class="card">
        <div class="font-bold">Top Text/Email</div>
        <a-empty v-if="!text.content" />
        <template v-else>
          <div class="preview">
            <span>{{ text.content }}</span>
          </div>
          <div>{{ text.title }}</div>
          <div>{{ sortby | ucfirst }}: {{ text.stats }}</div>
        </template>
      </div>
      <div class="card">
        <div class="font-bold">Top Coupon</div>
        <a-empty v-if="!coupon.content" />
        <template v-else>
          <div class="preview">
            <span>{{ coupon.content }}</span>
          </div>
          <div>{{ coupon.title }}</div>
          <div>{{ sortby | ucfirst }}: {{ coupon.stats }}</div>
        </template>
      </div>
    </div>
  </a-spin>
</template>

<script>
import axios from "@/utils/request";
export default {
  props: ["medium"],
  watch: {
    medium: {
      immediate: true,
      handler: function(newV, oldV) {
        if (newV && newV.apply_status == "adopt") {
          this.isApplied = true;
          this.getData();
        }
      },
    },
  },
  data() {
    return {
      sortby: "clicks",
      timeToggle: "week",
      isLoadingCount: 0,
      isApplied: false,
      image: {},
      text: {},
      coupon: {},
    };
  },
  methods: {
    getData() {
      if (!this.isApplied) {
        return;
      }
      this.getTypeData("TEXT");
      this.getTypeData("IMAGE").then((data) => {
        if (!data) {
          this.image = {};
          return;
        }
        this.image = {
          title: data.creative.title,
          content: data.creative.image_url,
          stats: data.stats[this.sortby ? "clicks" : "aff_cmsn"],
        };
      });
      this.getTypeData("COUPON").then((data) => {
        if (!data) {
          this.coupon = {};
          return;
        }
        this.coupon = {
          title: data.creative.title,
          content: data.creative.description,
          stats: data.stats[this.sortby ? "clicks" : "aff_cmsn"],
        };
      });
    },
    getTypeData(type) {
      this.isLoadingCount++;
      return axios
        .get("/api_admin.php?a=report/call", {
          params: {
            report_name: "dtcForTopCreatives",
            site_id: this.medium.site_id,
            start_date: moment()
              .add(-1, this.timeToggle)
              .format("MM/DD/YYYY"),
            end_date: moment().format("MM/DD/YYYY"),
            sort_field: this.sortby ? "clicks" : "aff_cmsn",
            type,
          },
        })
        .then((res) => {
          this.isLoadingCount--;
          if (res.code != 0) {
            return;
          }
          return res.data;
        })
        .catch((e) => {
          console.log(e);
          this.isLoadingCount--;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.toggle-select {
  margin-top: 7px;
  width: 220px;
  margin-left: 20px;
}
.toggle {
  width: 167px;
  height: 52px;
  background: #f2f9ff;
  border-radius: 6px;
  float: left;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  div {
    width: 50px;
    height: 32px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.active {
      width: 80px;
      cursor: default;
      background: #ffffff;
      box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
    }
  }
}

.content-list {
  margin-top: 32px;
  width: 100%;
  .card {
    width: 360px;
    float: left;
    height: 354px;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid #eee;
    margin-right: 39px;
    padding: 16px;
    /deep/ .ant-empty {
      height: 286px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
    > * {
      margin-bottom: 14px;
    }
    > div:first-child {
      height: 18px;
      font-size: 1rem;
      font-weight: 900;
      line-height: 18px;
    }
    > img:nth-child(2) {
      width: 326px;
      height: 204px;
      object-fit: contain;
      border-radius: 8px;
      border: 1px solid #eeeeee;
      box-sizing: border-box;
      margin-bottom: 18px;
    }
    > .preview {
      width: 326px;
      height: 204px;
      background: #f5f5f5;
      border-radius: 8px;
      border: 1px solid #eeeeee;
      box-sizing: border-box;
      padding: 15px 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      url {
        color: #2898ff;
        font-family: "dtc-bold";
      }
      margin-bottom: 18px;
    }
    > div:nth-child(3),
    > div:nth-child(4) {
      width: 326px;
      height: 19px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;
    }
  }

  &::after {
    display: block;
    content: " ";
    clear: both;
  }
}
</style>
