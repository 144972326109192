<template>
  <div
    class="camptext"
    :class="{ 'view-item': isEdit === '2' }"
    @click="viewItem"
  >
    <div
      v-if="isEdit === '1'"
      class="camptext-select"
      :class="isChecked ? '' : 'has-border'"
      @click.stop="checkItem"
    >
      <a-icon type="check-square" theme="filled" v-show="isChecked" />
    </div>
    <a-icon
      v-else-if="isEdit === '2'"
      class="to-delete"
      type="close"
      @click.stop="deleteText"
    />
    <p class="five-line">
      {{ text }}
    </p>
    <span class="one-line">{{ title }}</span>
    <Modal
      :isVisible="isShowEdit"
      :isShowCancel="canEdit"
      :isShowSubmit="canEdit"
      title="Add Creatives Text"
      @close-popup="hideEdit"
      width="920px"
      submitText="Save"
      cancelText="Discard"
      @submit="submitEdit"
    >
      <template #content>
        <a-spin :spinning="editLoading">
          <div class="camptadd">
            <p>Title</p>
            <a-input placeholder="Please enter title" v-model="editTitle" />
            <p>Text</p>
            <a-textarea
              :auto-size="{ minRows: 8, maxRows: 10 }"
              v-model="editText"
            />
          </div>
        </a-spin>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import axios from "@/utils/request";
import APIS from "@/api/const";

export default {
  components: { Modal },
  props: {
    cid: {
      type: String | Number,
      default: "0",
    },
    id: {
      type: [String, Number],
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    isEdit: {
      type: String,
      default: "",
    },
    toChecked: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    start_time: {
      type: String,
      default: "",
    },
    end_time: {
      type: String,
      default: "",
    },
    privacy: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
    // 能否编辑text
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  data() {
    return {
      isLoadingModal: false,
      isChecked: this.toChecked,
      isShowEdit: false,
      editTitle: "",
      editText: "",
      editLoading: false,
    };
  },
  watch: {},
  created() {},
  methods: {
    viewItem() {
      // 避免事件冒泡
      if (!this.isShowEdit) {
        if (this.isEdit === "2") {
          this.editTitle = this.title;
          this.editText = this.text;
          this.isShowEdit = true;
        }
      }
    },
    submitEdit() {
      if (!this.editText || !this.editTitle) {
        this.$message.error("Data can't be null");
        return;
      }
      const editData = {
        id: this.cid,
        content: this.editText,
        title: this.editTitle,
        end_time: this.end_time,
        start_time: this.start_time,
        privacy: this.privacy,
        link: this.link,
      };
      this.editLoading = true;
      axios.post(APIS.CREATIVE.EDIT, editData).then((res) => {
        this.editLoading = false;
        if (res.code === 0) {
          this.$emit("refreshStats");
          this.$emit("edited-text");
          this.isShowEdit = false;
          this.$message.success("Add Success");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    hideEdit() {
      this.isShowEdit = false;
    },
    deleteText() {
      if (this.isEditing) {
        this.$emit("delete-text", this.id);
      } else {
        this.$store.dispatch("removeCampaignCreatives", {
          type: 1,
          cid: this.cid,
        });
      }
    },
    checkItem() {
      this.isChecked = !this.isChecked;
      this.$emit(
        "text-check",
        {
          creative_id: this.cid,
          cid: this.cid,
          title: this.title,
          text: this.text,
        },
        this.isChecked
      );
    },
  },
};
</script>

<style lang="less" scoped>
.camptext {
  width: 238px;
  height: 144px;
  border-radius: 12px;
  border: 1px solid #e6e6e6;
  padding: 12px;
  position: relative;
  margin-right: 12px;
  margin-bottom: 16px;
  .to-delete {
    display: none;
  }
  &:hover {
    .to-delete {
      display: inherit;
    }
  }
  &.view-item {
    cursor: pointer;
  }
  &-select {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.has-border {
      border: 1px solid #aaa;
    }
    > i {
      color: #2898ff;
      font-size: 1.2857rem;
    }
  }
  > i {
    position: absolute;
    top: 4px;
    right: 4px;
    font-size: 1.2857rem;
    cursor: pointer;
    color: #666;
  }
  > p {
    color: #333333;
    line-height: 19px;
    padding-right: 10px;
    margin-bottom: 4px;
    height: 96px;
  }
  > span {
    color: #333333;
    font-size: 1.1429rem;
    font-weight: 600;
    border-top: 1px solid #e6e6e6;
  }
}
</style>
