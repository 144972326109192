<template>
  <a-modal
    v-model="showCampaignEdit"
    width="940px"
    :closable="false"
    title="Campaign"
    centered
    destroyOnClose
    :maskClosable="false"
  >
    <a-icon class="camp-edit-close" type="close" @click="hideCampaignEdit" />
    <a-spin :spinning="isLoading">
      <div class="campedit" v-if="item">
        <a-form
          :form="formCamEdit"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-form-item label="Campaign Name" labelAlign="left">
            <a-input
              :maxLength="100"
              v-decorator="[
                'name',
                {
                  initialValue: item.name,
                  rules: [
                    {
                      required: true,
                      message:
                        'Subject is a required field. Please enter a Campaign Name',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="Platforms" labelAlign="left">
            <div>
              <a-checkbox
                v-for="(pl, idx) in PLATFORMS"
                :key="`platforms-edit1-${idx}`"
                :defaultChecked="isPlatChecked(pl.name)"
                @change="platformsCheckChange(pl.name)"
                ><img class="platform-icon" :src="pl.icon" /> {{ pl.name }}
              </a-checkbox>
            </div>
            <a-input
              style="display: none"
              v-decorator="[
                'platforms',
                {
                  initialValue: item.platforms.join(','),
                  rules: [
                    {
                      required: true,
                      message:
                        'platforms is a required field. Please select at least one platform',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="Budget ($ USD) " labelAlign="left">
            <div class="campedit-amount">
              <a-input-number
                :min="0"
                :max="999999999"
                v-decorator="[
                  'amount_total',
                  {
                    initialValue: item.amount_total,
                    rules: [
                      {
                        required: true,
                        message:
                          'Budget is a required field. Please enter amount',
                      },
                    ],
                  },
                ]"
              />
            </div>
          </a-form-item>
          <a-form-item label="Duration" labelAlign="left">
            <a-range-picker
              :disabled-date="disabledDate"
              :default-value="[
                dayjs(new Date(item.start_time * 1000)).format('MM/DD/YYYY'),
                dayjs(new Date(item.end_time * 1000)).format('MM/DD/YYYY'),
              ]"
              v-decorator="['duration', rangeConfig]"
            />
          </a-form-item>
          <a-form-item label="Description" labelAlign="left">
            <a-textarea
              :auto-size="{ minRows: 3, maxRows: 5 }"
              v-decorator="[
                'description',
                {
                  initialValue: item.description,
                  rules: [
                    {
                      required: true,
                      message:
                        'Subject is a required field. Please enter description',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="Objective" labelAlign="left">
            <a-textarea
              :auto-size="{ minRows: 3, maxRows: 5 }"
              v-decorator="[
                'objective',
                {
                  initialValue: item.objective,
                  rules: [
                    {
                      required: true,
                      message:
                        'Subject is a required field. Please enter objective',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-form>
      </div>
    </a-spin>
    <template slot="footer">
      <a-button key="back" @click="hideCampaignEdit"> Discard </a-button>
      <a-button key="submit" type="primary" @click="submitCampaign">
        Save
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import Modal from "../../../../components/Modal.vue";
import APIS, { PLATFORMS } from "@/api/const";
import axios from "@/utils/request";

export default {
  components: { Modal },
  props: {
    item: {
      type: Object,
      default: () => null,
    },
    showCampaignEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formCamEdit: this.$form.createForm(this, { name: "form-campaign-edit" }),
      rangeConfig: {
        rules: [
          { type: "array", required: true, message: "Please select time!" },
        ],
      },
      PLATFORMS,
      platforms: [],
      isLoading: false,
    };
  },
  watch: {
    showCampaignEdit(newVal, oldVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.formCamEdit.setFieldsValue({
            duration: [
              this.dayjs(new Date(this.item.start_time * 1000)).format(
                "MM/DD/YYYY HH:mm:ss"
              ),
              this.dayjs(new Date(this.item.end_time * 1000)).format(
                "MM/DD/YYYY HH:mm:ss"
              ),
            ],
          });
          this.platforms = this.item.platforms;
          this.formCamEdit.setFieldsValue({
            platforms: this.item.platforms.join(","),
          });
        });
      }
    },
  },
  methods: {
    isPlatChecked(name) {
      let res = false;
      for (let pl of this.item.platforms) {
        if (pl === name) {
          res = true;
        }
      }
      return res;
    },
    // dayjs,
    disabledDate(current) {
      return current && current < this.dayjs();
    },
    // platforms
    platformsCheckChange(val) {
      if (_.indexOf(this.platforms, val) > -1) {
        _.remove(this.platforms, (item) => {
          return item === val;
        });
      } else {
        this.platforms.push(val);
      }
      this.formCamEdit.setFieldsValue({
        platforms: _.join(this.platforms, ","),
      });
      console.log("platforms：", JSON.stringify(this.platforms));
    },
    hideCampaignEdit() {
      this.$emit("on-closetips");
    },
    submitCampaign() {
      this.formCamEdit.validateFields((err, formDatas) => {
        if (err) {
          return;
        }
        if (this.isLoading) {
          return;
        }
        if (!formDatas.name.trim()) {
          this.$message.error("The name should not be white space");
          return;
        }
        this.isLoading = true;
        const rangeValue = formDatas["duration"];
        let start_time = "";
        let end_time = "";
        if (rangeValue) {
          start_time = parseInt(new Date(rangeValue[0]).getTime() / 1000);
          end_time = parseInt(new Date(rangeValue[1]).getTime() / 1000);
        } else {
          start_time = this.item.start_time;
          end_time = this.item.end_time;
        }
        const editData = {
          ...formDatas,
          start_time,
          end_time,
          platforms: this.platforms,
          module: "basic",
          id: this.item.id,
        };
        axios.post(APIS.CAMPAIGN.MODIFY, editData).then((res) => {
          this.isLoading = false;
          if (res.code == 0) {
            this.$message.success("Modify Success.");
            this.$emit("success-close");
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.campedit {
  &-amount {
    .ant-input-number {
      width: 100%;
    }
  }
}
.platform-icon {
  width: 28px;
  height: 28px;
}
.ant-checkbox-wrapper {
  margin-left: 8px;
  width: 156px;
  margin-bottom: 6px;
}
.camp-edit-close {
  position: absolute;
  right: 18px;
  top: 18px;
  font-size: 1.2857rem;
  cursor: pointer;
  &:hover {
    opacity: 0.85;
  }
}
</style>
