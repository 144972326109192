<template>
  <div class="campaign">
    <Header collection="Brands"></Header>
    <div class="campaign-search">
      <div class="campaign-top">
        <div class="search-left">
          <div class="search-btns">
            <a-select
              placeholder="Select site"
              :default-value="selectedSite.id"
              @change="siteSelected"
              class="campaign-orderby"
            >
              <a-select-option
                v-for="(site, index) in $store.state.siteInfo"
                :key="`site-${index}`"
                :value="site.id"
              >
                {{ site.title }} ({{ site.medium_id }})
              </a-select-option>
            </a-select>
            <SelectButton
              title="Verifying Type"
              v-model="verifyList"
              :options="verifyEnums"
              multiple
              clearAble
              @selected="getDataList"
            />
            <SelectButton
              title="Wechat App"
              v-model="wechatList"
              :options="wechatEnums"
              multiple
              clearAble
              @selected="getDataList"
            />
            <SelectButton
              title="Categories"
              v-model="categoryList"
              :options="categoryEnums"
              multiple
              clearAble
              @selected="getDataList"
            />
            <SelectButton
              title="Countries"
              v-model="countryList"
              :options="countryEnums"
              multiple
              clearAble
              @selected="getDataList"
            />
            <SelectButton
              title="Platform"
              v-model="platformList"
              :options="platformEnums"
              multiple
              clearAble
              @selected="getDataList"
            />
            <SelectButton
              title="Payout Type"
              v-model="payoutTypeList"
              :options="payoutTypeEnums"
              multiple
              clearAble
              @selected="getDataList"
            />
            <SelectButton
              v-if="shipEnums && shipEnums.length > 0"
              title="Shipping Destination"
              v-model="shipList"
              :options="shipEnums"
              multiple
              clearAble
              @selected="getDataList"
            />
            <SelectButton
              title="Cross-Platform Track"
              v-model="trackList"
              :options="trackEnums"
              multiple
              clearAble
              @selected="getDataList"
            />
            <a-select
              placeholder="Status"
              style="width: 11.5625rem"
              :getPopupContainer="
                (triggerNode) => {
                  return triggerNode.parentNode || document.body;
                }
              "
              v-model="status"
              @select="getDataList"
            >
              <a-select-option
                v-for="(item, index) in statuList"
                :key="`site-${index}`"
                :value="item.value"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
            <span
              v-show="showKeysLine"
              class="search-reset"
              @click="resetSelect"
              >Reset</span
            >
          </div>
        </div>
        <div class="search-right">
          <div class="search-input">
            <img src="@/assets/campaign/icon-search.svg" />
            <input
              type="text"
              placeholder="Search Brands"
              @keyup.enter="toSearch"
              v-model="searchKey"
            />
            <!-- <img
              class="img-close"
              src="@/assets/campaign/icon-close.svg"
              @click="closeSearch"
            /> -->
          </div>
          <!-- <div
            v-show="!isShowSearch"
            class="search-btn search-action"
            @click="showSearchKey"
          >
            <img src="@/assets/campaign/icon-search.svg" />
          </div> -->
        </div>
      </div>
      <div v-show="showKeysLine" class="search-keys">
        <a-tag
          v-for="(item, index) in verifyList"
          :key="`verifyList-${item.value}-${index}`"
          @close="closeFromTags(item, 'verifyList')"
          closable
        >
          {{ item.label }}
        </a-tag>
        <a-tag
          v-for="(item, index) in wechatList"
          :key="`wechatList-${item.value}-${index}`"
          @close="closeFromTags(item, 'wechatList')"
          closable
        >
          {{ item.label }}
        </a-tag>
        <a-tag
          v-for="(item, index) in shipList"
          :key="`shipList-${item.value}-${index}`"
          @close="closeFromTags(item, 'shipList')"
          closable
        >
          {{ item.label }}
        </a-tag>
        <a-tag
          v-for="(item, index) in trackList"
          :key="`trackList-${item.value}-${index}`"
          @close="closeFromTags(item, 'trackList')"
          closable
        >
          {{ item.label }}
        </a-tag>
        <a-tag
          v-for="(item, index) in categoryList"
          :key="`category-list-${item.value}-${index}`"
          @close="closeCategory(item)"
          closable
        >
          {{ item.label }}
        </a-tag>
        <a-tag
          v-for="(item, index) in countryList"
          :key="`country-list-${item.value}-${index}`"
          @close="closeCountry(item)"
          closable
        >
          {{ item.label }}
        </a-tag>
        <a-tag
          v-for="(item, index) in platformList"
          :key="`country-list-${item.value}-${index}`"
          @close="closePlatform(item)"
          closable
        >
          {{ item.label }}
        </a-tag>
        <a-tag
          v-for="(item, index) in payoutTypeList"
          :key="`country-list-${item.value}-${index}`"
          @close="closePayType(item)"
          closable
        >
          {{ item.label }}
        </a-tag>
      </div>
    </div>
    <a-spin
      v-if="!showChanelTips && !showInfoTips"
      :class="{ empty: dataLists.length == 0 }"
      :spinning="isLoading"
    >
      <div class="comm-list">
        <div class="comm-selected">
          <span class="comm-selected-text">
            <template v-if="hasSelected">
              {{ `${selectedRowKeys.length} Selected` }}
            </template>
          </span>
          <a-button
            type="primary"
            :disabled="!hasSelected"
            :loading="btnLoading"
            @click="batchJoin"
          >
            Join
          </a-button>
        </div>
        <a-table
          class="comtable"
          :columns="columns"
          :data-source="dataLists"
          :rowKey="(record, index) => record.m_id"
          :pagination="false"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
        >
          <div slot="customCommission">
            <a-tooltip>
              <template slot="title">
                When the base commission is displayed as "Rev. Share: 70%," it
                means that LinkPrefer will pay you 70% of the sales commission
                we receive from the merchant.
              </template>
              <div style="white-space: nowrap">
                Default Commission
                <img class="question-img" src="@/assets/question.png" alt="" />
              </div>
            </a-tooltip>
          </div>
          <div class="comm-platforms" slot="slot-brand" slot-scope="text, row">
            <img :src="text" />
            <span>{{ row.sitename }}</span>
          </div>
          <div slot="commission" slot-scope="text">
            <span v-if="text.name == 'Rev. Share'">{{ text.name }}:&nbsp;</span
            >{{ text.aff }}
          </div>
          <div class="comm-status" slot="status" slot-scope="text">
            <span class="comm-status-joined" v-show="text === 'Joined'">{{
              text
            }}</span>
            <span
              class="comm-status-avaliable"
              v-show="formatStatusText(text)"
              >{{ text }}</span
            >
            <span class="comm-status-pending" v-show="text === 'Pending'">{{
              text
            }}</span>
            <span class="comm-status-declined" v-show="text === 'Declined'">{{
              text
            }}</span>
          </div>
          <div class="comm-actions" slot="actions" slot-scope="text, row">
            <a-button
              type="primary"
              class="btn-link"
              v-if="formatStatusText(row.adv_apply_status)"
              @click="singleJoin(row)"
              >Join</a-button
            >
            <a-button @click="goToDetail(row)">View Links</a-button>
          </div>
        </a-table>
        <div class="footer-pagination">
          <a-pagination
            v-if="totalCount"
            :defaultPageSize="10"
            v-model="calcPage"
            :total="totalCount"
            show-less-items
            @change="paginationChange"
          />
        </div>
      </div>
    </a-spin>
    <Modal
      :isVisible="showChanelTips"
      :isShowCancel="false"
      title=""
      @close-popup="gotoDashboard"
      width="400px"
      submitText="OK"
      @submit="gotoDashboard"
    >
      <template #content>
        <p class="center">Please make sure your channel has been verified.</p>
      </template>
    </Modal>
    <Modal
      :isVisible="showInfoTips"
      :isShowCancel="false"
      title=""
      @close-popup="gotoBasicinfo"
      width="400px"
      submitText="OK"
      :isShowSubmit="false"
    >
      <template #content>
        <p class="center">
          If you want to apply brand, please click
          <a @click="handleRoute">here</a> to complete your basic info first.
        </p>
      </template>
    </Modal>
  </div>
</template>
<script>
import SelectButton from "@/components/public/SelectButton.vue";
import Header from "@/components/public/Header.vue";
import axios from "@/utils/request";
import apis from "@/api/const";
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Header,
    SelectButton,
    Modal,
  },
  data() {
    let columns = [
      {
        title: "Brand",
        dataIndex: "logo",
        width: 150,
        align: "center",
        scopedSlots: { customRender: "slot-brand" },
      },
      {
        title: "Payout Type",
        dataIndex: "payout_type",
        align: "center",
      },
      {
        title: "Category",
        dataIndex: "category",
        align: "center",
      },
      {
        dataIndex: "commission",
        align: "center",
        scopedSlots: { customRender: "commission" },
        slots: { title: "customCommission" },
      },
      {
        title: "End Time",
        dataIndex: "end_time",
        align: "center",
      },
      {
        title: "Banned Traffic",
        dataIndex: "banned_traffic",
        align: "center",
      },
      {
        title: "Verifying Type",
        dataIndex: "verify_type",
        align: "center",
      },
      {
        title: "Status",
        dataIndex: "adv_apply_status",
        align: "center",
        scopedSlots: { customRender: "status" },
      },
      {
        title: "Action",
        dataIndex: "",
        align: "center",
        width: 120,
        scopedSlots: { customRender: "actions" },
      },
    ];
    return {
      verifyEnums: [],
      verifyList: [],
      wechatEnums: [],
      wechatList: [],
      shipEnums: [],
      shipList: [],
      trackEnums: [],
      trackList: [],
      columns,
      dataLists: [],
      isLoading: false,
      totalCount: 0,
      currentPage: 1,
      calcPage: 1,
      page_size: 10,
      searchKey: "",
      isShowSearch: false,
      tableType: "grid",
      categoryList: [],
      countryList: [],
      platformList: [],
      payoutTypeList: [],
      selectedRowKeys: [], // Check here to configure the default column
      btnLoading: false,
      selectedSite:
        this.$store.state.siteInfo.length > 0
          ? this.$store.state.siteInfo[0]
          : {},
      categoryEnums: [],
      countryEnums: [],
      platformEnums: [],
      payoutTypeEnums: [],
      status: undefined,
      statuList: [],
      closeAllModal: true,
    };
  },
  watch: {
    "$route.query": function (nv) {
      this.calcPage = Number(nv.page) || this.currentPage;
      console.log(this.calcPage);
    },
  },
  created() {
    this.getCategory();
  },
  mounted() {
    this.calcPage = Number(this.$route.query.page) || 1;
    this.getDataList();
    this.getTypeList();
    const initSite =
      this.$store.state.siteInfo.length > 0
        ? this.$store.state.siteInfo[0]
        : {};
    this.$store.commit("setSelectedSite", initSite);
    let page = this.$route.query.page;
    if (page) {
      this.currentPage = page;
    }
  },
  computed: {
    showChanelTips() {
      return (
        this.$store.state.userInfo.has_channel_status == 0 && this.closeAllModal
      );
    },
    showInfoTips() {
      //   return false;
      // chanel弹窗优先级高
      if (this.$store.state.userInfo.has_channel_status == 0) {
        return false;
      }
      return this.$store.state.userInfo.is_check_account && this.closeAllModal;
    },
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
    showKeysLine() {
      const list = [
        ...this.verifyList,
        ...this.wechatList,
        ...this.shipList,
        ...this.trackList,
        ...this.categoryList,
        ...this.countryList,
        ...this.platformList,
        ...this.payoutTypeList,
      ];
      return list.length ? true : false;
    },
  },
  methods: {
    handleRoute() {
      this.closeAllModal = false;
      this.$nextTick(() => {
        this.$router.push({ path: "/account/" });
      });
    },
    closeSearch() {
      this.searchKey = "";
      this.isShowSearch = false;
    },
    getCategory() {
      axios
        .get("m/advertiser/c/index/a/merchantCategoryNum", {
          params: {
            site_id: this.selectedSite.id,
          },
        })
        .then((res) => {
          if (res.code === "0200") {
            this.categoryEnums = res.payload.map((item) => {
              return {
                value: item.id,
                label: item.title + `(${item.num})`,
              };
            });
          } else {
            this.$message.error(res.msg || "");
          }
        });
    },
    // 获取列表
    getTypeList() {
      axios.get(apis.brands.type_list, {}).then((res) => {
        if (res.code === "0200") {
          this.verifyEnums = res.payload.verify_type?.map((e) => ({
            label: e,
            value: e,
          }));
          this.wechatEnums = res.payload.webchat_app?.map((e) => ({
            label: e,
            value: e,
          }));
          this.shipEnums = res.payload.shipping_destination?.map((e) => ({
            label: e.country,
            value: e.country,
          }));
          this.trackEnums = res.payload.cross_platform_tracking?.map((e) => ({
            label: e,
            value: e,
          }));

          this.countryEnums = res.payload.country
            .filter((e) => !!e)
            .map((item) => {
              return {
                value: item.title,
                label: item.title,
              };
            });
          this.platformEnums = res.payload.platform.map((item) => {
            return {
              value: item,
              label: item,
            };
          });
          this.payoutTypeEnums = res.payload.payout_type.map((item) => {
            return {
              value: item,
              label: item,
            };
          });
          this.statuList = res.payload.status.map((item) => {
            return {
              value: item,
              name: item,
            };
          });
          //   this.sortList = res.payload.sort_by_map.map((item) => {
          //     return {
          //       value: item.name,
          //       name: item.value,
          //     };
          //   });
        } else {
          this.$message.error(res.msg || "");
        }
      });
    },
    gotoBasicinfo() {
      this.closeAllModal = false;
      setTimeout(() => {
        this.$router.push({
          name: "my_basicinfo",
        });
      }, 200);
    },
    gotoDashboard() {
      this.closeAllModal = false;
      setTimeout(() => {
        this.$router.push({
          name: "dashboard",
        });
      }, 200);
    },
    goToDetail(row) {
      this.$router.push({
        name: "brand_detail",
        params: {
          id: row.m_id,
          sid: this.selectedSite.id,
        },
        query: {
          page: this.calcPage,
        },
      });
    },
    // 获取列表
    getDataList() {
      this.isLoading = true;
      const { page } = this.$route.query;
      axios
        .get(apis.brands.index_search, {
          params: {
            verify_type: this.verifyList?.map((e) => e.value).join(","),
            webchat_app: this.wechatList?.map((e) => e.value).join(","),
            shipping_destination: this.shipList?.map((e) => e.value).join(","),
            cross_platform_tracking: this.trackList
              ?.map((e) => e.value)
              .join(","),
            category: this.categoryList
              .map((item) => {
                return item.value;
              })
              .join(","),
            country: this.countryList
              .map((item) => {
                return item.value;
              })
              .join(","),
            platform: this.platformList
              .map((item) => {
                return item.value;
              })
              .join(","),
            payout_type: this.payoutTypeList
              .map((item) => {
                return item.value;
              })
              .join(","),
            status: this.status || "",
            page_size: this.page_size,
            page: page || this.calcPage,
            keywords: this.searchKey,
            site_id: this.selectedSite.id,
          },
        })
        .then((res) => {
          this.isLoading = false;
          if (res.code === "0200") {
            this.dataLists = res.payload.list.map((item) => ({
              ...item,
            }));
            this.totalCount = parseInt(res.payload.pagination.total);
          } else {
            this.$message.error(res.msg || "");
          }
        });
    },
    batchJoin() {
      this.btnLoading = true;
      const joinData = new FormData();
      joinData.append("site_id", this.selectedSite.id);
      joinData.append("m_id", this.selectedRowKeys.join(","));
      axios.post(apis.brands.join_site_bacth, joinData).then((res) => {
        this.btnLoading = false;
        if (res.code == "0200") {
          this.getDataList();
          this.$message.success("Join Success.");
        } else {
          this.$message.error(res.msg);
        }
        this.isLoading = false;
      });
    },
    singleJoin(row) {
      const joinData = new FormData();
      joinData.append("site_id", this.selectedSite.id);
      joinData.append("m_id", row.m_id);
      axios.post(apis.brands.join_site, joinData).then((res) => {
        if (res.code == "0200") {
          this.getDataList();
          this.$message.success("Join Success.");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    orderBySelected() {},
    siteSelected(value) {
      this.selectedSite = _.find(this.$store.state.siteInfo, (site) => {
        return site.id === value;
      });
      this.getDataList();
      this.getCategory();
      this.$store.commit("setSelectedSite", this.selectedSite);
    },
    resetSelect() {
      this.verifyList = [];
      this.wechatList = [];
      this.shipList = [];
      this.trackList = [];
      this.categoryList = [];
      this.countryList = [];
      this.platformList = [];
      this.payoutTypeList = [];

      this.status = undefined;
    },
    switchTable(val) {
      this.tableType = val;
    },
    toSearch() {
      this.resetSelect();
      this.getDataList();
    },
    showSearchKey() {
      this.isShowSearch = true;
    },
    closeCountry(val) {
      const index = _.findIndex(this.countryList, (item) => {
        return item.value === val.value;
      });
      this.countryList.splice(index, 1);
      this.getDataList();
    },
    closeFromTags(val, key) {
      const index = _.findIndex(this?.[key], (item) => {
        return item.value === val.value;
      });
      this?.[key].splice(index, 1);
      this.getDataList();
    },
    closeCategory(val) {
      const index = _.findIndex(this.categoryList, (item) => {
        return item.value === val.value;
      });
      this.categoryList.splice(index, 1);
      this.getDataList();
    },
    closePlatform(val) {
      const index = _.findIndex(this.platformList, (item) => {
        return item.value === val.value;
      });
      this.platformList.splice(index, 1);
      this.getDataList();
    },
    closePayType(val) {
      const index = _.findIndex(this.payoutTypeList, (item) => {
        return item.value === val.value;
      });
      this.payoutTypeList.splice(index, 1);
      this.getDataList();
    },
    // 切换下一页
    paginationChange(page, pageSize) {
      this.currentPage = page;
      this.$router.replace({ query: { page } });
      this.getDataList();
    },
  },
};
</script>

<style lang="less" scoped>
.campaign {
  .comm-list {
    border-radius: 16px;
  }
  &-search {
    background: #ffffff;
    border-radius: 16px;
    padding: 20px;
    margin-top: 12px;
    margin-bottom: 20px;
    .search-left {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .search-reset {
        font-size: 1rem;
        color: #666666;
        cursor: pointer;
      }

      .campaign-orderby {
        width: 13.5714rem;
      }
      .search-btns {
        .btns {
          padding: 0 12px;
        }
        > div {
          margin-right: 12px;
          margin-bottom: 12px;
        }
      }
    }
    .search-keys {
      margin-top: 12px;
      /deep/ .ant-tag {
        font-size: 0.8571rem;
        height: 24px;
        line-height: 24px;
        color: #3762d6;
        background: #f6f9ff;
        border-radius: 11px;
        border: none;
        margin-bottom: 5px;
        margin-right: 12px;
        > i {
          color: #3762d6;
        }
      }
    }
    .search-right {
      display: flex;
      justify-content: flex-end;
      .search-input {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #333;
        height: 2.8571rem;
        transition: all 0.3s;
        img {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
        .img-close {
          width: 10px;
          height: 10px;
        }
        input {
          border: none;
          outline: none;
          width: 260px;
          padding-left: 5px;
          &::placeholder {
            color: #bfbfbf;
          }
        }
      }
    }
    .search-btn {
      height: 2.8571rem;
      line-height: 2.8571rem;
      margin-right: 20px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  &-top {
    display: flex;
    justify-content: space-between;
  }
  .comm {
    &-selected {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 16px;
      &-text {
        margin-right: 16px;
      }
    }
    &-actions {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      > button {
        width: 100px;
      }
      .btn-link {
        margin-bottom: 12px;
      }
    }
    &-status {
      &-joined {
        background: #28acff;
      }
      &-avaliable {
        background: #15dcb3;
      }
      &-pending {
        background: #ffc55a;
      }
      &-declined {
        background: #f64a47;
      }
      span {
        padding: 0.2857rem 0.5714rem;
        border-radius: 4px;
        font-size: 1rem;
        color: #fff;
      }
    }
    &-platforms {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      img {
        width: 8.5714rem;
        height: 68px;
        object-fit: contain;
        border-radius: 8px;
      }
      span {
        font-size: 0.8571rem;
        margin-top: 8px;
      }
    }
  }
  &-rlist {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  &-glist {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  /deep/ .ant-select-selection {
    height: 2.8571rem;
  }
  /deep/ .ant-checkbox {
    width: 18px;
    height: 18px;
  }
}
/deep/.ant-select-selection {
  height: 2.8571rem;
}
/deep/.ant-select {
  min-width: 11.4286rem;
  margin-right: 12px;
}

/deep/ .comm-selected {
  .ant-button {
    border: 0;
  }
}
.question-img {
  height: 14px;
  margin-left: 2px;
}
</style>
