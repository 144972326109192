<template>
  <div class="mybasic">
    <Header collection="My Account"></Header>
    <div class="container">
      <div class="headers"></div>
      <div class="content">
        <div class="content-title">Login Info</div>
        <ul class="info-list">
          <!-- <li class="info-item">
            <span class="info-title">Username</span>
            <div class="info-content">
              <a-input class="info-input" v-if="isEditName"></a-input>
              <span v-else class="info-text">ashrjdklopwhdkl</span>
            </div>
          </li> -->
          <li class="info-item">
            <span class="info-title">Login Email</span>
            <div class="info-content">
              <a-input
                placeholder="Enter your login email"
                v-if="isEditEmail"
                v-model="basicInfo.email"
              ></a-input>
              <a-input
                v-if="isEditEmail"
                placeholder="Enter your login password"
                type="password"
                v-model="oriPwd"
              ></a-input>
              <span v-else class="info-text">{{ basicInfo.email }}</span>
              <span
                v-if="!isEditEmail"
                class="info-edit"
                @click="isEditEmail = true"
                >Edit</span
              >
            </div>
          </li>
          <li class="info-item">
            <span class="info-title center">Login Password</span>
            <div class="info-content pwd-content center">
              <a-input :type="pwdType" v-if="isEditPwd"></a-input>
              <img
                v-if="isEditPwd && isEditShowPwd"
                src="@/assets/public/icon-eye-open.svg"
                @click="editShowPwd(1)"
              />
              <img
                v-if="isEditPwd && !isEditShowPwd"
                src="@/assets/public/icon-eye-close.svg"
                @click="editShowPwd(0)"
              />
              <span v-if="!isEditPwd" class="info-pwd">
                <!-- <span v-if="isShowPwd">ashrjdklopwhdkl</span> -->
                <span>* * * * * * * * * *</span>
                <!-- <img
                  v-if="isShowPwd"
                  src="@/assets/public/icon-eye-open.svg"
                  @click="isShowPwd = false"
                />
                <img
                  v-else
                  src="@/assets/public/icon-eye-close.svg"
                  @click="isShowPwd = true"
                /> -->
              </span>
              <span v-if="!isEditPwd" class="info-edit center" @click="goPwd"
                >Edit</span
              >
            </div>
          </li>
        </ul>
        <div class="info-actions">
          <a-button
            v-if="isShowSave"
            type="primary"
            class="info-save"
            :loading="btnLoading"
            @click="updateEmail"
            >Save</a-button
          >
          <a-button v-if="isShowSave" @click="cancelSave" class="info-save"
            >Cancel</a-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/public/Header.vue";
import axios from "@/utils/request";
import apis from "@/api/const";

export default {
  components: {
    Header,
  },
  data() {
    return {
      formInfo: this.$form.createForm(this, { name: "form-info" }),
      showList: this.$store.state.commonConfig.countries,
      isEditName: false,
      isEditEmail: false,
      isEditPwd: false,
      isShowPwd: false,
      isEditShowPwd: false,
      pwdType: "password",
      basicInfo: {},
      oriPwd: "",
      btnLoading: false,
    };
  },
  mounted() {
    this.getUserInfo();
  },
  computed: {
    isShowSave() {
      return this.isEditName || this.isEditEmail || this.isEditPwd;
    },
  },
  methods: {
    updateEmail() {
      this.btnLoading = true;
      const upData = {
        email: this.basicInfo.email,
        password: this.oriPwd,
        formData: true,
      };
      axios.post(apis.my.change_email, upData).then((res) => {
        this.btnLoading = false;
        if (res.code == "0200") {
          this.cancelSave();
          this.$message.success("Update Success.");
          this.getUserInfo();
        } else {
          this.$message.error(res.msg);
        }
        this.isLoading = false;
      });
    },
    getUserInfo() {
      this.isLoading = true;
      axios.get(apis.my.user_info, {}).then((res) => {
        this.isLoading = false;
        if (res.code === "0200") {
          this.basicInfo = res.payload;
        } else {
          this.$message.error(res.msg || "");
        }
      });
    },
    goPwd() {
      this.$router.push({
        name: "my_change_pwd",
      });
    },
    cancelSave() {
      this.isEditName = false;
      this.isEditEmail = false;
      this.isEditPwd = false;
    },
    editShowPwd(val) {
      if (val) {
        this.pwdType = "password";
        this.isEditShowPwd = false;
      } else {
        this.pwdType = "text";
        this.isEditShowPwd = true;
      }
    },
  },
  beforeDestroy() {
    if (this.onWindowResize) {
      window.onresize = null;
    }
  },
};
</script>

<style lang="less" scoped>
.mybasic {
  .headers {
  }
  .content {
    display: flex;
    justify-content: space-between;
    margin-top: 1.4286rem;
    background: #ffffff;
    box-shadow: 0px 0px 12px 0px rgba(102, 118, 186, 0.1);
    border-radius: 1.1429rem;
    flex-direction: column;
    padding: 1.7143rem 2.2857rem;
    &-title {
      font-size: 1.2857rem;
      color: #333333;
      font-family: "prefer-bold";
    }
    .info-list {
      margin-top: 1.4286rem;
      width: 660px;
    }
    .info-item {
      display: flex;
      min-height: 52px;
      .info-title {
        width: 130px;
        color: #999999;
      }
      .info-content {
        width: 470px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        > input {
          margin-bottom: 12px;
        }
        .info-edit {
          color: #3762d6;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .info-pwd {
        span {
        }
        img {
          margin-left: 20px;
          cursor: pointer;
          &:hover {
            opacity: 0.85;
          }
        }
      }
    }
    .info-actions {
      display: flex;
      margin-top: 32px;
      .info-save {
        width: 8.5714rem;
        margin-left: 32px;
      }
    }
    .pwd-content {
      position: relative;
      > img {
        position: absolute;
        right: 12px;
        top: 12px;
        cursor: pointer;
        &:hover {
          opacity: 0.85;
        }
      }
    }
    .center {
      display: flex;
      align-items: center;
    }
  }
  /deep/ .ant-form-item-no-colon {
    color: #999999;
  }
  /deep/ .ant-input {
    height: 2.8571rem;
    letter-spacing: 3px;
  }
}
</style>
