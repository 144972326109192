<template>
  <div class="loading-modal" :class="`${position}`">
    <div class="center" v-if="type == 'progress'">
      <a-progress :percent="percent | roundVal" status="active" />
    </div>
    <a-icon type="loading" style="font-size:3.4286rem" spin v-else />
    <!-- <a-icon type="loading-3-quarters" style="font-size:3.4286rem" spin /> -->
    <!-- <div class="anticon anticon-loading anticon-spin ant-spin-dot" v-else></div> -->
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    percent: {
      default: 0,
      type: Number,
    },
    type: {
      default: "loading",
      type: String,
    },
    position: {
      default: "fixed",
      type: String,
    },
  },
  filters: {
    roundVal(val) {
      return parseFloat(parseFloat(val).toFixed(2));
    },
  },
};
</script>

<style lang="less" scoped>
.loading-modal {
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;

  &.fixed {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  &.relative {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .center {
    width: 300px;
    max-width: 100%;
  }
}
</style>
