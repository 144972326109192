<template>
  <div class="right">
    <div class="right-title" style="margin-top: 8px;font-size:1.4286rem">
      Affiliate Links
    </div>
    <a-select
      placeholder="Please Select your Channel"
      :getPopupContainer="
        (triggerNode) => {
          return triggerNode.parentNode || document.body;
        }
      "
      :disabled="disabled"
      v-model="siteId"
    >
      <a-icon slot="suffixIcon" type="caret-down" />
      <a-select-option
        v-for="(site, index) in $store.state.siteInfo"
        :key="`site-${index}`"
        :value="site.id"
      >
        {{ site.title }} ({{ site.medium_id }})
      </a-select-option>
    </a-select>
    <a-input
      :disabled="disabled"
      placeholder="Target URL"
      v-model="siteUrl"
    ></a-input>
    <a-input :disabled="disabled" placeholder="u_id" v-model="uTag"></a-input>
    <a-button :disabled="disabled" type="primary" @click="getLink"
      >Get Links</a-button
    >
    <div class="right-links" v-show="showLinks">
      <div class="links-tile">
        <span :class="{ active: linkNum == 0 }" @click="swithcLink(0)"
          >Default</span
        >
        <!-- <span :class="{ active: linkNum == 1 }" @click="swithcLink(1)"
          >Weibo</span
        > -->
        <!-- <span :class="{ active: linkNum == 2 }" @click="swithcLink(2)"
          >Short URL</span
        > -->
      </div>
      <div v-if="linkNum == 0" class="links-text">
        {{ linkObj.link }}
      </div>
      <!-- <div v-else-if="linkNum == 1" class="links-text">
        {{ linkObj.weibo }}
      </div> -->
      <!-- <div v-else-if="linkNum == 2" class="links-text">
        {{ linkObj.short_link }}
      </div> -->
    </div>
    <Modal
      :isVisible="isShowInfo"
      :isShowCancel="false"
      title=""
      @close-popup="closeTips"
      width="400px"
      submitText="OK"
      :isShowSubmit="false"
    >
      <template #content>
        <p class="center">
          If you want to apply campaign, please click
          <router-link :to="`/account/`">here</router-link> to complete your
          basic info first.
        </p>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import axios from "@/utils/request";
import apis from "@/api/const";

export default {
  props: {
    site_url: {
      type: String,
      default: "",
    },
    mId: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Modal,
  },
  watch: {
    site_url(nv) {
      this.siteUrl = nv;
    },
  },
  computed: {
    showInfoTips() {
      return this.$store.state.userInfo.is_check_account;
    },
  },
  data() {
    return {
      siteId: undefined,
      siteUrl: this.site_url,
      uTag: "",
      showLinks: false,
      linkNum: 0,
      linkObj: {},
      isShowInfo: false,
    };
  },
  mounted() {},
  methods: {
    closeTips() {
      this.isShowInfo = false;
    },
    swithcLink(val) {
      this.linkNum = val;
    },
    // 获取链接
    getLink() {
      if (this.showInfoTips) {
        this.isShowInfo = true;
        return;
      }
      const linkData = {
        site_id: this.siteId,
        m_id: this.mId,
        campaign_id: this.$route.params.id,
        site_url: this.siteUrl,
        u_tag: this.uTag,
        is_short_link: 1,
        formData: true,
      };
      this.isLoading = true;
      axios.post(apis.common.get_camp_link, linkData).then((res) => {
        this.isLoading = false;
        if (res.code == "0200") {
          this.showLinks = true;
          this.linkObj = res.payload.link_list;
          this.$message.success("Get Link Success.");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.right {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 16px;
  padding: 16px;
  .right-title {
    font-family: "prefer-bold";
    font-size: 1.2857rem;
    margin-bottom: 4px;
  }
  > div,
  > input {
    margin-top: 12px;
  }
  > button {
    margin-top: 22px;
  }
  .right-links {
    margin-top: 20px;
    .links-tile {
      > span {
        margin-right: 12px;
        cursor: pointer;
        font-family: "prefer-bold";
        font-size: 1.1429rem;
        &:hover {
          opacity: 0.8;
        }
        &.active {
          color: #3762d6;
        }
      }
    }
    .links-text {
      margin-top: 12px;
      border-radius: 8px;
      border: 1px solid #e6e6e6;
      padding: 1.1429rem;
      min-height: 11.4286rem;
      word-break: break-all;
    }
  }
}
/deep/ .ant-input,
.ant-btn {
  height: 40px;
  line-height: 40px;
  border-radius: 8px;
}
/deep/ .ant-select-selection {
  height: 40px;
  line-height: 40px;
  border-radius: 8px;
}
</style>
