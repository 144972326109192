<template>
  <a-spin class="content-block" :class="{ empty: false }" :spinning="isLoading">
    <div class="content-block-title font-bold">Activicy Charts</div>
    <div class="left-nav font-bold">
      <div :class="{ active: navToggle == 'clicks' }" @click="toggleNav(0)">
        Clicks
      </div>
      <div
        :class="{ active: navToggle == 'commissions' }"
        @click="toggleNav(1)"
      >
        Commissions
      </div>
      <div :class="{ active: navToggle == 'gross' }" @click="toggleNav(2)">
        Gross/Net Sales
      </div>
      <div :class="{ active: navToggle == 'number' }" @click="toggleNav(3)">
        Number of Orders
      </div>
      <div :class="{ active: navToggle == 'voids' }" @click="toggleNav(4)">
        Voids
      </div>
    </div>
    <div class="right-canvas">
      <a-empty v-if="!isApplied" />
      <template v-else>
        <div
          :style="{
            marginTop: rightPos + 'px',
          }"
        >
          <canvas
            id="chart-clicks"
            ref="canvas"
            width="962"
            height="291"
          ></canvas>
        </div>
        <div>
          <canvas id="chart-comm" width="962" height="291"></canvas>
        </div>
        <div>
          <div class="legend">
            <div>Gross Sales</div>
            <div>Net Sales</div>
          </div>
          <canvas id="chart-sales" width="962" height="291"></canvas>
        </div>
        <div>
          <canvas id="chart-orders" width="962" height="291"></canvas>
        </div>
        <div>
          <canvas id="chart-voids" width="962" height="291"></canvas>
        </div>
      </template>
    </div>
    <div class="toggle">
      <div
        :class="{ active: timeToggle == 'week' }"
        @click="
          timeToggle = 'week';
          getData();
        "
      >
        Week
      </div>
      <div
        :class="{ active: timeToggle == 'month' }"
        @click="
          timeToggle = 'month';
          getData();
        "
      >
        Month
      </div>
    </div>

    <div
      class="chart-tips"
      :style="{
        left: chartTip.left,
        top: chartTip.top,
        display: chartTip.display,
      }"
    >
      <span>{{ chartTip.title }}</span>
      <span :class="{ 'color-blue-light': chartTip.value2 !== null }">{{
        chartTip.value
      }}</span>
      <span v-if="chartTip.value2 !== null" class="color-red-light">{{
        chartTip.value2
      }}</span>
    </div>
  </a-spin>
</template>

<script>
import axios from "@/utils/request";
import moment from "moment-timezone";
import { cloneDeep } from "lodash";
import {
  Chart,
  CategoryScale,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Filler,
  Tooltip,
} from "chart.js";
Chart.register(
  CategoryScale,
  LineController,
  Filler,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip
);

export default {
  props: ["medium"],
  watch: {
    medium: {
      immediate: true,
      handler: function(newV, oldV) {
        if (newV && newV.apply_status == "adopt") {
          this.isApplied = true;
          this.$nextTick(() => {
            this.initCharts();
            this.getData();
          });
        }
      },
    },
  },
  data() {
    return {
      isLoading: false,
      timeToggle: "week",
      navToggle: "clicks",
      isApplied: false,
      rightPos: 0,
      gradient1: null,
      gradient2: null,
      dash1: [4, 5],
      chartTip: {
        title: "",
        value: "",
        value2: null,
        left: "50%",
        top: "50%",
        display: "none",
      },
      charts: {
        clicks: null,
        comm: null,
        sales: null,
        orders: null,
        voids: null,
      },
    };
  },
  methods: {
    toggleNav(idx) {
      this.rightPos = -291 * idx;
      this.navToggle = ["clicks", "commissions", "gross", "number", "voids"][
        idx
      ];
      if (!this.isApplied) {
        return;
      }
    },
    toggleChartToolTip(context) {
      if (context.tooltip.opacity === 0) {
        this.chartTip = {
          title: "",
          value: "",
          value2: null,
          left: "50%",
          top: "50%",
          display: "none",
        };
        return;
      }
      let dataIdx = context.tooltip.dataPoints[0].dataIndex;
      let datasets = context.chart.data.datasets;
      let ignoreCurrency1 = [
        "chart-clicks",
        "chart-orders",
        "chart-voids",
      ].includes(context.chart.canvas.id);
      let chartTip = {
        title: context.tooltip.dataPoints[0].label,
        value: this.toThousands(datasets[0].data[dataIdx], ignoreCurrency1),
        // 191左边nav宽度  20与原点距离
        left: context.tooltip.caretX + 191 + 20 + "px",
        // 47 顶部高度 35往上偏移
        top: context.tooltip.caretY + 47 - 35 + "px",
        display: "flex",
        value2:
          context.chart.canvas.id == "chart-sales"
            ? this.toThousands(datasets[1].data[dataIdx])
            : null,
      };
      console.log(chartTip, datasets, dataIdx);

      this.chartTip = chartTip;
    },
    initCharts() {
      this.gradient1 = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 450);
      this.gradient1.addColorStop(0, "#E5EFFFCC");
      this.gradient1.addColorStop(1, "#FFFFFF00");
      this.gradient2 = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 450);
      this.gradient2.addColorStop(0, "#FFE7E7CC");
      this.gradient2.addColorStop(1, "#FFFFFF00");
      let basicConfig = {
        type: "line",
        data: {
          labels: [],
          datasets: [
            {
              label: "Sales",
              data: [],
              fill: "start",
              pointBorderWidth: 2,
              pointBackgroundColor: "#A2DCFFFF",
              pointHoverBorderWidth: 5,
              borderColor: "#A2DCFFFF",
              borderWidth: 1.5,
              backgroundColor: this.gradient1,
              tension: 0.2,
            },
          ],
        },
        options: {
          plugins: {
            tooltip: {
              enabled: false,
              external: (ctx) => this.toggleChartToolTip(ctx),
            },
            title: {
              display: true,
              text: "",
              align: "start",
              padding: {
                top: 20,
                bottom: 20,
              },
              font: {
                size: 16,
                family: "dtc",
                color: "#3c3c3c",
                weight: "normal",
              },
            },
          },
          scales: {
            xAxis: {
              type: "category",
              ticks: {
                minRotation: 45,
                maxRotation: 90,
              },
              grid: {
                color: "#00000000",
              },
            },
            yAxis: {
              type: "linear",
              suggestedMin: 0,
              max: 1000,
              step: 100,
              grid: {
                color: "#CCCCCC44",
                borderDash: this.dash1,
              },
            },
          },
        },
      };
      let configClicks = cloneDeep(basicConfig);
      configClicks.data.datasets[0].label = "Clicks";
      configClicks.options.plugins.title.text = "Clicks";
      this.charts.clicks = new Chart("chart-clicks", configClicks);

      let configComm = cloneDeep(basicConfig);
      configComm.data.datasets[0].label = "Commissions";
      configComm.options.plugins.title.text = "Commissions";
      this.charts.comm = new Chart("chart-comm", configComm);

      let configSales = cloneDeep(basicConfig);
      configSales.data.datasets[0].label = "Gross Sales";
      configSales.data.datasets.push({
        label: "Net Sales",
        data: [],
        fill: "start",
        pointBorderWidth: 2,
        pointBackgroundColor: "#FFA2A2FF",
        pointHoverBorderWidth: 5,
        borderColor: "#FFA2A2FF",
        borderWidth: 1.5,
        backgroundColor: this.gradient2,
        tension: 0.2,
      });
      configSales.options.plugins.title.text = "Gross/Net Sales";
      this.charts.sales = new Chart("chart-sales", configSales);

      let configOrders = cloneDeep(basicConfig);
      configOrders.data.datasets[0].label = "Orders";
      configOrders.options.plugins.title.text = "Number of Orders";
      this.charts.orders = new Chart("chart-orders", configOrders);

      let configVoids = cloneDeep(basicConfig);
      configVoids.data.datasets[0].label = "Voids";
      configVoids.options.plugins.title.text = "Voids";
      this.charts.voids = new Chart("chart-voids", configVoids);
    },
    getData() {
      // 最近30个周期的
      this.isLoading = true;
      axios
        .get("/api_admin.php?a=report/call", {
          params: {
            report_name: "dtcForActivityCharts",
            site_id: this.medium.site_id,
            start_date: moment()
              .add(-1, this.timeToggle)
              .format("MM/DD/YYYY"),
            end_date: moment().format("MM/DD/YYYY"),
          },
        })
        .then((res) => {
          this.isLoading = false;
          if (res.code != 0) {
            return;
          }
          let start = moment().add(-1, this.timeToggle);
          let todayMD = moment().format("MM/DD");
          let datas = {};
          while (true) {
            let temp = start.format("MM/DD");
            datas[temp] = {
              clicks: 0,
              aff_cmsn: 0,
              sales: 0,
              sales_effective: 0,
              order_count: 0,
              order_count_expired: 0,
            };
            if (temp == todayMD) {
              break;
            }
            start.add(1, "day");
          }
          let max = {
            clicks: 0,
            aff_cmsn: 0,
            sales: 0,
            sales_effective: 0,
            order_count: 0,
            order_count_expired: 0,
          };
          let minCommission = 0;
          res.data.data.forEach((item) => {
            var ymd = item.daily.split("-");
            datas[ymd[1] + "/" + ymd[2]] = item;
            max = {
              clicks: Math.max(max.clicks, item.clicks),
              aff_cmsn: Math.max(max.aff_cmsn, item.aff_cmsn),
              sales: Math.max(max.sales, item.sales),
              sales_effective: Math.max(
                max.sales_effective,
                item.sales_effective
              ),
              order_count: Math.max(max.order_count, item.order_count),
              order_count_expired: Math.max(
                max.order_count_expired,
                item.order_count_expired
              ),
            };
            minCommission = Math.min(minCommission, item.aff_cmsn);
          });
          max = {
            clicks: Math.max(Math.ceil(max.clicks * 1.05), 10),
            aff_cmsn: Math.max(Math.ceil(max.aff_cmsn * 1.05), 10),
            sales: Math.max(Math.ceil(max.sales * 1.05), 10),
            sales_effective: Math.max(
              Math.ceil(max.sales_effective * 1.05),
              10
            ),
            order_count: Math.max(Math.ceil(max.order_count * 1.05), 10),
            order_count_expired: Math.max(
              Math.ceil(max.order_count_expired * 1.05),
              10
            ),
          };
          let step = {
            clicks: Math.max(Math.ceil(max.clicks / 5), 2),
            aff_cmsn: Math.max(Math.ceil(max.aff_cmsn / 5), 2),
            sales: Math.max(Math.ceil(max.sales / 5), 2),
            sales_effective: Math.max(Math.ceil(max.sales_effective / 5), 2),
            order_count: Math.max(Math.ceil(max.order_count / 5), 2),
            order_count_expired: Math.max(
              Math.ceil(max.order_count_expired / 5),
              2
            ),
          };

          // clicks: item.clicks,
          // // commission
          // aff_cmsn: item.aff_cmsn,
          // // gross // net sales
          // sales: item.sales,
          // sales_effective: item.sales_effective,
          // // orders
          // order_count: item.order_count,
          // // voids
          // order_count_expired: item.order_count_expired,

          let labels = Object.keys(datas);

          this.charts.clicks.data.labels = labels;
          this.charts.clicks.data.datasets[0].data = Object.values(datas).map(
            (item) => item.clicks
          );
          this.charts.clicks.options.scales.yAxis.max = max.clicks;
          this.charts.clicks.options.scales.yAxis.step = step.clicks;
          this.charts.clicks.update();

          this.charts.comm.data.labels = labels;
          this.charts.comm.data.datasets[0].data = Object.values(datas).map(
            (item) => item.aff_cmsn
          );
          // this.charts.comm.options.scales.yAxis.suggestedMin = minCommission
          this.charts.comm.options.scales.yAxis.max = max.comm;
          this.charts.comm.options.scales.yAxis.step = step.comm;
          this.charts.comm.update();

          this.charts.sales.data.labels = labels;
          this.charts.sales.data.datasets[0].data = Object.values(datas).map(
            (item) => item.sales
          );
          this.charts.sales.data.datasets[1].data = Object.values(datas).map(
            (item) => item.sales_effective
          );
          this.charts.sales.options.scales.yAxis.max = Math.max(
            max.sales,
            max.sales_effective
          );
          this.charts.sales.options.scales.yAxis.step = Math.max(
            step.sales,
            step.sales_effective
          );
          this.charts.sales.update();

          this.charts.orders.data.labels = labels;
          this.charts.orders.data.datasets[0].data = Object.values(datas).map(
            (item) => item.order_count
          );
          this.charts.orders.options.scales.yAxis.max = max.orders;
          this.charts.orders.options.scales.yAxis.step = step.orders;
          this.charts.orders.update();

          this.charts.voids.data.labels = labels;
          this.charts.voids.data.datasets[0].data = Object.values(datas).map(
            (item) => item.order_count_expired
          );
          this.charts.voids.options.scales.yAxis.max = max.voids;
          this.charts.voids.options.scales.yAxis.step = step.voids;
          this.charts.voids.update();
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.left-nav {
  width: 172px;
  height: 291px;
  border-radius: 6px;
  border: 1px solid #eeeeee;
  float: left;
  > div {
    font-family: dtc;
    user-select: none;
    font-size: 1rem;
    height: 58px;
    border-top: 1px solid #eeeeee;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    cursor: pointer;
    &:first-child {
      height: 57px;
      border: none;
      border-radius: 6px 6px 0 0;
    }
    &:last-child {
      border-radius: 0 0 6px 6px;
    }
    &.active {
      background: #f2f9ff;
    }
  }
}
.right-canvas {
  width: 962px;
  height: 291px;
  margin-left: 196px;
  overflow: hidden;
  > div {
    transition: all 0.3s ease;
    width: 962px;
    height: 291px;
  }
  /deep/.ant-empty {
    background-color: #fff;
    height: auto;
    margin: 0;
    padding: 81px 0;
  }
}
.toggle {
  width: 167px;
  height: 52px;
  background: #f2f9ff;
  border-radius: 6px;
  float: left;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  div {
    width: 50px;
    height: 32px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.active {
      width: 80px;
      cursor: default;
      background: #ffffff;
      box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
    }
  }
}

.chart-tips {
  position: absolute;
  top: 50%;
  left: 50%;
  pointer-events: none;
  z-index: 1;
  width: fit-content;
  min-width: 100px;
  background: rgba(102, 102, 102, 0.9);
  border-radius: 2px;
  display: none;
  flex-direction: column;
  color: #fff;
  > span {
    box-sizing: border-box;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    + span {
      height: 36px;
      border-top: 1px solid #ffffff66;
    }
  }
}

.legend {
  position: relative;
  width: 300px;
  height: 0px;
  left: 150px;
  top: 20px;
  div {
    display: inline-block;
    height: 20px;
    line-height: 18px;
    background-color: #fff;
    font-size: 1.1429rem;
    color: #a2dcffff;
    + div {
      color: #ffa2a2ff;
      margin-left: 20px;
      &::before {
        background-color: #ffa2a2ff;
      }
    }
    &::before {
      display: inline-block;
      content: " ";
      width: 14px;
      height: 14px;
      position: relative;
      top: 1px;
      margin-right: 5px;
      background-color: #a2dcffff;
      border-radius: 99px;
      // background-color: #ffffff00;
    }
  }
}
</style>
