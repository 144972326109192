<template>
  <div id="submenu-list">
    <div v-for="(item, index) in btnLists" :key="index" class="button">
      <template v-if="mode == 'route'">
        <router-link
          :to="{ name: item.path }"
          :class="currentRoute == item.path ? 'active' : ''"
          >{{ item.name }}</router-link
        >
      </template>
      <template v-else>
        <a
          @click="$emit('go', item.path)"
          :class="current == item.path ? 'active' : ''"
          >{{ item.name }}</a
        >
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    btnLists: {
      default: () => [],
      type: Array,
    },
    mode: {
      type: String,
      default: "route",
    },
    current: {
      type: String,
      default: null,
    },
  },
  computed: {
    currentRoute() {
      return this.$route.name;
    },
  },
};
</script>

<style lang="less" scoped>
#submenu-list {
  margin-bottom: 22px;

  .button {
    padding: 0px;
    border: none;
    margin-right: 18px;
    border-radius: 6px;
    display: inline-block;

    a {
      display: inline-block;
      padding: 9px 22px;
      background: #f5f5f5;
      border-radius: 6px;
      font-size: 1.1429rem;
      color: #999999;
      line-height: 20px;
    }

    .active {
      color: #2898ff;
      background: #f2f9ff;
    }
  }
}
</style>
