<template>
  <ul class="campprev" v-if="item">
    <li class="campprev__item">
      <span class="campprev__item__title">Texts</span>
      <div class="campprev__item__content">
        <TextItem
          v-for="(tt, index) in item.creatives.text"
          :key="`tts-${index}`"
          v-bind="tt"
        />
      </div>
    </li>
    <li class="campprev__item">
      <span class="campprev__item__title">Images</span>
      <div class="campprev__item__content">
        <ImageItem
          v-for="(tt, index) in item.creatives.image"
          :key="`tts-${index}`"
          v-bind="tt"
        />
      </div>
    </li>
    <li class="campprev__item">
      <span class="campprev__item__title">Videos</span>
      <div class="campprev__item__content">
        <VideoItem
          v-for="(tt, index) in item.creatives.video"
          :key="`tts-${index}`"
          v-bind="tt"
        />
      </div>
    </li>
  </ul>
</template>

<script>
import TextItem from "../create/TextItem.vue";
import ImageItem from "../create/ImageItem.vue";
import VideoItem from "../create/VideoItem.vue";
export default {
  components: { TextItem, ImageItem, VideoItem },
  props: {
    item: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {};
  },
  methods: {},
  watch: {},
};
</script>

<style lang="less" scoped>
.campprev {
  margin-top: 16px;
  &__item {
    display: flex;
    margin-top: 20px;
    &__title {
      width: 80px;
      color: #999999;
      .red {
        color: red;
      }
    }
    &__content {
      width: calc(100% - 80px);
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      &__sm {
        font-size: 0.8571rem;
        color: #333333;
        font-weight: lighter;
        margin-right: 10px;
      }
      &__normal {
        margin-right: 10px;
      }
      &__bg {
        margin-right: 10px;
        font-weight: 600;
      }
    }
    &__platform {
      margin-right: 20px;
      display: flex;
      align-items: center;
      img {
        width: 18px;
        margin-right: 6px;
      }
    }
  }
}
</style>
