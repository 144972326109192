<template>
  <div
    class="campadd"
    @click="addItem"
    :style="{ width: `${width}px`, height: `${height}px` }"
  >
    <a-icon type="plus" />
  </div>
</template>

<script>
// const defaultLogo = require("@/assets/default.png");
import moment from "moment-timezone";
import axios from "@/utils/request";
// import Modal from "../../../components/Modal.vue";

export default {
  components: {},
  props: {
    width: {
      type: String,
      default: 100,
    },
    height: {
      type: String,
      default: 100,
    },
  },
  computed: {},
  data() {
    return {
      isLoadingModal: false,
    };
  },
  watch: {},
  created() {},
  methods: {
    addItem() {
      this.$emit("add-text");
    },
  },
};
</script>

<style lang="less" scoped>
.campadd {
  border: 1px dashed #aaaaaa;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 12px;
  &:hover {
    opacity: 0.85;
  }
  i {
    font-size: 32px;
    color: #aaa;
  }
}
</style>
