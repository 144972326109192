<template>
  <div class="list">
    <Brands collection="Message" subset="List"></Brands>
    <div class="content">
      <a-spin class="content-list" :spinning="isLoading">
        <a-empty v-if="messageList.length == 0 && !isLoading" />

        <div class="item" v-for="item in messageList" :key="item.from.id">
          <div
            class="time"
            :style="{ display: item.showTime ? 'block' : 'none' }"
          >
            {{ item.from.created_time_text }}
          </div>
          <div class="title">
            <message-from
              :item="item.from"
              @update="(val) => (item.from = val)"
            ></message-from>
            <template v-if="item.from.operate_type == 'apply_merchant'">
              <div class="notice">has applied to cooperate with you.</div>
              <div
                class="option"
                v-if="item.from.can_operate && !item.from.operate_result"
              >
                <div class="accpet-merchant">
                  <img
                    src="@/assets/message-accept.svg"
                    style="color:#2898FF"
                    alt
                    @click="editMeduim(item.from, 'adopt')"
                  />
                </div>
                <div class="deny-merchant">
                  <img
                    src="@/assets/message-deny.svg"
                    alt
                    @click="editMeduim(item.from, 'refuse')"
                    style="color:#DDDDDD "
                  />
                </div>
              </div>
            </template>
            <div class="notice" v-else>
              {{ item.from.operate_type | typeMsg }}
            </div>
          </div>
          <div class="message" v-if="item.from.content">
            <div class="arrow"></div>
            <div class="message-content">{{ item.from.content }}</div>
          </div>
          <div class="bottom-line"></div>
          <template v-if="item.reply != null">
            <div class="title reply">
              <message-from
                :item="item.reply"
                @update="(val) => (item.reply = val)"
              ></message-from
              >reply
            </div>
            <div class="message reply">
              <div class="arrow"></div>
              <div class="message-content">{{ item.reply.content }}</div>
            </div>
          </template>
        </div>
        <div class="footer-pagination" v-if="totalSize > 0 && !isLoading">
          <a-pagination
            v-model="currentPage"
            :total="totalSize"
            :defaultPageSize="20"
            show-less-items
            @change="paginationChange"
          />
        </div>
      </a-spin>
    </div>
    <div class="clearboth"></div>
  </div>
</template>

<script>
import Brands from "@/components/Brands.vue";
import axios from "@/utils/request";
import moment from "moment-timezone";
import MediumCard from "@/views/Publishers/components/MediumCard.vue";
import MessageFrom from "./components/MessageFrom.vue";

export default {
  name: "message",
  components: {
    Brands,
    MediumCard,
    MessageFrom,
  },
  data() {
    return {
      replyObj: null,
      messageList: [],
      isLoading: true,
      currentPage: 1,
      page_size: 10,
      totalSize: 0,
      totalPage: 0,
      mediumCardInfos: {},
    };
  },
  computed: {
    mid() {
      return this.$store.state.accountInfo.merchant_info.m_id;
    },
  },
  filters: {
    time_hi(time) {
      return moment
        .unix(time)
        .fromNow()
        .replace("a month ago", "1 month ago");
    },
    typeMsg(type) {
      return (
        {
          merchant_automatic_fail: "Account Automatic Deposit Failed",
          merchant_low_funds: "Account Low Balance",
          merchant_unpay_overdue: "Account Overdue Amount",
          merchant_unpay_overdue_cancel:
            "Account Affiliate Links Restore Normal",
          // 'merchant_affiliate_apply_auto_approve' : 'merchant_affiliate_apply_auto_approve'
        }[type] || "has sent a message."
      );
    },
  },
  mounted() {
    this.getMessageList();
  },
  methods: {
    getMessageList() {
      this.isLoading = true;

      axios
        .get("/api_admin.php?a=message/list", {
          params: { ...this.params, page_num: this.currentPage },
        })
        .then((res) => {
          if (res.code === 0) {
            let prevTime = null;
            this.messageList = res.data.data.map((item) => {
              item.from.created_time_text = moment
                .unix(item.from.created_time)
                .fromNow()
                .replace("a month ago", "1 month ago");
              item.showTime = item.from.created_time_text != prevTime;
              if (item.showTime) {
                prevTime = item.from.created_time_text;
              }
              item.from.fromInfo = null;
              item.from.fromInfoLoading = false;
              item.from.fromInfoDisplay = false;
              if (item.reply) {
                item.reply.fromInfo = null;
                item.reply.fromInfoLoading = false;
                item.reply.fromInfoDisplay = false;
              }
              return item;
            });
            this.isLoading = false;
            this.dataLists = res.data.data;
            this.totalSize = parseInt(res.data.total_size);
            this.totalPage = parseInt(res.data.total_page);
            document.documentElement.scrollTop = 0;
          } else {
            this.$message.error(res.msg || "");
          }
        });
    },
    paginationChange(page, pageSize) {
      this.currentPage = page;
      this.getMessageList();
    },
    editMeduim(mailInfo, status) {
      if (mailInfo.operate_result) {
        return;
      }
      this.isLoading = true;
      axios
        .post("/api_admin.php?a=medium/edit", {
          site_ids: parseInt(mailInfo.from_id.substr(3)) + "",
          status,
          mail_id: mailInfo.id,
        })
        .then((res) => {
          this.isLoading = false;
          if (res.code === 0) {
            mailInfo.operate_result = "true";
          } else {
            alert(res.message);
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  margin-bottom: 100px;

  .content-list {
    width: 100%;
    padding: 20px 30px 20px 30px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    margin-top: 22px;
    position: relative;

    .item {
      width: 100%;
      box-sizing: border-box;

      .time {
        font-size: 1.4286rem;
        font-family: Circular-Black, Circular;
        font-weight: 900;
        color: #333333;
        line-height: 25px;
      }

      .bottom-line {
        width: 100%;
        height: 1px;
        background-color: #eeeeee;
        margin-top: 23px;
        margin-bottom: 23px;
      }

      .title {
        font-size: 1.1429rem;
        margin-top: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        > * {
          margin-right: 8px;
        }

        .from {
          text-decoration: underline;

          &.canClick {
            cursor: pointer;
          }
        }

        .option {
          display: flex;
          justify-content: flex-start;

          div {
            margin-right: 8px;
            cursor: pointer;
          }
        }

        &.reply {
          justify-content: flex-start;
          margin-top: -8px;

          .from {
            font-family: "dtc";
          }
        }
      }

      .message {
        border: 1px solid #00000026;
        border-radius: 8px;
        position: relative;
        box-sizing: border-box;
        margin-top: 15px;
        padding: 11px 14px;
        font-size: 1rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: max-content;
        max-width: 100%;

        .message-content {
          min-width: 60px;
          min-height: 21px;
          line-height: 1.8em;
        }

        .reply-btn {
          background: rgba(0, 0, 0, 0.16);
          border: none;
          color: #fff;
        }

        &.reply {
          margin-top: 10px;
          margin-bottom: 26px;
          border: none;
          background: rgba(243, 243, 243, 1);
          border-radius: 6px;

          > .arrow {
            display: none;
          }
        }

        .arrow {
          width: 10px;
          height: 10px;
          border: 1px solid #00000026;
          border-right-color: #fff;
          border-bottom-color: #fff;
          transform: rotate(45deg);
          position: absolute;
          top: -5px;
          left: 25px;
          background-color: #fff;
          border-radius: 2px;
        }
      }
    }
  }
}
</style>
