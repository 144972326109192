<template>
  <a-spin :spinning="loading" style="min-height: 700px;">
    <div class="logo-header">
      <a href="https://www.linkprefer.com/" target="_blank"
        ><img src="@/assets/login/logo.svg" alt
      /></a>
    </div>
    <div class="login" v-show="!loading">
      <div class="login-back">
        <span @click="back">
          <a-icon type="left" />
        </span>
      </div>
      <div class="login-box">
        <div class="forgot-step">
          <div class="text">
            <div class="step-item">
              <h5 :class="{ stepping: step >= 1 }">STEP 1</h5>
            </div>
            <div class="step-item">
              <h5 :class="{ stepping: step >= 2 }">STEP 2</h5>
            </div>
            <div class="step-item">
              <h5 :class="{ stepping: step == 3 }">STEP 3</h5>
            </div>
          </div>
          <div class="bg">
            <div class="current" :style="`width: ${33.33 * step}%`"></div>
          </div>
        </div>

        <div
          v-if="step === 1 && !stepOneFinished"
          class="forgot-content"
          data-step="1-0"
        >
          <h1 class="login-box__title title-forgot">
            Please fill in your account email and verification code.
          </h1>
          <div class="login-form">
            <div class="login-form__item">
              <div class="form-item__label">Email <span>*</span></div>
              <div class="form-item__input">
                <input type="text" v-model="email" />
              </div>
            </div>
            <div class="login-form__item">
              <div class="form-item__label">
                Verifcation Code <span>*</span>
              </div>
              <div class="form-item__input">
                <input type="text" v-model="code" />
                <div class="code" @click="onCodeRefresh">
                  <img :src="`${codeUrl}?t=${t}`" />
                </div>
              </div>
            </div>

            <div class="login-form__btn2">
              <a-button @click="cancel">Cancel</a-button>
              <a-button type="primary" @click="onStepOneSubmit"
                >Submit</a-button
              >
            </div>
          </div>
        </div>

        <div
          v-if="step === 1 && stepOneFinished"
          class="forgot-content"
          data-step="1-1"
        >
          <div class="step1-2">
            <p>Please check your email to reset your password.</p>
            <p class="email">{{ email }}</p>
            <div class="login-form">
              <div class="login-form__btn">
                <a-button type="primary" @click="onEmailResend"
                  >Resend Email</a-button
                >
              </div>
            </div>
          </div>
        </div>

        <div v-if="step === 2" class="forgot-content" data-step="2">
          <div class="login-form">
            <div class="login-form__item">
              <div class="form-item__label">Email <span>*</span></div>
              <div class="form-item__input">
                <input type="text" disabled v-model="form.email" />
              </div>
            </div>
            <div class="login-form__item">
              <div class="form-item__label">New Password <span>*</span></div>
              <div class="form-item__input">
                <input
                  :type="pwdShow ? 'text' : 'password'"
                  v-model="form.password"
                />
                <div class="eye">
                  <a-icon type="eye" v-if="pwdShow" @click="onPwdVisiable" />
                  <a-icon type="eye-invisible" v-else @click="onPwdVisiable" />
                </div>
              </div>
            </div>

            <div class="login-form__item">
              <div class="form-item__label">
                Confirm Password <span>*</span>
              </div>
              <div class="form-item__input">
                <input
                  :type="pwdShow ? 'text' : 'password'"
                  v-model="form.rpassword"
                />
              </div>
            </div>

            <div class="login-form__item">
              <div class="form-item__label">
                Verifcation Code <span>*</span>
              </div>
              <div class="form-item__input">
                <input type="text" v-model="form.code" />
                <div class="code" @click="onCodeRefresh">
                  <img :src="`${codeUrl}?t=${form.t}`" />
                </div>
              </div>
            </div>
            <div class="login-form__btn">
              <a-button type="primary" @click="onPwdReset"
                >Resend Password</a-button
              >
            </div>
          </div>
        </div>

        <div v-if="step === 3" class="forgot-content" data-step="3">
          <div class="login-form">
            <div class="reset">
              <p><img src="@/assets/login/reset-tips.svg" /></p>
              <p>Your password has been updated.</p>
            </div>
            <div class="login-form__btn">
              <a-button type="primary" @click="goLogin">Login</a-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import axios from "@/utils/request";
import APIS from "@/api/const";
import { baseUrl } from "@/utils/config";

function get_uuid() {
  let s = [];
  const hexDigits = "0123456789abcdef";
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4";
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
  s[8] = s[13] = s[18] = s[23] = "-";
  let uuid = s.join("");
  return uuid;
}

export default {
  name: "login",
  data() {
    return {
      t: get_uuid(),
      loading: true,
      pwdShow: false,
      step: 1,
      stepOneFinished: false,
      pwdShow: false,
      codeUrl: baseUrl + APIS.common.get_vcode,
      email: "",
      code: "",
      form: {
        track: "",
        email: "",
        code: "",
        password: "",
        rpassword: "",
        t: "",
      },
    };
  },
  mounted() {
    const { track, email } = this.$route.query;
    if (email) {
      this.email = email;
      this.step = 1;
      this.stepOneFinished = true;
    }
    if (track) {
      this.form.track = track;
      this.initTrackCheck();
    } else {
      this.loading = false;
    }
    this.getCodeSID();
  },
  methods: {
    getCodeSID() {
      this.t = get_uuid();
      this.form.t = get_uuid();
    },
    back() {
      this.$router.push("/login");
    },
    initTrackCheck() {
      const { track } = this.form;
      axios
        .post(APIS.common.verify_track, {
          formData: true,
          track,
        })
        .then((resp) => {
          if (resp.code === "0200") {
            this.step = 2;
            this.form.email = resp.payload.email;
            this.loading = false;
          } else {
            this.$message.error(resp.msg);
          }
        });
    },
    onCodeRefresh() {
      this.getCodeSID();
    },
    onPwdVisiable() {
      this.pwdShow = !this.pwdShow;
    },
    cancel() {
      this.$router.push("/");
    },
    onEmailResend() {
      const { email } = this.$route.query || this;
      axios
        .post(APIS.common.pwd_email_resend, {
          formData: true,
          email,
        })
        .then((resp) => {
          if (resp.code === "0200") {
            this.$message.success(resp.msg);
          } else {
            this.$message.error(resp.msg);
          }
        });
    },
    onStepOneSubmit() {
      const { email, code, t } = this;
      if (!email) {
        this.$message.error("Please enter email address");
        return;
      }
      if (
        !/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
          email
        )
      ) {
        this.$message.error("Please enter a valid email address");
        return;
      }
      if (!code) {
        this.$message.error("Please enter verifcation code");
        return;
      }
      axios
        .post(APIS.common.find_pwd, {
          formData: true,
          email: email.trim(),
          code,
          t,
        })
        .then((resp) => {
          if (resp.code === "0200") {
            this.step = 1;
            this.stepOneFinished = true;
            this.$router.push({ name: "forgot", query: { email: email } });
          } else {
            this.$message.error(resp.msg);
            this.onCodeRefresh();
          }
        });
    },
    onPwdReset() {
      const { code, password, rpassword } = this.form;
      if (!password) {
        this.$message.error(
          "Password more than 8 letters, with all types of letter/number/special character(-_$@!%*?&)."
        );
        return;
      }
      if (!rpassword) {
        this.$message.error("Please enter confirm password");
        return;
      }
      if (password != rpassword) {
        this.$message.error("The two entered passwords do not match");
        return;
      }
      if (!code) {
        this.$message.error("Please enter verifcation code");
        return;
      }
      axios
        .post(APIS.common.pwd_reset, {
          formData: true,
          ...this.form,
        })
        .then((resp) => {
          if (resp.code === "0200") {
            this.step = 3;
          } else {
            this.$message.error(resp.msg);
            this.onCodeRefresh();
          }
        });
    },
    goLogin() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./user.less");
</style>
