<template>
  <div class="text">
    {{ string }}
  </div>
</template>
<script>
export default {
  props: {
    string: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.text {
  white-space: pre;
  overflow: hidden;
  line-height: 22px;
  letter-spacing: 1px;
}
</style>
