// 封装axios的请求，返回重新封装的数据格式
// 对错误的统一处理
import axios from "axios";
import errorHandle from "./errorHandle";
import store from "@/store";
import { message } from "ant-design-vue";
import router from "../router";
import qs from "qs";
class HttpRequest {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  // 获取axios配置
  getInsideConfig() {
    const config = {
      baseURL: this.baseUrl,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Lp-Authorization": store.state.token,
      },
      timeout: 60000,
    };
    return config;
  }

  // 设定拦截器
  interceptors(instance) {
    // 请求拦截器
    instance.interceptors.request.use(
      (config) => {
        // Do something before request is sent
        return config;
      },
      (err) => {
        // debugger
        errorHandle(err);
        // Do something with request error
        return Promise.reject(err);
      }
    );

    // 响应请求的拦截器
    instance.interceptors.response.use(
      (res) => {
        if (
          res.data.message == "token is out of date" ||
          res.data.code == "1403"
        ) {
          // if (true) {
          store.commit("clearToken");
          router.push({ name: "login" });
          let err = new Error("token is out of date");
          message.error("Token is out of date, please login");
          errorHandle(err);
          return Promise.reject(err);
        }

        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        if (res.status === 200) {
          return Promise.resolve(res.data);
        } else {
          return Promise.reject(res);
        }
      },
      (err) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        // debugger
        // if (err.response.status === 401) {
        //     message.warning("认证失败！跳转登录");
        //     setTimeout(()=>{
        //         router.push({name: "login"})
        //     }, 1000)
        // } else {
        //     message.error(err.response.data.message);
        // }
        errorHandle(err);
        return Promise.reject(err);
      }
    );
  }

  // 创建实例
  request(options) {
    const instance = axios.create();
    // axios.defaults.withCredentials = true;
    const newOptions = Object.assign(this.getInsideConfig(), options);
    this.interceptors(instance);
    return instance(newOptions);
  }

  get(url, config) {
    const options = Object.assign(
      {
        method: "get",
        url: url,
      },
      config
    );
    return this.request(options);
  }

  post(url, data, options = {}) {
    const qsForm = data.formData;
    const parmas = qsForm ? qs.stringify(data) : data;
    const optionsCombine = qsForm
      ? Object.assign(
          {
            ...options,
          },
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
              "Lp-Authorization": store.state.token,
            },
          }
        )
      : options;
    return this.request({
      method: "post",
      url: url,
      data: parmas,
      ...optionsCombine,
    });
  }

  delete(url) {
    return this.request({
      method: "delete",
      url: url,
    });
  }

  put(url, data) {
    return this.request({
      method: "put",
      url: url,
      data: data,
    });
  }
}

export default HttpRequest;
