<template>
  <a-modal
    v-model="showEditTarget"
    width="900px"
    :closable="false"
    title="Campaign Traget"
    centered
    destroyOnClose
    :maskClosable="false"
  >
    <a-icon class="camp-edit-close" type="close" @click="hideEditTarget" />
    <a-spin :spinning="isLoading">
      <div>
        <a-form
          :form="formTarget"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-form-item label="Location(s)" labelAlign="left">
            <div class="camptarget-country">
              <span class="camptarget-country__text"
                >Country<span>*</span></span
              >
              <a-auto-complete
                class="camptarget-country__list"
                v-decorator="[
                  'country_id',
                  {
                    initialValue: item.country_id,
                    rules: [
                      {
                        required: true,
                        message: 'Please enter a country',
                      },
                    ],
                  },
                ]"
                style="width: 200px"
                placeholder="input here"
                @select="onForm2Select"
                @search="onForm2Search"
              >
                <template slot="dataSource">
                  <a-select-option
                    v-for="item in showList"
                    :key="item.id"
                    :text="item.name_en"
                  >
                    {{ item.name_en }}
                  </a-select-option>
                </template></a-auto-complete
              >
            </div>
            <div class="camptarget-city">
              <a-form-item label="City" labelAlign="left">
                <a-input
                  class="camptarget-city-city"
                  v-decorator="[
                    'city',
                    {
                      initialValue: item.city,
                    },
                  ]"
                />
              </a-form-item>
            </div>
          </a-form-item>
          <a-form-item label="Gender" labelAlign="left">
            <a-select
              v-decorator="[
                'gender',
                {
                  initialValue: item.gender,
                  rules: [
                    { required: true, message: 'Please select your gender!' },
                  ],
                },
              ]"
              placeholder="Select gender"
            >
              <a-select-option value="Male"> Male </a-select-option>
              <a-select-option value="Female"> Female </a-select-option>
              <a-select-option value="All"> All </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="Age" class="camptarget-age" labelAlign="left">
            <div class="camptarget-age-line">
              <!-- <span class="text1">From&nbsp;</span>
              <a-input-number
                :min="1"
                :max="120"
                v-decorator="[
                  'age_lower',
                  {
                    initialValue: item.age_lower,
                    rules: [
                      {
                        required: true,
                        message: 'Please enter a age lower',
                      },
                    ],
                  },
                ]"
              />
              <span class="text2">&nbsp;To&nbsp;</span>
              <a-form-item>
                <a-input-number
                  :min="1"
                  :max="120"
                  v-decorator="[
                    'age_upper',
                    {
                      initialValue: item.age_upper,
                    },
                  ]"
              /></a-form-item> -->
              <NumberInputGroup
                textFrom="From"
                textTo="To"
                :fromMax="120"
                :toMax="120"
                v-decorator="[
                  'age_group',
                  {
                    initialValue: {
                      fromNum: item.age_lower,
                      toNum: item.age_upper,
                    },
                    rules: [{ validator: checkAge, required: true }],
                  },
                ]"
              />
            </div>
          </a-form-item>
          <a-form-item label="Interests" labelAlign="left">
            <a-input
              :maxLength="100"
              v-decorator="[
                'interests',
                {
                  initialValue: item.interests,
                  rules: [
                    {
                      required: true,
                      message: 'Please enter a age interests',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="Target Description" labelAlign="left">
            <a-textarea
              :auto-size="{ minRows: 3, maxRows: 5 }"
              v-decorator="[
                'target_description',
                { initialValue: item.target_description },
              ]"
            />
          </a-form-item>
        </a-form></div
    ></a-spin>
    <template slot="footer">
      <a-button key="back" @click="hideEditTarget"> Discard </a-button>
      <a-button key="submit" type="primary" @click="submitTarget">
        Save
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import NumberInputGroup from "@/components/NumberInputGroup.vue";
import axios from "@/utils/request";
import APIS from "@/api/const";
export default {
  components: { Modal, NumberInputGroup },
  props: {
    item: {
      type: Object,
      default: () => null,
    },
    showEditTarget: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formTarget: this.$form.createForm(this, { name: "form-target-edit" }),
      showList: this.$store.state.commonConfig.countries,
      isLoading: false,
      country_name: "",
    };
  },
  methods: {
    checkAge(rule, value, callback) {
      if (value.fromNum === null || value.toNum === null) {
        callback("The age should not be null");
        return;
      }
      if (value.fromNum > value.toNum) {
        callback("From age should less than to age");
        return;
      }
      callback();
    },
    ageGroupChange(val) {},
    onForm2Select(value, option) {
      this.country_name = option.data.attrs.text;
    },
    hideEditTarget() {
      this.$emit("on-closetips");
    },
    submitTarget() {
      this.formTarget.validateFields((err, formDatas) => {
        if (err) {
          return;
        }
        if (this.isLoading) {
          return;
        }
        if (isNaN(formDatas.country_id)) {
          this.$message.error("Please select a country");
          return;
        }
        this.isLoading = true;
        const editData = {
          ...formDatas,
          age_lower: formDatas.age_group.fromNum,
          age_upper: formDatas.age_group.toNum,
          module: "targets",
          id: this.item.id,
        };
        // console.log("数据：", editData);
        axios.post(APIS.CAMPAIGN.MODIFY, editData).then((res) => {
          this.isLoading = false;
          if (res.code == 0) {
            this.$message.success("Modify Success.");
            this.$emit("success-close");
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    onForm2Search(searchText) {
      this.showList = this.$store.state.commonConfig.countries.filter(
        (item) => {
          if (
            item.name_en.toUpperCase().indexOf(searchText.toUpperCase()) > -1
          ) {
            return item;
          }
        }
      );
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.camptarget {
  &-country {
    margin-bottom: 10px;
    > span {
      width: 95px;
      display: inline-block;
    }
    &__list {
      width: 472px !important;
    }
    &__text {
      span {
        color: rgb(245, 34, 45);
        font-family: SimSun, sans-serif;
        margin-left: 3px;
      }
    }
  }
  &-city {
    .ant-row {
      margin-bottom: 2px;
    }
    &-city {
      width: 472px;
    }
  }
  &-age {
    &-line {
      display: flex;
      align-items: center;
      .ant-row {
        margin-bottom: 0;
      }
    }
  }
}
.ant-checkbox-wrapper {
  margin-left: 8px;
  width: 172px;
  margin-bottom: 6px;
}
.camp-edit-close {
  position: absolute;
  right: 18px;
  top: 18px;
  font-size: 1.2857rem;
  cursor: pointer;
  &:hover {
    opacity: 0.85;
  }
}
/deep/ .ant-select-dropdown-menu-item {
  text-align: left;
}
</style>
