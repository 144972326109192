<template>
  <div class="list">
    <Brands collection="Publishers" subset="Publisher List"></Brands>
    <SubMenu :btnLists="subMenus"></SubMenu>

    <div class="content">
      <div class="content-title">
        <div class="items">
          <div class="item">
            <span>Status</span>
            <PSelector
              size="large"
              v-model="params.status"
              :options="[
                {
                  value: 'adopt',
                  label: 'Approved',
                },
                {
                  value: 'refuse',
                  label: 'Declined',
                },
                {
                  value: 'terminated',
                  label: 'Removed',
                },
              ]"
              multiple
              clearAble
            ></PSelector>
          </div>
          <div class="item">
            <span>Country</span>
            <PSelector
              size="large"
              v-model="params.country_id"
              :options="
                countryList.map((c) => ({
                  value: c.id,
                  label: c.name_en,
                }))
              "
              multiple
              clearAble
            ></PSelector>
          </div>
          <div class="item">
            <span>Search Keywords</span>
            <a-input v-model="params.keyword" />
          </div>
        </div>
        <div class="search-reset">
          <button @click="search">Search</button>
          <button @click="reset">Reset</button>
        </div>
      </div>

      <a-spin
        class="content-list"
        :class="{ empty: mediumLists.length == 0 }"
        :spinning="isLoading"
      >
        <a-empty v-if="mediumLists.length == 0 && !isLoading" />
        <div class="list-prefix">
          <div class="total-size">{{ totalSize }} Results</div>
          <div
            class="select-all"
            @click="selectAll"
            v-if="mediumLists.length > 0"
          >
            Select All
          </div>
        </div>

        <medium-card
          v-for="item in mediumLists"
          :key="item.medium_id"
          :item="item"
          :tab-active="tabActive"
          :stat-by="params.stats_by"
          :show-stats="true"
          :showGiveBonus="true"
          :showStatusSelect="true"
          :checked="checkedList.includes(item.site_id)"
          @checkChange="handleChangeCheck"
          @tabActiveChange="handleChangeTabActive"
          @statByChange="handleChangeStatBy"
          @giveBounus="handleGiveBounus"
          @statusChange="handleStatusChanged"
        >
        </medium-card>

        <div class="footer-pagination" v-if="totalSize > 0">
          <a-pagination
            v-model="currentPage"
            :total="totalSize"
            :defaultPageSize="20"
            show-less-items
            @change="paginationChange"
          />
        </div>
        <div class="item-action" v-show="checkedList.length > 0">
          <div class="item-action-inside">
            <div class="number">
              <div>Publisher Mass Action</div>
              <div>Total: {{ checkedList.length }}</div>
            </div>
            <div class="status" id="status-box">
              <span>Change Status</span>
              <a-select
                placeholder="Select Status"
                size="large"
                v-model="batchStatus"
                :getPopupContainer="getStatusContainer"
              >
                <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
                <a-select-option
                  :value="item"
                  v-for="item in batchStatusList"
                  :key="item"
                  >{{ item }}</a-select-option
                >
              </a-select>
            </div>
            <div class="options">
              <a-button
                size="large"
                type="primary"
                :disabled="!batchStatus"
                @click="isShowUpdate = true"
                >Update</a-button
              >&emsp;
              <a-button
                size="large"
                type="primary"
                ghost
                @click="checkedList = []"
                >Cancel</a-button
              >
            </div>
          </div>
        </div>
        <div class="clearboth"></div>
      </a-spin>
    </div>

    <!-- 批量编辑 -->
    <Modal
      :isVisible="isShowUpdate"
      title="Mass Action Confirmation"
      :showBtn="false"
      @close-popup="isShowUpdate = false"
      width="365px"
      class="batch-update-modal"
    >
      <template #content>
        <div class="update-info">
          <div class="color-primary font-bold">
            {{ checkedList.length }} Publisher{{
              checkedList.length > 1 ? "s" : ""
            }}
          </div>
          <div v-for="item in batchStatusObjList" :key="item.id">
            {{ item.title }} - {{ item.medium_id }}
          </div>

          <div class="color-primary font-bold">Status Change</div>
          <div>Set status to: {{ batchStatus }}</div>
        </div>
        <div style="display: flex; justify-content: center;">
          <a-button
            type="default"
            @click="isShowUpdate = false"
            style="margin-right: 60px;"
            size="large"
            >Cancel</a-button
          >
          <a-button
            type="primary"
            size="large"
            style="font-size:16px"
            @click="batchEdit"
            >Confirm</a-button
          >
        </div>
      </template>
    </Modal>

    <!-- 给予奖励 -->
    <Modal
      :isVisible="showGiveBonusInfo != null"
      title="Give A Bonus"
      :showBtn="false"
      @close-popup="showGiveBonusInfo = null"
      width="348px"
    >
      <template #content>
        <div class="give-bonus-content">
          <div class="item">
            <h4>Bonus Amount</h4>
            <div class="input-row">
              <input
                type="text"
                class="select-input"
                v-model="showGiveBonusInfo.bonusAmount"
              />
              <a-select
                class="select"
                v-model="showGiveBonusInfo.bonusCurrency"
              >
                <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
                <a-select-option
                  :value="currency.simple_name"
                  v-for="currency in $store.state.commonConfig.currencies.filter(
                    (c) => c.simple_name != 'MIX'
                  )"
                  :key="currency.simple_name"
                  >{{ currency.simple_name }}</a-select-option
                >
              </a-select>
            </div>
          </div>
          <div class="item">
            <h4>Comment</h4>
            <input
              type="text"
              v-model.trim="showGiveBonusInfo.bonusRemark"
              placeholder="Required"
            />
          </div>
        </div>
        <div style="display: flex; justify-content: center;">
          <a-button
            type="primary"
            style="font-size:16px"
            class="add"
            @click="submitBonus"
            >Submit</a-button
          >
        </div>
      </template>
    </Modal>
    <Loading v-if="isLoadingModal"></Loading>
    <div class="clearboth"></div>
  </div>
</template>

<script>
import Brands from "./../../components/Brands.vue";
import axios from "@/utils/request";
import Modal from "../../components/Modal.vue";
import MediumCard from "./components/MediumCard.vue";
import SubMenu from "../../components/SubMenu.vue";

export default {
  name: "publisher_list",
  components: {
    Brands,
    SubMenu,
    Modal,
    MediumCard,
  },
  data() {
    return {
      subMenus: [
        {
          name: "Publisher List",
          path: "publisher_list",
        },
        {
          name: "Pending Publishers",
          path: "publisher_pending",
          // }, {
          //   name: 'Search Publishers',
          //   path: 'search_publisher',
        },
      ],
      isShowUpdate: false,
      chatMediumId: null,
      keyword: "",
      checkedList: [],
      countryList: [],
      countryMap: {},
      mediumLists: [],
      isLoading: true,
      statByLoading: false,
      currentPage: 1,
      page_size: 10,
      totalSize: 0,
      totalPage: 0,
      tabActive: "info",
      params: {
        // adopt 已签约
        // refuse 已拒绝
        // verifing 待审核
        status: ["adopt"],
        country_id: [],
        keyword: null,
        has_private_norm: "",
        stats_by: "today",
      },
      isLoadingModal: false,
      showGiveBonusInfo: null,
      batchStatus: undefined,
      batchStatusList: [],
      batchStatusObjList: [],
    };
  },
  watch: {
    checkedList(newV, oldV) {
      if (!newV || newV.length == 0) {
        this.batchStatus = undefined;
      }
      let hasApproved = false;
      let hasDeclined = false;
      let hasRemoved = false;
      this.batchStatusObjList = this.mediumLists.filter((item) =>
        newV.includes(item.site_id)
      );
      this.batchStatusObjList.forEach((item) => {
        hasApproved = hasApproved || item.status == "adopt";
        hasDeclined = hasDeclined || item.status == "refuse";
        hasRemoved = hasRemoved || item.status == "terminated";
      });

      let targetStatus = ["Declined", "Approved", "Removed"];
      if (hasApproved) {
        // targetStatus = targetStatus.filter(item => item != 'Approved')
      }
      if (hasDeclined) {
        targetStatus = targetStatus.filter((item) => item != "Removed");
      }
      if (hasRemoved) {
        targetStatus = targetStatus.filter((item) => item != "Declined");
      }
      this.batchStatusList = targetStatus;
      if (!this.batchStatusList.includes(this.batchStatus)) {
        this.batchStatus = undefined;
      }
    },
  },
  mounted() {
    this._getCoutry();
    this._getMediumLists();
  },
  methods: {
    _getCoutry() {
      this.countryList = this.$store.state.commonConfig.countries.map(
        (item) => {
          this.countryMap[item.id] = item;
          return item;
        }
      );
    },
    _getMediumLists(keepList) {
      if (!keepList) {
        this.mediumLists = [];
      }
      axios
        .get("/api_admin.php?a=medium/list", {
          params: {
            ...this.params,
            has_private_norm: this.params.has_private_norm,
            page_num: this.currentPage,
          },
        })
        .then((res) => {
          this.isLoading = false;
          this.statByLoading = false;
          if (res.code === 0) {
            let formatItems = res.data.data.map((item) => {
              return {
                ...item,
                aff_ba: parseInt(item.norm.aff_ba) + item.norm.aff_ba_unit,
              };
            });

            if (keepList) {
              this.mediumLists = formatItems;
            } else {
              this.mediumLists.push(...formatItems);
            }
            this.dataLists = res.data.data;
            this.totalSize = parseInt(res.data.total_size);
            this.totalPage = parseInt(res.data.total_page);
            document.documentElement.scrollTop = 0;
          } else {
            this.$message.error(
              res.msg || "Sorry, server is busy. please try later."
            );
          }
        });
    },
    reset() {
      this.params = {
        status: ["adopt"],
        country_id: [],
        keyword: null,
        has_private_norm: "",
        stats_by: "today",
      };
      this.search();
    },
    search() {
      this.checkedList = [];
      this.isLoading = true;
      this.currentPage = 1;
      this._getMediumLists();
    },
    paginationChange(page, pageSize) {
      this.isLoading = true;
      this.currentPage = page;
      this._getMediumLists();
    },
    handleChangeStatus(val) {
      this.params.status = val;
    },
    handleChangeCountry(val) {
      this.params.country_id = val;
    },
    handleChangeDefaultPrivate(val) {
      this.params.has_private_norm = val;
    },
    handleChangeCheck(siteId, isCheck) {
      if (isCheck && !this.checkedList.includes(siteId)) {
        this.checkedList.push(siteId);
      } else if (!isCheck && this.checkedList.includes(siteId)) {
        this.checkedList.splice(this.checkedList.indexOf(siteId), 1);
      }
    },
    handleChangeTabActive(val) {
      this.tabActive = val;
    },
    handleChangeStatBy(val) {
      this.params.stats_by = val;
      this.statByLoading = true;
      this._getMediumLists(true);
    },
    handleStatusChanged(item, newStatus) {
      item.status = newStatus;
      if (this.checkedList.includes(item.site_id)) {
        this.checkedList.splice(this.checkedList.indexOf(item.site_id), 1);
      }
      if (this.params.status != "all" && this.params.status != newStatus) {
        let size = this.mediumLists.length;
        this.mediumLists.splice(this.mediumLists.indexOf(item), 1);
        if (size == 20) {
          this.isLoading = true;
          this._getMediumLists(true);
        }
      }
    },
    handleGiveBounus(medium) {
      this.showGiveBonusInfo = Object.assign({}, medium, {
        bonusAmount: null,
        bonusRemark: "",
        bonusCurrency: "USD",
      });
    },
    submitBonus() {
      let amount = parseFloat(this.showGiveBonusInfo.bonusAmount);
      if (isNaN(amount) || amount == 0) {
        this.$message.error("Please input valid number");
        return;
      }
      if (this.showGiveBonusInfo.bonusRemark.length == 0) {
        this.$message.error("Please input comment");
        return;
      }
      this.isLoadingModal = true;
      axios
        .post("/api_admin.php?a=medium/giveBonus", {
          medium_id: this.showGiveBonusInfo.medium_id,
          amount,
          remark: this.showGiveBonusInfo.bonusRemark,
          currency: this.showGiveBonusInfo.bonusCurrency,
        })
        .then((res) => {
          this.isLoadingModal = false;
          if (res.code === 0) {
            this.showGiveBonusInfo = null;
            this.$message.success("Give Bonus Success");
          } else {
            this.$message.error("Give Bonus Fail, " + res.message);
          }
        })
        .catch((e) => {
          console.log(e);
          this.isLoadingModal = false;
        });
    },
    checkedChange(site_id) {
      if (this.checkedList.includes(site_id)) {
        this.checkedList.splice(this.checkedList.indexOf(site_id), 1);
      } else {
        this.checkedList.push(site_id);
      }
    },
    selectAll() {
      if (this.mediumLists.length == this.checkedList.length) {
        this.checkedList = [];
        return;
      }
      this.mediumLists.forEach((item) => {
        if (!this.checkedList.includes(item.site_id)) {
          this.checkedList.push(item.site_id);
        }
      });
    },
    batchEdit() {
      let newStatus = {
        Approved: "adopt",
        Declined: "refuse",
        Pending: "verifing",
        Removed: "terminated",
      }[this.batchStatus];
      let finalSiteIds = this.batchStatusObjList
        .filter((item) => item.status != newStatus)
        .map((item) => item.site_id);
      console.log(finalSiteIds);
      if (finalSiteIds.length == 0) {
        this.$message.error("Nothing to change");
        this.checkedList = [];
        return;
      }

      this.isLoadingModal = true;
      axios
        .post("/api_admin.php?a=medium/edit", {
          site_ids: finalSiteIds.join(","),
          status: newStatus,
        })
        .then((res) => {
          this.isLoadingModal = false;
          this.isShowUpdate = false;
          if (res.code === 0) {
            this.mediumLists.forEach((item, idx) => {
              console.log(finalSiteIds.includes(item.site_id));
              if (finalSiteIds.includes(item.site_id)) {
                item.status = newStatus;
                this.$set(this.mediumLists, idx, item);
              }
            });
            let size = this.mediumLists.length;
            if (this.params.status != "all") {
              this.mediumLists = this.mediumLists.filter(
                (item) => item.status == this.params.status
              );
            }
            this.checkedList = [];
            if (size == 20) {
              this.search();
            }
          }
        })
        .catch((e) => {
          this.isLoadingModal = false;
        });
    },
    getStatusContainer() {
      return document.getElementById("status-box");
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  margin-bottom: 100px;

  .content-title {
    width: 100%;
    padding: 20px 44px 30px 30px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;

    .items {
      display: flex;

      .item {
        width: 262px;
        margin-right: 26px;

        &:last-child {
          margin-right: 0px;
        }

        span {
          display: block;
          font-size: 1rem;
          font-family: "dtc-bold";
          color: #333333;
          line-height: 30px;
          margin-bottom: 4px;
        }

        input {
          width: 100%;
          height: 2.8571rem;
          background: #ffffff;
          border-radius: 6px;
        }

        .select {
          width: 100%;
          height: 2.8571rem;
          // /deep/ .ant-select-selection--single {
          //   height: 2.8571rem;
          //   border-radius: 6px;
          //   background: #fafafa;
          // }
          // /deep/ .ant-select-selection__rendered {
          //   line-height: 2.8571rem;
          // }
          // .icon-down {
          //   color: #333333;
          //   font-size: 1rem;
          // }
        }

        .date {
          width: 100%;
          height: 2.8571rem;

          /deep/ .ant-input {
            height: 2.8571rem;
            border-radius: 25px;
            background: #fafafa;
          }
        }
      }
    }

    .search-reset {
      margin-top: 20px;
      text-align: right;

      button {
        padding: 9px 32px;
        border-radius: 6px;
        cursor: pointer;
        border: none;

        &:first-child {
          background: #2898ff;
          font-size: 1.1429rem;
          color: #ffffff;
          line-height: 20px;
          margin-right: 20px;
        }

        &:last-child {
          border: 1px solid #dddddd;
          font-size: 1.1429rem;
          color: #333333;
          line-height: 20px;
        }
      }
    }
  }

  .content-list {
    width: 100%;
    padding: 70px 32px 20px 32px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    margin-top: 22px;
    position: relative;

    /deep/ .ant-spin-container {
      display: flex;
      flex-wrap: wrap;

      > .medium-card {
        margin-right: 29px;
        margin-bottom: 29px;

        &:nth-child(4n + 1) {
          margin-right: 0;
        }
      }
    }

    &.empty {
      /deep/ .ant-spin-container {
        justify-content: center;
        align-items: center;
      }
    }

    .item-action {
      position: fixed;
      width: 100%;
      min-width: 1200px;
      bottom: 0;
      left: 0;
      height: 114px;
      background: #f2f9ff;
      margin: 0;

      .item-action-inside {
        display: flex;
        width: 1200px;
        justify-content: space-evenly;
        align-items: center;
        height: 114px;
        margin: 0 auto;

        .number {
          div:first-child {
            width: 190px;
            height: 23px;
            font-size: 1.2857rem;
            font-family: dtc-bold;
            color: #2898ff;
            line-height: 23px;
          }

          div:last-child {
            width: 190px;
            height: 20px;
            font-size: 1.1429rem;
            color: #2898ff;
            line-height: 20px;
            margin-top: 16px;
            text-align: center;
          }
        }

        .status {
          position: relative;

          span {
            font-size: 1.1429rem;
            color: #333333;
            line-height: 30px;
            padding-right: 12px;
          }

          .ant-select {
            width: 250px;

            &.holder .ant-select-selection__placeholder {
              display: block !important;
            }
          }
        }
      }

      .item-action-approve {
        user-select: none;
        text-align: center;
        width: 216px;
        height: 2.8571rem;
        background: #2898ff;
        border-radius: 6px;
        font-size: 1.1429rem;
        color: #ffffff;
        line-height: 2.8571rem;
        cursor: pointer;
      }
    }

    .list-prefix {
      height: 22px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: absolute;
      right: 0;
      top: -44px;

      .total-size {
        color: #ccc;
      }

      .select-all {
        user-select: none;
        color: rgba(40, 152, 255, 1);
        cursor: pointer;
      }
    }
  }
}

.give-bonus-content {
  width: 308px;

  .item {
    margin-bottom: 12px;

    h4 {
      margin-bottom: 6px;
      font-size: 1.1429rem;
      font-family: "dtc-bold";
      color: #333333;
      line-height: 30px;
    }

    .input-row {
      display: flex;
      height: 2.8571rem;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    .select {
      width: 8.5714rem;
      height: 2.8571rem;
      margin-left: 10px;
      display: inline-block;

      /deep/ .ant-select-selection--single {
        height: 2.8571rem;
        border-radius: 6px;
        background: #fafafa;
      }

      /deep/ .ant-select-selection__rendered {
        line-height: 2.8571rem;
      }

      .icon-down {
        color: #333333;
        font-size: 1rem;
      }
    }

    .select-input {
      width: 178px;
      height: 2.8571rem;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #e6e6e6;
      box-sizing: border-box;
      outline: none;
      padding-left: 10px;
    }

    input {
      width: 100%;
      height: 2.8571rem;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #e6e6e6;
      box-sizing: border-box;
      outline: none;
      padding-left: 10px;
    }
  }

  & + div .add {
    margin-top: 35px;
    width: 102px;
    height: 2.8571rem;
    background: #2898ff;
    border-radius: 6px;
    outline: none;
    cursor: pointer;
    border: none;
    font-size: 1.1429rem;
    color: #ffffff;
    line-height: 18px;
  }
}

.batch-update-modal {
  /deep/ .update-info {
    padding-bottom: 20px;

    > div {
      + div {
        margin-top: 4px;
      }

      font-size: 1rem;

      &.font-bold {
        font-size: 1.1429rem;
        margin-top: 20px;
      }

      &:first-child {
        margin-top: 0 !important;
      }
    }
  }
}
</style>
