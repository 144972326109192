<template>
  <div id="deposit-history">
    <div class="deposit-checking" v-if="warning == 'Checking'">
      <a-empty></a-empty>
    </div>
    <div class="deposit-warning-yes" v-else-if="warning == 'YES'">
      <img src="@/assets/image-warning-deposit.svg" alt="" />
      <p>Make this initial <span>$100</span> deposit into your account.</p>
      <a-button type="primary" size="large" @click="$emit('makeFirstDeposit')"
        >Make Deposit</a-button
      >
    </div>
    <div class="deposit-history-wrap" v-else>
      <div class="export">
        <button>Export</button>
      </div>
      <a-table
        :columns="columns"
        :data-source="depositHistoryLists"
        bordered
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :pagination="false"
      >
        <template slot="amount" slot-scope="text, record">
          <span>${{ record.amount }}</span>
        </template>
        <template slot="time" slot-scope="text, record">
          <span>{{ record.created_time | formatMDYHI }}</span>
        </template>
        <template slot="source" slot-scope="text, record">
          <span>{{ record.type }}</span>
        </template>
        <template slot="bank" slot-scope="text, record">
          <span>{{ record.detail_info | filterInfo }}</span>
        </template>
        <template slot="number" slot-scope="text, record">
          <span>{{ record.detail_info | filterToken }}</span>
        </template>
        <template slot="auto" slot-scope="text, record">
          <span>{{
            record.is_auto === "1" ? "Auto Recharge" : "Manual Deposit"
          }}</span>
        </template>
        <template slot="invoice" slot-scope="text, record">
          <a
            v-if="
              billingInfo.billing_address !== undefined &&
                billingInfo.billing_address !== ''
            "
            :href="
              'https://app.partnerboost.com/api_admin.php?a=account/pdf&invoice=' +
                record.invoice
            "
            class="invoice-normal"
            >{{ record.invoice }}</a
          >
          <a
            v-else
            href="#"
            @click="editBilling"
            style="color: #7F7F7F;text-decoration: underline;"
            >{{ record.invoice }}</a
          >
        </template>
      </a-table>
      <div class="footer-pagination">
        <a-pagination
          v-model="currentPage"
          :total="totalPage"
          show-less-items
          @change="paginationChange"
        />
      </div>
      <Loading v-if="isLoading"></Loading>
    </div>

    <!-- 更新联系信息 -->
    <Modal
      :isVisible="isShowInformationPopup"
      title="Billing Address"
      width="394px"
      submitText="Update"
      @close-popup="hideInformationPopup"
      @submit="submitInformationPopup"
    >
      <template #content>
        <div class="edit-item">
          <h6>Organization</h6>
          <input type="text" v-model="informationObj.company_name" />
        </div>
        <div class="edit-item">
          <h6>Contact Name</h6>
          <input type="text" v-model="informationObj.contact_name" />
        </div>
        <div class="edit-item">
          <h6>Country</h6>
          <a-select
            @change="handleChangeEditCountry"
            class="select"
            :defaultValue="informationObj.country"
          >
            <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
            <a-select-option
              :value="item.name_en"
              v-for="(item, index) in getCountry"
              :key="index"
              >{{ item.name_en }}</a-select-option
            >
          </a-select>
        </div>
        <div class="edit-item">
          <h6>Address</h6>
          <input type="text" v-model="informationObj.address" />
        </div>
        <div class="edit-item">
          <h6>Address Cont.</h6>
          <input type="text" v-model="informationObj.address_1" />
        </div>
        <div class="edit-item">
          <h6>City</h6>
          <input type="text" v-model="informationObj.city" />
        </div>
        <div class="edit-item">
          <h6>State</h6>
          <a-select
            @change="handleChangeEditState"
            class="select"
            v-if="isSelectUs == 'us'"
            :defaultValue="informationObj.state"
          >
            <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
            <a-select-option
              :value="item.name"
              v-for="(item, index) in usStateLists"
              :key="index"
              >{{ item.name }}</a-select-option
            >
          </a-select>
          <input type="text" v-else v-model="informationObj.state" />
        </div>
        <div class="edit-item">
          <h6>Zip Code</h6>
          <input type="text" v-model="informationObj.zipcode" />
        </div>
        <div class="edit-item">
          <h6>Phone</h6>
          <input type="text" v-model="informationObj.telephone" />
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "Payment Amount",
    dataIndex: "amount",
    key: "amount",
    align: "left !important",
    scopedSlots: { customRender: "amount" },
  },
  {
    title: "Order Time",
    dataIndex: "time",
    key: "time",
    align: "left !important",
    scopedSlots: { customRender: "time" },
  },
  {
    title: "Payment Source",
    dataIndex: "source",
    key: "source",
    align: "left !important",
    scopedSlots: { customRender: "source" },
  },
  {
    title: "Payment Account",
    dataIndex: "bank",
    key: "bank",
    align: "left !important",
    ellipsis: true,
    scopedSlots: { customRender: "bank" },
  },
  {
    title: "Payment Number",
    dataIndex: "number",
    key: "number",
    align: "left !important",
    ellipsis: true,
    scopedSlots: { customRender: "number" },
  },
  {
    title: "Deposit Type",
    dataIndex: "auto",
    key: "auto",
    align: "left !important",
    ellipsis: true,
    scopedSlots: { customRender: "auto" },
  },
  {
    title: "Invoice",
    dataIndex: "invoice",
    key: "invoice",
    align: "left !important",
    ellipsis: true,
    scopedSlots: { customRender: "invoice" },
  },
];
import axios from "@/utils/request";
import moment from "moment-timezone";
import Modal from "@/components/Modal.vue";
import inspectUserPermissions from "@/utils/permissionsJudge";
export default {
  name: "deposit-history",
  props: {
    time_start: {
      type: String,
    },
    time_end: {
      type: String,
    },
    warning: {
      type: String,
      default: "CHECKING",
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      depositHistoryLists: [],
      columns,
      currentPage: 1,
      page_size: 10,
      totalPage: 10,
      isLoading: false,
      isShowInformationPopup: false,
      isSelectUs: "us",
      informationObj: {
        company_name: "",
        contact_name: "",
        country: "",
        address: "",
        address_1: "",
        state: "",
        city: "",
        telephone: "",
        zipcode: "",
      },
      usStateLists: [
        { name: "Alabama" },
        { name: "Alaska" },
        { name: "American Samoa" },
        { name: "Arizona" },
        { name: "Arkansas" },
        { name: "Armed Forces Americas" },
        { name: "Armed Forces Europe, Middle East, & Canada" },
        { name: "Armed Forces Pacific" },
        { name: "California" },
        { name: "Colorado" },
        { name: "Connecticut" },
        { name: "Delaware" },
        { name: "District of Columbia" },
        { name: "Federated States of Micronesia" },
        { name: "Florida" },
        { name: "Georgia" },
        { name: "Guam" },
        { name: "Hawaii" },
        { name: "Idaho" },
        { name: "Illinois" },
        { name: "Indiana" },
        { name: "Iowa" },
        { name: "Kansas" },
        { name: "Kentucky" },
        { name: "Kirklees" },
        { name: "Maine" },
        { name: "Marshall Islands" },
        { name: "Medway" },
        { name: "Merthyr Tydfil [Merthyr Tudful GB-MTU]" },
        { name: "Merton" },
        { name: "Milton Keynes" },
        { name: "Monmouthshire [Sir Fynwy GB-FYN]" },
        { name: "Montana" },
        { name: "Moray" },
        { name: "Neath Port Talbot [Castell-nedd Port Talbot GB-CTL]" },
        { name: "New Hampshire" },
        { name: "New Jersey" },
        { name: "New York" },
        { name: "Newcastle upon Tyne" },
        { name: "Newfoundland" },
        { name: "Newham" },
        { name: "North East Lincolnshire" },
        { name: "North Lanarkshire" },
        { name: "Nottingham" },
        { name: "Omagh" },
        { name: "Ontario" },
        { name: "Palau" },
        { name: "Perth and Kinross" },
        { name: "Plymouth" },
        { name: "Redbridge" },
        { name: "Rochdale" },
        { name: "South Lanarkshire" },
        { name: "South Tyneside" },
        { name: "Torbay" },
        { name: "Torfaen [Tor-faen]" },
        { name: "Virgin Islands" },
        { name: "Wakefield" },
        { name: "Walsall" },
        { name: "Waltham Forest" },
        { name: "West Lothian" },
        { name: "Wiltshire" },
        { name: "Worcestershire" },
      ],
      billingInfo: [],
    };
  },
  created() {
    this._getDepositHistory();
    this.billingInfo = this.$store.state.accountDetails.billing_info;
  },
  computed: {
    accountDetails() {
      return this.$store.state.accountDetails;
    },
    getCountry() {
      if (
        this.$store.state.commonConfig &&
        this.$store.state.commonConfig.countries
      ) {
        return this.$store.state.commonConfig.countries;
      }
      return [];
    },
  },
  methods: {
    _getDepositHistory() {
      this.isLoading = true;
      axios
        .get("api_admin.php?a=account/depositHistory", {
          params: {
            time_start: this.time_start,
            time_end: this.time_end,
            page_num: this.currentPage,
            page_size: this.page_size,
          },
        })
        .then((res) => {
          if (res.code === 0) {
            this.depositHistoryLists = res.data.data;
            this.totalPage = res.data.total_size;
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.$message.error(
              res.msg || "Sorry, server is busy. please try later."
            );
          }
        });
    },
    paginationChange(page, pageSize) {
      this.currentPage = page;
      this._getDepositHistory();
    },
    hideInformationPopup() {
      this.isShowInformationPopup = false;
    },
    handleChangeEditCountry(value) {
      this.isSelectUs = value;
      this.informationObj.country = value;
    },
    handleChangeEditState(value) {
      this.informationObj.state = value;
    },
    showEditInformationPopup() {
      this.iscontactBill = 2;
      this.informationTitle = "Billing Address";
      (this.informationObj.company_name = this.accountDetails.billing_info.company_name),
        (this.informationObj.contact_name = this.accountDetails.billing_info.contact_name),
        (this.informationObj.country = this.accountDetails.billing_info.billing_country),
        (this.informationObj.address = this.accountDetails.billing_info.billing_address),
        (this.informationObj.address_1 = this.accountDetails.billing_info.billing_address_1),
        (this.informationObj.state = this.accountDetails.billing_info.billing_state),
        (this.informationObj.city = this.accountDetails.billing_info.billing_city),
        (this.informationObj.telephone = this.accountDetails.billing_info.billing_telephone),
        (this.informationObj.zipcode = this.accountDetails.billing_info.billing_zipcode);
      if (this.informationObj.state != "us") {
        this.isSelectUs = this.informationObj.state;
      }
      this.isShowInformationPopup = true;
    },
    submitInformationPopup() {
      axios
        .post("/api_admin.php?a=user/updateBillingAddress", {
          contact_name: this.informationObj.contact_name,
          company_name: this.informationObj.company_name,
          billing_country: this.informationObj.country,
          billing_state: this.informationObj.state,
          billing_city: this.informationObj.city,
          billing_telephone: this.informationObj.telephone,
          billing_zipcode: this.informationObj.zipcode,
          billing_address: this.informationObj.address,
          billing_address_1: this.informationObj.address_1,
        })
        .then((res) => {
          if (res.code === 0) {
            this.refreshUserInfo().then((data) => {
              this.isShowInformationPopup = false;
              this.isLoading = false;
              this.billingInfo = this.$store.state.accountDetails.billing_info;
            });
          } else {
            this.isLoading = false;
            this.$message.error(
              res.msg || "Sorry, server is busy. please try later."
            );
          }
        });
    },
    downInvoice() {
      axios.get("/api_admin.php?a=creative/pdf", {}, (res) => {
        console.log(res);
      });
    },
    editBilling() {
      var that = this;
      this.$confirm({
        title: "",
        content:
          "Your billing address is required in order to generate your invoice",
        okText: "Enter Billing Address",
        cancelText: "Cancel",
        onOk() {
          that.showEditInformationPopup();
        },
        onCancel() {},
      });
    },
  },
  filters: {
    filterInfo(item) {
      if (item) {
        let info = JSON.parse(item);
        return info.payer_email;
      }
      return "";
    },
    filterToken(item) {
      if (item) {
        let info = JSON.parse(item);
        return info.token;
      }
      return "";
    },
  },
};
</script>

<style lang="less" scoped>
.deposit-history-wrap {
  margin-top: 22px;

  p {
    font-size: 1.1429rem;
    color: #999999;
    line-height: 20px;
    margin-bottom: 22px;
  }

  .export {
    margin-bottom: 22px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      width: 116px;
      height: 32px;
      border-radius: 6px;
      font-size: 1rem;
      line-height: 30px;
      text-align: center;
      vertical-align: middle;
      box-sizing: border-box;
      padding: 0;
      border: 1px solid #2898ff;

      cursor: pointer;

      &:first-child {
        background: #2898ff;
        color: #ffffff;
        width: 134px;
        margin-right: 16px;
      }

      &:last-child {
        background: #fff;
        color: #2898ff;
      }
    }
  }

  .footer-pagination {
    margin-top: 22px;
    text-align: right;
  }

  .input {
    width: 100%;
    height: 2.8571rem;
    background: #fafafa;
    position: relative;

    input {
      width: 100%;
      height: 36px;
      border: 1px solid #e6e6e6;
      outline: none;
      border-radius: 6px;
      padding-left: 26px;
    }

    span {
      font-size: 1.1429rem;
      color: #333333;
      line-height: 20px;
      position: absolute;
      top: 8px;
      left: 14px;
    }
  }
}

.deposit-checking,
.deposit-warning-yes {
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.deposit-warning-yes {
  p {
    font-size: 1.2857rem;
    margin-top: 2.8571rem;
    margin-bottom: 30px;
    height: 20px;
    color: #333333;

    span {
      color: #2898ff;
    }
  }
}
.invoice-normal {
  color: rgb(5, 120, 255);
  cursor: pointer;
  text-decoration: underline;
}
.edit-item {
  margin-bottom: 12px;

  h6 {
    font-size: 1.1429rem;
    font-family: "dtc-bold";
    color: #333333;
    line-height: 30px;
    margin-bottom: 6px;
  }

  input {
    width: 100%;
    height: 2.8571rem;
    border-radius: 6px;
    border: 1px solid #e6e6e6;
    padding-left: 10px;
    outline: none;
  }

  .select {
    width: 100%;
    height: 2.8571rem;

    /deep/ .ant-select-selection--single {
      height: 2.8571rem;
      border-radius: 6px;
      background: #fafafa;
    }

    /deep/ .ant-select-selection__rendered {
      line-height: 2.8571rem;
    }

    .icon-down {
      color: #333333;
      font-size: 1rem;
    }
  }
}
</style>
