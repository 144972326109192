<template>
  <div class="addbank">
    <Header collection="My Account"></Header>
    <Brands
      collection="Payment Methods"
      path="/account/payment-setting/select"
      subset="Add Payment Methods"
    ></Brands>
    <div class="container">
      <a-form
        :form="formInfo"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }"
        @submit="handleSubmit"
      >
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item
              :label="isPayoneer ? 'Payoneer Email' : 'Paypal Email'"
              labelAlign="left"
              :colon="false"
            >
              <a-input
                :disabled="disabledBankInfo || isPayoneer"
                v-decorator="[
                  `accountcard`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your Email',
                      },
                    ],
                  },
                ]"
                placeholder=""
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item
              :label="isPayoneer ? 'Payoneer Name' : 'Paypal Name'"
              labelAlign="left"
              :colon="false"
            >
              <a-input
                :disabled="disabledBankInfo || isPayoneer"
                v-decorator="[
                  `username`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your Name',
                      },
                    ],
                  },
                ]"
                placeholder=""
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24" v-if="!isPayoneer">
          <a-col :span="12">
            <a-form-item
              label="Paypal Country"
              labelAlign="left"
              :colon="false"
            >
              <a-select
                :disabled="disabledBankInfo"
                v-decorator="[
                  'user_country_id',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please select a Paypal Country',
                      },
                    ],
                  },
                ]"
                placeholder=""
              >
                <a-select-option
                  v-for="item in countrys"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name_en }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item
              label="Withdraw Threshold"
              labelAlign="left"
              :colon="false"
            >
              <a-select
                v-decorator="[
                  'threshold',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please select a Withdraw Threshold',
                      },
                    ],
                  },
                ]"
                placeholder="Please select a Withdraw Threshold"
              >
                <a-select-option v-if="isPayoneer" value="15"
                  >$15</a-select-option
                >
                <a-select-option value="50">$50</a-select-option>
                <a-select-option value="100">$100</a-select-option>
                <a-select-option value="500">$500</a-select-option>
                <a-select-option value="1000">$1000</a-select-option>
                <a-select-option value="2500">$2500</a-select-option>
                <a-select-option value="5000">$5000</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item labelAlign="left" :colon="false">
              <div slot="label">
                <span
                  style="
                    color: #f5222d;
                    font-size: 14px;
                    font-family: SimSun, sans-serif;
                  "
                  >*</span
                >
                Tax Info
              </div>
              <div class="form-upload form-item-lg" @click="showUpload = true">
                Upload
              </div>
              <!-- 较验 -->
              <a-input style="display: none"></a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-button class="btn-add" type="primary" html-type="submit"
          >Save</a-button
        >
      </a-form>

      <UploadModel
        @onSubmit="onSubmit"
        :showUpload="showUpload"
        @onClose="showUpload = false"
        :init="detail"
      />
    </div>
  </div>
</template>
<script>
import Header from "@/components/public/Header.vue";
import Brands from "@/components/Brands.vue";
import axios from "@/utils/request";
import moment from "moment-timezone";
import apis from "@/api/const";
import UploadModel from "./UploadModel.vue";
export default {
  filters: {
    filterStaus(status) {
      if (status == "new") {
        return "Unpaid";
      } else if (status == "paid") {
        return "Paid";
      } else {
        return "Void";
      }
    },
  },
  components: {
    Header,
    Brands,
    UploadModel,
  },
  data() {
    return {
      formInfo: this.$form.createForm(this, { name: "form-bank" }),
      countrys: [],
      detail: {},
      showUpload: false,
      fileInfo: null,
    };
  },
  computed: {
    isPayoneer() {
      const { type } = this.$route.query;
      return !!type && type == "payoneer";
    },
    disabledBankInfo() {
      return this.detail.status == 1;
    },
  },
  mounted() {
    this.getCountry();
    let { id } = this.$route.params;
    if (id) {
      this.getAccountDetailById(id);
    }
  },
  methods: {
    onSubmit(val) {
      this.fileInfo = val;
      this.formInfo.setFieldsValue({ taxInfo: "1" });
    },
    validateFile() {
      console.log(this.fileInfo);
      if (
        !this.fileInfo?.file ||
        !this.fileInfo?.tax_number ||
        (!this.fileInfo?.tax_from_type && this.fileInfo.tax_from_type != 0)
      ) {
        this.$message.error("please input a tax info");
        return false;
      }
      return true;
    },
    getCountry() {
      axios.get(apis.paymentSeting.foreign_country, {}).then((res) => {
        if (res.code === "0200") {
          this.countrys = res.payload;
        } else {
          this.$message.error(res.msg || "");
        }
      });
    },
    getAccountDetailById(id) {
      axios
        .get(apis.paymentSeting.account_detail, { params: { id } })
        .then((res) => {
          if (res.code === "0200") {
            this.formInfo.setFieldsValue({ ...res.payload });
            this.detail = res.payload;
            this.fileInfo = {
              tax_number: res.payload.tax_number,
              file: res.payload.tax_file,
              tax_from_type: res.payload.file_type,
            };
          } else {
            this.$message.error(res.msg || "");
          }
        });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.formInfo.validateFields((err, values) => {
        if (!err) {
          this.addPaypal(values);
        }
      });
    },
    addPaypal(values) {
      if (!this.validateFile()) return;
      let { accountcard, username, user_country_id, threshold } = values;
      var data = new FormData();
      let { id } = this.$route.params;
      if (id) {
        data.append("id", id);
      }
      if (this.isPayoneer) {
        data.append("atype", 11);
        data.append("threshold", threshold);
        data.append("tax_number", this.fileInfo.tax_number);
        data.append("file", this.fileInfo.file);
        data.append("tax_from_type", this.fileInfo.tax_from_type);
      } else {
        data.append("source", 1);
        data.append("mtype", 0);
        data.append("atype", 10);
        data.append("wd_currency", "USD");
        data.append("accountcard", accountcard);
        data.append("username", username);
        data.append("user_country_id", user_country_id);
        data.append("threshold", threshold);
        data.append("tax_number", this.fileInfo.tax_number);
        data.append("file", this.fileInfo.file);
        data.append("tax_from_type", this.fileInfo.tax_from_type);
      }

      axios.post(apis.paymentSeting.do_pay_account, data).then((res) => {
        if (res.code === "0200") {
          this.$message.success(res.msg);
          this.$router.push({ name: "my_payment_setting" });
        } else {
          this.$message.error(res.msg || "");
        }
      });
    },
  },
  beforeDestroy() {
    if (this.onWindowResize) {
      window.onresize = null;
    }
  },
};
</script>

<style lang="less" scoped>
.addbank {
  .container {
    background: #ffffff;
    border-radius: 1.1429rem;
    padding: 32px;
  }
  .form-line {
    margin-bottom: 20px;
  }
  .form-col {
    padding-left: 13.1429rem !important;
  }
  .form-rt {
    width: 33.4286rem;
  }
  .form-upload {
    // width: 29.9286rem;
    height: 2.8571rem;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      border: 1px solid #3762d6;
      color: #3762d6;
    }
  }
  .btn-add {
    margin-left: 12.2857rem;
  }
}
/deep/ .ant-form-item-no-colon {
  color: #999999;
}
/deep/ .ant-input {
  height: 2.8571rem;
}
</style>
