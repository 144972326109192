import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

import OrderDetails from "@/views/Reports/OrderDetails.vue";
import Report from "@/views/Reports/Report.vue";
import List from "@/views/Publishers/List.vue";
import Discovery from "@/views/Publishers/Discovery.vue";
import Pending from "@/views/Publishers/Pending.vue";
import Detail from "@/views/Publishers/Detail.vue";
import TransactionInquiry from "@/views/Publishers/TransactionInquiry.vue";
import Recruitment from "@/views/Recruitment.vue";
import AccountDetails from "@/views/Account/Details2.vue";
import Settlement from "@/views/Account/Settlement.vue";
import MessageList from "../views/MessageList/MessageList.vue";
import SignUp from "@/views/SignUp/index.vue";
import PaymentSuccess from "@/views/PaymentSuccess.vue";
import PaymentError from "@/views/PaymentError.vue";
import Empty from "@/views/Empty.vue";
import CreativeLayout from "@/views/Creatives/Layout.vue";

import Login from "@/views/Login.vue";
import LoginView from "../views/LoginReset/LoginView.vue";

import AccountEdit from "@/views/Account/DetailsChild/ChildAccountDetails.vue";
// import LoginInfo from "@/views/Account/DetailsChild/ChildLoginInfo.vue";
import EmailSetting from "@/views/Account/DetailsChild/ChildEmailSetting.vue";
import SubAcc from "@/views/Account/DetailsChild/ChildSubAcc.vue";
import ProgramSettings from "@/views/Account/DetailsChild/ChildProgramSettings.vue";
import PaymentMethods from "@/views/Account/DetailsChild/ChildPaymentMethods.vue";
import Communications from "@/views/Account/DetailsChild/ChildAcommunications.vue";
import Management from "@/views/Account/DetailsChild/ChildManagement.vue";

import Banners from "@/views/Creatives/Banners.vue";
import Texts from "@/views/Creatives/Texts.vue";
import Coupons from "@/views/Creatives/Coupons.vue";
import Datafeed from "@/views/Creatives/Datafeed.vue";

// import Campaign from "@/views/Campaign/index.vue";
import CreateCampaign from "@/views/Campaign/Created.vue";
import Overview from "@/views/Campaign/Overview.vue";

// import Reporting from "@/views/Campaign/Reporting.vue";
// import Influencer from "@/views/Campaign/Influencer.vue";
// import InfluencerNewletters from "@/views/Campaign/InfluencerNewletters.vue";
// import InfluencerSendletters from "@/views/Campaign/InfluencerSendletters.vue";
// import InfluLetterDetail from "@/views/Campaign/InfluLetterDetail.vue";
// import Proposals from "@/views/Campaign/Proposals.vue";
// import Contents from "@/views/Campaign/Contents.vue";
// import InfluencerDiscovery from "@/views/Publishers/InfluencerDiscovery.vue";
// import Creatives from "@/views/Campaign/Creatives.vue";
// import Tools from "@/views/Campaign/Tools.vue";
// import Payments from "@/views/Campaign/Payments.vue";

/* ----------news------------- */

import Layout from "@/views/Layout.vue";
import Dashboard from "@/views/Dashboard/index.vue";
import Campaign from "@/views/Campaign/index.vue";
import CampaignDetail from "@/views/Campaign/CampaignDetail.vue";
import CampaignDetailPreview from "@/views/Campaign/CampaignDetailPreview.vue";
import Payments from "@/views/Payments/index.vue";
import PaymentsHistory from "@/views/Payments/PaymentsHistory.vue";
import BasicInfo from "@/views/My/BasicInfo.vue";
import Channels from "@/views/My/Channels.vue";
import EmailSettings from "@/views/My/EmailSettings.vue";
import PaymentSettings from "@/views/My/PaymentSettings.vue";
import AddPayment from "@/views/My/AddPayment.vue";
import AddPaymentBank from "@/views/My/AddPaymentBank.vue";
import AddPaymentPaypal from "@/views/My/AddPaymentPaypal.vue";
import LoginInfo from "@/views/My/LoginInfo.vue";
import BrandList from "@/views/Brands/index.vue";
import BrandDetail from "@/views/Brands/BrandDetail.vue";
import DeepLinkBookMark from "@/views/Tools/DeepLinkBookMark.vue";
import CustomLinks from "@/views/Tools/CustomLinks.vue";
import ReportingApi from "@/views/Tools/ReportingApi.vue";
import PaymentApi from "@/views/Tools/PaymentApi.vue";
import SettlementApi from "@/views/Tools/SettlementApi.vue";
import ForgotPassword from "@/views/forgot-password.vue";
import EmailVerification from "@/views/SignUp/EmailVerification.vue";
import CodeVerification from "@/views/SignUp/CodeVerification.vue";
import ChannelAdd from "@/views/ChannelAdd/index.vue";
import ChannelAddMedia from "@/views/ChannelAdd/media.vue";
import ChannelAddChannel from "@/views/ChannelAdd/channel.vue";
import ChannelAddApp from "@/views/ChannelAdd/app.vue";
import MerchantStatusApi from "@/views/Tools/MerchantStatusApi.vue";
import MerchantInfoApi from "@/views/Tools/MerchantInfoApi.vue";
import Reporting from "@/views/Reporting/index.vue";
import ReportingCps from "@/views/Reporting/ReportingCps.vue";
import TransactionInquiries from "@/views/Reporting/TransactionInquiries.vue";
import TransactionOrderList from "@/views/Reporting/TransactionOrderList.vue";
import TransactionImport from "@/views/Reporting/TransactionImport.vue";
import CpsDownList from "@/views/Reporting/CpsDownList.vue";
import ChangePwd from "@/views/My/ChangePwd.vue";
import ClickReportApi from "@/views/Tools/ClickReportApi.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/autologin",
    name: "autologin",
    component: () => import("@/views/AutoLogin.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/forgot",
    name: "forgot",
    component: ForgotPassword,
  },
  {
    path: "/sign",
    redirect: "/signup",
  },
  {
    path: "/signup/account",
    redirect: "/signup",
  },
  {
    path: "/signup",
    name: "signup",
    component: SignUp,
  },
  {
    path: "/email-verification",
    name: "email-verification",
    component: EmailVerification,
  },
  {
    path: "/code-verification",
    name: "code-verification",
    component: CodeVerification,
  },
  {
    path: "/channel-add",
    name: "channel-add",
    component: Layout,
    children: [
      {
        path: "/",
        name: "channel-add2",
        component: ChannelAdd,
      },
      {
        path: "/channel-add/media",
        name: "channel-add-media",
        component: ChannelAddMedia,
      },
      {
        path: "/channel-add/channel",
        name: "channel-add-channel",
        component: ChannelAddChannel,
      },
      {
        path: "/channel-add/app",
        name: "channel-add-app",
        component: ChannelAddApp,
      },
    ],
  },
  {
    path: "/",
    name: "home-page",
    redirect: "/dashboard",
    component: () => import("@/views/HomePage"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "/announcements",
        name: "announcements",
        component: () => import("@/views/Dashboard/List"),
      },
      {
        path: "/announcementDetail/:id",
        name: "announcementDetail",
        component: () => import("@/views/Dashboard/Detail"),
      },
      {
        path: "/reporting",
        component: Layout,
        children: [
          {
            path: "/",
            name: "reporting",
            component: Reporting,
          },
          {
            path: "/reporting/cps",
            name: "reporting_cps",
            component: ReportingCps,
          },
          {
            path: "/reporting/trans-inquiry",
            name: "reporting_trans_inquiry",
            component: TransactionInquiries,
          },
          {
            path: "/reporting/trans-orders",
            name: "reporting_trans_orders",
            component: TransactionOrderList,
          },
          {
            path: "/reporting/trans-import",
            name: "reporting_trans_import",
            component: TransactionImport,
          },
          {
            path: "/reporting/cps-downlist",
            name: "reporting_cps_downlist",
            component: CpsDownList,
          },
        ],
      },
      {
        path: "/campaigns",
        component: Layout,
        children: [
          {
            path: "/",
            name: "campaign",
            component: Campaign,
          },
          {
            path: "/campaigns/:id",
            name: "campaign_detail",
            component: CampaignDetail,
          },
          {
            path: "/campaigns/:id/preview",
            name: "campaign_detail_preview",
            component: CampaignDetailPreview,
          },
        ],
      },
      {
        path: "/payments",
        component: Layout,
        children: [
          {
            path: "/",
            name: "payments",
            component: Payments,
          },
          {
            path: "/payments/history",
            name: "payments_history",
            component: PaymentsHistory,
          },
        ],
      },
      {
        path: "/brands",
        component: Layout,
        children: [
          {
            path: "/",
            name: "brands",
            component: BrandList,
          },
          {
            path: "/brands/:sid/:id",
            name: "brand_detail",
            component: BrandDetail,
          },
        ],
      },
      {
        path: "/tools",
        name: "tools",
        component: Layout,
        children: [
          {
            path: "/",
            name: "tools_deep_link",
            component: DeepLinkBookMark,
          },
          {
            path: "/tools/custom-links",
            name: "tools_custom_links",
            component: CustomLinks,
          },
          {
            path: "/tools/reporting-api",
            name: "tools_reporting_api",
            component: ReportingApi,
          },
          {
            path: "/tools/click-api",
            name: "click_report_api",
            component: ClickReportApi,
          },
          {
            path: "/tools/payment-api",
            name: "tools_payment_api",
            component: PaymentApi,
          },
          {
            path: "/tools/settlement-api",
            name: "settlement_api",
            component: SettlementApi,
          },
          {
            path: "/tools/status-api",
            name: "tools_merstatus_api",
            component: MerchantStatusApi,
          },
          {
            path: "/tools/info-api",
            name: "tools_merinfo_api",
            component: MerchantInfoApi,
          },
        ],
      },
      {
        path: "/account",
        component: Layout,
        children: [
          {
            path: "/",
            name: "my_basicinfo",
            component: BasicInfo,
          },
          {
            path: "/account/channels",
            name: "my_channel",
            component: Channels,
          },
          {
            path: "/account/email-setting",
            name: "my_email_setting",
            component: EmailSettings,
          },
          {
            path: "/account/payment-setting",
            name: "my_payment_setting",
            component: PaymentSettings,
          },
          {
            path: "/account/payment-setting/select",
            name: "add_payment_select",
            component: AddPayment,
          },
          {
            path: "/account/payment-setting/add-bank",
            name: "add_payment_bank",
            component: AddPaymentBank,
          },
          {
            path: "/account/payment-setting/add-bank/:id",
            name: "add_payment_bank_edit",
            component: AddPaymentBank,
          },
          {
            path: "/account/payment-setting/add-paypal",
            name: "add_payment_paypal",
            component: AddPaymentPaypal,
          },
          {
            path: "/account/payment-setting/add-paypal/:id",
            name: "add_payment_paypal_edit",
            component: AddPaymentPaypal,
          },
          {
            path: "/account/login-info",
            name: "my_login_info",
            component: LoginInfo,
          },
          {
            path: "/account/change-pwd",
            name: "my_change_pwd",
            component: ChangePwd,
          },
        ],
      },
    ],
  },
  {
    path: "/",
    name: "home",
    redirect: "/dashboard",
    component: () => import("@/views/Home"),
    children: [
      {
        path: "/message",
        name: "message",
        component: MessageList,
      },
      {
        path: "/report",
        redirect: "/report/order",
        name: "report",
        component: Layout,
        children: [
          {
            path: "order",
            name: "report_orders",
            component: OrderDetails,
          },
          {
            path: "report",
            name: "report_reports",
            component: Report,
          },
        ],
      },
      {
        path: "/publisher",
        redirect: "/publisher/list",
        name: "publisher",
        component: Layout,
        children: [
          {
            path: "list",
            name: "publisher_list",
            component: List,
            // }, {
            //   path: 'search',
            //   name: 'search_publisher',
            //   component: SearchMedium
          },
          {
            path: "pending",
            name: "publisher_pending",
            component: Pending,
          },
          {
            path: "detail/:id",
            name: "publisher_detail",
            component: Detail,
            props: true,
          },
          {
            path: "transaction_inquiry",
            name: "transaction_inquiry",
            component: TransactionInquiry,
          },
          {
            path: "discovery",
            name: "discovery",
            component: Discovery,
          },
          //   {
          //     path: "influence-discovery",
          //     name: "influence_discovery",
          //     component: InfluencerDiscovery,
          //   },
        ],
      },
      {
        path: "/creative",
        redirect: "/creative/image",
        name: "creative",
        component: CreativeLayout,
        children: [
          {
            path: "image",
            name: "creative_banner",
            component: Banners,
          },
          {
            path: "text",
            name: "creative_text",
            component: Texts,
          },
          {
            path: "coupon",
            name: "creative_coupon",
            component: Coupons,
          },
          {
            path: "datafeed",
            name: "creative_datafeed",
            component: Datafeed,
          },
        ],
      },
      {
        path: "/account",
        name: "my-account",
        component: Layout,
        children: [
          {
            path: "details",
            name: "details",
            component: AccountDetails,
            redirect: { name: "account-edit" },
            children: [
              {
                path: "edit",
                name: "account-edit",
                component: AccountEdit,
              },
              {
                path: "program-settings",
                name: "program-settings",
                component: ProgramSettings,
              },
              {
                path: "payment-methods",
                name: "payment-methods",
                component: PaymentMethods,
              },
              //   {
              //     path: "login-info",
              //     name: "login-info",
              //     component: LoginInfo,
              //   },
              {
                path: "email-setting",
                name: "email-setting",
                component: EmailSetting,
              },
              {
                path: "sub-account",
                name: "sub-account",
                component: SubAcc,
              },
            ],
          },
          {
            path: "balance_details",
            name: "balance_details",
            component: Settlement,
          },
        ],
      },
    ],
  },
  {
    path: "/recruitment/*",
    name: "Recruitment",
    component: Recruitment,
  },
  {
    path: "/payment-success",
    name: "payment-success",
    component: PaymentSuccess,
  },
  {
    path: "/payment-error",
    name: "payment-error",
    component: PaymentError,
  },
  {
    path: "*",
    name: "404",
    component: Empty,
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    from.name === "CreateNewPage" &&
    (to.name === "tools_Recruitment" || to.name === "Recruitment")
  ) {
    from.meta.keepAlive = true;
  } else {
    from.meta.keepAlive = false;
  }

  // // 注册 头部文字
  // if (to.name == "account") {
  //   store.commit("setBannerTitle", "Brand Account");
  // } else if (to.name == "commission") {
  //   store.commit("setBannerTitle", "Sale Commission");
  // } else if (to.name == "information") {
  //   store.commit("setBannerTitle", "Attract Publisher");
  // } else if (to.name == "media") {
  //   store.commit("setBannerTitle", "Manage Publishers");
  // } else if (to.name == "tracking_test") {
  //   store.commit("setBannerTitle", "Tracking Installation<br/>and Testing");
  // } else if (to.name == "payment") {
  //   store.commit("setBannerTitle", "Payment");
  // } else if (to.name == "processing") {
  //   store.commit("setBannerTitle", "Processing");
  // }

  // 添加选中样式
  if (to.path.startsWith("/dashboard")) {
    store.commit("setActMenu", "dashboard");
  } else if (to.path.startsWith("/reporting/cps")) {
    store.commit("setActMenu", "reporting-cps");
  } else if (to.path.startsWith("/reporting/trans-inquiry")) {
    store.commit("setActMenu", "reporting-trans-inquiry");
  } else if (to.path.startsWith("/reporting/")) {
    store.commit("setActMenu", "flow-report");
  } else if (to.path.startsWith("/campaigns")) {
    store.commit("setActMenu", "campaign");
  } else if (to.path.startsWith("/brands")) {
    store.commit("setActMenu", "brands");
  } else if (to.path.startsWith("/account/channel")) {
    store.commit("setActMenu", "my-chanels");
  } else if (to.path.startsWith("/account/payment-setting")) {
    store.commit("setActMenu", "my-settings");
  } else if (to.path.startsWith("/account/email-setting")) {
    store.commit("setActMenu", "my-email");
  } else if (to.path.startsWith("/account/login-info")) {
    store.commit("setActMenu", "my-login");
  } else if (to.path.startsWith("/account/change-pwd")) {
    store.commit("setActMenu", "my-login");
  } else if (to.path.startsWith("/account")) {
    store.commit("setActMenu", "my-basic");
  } else if (to.path.startsWith("/tools/custom-links")) {
    store.commit("setActMenu", "tools-links");
  } else if (to.path.startsWith("/tools/click-api")) {
    store.commit("setActMenu", "tools-clickapi");
  } else if (to.path.startsWith("/tools/reporting-api")) {
    store.commit("setActMenu", "tools-repapi");
  } else if (to.path.startsWith("/tools/payment-api")) {
    store.commit("setActMenu", "tools-payapi");
  } else if (to.path.startsWith("/tools/settlement-api")) {
    store.commit("setActMenu", "tools-settleapi");
  } else if (to.path.startsWith("/tools/status-api")) {
    store.commit("setActMenu", "tools-statusapi");
  } else if (to.path.startsWith("/tools/info-api")) {
    store.commit("setActMenu", "tools-infoapi");
  } else if (to.path.startsWith("/tools/")) {
    store.commit("setActMenu", "tools-deep");
  } else if (to.path.startsWith("/payments/history")) {
    store.commit("setActMenu", "payments-history");
  } else if (to.path.startsWith("/payments")) {
    store.commit("setActMenu", "payments-home");
  }
  // next()

  if (to.path.startsWith("//")) {
    next(to.path.substring(1));
    return;
  }

  //   if (to.name == 'commission') {
  document.documentElement.scrollTop = 0;
  //   }
  let isNoLoginPage =
    to.fullPath.startsWith("/login") ||
    to.fullPath.startsWith("/signup") ||
    to.fullPath.startsWith("/reset") ||
    to.fullPath.startsWith("/forgot") ||
    to.fullPath.startsWith("/email-verification") ||
    to.fullPath.startsWith("/code-verification") ||
    to.fullPath.startsWith("/channel-add") ||
    to.fullPath.startsWith("/recruitment") ||
    to.fullPath.startsWith("/autologin");
  let token = store.state.token;

  if (isNoLoginPage) {
    if (token) {
      if (
        to.fullPath.startsWith("/signup") ||
        to.fullPath.startsWith("/login")
      ) {
        next("/");
      } else {
        next();
      }
    } else {
      next();
    }
  } else if (token) {
    if (to.fullPath.startsWith("/login") || to.fullPath.startsWith("/signup")) {
      next("/");
    } else {
      next();
    }
  } else {
    if (isNoLoginPage) {
      next();
    } else {
      next("/login");
    }
  }
});

export default router;
