import { webRoot } from "@/utils/config";
export default {
  BASE_URL: "",
  common: {
    country_list: "/m/user/c/index/a/foreignCountry",
    upload_file: "/m/user/c/index/a/doUpload",
    get_link: "/m/advertiser/c/index/a/doSelfLink",
    get_camp_link: "m/campaigns/c/index/a/doSelfLink",
    get_vcode: "/m/index/c/login/a/get_code",
    get_vcode_sid: "/m/index/c/login/a/get_code",
    site_info: "/m/site/c/index/a/getProfileSite",
    find_pwd: "/m/index/c/register/a/findPassword",
    verify_track: "/m/index/c/register/a/checkResetPassword",
    pwd_reset: "/m/index/c/register/a/resetPassword",
    pwd_email_resend: "m/index/c/register/a/reSendPasswordEmail",
    loginAdmin: "/m/index/c/login/a/loginAdmin",
  },
  channel: {
    cata_list: "/m/site/c/index/a/typeList",
    index: "/m/site/c/index/a/index",
    site_list: "/m/site/c/index/a/channelByCat",
    site_create: "/m/site/c/index/a/doCreateSite",
    site_detail: "/m/site/c/index/a/getChannelDetail",
    site_delete: "/m/site/c/index/a/delSite",
  },
  auth: {
    login: "/m/index/c/login/a/loginCheck",
    register_1: "/m/index/c/register/a/registerStep1",
    email_verify: "/m/index/c/register/a/emailVerify",
    email_re_send: "/m/index/c/register/a/reSendRegisterVerifyEmail",
    email_check_repeat: "m/index/c/register/a/checkEmailUnique",
  },
  dashboard: {
    home: "/m/finances/c/index/a/myCommissionList",
    noticeList: "/m/finances/c/index/a/noticeList",
  },
  brands: {
    join_site: "/m/advertiser/c/index/a/advApply",
    join_site_bacth: "/m/advertiser/c/index/a/multiApply",
    store_search: "/m/advertiser/c/index/a/suggestMerchant",
    type_list: "/m/advertiser/c/index/a/merchantSearchInfo",
    store_detail: "/m/advertiser/c/index/a/detail",
    index_search: "/m/advertiser/c/index/a/index",
  },
  campaign: {
    type_list: "/m/campaigns/c/index/a/getEnum",
    camp_list: "/m/campaigns/c/index/a/list",
    camp_detail: "/m/campaigns/c/index/a/detail",
    app_type: "/m/campaigns/c/index/a/getAppEnum",
    app_detail: "/m/campaigns/c/index/a/appDetail",
    app_create: "/m/campaigns/c/index/a/appAdd",
  },
  reporting: {
    advertisers: "/m/advertiser/c/index/a/suggestMerchant",
    traffic: "/m/analysis/c/index/a/trafficReport",
    cps: "/m/analysis/c/index/a/cpsReport",
    cps_download: "/m/analysis/c/index/a/cpsDownload",
    cps_download_list: "/m/analysis/c/index/a/cpsDownList",
    order_lost: "/m/analysis/c/index/a/orderLost",
  },
  my: {
    user_info: "/m/user/c/index/a/profile",
    basic_info: "/m/user/c/index/a/getBasicInfo",
    channel: "/m/site/c/index/a/index",
    edit_basic: "/m/user/c/index/a/editBasicInfo",
    edit_top: "/m/user/c/index/a/editBasicInfoHead",
    set_channel_def: "/m/site/c/index/a/setDefault",
    email_save: "/m/user/c/index/a/doSubscribe",
    email_list: "/m/user/c/index/a/subscribeList",
    email_search: "/m/user/c/index/a/searchSubscribeMerchants",
    change_pwd: "/m/user/c/index/a/changePassword",
    change_email: "/m/user/c/index/a/changeEmail",
    delete_media: "/m/user/c/index/a/delBasicMedia",
  },
  tools: {
    deep_link: "/m/tools/c/index/a/toolBookmark",
    slef_link_site: "/m/tools/c/index/a/selfLinkSite",
    slef_link_export: "/m/tools/c/index/a/selfLinkExport",
    slef_link_list: "/m/tools/c/index/a/selfLinkList",
  },
  payments: {
    user_finance_info: "/m/finances/c/index/a/userFinanceInfo",
    with_draw_list: "/m/finances/c/index/a/withdrawList",
    finance_list: "/m/finances/c/index/a/financeList",
    cash_list: "/m/finances/c/index/a/cashList",
  },
  paymentSeting: {
    pay_account: "/m/user/c/index/a/payAccount",
    change_pwd: "/m/user/c/index/a/changePayPassword",
    delete_account: "/m/user/c/index/a/deleteAccount",
    set_default: "/m/user/c/index/a/setAccountDefault",
    do_pay_account: "/m/user/c/index/a/doPayAccount",
    bank: "/m/user/c/index/a/bank",
    city: "/m/user/c/index/a/city",
    foreign_country_region: "/m/user/c/index/a/foreignCountryRegion",
    foreign_country: "/m/user/c/index/a/foreignCountry",
    account_detail: "/m/user/c/index/a/payAccountDetail",
    check_pay_password: "/m/user/c/index/a/checkPayPassword",
  },
};
export const DEFAULT_LOGO = require("@/assets/default.png");
export const PLATFORMS = [
  {
    name: "facebook",
    full_name: "Facebook",
    icon: webRoot + "/public/image/icon/icon-facebook.svg",
    order: 1,
  },
  {
    name: "instagram",
    full_name: "Instagram",
    icon: webRoot + "/public/image/icon/icon-instagram.svg",
    order: 2,
  },
  {
    name: "youtube",
    full_name: "Youtube",
    icon: webRoot + "/public/image/icon/icon-youtube.svg",
    order: 3,
  },
  {
    name: "tiktok",
    full_name: "Tiktok",
    icon: webRoot + "/public/image/icon/icon-tiktok.svg",
    order: 4,
  },
  {
    name: "blog_website",
    full_name: "Blog/Website",
    icon: webRoot + "/public/image/icon/icon-weibo.svg",
    order: 5,
  },
  {
    name: "other",
    full_name: "Other",
    icon: webRoot + "/public/image/icon/icon-social-other.svg",
    order: 6,
  },
];

export const FORMATS = [
  {
    name: "blog_text",
    full_name: "Blog/Text",
    icon: webRoot + "/public/image/icon/blog_text.svg",
    order: 1,
  },
  {
    name: "video",
    full_name: "Video",
    icon: webRoot + "/public/image/icon/video.svg",
    order: 2,
  },
  {
    name: "image",
    full_name: "Image",
    icon: webRoot + "/public/image/icon/image.svg",
    order: 3,
  },
  {
    name: "other",
    full_name: "Other",
    icon: webRoot + "/public/image/icon/other.svg",
    order: 4,
  },
];

export const PAY_TYPES = [
  { name: "cps", text: "CPS", order: 1, width: 40 },
  { name: "sponsored_fee", text: "Sponsored Fee", order: 2, width: 98 },
  { name: "coupon_code", text: "Coupon Code", order: 3, width: 94 },
  { name: "free_products", text: "Free Products", order: 4, width: 94 },
  { name: "gift_cards", text: "Gift Cards", order: 5, width: 95 },
  { name: "other", text: "Other", order: 6, width: 50 },
];
export const PAY_TYPES_SELECT = [
  {
    value: "cps",
    label: "CPS",
  },
  {
    value: "sponsored_fee",
    label: "Sponsored Fee",
  },
  {
    value: "coupon_code",
    label: "Coupon Code",
  },
  {
    value: "free_products",
    label: "Free Products",
  },
  {
    value: "gift_cards",
    label: "Gift Cards",
  },
  {
    value: "other",
    label: "Other",
  },
];
