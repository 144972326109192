<template>
  <a-auto-complete :style="{ width }" @search="handleSearch" @change="onSelect">
    <template slot="dataSource">
      <a-select-option v-for="item in list" :key="item.m_id">
        {{ item.m_id }}
      </a-select-option>
    </template>
  </a-auto-complete>
</template>

<script>
import axios from "@/utils/request";
import apis from "@/api/const";

export default {
  props: {
    width: {
      type: String,
      default: "200px",
    },
  },
  data() {
    return {
      list: [],
    };
  },
  methods: {
    handleSearch(value) {
      axios
        .get(apis.brands.store_search, { params: { keywords: value } })
        .then((res) => {
          if (res.code === "0200") {
            this.list = res.payload;
          } else {
            this.$message.error(res.msg || "");
          }
        });
    },
    onSelect(value) {
      this.$emit("input", value);
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-input {
  height: 2.8571rem !important;
}
</style>
