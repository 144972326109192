
<template>
    <a-spin class="content-block detail-summary" :class="{ empty: false }" :spinning="isLoading">
        <div class="content-block-title font-bold">Perdormance Summary</div>
        <div class="toggle">
            <div
                :class="{ active: timeToggle == 'week' }"
                @click="timeToggle = 'week'; getData()"
            >Week</div>
            <div
                :class="{ active: timeToggle == 'month' }"
                @click="timeToggle = 'month'; getData()"
            >Month</div>
        </div>
        <div class="table">
            <div class="thead font-bold">
                <div class="td" style="width:166px">Clicks</div>
                <div class="td" style="width:166px">Conversions</div>
                <div class="td" style="width:166px">Sales</div>
                <div class="td" style="width:166px">Commissions</div>
                <div class="td" style="width:166px">Conversion Rate</div>
                <div class="td" style="width:166px">EPC</div>
            </div>
            <div class="tbody">
                <div class="tr font-bold">
                    <!-- 点击数 -->
                    <div class="td" style="width:166px">{{ stats.clicks | toThousands(true) }}</div>
                    <!-- 订单数 -->
                    <div class="td" style="width:166px">{{ stats.conversions | toThousands(true) }}</div>
                    <!-- 销售额 -->
                    <div class="td" style="width:166px">{{ stats.sales | toThousands }}</div>
                    <!-- 给媒体的佣金 -->
                    <div class="td" style="width:166px">{{ stats.commissions | toThousands }}</div>
                    <!-- 订单数/点击数 -->
                    <div class="td" style="width:166px">{{ stats.conversion_rate }}</div>
                    <!-- 佣金/点击数 每点击支付佣金 -->
                    <div class="td" style="width:166px">{{ stats.epc | toThousands }}</div>
                </div>
            </div>
        </div>
    </a-spin>
</template>

<script>
import axios from "@/utils/request";
export default {
    props: [
        'medium'
    ],
    watch: {
        medium: {
            immediate: true,
            handler: function (newV, oldV) {
                if (newV && newV.apply_status == 'adopt') {
                    this.isApplied = true
                    this.getData()
                }
            }
        }
    },
    data() {
        return {
            stats: {
                clicks: 0,
                conversions: 0,
                sales: 0,
                commissions: 0,
                conversion_rate: '-',
                epc: '-',
            },
            isLoading: false,
            isApplied: false,
            timeToggle: 'week',
            statsWeek: {},
            statsMonth: {},
        }
    },
    methods: {
        getData() {
            if (!this.isApplied) {
                return;
            }
            if (this.timeToggle == 'week' && Object.keys(this.statsWeek).length > 0) {
                this.stats = Object.assign({}, this.statsWeek);
                return
            }
            if (this.timeToggle == 'month' && Object.keys(this.statsMonth).length > 0) {
                this.stats = Object.assign({}, this.statsMonth);
                return
            }

            this.isLoading = true
            axios.get('api_admin.php?a=report/applied_aff_stat', {
                params: {
                    stat: 'range_basic',
                    medium_id: this.medium.medium_id,
                }
            }).then(res => {
                this.isLoading = false
                if (res.code === 0) {
                    this.statsWeek.commissions = parseFloat(res.data.week.comm || 0)
                    this.statsWeek.sales = parseFloat(res.data.week.order_amount || 0)
                    this.statsWeek.clicks = parseInt(res.data.week.clicks || 0)
                    this.statsWeek.conversions = parseInt(res.data.week.order_count || 0)
                    if (this.statsWeek.clicks > 0) {
                        this.statsWeek.conversion_rate = (this.statsWeek.conversions / this.statsWeek.clicks).toFixed(2) + '%'
                        this.statsWeek.epc = (this.statsWeek.commissions / this.statsWeek.clicks).toFixed(2)
                    }
                    this.statsMonth.commissions = parseFloat(res.data.month.comm || 0)
                    this.statsMonth.sales = parseFloat(res.data.month.order_amount || 0)
                    this.statsMonth.clicks = parseInt(res.data.month.clicks || 0)
                    this.statsMonth.conversions = parseInt(res.data.month.order_count || 0)
                    if (this.statsMonth.clicks > 0) {
                        this.statsMonth.conversion_rate = (this.statsMonth.conversions / this.statsMonth.clicks).toFixed(2) + '%'
                        this.statsMonth.epc = (this.statsMonth.commissions / this.statsMonth.clicks).toFixed(2)
                    }
                    if (this.timeToggle == 'week') {
                        this.stats = Object.assign({}, this.statsWeek);
                    }
                    if (this.timeToggle == 'month') {
                        this.stats = Object.assign({}, this.statsMonth);
                    }
                }
            }).catch(e => {
                this.isLoading = false;
            })

        }
    }
}
</script>

<style lang="less" scoped>
.toggle {
    width: 107px;
    height: 132px;
    background: #F2F9FF;
    border-radius: 6px;
    float: left;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    div {
        width: 80px;
        height: 32px;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &.active {
            cursor: default;
            background: #ffffff;
            box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
        }
    }
}
.detail-summary .table {
    width: calc(100% - 107px - 32px) !important;
    margin-left: 139px !important;
}
</style>