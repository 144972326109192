<template>
  <ul class="campprev" v-if="item">
    <li class="campprev__item" v-if="item.dos">
      <span class="campprev__item__title">Do's</span>
      <div class="campprev__item__content">
        <span class="campprev__item__content__normal">{{ item.dos }}</span>
      </div>
    </li>
    <li class="campprev__item" v-if="item.donts">
      <span class="campprev__item__title">Don'ts</span>
      <div class="campprev__item__content">
        <span class="campprev__item__content__normal">{{ item.donts }}</span>
      </div>
    </li>
    <li
      class="campprev__item"
      v-if="item.terms_and_conditions || item.terms_and_conditions_file"
    >
      <span class="campprev__item__title">Terms</span>
      <div class="campprev__item__content campprev-download">
        <span
          v-if="item.terms_and_conditions"
          class="campprev__item__content__normal campprev-download-text"
          >{{ item.terms_and_conditions }}</span
        >
        <div
          v-if="item.terms_and_conditions_file"
          class="campprev-download-item"
        >
          <span>{{
            item.terms_and_conditions_file.split("/")[
              item.terms_and_conditions_file.split("/").length - 1
            ]
          }}</span>
          <a-icon
            type="download"
            @click="downloadFile(item.terms_and_conditions_file)"
          />
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {};
  },
  methods: {
    downloadFile(url) {
      window.open(url, "_blank");
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.campprev {
  margin-top: 16px;
  &-download {
    flex-direction: column;
    &-text {
      width: 100%;
      margin-bottom: 12px;
    }
    &-item {
      width: 100%;
      margin-right: 10px;
      background: #f5f5f5;
      height: 2.8571rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 12px;
      border-radius: 6px;
      > i {
        font-size: 1.4286rem;
        cursor: pointer;
        &:hover {
          color: #4a90e2;
        }
      }
    }
  }
  &__item {
    display: flex;
    margin-top: 20px;
    &__title {
      width: 200px;
      color: #999999;
      .red {
        color: red;
      }
    }
    &__content {
      width: calc(100% - 200px);
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      &__sm {
        font-size: 0.8571rem;
        color: #333333;
        font-weight: lighter;
        margin-right: 10px;
      }
      &__normal {
        margin-right: 10px;
      }
      &__bg {
        margin-right: 10px;
        font-weight: 600;
      }
    }
    &__platform {
      margin-right: 20px;
      display: flex;
      align-items: center;
      img {
        width: 18px;
        margin-right: 6px;
      }
    }
  }
}
</style>
