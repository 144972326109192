import Vue from "vue";
import {
  Button,
  Layout,
  Menu,
  Icon,
  Form,
  Input,
  Table,
  Checkbox,
  Row,
  Col,
  Modal,
  Select,
  FormModel,
  Popconfirm,
  Divider,
  Progress,
  Dropdown,
  Statistic,
  Card,
  message,
  Switch,
  InputNumber,
  Tag,
  notification,
  Radio,
  Upload,
  DatePicker,
  Spin,
  Pagination,
  List,
  Empty,
  Avatar,
  Popover,
  Drawer,
  ConfigProvider,
  Tooltip,
  Skeleton,
  AutoComplete,
  Collapse,
  Tabs,
  Alert,
} from "ant-design-vue";

Vue.use(Alert);
Vue.use(Collapse);
Vue.use(AutoComplete);
Vue.use(Tabs);
Vue.use(Skeleton);
Vue.use(Button);
Vue.use(Checkbox);
Vue.use(Tooltip);
Vue.use(Layout);
Vue.use(Menu);
Vue.use(Icon);
Vue.use(Form);
Vue.use(Input);
Vue.use(Table);
Vue.use(Row);
Vue.use(Col);
Vue.use(Modal);
Vue.use(Select);
Vue.use(FormModel);
Vue.use(Popconfirm);
Vue.use(Divider);
Vue.use(Progress);
Vue.use(Dropdown);
Vue.use(Statistic);
Vue.use(Card);
Vue.use(Switch);
Vue.use(InputNumber);
Vue.use(Tag);
Vue.use(Radio);
Vue.use(Upload);
Vue.use(DatePicker);
Vue.use(Spin);
Vue.use(Pagination);
Vue.use(List);
Vue.use(Empty);
Vue.use(Avatar);
Vue.use(Popover);
Vue.use(Drawer);
Vue.use(ConfigProvider);
Vue.prototype.$message = message;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$notification = notification;
