<template>
  <a-spin :spinning="isLoading">
    <div class="campover">
      <div class="campover-left">
        <div class="campover-perf">
          <p>Key Performance Indicator</p>
          <ul>
            <li>
              <span>Revenue</span>
              <p>
                {{ currencySign }}
                {{ statisticsObj.revenue | toThousandsAndFixed2 }}
              </p>
            </li>
            <li>
              <span>Spend</span>
              <p>
                {{ currencySign }}
                {{ statisticsObj.spend | toThousandsAndFixed2 }}
              </p>
            </li>
            <li>
              <span>Budget</span>
              <p>
                {{ currencySign }}
                {{ statisticsObj.budget | toThousandsAndFixed2 }}
              </p>
            </li>
            <li>
              <span>Influencers</span>
              <p>{{ statisticsObj.influencer_count }}</p>
            </li>
            <li>
              <span>Contents</span>
              <p>{{ statisticsObj.contents }}</p>
            </li>
            <li>
              <span>Views</span>
              <p>{{ statisticsObj.views | formatBigNumber }}</p>
            </li>
          </ul>
        </div>
        <div class="campover-list">
          <div class="campover-sublist">
            <div class="campover-sublist-top">Influencer ({{ influNum }})</div>
            <div class="campover-sublist-title">
              <div>Name</div>
              <div>Contents</div>
            </div>
            <ul class="campover-sublist-list">
              <li
                class="campover-sublist-item"
                v-for="(influ, index) in influList"
                :key="`campover-influs-${index}`"
              >
                <div>
                  <img :src="influ.logo | getSourceUrl" />
                  <span class="one-line">{{ influ.uname }}</span>
                </div>
                <span>{{ influ.contents_total }}</span>
              </li>
            </ul>
          </div>
          <div class="campover-sublist">
            <div class="campover-sublist-top">
              New Proposals ({{ propoNum }})
            </div>
            <div class="campover-sublist-title">
              <div>Name</div>
              <div>Time Applied</div>
            </div>
            <ul class="campover-sublist-list">
              <li
                class="campover-sublist-item"
                v-for="(props, index) in propoList"
                :key="`campover-propos-${index}`"
              >
                <div>
                  <img :src="props.logo | getSourceUrl" />
                  <span class="one-line">{{ props.uname }}</span>
                </div>
                <span
                  v-html="
                    dayjs(props.created_time * 1000).format('MM/DD/YYYY') +
                      '<br/>' +
                      dayjs(props.created_time * 1000).format('hh:mm A')
                  "
                ></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="campover-right">
        <EditCard title="Campaign" @parent-fun="EditCampaign(1)">
          <template #content>
            <PreviewCard1 :item="campaignObj" is-over />
          </template>
        </EditCard>
        <EditCard title="Targets" @parent-fun="EditCampaign(2)">
          <template #content>
            <PreviewCard2 :item="campaignObj" is-over />
          </template>
        </EditCard>
        <EditCard title="Deliverbles" @parent-fun="gotoRoute('3')">
          <template #content>
            <PreviewCard4 :item="campaignObj" is-over />
          </template>
        </EditCard>
        <EditCard title="Campaign Guidelines" @parent-fun="gotoRoute('2')">
          <template #content>
            <PreviewCard6 :item="campaignObj" is-over />
          </template>
        </EditCard>
        <EditCard title="Compensation Method" @parent-fun="gotoRoute('1')">
          <template #content>
            <PreviewCard5 :item="campaignObj" is-over />
          </template>
        </EditCard>
      </div>
      <Modal
        :isVisible="showCampaignTips"
        :isShowCancel="true"
        title=""
        @close-popup="hideCampaignTips"
        width="405px"
        submitText="Edit"
        cancelText="Cancel"
        @submit="toEditCampaign"
        :closeAble="false"
      >
        <template #content>
          <p class="center">
            All the changes made to this campaign do not affect any APPROVED
            proposals. We will also send messages to notify all influencers
            involved in this campaign about all the changes you are about to
            make.
          </p>
        </template>
      </Modal>
      <EditCampaign
        :item="campaignObj"
        @on-closetips="hideCampaignEdit"
        :show-campaign-edit="showCampaignEdit"
        @success-close="reloadData"
      />
      <EditTarget
        :item="campaignObj"
        @on-closetips="hideCampaignEdit"
        :show-edit-target="showEditTarget"
        @success-close="reloadData"
      />
      <Modal
        :isVisible="showCloseTips"
        :isShowCancel="true"
        title=""
        @close-popup="hideCloseTips"
        width="405px"
        submitText="Cancel"
        cancelText="Discard"
        @submit="toCloseCampaign"
        :closeAble="false"
      >
        <template #content>
          <p class="center">
            Are you sure you want to discard, the changes you made will not be
            saved
          </p>
        </template>
      </Modal>
    </div>
  </a-spin>
</template>
<script>
// import Brands from "./../../components/Brands.vue";
// import CampaignItem from "./components/create/CampaignItem.vue";
import axios from "@/utils/request";
// import moment from "moment-timezone";
import EditCard from "./components/public/EditCard.vue";
import PreviewCard1 from "./components/public/PreviewCard1.vue";
import PreviewCard2 from "./components/public/PreviewCard2.vue";
import PreviewCard3 from "./components/public/PreviewCard3.vue";
import PreviewCard4 from "./components/public/PreviewCard4.vue";
import PreviewCard5 from "./components/public/PreviewCard5.vue";
import PreviewCard6 from "./components/public/PreviewCard6.vue";
import EditCampaign from "./components/public/EditCampaign.vue";
import EditTarget from "./components/public/EditTarget.vue";
import Modal from "../../components/Modal.vue";
import APIS from "@/api/const";

export default {
  components: {
    EditCard,
    PreviewCard1,
    PreviewCard2,
    PreviewCard3,
    PreviewCard4,
    PreviewCard5,
    PreviewCard6,
    Modal,
    EditCampaign,
    EditTarget,
  },
  data() {
    return {
      rangeConfig: {
        rules: [
          { type: "array", required: true, message: "Please select time!" },
        ],
      },
      showCampaignTips: false,
      showCampaignEdit: false,
      showCloseTips: false,
      showEditTarget: false,
      tipsStep: 1,
      isLoading: false,
      campaignObj: {
        platforms: [],
        content_format: [],
        content_platforms: [],
        hashtags: "",
      },
      statisticsObj: {},
      currencySign: "",
      influList: [],
      influNum: 0,
      propoList: [],
      propoNum: 0,
    };
  },
  mounted() {
    this.getCampaignDetail();
  },
  methods: {
    gotoRoute(tab) {
      this.$router.push({
        name: "campaign_tools",
        query: {
          tab,
        },
      });
    },
    reloadData() {
      this.showCampaignEdit = false;
      this.showEditTarget = false;
      this.getCampaignDetail();
    },
    async getCampaignDetail() {
      this.isLoading = true;
      const [detailRes, influRes, propRes] = await Promise.all([
        axios.get(APIS.CAMPAIGN.OVERVIEW, {
          params: {
            id: this.$route.params.id,
          },
        }),
        axios.get(APIS.CAMPAIGN.INFLU_LIST, {
          params: {
            id: this.$route.params.id,
            sort: "contents_desc",
            status: ["Relationship"],
            page_num: 1,
            page_size: 10,
          },
        }),
        axios.get(APIS.CAMPAIGN.PROPOS_LIST, {
          params: {
            id: this.$route.params.id,
            status: ["Applied"],
            page_num: 1,
            page_size: 10,
          },
        }),
      ]);
      this.isLoading = false;
      if (detailRes.code === 0) {
        let currencyObj = this.filterCommonConfig(
          this.$store.state.commonConfig.currencies,
          detailRes.data.campaign.currency_id
        );
        let currency_sign = currencyObj.sign;
        let currency_name = currencyObj.simple_name;
        this.campaignObj = {
          ...detailRes.data.campaign,
          country_name: this.filterCommonConfig(
            this.$store.state.commonConfig.countries,
            detailRes.data.campaign.country_id
          ).name_en,
          currency_sign,
          currency_name,
        };
        this.statisticsObj = detailRes.data.report_overview;
        this.currencySign = currency_sign;
      } else {
        this.$message.error(detailRes.message || "");
      }

      this.influList = influRes.data.data.map((item) => ({
        ...item,
      }));
      this.influNum = parseInt(influRes.data.total_size);

      this.propoList = propRes.data.data.map((item) => ({
        ...item,
      }));
      this.propoNum = parseInt(propRes.data.total_size);
    },
    hideCampaignTips() {
      this.showCampaignTips = false;
    },
    toEditCampaign() {
      this.showCampaignTips = false;
      if (this.tipsStep === 1) {
        this.showCampaignEdit = true;
      } else if (this.tipsStep === 2) {
        console.log("弹出2");
        this.showEditTarget = true;
      }
    },
    hideCampaignEdit() {
      this.showCloseTips = true;
    },
    EditCampaign(step) {
      this.tipsStep = step;
      this.showCampaignTips = true;
    },
    hideCloseTips() {
      this.showCloseTips = false;
      this.showCampaignEdit = false;
      this.showEditTarget = false;
    },
    toCloseCampaign() {
      this.showCloseTips = false;
    },
  },
};
</script>

<style lang="less" scoped>
.campover {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  .center {
    text-align: center;
  }
  &-left {
    width: 550px;
  }
  &-perf {
    width: 550px;
    height: 248px;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 20px 20px 12px;
    > p {
      font-size: 1.1429rem;
      color: #333;
      font-family: "prefer-bold";
      margin-bottom: 0;
    }
    > ul {
      display: flex;
      flex-wrap: wrap;
      margin-top: 16px;
      li {
        width: calc(100% / 3);
        text-align: center;
        height: 90px;
        span {
          color: #999999;
          font-size: 1.1429rem;
          line-height: 30px;
        }
        p {
          font-size: 1.4286rem;
          font-family: "prefer-bold";
          color: #333333;
          margin-bottom: 0;
          margin-top: 12px;
        }
      }
    }
  }
  &-list {
    width: 550px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  &-sublist {
    width: 262px;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    &-top {
      font-size: 1.1429rem;
      font-family: "prefer-bold";
      color: #333333;
      height: 52px;
      line-height: 52px;
      padding-left: 20px;
    }
    &-title {
      height: 2.8571rem;
      background: #f5f5f5;
      display: flex;
      > div {
        width: 50%;
        line-height: 2.8571rem;
        text-align: center;
      }
    }
    &-list {
    }
    &-item {
      display: flex;
      height: 3.4286rem;
      align-items: center;
      div {
        width: 50%;
        justify-content: center;
        display: flex;
        img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          object-fit: cover;
        }
        span {
          font-weight: 600;
          color: #333333;
          margin-left: 5px;
        }
      }
      span {
        width: 50%;
        text-align: center;
        color: #333333;
        font-weight: lighter;
      }
      & + li {
        border-top: 1px solid #eee;
      }
    }
  }

  &-right {
    width: calc(100% - 582px);
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
  }
}
</style>
