<template>
  <modal
    id="test-order-modal"
    :isVisible="isShowPop"
    title="Testing Your Tracking Code"
    :showBtn="false"
    @close-popup="$emit('close')"
    width="790px"
    height="500px"
    :disabled="isLoadingModal"
  >
    <div class="need-test" slot="content">
      <div class="waiting-test" v-if="testStat == 'waiting'">
        <div>
          <a-icon type="loading" style="font-size:3.4286rem" spin />
          &nbsp;Waiting for you to place a sale or lead on your site.
        </div>
        <div class="completed" @click="handleCompletedTestOrder(true)">
          I've completed the Transaction ON MY SITE
        </div>
      </div>
      <div class="test-faild" v-else-if="testStat == 'fail'">
        <h2>We did not receive a test order.</h2>
        <p>Here are the common reasons for a testing failure:</p>

        <p>
          1. Verify that you have completed a full conversion on your site and
          that you reached your confirmation / thank you page. PartnerBoost will
          only track a transaction on a completed conversion.
        </p>

        <p>
          2. The tracking pixel may not be correctly installed. Please have your
          programmer/installer revisit step 4 in your PartnerBoost account in
          order to access the tracking pixel installation instructions.
        </p>
      </div>
      <div class="options" v-if="testStat != 'waiting'">
        <div class="start-test" @click="startTest">
          Try a New Test
        </div>
      </div>

      <div class="order-title">
        Transaction Receipt
        <a @click="handleCompletedTestOrder(false)">Load History</a>
      </div>
      <div class="row row-head">
        <div>Order ID</div>
        <div>Order Total</div>
        <div>Order Commission</div>
      </div>
      <div
        class="row row-content"
        v-for="item in testOrderList"
        :key="item.key"
      >
        <div>{{ item.order_number }}</div>
        <div>
          {{ item.currency }}
          {{ parseFloat(item.amount).toFixed(2) | toThousands(true) }}
        </div>
        <div>
          {{ item.currency }}
          {{ parseFloat(item.commission).toFixed(2) | toThousands(true) }}
        </div>
      </div>
      <Loading v-if="isLoadingModal"></Loading>
    </div>
  </modal>
</template>
<script>
import Modal from "@/components/Modal.vue";
import axios from "@/utils/request";

export default {
  name: "chat-model",
  components: { Modal },
  props: {
    isShowPop: {
      type: Boolean,
      default: false,
    },
    defaultTestOrderList: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    defaultTestOrderList: {
      handler(val) {
        this.testOrderList = val;
      },
      immediate: true,
    },
  },
  data() {
    return {
      testOrderList: [],
      testStat: null,
      isLoadingModal: false,
    };
  },
  methods: {
    startTest() {
      this.isLoadingModal = true;
      axios
        .get("/api_admin.php?a=user/trackUrl")
        .then((res) => {
          this.isLoadingModal = false;
          this.testStat = "waiting";
          window.open(res.data);
        })
        .catch((e) => {
          this.isLoadingModal = false;
        });
    },
    handleCompletedTestOrder(showFail) {
      // 完成测试, 从服务端获取测试结果
      this.isLoadingModal = true;
      axios
        .get("/api_admin.php?a=user/testOrderHistory")
        .then((res) => {
          this.isLoadingModal = false;
          this.testOrderList = res.data.map((item) => ({
            ...item,
            key: Math.random() * Math.random(),
          }));
          if (this.testOrderList.length > 0) {
            this.$emit("connected");
          }
          if (this.testOrderList.length == 0 && showFail) {
            this.testStat = "fail";
          } else {
            this.testStat = null;
          }
        })
        .catch((e) => {
          this.isLoadingModal = false;
        });
    },
  },
};
</script>
<style lang="less" scoped>
@import "~/src/dtc.less";

#test-order-modal {
  .options {
    padding: 16px;
    background-color: #00000009;
    box-sizing: border-box;
  }

  .start-test,
  .completed {
    display: inline-block;
    width: fit-content;
    height: 2.8571rem;
    border-radius: 6px;
    text-align: center;
    line-height: 36px;
    font-size: 1rem;
    padding: 0 20px;
    cursor: pointer;
    border: 1px solid #2898ff;
    box-sizing: border-box;
    background-color: #fff;
    color: #2898ff;
  }

  .order-title {
    margin: 30px 0 15px;
    font-size: 1.2857rem;

    a {
      font-family: dtc;
      font-size: 1rem;
      margin-left: 10px;
    }
  }

  .row {
    display: flex;
    justify-content: stretch;
    align-items: center;
    height: 2.8571rem;

    &.row-head {
      background-color: @dtc-th-bg;
      font-family: dtc-bold;
    }

    > div {
      flex: 1;
      text-align: center;

      color: #2898ff;

      &:first-child {
        flex: 2;
        color: @text-color;
      }
    }

    border-bottom: 1px solid @dtc-th-bg;
  }

  .waiting-test {
    text-align: center;
    padding: 50px 0;

    > div:first-child {
      font-size: 1.4286rem;
      color: @primary-color;
      display: inline-flex;
      align-items: center;
      width: fit-content;
    }

    div + div {
      margin-top: 20px;
    }
  }

  .test-faild {
    padding: 10px 0 10px;
  }
}
</style>
