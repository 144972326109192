<template>
  <div class="deeplink">
    <Header collection="Tools"></Header>
    <!-- <div class="headers">
      </div> -->
    <div class="container">
      <div class="title">
        With the Bookmark tool, you may generate a tracking link from any
        website you choose to promote.
      </div>
      <div class="content">
        <div class="top">
          <div class="top-left">Choose your channel:</div>
          <div class="top-right">
            <a-select
              placeholder="Please Select your Channel"
              :getPopupContainer="
                (triggerNode) => {
                  return triggerNode.parentNode || document.body;
                }
              "
              v-model="siteTrack"
            >
              <a-select-option
                v-for="(site, index) in linkList"
                :key="`site-${index}`"
                :value="site.track"
              >
                {{ site.lhid }}
              </a-select-option>
            </a-select>
            <a
              :href="
                `javascript: void((function () {var track = '${siteTrack}';var lhCurrentUrl = encodeURIComponent(document.URL);var e = document.createElement('script');e.setAttribute('type', 'text/javascript');e.setAttribute('charset', 'UTF-8');e.setAttribute('src', 'https://www.linkprefer.com/tool.php?mod=adlink&amp;op=bookmark_getlink&amp;track='+ track +'&amp;u=' + lhCurrentUrl + '&amp;nocache='+ Math.random() * 99999999);document.body.appendChild(e);})());`
              "
              target="_blank"
              >LinkPrefer - Deep Link Bookmarklet</a
            >
          </div>
        </div>
        <div class="tips">
          <div class="tips-title">How to Use It：</div>
          <div class="tips-text">
            1. Drag the bookmark button directly to your browser's bookmark bar.
          </div>
          <div class="tips-text">
            2. Visit the brands' website you have joined.
          </div>
          <div class="tips-text">
            3. Find the specific page you want to promote and click on the
            bookmarklet Tool.
          </div>
          <div class="tips-text">
            4. Copy the tracking link generated by the bookmark tool to promote.
          </div>
          <div class="tips-text">
            *A default link will be generated for brands which does not support
            custom links.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/public/Header.vue";
import axios from "@/utils/request";
import apis from "@/api/const";

export default {
  components: {
    Header,
  },
  data() {
    return {
      linkList: [],
      siteId: undefined,
      siteTrack: "",
    };
  },
  mounted() {
    this.getDeepLink();
  },
  methods: {
    selectSite(value, option) {
      //   console.log("生产：", value, option);
      this.siteTrack = value;
    },
    getDeepLink() {
      axios.get(apis.tools.deep_link, {}).then((res) => {
        // this.isLoading = false;
        if (res.code === "0200") {
          this.linkList = res.payload;
          if (this.linkList.length > 0) {
            this.siteTrack = this.linkList[0].track;
          }
        } else {
          this.$message.error(res.msg || "");
        }
      });
    },
  },
  beforeDestroy() {
    if (this.onWindowResize) {
      window.onresize = null;
    }
  },
};
</script>

<style lang="less" scoped>
.deeplink {
  .container {
    display: flex;
    justify-content: space-between;
    margin-top: 0.8571rem;
    background: #ffffff;
    box-shadow: 0px 0px 12px 0px rgba(102, 118, 186, 0.1);
    border-radius: 1.1429rem;
    flex-direction: column;
    padding: 1.7143rem 2.2857rem;
    .title {
      font-size: 1.1429rem;
      color: #333333;
      font-family: "prefer-bold";
    }
    .content {
      .top {
        display: flex;
        margin-top: 22px;
        &-left {
          width: 160px;
          padding-top: 10px;
        }
        &-right {
          display: flex;
          flex-direction: column;
          > a {
            width: 366px;
            height: 2.8571rem;
            border-radius: 4px;
            border: 1px solid #e6e6e6;
            text-align: center;
            line-height: 2.8571rem;
            color: #333;
            margin-top: 20px;
            &:hover {
              color: #3762d6;
              border: 1px solid #3762d6;
            }
          }
        }
      }
      .tips {
        &-title {
          font-family: "prefer-bold";
          margin-top: 20px;
          margin-bottom: 12px;
        }
        &-text {
          line-height: 26px;
        }
      }
    }
  }
  /deep/ .ant-form-item-no-colon {
    color: #999999;
  }
  /deep/ .ant-input {
    height: 2.8571rem;
  }
}
</style>
