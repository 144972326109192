<template>
  <div class="campaign">
    <Header collection="Campaigns"></Header>
    <div class="campaign-search">
      <div class="campaign-top">
        <div class="search-left">
          <div class="search-btns">
            <SelectButton
              title="Categories"
              v-model="categoryList"
              :options="categoryEnums"
              multiple
              clearAble
              @selected="onSelect"
            />
            <SelectButton
              title="Countries"
              v-model="countryList"
              :options="countryEnums"
              multiple
              clearAble
              @selected="onSelect"
            />
            <SelectButton
              title="Platform"
              v-model="platformList"
              :options="platformEnums"
              multiple
              clearAble
              @selected="onSelect"
            />
            <SelectButton
              title="Format"
              v-model="formatList"
              :options="formatEnums"
              multiple
              clearAble
              @selected="onSelect"
            />
            <SelectButton
              title="Payout Type"
              v-model="payoutTypeList"
              :options="payoutTypeEnums"
              multiple
              clearAble
              @selected="onSelect"
            />
            <div>
              <span style="margin-right:10px;">Status:</span>
              <a-select
                placeholder="Status"
                :getPopupContainer="
                  (triggerNode) => {
                    return triggerNode.parentNode || document.body;
                  }
                "
                v-model="status"
                @change="handleStatusChange"
                @select="onSelect"
              >
                <a-select-option
                  v-for="(item, index) in statuList"
                  :key="`site-${index}`"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
            <a-select
              placeholder="Sort by"
              :getPopupContainer="
                (triggerNode) => {
                  return triggerNode.parentNode || document.body;
                }
              "
              v-model="sortBy"
              @select="onSelect"
            >
              <a-select-option
                v-for="(item, index) in sortList"
                :key="`site-${index}`"
                :value="item.value"
              >
                <span
                  v-html="item.name.replace('DESC', '').replace('ASC', '')"
                ></span>
                <a-icon v-if="item.name.includes('DESC')" type="arrow-down" />
                <a-icon v-if="item.name.includes('ASC')" type="arrow-up" />
              </a-select-option>
            </a-select>
            <span
              v-show="showKeysLine"
              class="search-reset"
              @click="resetSelect"
              >Reset</span
            >
          </div>
        </div>
        <div class="search-right">
          <div class="search-input">
            <img src="@/assets/campaign/icon-search.svg" />
            <input
              type="text"
              placeholder="Search Campaign Name"
              @keyup.enter="toSearch"
              v-model="searchKey"
            />
            <!-- <img
              class="img-close"
              src="@/assets/campaign/icon-close.svg"
              @click="closeSearch"
            /> -->
          </div>
          <!-- <div
            v-show="!isShowSearch"
            class="search-btn search-action"
            @click="showSearchKey"
          >
            <img src="@/assets/campaign/icon-search.svg" />
          </div> -->
          <div class="search-btn search-grid" @click="switchTable('grid')">
            <img
              v-if="tableType === 'grid'"
              src="@/assets/campaign/icon-grid-select.svg"
            />
            <img v-else src="@/assets/campaign/icon-grid.svg" />
          </div>
          <div class="search-btn search-row" @click="switchTable('row')">
            <img
              v-if="tableType === 'row'"
              src="@/assets/campaign/icon-row-select.svg"
            />
            <img v-else src="@/assets/campaign/icon-row.svg" />
          </div>
        </div>
      </div>
      <div v-show="showKeysLine" class="search-keys">
        <a-tag
          v-for="(item, index) in categoryList"
          :key="`category-list-${item.value}-${index}`"
          @close="closeCategory(item)"
          closable
        >
          {{ item.label }}
        </a-tag>
        <a-tag
          v-for="(item, index) in countryList"
          :key="`country-list-${item.value}-${index}`"
          @close="closeCountry(item)"
          closable
        >
          {{ item.label }}
        </a-tag>
        <a-tag
          v-for="(item, index) in platformList"
          :key="`country-list-${item.value}-${index}`"
          @close="closePlatform(item)"
          closable
        >
          {{ item.label }}
        </a-tag>
        <a-tag
          v-for="(item, index) in payoutTypeList"
          :key="`country-list-${item.value}-${index}`"
          @close="closePayType(item)"
          closable
        >
          {{ item.label }}
        </a-tag>
        <a-tag
          v-for="(item, index) in formatList"
          :key="`country-list-${item.value}-${index}`"
          @close="closeFormat(item)"
          closable
        >
          {{ item.label }}
        </a-tag>
      </div>
    </div>
    <a-spin
      v-if="!showChanelTips && !showInfoTips"
      :class="{ empty: dataLists.length == 0 }"
      :spinning="isLoading"
    >
      <a-empty v-if="dataLists.length == 0 && !isLoading" />
      <div v-else class="campaign-list-content">
        <div v-if="tableType === 'row'" class="campaign-rlist">
          <CampaignRowItem
            v-for="(camp, index) in dataLists"
            :item="camp"
            :calcPage="calcPage"
            :key="`camp-list-${index}`"
          />
        </div>
        <div v-else class="campaign-glist">
          <CampaignGridItem
            v-for="(camp, index) in dataLists"
            :item="camp"
            :calcPage="calcPage"
            :key="`camp-list-${index}`"
          />
        </div>
      </div>
      <div class="footer-pagination">
        <a-pagination
          v-if="totalCount"
          :defaultPageSize="10"
          v-model="calcPage"
          :total="totalCount"
          show-less-items
          @change="paginationChange"
        />
      </div>
    </a-spin>
    <Modal
      :isVisible="showChanelTips"
      :isShowCancel="false"
      title=""
      @close-popup="gotoDashboard"
      width="400px"
      submitText="OK"
      @submit="gotoDashboard"
    >
      <template #content>
        <p class="center">
          Please make sure your channel has been verified.
        </p>
      </template>
    </Modal>
    <Modal
      :isVisible="showInfoTips"
      :isShowCancel="false"
      title=""
      @close-popup="gotoBasicinfo"
      width="400px"
      submitText="OK"
      :isShowSubmit="false"
    >
      <template #content>
        <p class="center">
          If you want to apply campaign, please click
          <a @click="handleRoute">here</a> to complete your basic info first.
        </p>
      </template>
    </Modal>
  </div>
</template>
<script>
import CampaignItem from "./components/create/CampaignItem.vue";
import CampaignRowItem from "./components/create/CampaignRowItem.vue";
import CampaignGridItem from "./components/create/CampaignGridItem.vue";
import SelectButton from "@/components/public/SelectButton.vue";
import Header from "@/components/public/Header.vue";
import axios from "@/utils/request";
import apis from "@/api/const";
import Modal from "@/components/Modal.vue";

export default {
  components: {
    CampaignItem,
    Header,
    SelectButton,
    CampaignRowItem,
    CampaignGridItem,
    Modal,
  },
  data() {
    return {
      dataLists: [],
      isLoading: false,
      totalCount: 0,
      currentPage: 1,
      calcPage: 1,
      page_size: 10,
      searchKey: "",
      isShowSearch: false,
      tableType: "grid",
      categoryList: [],
      countryList: [],
      platformList: [],
      formatList: [],
      payoutTypeList: [],
      status: "all",
      sortBy: undefined,
      categoryEnums: [],
      countryEnums: [],
      platformEnums: [],
      payoutTypeEnums: [],
      formatEnums: [],
      statuList: [],
      sortList: [],
      closeAllModal: true,
    };
  },
  watch: {
    "$route.query": function(nv) {
      this.calcPage = Number(nv.page) || this.currentPage;
      console.log(this.calcPage);
    },
  },
  created() {},
  mounted() {
    this.calcPage = Number(this.$route.query.page) || 1;
    console.log(this.calcPage, "this.calcPage");
    let { status } = this.$route.query;
    if (status) {
      this.status = status;
    }
    this.getDataList();
    this.getTypeList();
  },
  computed: {
    showChanelTips() {
      return (
        this.$store.state.userInfo.has_channel_status == 0 && this.closeAllModal
      );
    },
    showInfoTips() {
      //   return false;
      // chanel弹窗优先级高
      if (this.$store.state.userInfo.has_channel_status == 0) {
        return false;
      }
      return this.$store.state.userInfo.is_check_account && this.closeAllModal;
    },
    showKeysLine() {
      const list = [
        ...this.categoryList,
        ...this.countryList,
        ...this.platformList,
        ...this.formatList,
        ...this.payoutTypeList,
      ];
      return list.length > 0 ? true : false;
    },
  },
  methods: {
    onSelect() {
      this.$router.replace({ query: { page: 1 } });
      this.currentPage = 1;
      this.getDataList();
    },
    handleStatusChange(val) {
      this.$router.push({ query: { status: val } });
    },
    handleRoute() {
      this.closeAllModal = false;
      this.$nextTick(() => {
        this.$router.push({ path: "/account/" });
      });
    },
    // 获取列表
    getTypeList() {
      axios.get(apis.campaign.type_list, {}).then((res) => {
        if (res.code === "0200") {
          this.categoryEnums = res.payload.category_map.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          });
          this.countryEnums = res.payload.countries.map((item) => {
            return {
              value: item,
              label: item,
            };
          });
          this.platformEnums = res.payload.platform_map.map((item) => {
            return {
              value: item.name,
              label: item.full_name,
            };
          });
          this.payoutTypeEnums = res.payload.payout_type_map.map((item) => {
            return {
              value: item.name,
              label: item.value,
            };
          });
          this.formatEnums = res.payload.format_map.map((item) => {
            return {
              value: item.name,
              label: item.value,
            };
          });
          this.statuList = res.payload.status_map.map((item) => {
            return {
              value: item.name,
              name: item.value,
            };
          });
          this.statuList.unshift({ name: "All", value: "all" });
          this.sortList = res.payload.sort_by_map.map((item) => {
            return {
              value: item.name,
              name: item.value,
            };
          });
          console.log(this.sortList);
        } else {
          this.$message.error(res.msg || "");
        }
      });
    },
    closeSearch() {
      this.searchKey = "";
      this.isShowSearch = false;
    },
    gotoBasicinfo() {
      this.closeAllModal = false;
      setTimeout(() => {
        this.$router.push({
          name: "my_basicinfo",
        });
      }, 200);
    },
    gotoDashboard() {
      this.closeAllModal = false;
      setTimeout(() => {
        this.$router.push({
          name: "dashboard",
        });
      }, 200);
    },
    // 获取列表
    getDataList() {
      this.isLoading = true;
      const { page } = this.$route.query;
      axios
        .get(apis.campaign.camp_list, {
          params: {
            category: this.categoryList.map((item) => {
              return item.value;
            }),
            countries: this.countryList.map((item) => {
              return item.value;
            }),
            platform: this.platformList.map((item) => {
              return item.value;
            }),
            formats: this.formatList.map((item) => {
              return item.value;
            }),
            payout_type: this.payoutTypeList.map((item) => {
              return item.value;
            }),
            status: this.status == "all" ? null : this.status,
            sort_by: this.sortBy || "",
            page_size: this.page_size,
            page: page || this.currentPage,
            keywords: this.searchKey,
          },
        })
        .then((res) => {
          this.isLoading = false;
          if (res.code === "0200") {
            this.dataLists = res.payload.list.map((item) => ({
              ...item,
              plat_list: this.filterPlatforms(item.platform),
              format_list: this.filterFormats(item.formats),
            }));
            this.totalCount = parseInt(res.payload.total);
          } else {
            this.$message.error(res.msg || "");
          }
        });
    },
    orderBySelected() {},
    resetSelect() {
      this.categoryList = [];
      this.countryList = [];
      this.platformList = [];
      this.formatList = [];
      this.payoutTypeList = [];
      this.status = undefined;
      this.sortBy = undefined;
    },
    switchTable(val) {
      this.tableType = val;
    },
    toSearch() {
      this.resetSelect();
      this.onSelect();
    },
    showSearchKey() {
      this.isShowSearch = true;
    },
    closeCountry(val) {
      const index = _.findIndex(this.countryList, (item) => {
        return item.value === val.value;
      });
      this.countryList.splice(index, 1);
      this.getDataList();
    },
    closeCategory(val) {
      const index = _.findIndex(this.categoryList, (item) => {
        return item.value === val.value;
      });
      this.categoryList.splice(index, 1);
      this.getDataList();
    },
    closePlatform(val) {
      const index = _.findIndex(this.platformList, (item) => {
        return item.value === val.value;
      });
      this.platformList.splice(index, 1);
      this.getDataList();
    },
    closePayType(val) {
      const index = _.findIndex(this.payoutTypeList, (item) => {
        return item.value === val.value;
      });
      this.payoutTypeList.splice(index, 1);
      this.getDataList();
    },
    closeFormat(val) {
      const index = _.findIndex(this.formatList, (item) => {
        return item.value === val.value;
      });
      this.formatList.splice(index, 1);
      this.getDataList();
    },

    // 切换下一页
    paginationChange(page, pageSize) {
      this.currentPage = page;
      this.$router.replace({ query: { page } });
      this.getDataList();
    },
  },
};
</script>

<style lang="less" scoped>
.campaign {
  &-search {
    background: #ffffff;
    border-radius: 16px;
    padding: 20px;
    margin-top: 12px;
    .search-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .search-btns {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        > div {
          // margin-bottom: 12px;
        }
      }
      .search-reset {
        font-size: 1rem;
        color: #666666;
        cursor: pointer;
        margin-left: 12px;
      }

      .campaign-orderby {
        width: 150px;
      }
    }
    .search-keys {
      margin-top: 12px;
      /deep/ .ant-tag {
        font-size: 0.8571rem;
        height: 24px;
        line-height: 24px;
        color: #3762d6;
        background: #f6f9ff;
        border-radius: 11px;
        border: none;
        margin-bottom: 5px;
        margin-right: 12px;
        > i {
          color: #3762d6;
        }
      }
    }
    .search-right {
      display: flex;
      justify-content: flex-end;
      .search-input {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #333;
        height: 2.8571rem;
        transition: all 0.3s;
        margin-right: 20px;
        img {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
        .img-close {
          width: 10px;
          height: 10px;
        }
        input {
          border: none;
          outline: none;
          width: 220px;
          padding-left: 5px;
        }
      }
    }
    .search-btn {
      height: 2.8571rem;
      line-height: 2.8571rem;
      margin-right: 20px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  &-top {
    display: flex;
    justify-content: space-between;
  }
  &-rlist {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  &-glist {
    display: grid;
    grid-template-columns: repeat(4, minmax(19.5rem, 1fr));
    gap: 1.4286rem;
    margin: 30px 0;
    width: 100%;
  }
}
@media (min-width: 1680px) {
  .campaign-glist {
    grid-template-columns: repeat(5, minmax(19rem, 1fr));
  }
}
@media (max-width: 1279px) {
  .campaign-glist {
    grid-template-columns: repeat(3, minmax(19.5rem, 1fr));
  }
}
/deep/.ant-select-selection {
  height: 2.8571rem;
}
/deep/.ant-select {
  min-width: 11.4286rem;
  margin-right: 12px;
}
</style>
