<template>
  <div class="campaign-steps">
    <div class="campaign-steps-node">
      <div
        class="campaign-steps-node__text"
        :class="stepIndex == 1 ? 'current' : 'done'"
      >
        Main info
      </div>
      <CampaignStepsShape :nodeStatus="stepIndex == 1 ? 2 : 3" />
    </div>
    <div
      class="campaign-steps-line"
      :class="stepIndex > 1 ? 'active' : ''"
    ></div>
    <div class="campaign-steps-node">
      <div
        class="campaign-steps-node__text"
        :class="stepIndex > 2 ? 'done' : stepIndex == 2 ? 'current' : ''"
        style="left: -12px"
      >
        Targets
      </div>
      <CampaignStepsShape
        :nodeStatus="stepIndex > 2 ? 3 : stepIndex == 2 ? 2 : 1"
      />
    </div>
    <div
      class="campaign-steps-line"
      :class="stepIndex > 2 ? 'active' : ''"
    ></div>
    <div class="campaign-steps-node">
      <div
        class="campaign-steps-node__text"
        :class="stepIndex > 3 ? 'done' : stepIndex == 3 ? 'current' : ''"
      >
        Creatives
      </div>
      <CampaignStepsShape
        :nodeStatus="stepIndex > 3 ? 3 : stepIndex == 3 ? 2 : 1"
      />
    </div>
    <div
      class="campaign-steps-line"
      :class="stepIndex > 3 ? 'active' : ''"
    ></div>
    <div class="campaign-steps-node">
      <div
        class="campaign-steps-node__text"
        :class="stepIndex > 4 ? 'done' : stepIndex == 4 ? 'current' : ''"
        style="left: -7px"
      >
        Tools
      </div>
      <CampaignStepsShape
        :nodeStatus="stepIndex > 4 ? 3 : stepIndex == 4 ? 2 : 1"
      />
    </div>
    <div
      class="campaign-steps-line"
      :class="stepIndex > 4 ? 'active' : ''"
    ></div>
    <div class="campaign-steps-node">
      <div
        class="campaign-steps-node__text"
        :class="stepIndex > 5 ? 'done' : stepIndex == 5 ? 'current' : ''"
        style="left: -32px"
      >
        Compensation
      </div>
      <CampaignStepsShape
        :nodeStatus="stepIndex > 5 ? 3 : stepIndex == 5 ? 2 : 1"
      />
    </div>
    <div
      class="campaign-steps-line"
      :class="stepIndex > 5 ? 'active' : ''"
    ></div>
    <div class="campaign-steps-node">
      <div
        class="campaign-steps-node__text"
        :class="stepIndex == 6 ? 'current' : ''"
        style="left: -12px"
      >
        Preview
      </div>
      <CampaignStepsShape :nodeStatus="stepIndex == 6 ? 2 : 1" />
    </div>
  </div>
</template>

<script>
// const defaultLogo = require("@/assets/default.png");
import moment from "moment-timezone";
import axios from "@/utils/request";
// import Modal from "../../../components/Modal.vue";
import CampaignStepsShape from "./CampaignStepsShape.vue";

export default {
  components: { CampaignStepsShape },
  props: {
    stepIndex: {
      type: Number,
      default: 1,
    },
  },
  computed: {},
  data() {
    return {
      countryMap: {},
      statByLocal: "today",
      errorPlaceHolder: null,
      isShowUpdate: false,
      newStatus: "",
      isLoadingModal: false,
    };
  },
  watch: {},
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.campaign-steps {
  display: flex;
  align-items: center;
  margin: 68px auto 0;
  width: 1000px;
  &-node {
    position: relative;
    &__text {
      position: absolute;
      min-width: 68px;
      top: -28px;
      left: -16px;
      color: #999999;
      &.current {
        color: #333333;
      }
      &.done {
        color: #05c15f;
      }
    }
    &__shape {
    }
    &__current {
      width: 24px;
      height: 24px;
      background: #ffffff;
      box-shadow: 0px 2px 10px 0px rgba(204, 204, 204, 0.36);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      > div {
        width: 8px;
        height: 8px;
        background: #05c15f;
        border-radius: 50%;
      }
    }
    &__done {
      > i {
        color: #05c15f;
        font-size: 24px;
      }
    }
    &__future {
      width: 24px;
      height: 24px;
      background: #ffffff;
      border: 2px solid #e1e2e8;
      border-radius: 50%;
    }
  }
  &-line {
    width: 172px;
    height: 2px;
    background: #e1e2e8;
    margin-left: -1px;
    &.active {
      background: #05c15f;
    }
  }
}
</style>
