<template>
  <div id="footer-main">
    <div class="footer-wrap">
      <span
        ><a
          :href="CONFIG.WWW_HOST + '/privacy-policy'"
          style="color:#999999"
          target="_blank"
          >Privacy Policy</a
        ></span
      >
      <span></span>
      <span>PartnerBoost ©2021 - {{ year }}. All Rights Reserved.</span>
    </div>
  </div>
</template>
<script>
import CONFIG from "@/utils/config";
export default {
  data() {
    return {
      CONFIG,
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="less" scoped>
#footer-main {
  width: 100%;
  height: 60px;

  .footer-wrap {
    width: 100%;
    // min-width: 1200px;
    height: 60px;
    background: #f5f5f5;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    span {
      &:nth-of-type(1) {
        font-size: 0.8571rem;
        color: #999999;
      }
      &:nth-of-type(2) {
        display: inline-block;
        width: 1px;
        height: 13px;
        background-color: #999;
        margin: 0px 12px;
      }
      &:nth-of-type(3) {
        font-size: 0.8571rem;
        color: #999999;
      }
    }
  }
}
</style>
