<template>
  <div class="textcount">
    <a-textarea
      class="textcount-text"
      v-model="value"
      :disabled="disabled"
      :placeholder="placeholder"
      :maxLength="textMaxNum"
      :auto-size="{ minRows: minRow, maxRows: maxRow }"
      v-if="disabled"
    />
    <a-textarea
      v-else
      class="textcount-text"
      v-model="textStr"
      :disabled="disabled"
      :placeholder="placeholder"
      :maxLength="textMaxNum"
      :auto-size="{ minRows: minRow, maxRows: maxRow }"
    />
    <div class="textcount-num" v-if="!disabled">
      <span>{{ currentNum }}/{{ textMaxNum }}</span>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  components: {},
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    textMaxNum: {
      type: Number,
      default: 8000,
    },
    minRow: {
      type: Number,
      default: 5,
    },
    maxRow: {
      type: Number,
      default: 15,
    },
  },
  computed: {},
  data() {
    return {
      currentNum: this.value.length,
      textStr: this.value,
    };
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal) this.textStr = newVal;
    },
    textStr(newVal, oldVal) {
      this.currentNum = newVal.length;
      this.triggerChange(newVal);
    },
  },
  created() {},
  methods: {
    triggerChange(changedVal) {
      this.$emit("textChange", changedVal);
    },
  },
};
</script>

<style lang="less" scoped>
.textcount {
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 8px 4px;
  background: #fff;
  &-text {
    border: none;
    background: none;
    resize: none;
    &:focus {
      border: none;
      box-shadow: none;
    }
  }
  &-num {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    color: #999;
  }
  /deep/ .ant-input[disabled] {
    background-color: #fff;
  }
}
</style>
