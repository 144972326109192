<template>
  <div class="campaign-create">
    <CampaignSteps :stepIndex="stepIndex" />
    <div class="campaign-create-forms">
      <a-spin :spinning="isLoading">
        <div v-show="stepIndex === 1" class="campaign-create-step1">
          <a-form
            :form="formMain"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 16 }"
          >
            <a-form-item label="Campaign Name" labelAlign="left">
              <a-input
                :maxLength="100"
                v-decorator="[
                  'name',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter a Campaign Name',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
            <a-form-item label="Platforms" labelAlign="left">
              <a-checkbox
                v-for="(item, idx) in PLATFORMS"
                :key="`platforms-s1-${idx}`"
                @change="platformsCheckChange(item.name)"
                ><img class="platform-icon" :src="item.icon" /> {{ item.name }}
              </a-checkbox>
              <a-input
                style="display: none"
                v-decorator="[
                  'platforms',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please select at least one platform',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
            <a-form-item label="Budget" labelAlign="left">
              <div class="campaign-create-step1__currency">
                <span class="campaign-create-step1__currency__text"
                  >Currency</span
                ><span
                  class="campaign-create-step1__currency__select"
                  @click="isChoosePopup = true"
                  >{{ selectedCurrency.simple_name }}
                  {{
                    selectedCurrency.id ? `(${selectedCurrency.sign})` : ""
                  }}</span
                >
                <p v-show="showCurrencyError" class="error">
                  Please select a currency
                </p>
              </div>
              <div class="campaign-create-step1__amount">
                <span class="campaign-create-step1__amount__text">Amount</span>
                <a-input-number
                  :min="0"
                  :max="999999999"
                  v-decorator="[
                    'amount_total',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter amount',
                        },
                      ],
                    },
                  ]"
                />&nbsp;
                {{ selectedCurrency.id ? `${selectedCurrency.sign}` : "" }}
              </div>
            </a-form-item>
            <a-form-item label="Duration" labelAlign="left">
              <a-range-picker
                :disabled-date="disabledDate"
                v-decorator="['duration', rangeConfig]"
              />
            </a-form-item>
            <a-form-item label="Description" labelAlign="left">
              <a-textarea
                :auto-size="{ minRows: 5, maxRows: 5 }"
                :maxLength="5000"
                v-decorator="[
                  'description',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter description',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
            <a-form-item label="Objective" labelAlign="left">
              <a-textarea
                :maxLength="5000"
                :auto-size="{ minRows: 5, maxRows: 5 }"
                v-decorator="[
                  'objective',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter objective',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-form>
        </div>
        <div v-show="stepIndex === 2" class="campaign-create-step2">
          <a-form
            :form="formTargets"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 16 }"
          >
            <a-form-item label="Location(s)" labelAlign="left">
              <div class="campaign-create-step2__country">
                <span class="campaign-create-step2__country__text"
                  >Country<span>*</span></span
                >
                <a-auto-complete
                  class="campaign-create-step2__country__list"
                  v-decorator="[
                    'country_id',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter a valid country',
                        },
                      ],
                    },
                  ]"
                  style="width: 200px"
                  placeholder="input here"
                  @select="onForm2Select"
                  @search="onForm2Search"
                >
                  <template slot="dataSource">
                    <a-select-option
                      v-for="item in showList"
                      :key="item.id"
                      :text="item.name_en"
                    >
                      {{ item.name_en }}
                    </a-select-option>
                  </template></a-auto-complete
                >
              </div>
              <div class="campaign-create-step2__city">
                <a-form-item label="City" labelAlign="left">
                  <a-input
                    class="campaign-create-step2__city__city"
                    v-decorator="['city', {}]"
                  />
                </a-form-item>
              </div>
            </a-form-item>
            <a-form-item label="Gender" labelAlign="left">
              <a-select
                v-decorator="[
                  'gender',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please select your target gender',
                      },
                    ],
                  },
                ]"
                placeholder="Select gender"
              >
                <a-select-option value="Male"> Male </a-select-option>
                <a-select-option value="Female"> Female </a-select-option>
                <a-select-option value="All"> All </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="Age"
              class="campaign-create-step2__age"
              labelAlign="left"
            >
              <div class="campaign-create-step2__age__line">
                <NumberInputGroup
                  textFrom="From"
                  textTo="To"
                  :fromMax="120"
                  :toMax="120"
                  v-decorator="[
                    'age_group',
                    {
                      initialValue: {
                        fromNum: null,
                        toNum: null,
                      },
                      rules: [{ validator: checkAge, required: true }],
                    },
                  ]"
                />
              </div>
            </a-form-item>
            <a-form-item label="Interests" labelAlign="left">
              <a-input
                :maxLength="100"
                v-decorator="[
                  'interests',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter interest(s)',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
            <a-form-item label="Target Description" labelAlign="left">
              <a-textarea
                :auto-size="{ minRows: 3, maxRows: 5 }"
                v-decorator="['target_description', { initialValue: '' }]"
              />
            </a-form-item>
          </a-form>
        </div>
        <div v-show="stepIndex === 3" class="campaign-create-step3">
          <div class="campaign-create-step3__line">
            <span>Text</span>
            <div class="campaign-create-step3__tlist">
              <TextItem
                v-for="(item, index) in texts"
                :key="`texts-${index}`"
                is-edit="2"
                v-bind="item"
              />
              <CreateAdd
                width="238"
                height="144"
                @add-text="isShowAddText = true"
              />
            </div>
          </div>
          <div class="campaign-create-step3__line">
            <span>Images</span>
            <div class="campaign-create-step3__imglist">
              <ImageItem
                v-for="(item, index) in images"
                :key="`images-${index}`"
                is-edit="2"
                v-bind="item"
                can-pop
              />
              <CreateAdd width="238" height="144" @add-text="openImgModal" />
            </div>
          </div>
          <div class="campaign-create-step3__line">
            <span>Videos</span>
            <div class="campaign-create-step3__tlist">
              <VideoItem
                is-edit="2"
                v-for="(item, index) in videos"
                :key="`videos-${index}`"
                v-bind="item"
              />
              <CreateAdd width="238" height="144" @add-text="openVideoModal" />
            </div>
          </div>
        </div>
        <div v-show="stepIndex === 4" class="campaign-create-step4">
          <div class="collap">
            <div class="collap-left active">
              <div class="collap-head">
                <span>Deliverbles</span>
                <a-icon
                  v-if="isExpand1"
                  class="up"
                  type="caret-up"
                  theme="filled"
                  @click="collaExpand1(false)"
                />
                <a-icon
                  v-else
                  class="down"
                  type="caret-down"
                  theme="filled"
                  @click="collaExpand1(true)"
                />
              </div>
              <div v-show="isExpand1" class="collap-content">
                <a-form
                  :form="formDeli"
                  :label-col="{ span: 5 }"
                  :wrapper-col="{ span: 18 }"
                >
                  <a-form-item label="Content Format(s)" labelAlign="left">
                    <a-checkbox @change="contentFormatCheckChange('Video')">
                      Video
                    </a-checkbox>
                    <a-checkbox @change="contentFormatCheckChange('Image')">
                      Image
                    </a-checkbox>
                    <a-checkbox @change="contentFormatCheckChange('Other')">
                      Other
                    </a-checkbox>
                    <a-input
                      style="display: none"
                      v-decorator="[
                        'content_format',
                        {
                          initialValue: '',
                          rules: [
                            {
                              required: true,
                              message: 'Please select at least one format',
                            },
                          ],
                        },
                      ]"
                    />
                  </a-form-item>
                  <a-form-item label="Platform(s)" labelAlign="left">
                    <a-checkbox
                      v-for="(item, idx) in PLATFORMS"
                      :key="`platforms-s1-${idx}`"
                      @change="contentPlatformsCheckChange(item.name)"
                      ><img class="platform-icon" :src="item.icon" />
                      {{ item.name }}
                    </a-checkbox>
                    <a-input
                      style="display: none"
                      v-decorator="[
                        'content_platforms',
                        {
                          initialValue: '',
                          rules: [
                            {
                              required: true,
                              message: 'Please select at least one platform',
                            },
                          ],
                        },
                      ]"
                    />
                  </a-form-item>
                  <a-form-item label="Deadline" labelAlign="left">
                    <a-date-picker
                      @change="onChangeDeadline"
                      :disabled-date="disabledDate"
                      v-decorator="[
                        'deadline',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Please select deadline',
                            },
                          ],
                        },
                      ]"
                    />
                  </a-form-item>
                  <a-form-item label="#Hashtags" labelAlign="left">
                    <TagInput
                      tagText="#"
                      v-decorator="[
                        'hashtags',
                        {
                          initialValue: '',
                        },
                      ]"
                    />
                  </a-form-item>
                  <a-form-item label="@Mentions" labelAlign="left">
                    <TagInput
                      tagText="@"
                      v-decorator="[
                        'at',
                        {
                          initialValue: '',
                        },
                      ]"
                    />
                  </a-form-item>
                  <a-form-item label="Caption Description" labelAlign="left">
                    <a-textarea
                      :auto-size="{ minRows: 3, maxRows: 5 }"
                      v-decorator="[
                        'postcaption',
                        {
                          initialValue: '',
                        },
                      ]"
                    />
                  </a-form-item>
                </a-form>
              </div>
            </div>
            <div class="collap-right">
              <a-icon
                class="collap-right-on"
                type="check-circle"
                theme="filled"
              />
            </div>
          </div>
          <div class="collap">
            <div class="collap-left" :class="{ active: isChecked2 }">
              <div class="collap-head">
                <span>Campaign Guidelines</span>
                <a-icon
                  v-if="isExpand2"
                  type="caret-up"
                  theme="filled"
                  @click="collaExpand2(false)"
                />
                <a-icon
                  v-else
                  type="caret-down"
                  theme="filled"
                  @click="collaExpand2(true)"
                />
              </div>
              <div v-show="isExpand2" class="collap-content">
                <a-form
                  :form="formGuid"
                  :label-col="{ span: 5 }"
                  :wrapper-col="{ span: 18 }"
                >
                  <a-form-item label="Do’s" labelAlign="left">
                    <TextWithCount
                      :maxRow="5"
                      :textMaxNum="2000"
                      v-decorator="[
                        'dos',
                        {
                          initialValue: '',
                        },
                      ]"
                    />
                  </a-form-item>
                  <a-form-item label="Don’ts" labelAlign="left">
                    <TextWithCount
                      :maxRow="5"
                      :textMaxNum="2000"
                      v-decorator="[
                        'donts',
                        {
                          initialValue: '',
                        },
                      ]"
                    />
                  </a-form-item>
                  <a-form-item label="Terms" labelAlign="left">
                    <TextAndFileUplaod
                      :textMaxNum="8000"
                      v-decorator="[
                        'terms_group',
                        {
                          initialValue: {
                            text: '',
                            file: '',
                          },
                        },
                      ]"
                    />
                  </a-form-item>
                </a-form>
              </div>
            </div>
            <div class="collap-right">
              <a-icon
                v-if="isChecked2"
                class="collap-right-on"
                type="check-circle"
                theme="filled"
                @click="collaChecked2(false)"
              />
              <i
                v-else
                class="collap-right-off"
                @click="collaChecked2(true)"
              ></i>
            </div>
          </div>
        </div>
        <div v-show="stepIndex === 5" class="campaign-create-step5">
          <div class="campaign-create-step5__left">Compensation Method:</div>
          <div class="campaign-create-step5__right">
            <div class="campaign-create-step5__item">
              <a-checkbox @change="onForm_5_1_CheckChange">
                Pay per Content
              </a-checkbox>
              <div v-if="isShowStep5Check1" class="campaign-create-step5__pay">
                <div class="campaign-create-step5__pay__line">
                  <span class="pay-title">Price</span>
                  <div class="pay-section">
                    <a-input-number
                      v-model="pay_per_content_amount_lower"
                      :min="0"
                      :max="99999999"
                      @change="onStep5AmountChange"
                    />
                    <span
                      >&nbsp;&nbsp;{{
                        $store.state.currentCurrency.sign
                      }}&nbsp;&nbsp;-&nbsp;&nbsp;</span
                    >
                    <a-input-number
                      v-model="pay_per_content_amount_upper"
                      :min="0"
                      :max="99999999"
                      @change="onStep5AmountChange"
                    />
                    <span
                      >&nbsp;&nbsp;{{
                        $store.state.currentCurrency.sign
                      }}&nbsp;&nbsp;</span
                    >
                  </div>
                </div>
                <p v-show="showStep5Error1" class="error right-error">
                  {{ showStep5ErrorText }}
                </p>
              </div>
            </div>
            <div class="campaign-create-step5__item mt20">
              <a-checkbox @change="onForm_5_2_CheckChange">
                Free Product
              </a-checkbox>
            </div>
            <div class="campaign-create-step5__item mt20">
              <a-checkbox @change="onForm_5_3_CheckChange">
                Gift Cards
              </a-checkbox>
              <div v-if="isShowStep5Check3" class="campaign-create-step5__pay">
                <div class="campaign-create-step5__pay__line">
                  <span class="pay-title">Price</span>
                  <div class="pay-section">
                    <a-input-number
                      v-model="gift_card_amount"
                      :min="0"
                      :max="99999999"
                      @change="onStep5GiftChange"
                    />
                    <span
                      >&nbsp;&nbsp;{{
                        $store.state.currentCurrency.sign
                      }}&nbsp;&nbsp;</span
                    >
                  </div>
                </div>
                <p v-show="showStep5Error2" class="error">
                  Please make sure this number is larger than 0
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-show="stepIndex === 6" class="campaign-create-step6">
          <ViewCard title="Campaign">
            <template #content> <PreviewCard1 :item="createData" /> </template>
          </ViewCard>
          <ViewCard title="Targets">
            <template #content> <PreviewCard2 :item="createData" /> </template>
          </ViewCard>
          <ViewCard title="Creatives">
            <template #content> <PreviewCard3 :item="createData" /> </template>
          </ViewCard>
          <ViewCard title="Tools">
            <template #content> <PreviewCard4 :item="createData" /> </template>
          </ViewCard>
          <ViewCard title="Compensation">
            <template #content> <PreviewCard5 :item="createData" /> </template>
          </ViewCard>
        </div>
      </a-spin>
    </div>
    <div class="campaign-create-action">
      <a-button
        v-show="stepIndex > 1"
        class="campaign-create-action__pre"
        @click="Previous"
      >
        Previous
      </a-button>
      <a-button
        v-if="stepIndex < 6"
        class="campaign-create-action__next"
        type="primary"
        @click="checkForm"
      >
        Next
      </a-button>
      <a-button v-else type="primary" @click="submitCreate"> Launch </a-button>
    </div>
    <AddTextModal :isShow="isShowAddText" @on-close="isShowAddText = false" />
    <AddImgModal :isShow="isShowAddImg" @on-close="isShowAddImg = false" />
    <AddVideoModal
      :isShow="isShowAddVideo"
      @on-close="isShowAddVideo = false"
    />
    <ChoosePopup
      title="Set Default display currency"
      :defaultActive="defaultActiveCurrencyIndex"
      :lists="currenciesLists"
      @close-popup="closePopup"
      @choose-item="chooseCategories"
      :isChoosePopup="isChoosePopup"
      :isCtegories="false"
    />
  </div>
</template>
<script>
import CampaignSteps from "./components/create/CampaignSteps.vue";
import CreateAdd from "./components/create/CreateAdd.vue";
import TextItem from "./components/create/TextItem.vue";
import ImageItem from "./components/create/ImageItem.vue";
import VideoItem from "./components/create/VideoItem.vue";
import AddTextModal from "./components/create/AddTextModal.vue";
import AddImgModal from "./components/create/AddImgModal.vue";
import AddVideoModal from "./components/create/AddVideoModal.vue";
import ViewCard from "./components/create/ViewCard.vue";
import PreviewCard1 from "./components/public/PreviewCard1.vue";
import PreviewCard2 from "./components/public/PreviewCard2.vue";
import PreviewCard3 from "./components/public/PreviewCard3.vue";
import PreviewCard4 from "./components/public/PreviewCard4.vue";
import PreviewCard5 from "./components/public/PreviewCard5.vue";
import TagInput from "./components/public/TagInput.vue";
import ChoosePopup from "@/components/ChoosePopup.vue";
import NumberInputGroup from "@/components/NumberInputGroup.vue";
import APIS, { PLATFORMS } from "@/api/const";
import TextAndFileUplaod from "@/components/TextAndFileUplaod.vue";
import TextWithCount from "@/components/TextWithCount.vue";

import _ from "lodash";
import axios from "@/utils/request";

const customStyle =
  "background: #f7f7f7;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden";
export default {
  components: {
    CampaignSteps,
    CreateAdd,
    TextItem,
    AddTextModal,
    ImageItem,
    AddImgModal,
    VideoItem,
    AddVideoModal,
    ViewCard,
    PreviewCard1,
    PreviewCard2,
    PreviewCard3,
    PreviewCard4,
    PreviewCard5,
    TagInput,
    ChoosePopup,
    NumberInputGroup,
    TextAndFileUplaod,
    TextWithCount,
  },
  data() {
    return {
      mediumLists: [],
      isLoading: false,
      stepIndex: 1,
      formMain: this.$form.createForm(this, { name: "form-step-1" }),
      formTargets: this.$form.createForm(this, { name: "form-step-2" }),
      formDeli: this.$form.createForm(this, { name: "form-step-3" }),
      formGuid: this.$form.createForm(this, { name: "form-step-5" }),
      platforms: [],
      rangeConfig: {
        rules: [
          {
            type: "array",
            required: true,
            message: "Please select your campaign duration",
          },
        ],
      },
      createData: {
        platforms: [],
        creatives: {},
        content_platforms: [],
        hashtags: "",
      },
      showList: this.$store.state.commonConfig.countries,
      isShowAddText: false,
      isShowAddImg: false,
      isShowAddVideo: false,
      customStyle,
      isShowStep5Check1: false,
      isShowStep5Check2: false,
      isShowStep5Check3: false,
      PLATFORMS,
      contentFormats: [],
      contentPlatforms: [],
      currenciesLists: this.$store.state.commonConfig.currencies,
      defaultActiveCurrencyIndex: -1,
      isChoosePopup: false,
      selectedCurrency: {
        id: 0,
        simple_name: "Please select",
        sign: "",
      },
      showCurrencyError: false,
      texts: this.$store.state.campaignTexts,
      images: this.$store.state.campaignImages,
      videos: this.$store.state.campaignVideos,
      showStep5Error1: false,
      showStep5Error2: false,
      pay_per_content_amount_lower: 0,
      pay_per_content_amount_upper: 0,
      gift_card_amount: 0,
      country_name: "",
      showStep5ErrorText: "",
      isExpand1: true,
      isExpand2: false,
      isChecked2: false,
    };
  },
  created() {},
  mounted() {},
  watch: {
    pay_per_content_amount_lower() {},

    "$store.state.campaignTexts": {
      handler: function(newVal, oldVal) {
        this.texts = newVal;
      },
    },
    "$store.state.campaignImages": {
      handler: function(newVal, oldVal) {
        this.images = newVal;
      },
    },
    "$store.state.campaignVideos": {
      handler: function(newVal, oldVal) {
        this.videos = newVal;
      },
    },
  },
  methods: {
    collaChecked2(val) {
      this.isChecked2 = val;
    },
    collaExpand2(val) {
      this.isExpand2 = val;
    },
    collaExpand1(val) {
      this.isExpand1 = val;
    },
    checkAge(rule, value, callback) {
      if (value.fromNum === null || value.toNum === null) {
        callback("Please fill out age");
        return;
      }
      if (value.fromNum > value.toNum) {
        callback(
          "Please make sure the second number is larger or equal to the first number"
        );
        return;
      }
      callback();
    },
    disabledDate(current) {
      return current && current < this.dayjs();
    },
    onStep5GiftChange() {
      if (this.gift_card_amount <= 0) {
        this.showStep5Error2 = true;
        return;
      } else {
        this.showStep5Error2 = false;
      }
    },
    onStep5AmountChange() {
      if (
        this.pay_per_content_amount_lower <= this.pay_per_content_amount_upper
      ) {
        this.showStep5Error1 = false;
      } else if (
        this.pay_per_content_amount_lower &&
        this.pay_per_content_amount_upper
      ) {
        this.showStep5Error1 = true;
        this.showStep5ErrorText = `Please make sure this number is larger than or equal to ${this.pay_per_content_amount_lower}`;
      }
    },
    closePopup() {
      this.isChoosePopup = false;
      //   this.isChoosePopup5 = false;
    },
    chooseCategories(item) {
      this.selectedCurrency = item;
      this.isChoosePopup = false;
      this.showCurrencyError = false;
      this.$store.commit("setCurrentCurrencyId", item);
    },
    // platforms
    platformsCheckChange(val) {
      if (_.indexOf(this.platforms, val) > -1) {
        _.remove(this.platforms, (item) => {
          return item === val;
        });
      } else {
        this.platforms.push(val);
      }
      this.formMain.setFieldsValue({
        platforms: _.join(this.platforms, ","),
      });
      console.log("platforms：", JSON.stringify(this.platforms));
    },
    // contentFormats
    contentFormatCheckChange(val) {
      if (_.indexOf(this.contentFormats, val) > -1) {
        _.remove(this.contentFormats, (item) => {
          return item === val;
        });
      } else {
        this.contentFormats.push(val);
      }
      this.formDeli.setFieldsValue({
        content_format: _.join(this.contentFormats, ","),
      });
      console.log("scontent_format：", JSON.stringify(this.contentFormats));
    },
    // contentPlatforms
    contentPlatformsCheckChange(val) {
      if (_.indexOf(this.contentPlatforms, val) > -1) {
        _.remove(this.contentPlatforms, (item) => {
          return item === val;
        });
      } else {
        this.contentPlatforms.push(val);
      }
      this.formDeli.setFieldsValue({
        content_platforms: _.join(this.contentPlatforms, ","),
      });
      console.log("content_platforms：", JSON.stringify(this.contentPlatforms));
    },
    onChangeDeadline() {},
    // 下一步
    checkForm() {
      if (this.stepIndex === 1) {
        this.formMain.validateFields((err, formDatas) => {
          if (!this.selectedCurrency.id) {
            this.showCurrencyError = true;
            return;
          } else {
            this.showCurrencyError = false;
          }
          if (err) {
            return;
          }
          if (!formDatas.name.trim()) {
            this.$message.error("The name should not be white space");
            return;
          }
          const rangeValue = formDatas["duration"];
          this.createData = {
            ...this.createData,
            ...formDatas,
            currency_id: this.selectedCurrency.id,
            currency_sign: this.selectedCurrency.sign,
            currency_str:
              this.selectedCurrency.sign + this.selectedCurrency.simple_name,
            platforms: this.platforms,
            start_time: parseInt(
              new Date(rangeValue[0].format("MM/DD/YYYY HH:mm:ss")).getTime() /
                1000
            ),
            end_time: parseInt(
              new Date(rangeValue[1].format("MM/DD/YYYY HH:mm:ss")).getTime() /
                1000
            ),
          };
          console.log("step 1 数据：", this.createData);
          this.stepIndex++;
        });
      } else if (this.stepIndex === 2) {
        this.formTargets.validateFields((err, formDatas) => {
          if (err) {
            return;
          }
          if (isNaN(formDatas.country_id) || !this.country_name) {
            this.$message.error("Please enter a valid country");
            return;
          }
          this.createData = {
            ...this.createData,
            ...formDatas,
            age_lower: formDatas.age_group.fromNum,
            age_upper: formDatas.age_group.toNum,
            country_name: this.country_name,
          };
          console.log("step 2 数据：", this.createData);
          this.stepIndex++;
        });
      } else if (this.stepIndex === 3) {
        this.createData.creatives = {
          text: this.texts,
          image: this.images,
          video: this.videos,
        };
        console.log("step 3 数据：", this.createData);
        this.stepIndex++;
      } else if (this.stepIndex === 4) {
        let dos = "";
        let donts = "";
        let terms_and_conditions = "";
        let terms_and_conditions_file = "";
        this.formGuid.validateFields((err, guidDatas) => {
          if (err) {
            return;
          }
          if (this.isChecked2) {
            dos = guidDatas.dos || "";
            donts = guidDatas.donts || "";
            terms_and_conditions = guidDatas.terms_group.text || "";
            terms_and_conditions_file = guidDatas.terms_group.file || "";
          }
        });
        this.formDeli.validateFields((err, formDatas) => {
          if (err) {
            return;
          }
          this.createData = {
            ...this.createData,
            ...formDatas,
            dos,
            donts,
            terms_and_conditions,
            terms_and_conditions_file,
            deadline: parseInt(new Date(formDatas.deadline).getTime() / 1000),
            content_format: this.contentFormats,
            content_platforms: this.contentPlatforms,
          };
          console.log("step 4 数据：", this.createData);
          this.stepIndex++;
        });
      } else if (this.stepIndex === 5) {
        if (
          !this.isShowStep5Check1 &&
          !this.isShowStep5Check2 &&
          !this.isShowStep5Check3
        ) {
          this.$message.error("Please select at least one compensation method");
          return;
        }
        if (this.isShowStep5Check1) {
          if (
            this.pay_per_content_amount_lower >
            this.pay_per_content_amount_upper
          ) {
            this.showStep5Error1 = true;
            this.showStep5ErrorText = `Please make sure this number is larger than or equal to ${this.pay_per_content_amount_lower}`;
            return;
          }
          if (this.pay_per_content_amount_upper <= 0) {
            this.showStep5Error1 = true;
            this.showStep5ErrorText =
              "Please make sure this number is larger than 0";
            return;
          }
        } else {
          this.pay_per_content_amount_lower = 0;
          this.pay_per_content_amount_upper = 0;
        }
        if (this.isShowStep5Check3) {
          if (this.gift_card_amount <= 0) {
            this.showStep5Error2 = true;
            return;
          }
        } else {
          this.gift_card_amount = 0;
        }
        this.createData = {
          ...this.createData,
          pay_per_content_amount_lower: this.pay_per_content_amount_lower,
          pay_per_content_amount_upper: this.pay_per_content_amount_upper,
          free_product_enable: this.isShowStep5Check2 ? 1 : 0,
          gift_card_amount: this.gift_card_amount,
        };
        console.log("step 5 数据：", this.createData);
        this.stepIndex++;
      }
    },
    // 上一步
    Previous() {
      if (this.stepIndex > 1) {
        this.stepIndex--;
      }
    },
    onForm2Search(searchText) {
      this.showList = this.$store.state.commonConfig.countries.filter(
        (item) => {
          if (
            item.name_en.toUpperCase().indexOf(searchText.toUpperCase()) > -1
          ) {
            return item;
          }
        }
      );
    },
    onForm2Select(value, option) {
      this.country_name = option.data.attrs.text;
    },
    openImgModal() {
      this.isShowAddImg = true;
    },
    openVideoModal() {
      this.isShowAddVideo = true;
    },
    textModalOk(list) {},
    onForm_5_1_CheckChange(e) {
      this.isShowStep5Check1 = e.target.checked;
    },
    onForm_5_2_CheckChange(e) {
      this.isShowStep5Check2 = e.target.checked;
    },
    onForm_5_3_CheckChange(e) {
      this.isShowStep5Check3 = e.target.checked;
    },
    // 提交
    submitCreate() {
      this.isLoading = true;
      console.log("提交的数据：", JSON.stringify(this.createData));
      axios
        .post(APIS.CAMPAIGN.CREATE_CAMP, {
          ...this.createData,
        })
        .then((res) => {
          if (res.code == 0) {
            this.$store.dispatch("resetCampaignCreatives");
            this.$message.success("Created Success.");
            setTimeout(() => {
              this.$router.push({ name: "campaign" });
            }, 1500);
          } else {
            this.$message.error(res.msg);
          }
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.campaign-create {
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.06);
  margin-top: 32px;
  border-radius: 12px;
  padding: 2px 100px 2.8571rem;
  margin-bottom: 2.8571rem;
  .collap {
    display: flex;
    justify-content: space-between;
    &-left {
      width: calc(100% - 66px);
      border-radius: 12px;
      border: 1px solid #e6e6e6;
      padding: 24px;
      margin-bottom: 32px;
      box-sizing: border-box;
      &.active {
        border: 2px solid #2898ff;
      }
    }
    &-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 1.4286rem;
        font-family: "dtc-bold";
      }
      i {
        font-size: 24px;
        &:hover {
          opacity: 0.8;
        }
      }
      .up {
        color: #999;
      }
      .down {
        color: #333;
      }
    }
    &-content {
      margin-top: 24px;
    }
    &-right {
      width: 66px;
      display: flex;
      justify-content: center;
      i {
        font-size: 32px;
        height: 32px;
        width: 32px;
        cursor: pointer;
        margin-top: 24px;
        &:hover {
          opacity: 0.85;
        }
      }
      &-off {
        border: 1px solid #999;
        border-radius: 50%;
      }
      &-on {
        color: #2898ff;
      }
    }
  }
  .error {
    color: #f5222d;
    &.right-error {
      margin-left: 208px;
      margin-top: 5px;
    }
  }
  .platform-icon {
    width: 28px;
    height: 28px;
  }
  &-forms {
    margin-top: 32px;
  }
  &-action {
    width: 300px;
    margin: 30px auto;
    display: flex;
    justify-content: space-around;
    button {
      width: 90px;
    }
  }
  &-step1 {
    &__currency {
      &__select {
        width: 320px;
        height: 32px;
        border-radius: 6px;
        border: 1px solid #e6e6e6;
        display: inline-block;
        text-align: center;
        line-height: 32px;
        margin-left: 16px;
        cursor: pointer;
        &:hover {
          opacity: 0.85;
          box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.06);
        }
      }
    }
    &__amount {
      margin-top: 16px;
      //   > input {
      //     width: 320px;
      //     margin-left: 23px;
      //   }
      .ant-input-number {
        margin-left: 23px;
      }
    }
  }
  &-step2 {
    &__age {
      .ant-form-item {
        margin-bottom: 0;
      }
      &__line {
        display: flex;
        align-items: center;
        .text1 {
          margin-right: 10px;
        }
        .text2 {
          margin: 0 10px;
        }
      }
    }
    &__country {
      &__text {
        width: 117px;
        display: inline-block;
        span {
          color: rgb(245, 34, 45);
          font-family: SimSun, sans-serif;
          margin-left: 3px;
        }
      }
      &__list {
        width: 530px !important;
        margin-left: 18px !important;
      }
    }
    &__city {
      margin-top: 10px;
      .ant-form-item-label-left {
        width: 50px;
      }
      .ant-form-item {
        margin-bottom: 0;
      }
      &__city {
        width: 530px !important;
        margin-left: 24px !important;
      }
    }
  }
  &-step3 {
    &__line {
      margin-top: 20px;
      > span {
        font-size: 1.2857rem;
        font-weight: 600;
      }
    }
    &__tlist,
    &__imglist {
      margin-top: 8px;
      display: flex;
      flex-wrap: wrap;
    }
  }
  &-step4 {
  }
  &-step5 {
    justify-content: center;
    display: flex;
    margin-bottom: 80px;
    &__left {
      width: 180px;
    }
    &__right {
      width: 500px;
    }
    &__pay {
      padding-left: 20px;
      margin-top: 16px;
      &__line {
        display: flex;
        margin-top: 16px;
        align-items: center;
        .pay-title {
          color: #999;
        }
        .pay-type {
          width: 192px;
          height: 32px;
          border-radius: 6px;
          border: 1px solid #e6e6e6;
          line-height: 32px;
          display: inline-block;
          text-align: center;
          margin-left: 30px;
        }
        .pay-section {
          margin-left: 56px;
          > input {
            width: 50px;
          }
        }
      }
    }
  }
  /deep/ .ant-form-item-required::before {
    content: "";
  }
  /deep/ .ant-form-item-required::after {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 1rem;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
  /deep/ .ant-checkbox-wrapper {
    margin-left: 8px;
    width: 156px;
    margin-bottom: 6px;
  }
  /deep/ .ant-collapse-item {
    background: #fff !important;
    border-radius: 12px !important;
    border: 1px solid #e6e6e6 !important;
  }
  /deep/ .ant-collapse-borderless {
    background-color: #fff;
  }
  /deep/ .ant-collapse-item-active {
    border: 2px solid #2898ff !important;
  }
  //   .numgroup {
  //     /deep/ .ant-form-explain {
  //       margin-left: 172px !important;
  //     }
  //   }
}
</style>
