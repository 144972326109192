// 权限操作
import store from './../store'

// let userPermissions = store.state.authorityJudgment

const inspectUserPermissions = (action, userPermissions) => {
  // console.log(userPermissions)
  if(userPermissions.length > 0) {
    for(let i = 0; i < userPermissions.length ; i++) {
      if(userPermissions[i].action == action) {
        return userPermissions[i].allow
      }
    }
  }

  return true
}

export default inspectUserPermissions