<template>
  <div
    class="camprow"
    :style="{ opacity: item.show_status === 'ended' ? '0.6' : 'unset' }"
  >
    <div class="camprow-icon">
      <img :src="item.cover_image" />
      <span
        class="camprow-icon-tips"
        :class="{
          avaliable: formatStatusText(item.show_status),
          expired: item.show_status === 'expired',
          pending: item.show_status === 'pending',
          declined: item.show_status === 'declined',
          approved: item.show_status === 'approved',
          cancelled: item.show_status === 'cancelled',
          ended: item.show_status === 'ended',
        }"
        >{{ item.show_status_text }}</span
      >
    </div>
    <ul class="camprow-detail">
      <li>
        <span class="detail-title">Campaign Name</span>
        <a-tooltip placement="top">
          <template slot="title" v-if="item.campaign_name.length > 20">
            <span>{{ item.campaign_name }}</span>
          </template>
          <span class="detail-text">{{ item.campaign_name }}</span>
        </a-tooltip>
      </li>
      <li>
        <span class="detail-title">Payout Type</span>
        <TypeItem tableType="row" :item="item" />
      </li>
      <li class="detail-reltive">
        <span class="detail-title">Location</span>
        <span class="detail-text"
          >{{
            Array.isArray(item.region) ? item.region.join(", ") : item.region
          }}
        </span>
        <span
          class="paytype-mores"
          v-if="item.region && item.region.length > 1"
        >
          <span
            v-for="(ptype, index) in item.region"
            :key="`ptype-hides-${index}`"
            class="paytype-tags"
            :style="
              `background-color:${
                color[index % 3]
              };padding:2px;margin-bottom:8px;margin-right:8px;border-radius:4px;`
            "
            >{{ ptype }}</span
          >
        </span>
      </li>
      <li>
        <span class="detail-title">Deadline</span>
        <span class="detail-text">{{
          dayjs(item.deadline_at).format("ll")
        }}</span>
      </li>
      <li>
        <span class="detail-title">Platform</span>
        <span class="detail-platform">
          <img
            v-for="(plat, index) in item.plat_list"
            :key="`plat-list-${index}`"
            :src="plat.icon"
          />
        </span>
      </li>
      <li>
        <span class="detail-title">Task Format</span>
        <span class="detail-format">
          <a-tooltip
            v-for="(fot, index) in item.format_list"
            :key="`format-list-${index}`"
            :title="fot.full_name"
          >
            <img :src="fot.icon" />
          </a-tooltip>
        </span>
      </li>
      <li>
        <span class="detail-title">Incentive</span>
        <span class="detail-money"
          >{{ item.currency_sign }}{{ item.incentives }}</span
        >
      </li>
    </ul>
    <div class="camprow-info">
      <div class="info-title">Campaign Info</div>
      <div class="info-content">
        <div class="info-text six-line" v-html="item.content"></div>
        <span class="info-btn" @click="gotoDetail">View More</span>
      </div>
    </div>
    <div class="camprow-action">
      <a-button class="active-btn" type="primary" @click="gotoDetail">
        Apply Now
      </a-button>
      <a-button class="negative-btn" @click="gotoDetail">
        View Details
      </a-button>
      <span class="action-text _ellipsis"
        >Open Application {{ dayjs(item.start_at).format("ll") }}</span
      >
    </div>
  </div>
</template>

<script>
const defaultLogo = require("@/assets/default.png");
import Modal from "@/components/Modal.vue";
import TypeItem from "./TypeItem.vue";
import _ from "lodash";

export default {
  components: { Modal, TypeItem },
  props: {
    item: {
      type: Object,
      default: () => null,
    },
    calcPage: {
      type: Number,
      default: 1,
    },
  },
  mounted() {},
  computed: {
    content() {
      console.log(item.content);
    },
  },
  data() {
    return {
      color: ["#28acff", "#15dcb3", "#ffc55a"],
      countryMap: {},
      statByLocal: "today",
      errorPlaceHolder: null,
      isShowUpdate: false,
      newStatus: "",
      isLoadingModal: false,
    };
  },
  watch: {},
  created() {},
  methods: {
    gotoDetail() {
      this.$router.push({
        name: this.formatStatusText(this.item.show_status)
          ? "campaign_detail"
          : "campaign_detail_preview",
        params: {
          id: this.item.id,
        },
        query: { page: this.calcPage },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.camprow {
  display: flex;
  padding: 8px;
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(102, 118, 186, 0.1);
  border-radius: 16px;
  margin-top: 16px;
  &:hover {
    box-shadow: 0px 0px 12px 0px rgba(102, 118, 186, 0.25);
  }
  &-icon {
    position: relative;
    display: flex;
    align-items: center;
    width: 21.5%;
    > img {
      // width: 18.8571rem;
      width: 100%;
      height: 12.7143rem;
      object-fit: cover;
      border-radius: 16px;
    }
    &-tips {
      position: absolute;
      top: 0.3571rem;
      right: 0;
      width: 5.5rem;
      height: 1.5714rem;

      border-radius: 0px 14px 0px 8px;
      text-align: center;
      color: #ffffff;
      line-height: 22px;
      &.avaliable {
        background: #2ad57b;
      }
      &.expired {
        background: #aaaaaa;
      }
      &.pending {
        background: #28acff;
      }
      &.declined {
        background: #ff5252;
      }
      &.approved {
        background: #ffc55a;
      }
      &.cancelled {
        background: #e6e6e6;
      }
      &.ended {
        background: #000;
      }
    }
  }
  &-detail {
    flex-shrink: 0;
    width: 30.5%;
    padding: 0px 12px;
    > li {
      display: flex;
      height: 1.7857rem;
      align-items: center;
      margin-bottom: 2px;
    }
    .detail-title {
      color: #666666;
      width: 40%;
    }
    .detail-text {
      color: #333333;
      width: 60%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .detail-platform {
      display: flex;
      align-items: center;
      > img {
        width: 20px;
        height: 20px;
        object-fit: contain;
        margin-right: 6px;
      }
    }
    .detail-format {
      display: flex;
      align-items: center;
      > img {
        // width: 21px;
        // height: 21px;
        object-fit: contain;
        margin-right: 6px;
      }
    }
    .detail-money {
      color: #3762d6;
      font-family: "prefer-bold";
    }
  }
  .detail-reltive {
    position: relative;
    .paytype-mores {
      display: none;
      position: absolute;
      background: rgba(0, 0, 0, 0.5);
      padding: 10px;
      border-radius: 4px;
      top: 30px;
      right: -2.8571rem;
      max-width: 14.2857rem;
      flex-wrap: wrap;
      z-index: 999;
      color: #fff;
    }
    .paytype-mores:after,
    .paytype-mores:before {
      bottom: 100%;
      left: 10%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    .paytype-mores:after {
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: rgba(0, 0, 0, 0.5);
      border-width: 6px;
      margin-left: -6px;
    }
    &:hover {
      .paytype-mores {
        display: flex;
      }
    }
  }

  &-info {
    width: 33.5%;
    height: 12.7143rem;
    background: #f6f9ff;
    border-radius: 16px;

    padding: 10px;
    .info-title {
      color: #999;
      margin-bottom: 2px;
    }
    .info-content {
      position: relative;
      height: 10.1429rem;
    }
    .info-text {
      color: #333333;
      font-size: 1rem;
      line-height: 18px;
      height: 78%;
      --m: linear-gradient(to bottom, #000 calc(100% - 1rem), transparent 100%);
      mask: var(--m);
      -webkit-mask-image: var(--m);
      p {
        margin: 0;
        margin-bottom: 5px;
      }
    }
    .info-btn {
      color: #3762d6;
      position: absolute;
      bottom: 2px;
      right: 0;
      cursor: pointer;
      margin-bottom: 2px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &-action {
    // width: 14.2857rem;
    width: 14.75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    > button {
      margin-bottom: 20px;
      width: 10.3571rem;
    }
    .active-btn {
      background-color: #3762d6;
      border-color: #3762d6;
      &:hover {
        opacity: 0.85;
      }
    }
    .negative-btn {
      border: 1px solid #3762d6;
      color: #3762d6;
      &:hover {
        opacity: 0.7;
      }
    }
    .action-text {
      color: #666;
      position: absolute;
      font-size: 0.8571rem;
      text-align: center;
      bottom: 14px;
      width: 100%;
    }
  }
}
/deep/ .info-text {
  p,
  span {
    background-color: #f6f9ff !important;
    font-family: "prefer" !important;
    color: #666 !important;
    font-weight: unset;
  }
}
</style>
