<template>
  <div id="creative-texts">
    <div class="content">
      <div class="content-title">
        <div class="items">
          <div class="item" style="width: 270px">
            <span>Date Range</span>
            <a-range-picker
              class="date"
              v-model="dateRange"
              @change="handleChangeDate"
              format="L"
            />
          </div>
          <div class="item" style="width: 270px">
            <span>Status</span>
            <PSelector
              size="large"
              v-model="params.status"
              :options="[
                {
                  value: 'Scheduled',
                  label: 'Scheduled',
                },
                {
                  value: 'Active',
                  label: 'Active',
                },
                {
                  value: 'Expired',
                  label: 'Expired',
                },
              ]"
              multiple
              clearAble
            ></PSelector>
          </div>
          <div class="item" style="width: 270px">
            <span>Permission</span>
            <PSelector
              size="large"
              v-model="params.privacy"
              :options="[
                {
                  value: 'PUBLIC',
                  label: 'Public for all publishers',
                },
                {
                  value: 'PRIVATE',
                  label: 'Private',
                },
              ]"
              multiple
              clearAble
            ></PSelector>
          </div>
          <div class="item" style="width: 270px">
            <span>Search keywords</span>
            <a-input v-model="params.keyword" />
          </div>
        </div>
        <div class="search-reset">
          <button @click="search">Search</button>
          <button @click="reset">Reset</button>
        </div>
      </div>
      <a-spin
        class="content-list"
        :class="{ empty: list.length == 0 }"
        :spinning="isLoading"
      >
        <a-empty v-if="list.length == 0 && !isLoading" />
        <button class="addone" @click="showAdd">Add Text/Email</button>
        <button
          class="batch-edit"
          @click="showEdit()"
          v-if="checkedList.length > 0"
        >
          Edit Selected
        </button>

        <div class="table" v-if="list.length > 0">
          <div class="thead">
            <div class="td">ID</div>
            <div class="td">Name</div>
            <div class="td">Preview</div>
            <div class="td">Start Date</div>
            <div class="td">End Date</div>
            <div class="td">Permission</div>
            <div class="td">Action</div>
          </div>
          <div class="tr" v-for="item in list" :key="item.id">
            <div class="td">
              <a-checkbox
                :class="{
                  Active: item.status == 'Active',
                  expired: item.status == 'Expired',
                  scheduled: item.status == 'Scheduled',
                }"
                :checked="checkedList.includes(item)"
                @change="checkHandle(item)"
              />
              <div class="id">{{ item.id }}</div>
            </div>
            <div class="td">
              <div>{{ item.title }}</div>
            </div>
            <div
              class="td"
              v-html="'<div>' + highlight(item.texts[0].text) + '</div>'"
            ></div>
            <div class="td">{{ item.start_time | formatMDY }}</div>
            <div class="td">{{ item.end_time | formatMDY }}</div>
            <div class="td">{{ item.privacy | ucfirst }}</div>
            <div class="td">
              <img
                src="@/assets/text-edit.svg"
                @click="showEdit(item)"
                alt
                class="text-edit-icon"
              />
              <img
                src="@/assets/text-view.svg"
                @click="showCopy(item)"
                alt
                class="text-view-icon"
              />
            </div>
          </div>
        </div>
        <div class="footer-pagination">
          <a-pagination
            v-model="currentPage"
            :total="totalSize"
            :pageSize="pageSize"
            show-less-items
            @change="paginationChange"
          />
        </div>
      </a-spin>
    </div>

    <!-- BatchEdit -->
    <Modal
      :isVisible="isShowBatchEdit"
      width="760px"
      submitText="Confirm"
      @close-popup="isShowBatchEdit = false"
      :showBtn="false"
      :title="
        modifyBatchStatus === 'Expired'
          ? ' Text/Email Expire'
          : 'Text/Email Edit'
      "
      class="text-batch-edit"
    >
      <template #content>
        <div class="left">
          <div
            class="preview"
            v-html="highlight(item.texts[0].text)"
            v-for="item in checkedList"
            :key="item.id"
          ></div>
        </div>

        <div class="right">
          <div class="title">Batch Edit Time</div>
          <div class="name">Start Time</div>
          <a-date-picker
            :disabled="modifyBatchStatus === 'Expired'"
            :show-time="{
              format: 'HH:mm',
              minuteStep: 5,
            }"
            format="L HH:mm"
            placeholder="Select Start Time"
            v-model="batchEditFormData.start_time"
          />
          <div class="name">End Time</div>
          <a-date-picker
            :disabled="modifyBatchStatus === 'Expired'"
            :show-time="{
              format: 'HH:mm',
              minuteStep: 5,
            }"
            format="L HH:mm"
            placeholder="Select End Time"
            v-model="batchEditFormData.end_time"
          />

          <div class="tips">
            ({{ $store.state.timezone.gmt.replace(" ", "") }}) Cook lslands
            Standard Time
          </div>
          <div class="options" v-if="modifyBatchStatus !== 'Expired'">
            <a-button class="delete" @click="isShowBatchAction = true">
              {{ modifyBatchStatus === "Active" ? "Expire" : "Delete" }}
            </a-button>
            <a-button class="update" @click="batchEdit">Update</a-button>
          </div>
        </div>
      </template>
    </Modal>

    <!-- BatchAction -->
    <Modal
      :isVisible="isShowBatchAction"
      width="528px"
      submitText="Confirm"
      @close-popup="isShowBatchAction = false"
      :title="modifyBatchStatus === 'Active' ? 'Expire' : 'Delete'"
      class="text-batch-delete"
      @submit="modifyBatchHandle()"
    >
      <template #content>
        <div class="text">
          Are you sure you want to
          {{ modifyBatchStatus === "Active" ? "expire" : "delete" }} this
          text/emails? <br />(After cliking “confirm,” this text/emails will be
          {{ modifyBatchStatus === "Active" ? "expired" : "deleted" }}
          permanently, and the publishers won’t be able to see them anymore.)
        </div>
      </template>
    </Modal>

    <!-- BatchAdd -->
    <Modal
      :isVisible="isShowAdd"
      width="760px"
      submitText="Confirm"
      @close-popup="isShowAdd = false"
      title="Add Text/Email"
      class="text-batch-add"
      :showBtn="false"
    >
      <template #content>
        <div class="left">
          <div class="name">Link PreView</div>
          <div class="preview" v-html="addFormData.preview"></div>
          <a-textarea
            placeholder
            :auto-size="{ minRows: 3, maxRows: 6 }"
            ref="add-textarea"
            v-model.trim="addFormData.content"
          />
          <button
            class="highlight"
            @click="highligh('add-textarea', addFormData)"
          >
            HighLight
          </button>
          <div class="size">
            To edit this link, type in the text that you would like displayed,
            along with the link address, and a name to reference the link.
            <br />
            <br />Select part of the phrase and click the button"Highlight" you
            can highlight the selected phrase, if you don't highlight part of
            the text, the entire phrase will be highlighted
          </div>
        </div>
        <div class="right">
          <div class="title">Infomation</div>
          <div class="name">Name</div>
          <a-input v-model.trim="addFormData.title"></a-input>
          <div class="name desc">
            Category
            <span>?</span>
            <span>
              <span>Feel free to name your own category.</span>
            </span>
          </div>
          <a-input v-model="addFormData.category"></a-input>
          <div class="name">Link</div>
          <a-input v-model.trim="addFormData.link"></a-input>
          <div class="name">Permission</div>

          <a-select placeholder="Select" v-model="addFormData.privacy">
            <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
            <a-select-option value="PUBLIC"
              >Public for all publishers</a-select-option
            >
            <a-select-option value="PRIVATE">Private</a-select-option>
          </a-select>

          <template v-if="addFormData.privacy == 'PRIVATE'">
            <div class="name">Publisher</div>

            <a-select
              mode="multiple"
              v-model="addFormData.site_ids"
              placeholder="Please select"
              :filter-option="
                (input, option) =>
                  option.componentOptions.children[0].text
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
              "
            >
              <a-select-option
                v-for="medium in mediumLists"
                :key="`${medium.title} (${medium.site_id})`"
                :value="medium.site_id"
                >{{ `${medium.title} (${medium.site_id})` }}</a-select-option
              >
            </a-select>
          </template>

          <div class="name">Start Time</div>
          <a-date-picker
            :show-time="{
              format: 'HH:mm',
              minuteStep: 5,
            }"
            format="L HH:mm"
            placeholder="Select Start Time"
            v-model="addFormData.start_time"
          />
          <div class="name">End Time</div>
          <a-date-picker
            :show-time="{
              format: 'HH:mm',
              minuteStep: 5,
            }"
            format="L HH:mm"
            placeholder="Select End Time"
            v-model="addFormData.end_time"
          />

          <div class="tips">
            ({{ $store.state.timezone.gmt.replace(" ", "") }}) Cook lslands
            Standard Time
          </div>
          <div class="options">
            <button class="add" @click="doSubmitAdd">Add</button>
          </div>
        </div>
      </template>
    </Modal>

    <!-- SingleEdit -->
    <Modal
      :isVisible="singleEditData != null"
      width="760px"
      submitText="Confirm"
      @close-popup="singleEditData = null"
      :title="
        singleModifyStatus === 'Expired'
          ? 'Expire Text/Email'
          : 'Edit Text/Email'
      "
      class="text-single-edit"
      :showBtn="false"
    >
      <template #content v-if="singleEditData">
        <div class="left">
          <div class="name">Link PreView</div>
          <div class="preview" v-html="singleEditData.preview"></div>
          <a-textarea
            v-if="singleEditData.status !== 'Expired'"
            placeholder
            :auto-size="{ minRows: 3, maxRows: 6 }"
            ref="add-textarea"
            v-model.trim="singleEditData.content"
          />
          <button
            v-if="singleEditData.status !== 'Expired'"
            class="highlight"
            @click="highligh('add-textarea', singleEditData)"
          >
            HighLight
          </button>
          <div class="size">
            To edit this link, type in the text that you would like displayed,
            along with the link address, and a name to reference the link.
            <br />
            <br />Select part of the phrase and click the button"Highlight" you
            can highlight the selected phrase, if you don't highlight part of
            the text, the entire phrase will be highlighted
          </div>
        </div>
        <div class="right">
          <div class="title">Infomation</div>
          <div class="name">Name</div>
          <a-input
            :disabled="singleEditData.status === 'Expired'"
            v-model.trim="singleEditData.title"
          ></a-input>
          <div class="name desc">
            Category
            <span>?</span>
            <span>
              <span>Feel free to name your own category.</span>
            </span>
          </div>
          <a-input
            :disabled="singleEditData.status === 'Expired'"
            v-model.trim="singleEditData.category"
          ></a-input>
          <div class="name">Link</div>
          <a-input
            :disabled="singleEditData.status === 'Expired'"
            v-model.trim="singleEditData.link"
          ></a-input>

          <div class="name">Permission</div>
          <a-select
            :disabled="singleEditData.status === 'Expired'"
            placeholder="Select"
            v-model="singleEditData.privacy"
          >
            <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
            <a-select-option value="PUBLIC"
              >Public for all publishers</a-select-option
            >
            <a-select-option value="PRIVATE">Private</a-select-option>
          </a-select>

          <template v-if="singleEditData.privacy == 'PRIVATE'">
            <div class="name">Publisher</div>

            <a-select
              :disabled="singleEditData.status === 'Expired'"
              mode="multiple"
              v-model="singleEditData.site_ids"
              placeholder="Please select"
            >
              <a-select-option
                v-for="medium in mediumLists"
                :key="`${medium.title} (${medium.site_id})`"
                :value="medium.site_id"
                >{{ `${medium.title} (${medium.site_id})` }}</a-select-option
              >
            </a-select>
          </template>

          <div class="name">Start Time</div>
          <a-date-picker
            :disabled="singleEditData.status === 'Expired'"
            :show-time="{
              format: 'HH:mm',
              minuteStep: 5,
            }"
            format="L HH:mm"
            placeholder="Select Start Time"
            v-model="singleEditData.start_time"
          />
          <div class="name">End Time</div>
          <a-date-picker
            :disabled="singleEditData.status === 'Expired'"
            :show-time="{
              format: 'HH:mm',
              minuteStep: 5,
            }"
            format="L HH:mm"
            placeholder="Select End Time"
            v-model="singleEditData.end_time"
          />

          <div class="tips">
            ({{ $store.state.timezone.gmt.replace(" ", "") }}) Cook islands
            Standard Time
          </div>
          <div class="options" v-if="singleEditData.status !== 'Expired'">
            <a-button class="delete" @click="isShowSingleHandle = true">
              {{ singleEditData.status === "Active" ? "Expire" : "Delete" }}
            </a-button>
            <a-button class="update" @click="doSingleEdit">Update</a-button>
          </div>
        </div>
      </template>
    </Modal>

    <!-- SingleDelete -->
    <Modal
      :isVisible="isShowSingleHandle"
      width="528px"
      submitText="Confirm"
      @close-popup="isShowSingleHandle = false"
      :title="singleModifyStatus === 'Active' ? 'Expire' : 'Delete'"
      class="text-batch-delete"
      @submit="doShowSingleHandle(singleEditData)"
    >
      <template #content>
        <div class="text" v-if="singleEditData">
          Are you sure you want to
          {{ singleModifyStatus === "Active" ? "expire" : "delete" }} this
          text/email? <br />(After cliking “confirm,” this text/email will be
          {{ singleEditData.status === "Scheduled" ? "deleted" : "expired" }}
          permanently, and the publishers won’t be able to see it anymore.)
        </div>
      </template>
    </Modal>

    <Loading v-if="isOpLoading"></Loading>
  </div>
</template>

<script>
import Modal from "../../components/Modal.vue";
import axios from "@/utils/request";
import moment from "moment-timezone";
import { useDebounceMessage } from "@/utils/hooks";
import { useModifyStatus } from "./utils";

export default {
  name: "creative_text",
  components: {
    Modal,
  },
  data() {
    return {
      isDisabled: !(moment() - this.end_time),
      isOpLoading: false,
      dateRange: [],
      list: [],
      params: {
        start_date: null,
        end_date: null,
        status: [],
        privacy: [],
        keyword: null,
        type: "TEXT",
      },
      singleModifyStatus: "",
      modifyBatchStatus: "",
      checkedList: [],
      currentPage: 1,
      pageSize: 20,
      totalSize: 1,
      isLoading: false,
      isShowBatchEdit: false,
      isShowBatchAction: false,
      isShowSingleHandle: false,
      isShowAdd: false,
      singleEditData: null,
      batchEditFormData: {
        start_time: null,
        end_time: null,
      },
      addFormData: {
        title: "",
        category: "",
        privacy: "PUBLIC",
        site_ids: [],
        start_time: moment(),
        end_time: moment().add(1, "M"),
        type: "TEXT",
        link: "",
        highligh: [],
        preview: null,
        content: "",
      },
      mediumLists: [],
    };
  },
  mounted() {
    this._getMediumLists();
    this.getData();
  },
  methods: {
    modifyBatchHandle() {
      if (this.modifyBatchStatus === "Active") {
        this.batchExpire(); // 过期
      } else {
        this.doBatchDelete(); // 删除
      }
    },
    doShowSingleHandle(row) {
      if (row.status === "Scheduled") {
        this.doBatchDelete(row.id);
      } else {
        this.singleEditData.end_time = moment();
        this.doSingleEdit();
      }
    },
    checkHandle(item) {
      const status = useModifyStatus(item);
      const checkedList = this.checkedList;
      if (checkedList.length > 0) {
        const currentStatus = useModifyStatus(checkedList[0]);
        if (status !== currentStatus)
          return useDebounceMessage.call(this, {
            message:
              "You can only select and edit creatives of the same status at the same time",
            type: "error",
          });
      }
      checkedList.includes(item)
        ? checkedList.splice(checkedList.indexOf(item), 1)
        : checkedList.push(item);
    },
    showAdd() {
      this.addFormData = {
        title: "",
        category: "",
        privacy: "PUBLIC",
        site_ids: [],
        start_time: moment(),
        end_time: moment().add(1, "M"),
        type: "TEXT",
        link: "",
        highligh: [],
        preview: null,
        content: "",
      };
      this.isShowAdd = true;
    },
    doBatchDelete(id) {
      let cids = [];
      if (id) {
        cids.push(id);
      } else {
        cids = this.checkedList.map((item) => item.id);
      }
      this.isOpLoading = true;
      axios
        .post("/api_admin.php?a=creative/batch_delete", {
          cids,
        })
        .then((res) => {
          this.isOpLoading = false;
          if (res.code === 0) {
            this.getData();
            this.closeBatchEditModal();
            this.$emit("refreshStats");
            this.$message.success("Delete Success");
          }
        })
        .catch((e) => {
          this.isOpLoading = false;
        });
    },
    async doSingleEdit() {
      // 检查表单
      if (this.singleEditData.link.length == 0) {
        this.$message.error("Please input Link");
        return;
      }
      if (this.singleEditData.content.length == 0) {
        this.$message.error("Please input Content");
        return;
      }
      if (
        this.singleEditData.privacy == "PRIVATE" &&
        (!this.singleEditData.site_ids ||
          this.singleEditData.site_ids.length == 0)
      ) {
        this.$message.error("Please select publishers on private mode");
        return;
      }
      if (!this.singleEditData.start_time || !this.singleEditData.end_time) {
        this.$message.error("Please select time");
        return;
      }
      if (
        this.singleEditData.start_time.unix() >=
        this.singleEditData.end_time.unix()
      ) {
        this.$message.error("Please check the time you select");
        return;
      }

      let data = {
        id: this.singleEditData.id,
        title: this.singleEditData.title,
        content: this.singleEditData.content,
        category: this.singleEditData.category,
        link: this.singleEditData.link,
        privacy: this.singleEditData.privacy,
        site_ids: this.singleEditData.site_ids,
        start_time: this.singleEditData.start_time.unix(),
        end_time: this.singleEditData.end_time.unix(),
      };
      this.isOpLoading = true;
      // 创建物料
      axios.post("/api_admin.php?a=creative/modify", data).then((res) => {
        this.isOpLoading = false;
        if (res.code === 0) {
          this.singleEditData = null;
          this.isShowBatchAction = false;
          this.isShowSingleHandle = false;
          this.$message.success("Edit Success");
          this.currentPage = 1;
          this.getData();
        } else {
          this.$message.error(res.msg);
        }
      });
      // 显示成功
    },
    showEdit(item) {
      if (item || this.checkedList.length == 1) {
        item = item || this.checkedList[0];
        this.singleModifyStatus = useModifyStatus(item);
        this.singleEditData = Object.assign({}, item, {
          start_time: moment.unix(item.start_time),
          end_time: moment.unix(item.end_time),
          site_ids: item.private_site_ids,
          preview: this.highlight(item.texts[0].text),
          content: item.texts[0].text,
          status: this.singleModifyStatus,
        });
      } else {
        this.isShowBatchEdit = true;
        this.modifyBatchStatus = useModifyStatus(this.checkedList[0]);
      }
    },
    highlight(content) {
      if (!content) {
        return "";
      } else if (content.includes("<url>") && content.includes("</url>")) {
        return content;
      } else {
        return "<url>" + content + "</url>";
      }
    },
    showCopy(item) {
      this.addFormData = {
        title: item.title,
        category: item.category,
        privacy: item.privacy,
        site_ids: item.private_site_ids,
        start_time: moment(),
        end_time: moment().add(1, "M"),
        type: "TEXT",
        link: item.link,
        highligh: [],
        preview: this.highlight(item.texts[0].text),
        content: item.texts[0].text,
      };
      this.isShowAdd = true;
    },
    async doSubmitAdd() {
      // 检查表单
      if (this.addFormData.link.length == 0) {
        this.$message.error("Please input Link");
        return;
      }
      if (this.addFormData.content.length == 0) {
        this.$message.error("Please input Content");
        return;
      }
      if (
        this.addFormData.privacy == "PRIVATE" &&
        (!this.addFormData.site_ids || this.addFormData.site_ids.length == 0)
      ) {
        this.$message.error("Please select publishers on private mode");
        return;
      }
      if (!this.addFormData.start_time || !this.addFormData.end_time) {
        this.$message.error("Please select time");
        return;
      }
      if (
        this.addFormData.start_time.unix() >= this.addFormData.end_time.unix()
      ) {
        this.$message.error("Please check the time you select");
        return;
      }
      this.isOpLoading = true;
      // 创建物料
      axios
        .post("/api_admin.php?a=creative/create", {
          ...this.addFormData,
          start_time: this.addFormData.start_time.unix(),
          end_time: this.addFormData.end_time.unix(),
        })
        .then((res) => {
          this.isOpLoading = false;
          if (res.code === 0) {
            this.$emit("refreshStats");
            this.closeAddModal();
            this.$message.success("Add Success");
            this.currentPage = 1;
            this.getData();
          } else {
            this.$message.error(res.msg);
          }
        });
      // 显示成功
    },
    closeAddModal() {
      this.isShowAdd = false;
      this.addFormData = {
        title: "",
        category: "",
        privacy: "PUBLIC",
        site_ids: [],
        start_time: moment(),
        end_time: moment().add(1, "M"),
        type: "TEXT",
        link: "",
        highligh: [],
        preview: null,
        content: "",
      };
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
    },
    highligh(refName, form) {
      let dom = this.$refs[refName].$el;
      if (dom.selectionEnd > dom.selectionStart) {
        form.highligh = [dom.selectionStart, dom.selectionEnd];
        form.content = [
          form.content.substr(0, form.highligh[0]),
          "<url>",
          form.content.substr(
            form.highligh[0],
            form.highligh[1] - form.highligh[0]
          ),
          "</url>",
          form.content.substr(form.highligh[1]),
        ].join("");
      } else {
        form.highligh = [];
      }
      form.preview = this.highlight(form.content);
    },
    _getMediumLists() {
      axios
        .get("/api_admin.php?a=medium/list", {
          params: {
            status: ["adopt"],
            simple: 1,
          },
        })
        .then((res) => {
          if (res.code === 0) {
            this.mediumLists = res.data.data;
          }
        });
    },
    handleChange(event) {
      let file = event.target.files[0];
      event.target.value = null;
      if (!file) {
        return;
      }
      if (file.size / 1024 / 1024 >= 1) {
        this.$message.error("Please select a picture with a size less than 1m");
        return;
      }
      let image = new Image();
      image.src = (
        window.createObjectURL ||
        window.URL?.createObjectURL ||
        window.webkitURL?.createObjectURL
      )(file);
      image.onload = () => {
        this.fileList.push({
          hash: new Date().getTime() + "_" + Math.random(),
          file: file,
          src: image.src,
          height: image.height,
          width: image.width,
        });
        this.$refs.temp.innerHTML = "";
      };
      this.$refs.temp.appendChild(image);
    },
    getData() {
      this.checkedList = [];
      this.isLoading = true;
      axios
        .get("/api_admin.php?a=creative/list", {
          params: {
            ...this.params,
            page_num: this.currentPage,
            page_size: this.pageSize,
          },
        })
        .then((res) => {
          this.isLoading = false;
          if (res.code === 0) {
            this.list = res.data.data;
            this.totalSize = parseInt(res.data.total_size);
          } else {
            this.$message.error(
              res.msg || "Sorry, server is busy. please try later."
            );
          }
        });
    },
    search() {
      this.getData();
    },
    paginationChange(page, pageSize) {
      this.currentPage = page;
      this.getData();
    },
    batchExpire() {
      let cids = this.checkedList.map((item) => item.id);
      this.isOpLoading = true;
      axios
        .post("/api_admin.php?a=creative/batch_expire", {
          cids,
        })
        .then((res) => {
          this.isOpLoading = false;
          if (res.code === 0) {
            this.$message.success("Batch Edit Success");
            this.closeBatchEditModal();
            this.getData();
          }
        })
        .catch((e) => {
          this.isOpLoading = false;
        });
    },
    batchEdit() {
      let cids = this.checkedList.map((item) => item.id);
      if (
        !this.batchEditFormData.start_time ||
        !this.batchEditFormData.end_time
      ) {
        this.$message.error("Please select time");
        return;
      }
      if (
        this.batchEditFormData.start_time.unix() >=
        this.batchEditFormData.end_time.unix()
      ) {
        this.$message.error("Please check the time you select");
        return;
      }
      this.isOpLoading = true;
      axios
        .post("/api_admin.php?a=creative/batch_modify", {
          cids,
          start_time: this.batchEditFormData.start_time.unix(),
          end_time: this.batchEditFormData.end_time.unix(),
        })
        .then((res) => {
          this.isOpLoading = false;
          if (res.code === 0) {
            this.$message.success("Batch Edit Success");
            this.closeBatchEditModal();
            this.getData();
          }
        })
        .catch((e) => {
          this.isOpLoading = false;
        });
    },
    closeBatchEditModal() {
      this.isShowBatchAction = false;
      this.isShowBatchEdit = false;
      this.isShowSingleDelete = false;
      this.singleEditData = null;
      this.batchEditFormData = {
        start_time: null,
        end_time: null,
      };
    },
    reset() {
      this.dateRange = [];
      this.params = {
        start_date: null,
        end_date: null,
        status: [],
        privacy: [],
        keyword: null,
        type: "TEXT",
      };
    },
    handleChangeDate(_, dates) {
      this.params.start_time = _[0]?.unix() || null;
      this.params.end_time = _[1] ? _[1].unix() + 86399 : null;
    },
    preview(content, link) {
      if (content.includes("<url>")) {
        return content
          .replace(
            /<url>/g,
            '<a href="' + encodeURIComponent(link) + '" target="_blank">'
          )
          .replace(/<\/url>/g, "</a>");
      } else {
        return (
          '<a href="' +
          encodeURIComponent(link) +
          '" target="_blank">' +
          content +
          "</a>"
        );
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  margin-bottom: 100px;
  .content-title {
    width: 100%;
    padding: 20px 44px 30px 30px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    .items {
      display: flex;
      .item {
        width: 262px;
        margin-right: 26px;
        &:last-child {
          margin-right: 0px;
        }
        span {
          display: block;
          font-size: 1rem;
          font-family: "dtc-bold";
          color: #333333;
          line-height: 30px;
          margin-bottom: 2px;
        }
        input {
          width: 100%;
          height: 2.8571rem;
          background: #ffffff;
          border-radius: 6px;
          border: 1px solid #e6e6e6;
        }
        .select {
          width: 100%;
          height: 2.8571rem;
          /deep/ .ant-select-selection--single {
            height: 2.8571rem;
            border-radius: 6px;
            background: #fafafa;
          }
          /deep/ .ant-select-selection__rendered {
            line-height: 2.8571rem;
          }
          .icon-down {
            color: #333333;
            font-size: 1rem;
          }
        }
        .date {
          width: 100%;
          height: 2.8571rem;
          /deep/ .ant-input {
            height: 2.8571rem;
            border-radius: 6px;
            background: #fafafa;
          }
        }
      }
    }
    .search-reset {
      margin-top: 20px;
      text-align: right;
      button {
        padding: 9px 32px;
        border-radius: 6px;
        cursor: pointer;
        border: none;
        &:first-child {
          background: #2898ff;
          font-size: 1.1429rem;
          color: #ffffff;
          line-height: 20px;
          margin-right: 20px;
        }
        &:last-child {
          border: 1px solid #dddddd;
          font-size: 1.1429rem;
          color: #333333;
          line-height: 20px;
        }
      }
    }
  }

  .content-list {
    width: 100%;
    padding: 80px 25px 20px 25px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    margin-top: 22px;
    position: relative;
    /deep/ .ant-spin-container {
      display: flex;
      flex-wrap: wrap;
      min-height: 300px;
      justify-content: flex-start;

      .table {
        border: 1px solid #eee;
        border-radius: 6px;
        width: 100%;
        box-sizing: border-box;
        height: max-content;
        .thead {
          background-color: #f9f9f9;
          height: 44px;
          box-sizing: border-box;
          width: 100%;
          display: flex;
          align-items: center;
          text-align: center;
          border-bottom: 1px solid #eee;
          border-radius: 6px 6px 0 0;
          .td {
            font-size: 1.1429rem;
            font-family: "dtc-bold";
            font-family: "prefer-bold";
            align-items: center !important;
          }
        }
        .tr {
          min-height: 44px;
          height: max-content;
          box-sizing: border-box;
          width: 100%;
          display: flex;
          align-items: stretch;
          text-align: center;
          border-bottom: 1px solid #eee;
          &:last-child {
            border-bottom: unset;
            min-height: 43px;
          }
          &:hover {
            background-color: #f2f9ff;
          }
        }
        .td {
          font-size: 1rem;
          display: flex;
          min-height: 43px;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          border-right: 1px solid #eee;
          position: relative;
          a,
          url {
            color: #2898ff;
            font-family: "dtc-bold";
            font-weight: 700;
          }
          .text-edit-icon,
          .text-view-icon {
            cursor: pointer;
          }
          &:nth-child(1) {
            width: 136px;
            align-items: stretch;
            .ant-checkbox-wrapper {
              width: 22px;
              text-align: center;
              position: relative;
              top: calc(50% - 11px);
              box-sizing: border-box;
              margin: 0 9px;
              height: 22px;
              // background-color: #2898ff;

              &.Active {
                .ant-checkbox {
                  .ant-checkbox-inner {
                    border-color: #19c42c !important;
                  }

                  &.ant-checkbox-checked {
                    .ant-checkbox-inner {
                      background-color: #19c42c !important;
                      border-color: #19c42c !important;
                    }
                    &::after {
                      border-color: #19c42c !important;
                    }
                  }
                }
                &:hover .ant-checkbox-inner {
                  border-color: #19c42c !important;
                }
                .ant-checkbox-input:focus + .ant-checkbox-inner {
                  border-color: #19c42c !important;
                }
              }
              &.scheduled {
                .ant-checkbox {
                  .ant-checkbox-inner {
                    border-color: #ffd163 !important;
                  }
                  &.ant-checkbox-checked {
                    .ant-checkbox-inner {
                      background-color: #ffd163 !important;
                      border-color: #ffd163 !important;
                    }
                    &::after {
                      border-color: #ffd163 !important;
                    }
                  }
                }
                &:hover .ant-checkbox-inner {
                  border-color: #ffd163 !important;
                }
                .ant-checkbox-input:focus + .ant-checkbox-inner {
                  border-color: #ffd163 !important;
                }
              }
              &.expired {
                .ant-checkbox {
                  .ant-checkbox-inner {
                    border-color: #999999 !important;
                  }
                  &.ant-checkbox-checked {
                    .ant-checkbox-inner {
                      background-color: #999999 !important;
                      border-color: #999999 !important;
                    }
                    &::after {
                      border-color: #999999 !important;
                    }
                  }
                }
                &:hover .ant-checkbox-inner {
                  border-color: #999999 !important;
                }
                .ant-checkbox-input:focus + .ant-checkbox-inner {
                  border-color: #999999 !important;
                }
              }
            }

            .id {
              width: 91px;
              display: flex;
              box-sizing: border-box;
              align-items: center;
              justify-content: center;
              border-left: 1px solid #eee;
            }
          }
          &:nth-child(2) {
            width: 166px;
            padding: 8px;
            div {
              height: max-content;
              width: 149px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
          }
          &:nth-child(3) {
            width: 466px;
            padding: 8px;
            div {
              height: max-content;
              width: 449px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
          }
          &:nth-child(4) {
            width: 100px;
          }
          &:nth-child(5) {
            width: 100px;
          }
          &:nth-child(6) {
            width: 100px;
          }
          &:nth-child(7) {
            width: 80px;
            padding: 0 8px;
            border-right: unset;
            justify-content: space-evenly;
          }
        }
      }
    }

    .addone {
      padding: 9px 32px;
      border-radius: 6px;
      cursor: pointer;
      border: none;
      background: #2898ff;
      font-size: 1.1429rem;
      color: #ffffff;
      line-height: 20px;
      position: absolute;
      top: -58px;
      left: 0px;
    }
    .batch-edit {
      padding: 8px 30px;
      border-radius: 6px;
      cursor: pointer;
      background: #fff;
      border: 1px solid #2898ff;
      font-size: 1.1429rem;
      color: #2898ff;
      line-height: 20px;
      position: absolute;
      top: -58px;
      right: 0px;
      box-sizing: border-box;
    }

    &.empty {
      /deep/ .ant-spin-container {
        justify-content: center;
        align-items: center;
      }
    }

    &::after {
      content: " ";
      clear: both;
    }

    .footer-pagination {
      margin-top: 22px;
      text-align: right;
      width: 100%;
    }
  }
}
.text-view {
  /deep/ .items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      height: 138px;
      width: 138px;
      object-fit: contain;
      margin-bottom: 8px;
    }
    .item-info {
      font-size: 1rem;
      color: #333333;
      line-height: 18px;
      text-align: center;
    }
  }
}

.text-batch-edit {
  /deep/ .items {
    .left {
      width: 340px;
      height: max-content;
      float: left;
      .name {
        height: 24px;
        font-size: 1.1429rem;
        font-family: "dtc-bold";
        color: #333333;
        line-height: 20px;
        margin-bottom: 12px;
      }
      .size {
        font-size: 0.8571rem;
        color: #999999;
        line-height: 20px;
        text-align: left;
        margin-top: 28px;
      }
      .preview {
        width: 340px;
        min-height: 90px;
        background: #f5f5f5;
        border-radius: 8px;
        border: 1px solid #eeeeee;
        box-sizing: border-box;
        margin-bottom: 20px;
        padding: 15px 15px;
        word-break: break-all;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        text-align: center;
        url {
          color: #2898ff;
          font-family: "dtc-bold";
        }
      }
    }
    .right {
      width: 300px;
      padding-right: 20px;
      box-sizing: border-box;
      float: right;
      .title {
        height: 24px;
        font-size: 1.2857rem;
        font-family: "prefer-bold";
        font-family: "dtc-bold";
        color: #333333;
        line-height: 23px;
        margin-bottom: 22px;
      }
      .name {
        height: 24px;
        font-size: 1.1429rem;
        font-family: "dtc-bold";
        color: #333333;
        line-height: 20px;
        margin-bottom: 4px;
        margin-top: 8px;
      }
      .ant-calendar-picker {
        width: 280px;
        margin-bottom: 12px;
      }
      .tips {
        height: 18px;
        font-size: 1rem;
        font-weight: normal;
        color: #2898ff;
        line-height: 18px;
        margin-bottom: 32px;
        margin-top: 6px;
      }
      .options {
        display: flex;
        justify-content: flex-start;
        .delete {
          width: 133px;
          height: 2.8571rem;
          font-size: 1.1429rem;
          line-height: 34px;
          box-sizing: border-box;
          vertical-align: middle;
          border-radius: 6px;
          margin-right: 14px;
        }
        .update {
          width: 133px;
          height: 2.8571rem;
          cursor: pointer;
          background: #2898ff;
          border: 1px solid #2898ff;
          vertical-align: middle;
          font-size: 1.1429rem;
          color: #fff;
          line-height: 34px;
          box-sizing: border-box;
          border-radius: 6px;
        }
      }
    }

    &::after {
      display: block;
      content: " ";
      clear: both;
    }
  }
}

.text-batch-delete {
  .text {
    width: 478px;
    height: 90px;
    font-size: 1.1429rem;
    color: #333333;
    line-height: 20px;
    text-align: center;
    margin-top: 10px;
  }
}
.text-batch-add {
  /deep/ .items {
    .left {
      width: 340px;
      height: max-content;
      float: left;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .preview {
        width: 340px;
        min-height: 90px;
        background: #f5f5f5;
        border-radius: 8px;
        border: 1px solid #eeeeee;
        box-sizing: border-box;
        margin-bottom: 20px;
        padding: 15px 15px;
        word-break: break-all;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        text-align: center;
        url {
          color: #2898ff;
          font-family: "dtc-bold";
        }
      }

      .name {
        height: 24px;
        font-size: 1.1429rem;
        font-family: "dtc-bold";
        color: #333333;
        line-height: 20px;
        margin-bottom: 12px;
      }
      .size {
        font-size: 0.8571rem;
        color: #999999;
        line-height: 20px;
        text-align: left;
        margin-top: 28px;
      }
      .highlight {
        width: 162px;
        height: 2.8571rem;
        margin-top: 20px;
        align-self: flex-end;
        cursor: pointer;
        background: #2898ff;
        border: 1px solid #2898ff;
        vertical-align: middle;
        font-size: 1.1429rem;
        color: #fff;
        line-height: 34px;
        box-sizing: border-box;
        border-radius: 6px;
        text-align: center;
      }
    }
    .right {
      width: 300px;
      padding-right: 20px;
      box-sizing: border-box;
      float: right;
      .title {
        height: 24px;
        font-size: 1.2857rem;
        font-family: "prefer-bold";
        font-family: "dtc-bold";
        color: #333333;
        line-height: 23px;
        margin-bottom: 22px;
      }
      .name {
        height: 24px;
        font-size: 1.1429rem;
        font-family: "dtc-bold";
        color: #333333;
        line-height: 20px;
        margin-bottom: 4px;
        margin-top: 8px;
        &.desc {
          cursor: default;
          position: relative;
          > span:first-child {
            display: inline-block;
            height: 20px;
            width: 20px;
            transform: scale(0.6);
            font-size: 1rem;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 400;
            background-color: #2898ff;
            border-radius: 999px;
            text-align: center;
            line-height: 20px;
            vertical-align: middle;
            color: #fff;
            &:hover + span > span {
              display: block;
            }
          }
          > span:last-child {
            position: relative;
            height: 0px;
            width: 0px;
            display: inline-block;
            span {
              font-family: dtc;
              color: #2898ff;
              background-color: rgba(62, 61, 64, 0.9);
              position: absolute;
              left: -2.8571rem;
              top: 13px;
              padding: 8px;
              width: 200px;
              box-sizing: border-box;
              border-radius: 4px;
              color: #fff;
              font-size: 0.8571rem;
              line-height: 15px;
              z-index: 1;
              font-weight: 400;
              height: max-content;
              display: none;

              &::before {
                display: block;
                content: " ";
                border: 5px solid rgba(62, 61, 64, 0.9);
                border-top-color: rgba(0, 0, 0, 0);
                border-right-color: rgba(0, 0, 0, 0);
                height: 10px;
                box-sizing: border-box;
                width: 10px;
                border-bottom-left-radius: 1px;
                position: absolute;
                top: -5px;
                left: 25px;
                transform: rotate(135deg);
              }
            }
          }
        }
      }
      .ant-calendar-picker {
        width: 280px;
        margin-bottom: 12px;
      }
      > .ant-input,
      .ant-select {
        width: 280px;
        margin-bottom: 12px;
      }
      .tips {
        height: 18px;
        font-size: 1rem;
        font-weight: normal;
        color: #2898ff;
        line-height: 18px;
        margin-bottom: 32px;
        margin-top: 6px;
      }
      .options {
        display: flex;
        justify-content: flex-end;
        .add {
          width: 133px;
          height: 2.8571rem;
          cursor: pointer;
          background: #2898ff;
          border: 1px solid #2898ff;
          vertical-align: middle;
          font-size: 1.1429rem;
          color: #fff;
          line-height: 34px;
          box-sizing: border-box;
          border-radius: 6px;
        }
      }
    }

    &::after {
      display: block;
      content: " ";
      clear: both;
    }
  }
}
.text-single-edit {
  /deep/ .items {
    .left {
      width: 340px;
      height: max-content;
      float: left;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .preview {
        width: 340px;
        min-height: 90px;
        background: #f5f5f5;
        border-radius: 8px;
        border: 1px solid #eeeeee;
        box-sizing: border-box;
        margin-bottom: 20px;
        padding: 15px 15px;
        word-break: break-all;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        text-align: center;
        url {
          color: #2898ff;
          font-family: "dtc-bold";
        }
      }

      .name {
        height: 24px;
        font-size: 1.1429rem;
        font-family: "dtc-bold";
        color: #333333;
        line-height: 20px;
        margin-bottom: 12px;
      }
      .size {
        font-size: 0.8571rem;
        color: #999999;
        line-height: 20px;
        text-align: left;
        margin-top: 28px;
      }
      .highlight {
        width: 162px;
        height: 2.8571rem;
        margin-top: 20px;
        align-self: flex-end;
        cursor: pointer;
        background: #2898ff;
        border: 1px solid #2898ff;
        vertical-align: middle;
        font-size: 1.1429rem;
        color: #fff;
        line-height: 34px;
        box-sizing: border-box;
        border-radius: 6px;
        text-align: center;
      }
    }
    .right {
      width: 300px;
      padding-right: 20px;
      box-sizing: border-box;
      float: right;
      .title {
        height: 24px;
        font-size: 1.2857rem;
        font-family: "prefer-bold";
        font-family: "dtc-bold";
        color: #333333;
        line-height: 23px;
        margin-bottom: 22px;
      }
      .name {
        height: 24px;
        font-size: 1.1429rem;
        font-family: "dtc-bold";
        color: #333333;
        line-height: 20px;
        margin-bottom: 4px;
        margin-top: 8px;
        &.desc {
          cursor: default;
          position: relative;
          > span:first-child {
            display: inline-block;
            height: 20px;
            width: 20px;
            transform: scale(0.6);
            font-size: 1rem;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 400;
            background-color: #2898ff;
            border-radius: 999px;
            text-align: center;
            line-height: 20px;
            vertical-align: middle;
            color: #fff;
            &:hover + span > span {
              display: block;
            }
          }
          > span:last-child {
            position: relative;
            height: 0px;
            width: 0px;
            display: inline-block;
            span {
              font-family: dtc;
              color: #2898ff;
              background-color: rgba(62, 61, 64, 0.9);
              position: absolute;
              left: -2.8571rem;
              top: 13px;
              padding: 8px;
              width: 200px;
              box-sizing: border-box;
              border-radius: 4px;
              color: #fff;
              font-size: 0.8571rem;
              line-height: 15px;
              z-index: 1;
              font-weight: 400;
              height: max-content;
              display: none;

              &::before {
                display: block;
                content: " ";
                border: 5px solid rgba(62, 61, 64, 0.9);
                border-top-color: rgba(0, 0, 0, 0);
                border-right-color: rgba(0, 0, 0, 0);
                height: 10px;
                box-sizing: border-box;
                width: 10px;
                border-bottom-left-radius: 1px;
                position: absolute;
                top: -5px;
                left: 25px;
                transform: rotate(135deg);
              }
            }
          }
        }
      }
      .ant-calendar-picker {
        width: 280px;
        margin-bottom: 12px;
      }
      > .ant-input,
      .ant-select {
        width: 280px;
        margin-bottom: 12px;
      }
      .tips {
        height: 18px;
        font-size: 1rem;
        font-weight: normal;
        color: #2898ff;
        line-height: 18px;
        margin-bottom: 32px;
        margin-top: 6px;
      }
      .options {
        display: flex;
        justify-content: flex-start;
        .delete {
          width: 133px;
          height: 2.8571rem;
          font-size: 1.1429rem;
          line-height: 34px;
          box-sizing: border-box;
          vertical-align: middle;
          border-radius: 6px;
          margin-right: 14px;
        }
        .update {
          width: 133px;
          height: 2.8571rem;
          cursor: pointer;
          background: #2898ff;
          border: 1px solid #2898ff;
          vertical-align: middle;
          font-size: 1.1429rem;
          color: #fff;
          line-height: 34px;
          box-sizing: border-box;
          border-radius: 6px;
        }
      }
    }

    &::after {
      display: block;
      content: " ";
      clear: both;
    }
  }
}
</style>
