<template>
  <a-spin
    class="content-block detail-info"
    :class="{ empty: false }"
    :spinning="false"
    style="height:268px"
  >
    <a-skeleton active v-if="!medium" />
    <template v-else>
      <img :src="medium.logo" alt class="publisher-logo" />
      <div class="infos-1">
        <div class="infos-aff-name font-bold">
          {{ medium.title || medium.nickname }}
        </div>
        <div>
          <a-popover arrowPointAtCenter>
            <template slot="content">
              {{ medium.host }}
            </template>
            <a :href="medium.host">{{ medium.host }}</a>
          </a-popover>
          <img :src="medium.flag" alt :class="{ hide: !medium.flag }" />
          {{ medium.country_code }}
        </div>
        <div>Publisher ID: {{ medium.medium_id }}</div>
        <div>
          Category:
          <span class="color-blue">{{ medium.category }}</span>
        </div>
        <div>Application Date: {{ medium.apply_created_time | formatMDY }}</div>
      </div>
      <div class="infos-2">
        <div>Publisher Propvided Description:</div>
        <div>{{ medium.medium_content }}</div>
      </div>
      <div class="infos-3">
        <a-spin :spinning="isLoading">
          <div class="font-bold">Commissions:</div>
          <div>
            <span>
              <span class="font-bold">Total:</span>
              {{ stats.commission_total | toThousands }}
            </span>
            <span>
              <span class="font-bold">30 Day:</span>
              {{ stats.commission_30day | toThousands }}
            </span>
          </div>
          <div class="font-bold">Clicks:</div>
          <div>
            <span>
              <span class="font-bold">Total:</span>
              {{ stats.click_total | toThousands(true) }}
            </span>
            <span>
              <span class="font-bold">30 Day:</span>
              {{ stats.click_30day | toThousands(true) }}
            </span>
          </div>
        </a-spin>
      </div>
      <div class="infos-4">
        <a-select
          class="select"
          placeholder="Select"
          size="large"
          v-model="newStatus"
          v-if="medium.apply_status"
          @change="handleNewStatusChange"
        >
          <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
          <a-select-option value="Approved">Approved</a-select-option>
          <a-select-option
            value="Declined"
            v-if="medium.apply_status != 'terminated'"
            >Declined</a-select-option
          >
          <a-select-option
            value="Pending"
            v-if="medium.apply_status == 'verifing'"
            >Pending</a-select-option
          >
          <a-select-option value="Removed" v-if="medium.apply_status == 'adopt'"
            >Removed</a-select-option
          >
        </a-select>
        <img
          src="@/assets/medium-msg.svg"
          alt
          title="Send message"
          @click="
            $emit('openChat');
            $store.commit('setChatMediumId', medium.medium_id);
          "
        />
        <img
          src="@/assets/give-bonus.svg"
          @click="handleGiveBounus"
          alt
          title="Give a bonus"
          v-if="isApplied"
        />
        <a-button
          size="large"
          type="primary"
          ghost
          v-if="isApplied"
          @click="goTransactionList"
          >&emsp;View Transaction&emsp;</a-button
        >
      </div>
    </template>

    <Modal
      :isVisible="showGiveBonusInfo != null"
      title="Give A Bonus"
      :showBtn="false"
      @close-popup="showGiveBonusInfo = null"
      width="368px"
    >
      <template #content>
        <div class="give-bonus-content">
          <div class="item">
            <h4>Bonus Amount</h4>
            <div class="input-row">
              <input
                type="text"
                class="select-input"
                v-model="showGiveBonusInfo.bonusAmount"
              />
              <a-select
                class="select"
                v-model="showGiveBonusInfo.bonusCurrency"
              >
                <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
                <a-select-option
                  :value="currency.simple_name"
                  v-for="currency in $store.state.commonConfig.currencies.filter(
                    (c) => c.simple_name != 'MIX'
                  )"
                  :key="currency.simple_name"
                  >{{ currency.simple_name }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="item">
            <h4>Comment</h4>
            <input
              type="text"
              v-model.trim="showGiveBonusInfo.bonusRemark"
              placeholder="Required"
            />
          </div>
        </div>
        <div style="display: flex; justify-content: center;">
          <a-button
            type="primary"
            style="font-size:16px"
            class="add"
            @click="submitBonus"
            >Submit</a-button
          >
        </div>
      </template>
    </Modal>

    <Modal
      :isVisible="isShowUpdate"
      title="Mass Action Confirmation"
      @close-popup="isShowUpdate = false"
      width="365px"
      class="batch-update-modal"
      cancelText="Cancel"
      isShowCancel
      @submit="batchEdit"
      submitText="Submit"
    >
      <template #content>
        <div class="update-info">
          <div class="color-primary font-bold">1 Publisher</div>
          <div>{{ medium.title }} - {{ medium.medium_id }}</div>

          <div class="color-primary font-bold">Status Change</div>
          <div>Set status to: {{ newStatus }}</div>
        </div>
      </template>
    </Modal>
    <Loading v-if="isLoadingModal"></Loading>
  </a-spin>
</template>

<script>
import axios from "@/utils/request";
import Modal from "../../../components/Modal.vue";

export default {
  components: { Modal },
  props: ["medium"],
  watch: {
    medium: {
      immediate: true,
      handler: function(newV, oldV) {
        if (newV) {
          console.log(newV);
          this.newStatus = {
            adopt: "Approved",
            refuse: "Declined",
            verifing: "Pending",
            terminated: "Removed",
          }[newV.apply_status];
          if (newV.apply_status == "adopt") {
            this.isApplied = true;
            this.getData();
          }
        }
      },
    },
    isShowUpdate(newV, oldV) {
      if (!newV) {
        this.newStatus = {
          adopt: "Approved",
          refuse: "Declined",
          verifing: "Pending",
          terminated: "Removed",
        }[this.medium.apply_status];
      }
    },
  },
  data() {
    return {
      stats: {
        commission_total: 0,
        commission_30day: 0,
        click_total: 0,
        click_30day: 0,
      },
      isApplied: false,
      isLoading: false,
      isLoadingModal: false,
      showGiveBonusInfo: null,
      isShowUpdate: false,
      newStatus: null,
    };
  },
  methods: {
    batchEdit() {
      let newStatus = {
        Approved: "adopt",
        Declined: "refuse",
        Pending: "verifing",
        Removed: "terminated",
      }[this.newStatus];
      this.isLoadingModal = true;
      axios
        .post("/api_admin.php?a=medium/edit", {
          site_ids: this.medium.site_id,
          status: newStatus,
        })
        .then((res) => {
          this.isLoadingModal = false;
          this.isShowUpdate = false;
          this.$nextTick(() => {
            this.newStatus = "";
            if (res.code === 0) {
              this.$emit("statusChange", newStatus);
            }
          });
        })
        .catch((e) => {
          this.isLoadingModal = false;
        });
    },
    handleNewStatusChange(val) {
      let oldStatus = {
        adopt: "Approved",
        refuse: "Declined",
        verifing: "Pending",
        terminated: "Removed",
      }[this.medium.status];
      this.isShowUpdate = val != oldStatus;
    },
    goTransactionList() {
      this.$router.push({
        name: "report_orders",
        params: {
          medium_id: this.medium.medium_id,
        },
      });
    },
    getData() {
      if (!this.isApplied) {
        return;
      }
      this.isLoading = true;
      axios
        .get("api_admin.php?a=report/applied_aff_stat", {
          params: {
            stat: "basic",
            medium_id: this.medium.medium_id,
          },
        })
        .then((res) => {
          this.isLoading = false;
          if (res.code === 0) {
            this.stats.click_30day = parseInt(res.data.clicks);
            this.stats.click_total = parseInt(res.data.clicks_total);
            this.stats.commission_30day = parseFloat(res.data.commission);
            this.stats.commission_total = parseFloat(res.data.commission_total);
          }
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    handleGiveBounus() {
      this.showGiveBonusInfo = Object.assign({}, this.medium, {
        bonusAmount: null,
        bonusRemark: "",
        bonusCurrency: "USD",
      });
    },
    submitBonus() {
      let amount = parseFloat(this.showGiveBonusInfo.bonusAmount);
      if (isNaN(amount) || amount == 0) {
        this.$message.error("Please input valid number");
        return;
      }
      if (this.showGiveBonusInfo.bonusRemark.length == 0) {
        this.$message.error("Please input comment");
        return;
      }
      this.isLoadingModal = true;
      axios
        .post("/api_admin.php?a=medium/giveBonus", {
          medium_id: this.showGiveBonusInfo.medium_id,
          amount,
          remark: this.showGiveBonusInfo.bonusRemark,
          currency: this.showGiveBonusInfo.bonusCurrency,
        })
        .then((res) => {
          this.isLoadingModal = false;
          if (res.code === 0) {
            this.showGiveBonusInfo = null;
            this.$message.success("Give Bonus Success");
          } else {
            this.$message.error("Give Bonus Fail, " + res.message);
          }
        })
        .catch((e) => {
          console.log(e);
          this.isLoadingModal = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.detail-info {
  /deep/ .ant-spin-container {
    display: flex;
    flex-wrap: wrap;

    > img:first-child {
      width: 253px;
      height: 8.5714rem;
      border-radius: 6px;
      margin-right: 24px;
      margin-top: 18px;
      object-fit: contain;
      box-sizing: border-box;
      border: 1px solid #eee;
    }

    .infos-1 {
      height: 152px;
      width: 224px;
      margin-right: 24px;
      overflow: hidden;
      font-size: 0.9286rem;

      > * {
        margin-bottom: 9px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .infos-aff-name {
        width: 151px;
        height: 25px;
        font-size: 1.4286rem;
        color: #2898ff;
        line-height: 25px;
        text-transform: capitalize;
      }

      > div:nth-child(2) {
        display: flex;
        align-items: center;

        a {
          color: #3c3c3c;
          text-decoration: underline;
          display: inline-block;
          max-width: 185px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        img {
          height: 16px;
          width: 16px;
          margin-left: 10px;
          margin-right: 5px;

          &.hide {
            opacity: 0;
          }
        }
      }
    }

    .infos-2 {
      height: 108px;
      width: 337px;
      margin-right: 24px;
      overflow: hidden;
      margin-top: 34px;

      > div:first-child {
        padding-bottom: 10px;
      }
    }

    .infos-3 {
      width: 272px;
      height: 152px;
      background: #f2f9ff;
      border-radius: 6px;
      box-sizing: border-box;
      padding: 18px 16px;
      font-size: 1.1429rem;

      .ant-spin-container {
        display: block;

        > div {
          height: 24px;
          width: 100%;
          margin-bottom: 8px;

          > span {
            > span {
              padding-right: 3px;
            }

            &:first-child {
              display: inline-block;
              width: 50%;
            }
          }

          &:nth-child(2) {
            margin-bottom: 6px;
          }

          &:last-child {
            margin-bottom: 0;
          }

          &:nth-child(2),
          &:nth-child(4) {
            font-size: 0.9286rem;
          }
        }
      }
    }

    .infos-4 {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin-top: 22px;
      align-items: center;

      > * {
        margin-right: 22px;
      }

      > button:last-child {
        margin-left: auto;
        margin-right: 0;
      }

      > .ant-select {
        width: 168px;
      }

      > img {
        cursor: pointer;
        height: 18px;

        &:nth-of-type(1) {
          height: 24px;
        }
      }
    }
  }
}

.give-bonus-content {
  width: 308px;

  .item {
    margin-bottom: 12px;

    h4 {
      margin-bottom: 6px;
      font-size: 1.1429rem;
      font-family: "dtc-bold";
      color: #333333;
      line-height: 30px;
    }

    .input-row {
      display: flex;
      height: 2.8571rem;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    .select {
      width: 8.5714rem;
      height: 2.8571rem;
      margin-left: 10px;
      display: inline-block;

      /deep/ .ant-select-selection--single {
        height: 2.8571rem;
        border-radius: 6px;
        background: #fafafa;
      }

      /deep/ .ant-select-selection__rendered {
        line-height: 2.8571rem;
      }

      .icon-down {
        color: #333333;
        font-size: 1rem;
      }
    }

    .select-input {
      width: 178px;
      height: 2.8571rem;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #e6e6e6;
      box-sizing: border-box;
      outline: none;
      padding-left: 10px;
    }

    input {
      width: 100%;
      height: 2.8571rem;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #e6e6e6;
      box-sizing: border-box;
      outline: none;
      padding-left: 10px;
    }
  }

  & + div .add {
    margin-top: 35px;
    width: 102px;
    height: 2.8571rem;
    background: #2898ff;
    border-radius: 6px;
    outline: none;
    cursor: pointer;
    border: none;
    font-size: 1.1429rem;
    color: #ffffff;
    line-height: 18px;
  }
}

.batch-update-modal {
  /deep/ .update-info {
    padding-bottom: 20px;

    > div {
      + div {
        margin-top: 4px;
      }

      font-size: 1rem;

      &.font-bold {
        font-size: 1.1429rem;
        margin-top: 20px;
      }

      &:first-child {
        margin-top: 0 !important;
      }
    }
  }
}
</style>
