<template>
  <a-spin class="content-block" :class="{ empty: false }" :spinning="isLoading">
    <div class="table">
      <div class="thead font-bold">
        <div class="td" style="width:70px">ID</div>
        <div class="td" style="width:70px">Type</div>
        <div class="td" style="width:70px">Status</div>
        <div class="td" style="width:200px">Commission Name</div>
        <div class="td" style="width:8.5714rem">Rate/Amount</div>
        <div class="td" style="width:260px">Date Range</div>
        <div class="td" style="width:150px">Description</div>
        <div class="td" style="width:140px">Create Time</div>
        <div class="td" style="width:76px"></div>
      </div>
      <div class="tbody">
        <a-empty v-if="list.length == 0 && !isLoading" />
        <div
          class="tr"
          v-for="(item, idx) in list"
          :key="item.id"
          v-else-if="!isLoading"
        >
          <div class="td" style="width:70px">{{ item.id }}</div>
          <div class="td" style="width:70px">{{ item.norm_type }}</div>
          <div class="td" style="width:70px">
            {{ item.process_status | filterStaus }}
          </div>
          <div class="td" style="width:200px">{{ item.norm_name }}</div>
          <div class="td" style="width:8.5714rem">
            {{ item.aff_ba }}{{ item.aff_ba_unit }}
          </div>
          <div class="td" style="width:260px">
            {{ item.startdate_stamp | formatMDYHI }} ~
            {{ item.enddate_stamp | formatMDYHI }}
          </div>
          <div class="td" style="width:150px">{{ item.norm_desc }}</div>
          <div class="td" style="width:140px">
            {{ item.created_time_stamp | formatMDYHI }}
          </div>
          <div class="td" style="width:76px">
            <template v-if="item.process_status != 'overdue'">
              <img
                v-if="item.process_status != 'running'"
                src="@/assets/edit.svg"
                style="width: 16px;height: 16px;cursor: pointer;margin-right: 10px;"
                @click="
                  editIdx = idx;
                  editId = item.id;
                  editVal = item.aff_ba;
                  editType = item.aff_ba_unit;
                "
              />
              <img
                src="@/assets/locked.svg"
                style="width: 16px;height: 16px;cursor: pointer;"
                @click="delId = item.id"
              />
            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- edit -->
    <Modal
      :isVisible="!!editId"
      title="Commission Rule Edit"
      @close-popup="editId = null"
      width="350px"
      submitText="Confirm"
      cancelText="Cancel"
      :isShowCancel="true"
      @submit="editSubmit"
    >
      <template #content>
        <div class="item-add">
          <span>Commission Rate:</span>
          <a-input type="text" v-model="editVal" />
          {{ editType }}
        </div>
      </template>
    </Modal>

    <!-- delete -->
    <Modal
      title="Remove Private Publisher"
      :isVisible="!!delId"
      @close-popup="delId = null"
      width="365px"
      submitText="Confirm"
      cancelText="Cancel"
      :isShowCancel="true"
      @submit="deleteSubmit"
    >
      <template #content>
        <p style="font-size: 1.2857rem;color: #333333;line-height: 30px;">
          Are you sure you want to remove this private publisher from this
          commission rule?
        </p>
      </template>
    </Modal>
  </a-spin>
</template>

<script>
import Modal from "../../../components/Modal.vue";
import axios from "@/utils/request";

export default {
  components: { Modal },
  props: ["medium"],
  watch: {
    medium: {
      immediate: true,
      handler: function(newV, oldV) {
        if (newV && newV.apply_status == "adopt") {
          this.isApplied = true;
          this.getData();
        }
      },
    },
  },
  data() {
    return {
      list: [],
      isLoading: false,
      isApplied: false,
      editId: false,
      editVal: 0,
      editIdx: -1,
      editType: "%",
      delId: null,
    };
  },
  methods: {
    deleteSubmit() {
      axios
        .post("/api_admin.php?a=commissionRule/deleteAffiliateRule", {
          id: this.delId,
        })
        .then((res) => {
          if (res.code === 0) {
            this.list = this.list.filter((item) => item.id != this.delId);
            this.delId = null;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    editSubmit() {
      let val = parseFloat(this.editVal);
      if (isNaN(val) || val < 0) {
        this.$message.error("Commission should be a number greater than 0");
        return;
      }

      axios
        .post("/api_admin.php?a=commissionRule/private_edit_comm", {
          id: this.editId,
          value: val,
        })
        .then((res) => {
          if (res.code === 0) {
            this.$set(this.list, this.editIdx, {
              ...this.list[this.editIdx],
              aff_ba: val,
            });
            this.editId = null;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getData() {
      if (!this.isApplied) {
        return;
      }
      this.isLoading = true;
      axios
        .get("/api_admin.php?a=commissionRule/privates", {
          params: {
            site_id: this.medium.site_id,
          },
        })
        .then((res) => {
          this.isLoading = false;
          if (res.code != 0 || !res.data) {
            return;
          }
          let list = res.data.map((item) => ({
            ...item,
            norm_type: item.norm_type == "special" ? "Special" : "Default",
            startdate_stamp_moment: moment.unix(item.startdate_stamp),
            enddate_stamp_moment: moment.unix(item.enddate_stamp),
            norm_start_time_stamp_moment: moment.unix(
              item.norm_start_time_stamp
            ),
            norm_end_time_stamp_moment: moment.unix(item.norm_end_time_stamp),
          }));
          list.sort((a, b) => {
            if (a.type != b.type) {
              return a.type == "default" ? -1 : 1;
            }
            let statusSort = {
              running: 1,
              to_run: 2,
              pending: 3,
              overdue: 4,
            };
            return statusSort[a.process_status] - statusSort[b.process_status];
          });
          this.list = list;
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
    },
  },
  filters: {
    filterStaus(item) {
      return {
        pending: "Pending",
        running: "Active",
        overdue: "Expired",
        to_run: "Scheduled",
      }[item];
    },
    filterType(item) {
      if (item == "normal") {
        return "Default";
      }
      return item[0].toUpperCase() + item.substr(1);
    },
  },
};
</script>

<style lang="less" scoped>
.toggle {
  width: 167px;
  height: 32px;
  border-radius: 6px;
  float: left;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  left: 0;
  top: 65px;
  z-index: 1;
  div {
    width: 50px;
    height: 32px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.active {
      width: 80px;
      cursor: default;
      background: #ffffff;
      box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
    }
  }
}
.tbody .td {
  border-right: 1px solid #eee;
  &:last-child {
    border: none;
  }
}

.item-add {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  span {
    width: 100%;
    margin-bottom: 10px;
  }
  input {
    width: 200px;
    margin-right: 10px;
  }
}
</style>
