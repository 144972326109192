<template>
  <div class="details-wrap">
    <a-menu class="ant-menu-inline menu-inline" :selected-keys="[menuChecked]" mode="inline" theme="light">
      <a-menu-item v-for="item in menuList" :key="item.router">
        <img class="menu-icon menu-icon-blur" :src="require('@/assets/account/' + item.icon)" alt="">
        <img class="menu-icon menu-icon-focus" :src="require('@/assets/account/' + item.checkedIcon)" alt="">
        <router-link :to="{ name: item.router }">{{ item.name }}</router-link>
      </a-menu-item>
    </a-menu>

    <div class="child-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Brands from './../../components/Brands.vue'
import ChoosePopup from '@/components/ChoosePopup.vue'
import Modal from './../../components/Modal.vue'
import inspectUserPermissions from '@/utils/permissionsJudge'

export default {
  name: 'account-details',
  components: {
    Brands,
    ChoosePopup,
    Modal
  },
  data() {
    return {
      menuList: [
        {
          name: 'Account Details',
          icon: 'account_details.png',
          checkedIcon: 'account_details_checked.svg',
          router: 'account-edit',
        },
        {
          name: 'Program Settings',
          icon: 'program_settings.png',
          checkedIcon: 'program_settings_checked.svg',
          router: 'program-settings',
        },
        {
          name: 'Payment Methods',
          icon: 'payment_methods.png',
          checkedIcon: 'payment_methods_checked.svg',
          router: 'payment-methods',
        },
        {
          name: 'Role Management',
          icon: 'role_management.png',
          checkedIcon: 'role_management_checked.svg',
          router: 'sub-account',
        },
        {
          name: 'Email Settings',
          icon: 'email_settings.png',
          checkedIcon: 'email_settings_checked.svg',
          router: 'email-setting',
        },
        {
          name: 'Login Info',
          icon: 'login_info.svg',
          checkedIcon: 'login_info_checked.svg',
          router: 'login-info',
        },
      ],
    }
  },
  computed: {
    menuChecked() {
      return this.$route.name
    },
    guide() {
      return this.$route.params.guide || 0
    }
  },
  mounted() {
    if (this.guide == 1) {
      this.$store.commit('setGuide', 1)
    }

    let authorityJudgment = this.$store.state.authorityJudgment
    this.hasUpdateBrandInfo = inspectUserPermissions('user/updateMerchantInfo', authorityJudgment)
  },
  methods: {

  }
}

</script>

<style lang="less" scoped>
.details-wrap {
  margin-top: 22px;
  display: flex;

  .ant-menu-vertical {
    border: none !important;
  }

  .ant-menu-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px !important;
  }

  .ant-menu-item-selected {
    overflow: inherit !important;
    border-radius: 6px;
    font-weight: 600;
  }

  .ant-menu-item::after {
    right: -22px;
    border-right-width: 5px;
    border-right-color: #2898FF;
    border-radius: 2px 0px 0px 2px;
  }


  .child-content {
    width: 100%;
  }

  .menu-inline {
    width: 230px;
    height: 650px;
    padding: 22px;
    border-radius: 6px;
    border-right: none;
    margin-right: 22px;


    .menu-icon {
      width: 20px;
      height: 20px;
      margin-right: 10px;

      &-focus {
        display: none;
      }
    }
  }

  .ant-menu-item-active,
  .ant-menu-item-selected {
    .menu-icon {
      &-focus {
        display: block;
        color: #fff;
      }

      &-blur {
        display: none;
      }
    }
  }
}
</style>