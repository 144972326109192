<template>
  <ul class="campprev" v-if="item">
    <li v-if="!isOver" class="campprev__item">
      <span class="campprev__item__title">Compensation Methods:</span>
      <div class="campprev__item__content">
        <ul class="campprev__item__content__list">
          <li
            v-if="item.pay_per_content_amount_upper > 0"
            class="campprev__item__content__item"
          >
            <span>Pay per content</span>
            <span
              >{{ item.currency_sign }}&nbsp;{{
                item.pay_per_content_amount_lower
              }}</span
            >
            &nbsp;-&nbsp;
            <span
              >{{ item.currency_sign }}&nbsp;{{
                item.pay_per_content_amount_upper
              }}</span
            >
          </li>
          <li
            v-if="item.free_product_enable > 0"
            class="campprev__item__content__item"
          >
            <span>Free Product</span>
          </li>
          <li
            v-if="item.gift_card_amount > 0"
            class="campprev__item__content__item"
          >
            <span>Gift Cards</span>
            <span
              >{{ item.currency_sign }}&nbsp;{{ item.gift_card_amount }}</span
            >
          </li>
        </ul>
      </div>
    </li>
    <li v-else class="campprev__oitem">
      <ul class="campprev__oitem__list">
        <li
          v-if="item.pay_per_content_amount_upper > 0"
          class="campprev__oitem__item"
        >
          <span class="campprev__oitem__title">Pay per content</span>
          <span
            >{{ item.currency_sign }}&nbsp;{{
              item.pay_per_content_amount_lower + ""
            }}
            &nbsp;-&nbsp; {{ item.currency_sign }}&nbsp;{{
              item.pay_per_content_amount_upper + ""
            }}</span
          >
        </li>
        <li v-if="item.free_product_enable > 0" class="campprev__oitem__item">
          <span class="campprev__oitem__title">Free Product</span>
        </li>
        <li v-if="item.gift_card_amount > 0" class="campprev__oitem__item">
          <span class="campprev__oitem__title">Gift Cards</span>
          <span
            >{{ item.currency_sign }}&nbsp;{{
              item.gift_card_amount + ""
            }}</span
          >
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => null,
    },
    isOver: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {},
  watch: {},
};
</script>

<style lang="less" scoped>
.campprev {
  margin-top: 16px;
  &__item {
    display: flex;
    margin-top: 20px;
    &__title {
      width: 200px;
      color: #999999;
      .red {
        color: red;
      }
    }
    &__content {
      width: calc(100% - 200px);
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      &__sm {
        font-size: 0.8571rem;
        color: #333333;
        font-weight: lighter;
        margin-right: 10px;
      }
      &__normal {
        margin-right: 10px;
      }
      &__bg {
        margin-right: 10px;
        font-weight: 600;
      }
      &__list {
      }
      &__item {
        width: 240px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
      }
    }
    &__platform {
      margin-right: 20px;
      display: flex;
      align-items: center;
      img {
        width: 18px;
        margin-right: 6px;
      }
    }
  }
  &__oitem {
    &__list {
    }
    &__item {
      margin-top: 20px;
      display: flex;
    }
    &__title {
      width: 200px;
      color: #999999;
    }
  }
}
</style>
