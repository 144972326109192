<template>
  <span class="detail-paytype" ref="wrap">
    <span
      v-for="(ptype, index) in result.shows"
      :key="`ptype-list-${index}`"
      class="paytype-tags"
      :class="`${ptype.name}`"
      >{{ ptype.text }}</span
    >
    <span class="paytype-tags more" ref="more" v-if="!result.isEq"
      >+{{ hideList.length }}
      <span
        class="paytype-mores"
        :class="[right < 200 ? '_right' : '']"
        :style="{
          width: hideList.length > 2 ? '14.2857rem' : '9.8rem',
        }"
      >
        <span
          v-for="(ptype, index) in hideList"
          :key="`ptype-hides-${index}`"
          class="paytype-tags mb8"
          :class="`${ptype.name}`"
          >{{ ptype.text }}</span
        >
      </span>
    </span>
  </span>
</template>
<script>
import _ from "lodash";

export default {
  props: {
    tableType: {
      type: String,
      default: "grid",
    },
    item: {
      type: Object,
      default: () => {
        s;
      },
    },
  },
  computed: {
    hideList() {
      return _.differenceBy(this.result.hides, this.result.shows, "name");
    },
    result() {
      // let width = this.tableType == "row" ? 145 : 135;
      return this.filterPayTypes(this.item.payout_type, this.width);
    },
  },
  data() {
    return {
      right: 1000,
      width: 135,
      timer: null,
    };
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        let right =
          document.body.clientWidth -
          this.$refs.more?.getBoundingClientRect().x;
        this.right = right || 1000;
        // 26 为 + 1 的宽度
        this.width = this.$refs.wrap.clientWidth - 28;
      }, 300);
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer);
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="less" scoped>
.detail-paytype {
  flex: 1;
  .paytype-tags {
    height: 1.5714rem;
    border-radius: 0.2857rem;
    color: #fff;
    font-size: 0.8571rem;
    padding: 0.1429rem 0.4286rem;
    margin-right: 0.2857rem;
    white-space: nowrap;
    &.cps {
      background: #28acff;
    }
    &.sponsored_fee {
      background: #8e67ff;
    }
    &.coupon_code {
      background: #15dcb3;
    }
    &.free_products {
      background: #ffc55a;
    }
    &.gift_cards {
      background: #ff7745;
    }
    &.other {
      background: #1567dc;
    }
    &.more {
      background: #e6e6e6;
      color: #333333;
      cursor: pointer;
      position: relative;
      margin-right: 0;
      &:hover {
        .paytype-mores {
          display: flex;
        }
      }
      .paytype-mores {
        display: none;
        position: absolute;
        background: rgba(0, 0, 0, 0.5);
        padding: 10px;
        border-radius: 4px;
        top: 30px;
        left: 0;
        min-width: 8.5714rem;
        flex-wrap: wrap;
        z-index: 999999;
      }

      .paytype-mores:after,
      .paytype-mores:before {
        bottom: 100%;
        left: 10%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      ._right {
        left: unset;
        right: 0;
      }
      ._right:before,
      ._right:after {
        left: unset;
        right: 10%;
      }
      .paytype-mores:after {
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: rgba(0, 0, 0, 0.5);
        border-width: 6px;
        margin-left: -6px;
      }
      .paytype-mores:before {
        border-color: rgba(194, 225, 245, 0);
        // border-bottom-color: #c2e1f5;
        border-width: 16px;
        margin-left: -16px;
      }
    }
  }
}
</style>
