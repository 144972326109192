import {debounce} from 'lodash'
export function useDebounceMessage(messageInfo){
  const {message, type} = messageInfo
  if (!this.messageHandle) {
    this.messageHandle = debounce(()=>{this.$message[type](message)},
    2000,
    {leading: true,trailing: false,'maxWait': 3000})
  }
  this.messageHandle()
}
// todo 乐观更新
export function useOptimisticUpdate( ) {
  // optimistic update
  const status = {
    loading:true,
    error:undefined,
    data:undefined,
    success:undefined,
    isError:false,
    isSuccess:false,
  }
  // optimistic update
  const actions = {
    // 更新数据
    update: (state, payload,apiFunc) => {
      const idx = state.findIndex(item=>item.id === payload.id)
      const oldState = state[idx]
      this.$set(state,idx,{...state[idx],...payload})
      callback && callback(state, payload)
      if (apiFunc instanceof Function) {
        apiFunc().then(res=>{
          status.loading = false
          status.isSuccess = true
          status.data = res
          status.success = res
          Promise.resolve(status)
        }).catch(err=>{
          status.loading = false
          status.isError = true
          status.error = err
          this.$set(state,idx,oldState)
          Promise.reject(status)
        }) 
      }
    },
    delete: (state, payload,apiFunc) => {
      const idx = state.findIndex(item=>item.id === payload.id)
      this.$delete(state,idx)
      if (apiFunc instanceof Function) {
        apiFunc().then(res=>{
          status.loading = false
          status.isSuccess = true
          status.data = res
          status.success = res
          Promise.resolve(status)
        }).catch(err=>{
          status.loading = false
          status.isError = true
          status.error = err
          this.$set(state,idx,oldState)
          Promise.reject(status)
        }) 
      }
    },
    add: (state, payload,apiFunc) => {
      this.$set(state,state.length,payload)
      if (apiFunc instanceof Function) {
        apiFunc().then(res=>{
          status.loading = false
          status.isSuccess = true
          status.data = res
          status.success = res
          Promise.resolve(status)
        }).catch(err=>{
          status.loading = false
          status.isError = true
          status.error = err
          Promise.reject(status)
        }) 
      }
    }
  }
  return { ...actions , ...status }
}
