<template>
  <div>
    <div class="logo-header">
      <a href="https://www.linkprefer.com/" target="_blank"><img src="@/assets/login/logo.svg" alt /></a>
    </div>
    <div class="sign-box">
      <div class="login-back">
        <span @click="back">
          <a-icon type="left" />
        </span>
      </div>
      <div class="form">
        <div class="wrap">
          <h1 class="login-box__title">Email Verification</h1>
          <div class="veri-box">
            <p>An email has been sent to {{email}}. Please confirm your email address.</p>
            <p>Not received our email? Please click the resend button.</p>
          </div>
          <div class="login-form">
            <div class="login-form__btn">
              <a-button :disabled="disabled" type="primary" @click="submit">{{ !disabled ? 'Resend' : time + 's' }}</a-button>
            </div>
          </div>
        </div>
      </div>
      <div class="pic">
        <img src="@/assets/login/sign-right.png" alt />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/utils/request";
import APIS from "@/api/const";

export default {
  name: "login",
  data() {
    return {
      email: '',
      time: 60,
      disabled: false,
    };
  },
  mounted () {
    this.email = this.$route.query.email;
  },
  methods: {
    back() {
      this.$router.push('/');
    },
    loop() {
      let timer = setInterval(() => {
        this.time --;
        this.disabled = true;
        if (this.time === 0) {
          this.time = 60;
          this.disabled = false;
          clearInterval(timer);
        }
      }, 1000);
    },
    submit() {
      if (this.disabled) return;

      const { email } = this;
      axios
      .post(APIS.auth.email_re_send, {
        formData: true,
        email,
      })
      .then((resp) => {
        if (resp.code === '0200') {
          this.$message.success(resp.msg);
          this.loop();
        } else {
          this.$message.error(resp.msg);
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
@import url("./signup.less");
</style>
