<template>
  <div class="numgroup">
    <span v-show="textFrom" class="numgroup-fromtext">{{ textFrom }}</span>
    <a-input-number
      class="numgroup-left"
      :min="fromMin"
      :max="fromMax"
      v-model="NumberGroup.fromNum"
      @change="fromChange"
    />
    <span v-show="currenceSign" class="numgroup-sign">{{ currenceSign }}</span>
    <span class="numgroup-totext">{{ textTo }}</span>
    <a-input-number
      class="numgroup-right"
      :min="fromMin"
      :max="fromMax"
      v-model="NumberGroup.toNum"
      @change="toChange"
    />
    <span v-show="currenceSign" class="numgroup-sign">{{ currenceSign }}</span>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  components: {},
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    textFrom: {
      type: String,
      default: "",
    },
    textTo: {
      type: String,
      default: "-",
    },
    currenceSign: {
      type: String,
      default: "",
    },
    fromMin: {
      type: Number,
      default: 1,
    },
    fromMax: {
      type: Number,
      default: 9999999999,
    },
    toMin: {
      type: Number,
      default: 1,
    },
    toMax: {
      type: Number,
      default: 9999999999,
    },
  },
  computed: {},
  data() {
    return {
      NumberGroup: this.value
        ? this.value
        : {
            fromNum: null,
            toNum: null,
          },
    };
  },
  watch: {},
  created() {},
  methods: {
    fromChange(value) {
      this.NumberGroup.fromNum = value;
      if (this.NumberGroup.toNum > 0) {
        this.triggerChange(this.NumberGroup);
      }
    },
    toChange(value) {
      this.NumberGroup.toNum = value;
      this.triggerChange(this.NumberGroup);
    },
    triggerChange(changedVal) {
      this.$emit("change", changedVal);
    },
  },
};
</script>

<style lang="less" scoped>
.numgroup {
  display: flex;
  align-items: center;
  &-fromtext {
    margin-right: 12px;
  }
  &-totext {
    margin-left: 12px;
  }
  &-sign {
    margin-left: 12px;
  }
  &-right {
    margin-left: 12px;
  }
}
</style>
