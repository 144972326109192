<template>
  <div class="json-wrap">
    <pre v-highlightjs="xmlData">
    <code class="xml"></code>
  </pre>
  </div>
</template>

<script>
import vkbeautify from "vkbeautify";

export default {
  props: {
    dataSource: {
      type: String,
      default: "",
    },
  },
  computed: {
    xmlData() {
      return vkbeautify.xml(this.dataSource);
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.json-wrap {
  color: #fff;
  border-radius: 16px;
  padding: 8px 12px;
  margin: 12px 0;
  border: 1px solid #f5f5f5;
}

/deep/ .hljs-tag {
  color: #13ce66;
  font-weight: normal;
  font-family: "prefer";
  font-size: 14px;
}
/deep/ .hljs-name {
  color: #13ce66;
}
/deep/ pre code.hljs {
  overflow: hidden;
  font-family: "prefer";
}
</style>
