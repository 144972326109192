<template>
  <div class="mstapi">
    <Header collection="Tools"></Header>
    <div class="container">
      <div class="headers">
        <div class="headers-title">Version Updates</div>
        <ul class="headers-list">
          <li class="headers-item">
            <div>2016/08/08 --- Settlement API</div>
          </li>
          <li class="headers-item">
            <div>
              Description: Return to launch, rejected or closed merchants
            </div>
          </li>
        </ul>
      </div>
      <div class="headers">
        <div class="headers-title">Request Information</div>
        <ul class="headers-list">
          <li class="headers-item">
            <span>URL:</span>
            <div>
              https://www.LinkPrefer.com/api.php?mod=medium&op=merchantCheckList
            </div>
          </li>
          <li class="headers-item">
            <span>Return Format:</span>
            <div>Json/xml</div>
          </li>
          <li class="headers-item">
            <span>HTTP request method:</span>
            <div>GET/POST application/x-www-form-urlencoded</div>
          </li>
          <CopyToken />
        </ul>
      </div>
      <div class="content">
        <div class="comm-tables">
          <div class="comm-header">
            <div class="comm-header-left">
              <div>Request Parameter</div>
            </div>
          </div>
          <div class="comm-list">
            <a-table
              class="comtable"
              :columns="columns"
              :data-source="dataList1"
              :rowKey="(record, index) => record.name"
              :loading="isLoading"
              :pagination="false"
            >
              <div class="api-title" slot="apititle" slot-scope="text">
                {{ text }}
              </div>
            </a-table>
          </div>
        </div>
        <div class="comm-tables">
          <div class="comm-header">
            <div class="comm-header-left">
              <div>Return Parameter</div>
            </div>
          </div>
          <div class="comm-list">
            <a-table
              class="comtable"
              :columns="columns"
              :data-source="dataList2"
              :rowKey="(record, index) => record.name"
              :loading="isLoading"
              :pagination="false"
            >
              <div class="api-title" slot="apititle" slot-scope="text">
                {{ text }}
              </div>
            </a-table>
          </div>
        </div>
        <div class="comm-tables">
          <div class="comm-header">
            <div class="comm-header-left">
              <div>Result Code</div>
            </div>
          </div>
          <div class="comm-list">
            <a-table
              class="comtable"
              :columns="columns"
              :data-source="dataList3"
              :rowKey="(record, index) => record.name"
              :loading="isLoading"
              :pagination="false"
            >
              <div class="api-title" slot="apititle" slot-scope="text">
                {{ text }}
              </div>
            </a-table>
          </div>
        </div>

        <div class="json-list">
          <div class="json-title">Return Format</div>
          <JsonPretty :data="jsonStr" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/public/Header.vue";
import axios from "@/utils/request";
import CopyToken from "@/components/CopyToken.vue";
import JsonPretty from "@/components/JsonPretty.vue";
import XmlPretty from "@/components/XmlPretty.vue";

export default {
  filters: {
    filterStaus(status) {
      if (status == "new") {
        return "Unpaid";
      } else if (status == "paid") {
        return "Paid";
      } else {
        return "Void";
      }
    },
  },
  components: {
    Header,
    CopyToken,
    JsonPretty,
    XmlPretty,
  },
  data() {
    let columns = [
      {
        title: "Name",
        dataIndex: "name",
        width: 160,
        align: "left",
        scopedSlots: { customRender: "apititle" },
      },
      {
        title: "Description",
        dataIndex: "description",
        width: 1110,
        align: "left",
      },
    ];
    return {
      columns,
      dataList1: [
        {
          name: "token",
          description:
            "unique site identifier (obtained from the site management interface) {required}",
        },
        {
          name: "type",
          description:
            "Return data format: json,xml {optional, the default is json}",
        },
        {
          name: "payment_begin",
          description:
            "Operation start date Format: 2020-01-01 {Can be empty Both dates are empty at the same time, return all}",
        },
        {
          name: "payment_end",
          description: "Operation deadline format: 2020-05-31 {may be empty}",
        },
      ],
      dataList2: [
        {
          name: "m_id",
          description: "Merchant ID",
        },
        {
          name: "sitename",
          description: "Merchant name",
        },
        {
          name: "site_url",
          description: "Merchant URL",
        },
        {
          name: "datetime",
          description: "Operation time",
        },
        {
          name: "joinstatus",
          description: "Join status shows your relationship with merchants",
        },
        {
          name: "merchant_status",
          description:
            "The online and offline status of merchants on LinkPrefer",
        },
        {
          name: "tracking_url",
          description: "Tracking link",
        },
      ],
      dataList3: [
        {
          name: "0",
          description: "Success",
        },
        {
          name: "4001",
          description: "Date format is incorrect",
        },
        {
          name: "4002",
          description: "The start date cannot be less than the end date",
        },
        {
          name: "9999",
          description: "Request frequency limit",
        },
      ],
      currentPage: 1,
      isLoading: false,
      jsonStr: {
        status: {
          code: 0,
          msg: "Success",
        },
        data: [
          {
            m_id: "superdryus",
            sitename: "Superdry US",
            site_url: "https://www.superdry.com/us/",
            datetime: "2020-01-06 10:21:08",
            joinstatus: "no relationship",
            merchant_status: "launch",
            tracking_url: "",
          },
          {
            m_id: "needsupply",
            sitename: "Need Supply",
            site_url: "https://needsupply.com",
            datetime: "2020-03-12 14:46:07",
            joinstatus: "no relationship",
            merchant_status: "launch",
            tracking_url: "",
          },
          {
            m_id: "applecn",
            sitename: "Apple China",
            site_url: "https://www.apple.com/cn",
            datetime: "2020-03-03 16:04:33",
            joinstatus: "processing",
            merchant_status: "launch",
            tracking_url: "",
          },
          {
            m_id: "dji17",
            sitename: "dji17",
            site_url: "http://store.dji.com/?1=1",
            datetime: "2020-03-03 20:51:31",
            joinstatus: "processing",
            merchant_status: "closed",
            tracking_url: "",
          },
        ],
      },
      xmlStr: `<xml version="1.0" encoding="utf-8">
<root>
<status>
<code>0</code>
<msg>success</msg>
<data>
  <item>
      <m_id>hbcn</m_id>
      <amount>2.90</amount>
      <date>2016-04-19</date>
      <order_ym>2016-01</order_ym>
      <note>2016-01-01 ~ 2016-01-31, hbcn 产生业绩</note>
      <payment_sn>7b2ae0233c97b3310419</payment_sn>
  </item>
</data>
</status>
</root>`,
    };
  },
  mounted() {},
  methods: {
    html2Escape(sHtml) {
      return sHtml.replace(/[<>&"]/g, function(c) {
        return { "<": "&lt;", ">": "&gt;", "&": "&amp;", '"': "&quot;" }[c];
      });
    },
    escape2Html(str) {
      var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
      return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function(all, t) {
        return arrEntities[t];
      });
    },
    onSelectChange() {},
    // 切换下一页
    paginationChange(page, pageSize) {
      //   this.currentPage = page;
      //   this.getDataList();
    },
  },
  beforeDestroy() {
    if (this.onWindowResize) {
      window.onresize = null;
    }
  },
};
</script>

<style lang="less" scoped>
.mstapi {
  .headers {
    display: flex;
    width: 100%;
    margin-top: 1.4286rem;
    flex-direction: column;
    background: #ffffff;
    border-radius: 16px;
    padding: 1.7143rem;
    &-title {
      font-size: 1.1429rem;
      color: #3762d6;
      font-family: "prefer-bold";
    }
    &-list {
    }
    &-item {
      margin-top: 16px;
      span {
        color: #999999;
      }
      div {
        margin-top: 3px;
        font-family: "prefer-bold";
      }
    }
  }
  .content {
    margin-top: 1.4286rem;
    .comm-tables {
      margin-top: 1.4286rem;
    }
    .comm-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      background: #fff;
      padding: 1.1429rem;
      border-radius: 16px 16px 0 0;
      flex-direction: column;
    }
    .comm-header-left {
      > div {
        font-size: 1.1429rem;
        color: #3762d6;
        font-family: "prefer-bold";
      }
    }
    .api-title {
      font-family: "prefer-bold";
    }
    .json-list {
      background: #ffffff;
      border-radius: 16px;
      padding: 1.7143rem;
      margin-top: 20px;
    }
    .json-title {
      color: #3762d6;
      font-size: 1.1429rem;
      font-family: "prefer-bold";
    }
  }
}

/deep/ .ant-table-tbody > tr > td {
  padding-left: 24px;
}
/deep/ .ant-table-thead > tr > th {
  padding-left: 24px;
}
.comm-header {
  padding-left: 24px !important;
}
</style>
