<template>
  <div>
    <div class="logo-header">
      <a href="https://www.linkprefer.com/" target="_blank"
        ><img src="@/assets/login/logo.svg" alt
      /></a>
    </div>
    <div class="sign-box">
      <!-- <div class="login-back">
        <span @click="goDashboard">
          <a-icon type="left" />
        </span>
      </div> -->
      <div class="form">
        <div class="wrap">
          <h1 class="login-box__title login-font">Channels</h1>
          <h1 class="login-box__subtitle">
            Choose the method you use to promote
          </h1>
          <a-spin :spinning="spinning">
            <div class="channel-list">
              <div class="channel-list__item">
                <div class="item-hd" @click="goAdd('media')">
                  <div class="icon icon-media"></div>
                  <h4 class="title">Social Media</h4>
                  <div class="icon icon-add"></div>
                </div>
                <div class="item-box">
                  <div
                    class="item-box__item"
                    v-for="item in list.social"
                    :key="item.data.id"
                  >
                    <div
                      class="icon icon-close"
                      @click="onItemDelete(item.data.id)"
                    ></div>
                    <div
                      class="item-desc"
                      @click="goAdd('media', item.data.id)"
                    >
                      <div class="name">
                        {{ item.data.social_platform_name }}
                      </div>
                      <div class="link">{{ item.data.host }}</div>
                    </div>
                    <div
                      class="icon icon-arrow"
                      @click="goAdd('media', item.data.id)"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="channel-list__item">
                <div class="item-hd" @click="goAdd('channel')">
                  <div class="icon icon-channel"></div>
                  <h4 class="title">Website</h4>
                  <div class="icon icon-add"></div>
                </div>
                <div class="item-box">
                  <div
                    class="item-box__item"
                    v-for="item in list.website"
                    :key="item.data.id"
                  >
                    <div
                      class="icon icon-close"
                      @click="onItemDelete(item.data.id)"
                    ></div>
                    <div
                      class="item-desc"
                      @click="goAdd('channel', item.data.id)"
                    >
                      <div class="name">{{ item.data.title }}</div>
                      <div class="link">{{ item.data.host }}</div>
                    </div>
                    <div
                      class="icon icon-arrow"
                      @click="goAdd('channel', item.data.id)"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="channel-list__item">
                <div class="item-hd" @click="goAdd('app')">
                  <div class="icon icon-app"></div>
                  <h4 class="title">App</h4>
                  <div class="icon icon-add"></div>
                </div>
                <div class="item-box">
                  <div
                    class="item-box__item"
                    v-for="item in list.app"
                    :key="item.data.id"
                  >
                    <div
                      class="icon icon-close"
                      @click="onItemDelete(item.data.id)"
                    ></div>
                    <div class="item-desc" @click="goAdd('app', item.data.id)">
                      <div class="name">
                        [{{
                          item.data.app_platform_type === "1"
                            ? "Android"
                            : "IOS"
                        }}] {{ item.data.title }}
                      </div>
                      <div class="link">{{ item.data.host }}</div>
                    </div>
                    <div
                      class="icon icon-arrow"
                      @click="goAdd('app', item.data.id)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </a-spin>
          <div class="login-form">
            <div class="login-form__btn">
              <a-button
                :disabled="disabledDone"
                type="primary"
                @click="goDashboard"
                >Done</a-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="pic">
        <img src="@/assets/login/sign-right.png" alt />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/utils/request";
import APIS from "@/api/const";

export default {
  name: "login",
  data() {
    return {
      spinning: true,
      list: {
        social: [],
        app: [],
        website: [],
      },
    };
  },
  computed: {
    disabledDone() {
      if (
        this.list.social.length > 0 ||
        this.list.app.length > 0 ||
        this.list.website.length > 0
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.getChannelList();
  },
  methods: {
    async getChannelList() {
      this.spinning = true;
      const resp = await axios.get(APIS.channel.site_list, {
        params: {},
      });
      this.list.social = resp.payload.social ? resp.payload.social : [];
      this.list.app = resp.payload.app ? resp.payload.app : [];
      this.list.website = resp.payload.website ? resp.payload.website : [];
      this.spinning = false;
    },
    goAdd(type, id) {
      this.$router.push({
        name: `channel-add-${type}`,
        query: {
          id,
        },
      });
    },
    goDashboard() {
      this.$router.push({
        path: `/`,
      });
    },
    async onItemDelete(id) {
      const resp = await axios.post(APIS.channel.site_delete, {
        formData: true,
        id,
      });
      this.$message.success(resp.msg);
      this.getChannelList();
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
