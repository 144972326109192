<template>
  <modal
    id="chatmodal"
    :isVisible="mediumId != null"
    :title="title"
    :showBtn="true"
    :isShowCancel="false"
    @close-popup="$emit('close')"
    width="790px"
    :submitText="'SEND'"
    :cancelText="'Cancel'"
    @submit="sendMessage"
    :disabled="loading"
    btnAlign="center"
  >
    <template #content>
      <a-spin :spinning="loading">
        <div class="item font-bold" style="margin-top:10px;">
          <div style=" width:200px">Invite Template</div>
          <a-select
            class="select"
            placeholder="Choose a template or not"
            @change="handleTemplateChange"
            size="large"
          >
            <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
            <a-select-option
              :value="temp.id"
              v-for="temp in templates"
              :key="temp.id"
              >{{ temp.subject }}</a-select-option
            >
          </a-select>
        </div>
        <div class="mail-content">
          <template v-if="mediumId">
            <div class="item ">
              <div class="font-bold">Subject</div>
              <a-input v-model="content_title"></a-input>
            </div>
          </template>
          <div class="item">
            <div class="font-bold">Content</div>
            <a-textarea
              v-model="content"
              :auto-size="{ minRows: 12, maxRows: 20 }"
            />
          </div>
        </div>
      </a-spin>
    </template>
  </modal>
</template>
<script>
import Modal from "../../../components/Modal.vue";
import axios from "@/utils/request";

export default {
  name: "chat-model",
  components: { Modal },
  props: {
    mediumId: {
      default: null,
      type: String,
    },
    medium: {
      default: () => ({}),
      type: Object,
    },
    evt: {
      default: "invite",
      type: String,
    },
  },
  watch: {
    mediumId(newV, oldV) {
      if (!newV) {
        this.medium = null;
        return;
      }

      this._getTemplates();
      this.content = "";
      this.content_title = "";
    },
    medium(newV, oldV) {
      let withData = newV && newV.medium_id;
      if (withData) {
        this.title =
          "Create Invitation: " + newV.title + " - " + newV.medium_id;
      } else {
        this.title = "Create Invitation";
      }
    },
  },
  data() {
    return {
      title: "Create Invitation : ",
      loading: false,
      content: "",
      content_title: "",
      templates: [],
    };
  },
  methods: {
    _getTemplates() {
      axios
        .get("/api_admin.php?a=discovery/invite_template_list")
        .then((res) => {
          if (res.code === 0) {
            this.templates = res.data.data;
          }
        });
    },
    handleTemplateChange(val) {
      let temp = this.templates.find((v) => v.id == val);
      this.content = temp.message;
      this.content_title = temp.subject;
    },
    sendMessage() {
      if (this.content == "") {
        this.$message.error("Please input message");
      } else {
        // 执行发消息,且关闭窗口
        this.loading = true;
        // 发送邀请消息,接口是discovery/action
        axios
          .post("/api_admin.php?a=discovery/action", {
            action: "invite",
            mediumId: this.mediumId,
            message: this.content,
            subject: this.content_title,
          })
          .then((res) => {
            this.loading = false;
            if (res.code === 0) {
              this.$emit("invited", this.mediumId);
            } else if (res.code == 10801) {
              this.$emit("actionDisabled", "lowfunds");
            } else if (res.code == 10802) {
              this.$emit("actionDisabled", "newone");
            } else {
              this.$message.error(res.msg);
            }
          });
      }
    },
  },
};
</script>
<style lang="less" scoped>
#chatmodal {
  .item {
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.font-bold {
      padding-bottom: 10px;
      margin-top: 10px;
      font-size: 1.1429rem;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .mail-content {
    background-color: #f2f9ffff;
    padding: 20px;
    margin-top: 10px;
    border-radius: 6px;

    .item {
      > div:first-child {
        font-size: 1.1429rem;
        width: 90px;
        align-self: flex-start;
        margin-right: 10px;
        line-height: 2.8571rem;
      }
    }
  }

  .select {
    width: 100%;
  }
}
</style>
