<template>
  <div class="bill-wrap">
    <p>Deposit and Payment Details</p>
    <div class="export">
      <button>Export</button>
    </div>
    <div class="table">
      <div class="item" v-for="(item, index) in balanceLists" :key="index">
        <div class="remark">
          <img
            src="@/assets/balance-add.png"
            alt
            v-if="parseInt(item.amount) >= 0"
          />
          <img src="@/assets/balance-jian.png" alt v-else />
          <span>{{ item.remark }}</span>
        </div>
        <div class="time">
          <span>{{ item.created_time | filterTime }}</span>
        </div>
        <div class="amount">
          <span
            :style="
              parseInt(item.amount) >= 0 ? 'color: #2898FF;' : 'color: #999999;'
            "
            >{{ item.amount | toThousands(false, true) }}</span
          >
        </div>
        <!-- <div class="balance">
          <span>Balance: ${{balanceAmount}}</span>
        </div>-->
      </div>
    </div>
    <div class="footer-pagination">
      <a-pagination
        v-model="currentPage"
        :total="totalPage"
        show-less-items
        @change="paginationChange"
      />
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "Payment Amount",
    dataIndex: "amount",
    key: "amount",
    scopedSlots: { customRender: "amount" },
  },
  {
    title: "Order Time",
    dataIndex: "time",
    key: "time",
  },
  {
    title: "Account Name",
    dataIndex: "account-name",
    key: "account-name",
  },
  {
    title: "Bank Name",
    dataIndex: "bank-name",
    key: "bank-name",
    ellipsis: true,
  },
  {
    title: "Account Number",
    dataIndex: "number",
    key: "number",
    ellipsis: true,
  },
];
import axios from "@/utils/request";
import moment from "moment-timezone";
export default {
  name: "bill",
  props: {
    time_start: {
      type: String,
    },
    time_end: {
      type: String,
    },
  },
  data() {
    return {
      balanceLists: [],
      columns,
      balanceAmount: "",
      currentPage: 1,
      page_size: 10,
      totalPage: 10,
    };
  },
  created() {
    this._getBalanceHistory();
  },
  methods: {
    _getBalanceHistory() {
      axios
        .get("api_admin.php?a=account/balanceHistory", {
          params: {
            time_start: this.time_start,
            time_end: this.time_end,
            page_num: this.currentPage,
            page_size: this.page_size,
          },
        })
        .then((res) => {
          if (res.code === 0) {
            this.balanceLists = res.data.data;
            this.balanceAmount = res.data.balanceAmount;
            this.totalPage = res.data.total_size;
          } else {
            this.$message.error(
              res.msg || "Sorry, server is busy. please try later."
            );
          }
        });
    },
    paginationChange(page, pageSize) {
      this.currentPage = page;
      this._getBalanceHistory();
    },
  },
  filters: {
    filterTime(time) {
      let momentTime = moment.unix(time).format("L LT");
      return momentTime;
    },
  },
};
</script>

<style lang="less" scoped>
.bill-wrap {
  margin-top: 22px;
  p {
    font-size: 1.1429rem;
    color: #999999;
    line-height: 20px;
    margin-bottom: 22px;
  }
  .export {
    margin-bottom: 22px;
    text-align: right;
    button {
      width: 116px;
      height: 32px;
      background: #fff;
      border-radius: 6px;
      font-size: 1rem;
      color: #2898ff;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      border: 1px solid #2898ff;
      vertical-align: middle;
      box-sizing: border-box;
      padding: 0;
    }
  }
  .table {
    width: 100%;
    background: #ffffff;
    border: 1px solid #eeeeee;
    .item {
      height: 3.4286rem;
      display: flex;
      align-items: center;
      padding: 0 64px;
      box-sizing: border-box;
      border-bottom: 1px solid #eeeeee;
      > * {
        flex: 1 1 33.33%;
        line-height: 30px;
        vertical-align: middle;
      }
      &:last-child {
        border-bottom: 0px;
      }
      .remark {
        width: 260px;
        padding-right: 10px;
        box-sizing: border-box;
        img {
          width: 30px;
          height: 30px;
          margin-right: 6px;
        }
        span {
          font-size: 1.1429rem;
          color: #333333;
          line-height: 30px;
          vertical-align: middle;
        }
      }
      .time {
        width: 265px;
        padding-right: 10px;
        box-sizing: border-box;
        span {
          font-size: 1.1429rem;
          color: #333333;
          line-height: 30px;
          vertical-align: middle;
        }
      }
      .amount {
        width: 265px;
        padding-right: 10px;
        box-sizing: border-box;
        text-align: right;
        span {
          font-size: 1.1429rem;
          line-height: 30px;
          vertical-align: middle;
        }
      }
      .balance {
        width: 265px;
        padding-right: 10px;
        box-sizing: border-box;
        span {
          font-size: 1.1429rem;
          color: #333;
          line-height: 20px;
        }
      }
    }
  }
  .footer-pagination {
    margin-top: 22px;
    text-align: right;
  }
}
</style>
