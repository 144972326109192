import Vue from "vue";
import Vuex from "vuex";
import moment from "moment-timezone";
import { GMT_LIST } from "@/utils/config";

Vue.use(Vuex);

let guide = localStorage.getItem("guide-step");
guide = Number(guide);
if (guide === null || isNaN(guide)) {
  guide = 0;
}
if (guide != 0) {
  guide = -1;
}

const token = localStorage.getItem("saveToken")
  ? localStorage.getItem("saveToken") == "null"
    ? ""
    : localStorage.getItem("saveToken")
  : "";

export default new Vuex.Store({
  state: {
    authorityJudgment:
      JSON.parse(localStorage.getItem("saveUserPermissions")) || [], // 权限判断
    accountMessage:
      JSON.parse(localStorage.getItem("saveAccountMessage")) || {}, // 注册账户信息
    // bannerTitle: "Brand Account Creation", // 注册banner title
    token: token,
    isActMenu: "",
    accountInfo: JSON.parse(localStorage.getItem("accountInfo")) || {}, // 注册账户信息
    accountDetails: {}, // 我的账户详情
    commonConfig: {},
    chatMediumId: null,
    chatUserId: null,
    timezone: {
      gmt: "GMT -05:00",
      name: "Etc/GMT+5",
    },
    campaignTexts: [],
    campaignImages: [],
    campaignVideos: [],
    currentCurrency: {
      id: 0,
      simple_name: "",
      sign: "",
    },
    guide,
    // ---------新增---------
    countryList: [],
    siteInfo: {},
    userInfo: {},
    selectedSite: null,
    showNotice: false,
  },
  mutations: {
    setSelectedSite(state, payload) {
      state.selectedSite = payload;
    },
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
    setSiteInfo(state, data) {
      state.siteInfo = data;
    },
    setCountryList(state, data) {
      state.countryList = data;
    },
    setGuide(state, data) {
      state.guide = data;
      localStorage.setItem("guide-step", Number(data));
    },
    setAccountInfo(state, rawData) {
      state.accountInfo = {
        ...rawData,
        max_apply_step: Math.max(
          state.accountInfo.max_apply_step || 1,
          rawData.max_apply_step || 1
        ),
      };
      localStorage.setItem("accountInfo", JSON.stringify(rawData));
    },
    setAuthority(state, rawData) {
      state.authorityJudgment = rawData;
      localStorage.setItem("saveUserPermissions", JSON.stringify(rawData));
    },
    setAccountMessage(state, rawData) {
      state.accountMessage = { ...state.accountMessage, ...rawData };
      localStorage.setItem(
        "saveAccountMessage",
        JSON.stringify(state.accountMessage)
      );
    },
    setToken(state, rawData) {
      state.token = rawData;
      localStorage.setItem("saveToken", state.token);
    },
    clearToken(state) {
      state.token = null;
      state.accountInfo = {};
      localStorage.removeItem("saveToken");
      localStorage.removeItem("accountInfo");
      localStorage.removeItem("saveUserPermissions");
      localStorage.removeItem("saveAccountMessage");

      Vue.$cookies.remove("auth_token");
    },
    setChatMediumId(state, rawData) {
      state.chatMediumId = rawData;
    },
    setChatUserId(state, rawData) {
      state.chatUserId = rawData;
    },
    setActMenu(state, rawData) {
      state.isActMenu = rawData;
    },
    setAccountDetails(state, rawData) {
      state.accountDetails = rawData;
      let timezoneKey = rawData.merchant.timezone || "GMT -05:00";
      moment.tz.setDefault(GMT_LIST[timezoneKey]);
      state.timezone = {
        gmt: timezoneKey,
        name: GMT_LIST[timezoneKey],
      };
      // console.log('Timezone', timezoneKey, GMT_LIST[timezoneKey])
    },
    commonConfig(state, data) {
      state.commonConfig = data;
    },
    setCampaignTexts(state, data) {
      state.campaignTexts = data;
    },
    setCampaignImages(state, data) {
      state.campaignImages = data;
    },
    setCampaignVedios(state, data) {
      state.campaignVideos = data;
    },
    setCurrentCurrencyId(state, data) {
      state.currentCurrency = data;
    },
    setShowNotice(state, data) {
      state.showNotice = data;
    },
  },
  actions: {
    updateAccountDetails({ commit, state }, data) {
      let details = state.accountDetails;
      if (data.merchant) {
        details.merchant = { ...details.merchant, ...data.merchant };
      }
      if (data.norm) {
        details.norm = data.norm;
      }
      if (data.billing_info) {
        details.billing_info = {
          ...details.billing_info,
          ...data.billing_info,
        };
      }
      commit("setAccountDetails", details);
    },
    removeCampaignCreatives({ commit, state }, { type, cid }) {
      let list = [];
      if (type === 1) {
        list = [...state.campaignTexts];
      } else if (type === 2) {
        list = [...state.campaignImages];
      } else if (type === 3) {
        list = [...state.campaignVideos];
      }
      for (let i = 0; i < list.length; i++) {
        if (list[i].cid === cid) {
          list.splice(i, 1);
        }
      }
      if (type === 1) {
        commit("setCampaignTexts", list);
      } else if (type === 2) {
        commit("setCampaignImages", list);
      } else if (type === 3) {
        commit("setCampaignVedios", list);
      }
    },
    resetCampaignCreatives({ commit }) {
      commit("setCampaignTexts", []);
      commit("setCampaignImages", []);
      commit("setCampaignVedios", []);
    },
  },
  modules: {},
  getters: {
    countries: (state) => {
      if (state.commonConfig) {
        return state.commonConfig.countries;
      } else {
        return [];
      }
    },
    currencies: (state) => {
      if (state.commonConfig) {
        return state.commonConfig.currencies;
      } else {
        return [];
      }
    },
    countryMap: (state) => {
      let countryMap = {};
      state.countries.forEach((item) => {
        state.countryMap[item.id] = item;
      });
      return countryMap;
    },
  },
});
