<template>
  <div class="viewcard">
    <div class="viewcard-head">
      <p>{{ title }}</p>
    </div>
    <div class="viewcard-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {},
  watch: {},
};
</script>

<style lang="less" scoped>
.viewcard {
  background: #f2f9ff;
  border-radius: 12px;
  padding: 24px 32px;
  margin-top: 22px;
  &-head {
    p {
      margin-bottom: 0;
      color: #333333;
      font-size: 1.1429rem;
      font-weight: 600;
    }
  }
}
</style>
