<template>
  <div class="campaign-steps">
    <div class="campaign-steps-node">
      <div
        class="campaign-steps-node__text"
        :class="stepIndex == 1 ? 'current' : 'done'"
      >
        Open Application
      </div>
      <CampaignStepsShape :nodeStatus="2" />
      <div
        class="campaign-steps-node__bottom"
        :class="stepIndex == 1 ? 'current' : 'done'"
      >
        {{ date1 }}
      </div>
    </div>
    <div
      class="campaign-steps-line"
      :style="
        `background: linear-gradient(to right,#3762d6 0%,#3762d6 ${progress1}%,#ddecff ${progress1}%,#ddecff 100%)`
      "
    ></div>
    <div class="campaign-steps-node">
      <div
        class="campaign-steps-node__text"
        :class="stepIndex > 2 ? 'done' : stepIndex == 2 ? 'current' : ''"
      >
        Application Deadline
      </div>
      <CampaignStepsShape :nodeStatus="stepIndex >= 2 ? 2 : 1" />
      <div
        class="campaign-steps-node__bottom"
        :class="stepIndex > 2 ? 'done' : stepIndex == 2 ? 'current' : ''"
      >
        {{ date2 }}
      </div>
    </div>
    <div
      class="campaign-steps-line"
      :style="
        `background: linear-gradient(to right,#3762d6 0%,#3762d6 ${progress2}%,#ddecff ${progress2}%,#ddecff 100%)`
      "
    ></div>
    <div class="campaign-steps-node">
      <div
        class="campaign-steps-node__text campaign-steps-node__text1"
        :class="stepIndex == 6 ? 'current' : ''"
      >
        Campaign End Time
      </div>
      <CampaignStepsShape :nodeStatus="stepIndex == 3 ? 2 : 1" />
      <div
        class="campaign-steps-node__bottom"
        :class="stepIndex == 6 ? 'current' : ''"
      >
        {{ date3 }}
      </div>
    </div>
  </div>
</template>

<script>
// const defaultLogo = require("@/assets/default.png");
import moment from "moment-timezone";
import axios from "@/utils/request";
// import Modal from "../../../components/Modal.vue";
import CampaignStepsShape from "./create/CampaignStepsShape.vue";

export default {
  components: { CampaignStepsShape },
  props: {
    date1: {
      type: String,
      default: "",
    },
    date2: {
      type: String,
      default: "",
    },
    date3: {
      type: String,
      default: "",
    },
    stepIndex: {
      type: Number,
      default: 1,
    },
    progress1: {
      type: Number,
      default: 0,
    },
    progress2: {
      type: Number,
      default: 0,
    },
  },
  computed: {},
  data() {
    return {
      countryMap: {},
      statByLocal: "today",
      errorPlaceHolder: null,
      isShowUpdate: false,
      newStatus: "",
      isLoadingModal: false,
    };
  },
  watch: {},
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.campaign-steps {
  display: flex;
  align-items: center;
  margin: 3.4286rem auto;
  width: 85%;
  &-node {
    position: relative;
    &__text {
      position: absolute;
      text-align: center;
      width: 9.7143rem;
      top: -2.1429rem;
      left: -4.4286rem;
      color: #999999;
      &.current {
        color: #333333;
      }
      &.done {
        color: #333333;
      }
    }
    &__text1 {
      width: 10rem;
    }
    &__bottom {
      position: absolute;
      text-align: center;
      width: 8rem;
      top: 2.2857rem;
      left: -2.8571rem;
      color: #999999;
      &.current {
        color: #333333;
      }
      &.done {
        color: #333333;
      }
    }
    &__shape {
    }
    &__current {
      width: 1.7143rem;
      height: 1.7143rem;
      background: #ffffff;
      box-shadow: 0px 2px 10px 0px rgba(204, 204, 204, 0.36);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      > div {
        width: 0.5714rem;
        height: 0.5714rem;
        background: #05c15f;
        border-radius: 50%;
      }
    }
    &__done {
      > i {
        color: #05c15f;
        font-size: 1.7143rem;
      }
    }
    &__future {
      width: 1.7143rem;
      height: 1.7143rem;
      background: #ffffff;
      border: 2px solid #e1e2e8;
      border-radius: 50%;
    }
  }
  &-line {
    width: 26.2857rem;
    height: 0.1429rem;
    background: #ddecff;
    margin-left: -1px;
    // background: linear-gradient(
    //   to right,
    //   #3762d6 0%,
    //   #3762d6 50%,
    //   #ddecff 50%,
    //   #ddecff 100%
    // );
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
