<template>
  <div>
    <div class="logo-header">
      <a href="https://www.linkprefer.com/" target="_blank"
        ><img src="@/assets/login/logo.svg" alt
      /></a>
    </div>
    <div class="sign-box">
      <div class="form">
        <div class="wrap sign-wrap">
          <h1 class="login-box__title sign-form login-font">Sign Up</h1>
          <div class="login-form sign-form">
            <div
              class="login-form__item"
              :class="{
                'is-error': formMsg.email,
              }"
            >
              <div class="form-item__label">Email <span>*</span></div>
              <div class="form-item__input">
                <input
                  type="text"
                  v-model="form.email"
                  @input="onEFormChange"
                  @blur="onEFormChange"
                />
              </div>
              <div class="error-tips" v-show="formMsg.email">
                {{ formMsg.email }}
              </div>
            </div>
            <div
              class="login-form__item"
              :class="{
                'is-error': formMsg.password,
              }"
            >
              <div class="form-item__label">Password <span>*</span></div>
              <div class="form-item__input">
                <input
                  :type="pwdShow ? 'text' : 'password'"
                  v-model="form.password"
                  @input="onPFormChange"
                  @blur="onPFormChange"
                />
                <div class="eye">
                  <a-icon type="eye" v-if="pwdShow" @click="onPwdVisiable" />
                  <a-icon type="eye-invisible" v-else @click="onPwdVisiable" />
                </div>
              </div>
              <div class="error-tips" v-show="formMsg.password">
                {{ formMsg.password }}
              </div>
            </div>

            <div
              class="login-form__item"
              :class="{
                'is-error': formMsg.invite_code,
              }"
            >
              <div class="form-item__label">Invite Code <span>*</span></div>
              <div class="form-item__input">
                <input
                  type="text"
                  v-model="form.invite_code"
                  @input="onIFormChange"
                  @blur="onIFormChange"
                />
              </div>
              <div class="error-tips" v-show="formMsg.invite_code">
                {{ formMsg.invite_code }}
              </div>
            </div>

            <div class="login-form__item">
              <div class="form-item__input">
                <a-checkbox v-model="form.agree_newsletter">
                  Receive Newsletters and Special Deals
                </a-checkbox>
              </div>
            </div>
            <div class="login-form__item">
              <div class="form-item__input">
                <a-checkbox v-model="form.agree_privacy">
                  I agree to
                  <a
                    href="https://www.linkprefer.com/terms-of-use/"
                    target="_blank"
                    >the Terms of Use</a
                  >
                  and
                  <a
                    href="https://www.linkprefer.com/privacy-policy/"
                    target="_blank"
                    >Privacy Policy</a
                  >.
                </a-checkbox>
              </div>
            </div>
            <div class="login-form__btn">
              <a-button type="primary" @click="submit">Create Account</a-button>
            </div>
            <div class="login-tips">
              <p class="p3">
                Already have an account?
                <router-link to="/login">Log in</router-link>
              </p>
              <p class="p2">— or —</p>
              <p class="p3">
                Are you a brand?
                <a href="mailto:am@linkprefer.com">Click here</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="pic">
        <img src="@/assets/login/sign-right.png" alt />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/utils/request";
import APIS from "@/api/const";

export default {
  name: "login",
  data() {
    return {
      pwdShow: false,
      form: {
        email: "",
        password: "",
        invite_code: "",
        agree_newsletter: false,
        agree_privacy: false,
      },
      formMsg: {
        email: "",
        password: "",
        invite_code: "",
      },
    };
  },
  methods: {
    onPwdVisiable() {
      this.pwdShow = !this.pwdShow;
    },
    async onEFormChange() {
      const { email } = this.form;
      if (
        !/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
          email
        )
      ) {
        this.formMsg.email = "Please enter a valid email address";
        return false;
      }
      const resp = await axios.post(APIS.auth.email_check_repeat, {
        formData: true,
        email,
      });
      if (resp.code === "0200") {
        this.formMsg.email = "";
        return true;
      } else {
        this.formMsg.email = resp.msg;
        return false;
      }
    },
    async onPFormChange() {
      if (
        !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[~!@#$%^&*\(\)\+_=\-<>\?\[\]\:;\|\{\}])[A-Za-z\d~!@#$%^&*\(\)\+_=\-<>\?\[\]\:;\|\{\}]{8,}$/.test(
          this.form.password
        )
      ) {
        this.formMsg.password =
          "More than 8 letters, with all types of letter/number/special character(-_$@!%*?&).";
        return false;
      }
      this.formMsg.password = "";
      return true;
    },
    async onIFormChange() {
      if (!this.form.invite_code) {
        this.formMsg.invite_code = "Please enter a valid invite code";
        return false;
      }
      this.formMsg.invite_code = "";
      return true;
    },
    async submit() {
      const E = await this.onEFormChange();
      const P = await this.onPFormChange();
      const I = await this.onIFormChange();
      if (!(E && P && I)) return;

      const { form } = this;
      axios
        .post(APIS.auth.register_1, {
          formData: true,
          ...Object.assign(
            { ...form },
            {
              agree_newsletter: +form.agree_newsletter,
              agree_privacy: +form.agree_privacy,
            }
          ),
        })
        .then((resp) => {
          if (resp.code === "0200") {
            this.$router.push({
              name: "email-verification",
              query: {
                email: form.email,
              },
            });
          } else {
            this.$message.error(resp.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./signup.less");
</style>
