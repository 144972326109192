<template>
  <div class="order-details">
    <Brands collection="Publishers" subset="Transaction Inquiries"></Brands>
    <div class="content">
      <div class="toggle-btn">
        <div class="button">
          <a
            :class="{ active: params.activeType == 'pending' }"
            @click="params.activeType = 'pending'"
            >New Inquiries</a
          >
        </div>
        <div class="button">
          <a
            :class="{ active: params.activeType == 'history' }"
            @click="params.activeType = 'history'"
            >Previous Inquiries</a
          >
        </div>
      </div>
      <div class="content-title">
        <div class="items">
          <div class="item" style="width:220px">
            <span class="date-toggle">
              <span :class="{ active: params.search_date == 2 }"
                >Submit Date</span
              >
              <img
                src="@/assets/lost-row-toggle.svg"
                alt
                @click="params.search_date = params.search_date == 2 ? 1 : 2"
              />
              <span :class="{ active: params.search_date == 1 }"
                >Order Date</span
              >
            </span>
            <a-date-picker
              @change="onChangeDate"
              class="date"
              format="L"
              v-model="params.trans_time"
            />
          </div>
          <div class="item" style="width:220px">
            <span>Publisher ID</span>
            <a-input v-model="params.medium_id" />
          </div>
          <div class="item" style="width:220px">
            <span>Order ID</span>
            <a-input v-model="params.oid" />
          </div>
          <div
            class="item"
            style="width:8.5714rem"
            v-if="params.activeType == 'history'"
          >
            <span>Status</span>
            <a-select
              class="select"
              placeholder="select"
              v-model="params.status"
            >
              <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
              <a-select-option :value="0">All</a-select-option>
              <a-select-option :value="3">Approved</a-select-option>
              <a-select-option :value="2">Declined</a-select-option>
            </a-select>
          </div>
          <div class="search-reset item">
            <button @click="search">Search</button>
            <button @click="reset">Reset</button>
          </div>
        </div>
      </div>
      <div class="table-data">
        <a-table
          :columns="columns"
          :data-source="dataLists"
          bordered
          :rowKey="(record, index) => record.id"
          :loading="isLoading"
          :pagination="false"
        >
          <template slot="action" slot-scope="text, record">
            <template v-if="params.activeType == 'pending'">
              <a-button type="primary" ghost @click="checkLostOrder(record)"
                >Take Action</a-button
              >
            </template>
            <template v-else>{{ text }}</template>
          </template>
        </a-table>
        <div class="footer-pagination">
          <a-pagination
            v-model="currentPage"
            :total="totalPage"
            show-less-items
            @change="paginationChange"
          />
        </div>
      </div>
    </div>

    <!-- 丢单明细 -->
    <Modal
      :isVisible="isShowUpdateInquiry"
      title="Update Inquiry"
      @close-popup="hideUpdateInquiry"
      width="700px"
      class="update-inquiry-modal"
      @submit="submitUpdateInquiry"
      :submitText="updateInquiryObj.submitText"
      cancelText="Decline"
      :isShowCancel="isShowUpdateInquiryCancel"
      :isShowSubmit="isShowUpdateInquirySubmit"
      :loading="updateInquiryObj.existOrderLoading"
    >
      <template #content v-if="updateInquiryObj.lostOrder">
        <div class="tips">Pubilsher Entered Information</div>
        <div class="item">
          <span>Publisher:</span>
          <span
            >{{ updateInquiryObj.lostOrder.sitename }} -
            {{ updateInquiryObj.lostOrder.site_id | siteIdToMediumId }}</span
          >
        </div>
        <div class="item">
          <span>Order Time:</span>
          <span>{{ updateInquiryObj.lostOrder.trade_time | formatMDYHI }}</span>
        </div>
        <div class="item">
          <span>Order ID:</span>
          <span>{{ updateInquiryObj.lostOrder.order_id }}</span>
        </div>
        <div class="item">
          <span>Order Amount:</span>
          <span
            >{{ updateInquiryObj.lostOrder.currencySimpleName }}
            {{ updateInquiryObj.lostOrder.order_amount }}</span
          >
        </div>
        <div class="item">
          <span>UID:</span>
          <span>{{ updateInquiryObj.lostOrder.tag }}</span>
        </div>
        <div class="item">
          <span>Additional Comment:</span>
          <div>{{ updateInquiryObj.lostOrder.comment_2 }}</div>
        </div>
        <div class="hr">{{ hrContent }}</div>

        <div class="pb-table" v-if="updateInquiryObj.existOrder">
          <div class="thead">
            <div class="td">Publisher</div>
            <div class="td" style="flex: 150">Order ID</div>
            <div class="td" style="flex: 120">Order Time</div>
            <div class="td" style="flex: 120">Order Amount</div>
            <div class="td" style="flex: 50">Status</div>
          </div>
          <div class="tr">
            <div class="td">{{ updateInquiryObj.existOrder.medium_id }}</div>
            <div class="td" style="flex: 150">
              {{ updateInquiryObj.existOrder.oid }}
            </div>
            <div class="td" style="flex: 120">
              {{ updateInquiryObj.existOrder.trans_time | formatMDYHI }}
            </div>
            <div class="td" style="flex: 120">
              {{ updateInquiryObj.existOrder.ori_currency }}
              {{ updateInquiryObj.existOrder.ori_amount | toThousands(true) }}
            </div>
            <div class="td" style="flex: 50">
              {{ updateInquiryObj.existOrder.statusText }}
            </div>
          </div>
        </div>
      </template>
    </Modal>

    <!-- 拒绝原因 -->
    <Modal
      :isVisible="isShowDeclineInquiry"
      title="Decline Inquiry"
      @close-popup="isShowDeclineInquiry = false"
      width="430px"
      class="decline-inquiry-modal"
      @submit="submitDeclineInquiry"
      submit-text="Decline"
      btn-align="right"
    >
      <template #content>
        <h4>Select and enter your reason</h4>
        <a-select
          v-model="declineObj.reasonIdx"
          placeholder="Please select a reason"
          style="width: 100%;"
        >
          <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />

          <a-select-option value="Order exist">Order exist</a-select-option>
          <a-select-option value="Order cancelled/returned"
            >Order cancelled/returned</a-select-option
          >
          <a-select-option value="Attributed to Other Channel"
            >Attributed to Other Channel</a-select-option
          >
          <a-select-option value="Order number doesn't exist"
            >Order number doesn't exist</a-select-option
          >
          <a-select-option value="Order number pattern is incorrect"
            >Order number pattern is incorrect</a-select-option
          >
          <a-select-option value="Policy violation, order invalid"
            >Policy violation, order invalid</a-select-option
          >
          <a-select-option value="Suspicious Activity, order invalid"
            >Suspicious Activity, order invalid</a-select-option
          >
          <a-select-option value="custom">Other</a-select-option>
        </a-select>

        <a-input
          v-model="declineObj.reasonText"
          v-if="declineObj.reasonIdx == 'custom'"
          style="margin-top: 12px"
        ></a-input>
      </template>
    </Modal>

    <!-- 提交新订单 -->
    <Modal
      :isVisible="isShowManualOrder"
      title="Update Inquiry"
      @close-popup="
        isShowManualOrder = false;
        isShowUpdateInquiry = false;
      "
      width="700px"
      class="update-inquiry-modal"
      @submit="submitManualOrder"
      submitText="Submit Order"
    >
      <template #content v-if="manulOrderObj.oid">
        <div class="tips">Pubilsher Entered Information</div>
        <div class="item">
          <span>Publisher:</span>
          <span>{{ manulOrderObj.medium_id }}</span>
        </div>
        <div class="item">
          <span>Order Time:</span>
          <span>{{ updateInquiryObj.lostOrder.trade_time | formatMDYHI }}</span>
        </div>
        <div class="item">
          <span>Order ID:</span>
          <span>
            {{ manulOrderObj.oid }}
            <a-tooltip
              placement="bottom"
              :overlayStyle="{ width: '410px', maxWidth: '410px' }"
              v-if="manulOrderObj.oid.includes('_manual')"
            >
              <template slot="title">
                <div style="padding: 3px">
                  For orders that are rejected for commission, after the
                  publisher submits the application and the brand agrees, the
                  re-created order ID will add "_manual" to the ID of the
                  original rejected order.
                </div>
              </template>

              <a-button size="small" type="link">
                <img src="@/assets/icon-question.svg" style="margin-top:-3px" />
              </a-button>
            </a-tooltip>
          </span>
        </div>
        <div class="item">
          <span>Order Amount:</span>
          <span>{{ manulOrderObj.currency }} {{ manulOrderObj.amount }}</span>
        </div>
        <div class="item">
          <span>UID:</span>
          <span>{{ updateInquiryObj.lostOrder.tag }}</span>
        </div>
        <div class="item">
          <span>Additional Comment:</span>
          <div>{{ updateInquiryObj.lostOrder.comment_2 }}</div>
        </div>
        <div class="hr empty"></div>
        <div class="item">
          <span>Commission Type:</span>
          <a-radio-group v-model="manulOrderObj.type" style="width:fit-content">
            <a-radio value="percent">Percentage</a-radio>
            <a-radio value="flatrate">Flat Rate</a-radio>
          </a-radio-group>
        </div>
        <div class="item" :class="`${manulOrderObj.type}`">
          <span
            >{{
              manulOrderObj.type == "percent" ? "Percentage" : "Flat Rate"
            }}:</span
          >
          <span>{{ manulOrderObj.currency }}</span>
          <a-input
            type="text"
            v-model="manulOrderObj.commission"
            size="large"
            style="width:200px;"
          />
          <span>%</span>
        </div>
        <div class="item">
          <span>Comment:</span>
          <a-input
            v-model.trim="manulOrderObj.remark"
            size="large"
            style="width:500px"
          />
        </div>
      </template>
    </Modal>

    <!-- 修改订单 -->
    <Modal
      :isVisible="isShowEditOrder"
      title="Order Edit"
      submitText="Update Order"
      @close-popup="isShowEditOrder = false"
      width="528px"
      class="edit-order-modal"
      @submit="editOrderSubmit"
    >
      <template #content>
        <h4>Original Order Details</h4>
        <div class="item">
          <span>Order ID:</span>
          <span>{{ editShowData.oid }}</span>
        </div>
        <div class="item">
          <span>Order Amount:</span>
          <span
            >{{ editShowData.currency.simple_name }}
            {{ editShowData.ori_amount }}</span
          >
        </div>
        <div class="item">
          <span>Order Date:</span>
          <span>{{ editShowData.created_time | formatMDYHI }}</span>
        </div>
        <div class="item">
          <span>Commission:</span>
          <span
            >{{ editShowData.currency.simple_name }}
            {{ editShowData.ori_commission }}</span
          >
        </div>
        <div class="hr empty"></div>
        <div class="item">
          <span>Edit Status:</span>
          <a-select v-model="editStatusVoid" style="width:100px">
            <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
            <a-select-option value="new">New</a-select-option>
            <a-select-option value="void">Void</a-select-option>
            <a-select-option value="effective">Locked</a-select-option>
          </a-select>
        </div>
        <div class="item" v-if="editStatusVoid == 'void'">
          <span>Void Reason:</span>
          <a-select
            v-model="voidReasonSelect"
            style="width: 335px;"
            placeholder="Choose a reason"
          >
            <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
            <a-select-option value="Returned Merchandise"
              >Returned Merchandise</a-select-option
            >
            <a-select-option value="Violation of Brand Publisher Policy"
              >Violation of Brand Publisher Policy</a-select-option
            >
            <a-select-option value="Fraudulent Order"
              >Fraudulent Order</a-select-option
            >
            <a-select-option value="Repeat Order">Repeat Order</a-select-option>
            <a-select-option value="Test Order">Test Order</a-select-option>
            <a-select-option value="Cancelled Order"
              >Cancelled Order</a-select-option
            >
            <a-select-option value="Other">Other</a-select-option>
          </a-select>
        </div>
        <div class="item" v-if="editStatusVoid != 'void'">
          <span>Commission Type:</span>
          <a-radio-group
            name="radioGroup"
            v-model="radioCommissionType"
            style="width:fit-content"
          >
            <a-radio :value="1">Percentage</a-radio>
            <a-radio :value="2">Flat Rate</a-radio>
          </a-radio-group>
        </div>
        <div class="item" v-if="editStatusVoid != 'void'">
          <span>Order Amount:</span>
          <a-input
            type="text"
            v-model="newSaleAmount"
            :addon-before="editShowData.currency.simple_name"
            style="width:150px"
          />
        </div>
        <div class="item" v-if="editStatusVoid != 'void'">
          <span
            >{{ radioCommissionType == 1 ? "Percentage" : "Flat Rate" }}:</span
          >
          <a-input
            type="text"
            :addon-before="
              radioCommissionType == 1
                ? null
                : editShowData.currency.simple_name
            "
            :addon-after="radioCommissionType == 1 ? '%' : null"
            v-model="newCommissionPercentage"
            style="width:150px"
          />
        </div>
      </template>
    </Modal>

    <Loading v-if="isLoadingModal"></Loading>
  </div>
</template>

<script>
import Brands from "./../../components/Brands.vue";
import Modal from "./../../components/Modal.vue";
import axios from "@/utils/request";

export default {
  components: {
    Brands,
    Modal,
  },
  data() {
    let columns = [
      { title: "Inquiry ID", dataIndex: "id", width: 90, align: "center" },
      {
        title: "Publisher ID",
        dataIndex: "site_id",
        customRender: (text) => "PB" + text.padStart(8, "0"),
        width: 110,
        align: "center",
      },
      { title: "Type", dataIndex: "reasonText", align: "center" },
      {
        title: "Date",
        dataIndex: "create_time",
        customRender: (text) => this.formatMDY(text),
        align: "center",
      },
      { title: "Order ID", dataIndex: "order_id", align: "center" },
      {
        title: "Order Date",
        dataIndex: "trade_time",
        customRender: (text) => this.formatMDY(text),
        align: "center",
      },
      {
        title: "Order Amount",
        dataIndex: "order_amount",
        customRender: (text, record) =>
          record.currencySimpleName + " " + this.toThousands(text, true),
        align: "right",
      },
      { title: "UID", dataIndex: "tag", align: "center" },
      {
        title: "",
        dataIndex: "handleStatusText",
        align: "center",
        scopedSlots: { customRender: "action" },
        width: 80,
      },
    ];
    return {
      isLoadingModal: false,
      isLoading: false,

      dataLists: [],
      columns,

      params: {
        activeType: "pending",
        search_date: 2,
        trans_time: null,
        medium_id: null,
        oid: null,
        status: 0,
      },
      hrContent: "",

      // 编辑时展示的数据
      currentPage: 1,
      page_size: 10,
      totalPage: 0,

      mediumLists: [],
      isShowManualOrder: false,
      manulOrderObj: {
        medium_id: null,
        oid: null,
        type: "flatrate",
        currency: "USD",
        amount: null,
        commission: null,
        remark: null,
      },

      isShowUpdateInquiry: false,
      isShowUpdateInquiryCancel: false,
      isShowUpdateInquirySubmit: true,
      updateInquiryObj: {
        lostOrder: null,
        existOrder: false,
        existOrderLoading: false,
        submitText: "",
        norm: null,
      },
      isShowDeclineInquiry: false,
      declineObj: {
        reasonIdx: undefined,
        reasonText: undefined,
      },

      editShowData: {},
      newSaleAmount: "",
      newCommissionPercentage: "",
      isShowEditOrder: false,
      editStatusVoid: undefined,
      radioCommissionType: 1,
      voidReasonSelect: undefined,
    };
  },
  computed: {
    currenciesSimpleNameMap() {
      let map = {};
      if (
        this.$store.state.commonConfig &&
        this.$store.state.commonConfig.currencies
      ) {
        this.$store.state.commonConfig.currencies.forEach((item) => {
          map[item.simple_name] = item;
        });
      }
      return map;
    },
  },
  watch: {
    "params.activeType"(newVal, oldVal) {
      this.$set(this.columns, 8, {
        title: newVal == "history" ? "Status" : "",
        dataIndex: "handleStatusText",
        align: "center",
        scopedSlots: { customRender: "action" },
        width: 80,
      });
      if (newVal == "pending") {
        this.params.status = 0;
      }
      this.search();
    },
  },
  created() {
    this._getOrderLists();
    this._getMediumLists();
  },
  methods: {
    // 编辑提交
    editOrderSubmit() {
      this.isLoadingModal = true;
      let type = this.radioCommissionType == 1 ? "percent" : "flatrate";
      let edit = {
        id: this.editShowData.id,
        amount: this.newSaleAmount,
        commission_value: this.newCommissionPercentage,
        commission_type: type,
        status: this.editStatusVoid,
        remark: this.voidReasonSelect,
        inquiry_id: this.updateInquiryObj.lostOrder.id,
      };
      axios
        .post("/api_admin.php?a=transaction/order_edit", {
          ...edit,
        })
        .then((res) => {
          this.isLoadingModal = false;
          if (res.code == 0) {
            this.$message.success("Edited");
            this.isShowUpdateInquiry = false;
            this.isShowEditOrder = false;
            this._getOrderLists();
          } else {
            this.$message.error(
              res.msg || "Sorry, server is busy. please try later."
            );
          }
        })
        .catch((e) => {
          this.isLoadingModal = false;
        });
    },
    // 拒绝
    submitDeclineInquiry() {
      // Decline inquiry
      if (!this.declineObj.reasonIdx) {
        this.$message.error("Please select and enter your reason");
        return;
      }
      let reason =
        this.declineObj.reasonIdx == "custom"
          ? this.declineObj.reasonText
          : this.declineObj.reasonIdx;
      let inquiry_id = this.updateInquiryObj.lostOrder.id;

      this.isLoadingModal = true;
      axios
        .post("/api_admin.php?a=transaction/decline_lost", {
          inquiry_id,
          reason,
        })
        .then((res) => {
          this.isLoadingModal = false;
          if (res.code === 0) {
            this.$message.success("Declined");
            this.isShowUpdateInquiry = false;
            this.isShowDeclineInquiry = false;
            this._getOrderLists();
          } else {
            this.$message.error(
              res.msg || "Sorry, server is busy. please try later."
            );
          }
        })
        .catch((e) => {
          this.$message.error("Decline fail");
          this.isLoadingModal = false;
        });
    },
    hideUpdateInquiry(type) {
      if (type == "x") {
        this.isShowUpdateInquiry = false;
        return;
      }
      this.declineObj = {
        reasonIdx: undefined,
        reasonText: undefined,
      };
      this.isShowDeclineInquiry = true;
    },
    submitUpdateInquiry() {
      if (
        this.updateInquiryObj.submitText == "Add" ||
        this.updateInquiryObj.submitText == "Submit Order"
      ) {
        this.manulOrderObj = {
          medium_id: this.siteIdToMediumId(
            this.updateInquiryObj.lostOrder.site_id
          ),
          oid:
            this.updateInquiryObj.lostOrder.order_id +
            (this.updateInquiryObj.submitText == "Add" ? "" : "_manual"),
          type: "flatrate",
          currency: this.updateInquiryObj.lostOrder.currencySimpleName,
          amount: this.updateInquiryObj.lostOrder.order_amount,
          commission: this.updateInquiryObj.lostOrder.ori_commission || 0,
          remark: null,
          trans_time: this.updateInquiryObj.lostOrder.trans_time,
        };

        if (
          this.updateInquiryObj.norm &&
          this.updateInquiryObj.norm.aff_ba_unit
        ) {
          this.manulOrderObj.commission = parseFloat(
            this.updateInquiryObj.norm.aff_ba
          ).toFixed(2);
          if (this.updateInquiryObj.norm.aff_ba_unit != "%") {
            this.manulOrderObj.type = "flatrate";
          }
        }
        this.isShowManualOrder = true;
      } else if (this.updateInquiryObj.submitText == "Update Order") {
        this.editShowData = this.updateInquiryObj.existOrder;
        this.radioCommissionType = 2;
        this.newCommissionPercentage = this.updateInquiryObj.existOrder.ori_commission;
        this.editShowData.currency = this.currenciesSimpleNameMap[
          this.editShowData.ori_currency
        ];
        this.editStatusVoid = this.updateInquiryObj.existOrder.status;
        this.newSaleAmount = this.updateInquiryObj.existOrder.ori_amount;
        this.isShowEditOrder = true;
      } else if (this.updateInquiryObj.submitText == "Reply to publisher") {
        this.declineObj = {
          reasonIdx: undefined,
          reasonText: undefined,
        };
        this.isShowDeclineInquiry = true;
      }
      // Reply to publisher
      // Accept
    },
    submitManualOrder() {
      let amount = parseFloat(this.manulOrderObj.amount);
      if (isNaN(amount) || amount == 0) {
        this.$message.error("Please input valid amount number");
        return;
      }
      let commission = parseFloat(this.manulOrderObj.commission);
      if (isNaN(commission)) {
        this.$message.error("Please input valid commission number");
        return;
      }
      if (!this.manulOrderObj.remark || this.manulOrderObj.remark.length == 0) {
        this.$message.error("Please input comment");
        return;
      }
      if (this.manulOrderObj.medium_id === null) {
        this.$message.error("Please select Publisher");
        return;
      }
      if (!this.manulOrderObj.oid || this.manulOrderObj.oid.length == 0) {
        this.$message.error("Please input Order ID");
        return;
      }
      this.isLoadingModal = true;
      axios
        .post("/api_admin.php?a=transaction/manual_order", {
          ...this.manulOrderObj,
          inquiry_id: this.updateInquiryObj.lostOrder.id,
        })
        .then((res) => {
          this.isLoadingModal = false;
          if (res.code === 0) {
            this.$message.success("Edited");
            this.isShowUpdateInquiry = false;
            this.isShowManualOrder = false;
            this._getOrderLists();
            this.$message.success("Submit Manual Transaction Success");
          } else {
            this.$message.error(
              "Submit Manual Transaction Failed, " + res.message
            );
          }
        })
        .catch((e) => {
          this.$message.error(
            "Submit Manual Transaction Failed, please try later"
          );
          this.isLoadingModal = false;
        });
    },
    hideManualOrder() {
      this.manulOrderObj = {
        medium_id: null,
        oid: null,
        type: "percent",
        currency: "USD",
        amount: null,
        commission: null,
        remark: null,
      };
      this.isShowManualOrder = false;
    },
    onChangeDate(date, dateString) {
      console.log(dateString, date);
      this.trans_time = date;
    },
    // 获取订单
    _getOrderLists() {
      this.isLoading = true;
      axios
        .get("/api_admin.php?a=transaction/lost", {
          params: {
            ...this.params,
            type: "pending",
            trans_time:
              this.params.trans_time &&
              this.params.trans_time
                .hour(0)
                .minute(0)
                .second(0)
                .unix(),
            page_num: this.currentPage,
            page_size: this.page_size,
          },
        })
        .then((res) => {
          if (res.code === 0) {
            this.isLoading = false;
            this.dataLists = res.data.data.map((item) => ({
              ...item,
              handleStatusText: res.data.handleStatusList[item.handle_status][
                "name"
              ].replace(" Order", ""),
              reasonText: res.data.reasonList[item.comment]["name"],
              sitename: res.data.sites[item.site_id],
            }));
            this.totalPage = parseInt(res.data.total_size);
          } else {
            this.$message.error(
              res.msg || "Sorry, server is busy. please try later."
            );
          }
        });
    },
    // 搜索
    search() {
      this.currentPage = 1;
      this._getOrderLists();
    },
    // 重置
    reset() {
      this.params = {
        trans_time: null,
        medium_id: null,
        status: null,
        oid: null,
      };
      this.currentPage = 1;
      this._getOrderLists();
    },
    // 切换下一页
    paginationChange(page, pageSize) {
      this.currentPage = page;
      this._getOrderLists();
    },

    // /api_admin.php?a=medium/list
    // 获取媒体
    _getMediumLists() {
      axios
        .get("/api_admin.php?a=medium/list", {
          params: {
            status: ["adopt"],
            simple: 1,
          },
        })
        .then((res) => {
          if (res.code === 0) {
            this.mediumLists = res.data.data;
          } else {
            this.$message.error(
              res.msg || "Sorry, server is busy. please try later."
            );
          }
        });
    },
    checkLostOrder(lostOrder) {
      this.updateInquiryObj = {
        lostOrder: null,
        existOrder: false,
        existOrderLoading: true,
        submitText: "",
      };
      this.isShowUpdateInquiryCancel = false;
      this.isShowUpdateInquirySubmit = false;
      this.isShowUpdateInquiry = true;
      this.updateInquiryObj.lostOrder = Object.assign({}, lostOrder);
      axios
        .get("/api_admin.php?a=transaction/lost_check", {
          params: {
            order_id: lostOrder.order_id,
            site_id: lostOrder.site_id,
          },
        })
        .then((res) => {
          this.updateInquiryObj.existOrderLoading = false;
          this.updateInquiryObj.existOrder = res.data.order
            ? {
                ...res.data.order,
                statusText: {
                  new: "New",
                  effective: "Locked",
                  void: "Void",
                }[res.data.order.status],
              }
            : null;
          this.updateInquiryObj.norm = res.data.norm;

          // ['id' => 1, 'name' => getlang('已付订单未跟踪到')],
          // ['id' => 2, 'name' => getlang('有效订单被误判为无效')],
          // ['id' => 3, 'name' => getlang('有效订单额与实际不符')],
          if (lostOrder.comment == "3" || lostOrder.comment == "2") {
            this.isShowUpdateInquirySubmit = true;
            if (!this.updateInquiryObj.existOrder) {
              this.isShowUpdateInquiryCancel = false;
              this.updateInquiryObj.submitText = "Reply to publisher";
            } else if (this.updateInquiryObj.existOrder.status == "new") {
              this.isShowUpdateInquiryCancel = true;
              this.updateInquiryObj.submitText = "Update Order";
            } else {
              this.isShowUpdateInquiryCancel = true;
              this.updateInquiryObj.submitText = "Submit Order";
            }
          } else {
            this.isShowUpdateInquiryCancel = true;
            this.isShowUpdateInquirySubmit = !this.updateInquiryObj.existOrder;
            this.updateInquiryObj.submitText = this.updateInquiryObj.existOrder
              ? "Accept"
              : "Add";
          }
          if (this.updateInquiryObj.existOrder) {
            this.hrContent =
              "According to the Order ID provided by the publisher, the following order has been found for you in the database.";
          } else {
            this.hrContent =
              "According to the order information submitted by the publisher, we did not find the corresponding order in the database. Do you want to add a new order?";
          }
        })
        .catch((e) => {
          this.updateInquiryObj.existOrderLoading = false;
          this.isShowUpdateInquiry = false;
          this.$message.error("Network busy, please try later.");
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import "~/src/dtc.less";
.content {
  width: 100%;

  .toggle-btn {
    margin-bottom: 32px;
    .button {
      padding: 0px;
      border: none;
      margin-right: 18px;
      border-radius: 6px;
      display: inline-block;
      a {
        display: inline-block;
        padding: 9px 22px;
        background: rgba(0, 0, 0, 0.04);
        border-radius: 6px;
        font-size: 1.1429rem;
        color: #999999;
        line-height: 20px;
      }
      .active {
        color: #2898ff;
        background: #f2f9ff;
      }
    }
  }
  .content-title {
    width: 100%;
    padding: 20px 44px 30px 30px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    .items {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      .item {
        .date-toggle {
          font-size: 1rem;
          height: 30px;
          display: flex;
          align-items: center;
          font-family: dtc-bold;

          span {
            color: #999999;
            &.active {
              color: #333;
            }
          }
          img {
            cursor: pointer;
            margin: 0 4px;
          }
        }

        width: 262px;
        margin-right: 26px;
        &:last-child {
          margin-right: 0px;
        }
        span {
          display: block;
          font-size: 1rem;
          font-family: "dtc-bold";
          color: #333333;
          line-height: 30px;
          margin-bottom: 2px;
          &.ant-calendar-picker {
            font-family: inherit;
          }
        }
        input {
          width: 100%;
          height: 2.8571rem;
          background: #ffffff;
          border-radius: 6px;
          border: 1px solid #e6e6e6;
        }
        .select {
          width: 100%;
          height: 2.8571rem;
          /deep/ .ant-select-selection--single {
            height: 2.8571rem;
            border-radius: 6px;
            background: #fafafa;
          }
          /deep/ .ant-select-selection__rendered {
            line-height: 2.8571rem;
          }
          .icon-down {
            color: #333333;
            font-size: 1rem;
          }
        }
        .date {
          width: 100%;
          height: 2.8571rem;
          /deep/ .ant-input {
            height: 2.8571rem;
            border-radius: 6px;
            background: #fafafa;
          }
        }
      }
    }
    .search-reset {
      // text-align: right;
      margin: auto;

      &.item {
        width: fit-content;
        margin-top: 34px;
      }
      button {
        padding: 9px 32px;
        border-radius: 6px;
        cursor: pointer;
        border: none;
        &:first-child {
          background: #2898ff;
          font-size: 1.1429rem;
          color: #ffffff;
          line-height: 20px;
          margin-right: 20px;
        }
        &:last-child {
          border: 1px solid #dddddd;
          font-size: 1.1429rem;
          color: #333333;
          line-height: 20px;
        }
      }
    }
  }
  .table-data {
    margin-top: 36px;
    margin-bottom: 76px;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;

    /deep/ .ant-table table {
      border-radius: 6px;
      border: 1px solid #e8e8e8;
      tr:last-child td {
        border-bottom: none !important;
        &:first-child {
          border-bottom-left-radius: 5px;
        }
        &:last-child {
          border-bottom-right-radius: 5px;
        }
      }
    }
    .footer-pagination {
      margin-top: 20px;
      text-align: right;
    }
  }
  .manual-order-modal {
    &.modal /deep/ .modal-content > .items {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      margin-top: 0;
    }
    h4 {
      font-size: 1.1429rem;
      font-family: "dtc-bold";
      color: #333333;
      line-height: 30px;
      margin-bottom: 10px;
      &:first-child {
        margin-top: 0;
      }
      margin-top: 16px;
    }
    .commision-value {
      /deep/ .ant-input-group-addon {
        width: 60px;
      }
    }
    .ant-input.ant-input-lg {
      height: 2.8571rem !important;
      min-height: 2.8571rem;
    }
  }
  .update-inquiry-modal {
    &.modal /deep/ .modal-content > .items {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      margin-top: 0;
    }
    .item,
    .tips {
      box-sizing: border-box;
      padding: 6px 0;
      line-height: 18px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      span:first-child {
        padding: 6px 0;
        min-width: 150px;
        font-family: dtc-bold;
      }
      > div {
        width: 100%;
      }
    }
    .tips {
      color: @text-color-secondary;
    }

    .hr {
      border-top: 1px solid #eee;
      padding-top: 20px;
      margin-top: 15px;
      margin-bottom: 15px;
      font-size: 1.1429rem;
      line-height: 18px;
      &.empty {
        padding: 0;
      }
    }
  }

  .edit-order-modal {
    &.modal /deep/ .modal-content > .items {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      margin-top: 0;
    }
    .item,
    .tips {
      box-sizing: border-box;
      padding: 6px 0;
      line-height: 18px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      span:first-child {
        padding: 6px 0;
        min-width: 150px;
        font-family: dtc-bold;
      }
      > div {
        width: 100%;
      }
    }
    .tips {
      color: @text-color-secondary;
    }

    .hr {
      border-top: 1px solid #eee;
      padding-top: 20px;
      margin-top: 15px;
      margin-bottom: 15px;
      font-size: 1.1429rem;
      line-height: 18px;
      &.empty {
        padding: 0;
      }
    }
  }
}
.item {
  &.percent {
    position: relative;
    span:nth-child(2) {
      position: absolute;
      display: block;
      height: 2.8571rem;
      width: 2.8571rem;
      line-height: 2.8571rem;
      left: 150px;
      box-sizing: border-box;
      text-align: center;
      border-right: 1px solid #eee;
      z-index: 2;
    }
    input {
      padding-left: 50px;
    }
    span:nth-child(4) {
      display: none;
    }
  }
  &.flatrate {
    position: relative;
    span:nth-child(2) {
      display: none;
    }
    input {
      padding-right: 50px;
    }
    span:nth-child(4) {
      position: relative;
      display: block;
      height: 2.8571rem;
      width: 2.8571rem;
      line-height: 2.8571rem;
      left: -2.8571rem;
      box-sizing: border-box;
      text-align: center;
      border-left: 1px solid #eee;
    }
  }
}
</style>
