<template>
  <div v-if="item.from_type == 'admin'" class="from">Partnerboost</div>
  <div v-else-if="item.from_id == mid" class="from">My</div>
  <a-popover
    v-else
    trigger="click"
    overlayClassName="floating-medium-card"
    placement="right"
    :visible="item.fromInfoDisplay"
    @visibleChange="
      (v) => {
        (item.fromInfoDisplay = v), $emit('update', item);
      }
    "
  >
    <template #content>
      <a-spin :spinning="item.fromInfoLoading">
        <medium-card
          @openChat="(item.fromInfoDisplay = false), $emit('update', item)"
          :check-able="false"
          :item="item.fromInfo"
        ></medium-card>
      </a-spin>
    </template>
    <div
      class="from canClick"
      @click="$router.push('/publisher/detail/' + item.from_id)"
    >
      {{ item.from_id }}
    </div>
  </a-popover>
</template>

<script>
import MediumCard from "@/views/Publishers/components/MediumCard.vue";

const defaultLogo = require("@/assets/default.png");
import axios from "@/utils/request";
export default {
  components: { MediumCard },
  props: ["item"],
  computed: {
    mid() {
      return this.$store.state.accountInfo.merchant_info.m_id;
    },
  },
  methods: {
    showMediumCard(item) {
      item.fromInfoDisplay = true;
      this.$emit("update", item);
      if (item.fromInfo) {
        return;
      }
      item.fromInfoLoading = true;
      axios
        .get("/api_admin.php?a=medium/detail", {
          params: { medium_id: item.from_id },
        })
        .then((res) => {
          item.fromInfoLoading = false;
          if (res.code === 0) {
            if (!res.data) {
              item.fromInfoDisplay = false;
              this.$emit("update", item);
              this.$message.error("Target is unable to show.");
              return;
            }
            item.fromInfoDisplay = true;
            let flag;
            try {
              flag = require("@/assets/flag/" + res.data.country + ".png");
            } catch (e) {
              flag = undefined;
            }
            item.fromInfo = {
              ...res.data,
              logo: res.data.logo
                ? this.APP_CONFIG.OSS_HOST + res.data.logo
                : defaultLogo,
              flag,
              aff_ba: null,
              applied: null,
            };
            this.$emit("update", item);
          } else {
            item.fromInfoDisplay = false;
            this.$emit("update", item);
            this.$message.error(
              res.msg || "Sorry, server is busy. please try later."
            );
          }
        });
    },
  },
};
</script>

<style></style>
