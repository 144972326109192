<template>
  <div class="child-details">
    <div class="child-details-column">
      <!-- Timezone -->
      <div class="account-bg">
        <div class="flex-space-between-center">
          <div class="account-title">Time Zone</div>
          <div class="account-edit-btn" @click="showTimezonePopup">Edit</div>
        </div>
        <div class="item-bold">
          {{ GMT_LIST[accountDetails.merchant.timezone] }}
        </div>
      </div>
      <!-- Commission -->
      <div class="account-bg">
        <div class="flex-space-between-center">
          <div class="account-title">Default Commission</div>
          <div
            v-if="!defaultCommissionIsEdit"
            class="account-edit-btn"
            @click="handleEditCommission"
          >
            Edit
          </div>
        </div>
        <template v-if="defaultCommissionIsEdit">
          <a-input class="setting-form-item" v-model="commissionValue">
            <span slot="suffix" v-if="commissionTypeValue == '%'">%</span>
            <span slot="prefix" v-if="commissionTypeValue == 'ORIC'">{{
              accountDetails.merchant.currency.sign
            }}</span>
          </a-input>
          <div class="setting-edit-btn">
            <a-radio-group
              :value="commissionTypeValue"
              @change="handleCommissionTypeChange"
              style="margin-right: auto"
              size="small"
              button-style="solid"
            >
              <a-radio-button value="%">
                Percentage
              </a-radio-button>
              <a-radio-button value="ORIC">
                Fixed Amount
              </a-radio-button>
            </a-radio-group>

            <div @click="defaultCommissionCancel">Cancel</div>
            <a-button
              @click="defaultCommissionSave"
              type="primary"
              size="small"
              class="__btn"
              >Save
            </a-button>
          </div>
        </template>
        <div v-else class="item-bold">{{ defaultCommission }}</div>
      </div>

      <!-- TrackingGap -->
      <div class="account-bg">
        <div class="flex-space-between-center">
          <div class="account-title">Tracking Gap</div>
          <div class="account-edit-btn" @click="showTackingGapPopup">Edit</div>
        </div>
        <div class="item-bold">{{ trackingGap }} Days</div>
      </div>
      <!-- auto approved permission -->
      <div class="account-bg">
        <div class="flex-space-between-center">
          <div class="account-title">Auto Approved Permission</div>
        </div>
        <div class="approved-types">
          <div :class="'approved-type-' + accountDetails.merchant.verify_type">
            {{ autoApproved ? "On" : "Off" }}
          </div>
          <a-switch
            :checked="autoApproved"
            @change="handleAutoApproved"
            :loading="isAutoApprovalLoading"
          />
          <span class="setting-item-tips">{{
            accountDetails.merchant.verify_type_modified_time | formatMDYHI
          }}</span>
        </div>
      </div>
      <!-- program status -->
      <div class="account-bg">
        <div class="flex-space-between-center">
          <div class="account-title">Program Status</div>
        </div>
        <div class="program-statuss">
          <div
            :class="'program-status-' + accountDetails.merchant.program_status"
          >
            {{ programStatus ? "Online" : "Offline" }}
          </div>
          <a-switch
            :checked="programStatus"
            @change="handleProgramStatus"
            :loading="isProgramStatusLoading"
          />
        </div>
      </div>
    </div>
    <div class="child-details-column">
      <!-- Currnecy -->
      <div class="account-bg">
        <div class="flex-space-between-center">
          <div class="account-title">Currency</div>
          <div class="account-edit-btn" @click="currencyIsEdit = true">
            Edit
          </div>
        </div>
        <template v-if="currencyIsEdit">
          <SignUpSelect
            v-model="currencyValue"
            :options="
              $store.getters.currencies.map((item) => ({
                value: item.id,
                label: item.simple_name,
              }))
            "
            class="setting-form-item"
          />
          <div class="setting-edit-btn">
            <div @click="currencyCancel">Cancel</div>
            <a-button
              @click="currencySave"
              type="primary"
              size="small"
              class="__btn"
              >Save</a-button
            >
          </div>
        </template>
        <div class="item-bold" v-else>
          {{
            accountDetails.merchant.currency
              ? accountDetails.merchant.currency.simple_name
              : ""
          }}
        </div>
      </div>
      <!-- Locking Period -->
      <div class="account-bg">
        <div class="flex-space-between-center">
          <div class="account-title">Lock Period</div>
          <span
            v-if="accountDetails.merchant.preview_lock_date"
            class="lock-date-updating"
          >
            <img src="@/assets/lock-date-updating.svg" alt="" /> Updating
          </span>
          <div class="account-edit-btn" @click="showLockDatePopup">Edit</div>
        </div>
        <div class="item-bold flex-space-between">
          {{ lockDate }} Days
          <!-- {{ accountDetails.merchant.preview_lock_date }} Days  -->
          <span class="setting-item-tips"
            >after end of the month which sales generated
          </span>
        </div>
      </div>
      <!-- Tracking Step -->
      <div class="account-bg">
        <div class="flex-start-center">
          <div class="account-title">Tracking Step</div>
          <span :class="'trackingStepStatus ' + trackingStepStatus">{{
            trackingStepStatus
          }}</span>
        </div>
        <div class="tracking-step-actions" :class="trackingStepMode">
          <div
            class="tracking-step-action-devinstall"
            @click="handleTrackingStepClick('devinstall')"
          >
            Developer Install
          </div>
          <div
            class="tracking-step-action-api"
            @click="handleTrackingStepClick('api')"
          >
            Connect via API
          </div>
          <div
            class="tracking-step-action-shopify"
            @click="handleTrackingStepClick('shopify')"
          >
            <img src="@/assets/shopify.svg" alt="" />
            Shopify
          </div>
          <div
            class="tracking-step-action-publicdoc"
            @click="handleTrackingStepClick('publicdoc')"
          >
            Universal Installation Guide
          </div>
        </div>
      </div>
      <!-- Payout Schedule -->
      <div class="account-bg">
        <div class="flex-space-between-center">
          <div class="account-title">Payout Schedule</div>
        </div>
        <div class="item-bold">30 Days</div>
        <div class="payout-shadow">
          <img src="@/assets/icon-lock.svg" alt="" />
          <div>Not Avaliable to your Account</div>
          <div @click="goContact">Contact Us</div>
        </div>
      </div>
    </div>

    <!-- Pricing -->
    <div
      class="pricing-shadow"
      v-show="isShowPricing > 0"
      @click="isShowPricing = 0"
    >
      <div class="pricing-inside" v-show="isShowPricing == 1">
        <div class="pricing-enterprise" @click.stop="() => {}">
          <div>Contact Us Enterprise</div>
          <div>
            For enterprises who needs more functionalities and supports.
          </div>
          <div class="pricing-options">
            <span>Flexible Payment Plan</span>
            <span>Influencer Search/Discovery</span>
            <span>Influencer Lifecycle Management</span>
            <span>Campaign Management</span>
            <span>Customer Success Team</span>
            <span>Product/Gifting Tools</span>
            <span>Guaranteed Exposure</span>
          </div>
          <div class="pricing-btn" @click="submitPricing">Contact Us</div>
        </div>

        <div class="pricing-standard" @click.stop="() => {}">
          <div>MOST POPULAR</div>
          <div>was <span>$1150</span></div>
          <div><span>$990</span>One time payment</div>
          <div>Standard</div>
          <div>
            For most businesses that want to start influe and affiliate
            marketing
          </div>
          <div class="pricing-options">
            <span>1 Yesr of Subscription</span>
            <span>$100 Free Credit</span>
            <span>Includes $550 setup fee</span>
            <span>20% Network Fee</span>
            <span>Influencer Campaign</span>
            <span>Affiliate Program Managment</span>
            <span>Publisher Discovery</span>
            <span>Dedicated Customer Support</span>
            <span>New Launch Package</span>
          </div>
          <div class="pricing-btn" @click="isShowPricing = 2">Choose Plan</div>
        </div>
      </div>
      <div
        class="pricing-payment-confirm"
        v-show="isShowPricing == 2"
        @click.stop="() => {}"
      >
        <div>Payment confirmation</div>
        <img src="@/assets/pricing-payment-bg.svg" alt="" />
        <div class="pricing-payment-price">
          <span>Subtotal</span>
          <span>$990</span>
        </div>
        <div class="pricing-payment-price">
          <span>Tax</span>
          <span>$0.00</span>
        </div>
        <div class="pricing-payment-price">
          <span>Total</span>
          <span>$990</span>
        </div>
        <div class="pricing-payment-btn" @click="isShowPricing = 3">
          Confirm
        </div>
      </div>
      <div
        class="pricing-payment-submit"
        v-show="isShowPricing == 3"
        @click.stop="() => {}"
      >
        <div>Payment confirmation</div>
        <div
          class="pricing-payment-item"
          :class="{ active: pricingPaymentActive == 'paypal' }"
          @click="
            pricingPaymentActive = 'paypal';
            submitPricingText = 'Confirm & Pay $990.00';
          "
        >
          <img src="@/assets/pricing-payment-paypal.svg" alt="" />
          <span>
            Paypal
          </span>
        </div>
        <div
          class="pricing-payment-item"
          :class="{ active: pricingPaymentActive == 'bank' }"
          @click="
            pricingPaymentActive = 'bank';
            submitPricingText = 'Contact Us';
          "
        >
          <img src="@/assets/pricing-payment-bank.svg" alt="" />
          <span>
            Bank
          </span>
        </div>
        <div class="pricing-payment-btn" @click="submitPricing">
          {{ submitPricingText }}
        </div>
      </div>
    </div>

    <!-- 编辑Tracking Gap -->
    <Modal
      :isVisible="isShowTrackingGapEdit"
      title="Change Tracking Gap"
      width="394px"
      submitText="Confirm"
      @close-popup="hideTrackingGapEditPopup"
      @submit="submitTrackingGapEditPopup"
    >
      <template #content>
        <a-select
          @change="handleChangeEditGap"
          class="select"
          :defaultValue="trackingGap + ' Days'"
          style="width:100%"
        >
          <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
          <a-select-option value="15">15 Days</a-select-option>
          <a-select-option value="30">30 Days</a-select-option>
          <a-select-option value="60">60 Days</a-select-option>
          <a-select-option value="90">90 Days</a-select-option>
          <a-select-option value="120">120 Days</a-select-option>
          <a-select-option value="180">180 Days</a-select-option>
        </a-select>
      </template>
    </Modal>

    <!-- 编辑Lock Date -->
    <Modal
      :isVisible="isShowLockDateEdit"
      title="Lock Date"
      width="394px"
      submitText="Confirm"
      @close-popup="hideLockDateEditPopup"
      @submit="submitLockDateEditPopup"
    >
      <template #content>
        <a-select
          @change="handleChangeEditLockDate"
          class="select"
          :defaultValue="lockDate + ' Days'"
          style="width:100%"
        >
          <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
          <a-select-option value="15">15 Days</a-select-option>
          <a-select-option value="30">30 Days</a-select-option>
          <a-select-option value="45">45 Days</a-select-option>
          <a-select-option value="60">60 Days</a-select-option>
          <a-select-option value="90">90 Days</a-select-option>
        </a-select>
        <br />
        <br />
        <p>
          NOTE: New lock date setting will take up to 24 hours before becoming
          effective.
        </p>
      </template>
    </Modal>

    <!-- 编辑Timezone -->
    <Modal
      :isVisible="isShowTimezoneEdit"
      title="Time Zone"
      width="394px"
      submitText="Confirm"
      @close-popup="hideTimezoneEditPopup"
      @submit="submitTimezoneEditPopup"
    >
      <template #content>
        <a-select
          @change="handleChangeEditTimezone"
          class="select"
          :defaultValue="timezone"
          style="width:100%"
        >
          <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
          <a-select-option
            v-for="item in GMT_LIST2"
            :key="item.value"
            :value="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </template>
    </Modal>

    <TestOrderModal
      :isShowPop="isShowTestOrderModal"
      :defaultTestOrderList="testOrderList"
      @close="isShowTestOrderModal = false"
      @connected="trackingStepStatus = 'Connected'"
    ></TestOrderModal>
    <Loading v-if="isLoadingModal"></Loading>
  </div>
</template>

<script>
import Modal from "./../../../components/Modal.vue";
import SignUpSelect from "@/views/SignUp/components/SignUpSelect.vue";
import axios from "@/utils/request";
import inspectUserPermissions from "@/utils/permissionsJudge";
import TestOrderModal from "../components/TestOrderModal.vue";

import { GMT_LIST } from "@/utils/config";
export default {
  name: "account-edit",
  components: {
    Modal,
    SignUpSelect,
    TestOrderModal,
  },
  data() {
    return {
      isLoadingModal: false,
      GMT_LIST,
      GMT_LIST2: Object.keys(GMT_LIST).map((key) => ({
        value: key,
        label: GMT_LIST[key],
      })),

      trackingGapValue: 0,
      timezoneValue: 0,
      lockDateValue: 0,
      isShowTrackingGapEdit: false,
      isShowLockDateEdit: false,
      isShowTimezoneEdit: false,

      defaultCommissionIsEdit: false,
      commissionTypeValue: "%",
      commissionValue: 0,

      currencyValue: 0,
      currencyIsEdit: false,

      trackingStepMode: "tracking-step-actions-0",
      trackingStepStatus: "Offline",
      isShowTestOrderModal: false,
      testOrderList: [],

      autoApproved: false,
      isAutoApprovalLoading: false,

      programStatus: false,
      isProgramStatusLoading: false,

      isShowPricing: 0,
      pricingPaymentActive: "paypal",
      submitPricingText: "Confirm & Pay $990.00",
    };
  },
  computed: {
    accountDetails() {
      return this.$store.state.accountDetails;
    },
    defaultCommission() {
      if (
        this.$store.state.accountDetails.norm &&
        this.accountDetails.merchant.currency
      ) {
        let norm = this.$store.state.accountDetails.norm;
        norm.aff_ba = Number(norm.aff_ba);
        if (norm.aff_ba_unit == "%") {
          return norm.aff_ba + "%";
        } else {
          return this.accountDetails.merchant.currency.sign + norm.aff_ba;
        }
      }
      return "";
    },
    trackingGap() {
      return Number(this.$store.state.accountDetails.merchant.tracking_gap);
    },
    lockDate() {
      return Number(this.$store.state.accountDetails.merchant.lock_date);
    },
    timezone() {
      return this.$store.state.accountDetails.merchant.timezone;
    },
    currency() {
      return this.$store.state.accountDetails.merchant.currency;
    },
  },
  created() {
    let { PayerID, token, type } = this.$route.query;
    if (type == "success") {
      this.isSuccessWrap = true;
      this.paymentSuccessCallback(token, PayerID);
    } else if (type == "cancel") {
      this.paymentErrorCallback(token, PayerID);
    } else {
      if (this.$route.params.upgrade) {
        this.isShowPricing = 1;
      }
    }

    if (this.accountDetails.norm) {
      this.commissionTypeValue = this.accountDetails.norm.aff_ba_unit;
      this.commissionValue = Number(this.accountDetails.norm.aff_ba);
    } else {
      this.commissionTypeValue = "%";
      this.commissionValue = "";
    }
    this.trackingGapValue = this.trackingGap;
    this.lockDateValue = this.lockDate;
    this.timezoneValue = this.timezone;
    this.autoApproved = this.accountDetails.merchant.verify_type == "auto";
    this.programStatus = this.accountDetails.merchant.program_status > 0;

    axios.get("/api_admin.php?a=user/checkOrderConnected").then((res) => {
      this.testOrderList = res.data.testOrders;
      this.trackingStepStatus =
        res.data.testOrders.length > 0 || res.data.normalOrder > 0
          ? "Connected"
          : "Offline";
    });
  },
  methods: {
    defaultCommissionCancel() {
      if (this.accountDetails.norm) {
        this.commissionTypeValue = this.accountDetails.norm.aff_ba_unit;
        this.commissionValue = Number(this.accountDetails.norm.aff_ba);
      } else {
        this.commissionTypeValue = "%";
        this.commissionValue = "";
      }
      this.defaultCommissionIsEdit = false;
    },
    defaultCommissionSave() {
      this.commissionValue = Number(this.commissionValue);
      if (
        isNaN(this.commissionValue) ||
        this.commissionValue < 1 ||
        this.commissionValue > 100
      ) {
        this.$message.error("Commission must be a valid number");
        return;
      }
      let submit = {
        type: "default_commission",
        aff_ba: this.commissionValue,
        aff_ba_unit: this.commissionTypeValue,
      };
      this.isLoadingModal = true;
      axios
        .post("/api_admin.php?a=user/updateMerchantInfo", submit)
        .then((res) => {
          if (res.code === 0) {
            this.$store.dispatch("updateAccountDetails", {
              norm: res.data,
            });
          } else {
            this.$message.error(
              res.msg || "Sorry, server is busy. please try later."
            );
          }
        })
        .catch((e) => {})
        .then(() => {
          this.isLoadingModal = false;
          this.defaultCommissionCancel();
        });
    },
    handleCommissionTypeChange(e) {
      this.commissionTypeValue = e.target.value;
    },
    handleEditCommission() {
      if (!this.accountDetails.merchant.currency) {
        this.$message.error("Please select a currency");
        return;
      }
      this.defaultCommissionIsEdit = true;
    },

    hideTrackingGapEditPopup() {
      this.isShowTrackingGapEdit = false;
      this.trackingGapValue = this.trackingGap;
    },
    showTackingGapPopup() {
      this.isShowTrackingGapEdit = true;
    },
    handleChangeEditGap(value) {
      this.trackingGapValue = value;
    },
    submitTrackingGapEditPopup() {
      if (this.trackingGapValue == this.trackingGap) {
        this.hideTrackingGapEditPopup();
        return;
      }
      let submit = {
        type: "tracking_gap",
        tracking_gap: this.trackingGapValue,
      };
      this.isLoadingModal = true;
      axios
        .post("/api_admin.php?a=user/updateMerchantInfo", submit)
        .then((res) => {
          if (res.code === 0) {
            this.$store.dispatch("updateAccountDetails", {
              merchant: {
                tracking_gap: this.trackingGapValue,
              },
            });
          } else {
            this.$message.error(
              res.msg || "Sorry, server is busy. please try later."
            );
          }
        })
        .catch((e) => {})
        .then(() => {
          this.isLoadingModal = false;
          this.hideTrackingGapEditPopup();
        });
    },

    handleChangeEditLockDate(value) {
      this.lockDateValue = value;
    },
    hideLockDateEditPopup() {
      this.isShowLockDateEdit = false;
      this.lockDateValue = this.lockDate;
    },
    showLockDatePopup() {
      this.isShowLockDateEdit = true;
    },
    submitLockDateEditPopup() {
      if (this.lockDateValue == this.lockDate) {
        this.hideLockDateEditPopup();
        return;
      }
      let submit = {
        type: "lock_date",
        lock_date: this.lockDateValue,
      };
      this.isLoadingModal = true;
      axios
        .post("/api_admin.php?a=user/updateMerchantInfo", submit)
        .then((res) => {
          if (res.code === 0) {
            this.$store.dispatch("updateAccountDetails", {
              merchant: {
                preview_lock_date: this.lockDateValue,
              },
            });
          } else {
            this.$message.error(
              res.msg || "Sorry, server is busy. please try later."
            );
          }
        })
        .catch((e) => {})
        .then(() => {
          this.isLoadingModal = false;
          this.hideLockDateEditPopup();
        });
    },

    handleChangeEditTimezone(value) {
      this.timezoneValue = value;
    },
    hideTimezoneEditPopup() {
      this.isShowTimezoneEdit = false;
      this.timezoneValue = this.timezone;
    },
    showTimezonePopup() {
      this.isShowTimezoneEdit = true;
    },
    submitTimezoneEditPopup() {
      if (this.timezoneValue == this.timezone) {
        this.hideTimezoneEditPopup();
        return;
      }
      let submit = {
        type: "timezone",
        timezone: this.timezoneValue,
      };
      this.isLoadingModal = true;
      axios
        .post("/api_admin.php?a=user/updateMerchantInfo", submit)
        .then((res) => {
          if (res.code === 0) {
            this.$store.dispatch("updateAccountDetails", {
              merchant: {
                timezone: this.timezoneValue,
              },
            });
          } else {
            this.$message.error(
              res.msg || "Sorry, server is busy. please try later."
            );
          }
        })
        .catch((e) => {})
        .then(() => {
          this.isLoadingModal = false;
          this.hideTimezoneEditPopup();
        });
    },
    currencyCancel() {
      this.currencyIsEdit = false;
      this.currencyValue = 0;
    },
    currencySave() {
      if (this.currencyValue == this.currency || this.currencyValue == 0) {
        this.currencyCancel();
        return;
      }
      let submit = {
        type: "currency",
        currency: this.currencyValue,
      };
      this.isLoadingModal = true;
      axios
        .post("/api_admin.php?a=user/updateMerchantInfo", submit)
        .then((res) => {
          if (res.code === 0) {
            console.log(res.data);
            this.$store.dispatch("updateAccountDetails", {
              merchant: {
                currency_id: this.currencyValue,
                currency: res.data,
              },
            });
          } else {
            this.$message.error(
              res.msg || "Sorry, server is busy. please try later."
            );
          }
        })
        .catch((e) => {})
        .then(() => {
          this.isLoadingModal = false;
          this.currencyCancel();
        });
    },

    showTrackingTestModal() {
      this.isShowTestOrderModal = true;
    },
    handleTrackingStepClick(mode) {
      switch (mode) {
        case "devinstall":
          this.trackingStepMode = "tracking-step-actions-1";
          break;
        case "api":
          window.open("/brand/tools/api");
          break;
        case "shopify":
          window.open(
            "https://cdn.partnerboost.com/static/docs/PartnerBoost%20-%20Embedding%20Guide%20for%20Shopify.pdf"
          );
          this.showTrackingTestModal();
          break;
        case "publicdoc":
          window.open(
            "https://cdn.partnerboost.com/static/docs/PartnerBoost%20Universal%20Installation%20Guide.pdf"
          );
          this.showTrackingTestModal();
          break;
      }
    },
    handleAutoApproved(val) {
      this.isAutoApprovalLoading = true;
      axios
        .post("/api_admin.php?a=user/updateMerchantInfo", {
          type: "verify_type",
          verify_type: val ? "auto" : "",
        })
        .then((res) => {
          this.isAutoApprovalLoading = false;
          if (res.code === 0) {
            this.$store.dispatch("updateAccountDetails", {
              merchant: {
                verify_type: val ? "auto" : "admin",
                verify_type_modified_time: new Date().getTime() / 1000,
              },
            });
            this.autoApproved =
              this.accountDetails.merchant.verify_type == "auto";
          } else {
            this.$message.error(
              res.msg || "Sorry, server is busy. please try later."
            );
          }
        })
        .catch(() => {
          this.isAutoApprovalLoading = false;
        });
    },
    handleProgramStatus(val) {
      if (
        this.accountDetails.merchant.program_level_duration >=
          new Date().getTime() / 1000 ||
        !val
      ) {
        this.isProgramStatusLoading = true;
        axios
          .post("/api_admin.php?a=user/updateMerchantInfo", {
            type: "program_status",
            program_status: val ? 1 : 0,
          })
          .then((res) => {
            this.isProgramStatusLoading = false;
            if (res.code === 0) {
              this.$store.dispatch("updateAccountDetails", {
                merchant: {
                  program_status: val ? 1 : 0,
                },
              });
              this.programStatus =
                this.accountDetails.merchant.program_status > 0;
            } else {
              this.$message.error(
                res.msg || "Sorry, server is busy. please try later."
              );
            }
          })
          .catch(() => {
            this.isProgramStatusLoading = false;
          });
      } else {
        this.isShowPricing = 1;
        this.programStatus = this.accountDetails.merchant.program_status > 0;
      }
    },
    goContact() {
      window.open("https://www.partnerboost.com/contact/");
    },
    submitPricing() {
      if (this.isShowPricing == 1 || this.pricingPaymentActive == "bank") {
        window.open("https://www.partnerboost.com/contact/");
      } else if (this.pricingPaymentActive == "paypal") {
        this.isLoadingModal = true;
        axios
          .post("/api_admin.php?a=account/payFee")
          .then((res) => {
            if (res.code === 0) {
              window.location.href = res.data.payment_url;
            } else {
              this.$message.error(
                res.msg || "Sorry, server is busy. please try later."
              );
            }
          })
          .catch(() => {
            this.isLoadingModal = false;
          });
      } else {
        window.open("https://www.partnerboost.com/contact/");
      }
    },
    paymentSuccessCallback(token, PayerID) {
      this.isLoadingModal = true;
      axios
        .post("/api_admin.php?a=payment/callback", {
          token: token,
          PayerID: PayerID,
          state: "success",
        })
        .then((res) => {
          window.location.href = "/brand/account/details/program-settings";
        });
    },
    paymentErrorCallback(token) {
      this.$message.error("payment cancel!!");
      axios
        .post("/api_admin.php?a=payment/callback", {
          token: token,
          state: "cancel",
        })
        .then((res) => {
          console.log(res);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.child-details {
  color: #333333;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;

  /deep/.ant-input:focus {
    border-color: #2898ff;
    box-shadow: none;
  }

  .child-details-column {
    width: 463px;
  }

  .account-edit-btn {
    color: #2898ff;
    cursor: pointer;
    text-decoration: underline;
    font-size: 1rem;
    user-select: none;
  }

  .account-bg {
    background: #ffffff;
    border-radius: 12px;
    width: 463px;
    padding: 16px 22px;
    margin-bottom: 22px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .account-title {
    font-size: 1.1429rem;
    height: 30px;
    line-height: 30px;
  }

  .flex-space-between-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .flex-start-center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  .account-last-updated {
    font-size: 0.8571rem;
    color: #999999;
    width: 100%;
    display: flex;
    justify-content: right;
    margin-top: 10px;
    height: 21px;
    line-height: 21px;
  }

  .item-bold {
    font-size: 22px;
    font-family: dtc-bold;
    height: 2.8571rem;
    line-height: 2.8571rem;
    margin-top: 8px;
  }

  .setting-edit-btn {
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;

    > div {
      cursor: pointer;
    }
  }

  .setting-form-item {
    margin-bottom: 14px;
    height: 36px;
    font-size: 1.2857rem;
    border-radius: 4px;
    border-color: #eee;
    margin-top: 8px;

    /deep/ input {
      height: 36px !important;
      border-radius: 4px;
      border-color: #eee;
      font-size: 1.2857rem;
    }
  }

  /deep/ .ant-radio-group-solid.ant-radio-group-small {
    .ant-radio-button-wrapper {
      border-color: #2898ff;
      height: 28px;
      line-height: 26px;

      span {
        color: #2898ff;
      }

      &.ant-radio-button-wrapper-checked {
        span {
          color: #fff;
        }
      }
    }
  }

  /deep/ .signup-select {
    margin-bottom: 14px;
    padding-top: 0px;
    height: 2.8571rem;

    input {
      height: 2.8571rem;
    }

    .dropdown-list {
      top: 41px;
    }
  }

  .setting-item-tips {
    color: #999999;
    font-size: 0.9286rem;
    height: 28px;
    line-height: 28px;
    font-weight: 500;
    font-family: dtc;
  }

  .flex-space-between {
    display: flex;
    justify-content: space-between;
    align-items: self-end;
  }

  .__btn {
    width: 72px;
    height: 30px;
    margin-left: 24px;
  }

  .trackingStepStatus {
    border-radius: 4px;
    font-size: 1rem;
    color: #ffffff;
    padding: 0 10px;
    height: 24px;
    line-height: 24px;
    margin-left: 10px;

    &.Offline {
      background: #d8d8d8;
    }

    &.Connected {
      background: #30cd89;
    }
  }

  .tracking-step-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;

    > div {
      font-size: 0.8571rem;
      color: #2898ff;
      line-height: 42px;
      background: #f2f9ff;
      border-radius: 4px;
      height: 42px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      user-select: none;
      margin-top: 8px;
    }

    &.tracking-step-actions-0 {
      .tracking-step-action-api {
        margin-left: 8px;
      }

      .tracking-step-action-shopify,
      .tracking-step-action-publicdoc {
        display: none;
      }
    }

    &.tracking-step-actions-1 {
      flex-direction: column;

      .tracking-step-action-devinstall,
      .tracking-step-action-api {
        display: none;
      }
    }

    .tracking-step-action-devinstall {
    }

    .tracking-step-action-api {
      color: #fbc15b;
      background: #fff8ed;
    }

    .tracking-step-action-shopify {
      background: #f0fce8;
      color: #5e8e3eff;

      img {
        height: 20px;
        margin-right: 8px;
      }
    }

    .tracking-step-action-publicdoc {
      color: #fbc15b;
      background: #fff8ed;
    }
  }

  .approved-types {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;

    /deep/ .ant-switch-checked {
      background-color: #30cd89;
    }

    .setting-item-tips {
      margin-left: auto;
      height: 22px;
      line-height: 22px;
    }

    .approved-type- {
      &admin {
        width: 60px;
        height: 22px;
        background: #d8d8d8;
        border-radius: 4px;
        color: #fff;
        font-size: 1rem;
        line-height: 22px;
        text-align: center;
        display: inline-block;
        margin-right: 8px;
      }

      &auto {
        width: 60px;
        height: 22px;
        background: #30cd89;
        border-radius: 4px;
        color: #fff;
        font-size: 1rem;
        line-height: 22px;
        text-align: center;
        display: inline-block;
        margin-right: 8px;
      }
    }
  }

  .program-statuss {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;

    /deep/ .ant-switch-checked {
      background-color: #30cd89;
    }

    .setting-item-tips {
      margin-left: auto;
      height: 22px;
      line-height: 22px;
    }

    .program-status- {
      &0 {
        min-width: 60px;
        height: 22px;
        background: #d8d8d8;
        border-radius: 4px;
        color: #fff;
        font-size: 1rem;
        line-height: 22px;
        text-align: center;
        display: inline-block;
        margin-right: 8px;
        padding: 0 12px;
      }

      &1 {
        min-width: 60px;
        height: 22px;
        background: #30cd89;
        border-radius: 4px;
        color: #fff;
        font-size: 1rem;
        line-height: 22px;
        text-align: center;
        display: inline-block;
        margin-right: 8px;
        padding: 0 12px;
      }
    }
  }
}

.pricing-shadow {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);

  .pricing-inside {
    height: 636px;
    width: 688px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    z-index: 100;

    .pricing-enterprise {
      width: 318px;
      height: 540px;
      background: #ffffff;
      border-radius: 28px;
      box-sizing: border-box;
      padding: 36px 32px 30px;

      > div {
        &:first-child {
          font-size: 36px;
          font-family: HarmonyOS_Sans_Medium;
          color: #251d53;
          line-height: 52px;
          height: 92px;
        }

        &:nth-child(2) {
          font-size: 0.8571rem;
          color: #87859f;
          line-height: 22px;
          margin-top: 16px;
        }
      }

      .pricing-options {
        display: flex;
        flex-direction: column;
        margin-top: 18px;

        span {
          height: 22px;
          font-size: 1rem;
          color: #87849f;
          line-height: 19px;
          background: url(~@/assets/pricing-actions-enterprise.svg) no-repeat
            left center;
          background-size: contain;
          padding-left: 35px;

          + span {
            margin-top: 14px;
          }
        }
      }

      .pricing-btn {
        width: 100%;
        height: 44px;
        border-radius: 22px;
        font-size: 1rem;
        font-family: HarmonyOS_Sans_Medium;
        line-height: 44px;
        user-select: none;
        cursor: pointer;
        margin-top: 22px;
        text-align: center;
        background: #f2f9ff;
        color: #2898ff;

        &:hover {
          background: #224fc5;
          color: #7ec1ff;
        }
      }
    }

    .pricing-standard {
      width: 342px;
      height: 636px;
      background: url(~@/assets/pricing-standard-bg.svg) no-repeat center center;
      background-size: contain;
      border-radius: 28px;
      box-sizing: border-box;
      padding: 2.8571rem 32px 30px;
      position: relative;

      > div {
        &:first-child {
          width: 140px;
          height: 30px;
          background: #224fc5;
          border-radius: 16px;
          font-size: 0.8571rem;
          color: #c399cf;
          line-height: 30px;
          text-align: center;
          position: absolute;
          top: 14px;
          right: 22px;
        }

        &:nth-child(2) {
          height: 26px;
          font-size: 1rem;
          color: #cbcbcb;
          line-height: 19px;

          span {
            font-size: 1.2857rem;
            text-decoration: line-through;
          }
        }

        &:nth-child(3) {
          font-size: 1.2857rem;
          color: #ffffff;
          line-height: 25px;
          margin-top: 6px;

          span {
            font-size: 36px;
            line-height: 50px;
            padding-right: 20px;
          }
        }

        &:nth-child(4) {
          height: 30px;
          font-size: 26px;
          font-family: HarmonyOS_Sans_Medium;
          color: #ffffff;
          line-height: 36px;
          margin-top: 14px;
        }

        &:nth-child(5) {
          margin-top: 14px;
          height: 36px;
          font-size: 0.8571rem;
          color: #ffffff;
          line-height: 17px;
        }
      }

      .pricing-options {
        display: flex;
        flex-direction: column;
        margin-top: 18px;

        span {
          height: 22px;
          font-size: 1rem;
          color: #fff;
          line-height: 19px;

          background: url(~@/assets/pricing-actions-standard.svg) no-repeat left
            center;
          background-size: contain;
          padding-left: 35px;

          // pricing-standard-bg.svg
          // pricing-actions-enterprise.svg
          // pricing-actions-standard.svg
          + span {
            margin-top: 14px;
          }
        }
      }

      .pricing-btn {
        width: 100%;
        height: 44px;
        border-radius: 22px;
        font-size: 1rem;
        font-family: HarmonyOS_Sans_Medium;
        line-height: 44px;
        user-select: none;
        cursor: pointer;
        margin-top: 22px;
        text-align: center;
        background: #224fc5;
        color: #7ec1ff;

        &:hover {
          background: #f2f9ff;
          color: #2898ff;
        }
      }
    }
  }

  .pricing-payment-confirm {
    width: 342px;
    height: 466px;
    background: #ffffff;
    border-radius: 28px;
    box-sizing: border-box;
    padding: 32px 28px 34px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    > div:first-child {
      height: 22px;
      font-size: 1.1429rem;
      font-family: HarmonyOS_Sans_Bold;
      color: #333333;
      line-height: 23px;
    }

    img {
      margin-top: 16px;
      width: 100%;
    }

    .pricing-payment-price {
      margin-top: 15px;
      height: 42px;
      width: 100%;
      border-top: solid #ffffff00 1px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        &:first-child {
          font-size: 1rem;
          color: #999999;
        }

        &:last-child {
          font-size: 1rem;
          color: #333333;
        }
      }

      ~ .pricing-payment-price {
        margin-top: 0;
        border-top-color: #f5f5f5ff;
      }
    }

    .pricing-payment-btn {
      margin-top: auto;
      height: 2.8571rem;
      background: #2898ff;
      border-radius: 20px;
      font-family: HarmonyOS_Sans_Medium;
      color: #ffffff;
      font-size: 1rem;
      text-align: center;
      line-height: 2.8571rem;
      user-select: none;
      cursor: pointer;

      &:hover {
        background-color: #52b1ff;
      }
    }
  }

  .pricing-payment-submit {
    width: 342px;
    height: 466px;
    background: #ffffff;
    border-radius: 28px;
    box-sizing: border-box;
    padding: 32px 28px 34px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    > div:first-child {
      height: 22px;
      font-size: 1.1429rem;
      font-family: HarmonyOS_Sans_Bold;
      color: #333333;
      line-height: 23px;
    }

    .pricing-payment-item {
      margin-top: 26px;
      height: 73px;
      border-radius: 12px;
      border: 1px solid #eeeeee;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 22px;
      user-select: none;
      cursor: pointer;

      &.active {
        border-color: #2898ff !important;
      }

      &:hover {
        border-color: #a4d1ff;
      }

      img {
        width: 32px;
      }

      span {
        margin-left: 36px;
        font-size: 1.1429rem;
        color: #333333;
      }

      ~ .pricing-payment-item {
        margin-top: 16px;
      }
    }

    .pricing-payment-btn {
      margin-top: auto;
      height: 2.8571rem;
      background: #2898ff;
      border-radius: 20px;
      font-family: HarmonyOS_Sans_Medium;
      color: #ffffff;
      font-size: 1rem;
      text-align: center;
      line-height: 2.8571rem;
      user-select: none;
      cursor: pointer;

      &:hover {
        background-color: #52b1ff;
      }
    }
  }
}

.payout-shadow {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: rgba(242, 249, 255, 0.86);
  border-radius: 12px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;

  div:nth-child(2) {
    font-size: 1.1429rem;
    color: #333333;
  }

  div:last-child {
    font-size: 0.8571rem;
    color: #333333;
    line-height: 17px;
    cursor: pointer;
    user-select: none;
    border-bottom: 1px solid #33333380;
  }
}

.lock-date-updating {
  margin-right: auto;
  font-size: 0.9286rem;
  color: #999;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 24px;
  img {
    height: 15px;
    margin-right: 6px;
  }
}
</style>
