var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"addbank"},[_c('Header',{attrs:{"collection":"My Account"}}),_c('Brands',{attrs:{"collection":"Payment Methods","path":"/account/payment-setting/select","subset":"Add Payment Methods"}}),_c('div',{staticClass:"container"},[_c('a-form',{attrs:{"form":_vm.formInfo,"label-col":{ span: 7 },"wrapper-col":{ span: 17 }},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.isPayoneer ? 'Payoneer Email' : 'Paypal Email',"labelAlign":"left","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                "accountcard",
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your Email',
                    } ],
                } ]),expression:"[\n                `accountcard`,\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please input your Email',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"disabled":_vm.disabledBankInfo || _vm.isPayoneer,"placeholder":""}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.isPayoneer ? 'Payoneer Name' : 'Paypal Name',"labelAlign":"left","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                "username",
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your Name',
                    } ],
                } ]),expression:"[\n                `username`,\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please input your Name',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"disabled":_vm.disabledBankInfo || _vm.isPayoneer,"placeholder":""}})],1)],1)],1),(!_vm.isPayoneer)?_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Paypal Country","labelAlign":"left","colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'user_country_id',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please select a Paypal Country',
                    } ],
                } ]),expression:"[\n                'user_country_id',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please select a Paypal Country',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"disabled":_vm.disabledBankInfo,"placeholder":""}},_vm._l((_vm.countrys),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name_en)+" ")])}),1)],1)],1)],1):_vm._e(),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Withdraw Threshold","labelAlign":"left","colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'threshold',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please select a Withdraw Threshold',
                    } ],
                } ]),expression:"[\n                'threshold',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please select a Withdraw Threshold',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Please select a Withdraw Threshold"}},[(_vm.isPayoneer)?_c('a-select-option',{attrs:{"value":"15"}},[_vm._v("$15")]):_vm._e(),_c('a-select-option',{attrs:{"value":"50"}},[_vm._v("$50")]),_c('a-select-option',{attrs:{"value":"100"}},[_vm._v("$100")]),_c('a-select-option',{attrs:{"value":"500"}},[_vm._v("$500")]),_c('a-select-option',{attrs:{"value":"1000"}},[_vm._v("$1000")]),_c('a-select-option',{attrs:{"value":"2500"}},[_vm._v("$2500")]),_c('a-select-option',{attrs:{"value":"5000"}},[_vm._v("$5000")])],1)],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"labelAlign":"left","colon":false}},[_c('div',{attrs:{"slot":"label"},slot:"label"},[_c('span',{staticStyle:{"color":"#f5222d","font-size":"14px","font-family":"SimSun, sans-serif"}},[_vm._v("*")]),_vm._v(" Tax Info ")]),_c('div',{staticClass:"form-upload form-item-lg",on:{"click":function($event){_vm.showUpload = true}}},[_vm._v(" Upload ")]),_c('a-input',{staticStyle:{"display":"none"}})],1)],1)],1),_c('a-button',{staticClass:"btn-add",attrs:{"type":"primary","html-type":"submit"}},[_vm._v("Save")])],1),_c('UploadModel',{attrs:{"showUpload":_vm.showUpload,"init":_vm.detail},on:{"onSubmit":_vm.onSubmit,"onClose":function($event){_vm.showUpload = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }