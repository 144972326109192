var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"addbank"},[_c('Header',{attrs:{"collection":"My Account"}}),_c('Brands',{attrs:{"path":"/account/payment-setting/select","collection":"Payment Methods","subset":"Add Payment Methods"}}),_c('div',{staticClass:"container"},[_c('a-form',{attrs:{"form":_vm.formInfo,"label-col":{ span: 12 },"wrapper-col":{ span: 12 }},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Bank Location","labelAlign":"left","colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'source',
                {
                  initialValue: _vm.init.source,
                  rules: [
                    {
                      required: true,
                      message: 'Please select a Bank Location',
                    } ],
                } ]),expression:"[\n                'source',\n                {\n                  initialValue: init.source,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please select a Bank Location',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":"Please select a Bank Location"},on:{"change":function (v) { return (_vm.source = v); }}},[_c('a-select-option',{attrs:{"value":"0"}},[_vm._v(" Within Mainland China ")]),_c('a-select-option',{attrs:{"value":"1"}},[_vm._v(" Outside Mainland China ")])],1)],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Account Type","labelAlign":"left","colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'mtype',
                {
                  initialValue: _vm.init.mtype,
                  rules: [
                    {
                      required: true,
                      message: 'Please select a Account Type',
                    } ],
                } ]),expression:"[\n                'mtype',\n                {\n                  initialValue: init.mtype,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please select a Account Type',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":"Please select a Account Type"},on:{"change":function (v) { return (_vm.mtype = v); }}},[_c('a-select-option',{attrs:{"value":"0"}},[_vm._v(" Personal ")]),_c('a-select-option',{attrs:{"value":"1"}},[_vm._v(" Business ")])],1)],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Withdraw Threshold","labelAlign":"left","colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'threshold',
                {
                  initialValue: _vm.init.threshold,
                  rules: [
                    {
                      required: true,
                      message: 'Please select a Withdraw Threshold',
                    } ],
                } ]),expression:"[\n                'threshold',\n                {\n                  initialValue: init.threshold,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please select a Withdraw Threshold',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Please select a Withdraw Threshold"}},[_c('a-select-option',{attrs:{"value":"50"}},[_vm._v("$50")]),_c('a-select-option',{attrs:{"value":"100"}},[_vm._v("$100")]),_c('a-select-option',{attrs:{"value":"500"}},[_vm._v("$500")]),_c('a-select-option',{attrs:{"value":"1000"}},[_vm._v("$1000")]),_c('a-select-option',{attrs:{"value":"2500"}},[_vm._v("$2500")]),_c('a-select-option',{attrs:{"value":"5000"}},[_vm._v("$5000")])],1)],1)],1)],1),(_vm.source == 0 && _vm.mtype == 0)?_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Bank Name","labelAlign":"left","colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'bankname',
                {
                  initialValue: _vm.init.bankname,
                  rules: [
                    { required: true, message: 'Please select a Bank Name' } ],
                } ]),expression:"[\n                'bankname',\n                {\n                  initialValue: init.bankname,\n                  rules: [\n                    { required: true, message: 'Please select a Bank Name' },\n                  ],\n                },\n              ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":"Please select a Bank Name"}},_vm._l((_vm.banks),function(item){return _c('a-select-option',{key:item,attrs:{"value":item}},[_vm._v(_vm._s(item))])}),1)],1)],1)],1):_vm._e(),(_vm.source == 0)?_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Location of Bank","labelAlign":"left","colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'province',
                {
                  initialValue: _vm.init.province,
                  rules: [
                    { required: true, message: 'Please select a province' } ],
                } ]),expression:"[\n                'province',\n                {\n                  initialValue: init.province,\n                  rules: [\n                    { required: true, message: 'Please select a province' },\n                  ],\n                },\n              ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":"Please select a province"},on:{"change":_vm.selectProvince}},_vm._l((_vm.provinces),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.name}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{staticClass:"form-rt",attrs:{"span":12}},[_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'city',
                {
                  initialValue: _vm.init.city,
                  rules: [
                    { required: true, message: 'Please select a city' } ],
                } ]),expression:"[\n                'city',\n                {\n                  initialValue: init.city,\n                  rules: [\n                    { required: true, message: 'Please select a city' },\n                  ],\n                },\n              ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":"Please select a city"},on:{"change":_vm.selectCity}},_vm._l((_vm.citys),function(item){return _c('a-select-option',{key:item.name,attrs:{"value":item.name}},[_vm._v(_vm._s(item.name))])}),1)],1)],1)],1):_vm._e(),(_vm.source == 1)?_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Location of Bank","labelAlign":"left","colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'country_id',
                {
                  initialValue: _vm.init.country_id,
                  rules: [
                    { required: true, message: 'Please select a country' } ],
                } ]),expression:"[\n                'country_id',\n                {\n                  initialValue: init.country_id,\n                  rules: [\n                    { required: true, message: 'Please select a country' },\n                  ],\n                },\n              ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":"Please select a country"},on:{"change":_vm.handleCountryChange}},_vm._l((_vm.countrys),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name_en))])}),1)],1)],1),(_vm.isRegionShow)?_c('a-col',{staticClass:"form-rt",attrs:{"span":12}},[_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'region_id',
                {
                  initialValue: _vm.init.country_id,
                  rules: [{ required: true, message: 'State/Province' }],
                } ]),expression:"[\n                'region_id',\n                {\n                  initialValue: init.country_id,\n                  rules: [{ required: true, message: 'State/Province' }],\n                },\n              ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":"State/Province"}},_vm._l((_vm.regions),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name_en))])}),1)],1)],1):_vm._e()],1):_vm._e(),_c('a-row',{staticClass:"form-line",attrs:{"gutter":24}},[(_vm.source == 0)?_c('a-col',{staticClass:"form-col",attrs:{"span":12}},[_c('div',{staticClass:"left-label"}),_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'cmbArea',
                {
                  initialValue: _vm.init.cmbArea,
                  rules: [
                    { required: true, message: 'Please select a Area' } ],
                } ]),expression:"[\n                'cmbArea',\n                {\n                  initialValue: init.cmbArea,\n                  rules: [\n                    { required: true, message: 'Please select a Area' },\n                  ],\n                },\n              ]"}],staticClass:"form-item-width",attrs:{"disabled":_vm.isDisabled,"placeholder":"Please select a Area"}},_vm._l((_vm.cmbAreas),function(item){return _c('a-select-option',{key:item.name,attrs:{"value":item.name}},[_vm._v(_vm._s(item.name))])}),1)],1)],1):_c('a-col',{staticClass:"form-col",attrs:{"span":12}},[_c('div',{staticClass:"left-label"}),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                "bankcity",
                {
                  initialValue: _vm.init.bankcity,
                  rules: [{ required: true, message: 'City' }],
                } ]),expression:"[\n                `bankcity`,\n                {\n                  initialValue: init.bankcity,\n                  rules: [{ required: true, message: 'City' }],\n                },\n              ]"}],staticClass:"form-item-width",attrs:{"disabled":_vm.isDisabled,"placeholder":"City"}})],1)],1),(_vm.source == 1)?_c('a-col',{staticClass:"form-rt",attrs:{"span":12}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                "bankaddr",
                {
                  initialValue: _vm.init.bankaddr,
                  rule: {
                    required: true,
                    message: 'Please input Address',
                  },
                } ]),expression:"[\n                `bankaddr`,\n                {\n                  initialValue: init.bankaddr,\n                  rule: {\n                    required: true,\n                    message: 'Please input Address',\n                  },\n                },\n              ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":"Address 1"}})],1)],1):_vm._e()],1),(_vm.source == 1)?_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Types of Account","labelAlign":"left","colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'account_type',
                {
                  initialValue: _vm.init.account_type,
                  rules: [
                    {
                      required: true,
                      message: 'Please select a Types of Account',
                    } ],
                } ]),expression:"[\n                'account_type',\n                {\n                  initialValue: init.account_type,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please select a Types of Account',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":"Please select a Types of Account"}},[_c('a-select-option',{attrs:{"value":"2"}},[_vm._v("Checking")]),_c('a-select-option',{attrs:{"value":"1"}},[_vm._v("Saving")])],1)],1)],1)],1):_vm._e(),(_vm.source == 1 && _vm.mtype == 0)?_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Are you an United State tax payer?","labelAlign":"left","colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'is_us_tax',
                {
                  initialValue: _vm.init.is_us_tax,
                  rules: [{ required: true, message: 'Please select' }],
                } ]),expression:"[\n                'is_us_tax',\n                {\n                  initialValue: init.is_us_tax,\n                  rules: [{ required: true, message: 'Please select' }],\n                },\n              ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":"Please select"}},[_c('a-select-option',{attrs:{"value":"1"}},[_vm._v("Yes")]),_c('a-select-option',{attrs:{"value":"0"}},[_vm._v("No")])],1)],1)],1)],1):_vm._e(),(_vm.source == 1)?_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Swiftcode/ABA","labelAlign":"left","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                "swiftcode",
                {
                  initialValue: _vm.init.swiftcode,
                  rules: [
                    { required: true, message: 'Please input Swiftcode' } ],
                } ]),expression:"[\n                `swiftcode`,\n                {\n                  initialValue: init.swiftcode,\n                  rules: [\n                    { required: true, message: 'Please input Swiftcode' },\n                  ],\n                },\n              ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":""}})],1)],1)],1):_vm._e(),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Name of the Branch","labelAlign":"left","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                "accountname",
                {
                  initialValue: _vm.init.accountname,
                  rules: [
                    {
                      required: true,
                      message: 'Please input Name of the Branch',
                    } ],
                } ]),expression:"[\n                `accountname`,\n                {\n                  initialValue: init.accountname,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please input Name of the Branch',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":""}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":((_vm.source == 0 ? 'Card' : 'Account') + " No."),"labelAlign":"left","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'accountcard',
                {
                  initialValue: _vm.init.accountcard,
                  rules: [
                    {
                      required: true,
                      message: ("Please input " + (_vm.source == 0 ? 'Card' : 'Account') + " No."),
                    } ],
                } ]),expression:"[\n                'accountcard',\n                {\n                  initialValue: init.accountcard,\n                  rules: [\n                    {\n                      required: true,\n                      message: `Please input ${\n                        source == 0 ? 'Card' : 'Account'\n                      } No.`,\n                    },\n                  ],\n                },\n              ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":""}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":("" + (_vm.mtype == 0 ? 'Name of Account Holder' : 'Business Account Name')),"labelAlign":"left","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                "username",
                {
                  initialValue: _vm.init.username,
                  rules: [
                    {
                      required: true,
                      message: ("Please input " + (_vm.mtype == 0
                          ? 'Name of Account Holder'
                          : 'Business Account Name')),
                    } ],
                } ]),expression:"[\n                `username`,\n                {\n                  initialValue: init.username,\n                  rules: [\n                    {\n                      required: true,\n                      message: `Please input ${\n                        mtype == 0\n                          ? 'Name of Account Holder'\n                          : 'Business Account Name'\n                      }`,\n                    },\n                  ],\n                },\n              ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":""}})],1)],1)],1),(_vm.source == 1)?_c('div',[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Billing Address","labelAlign":"left","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'nickname',
                  {
                    initialValue: _vm.init.nickname,
                    rules: [
                      { required: true, message: 'Please input nickname' } ],
                  } ]),expression:"[\n                  'nickname',\n                  {\n                    initialValue: init.nickname,\n                    rules: [\n                      { required: true, message: 'Please input nickname' },\n                    ],\n                  },\n                ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":"name"}})],1)],1)],1),_c('a-row',{staticClass:"form-line",attrs:{"gutter":24}},[_c('a-col',{staticClass:"form-col",attrs:{"span":12}},[_c('div',{staticClass:"left-label"}),_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'user_country_id',
                  {
                    initialValue: _vm.init.user_country_id,
                    rules: [
                      { required: true, message: 'Please select Country' } ],
                  } ]),expression:"[\n                  'user_country_id',\n                  {\n                    initialValue: init.user_country_id,\n                    rules: [\n                      { required: true, message: 'Please select Country' },\n                    ],\n                  },\n                ]"}],staticClass:"form-item-width",attrs:{"disabled":_vm.isDisabled,"placeholder":"Country"}},_vm._l((_vm.countrys),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name_en))])}),1)],1)],1),_c('a-col',{staticClass:"form-rt",attrs:{"span":12}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  "user_province",
                  {
                    initialValue: _vm.init.user_province,
                    rules: [{ required: true, message: 'Please input city' }],
                  } ]),expression:"[\n                  `user_province`,\n                  {\n                    initialValue: init.user_province,\n                    rules: [{ required: true, message: 'Please input city' }],\n                  },\n                ]"}],staticClass:"form-item-width",attrs:{"disabled":_vm.isDisabled,"placeholder":"State/Province"}})],1)],1)],1),_c('a-row',{staticClass:"form-line",attrs:{"gutter":24}},[_c('a-col',{staticClass:"form-col",attrs:{"span":12}},[_c('div',{staticClass:"left-label"}),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  "user_city",
                  {
                    initialValue: _vm.init.user_city,

                    rules: [{ required: true, message: 'Please input City' }],
                  } ]),expression:"[\n                  `user_city`,\n                  {\n                    initialValue: init.user_city,\n\n                    rules: [{ required: true, message: 'Please input City' }],\n                  },\n                ]"}],staticClass:"form-item-width",attrs:{"disabled":_vm.isDisabled,"placeholder":"City"}})],1)],1),_c('a-col',{staticClass:"form-rt",attrs:{"span":12}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  "user_addr",
                  {
                    initialValue: _vm.init.user_addr,
                    rules: [
                      { required: true, message: 'Please input address' } ],
                  } ]),expression:"[\n                  `user_addr`,\n                  {\n                    initialValue: init.user_addr,\n                    rules: [\n                      { required: true, message: 'Please input address' },\n                    ],\n                  },\n                ]"}],staticClass:"form-item-width",attrs:{"disabled":_vm.isDisabled,"placeholder":"Address 1"}})],1)],1)],1),_c('a-row',{staticClass:"form-line",attrs:{"gutter":24}},[_c('a-col',{staticClass:"form-col",attrs:{"span":12}},[_c('div',{staticClass:"left-label"}),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  "postal_code",
                  {
                    initialValue: _vm.init.postal_code,
                    rules: [
                      { required: true, message: 'Please input Zip Code' } ],
                  } ]),expression:"[\n                  `postal_code`,\n                  {\n                    initialValue: init.postal_code,\n                    rules: [\n                      { required: true, message: 'Please input Zip Code' },\n                    ],\n                  },\n                ]"}],staticClass:"form-item-width",attrs:{"disabled":_vm.isDisabled,"placeholder":"Zip Code"}})],1)],1)],1)],1):_vm._e(),(_vm.source == 0 && _vm.mtype == 0)?_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Resident Identity Card No.","labelAlign":"left","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                "usernumber",
                {
                  initialValue: _vm.init.usernumber,
                  rules: [
                    {
                      required: true,
                      message: 'Please input Identity Card No.',
                    } ],
                } ]),expression:"[\n                `usernumber`,\n                {\n                  initialValue: init.usernumber,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please input Identity Card No.',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":""}})],1)],1)],1):_vm._e(),(_vm.source == 0 && _vm.mtype == 0)?_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Resident Identity Card Expiration Date","labelAlign":"left","colon":false}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'idcard_end_time',
                {
                  initialValue: _vm.init.idcard_end_time,
                  rules: [{ required: true, message: 'Please select  Date' }],
                } ]),expression:"[\n                'idcard_end_time',\n                {\n                  initialValue: init.idcard_end_time,\n                  rules: [{ required: true, message: 'Please select  Date' }],\n                },\n              ]"}],staticClass:"form-item-width",attrs:{"disabled":_vm.isDisabled}})],1)],1)],1):_vm._e(),(_vm.source == 0 && _vm.mtype == 0)?_c('a-row',{attrs:{"gutter":24}},[_c('div',{staticClass:"upload"},[_c('div',{staticClass:"upload-label"},[_vm._v("Image of Resident Identity Card")]),_c('div',{staticClass:"upload-right"},[_c('div',{staticClass:"upload-right-first"},[_c('a-form-item',[_c('a-upload',{staticClass:"avatar-uploader",attrs:{"disabled":_vm.isDisabled,"name":"filename","list-type":"picture-card","show-upload-list":false,"action":_vm.uploadUrl,"headers":_vm.headers,"data":{ type: 'image' },"accept":".jpg,.png,.jpeg"},on:{"change":function (info) { return _vm.handleChangeUpload(info, 'userpic1'); }}},[(_vm.userpic1)?_c('img',{staticClass:"upload-img",attrs:{"src":_vm.userpic1,"alt":"avatar"}}):_c('div',[_c('a-icon',{attrs:{"type":_vm.loading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("front")])],1)]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'userpic1',
                    {
                      initialValue: _vm.init.userpic1,
                      rules: [
                        {
                          required: true,
                          message: 'Please upload front of Identity card',
                        } ],
                    } ]),expression:"[\n                    'userpic1',\n                    {\n                      initialValue: init.userpic1,\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Please upload front of Identity card',\n                        },\n                      ],\n                    },\n                  ]"}],staticStyle:{"display":"none"},attrs:{"disabled":_vm.isDisabled}})],1)],1),_c('div',[_c('a-upload',{staticClass:"avatar-uploader",attrs:{"disabled":_vm.isDisabled,"name":"filename","list-type":"picture-card","show-upload-list":false,"action":_vm.uploadUrl,"headers":_vm.headers,"data":{ type: 'image' },"accept":".jpg,.png,.jpeg"},on:{"change":function (info) { return _vm.handleChangeUpload(info, 'userpic2'); }}},[(_vm.userpic2)?_c('img',{staticClass:"upload-img",attrs:{"src":_vm.userpic2,"alt":"avatar"}}):_c('div',[_c('a-icon',{attrs:{"type":_vm.loading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("back")])],1)]),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'userpic2',
                    {
                      initialValue: _vm.init.userpic2,
                      rules: [
                        {
                          required: true,
                          message: 'Please upload back of Identity card',
                        } ],
                    } ]),expression:"[\n                    'userpic2',\n                    {\n                      initialValue: init.userpic2,\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Please upload back of Identity card',\n                        },\n                      ],\n                    },\n                  ]"}],staticStyle:{"display":"none"},attrs:{"disabled":_vm.isDisabled}})],1)],1)])])]):_vm._e(),(_vm.source == 0 && _vm.mtype == 1)?_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Business License No.","labelAlign":"left","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                "usernumber",
                {
                  initialValue: _vm.init.usernumber,
                  rules: [
                    {
                      required: true,
                      message: 'Pleace input Business License No.',
                    } ],
                } ]),expression:"[\n                `usernumber`,\n                {\n                  initialValue: init.usernumber,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Pleace input Business License No.',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":""}})],1)],1)],1):_vm._e(),(_vm.source == 0 && _vm.mtype == 1)?_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Image of Business License","labelAlign":"left","colon":false}},[_c('a-upload',{staticClass:"avatar-uploader",attrs:{"disabled":_vm.isDisabled,"name":"filename","list-type":"picture-card","show-upload-list":false,"action":_vm.uploadUrl,"headers":_vm.headers,"data":{ type: 'image' },"accept":".jpg,.png,.jpeg"},on:{"change":function (info) { return _vm.handleChangeUpload(info, 'userpic1'); }}},[(_vm.userpic1)?_c('img',{staticClass:"upload-img",attrs:{"src":_vm.userpic1,"alt":"avatar"}}):_c('div',[_c('a-icon',{attrs:{"type":_vm.loading ? 'loading' : 'plus'}})],1)]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'userpic1',
                {
                  initialValue: _vm.init.userpic1,
                  rules: [
                    {
                      required: true,
                      message: 'Please upload Image of Business License',
                    } ],
                } ]),expression:"[\n                'userpic1',\n                {\n                  initialValue: init.userpic1,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please upload Image of Business License',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"display":"none"},attrs:{"disabled":_vm.isDisabled}})],1)],1)],1):_vm._e(),(_vm.source == 0 && _vm.mtype == 1)?_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Image of Operation Certificate","labelAlign":"left","colon":false}},[_c('a-upload',{staticClass:"avatar-uploader",attrs:{"disabled":_vm.isDisabled,"name":"filename","list-type":"picture-card","show-upload-list":false,"action":_vm.uploadUrl,"headers":_vm.headers,"data":{ type: 'image' },"accept":".jpg,.png,.jpeg"},on:{"change":function (info) { return _vm.handleChangeUpload(info, 'userpic2'); }}},[(_vm.userpic2)?_c('img',{staticClass:"upload-img",attrs:{"src":_vm.userpic2,"alt":"avatar"}}):_c('div',[_c('a-icon',{attrs:{"type":_vm.loading ? 'loading' : 'plus'}})],1)]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'userpic1',
                {
                  initialValue: _vm.init.userpic1,
                  rules: [
                    {
                      required: true,
                      message: 'Please upload Image of Operation Certificate',
                    } ],
                } ]),expression:"[\n                'userpic1',\n                {\n                  initialValue: init.userpic1,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please upload Image of Operation Certificate',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"display":"none"},attrs:{"disabled":_vm.isDisabled}})],1)],1)],1):_vm._e(),(_vm.source == 1)?_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"labelAlign":"left","colon":false}},[_c('div',{attrs:{"slot":"label"},slot:"label"},[_c('span',{staticStyle:{"color":"#f5222d","font-size":"14px","font-family":"SimSun, sans-serif"}},[_vm._v("*")]),_vm._v(" Tax Info ")]),_c('div',{staticClass:"form-upload form-item-lg",on:{"click":function($event){_vm.showUpload = true}}},[_vm._v(" Upload ")]),_c('a-input',{staticStyle:{"display":"none"}})],1)],1)],1):_vm._e(),_c('a-button',{staticClass:"btn-add",attrs:{"type":"primary","html-type":"submit"}},[_vm._v("Save")])],1)],1),_c('UploadModel',{attrs:{"showUpload":_vm.showUpload,"init":_vm.detail},on:{"onSubmit":_vm.onSubmit,"onClose":function($event){_vm.showUpload = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }