const CONFIG = {
  API_HOST: process.env.VUE_APP_API_HOST,
  WEB_HOST: process.env.VUE_APP_AFF_HOST,
  WWW_HOST: process.env.VUE_APP_WWW_HOST,
  OSS_HOST: process.env.VUE_APP_OSS_HOST,
};

export const GMT_LIST = {
  "GMT -12:00": "UTC-12/Oceania",
  "GMT -11:00": "UTC-11/Pacific Ocean",
  "GMT -10:00": "UTC-10/Polynesia, Hawaii",
  "GMT -09:00": "UTC-9/Alaska",
  "GMT -08:00": "UTC-8/Pacific Time Zone",
  "GMT -07:00": "UTC-7/California ",
  "GMT -06:00": "UTC-6/Central America, Canada",
  "GMT -05:00": "UTC-5/Eastern Caribbean",
  "GMT -04:00": "UTC-4/New York",
  "GMT -03:00": "UTC-3/Brazil",
  "GMT -02:30": "UTC-02:30/Newfoundland",
  "GMT -02:00": "UTC-2/Brazil",
  "GMT -01:00": "UTC-1/Altantic Ocean",
  "GMT +00:00": "UTC±0/West Africa",
  "GMT +01:00": "UTC+1/Central Europe",
  "GMT +02:00": "UTC+2/Central Africa",
  "GMT +03:00": "UTC+3/Turkey, Ukraine",
  "GMT +04:00": "UTC+4/UAE, Oman, Armenia",
  "GMT +04:30": "UTC+04:30/Afghanistan",
  "GMT +05:00": "UTC+5/Kazakhstan",
  "GMT +05:30": "UTC+05:30/India",
  "GMT +05:45": "UTC+05:45/Kathmandu",
  "GMT +06:00": "UTC+6/United Kingdom",
  "GMT +06:30": "UTC+06:30/Yangon",
  "GMT +07:00": "UTC+7/South East Asia",
  "GMT +08:00": "UTC+8/China",
  "GMT +09:00": "UTC+9/Japan, Russia, Korea",
  "GMT +09:30": "UTC+09:30/ASCT",
  "GMT +10:00": "UTC+10/Australia, Russia",
  "GMT +11:00": "UTC+11/MAGT",
  "GMT +12:00": "UTC+12/PETT, New Zealand",
  "GMT +13:00": "UTC+13/New Zealand",
  "GMT +14:00": "UTC+14/Kiribati",
};
export const webRoot = CONFIG.WEB_HOST;
export const baseUrl = CONFIG.API_HOST;
export const uploadUrl = CONFIG.API_HOST + "/m/user/c/index/a/doUpload";

export default CONFIG;
