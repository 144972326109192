<template>
  <div class="cuslink">
    <Header collection="Tools"></Header>
    <div class="container">
      <div class="headers">
        <div class="headers-title">Version Updates</div>
        <ul class="headers-list">
          <li class="headers-item">
            <div>2024/01/24 --- Payment API</div>
          </li>
        </ul>
      </div>
      <div class="headers">
        <div class="headers-title">Summary Request Information</div>
        <ul class="headers-list">
          <li class="headers-item">
            <span>URL:</span>
            <div>
              https://www.linkprefer.com/api.php?mod=linkpayment&op=payment
            </div>
          </li>
          <li class="headers-item">
            <span>Return Format:</span>
            <div>Json</div>
          </li>
          <li class="headers-item">
            <span>HTTP request method:</span>
            <div>GET/POST</div>
          </li>
          <CopyToken />
        </ul>
      </div>
      <div class="content">
        <div class="comm-tables">
          <div class="comm-header">
            <div class="comm-header-left">
              <div>Summary Request Parameter</div>
            </div>
          </div>
          <div class="comm-list">
            <a-table
              class="comtable"
              :columns="columns"
              :data-source="dataList1"
              :rowKey="(record, index) => record.name"
              :loading="isLoading"
              :pagination="false"
            >
              <div class="api-title" slot="apititle" slot-scope="text">
                {{ text }}
              </div>
            </a-table>
          </div>
        </div>
        <div class="comm-tables">
          <div class="comm-header">
            <div class="comm-header-left">
              <div>Summary Return Parameter</div>
            </div>
          </div>
          <div class="comm-list">
            <a-table
              class="comtable"
              :columns="columns"
              :data-source="dataList2"
              :rowKey="(record, index) => record.name"
              :loading="isLoading"
              :pagination="false"
            >
              <div class="api-title" slot="apititle" slot-scope="text">
                {{ text }}
              </div>
            </a-table>
          </div>
        </div>
        <div class="comm-tables">
          <div class="comm-header">
            <div class="comm-header-left">
              <div>Result Code</div>
            </div>
          </div>
          <div class="comm-list">
            <a-table
              class="comtable"
              :columns="columns"
              :data-source="dataList3"
              :rowKey="(record, index) => record.name"
              :loading="isLoading"
              :pagination="false"
            >
              <div class="api-title" slot="apititle" slot-scope="text">
                {{ text }}
              </div>
            </a-table>
          </div>
        </div>
        <div class="json-list">
          <div class="json-title">Return Format</div>
          <JsonPretty :data="jsonStr" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/public/Header.vue";
import axios from "@/utils/request";
import CopyToken from "@/components/CopyToken.vue";
import JsonPretty from "@/components/JsonPretty.vue";
import XmlPretty from "@/components/XmlPretty.vue";

export default {
  filters: {
    filterStaus(status) {
      if (status == "new") {
        return "Unpaid";
      } else if (status == "paid") {
        return "Paid";
      } else {
        return "Void";
      }
    },
  },
  components: {
    Header,
    CopyToken,
    JsonPretty,
    XmlPretty,
  },
  data() {
    let columns = [
      {
        title: "Name",
        dataIndex: "name",
        width: 160,
        align: "left",
        scopedSlots: { customRender: "apititle" },
      },
      {
        title: "Description",
        dataIndex: "description",
        width: 1110,
        align: "left",
      },
    ];
    return {
      columns,
      dataList1: [
        {
          name: "token",
          description:
            "The unique identifier of the website to obtain information {required}",
        },
        {
          name: "payment_begin",
          description:
            "The begin date of LinkPrefer's payment to the publisher. Format: YYYY-mm-dd HH:mm:ss {required}",
        },
        {
          name: "payment_end",
          description:
            "The end date of LinkPrefer's payment to the publisher. Format: YYYY-mm-dd HH:mm:ss {required}",
        },
      ],
      dataList2: [
        {
          name: "withdrawal_id",
          description: "Withdrawal ID",
        },
        {
          name: "created_date",
          description: "Time of withdrawal creation",
        },
        {
          name: "payment_date",
          description: "Time of withdrawal payment",
        },
        {
          name: "paid_status",
          description: "Payment Status",
        },
        {
          name: "account_name",
          description: "Account Name",
        },
        {
          name: "bank_name",
          description: "Bank Name",
        },
        {
          name: "withdrawal_account",
          description: "Withdrawal Account",
        },
        {
          name: "total",
          description: "Total",
        },
        {
          name: "service_fee",
          description: "Service Fee",
        },
      ],
      dataList3: [
        {
          name: "0",
          description: "Success",
        },
        {
          name: "1000",
          description: "Users do not exist",
        },
        {
          name: "1001",
          description: "Invalid token",
        },
        {
          name: "1002",
          description: "Call frequency is too high",
        },
        {
          name: "1003",
          description: "The input parameter is incorrect",
        },
      ],
      dataList5: [
        {
          name: "token",
          description:
            "The unique identifier of the website to obtain information {required}",
        },
        {
          name: "type",
          description:
            "Return data format: json,xml {optional, the default is json}",
        },
        {
          name: "payment_sn",
          description: "Payment ID",
        },
        {
          name: "offset",
          description: "Offset (Starts from 0 by default)",
        },
        {
          name: "pageSize",
          description: "Pagesize number of pages (minimum 100, maximum 40000)",
        },
      ],
      dataList6: [
        {
          name: "m_id",
          description: "Merchant ID",
        },
        {
          name: "sitename",
          description: "Merchant name",
        },
        {
          name: "order_id",
          description: "Order number",
        },
        {
          name: "order_time",
          description: "Order time",
        },
        {
          name: "sale_amount",
          description: "Sale amount",
        },
        {
          name: "cashback",
          description: "Cashback",
        },
        {
          name: "status",
          description: "Order status",
        },
        {
          name: "tagcode",
          description: "Custom tag",
        },
        {
          name: "productID",
          description: "Product ID",
        },
        {
          name: "quantity",
          description: "Order item quantity",
        },
        {
          name: "payment_sn",
          description:
            "Payment ID, a parameter must be passed when obtaining payment details",
        },
        {
          name: "report_time",
          description: "Order report time",
        },
        {
          name: "cross_device",
          description: "Whether cross-device: 1 yes, 0 no",
        },
      ],
      dataList7: [
        {
          name: "0",
          description: "Success",
        },
        {
          name: "1000",
          description: "Users do not exist",
        },
        {
          name: "1001",
          description: "Invalid token",
        },
        {
          name: "1002",
          description: "Call frequency is too high",
        },
        {
          name: "1003",
          description: "The input parameter is incorrect",
        },
        {
          name: "1006",
          description: "The query time span cannot exceed 62 days",
        },
      ],
      currentPage: 1,
      isLoading: false,
      jsonStr: {
        status: {
          code: 0,
          msg: "success",
        },
        data: [
          {
            withdrawal_id: "19059",
            created_date: "2022-05-05",
            payment_date: "2022-05-09",
            paid_status: "successed",
            account_name: "Mark76",
            bank_name: "ZFB",
            withdrawal_account: "309000077373",
            total: "700,192.00",
            service_fee: "0.00",
          },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    html2Escape(sHtml) {
      return sHtml.replace(/[<>&"]/g, function(c) {
        return { "<": "&lt;", ">": "&gt;", "&": "&amp;", '"': "&quot;" }[c];
      });
    },
    escape2Html(str) {
      var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
      return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function(all, t) {
        return arrEntities[t];
      });
    },
    onSelectChange() {},
    // 切换下一页
    paginationChange(page, pageSize) {
      //   this.currentPage = page;
      //   this.getDataList();
    },
  },
  beforeDestroy() {
    if (this.onWindowResize) {
      window.onresize = null;
    }
  },
};
</script>

<style lang="less" scoped>
.cuslink {
  .headers {
    display: flex;
    width: 100%;
    margin-top: 20px;
    flex-direction: column;
    background: #ffffff;
    border-radius: 16px;
    padding: 1.7143rem;
    &-title {
      font-size: 1.1429rem;
      color: #3762d6;
      font-family: "prefer-bold";
    }
    &-list {
    }
    &-item {
      margin-top: 16px;
      span {
        color: #999999;
      }
      div {
        margin-top: 3px;
        font-family: "prefer-bold";
      }
    }
  }
  .content {
    margin-top: 1.4286rem;
    .comm-tables {
      margin-top: 1.4286rem;
    }
    .comm-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      background: #fff;
      padding: 1.1429rem;
      border-radius: 16px 16px 0 0;
      flex-direction: column;
    }
    .comm-header-left {
      > div {
        font-size: 1.1429rem;
        color: #3762d6;
        font-family: "prefer-bold";
      }
    }
    .api-title {
      font-family: "prefer-bold";
    }
    .json-list {
      background: #ffffff;
      border-radius: 16px;
      padding: 1.7143rem;
      margin-top: 20px;
    }
    .json-title {
      color: #3762d6;
      font-size: 1.1429rem;
      font-family: "prefer-bold";
    }
  }
}
/deep/ .ant-table-tbody > tr > td {
  padding-left: 24px;
}
/deep/ .ant-table-thead > tr > th {
  padding-left: 24px;
}
.comm-header {
  padding-left: 24px !important;
}
</style>
