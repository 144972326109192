<template>
  <div class="campvideo">
    <div
      v-if="isEdit === '1'"
      class="campvideo-select"
      :class="isChecked ? '' : 'has-border'"
      @click="checkItem"
    >
      <i v-show="isChecked"></i>
    </div>
    <div v-else-if="isEdit === '2'" class="campvideo-mask">
      <a-icon class="campvideo-mask-close" type="close" @click="deleteImage" />
      <a-icon
        type="play-circle"
        class="campvideo-mask-play"
        @click="showPlay"
      />
      <p>
        <span v-show="width > 0">{{ width }} x {{ height }}</span
        ><span v-show="duration > 0">{{ duration | formatTimeDuration }}</span>
      </p>
    </div>
    <video :src="videoUrl"></video>
    <div v-if="isShowModal" class="campvideo-pop" @click="hidePlay">
      <div class="campvideo-pop-content" @click.stop="preventPop">
        <video controls="controls" :src="videoUrl"></video>
      </div>
    </div>
  </div>
</template>

<script>
// const defaultLogo = require("@/assets/default.png");
import moment from "moment-timezone";
import axios from "@/utils/request";
import Modal from "../../../../components/Modal.vue";

export default {
  components: { Modal },
  props: {
    cid: {
      type: [String, Number],
      default: "",
    },
    id: {
      type: [String, Number],
      default: "",
    },
    videoUrl: {
      type: String,
      default: "",
    },
    isEdit: {
      type: String,
      default: "",
    },
    toChecked: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "",
    },
    duration: {
      type: String,
      default: "",
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  data() {
    return {
      isLoadingModal: false,
      isChecked: this.toChecked,
      isShowModal: false,
    };
  },
  watch: {},
  created() {},
  methods: {
    deleteImage() {
      if (this.isEditing) {
        this.$emit("delete-video", this.id);
      } else {
        this.$store.dispatch("removeCampaignCreatives", {
          type: 3,
          cid: this.cid,
        });
      }
    },
    checkItem() {
      this.isChecked = !this.isChecked;
      this.$emit(
        "video-check",
        {
          creative_id: this.cid,
          cid: this.cid,
          videoUrl: this.videoUrl,
          duration: this.duration,
          width: this.width,
          height: this.height,
        },
        this.isChecked
      );
    },
    hidePlay() {
      this.isShowModal = false;
    },
    showPlay() {
      console.log("打开");
      this.isShowModal = true;
    },
    preventPop() {},
    // goPlay() {
    //   const videoElement = document.getElementById("camp-video-play");
    //   videoElement.play();
    //   this.hideMaskOfVideo = true;
    // },
  },
};
</script>

<style lang="less" scoped>
.campvideo {
  border-radius: 12px;
  border: 1px solid #e6e6e6;
  position: relative;
  margin-right: 16px;
  margin-bottom: 16px;
  width: 238px;
  height: 144px;
  cursor: pointer;
  &-pop {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    &-marsk {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.7);
      z-index: 3;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-text {
      position: absolute;
      left: 28px;
      top: 24px;
      font-size: 1.1429rem;
    }
    &-close {
      position: absolute;
      top: 24px;
      right: 22px;
      font-size: 1.2857rem;
      cursor: pointer;
    }
    &-play {
      font-size: 96px;
      cursor: pointer;
    }
    &-content {
      width: 800px;
      height: 480px;
      position: relative;
      background: #000;
      video {
        width: 800px;
        height: 480px;
        object-fit: contain;
        object-position: center;
      }
    }
  }
  &-mask {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    border-radius: 12px;
    display: none;
    z-index: 1;
    &-close {
      color: #fff;
      position: absolute;
      right: 5px;
      top: 5px;
      font-size: 1.1429rem;
      cursor: pointer;
    }
    &-play {
      color: #fff;
      position: absolute;
      right: calc((100% - 36px) / 2);
      top: calc((100% - 36px) / 2);
      font-size: 36px;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
    > p {
      color: #fff;
      position: absolute;
      bottom: 0;
      width: 100%;
      margin-bottom: 6px;
      span {
        margin-left: 16px;
      }
    }
  }
  &-select {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1003;
    cursor: pointer;
    background: #fff;
    &.has-border {
      border: 1px solid #ccc;
    }
    > i {
      color: #2898ff;
      font-size: 1.2857rem;
      display: inline-block;
      width: 18px;
      height: 18px;
      background: url("~@/assets/check-box-checked.svg") center/contain
        no-repeat;
    }
  }
  &:hover {
    .campvideo-mask {
      display: block;
    }
  }
  > video {
    width: 100%;
    object-fit: cover;
    height: 100%;
    border-radius: 12px;
    object-fit: contain;
    object-position: center;
  }
}
</style>
