<template>
  <div class="mybasic">
    <Header collection="My Account"></Header>
    <div class="container">
      <Brands collection="Login Info" subset="Change Password"></Brands>
      <div class="headers"></div>
      <div class="content">
        <div class="content-title">Login Info</div>
        <ul class="info-list">
          <li class="info-item">
            <span class="info-title">Current Password</span>
            <div class="info-content">
              <a-input
                v-model="old_password"
                type="password"
                class="info-input"
              ></a-input>
            </div>
          </li>
          <li class="info-item">
            <span class="info-title">New Password</span>
            <div class="info-content">
              <a-input
                v-model="new_password"
                type="password"
                class="info-input"
              ></a-input>
            </div>
          </li>
          <li class="info-item">
            <span class="info-title">Confirm Password</span>
            <div class="info-content">
              <a-input
                v-model="re_new_password"
                type="password"
                class="info-input"
              ></a-input>
            </div>
          </li>
          <li class="info-item">
            <span class="info-title">Verification Code</span>
            <div class="info-content">
              <a-input v-model="vcode" class="info-input"></a-input>
              <img
                @click="changeImg"
                :src="baseUrl + apis.common.get_vcode + `?v=${timeSpan}`"
              />
            </div>
          </li>
        </ul>
        <div class="info-actions">
          <a-button @click="cancelSave" class="info-save">Cancel</a-button>
          <a-button
            type="primary"
            :loading="btnLoading"
            class="info-save"
            @click="updatePwd"
            >Update</a-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/public/Header.vue";
import axios from "@/utils/request";
import Brands from "@/components/Brands.vue";
import apis from "@/api/const";
import { baseUrl } from "@/utils/config";

export default {
  components: {
    Header,
    Brands,
  },
  data() {
    return {
      isLoading: false,
      vImg: "",
      apis,
      baseUrl,
      timeSpan: Date.now(),
      btnLoading: false,
      old_password: "",
      new_password: "",
      re_new_password: "",
      vcode: "",
    };
  },
  mounted() {},
  computed: {},
  methods: {
    updatePwd() {
      if (this.new_password !== this.re_new_password) {
        this.$message.error("Two passwords are inconsistent");
        return;
      }
      this.btnLoading = true;
      const upData = {
        old_password: this.old_password,
        new_password: this.new_password,
        re_new_password: this.re_new_password,
        code: this.vcode,
        formData: true,
      };
      axios.post(apis.my.change_pwd, upData).then((res) => {
        this.btnLoading = false;
        if (res.code == "0200") {
          this.$message.success("Update Success.");
        } else {
          this.$message.error(res.msg);
        }
        this.isLoading = false;
      });
    },
    changeImg() {
      this.timeSpan = Date.now();
    },
    cancelSave() {
      this.$router.push({
        name: "my_login_info",
      });
    },
  },
  beforeDestroy() {
    if (this.onWindowResize) {
      window.onresize = null;
    }
  },
};
</script>

<style lang="less" scoped>
.mybasic {
  .headers {
  }
  .content {
    display: flex;
    justify-content: space-between;
    margin-top: 1.4286rem;
    background: #ffffff;
    box-shadow: 0px 0px 12px 0px rgba(102, 118, 186, 0.1);
    border-radius: 1.1429rem;
    flex-direction: column;
    padding: 1.7143rem 2.2857rem;
    &-title {
      font-size: 1.2857rem;
      color: #333333;
      font-family: "prefer-bold";
    }
    .info-list {
      width: 660px;
    }
    .info-item {
      display: flex;
      height: 52px;
      align-items: center;
      .info-title {
        width: 130px;
        color: #999999;
      }
      .info-content {
        width: 470px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        > img {
          position: absolute;
          right: 8px;
        }
        .info-edit {
          color: #3762d6;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .info-pwd {
        span {
        }
        img {
          margin-left: 20px;
          cursor: pointer;
          &:hover {
            opacity: 0.85;
          }
        }
      }
    }
    .info-actions {
      display: flex;
      margin-top: 32px;
      .info-save {
        width: 8.5714rem;
        margin-left: 128px;
      }
    }
    .pwd-content {
      position: relative;
      > img {
        position: absolute;
        right: 12px;
        top: 12px;
        cursor: pointer;
        &:hover {
          opacity: 0.85;
        }
      }
    }
  }
  /deep/ .ant-form-item-no-colon {
    color: #999999;
  }
  /deep/ .ant-input {
    height: 2.8571rem;
  }
}
</style>
