<template>
  <div class="login-view-wrap">
    <div class="title">
      Login
    </div>
    <div class="toogle">
      <div class="merchant">Brand</div>
      <div class="publisher">
        <a href="/publisher?mod=login&type=publisher">Publisher</a>
      </div>
      <div class="publisher">
        <a href="/publisher?mod=login&type=publisher">Influencer</a>
      </div>
    </div>
    <input type="text" placeholder="Username" v-model="username" />
    <input type="password" placeholder="Password" v-model="password" />
    <div class="remember">
      <img
        src="@/assets/remember.svg"
        alt
        v-if="isRemember"
        @click="remember"
      />
      <img src="@/assets/remember-no.svg" alt v-else @click="remember" />
      <span>Remember me on this computer</span>
    </div>
    <a-button
      type="primary"
      class="submit"
      @click="handleSubmit"
      :loading="handleSubmitLoading"
      >Login</a-button
    >
    <div class="forget">
      <a :href="APP_CONFIG.WWW_HOST + '/reset'">Forgot Your Password?</a>
    </div>
  </div>
</template>

<script>
import axios from "@/utils/request";
export default {
  name: "login-view",
  data() {
    return {
      username: "",
      password: "",
      isRemember: false,
      handleSubmitLoading: false,
    };
  },
  methods: {
    remember() {
      this.isRemember = !this.isRemember;
    },
    handleSubmit() {
      if (this.username == "" || this.password == "") {
        return;
      }
      let savePassword = {
        username: this.username,
        password: this.password,
      };
      // 保存密码
      if (this.isRemember) {
        localStorage.setItem("savePassword", JSON.stringify(savePassword));
      }
      this.handleSubmitLoading = true;
      axios
        .post("/api_admin.php?a=user/login", {
          uname: this.username,
          password: this.password,
          type: "1",
        })
        .then((res) => {
          this.handleSubmitLoading = false;
          if (res.code === 0) {
            this.$store.commit("setToken", res.data.auth_token);
            this.$store.commit("setAccountInfo", res.data);
            this.$message.success("login success");
            this._getUserPermissions().then(() => {
              this.$router.push({ name: "dashboard" });
            });
          } else {
            if (res.code == 1009) {
              this.$router.push({
                name: "signup",
                params: {
                  toStep: 3,
                  token: res.data.token,
                },
              });
            } else {
              this.$message.error(res.msg || "login error!!");
            }
          }
        })
        .catch((e) => {
          this.handleSubmitLoading = false;
          this.$message.error("network busy, please hold on!!");
        });
    },
    // 获取权限
    _getUserPermissions() {
      return axios.get("/api_admin.php?a=user/userPermissions").then((res) => {
        if (res.code === 0) {
          this.$store.commit("setAuthority", res.data);
        } else {
          this.$message.error(res.msg || "");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
input::-webkit-input-placeholder {
  color: #999;
}

.login-view-wrap {
  overflow: hidden;

  .title {
    width: 100%;
    height: 36px;
    font-size: 26px;
    font-family: dtc-bold;
    color: #333333;
    line-height: 36px;
    margin-bottom: 33px;
  }

  .toogle {
    display: flex;
    margin-bottom: 2.8571rem;

    .merchant {
      font-size: 1.2857rem;
      color: #2898ff;
      line-height: 23px;
      cursor: pointer;
      position: relative;

      &::after {
        content: "";
        width: 2.8571rem;
        height: 2px;
        background: #2898ff;
        position: absolute;
        bottom: -12px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .publisher {
      margin-left: 42px;

      a {
        font-size: 1.2857rem;
        color: #333333;
        line-height: 23px;
        cursor: pointer;
      }
    }

    .publisher :hover {
      color: #2898ff;
    }
  }

  input {
    width: 100%;
    height: 46px;
    border-radius: 6px;
    border: 1px solid #f3f3f3;
    padding-left: 12px;
    box-sizing: border-box;
    outline: none;
    margin-bottom: 20px;
  }

  .remember {
    margin-bottom: 20px;

    img {
      width: 16px;
      height: 16px;
      margin-right: 6px;
      cursor: pointer;
    }

    span {
      font-size: 1rem;
      color: #333333;
      line-height: 16px;
      // vertical-align: bottom;
    }
  }

  .submit {
    width: 100%;
    height: 42px;
    // background: #2898ff;
    // border-radius: 6px;
    cursor: pointer;
    // line-height: 42px;
    // text-align: center;
    font-size: 1.2857rem;
    // color: #ffffff;
  }

  .forget {
    margin-top: 30px;
    text-align: center;

    a {
      font-size: 1rem;
      color: #333333;
      line-height: 16px;
      text-decoration: underline;
    }
  }
}
</style>
