<template>
  <div id="medium-list-item">
    <div class="item-info" v-if="item">
      <div class="item-info-item" style="width:410px">
        <!-- 左边放图片,右边放名字和id和网站 -->
        <div class="item-info-item-left">
          <router-link :to="`/publisher/detail/${item.medium_id}`">
            <img
              :src="errorPlaceHolder || logo"
              :alt="item.title"
              class="logo"
              @error="onLogoError"
            />
          </router-link>
        </div>
        <div class="item-info-item-right">
          <div class="item-info-item-right-name">
            <router-link :to="`/publisher/detail/${item.medium_id}`">
              {{ item.title || item.nickname }}
            </router-link>
          </div>
          <div class="item-info-item-right-country">
            {{ item.country ? countryMap[item.country]["code"] : "" }}
          </div>
          <div class="item-info-item-right-id">
            Publisher ID: {{ item.medium_id }}
          </div>
          <div class="item-info-item-right-site">
            <a-popover
              trigger="hover"
              placement="top"
              v-if="item.host.length > 26"
            >
              <template #content>
                <a :href="item.host" ref="nofollow" target="_blank">{{
                  item.host
                }}</a>
              </template>
              <a :href="item.host" ref="nofollow" target="_blank">{{
                item.host
              }}</a>
            </a-popover>
            <a v-else :href="item.host" ref="nofollow" target="_blank">{{
              item.host
            }}</a>
          </div>
        </div>
      </div>
      <div class="item-info-item cates" style="width:170px;overflow:hidden">
        <template v-for="i of 3">
          <span v-if="item.category_names[i - 1]" :key="i">{{
            item.category_names[i - 1].en_title
          }}</span>
        </template>
        <a-popover
          trigger="hover"
          placement="bottom"
          v-if="item.category_names.length > 3"
        >
          <template #content>
            <div v-for="item in item.category_names" :key="item.id">
              {{ item.en_title }}
            </div>
          </template>
          <a ref="nofollow">more</a>
        </a-popover>
      </div>
      <div class="item-info-item cates" style="width:170px;overflow:hidden">
        <template v-for="i of 3">
          <span v-if="item.c_id_types[i - 1]" :key="i">{{
            item.c_id_types[i - 1].en_title
          }}</span>
        </template>
        <a-popover
          trigger="hover"
          placement="bottom"
          v-if="item.c_id_types.length > 3"
        >
          <template #content>
            <div v-for="item in item.c_id_types" :key="item.id">
              {{ item.en_title }}
            </div>
          </template>
          <a ref="nofollow">more</a>
        </a-popover>
      </div>
      <div class="item-info-item description" style="width:236px;">
        <a-popover
          trigger="hover"
          placement="bottom"
          :overlayStyle="{
            width: 'fit-content',
            minWidth: '100px',
            maxWidth: '400px',
          }"
          v-if="item.medium_content && item.medium_content.length > 0"
        >
          <template #content>
            {{ item.medium_content }}
          </template>
          <div>{{ item.medium_content }}</div>
        </a-popover>
      </div>
      <div
        class="item-info-item actions"
        :class="`${type} ${item.status || 'verifing'}`"
        style="width:150px;"
      >
        <template v-if="type == 'new'">
          <a-button type="primary" @click="invite('invite')">Invite</a-button>
          <a-button
            type="primary"
            ghost
            @click="addToWishList"
            :disabled="wished"
            :loading="wishLoading"
          >
            {{ wishText }}
          </a-button>
        </template>
        <template v-else-if="type == 'invited'">
          <span :class="item.status || 'verifing'">{{
            item.status | formatStatus
          }}</span>
          <span class="time">{{ item.invite_time | formatMDYHI }}</span>
          <a-button
            type="primary"
            @click="invite('followup')"
            ghost
            v-if="!item.status || item.status == 'verifing'"
            >Follow Up</a-button
          >
        </template>
        <template v-else-if="type == 'wish'">
          <a-button type="primary" @click="invite('invite')">Invite</a-button>
        </template>
        <template v-else-if="type == 'relationship'">
          <span :class="item.status">{{ item.status | formatStatus }}</span>
          <span class="time"
            >{{ item.invite_time ? "Accepted:" : "Applied:" }}
            {{ item.invite_time || item.created_time | formatMDYHI }}</span
          >
        </template>
      </div>
    </div>
    <el-empty v-else></el-empty>
  </div>
</template>
<script>
const defaultLogo = require("@/assets/default.png");
import moment from "moment-timezone";
import axios from "@/utils/request";
import Modal from "../../../components/Modal.vue";
export default {
  props: {
    item: {
      type: Object,
      default: () => null,
    },
    type: {
      type: String,
      default: "new",
    },
    isDisableAction: {
      type: Number,
      default: 0,
    },
  },
  data() {
    let countryMap = {};
    this.$store.state.commonConfig.countries.forEach((item) => {
      countryMap[item.id] = item;
    });
    return {
      errorPlaceHolder: null,
      wished: false,
      wishLoading: false,
      wishText: "Follow",
      countryMap,
    };
  },
  computed: {
    logo() {
      let logo = this.item.logo;
      if (!logo) {
        logo = defaultLogo;
      } else if (
        this.item.logo.indexOf("http") !== 0 &&
        this.item.logo.indexOf("//") !== 0
      ) {
        logo = this.APP_CONFIG.OSS_HOST + logo;
      }
      return logo;
    },
  },
  methods: {
    addToWishList() {
      if (this.isDisableAction > 0) {
        this.$emit("actionDisabled", this.isDisableAction);
        return;
      }
      this.wishLoading = true;
      this.wishText = "Following...";
      axios
        .post("/api_admin.php?a=discovery/action", {
          action: "wish",
          mediumId: this.item.medium_id,
        })
        .then((res) => {
          this.wishLoading = false;
          if (res.code === 0) {
            this.wished = true;
            this.wishText = "Followed";
            this.$emit("wished", this.item.medium_id);
            // 弹窗提示添加进邀请列表成功
            this.$message.success("Followed successfully");
          } else if (res.code == 10801) {
            this.wishText = "Follow";
            this.$emit("actionDisabled", "lowfunds");
          } else if (res.code == 10802) {
            this.wishText = "Follow";
            this.$emit("actionDisabled", "newone");
          } else {
            this.wishText = "Follow";
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.wishLoading = false;
          this.wishText = "Follow";
        });
    },
    invite(evt) {
      this.$emit("invite", this.item.medium_id, this.item, evt);
    },
    onLogoError() {
      this.errorPlaceHolder = defaultLogo;
    },
  },
  filters: {
    formatStatus(status) {
      if (!status) {
        return "Pending";
      }
      return {
        adopt: "Approved",
        refuse: "Declined",
        verifing: "Pending",
        terminated: "Removed",
      }[status];
    },
  },
};
</script>
<style lang="less" scoped>
#medium-list-item {
  &:last-child .item-info {
    border-bottom: none;
  }
}

.item-info {
  width: 1136px;
  height: calc(106px + 26px + 26px);
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;

  .item-info-item {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 26px 5px;
    font-size: 1rem;

    &:first-child {
      padding-left: 0;
      justify-content: space-between;
    }

    &.cates {
      flex-direction: column;

      a {
        font-size: 0.8571rem;
      }
    }

    &.actions {
      padding-right: 0;
      justify-content: space-evenly;
      flex-direction: column;
      align-items: flex-end;

      .ant-btn {
        width: 140px;
        border-radius: 6px;
      }

      &.invited.verifing {
        justify-content: space-between;
      }

      &.relationship,
      &.invited {
        align-items: center;
        font-size: 1.1429rem;

        .adopt {
          color: #19d300ff;
        }

        .refuse,
        .terminated {
          color: #d43501ff;
        }

        .verifing {
          color: #ff9f00ff;
        }

        .time {
          color: #999;
        }
      }
    }

    &.description {
      overflow: hidden;

      div {
        justify-content: center;
        align-items: center;
        width: 100%;
        height: fit-content;
        font-size: 1rem;
        color: #999999;
        line-height: 18px;
        overflow: hidden;
        max-height: 105px;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 6;
        display: -webkit-box;
      }
    }

    .item-info-item-left {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 186px;
      height: 106px;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #eeeeee;
      box-sizing: border-box;

      .logo {
        max-height: 104px;
        max-width: 184px;
      }
    }

    .item-info-item-right {
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      flex-direction: column;
      width: 195px;
      height: 110px;
      text-align: left;
      word-break: keep-all;

      .item-info-item-right-name {
        font-family: dtc-bold;
        color: #2898ff;
        line-height: 23px;
        height: 23px;
      }

      .item-info-item-right-country {
        height: 20px;
        width: 100%;
        color: #999999ff;
        line-height: 20px;
      }

      .item-info-item-right-id {
        height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        color: #333333;
        line-height: 20px;
      }

      .item-info-item-right-site {
        height: 23px;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;

        color: #2898ff;
        line-height: 23px;
      }
    }
  }
}
</style>
