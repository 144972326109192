<template>
  <div class="trans">
    <Header collection="Tools"></Header>
    <!-- <div class="headers">
      </div> -->
    <div class="container">
      <div class="content">
        <div class="tips">
          <div class="tips-text">Rules to submit losted sales：</div>
          <div class="tips-text">
            1. The sequence of submitted params for lost sales is: No,
            Transaction Time, Advertisers, LID, Order No, UID, Order Amount,
            Currency, Reason to submit.
          </div>
          <div class="tips-text">
            2. Nine types of currency: RMB, USD, GBP, TWD, EUR, JPY, AUD, KRW,
            HKD.
          </div>
          <div class="tips-text">
            3. Please note the correspondence between the order amount and the
            currency.If the exchange rate is concerned, you can use the
            submission to lose the present
            <a href="#">Exchange rate query</a> Results are submitted after
            conversion.
          </div>
          <div class="tips-text">
            4. Three types of submitting reason: paid order was not tracked,
            effective order to be expired wrongly, amount of effective order is
            incorrect.
          </div>
          <div class="tips-text">
            5. All of fields are required except the uid and comment field.
          </div>
        </div>
        <ul class="form-list">
          <li class="form-item header-item">
            <div class="item-1">No</div>
            <div class="item-2">Date Initiate<span class="star">*</span></div>
            <div class="item-2">Advertiser<span class="star">*</span></div>
            <div class="item-2">LID<span class="star">*</span></div>
            <div class="item-2">Order No<span class="star">*</span></div>
            <div class="item-2">UID(optional)<span class="star">*</span></div>
            <div class="item-2">Order Amount<span class="star">*</span></div>
            <div class="item-3">Currency<span class="star">*</span></div>
            <div class="item-4">
              Reason To Submit<span class="star">*</span>
            </div>
            <div class="item-2">Comment<span class="star">*</span></div>
          </li>
          <li class="form-item">
            <div class="item-1">1</div>
            <div class="item-2"><a-date-picker @change="onDateChange1" /></div>
            <div class="item-2"><a-input></a-input></div>
            <div class="item-2">
              <a-select placeholder="Select gender">
                <a-select-option value="Male"> Male </a-select-option>
                <a-select-option value="Female"> Female </a-select-option>
                <a-select-option value="All"> All </a-select-option>
              </a-select>
            </div>
            <div class="item-2"><a-input></a-input></div>
            <div class="item-2"><a-input></a-input></div>
            <div class="item-2"><a-input></a-input></div>
            <div class="item-3">
              <a-select placeholder="Select gender">
                <a-select-option value="Male"> Male </a-select-option>
                <a-select-option value="Female"> Female </a-select-option>
                <a-select-option value="All"> All </a-select-option>
              </a-select>
            </div>
            <div class="item-4">
              <a-select placeholder="Select gender">
                <a-select-option value="Male"> Male </a-select-option>
                <a-select-option value="Female"> Female </a-select-option>
                <a-select-option value="All"> All </a-select-option>
              </a-select>
            </div>
            <div class="item-2"><a-input></a-input></div>
          </li>
          <li class="form-item">
            <div class="item-1">1</div>
            <div class="item-2"><a-date-picker @change="onDateChange1" /></div>
            <div class="item-2"><a-input></a-input></div>
            <div class="item-2">
              <a-select placeholder="Select gender">
                <a-select-option value="Male"> Male </a-select-option>
                <a-select-option value="Female"> Female </a-select-option>
                <a-select-option value="All"> All </a-select-option>
              </a-select>
            </div>
            <div class="item-2"><a-input></a-input></div>
            <div class="item-2"><a-input></a-input></div>
            <div class="item-2"><a-input></a-input></div>
            <div class="item-3">
              <a-select placeholder="Select gender">
                <a-select-option value="Male"> Male </a-select-option>
                <a-select-option value="Female"> Female </a-select-option>
                <a-select-option value="All"> All </a-select-option>
              </a-select>
            </div>
            <div class="item-4">
              <a-select placeholder="Select gender">
                <a-select-option value="Male"> Male </a-select-option>
                <a-select-option value="Female"> Female </a-select-option>
                <a-select-option value="All"> All </a-select-option>
              </a-select>
            </div>
            <div class="item-2"><a-input></a-input></div>
          </li>
        </ul>
        <div class="form-actions">
          <a-button type="primary">Submit</a-button>
          <a-button>Reset</a-button>
          <a-button type="primary">View order status</a-button>
          <a-button type="primary">Batch Process</a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/public/Header.vue";
import axios from "@/utils/request";

export default {
  filters: {
    filterStaus(status) {
      if (status == "new") {
        return "Unpaid";
      } else if (status == "paid") {
        return "Paid";
      } else {
        return "Void";
      }
    },
  },
  components: {
    Header,
    // Modal
  },
  data() {
    return {
      formInfo: this.$form.createForm(this, { name: "form-info" }),
      showList: this.$store.state.commonConfig.countries,
      isEditTop: false,
      isEditInfo: false,
    };
  },
  mounted() {},
  methods: {
    onDateChange() {},
    onLocationSearch(searchText) {
      this.showList = this.$store.state.commonConfig.countries.filter(
        (item) => {
          if (
            item.name_en.toUpperCase().indexOf(searchText.toUpperCase()) > -1
          ) {
            return item;
          }
        }
      );
    },
    onLocationSelect(value, option) {
      this.country_name = option.data.attrs.text;
    },
  },
  beforeDestroy() {
    if (this.onWindowResize) {
      window.onresize = null;
    }
  },
};
</script>

<style lang="less" scoped>
.trans {
  .container {
    display: flex;
    justify-content: space-between;
    margin-top: 1.4286rem;
    background: #ffffff;
    box-shadow: 0px 0px 12px 0px rgba(102, 118, 186, 0.1);
    border-radius: 1.1429rem;
    flex-direction: column;
    .content {
      .tips {
        padding: 1.7143rem 2.2857rem;
        &-text {
          line-height: 26px;
          color: #999999;
        }
      }
      .form-list {
      }
      .form-item {
        display: flex;
        height: 46px;
        align-items: center;
        > div {
          text-align: center;
          padding: 0 3px;
        }
        .item-1 {
          width: 2.8571rem;
        }
        .item-2 {
          width: 128px;
        }
        .item-3 {
          width: 128px;
        }
        .item-4 {
          width: 200px;
        }
        .star {
          color: #f64a47;
        }
      }
      .header-item {
        height: 2.8571rem;
        background: #f6f9ff;
      }
      .form-actions {
        display: flex;
        padding: 1.7143rem 2.2857rem;
        justify-content: center;
        > button {
          margin-right: 0.8571rem;
          margin-top: 1.4286rem;
        }
      }
    }
  }
  /deep/ .ant-form-item-no-colon {
    color: #999999;
  }
  /deep/ .ant-input {
    height: 2.2857rem;
  }
  /deep/ .ant-select-selection {
    height: 2.2857rem;
    min-width: 7.1429rem;
  }
  /deep/.ant-select-selection__rendered {
    line-height: 32px;
  }
}
</style>
