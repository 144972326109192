<template>
  <div class="dashbd">
    <Header collection="Dashboard"></Header>
    <a-spin :spinning="isLoading">
      <div class="container">
        <div class="headers">
          <div class="headers-block block-1">
            <div class="block-left">
              <img src="@/assets/dashboard/icon-income-1.svg" />
            </div>
            <div class="block-right">
              <span class="block-title">Last 7 Days' Earnings</span>
              <span class="block-money">
                ${{ homeObj.commission.sevenBrokerage | toThousandsAndFixed2 }}
              </span>
            </div>
          </div>
          <div class="headers-block block-2">
            <div class="block-left">
              <img src="@/assets/dashboard/icon-income-2.svg" />
            </div>
            <div class="block-right">
              <span class="block-title">Total Earnings</span>
              <span class="block-money"
                >${{
                  homeObj.commission.totalIncome | toThousandsAndFixed2
                }}</span
              >
            </div>
          </div>
          <div class="headers-block block-3">
            <div class="block-left">
              <img src="@/assets/dashboard/icon-income-4.svg" />
            </div>
            <div class="block-right">
              <span class="block-title">In Progress</span>
              <span class="block-money"
                >${{
                  homeObj.commission.paymentInProgress | toThousandsAndFixed2
                }}</span
              >
            </div>
          </div>
          <div class="headers-block block-4">
            <div class="block-left">
              <img src="@/assets/dashboard/icon-income-3.svg" />
            </div>
            <div class="block-right">
              <span class="block-title">Available for Withdrawal</span>
              <span class="block-money"
                >${{
                  homeObj.commission.availableForWithdraw | toThousandsAndFixed2
                }}</span
              >
            </div>
          </div>
        </div>
        <div class="content">
          <div class="content-left scrollbar-none">
            <div class="left-heders">
              <div class="content-headers">Newsfeed</div>
              <span class="right-more" @click="viewAll">View All</span>
            </div>
            <NewsList :datas="homeObj.newsFeed_notice" />
          </div>
          <div class="content-right">
            <div class="right-headers">
              <span class="right-title">Latest Brands</span>
              <span class="right-more" @click="gotoBrands">View All</span>
            </div>
            <ul class="right-list scrollbar-none ">
              <li
                class="right-item"
                v-for="(item, index) in homeObj.lastBrands"
                :key="`brands-${index}`"
                @click="gotoBrand(item.m_id)"
              >
                <div class="right-item-icon">
                  <img :src="logo(item.logo)" />
                </div>
                <ul class="right-sublist">
                  <li class="right-subitem">
                    <span class="right-subitem-title">Name</span>
                    <span class="right-subitem-title">Type</span>
                  </li>
                  <li class="right-subitem">
                    <a-tooltip
                      :title="item.sitename.length > 22 ? item.sitename : null"
                    >
                      <span class="right-subitem-val _ellipsis">{{
                        item.sitename
                      }}</span>
                    </a-tooltip>
                    <span class="right-subitem-val">{{ item.adv_type }}</span>
                  </li>
                  <li class="right-subitem right-column">
                    <a-tooltip
                      placement="top"
                      :title="RateInfo"
                      class="right-subitem-rate"
                    >
                      <span class="right-subitem-title">Rate</span>
                      <img
                        class="question-img"
                        src="@/assets/question.png"
                        alt=""
                      />
                    </a-tooltip>
                    <span class="right-subitem-bold _ellipsis">{{
                      item.commission.aff
                    }}</span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>
<script>
import Header from "@/components/public/Header.vue";
import axios from "@/utils/request";
import apis from "@/api/const";
import NewsList from "@/views/Dashboard/NewsList.vue";
const defaultLogo = require("@/assets/default.png");
const RateInfo =
  'When the base commission is displayed as "Rev. Share: 70.00%," it means that LinkPrefer will pay you 70.00% of the sales commission we receive from the merchant.';

export default {
  components: {
    Header,
    NewsList,
  },
  data() {
    return {
      isLoading: false,
      homeObj: {
        commission: {},
        lastBrands: [],
        // newsFeed: [],
      },
      startUnix: 1707926400000, //2-15
      endUnix: 1709049599000, //2-27
      RateInfo,
    };
  },
  mounted() {
    this.getDetail();
    this.afterLogin();
  },
  computed: {
    shouldNotGoBrand() {
      return (
        this.$store.state.userInfo.has_channel_status == 0 ||
        this.$store.state.userInfo.is_check_account
      );
    },
  },
  methods: {
    afterLogin() {
      const now = new Date().getTime();
      if (this.startUnix >= now || now >= this.endUnix) return; //不在2-15 -- 2-27 内不执行
      const loginTag = sessionStorage.getItem("login-time");

      if (!loginTag) {
        this.$store.commit("setShowNotice", true);
      }
    },
    logo(logo) {
      console.log(this.APP_CONFIG.OSS_HOST);
      if (!logo) {
        logo = defaultLogo;
      } else if (logo.indexOf("http") !== 0 && logo.indexOf("//") !== 0) {
        logo = this.APP_CONFIG.OSS_HOST + logo;
      }
      return logo;
    },
    viewAll() {
      this.$router.push({ name: "announcements" });
    },
    gotoBrands() {
      this.$router.push({
        name: "brands",
      });
    },
    gotoBrand(id) {
      //   if (!id || shouldNotGoBrand) {
      //     return;
      //   }
      this.$router.push({
        name: "brand_detail",
        params: {
          id,
        },
      });
    },
    gotoCampaignDetail(id) {
      this.$router.push({
        name: "campaign_detail_preview",
        params: {
          id: id,
        },
      });
    },
    // 获取详情
    getDetail() {
      this.isLoading = true;
      axios.get(apis.dashboard.home, {}).then((res) => {
        this.isLoading = false;
        if (res.code === "0200") {
          this.homeObj = res.payload;
        } else {
          this.$message.error(res.msg || "");
        }
      });
    },
  },
  beforeDestroy() {
    if (this.onWindowResize) {
      window.onresize = null;
    }
  },
};
</script>

<style lang="less" scoped>
.dashbd {
  .headers {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 0.8571rem;
    .block-1 {
      background: #f2f6fe;
      .block-left {
        background: #dde9fe;
      }
      &:hover {
        box-shadow: 0px 0px 8px 0px #cfdeff;
      }
    }
    .block-2 {
      background: #fff6f4;
      .block-left {
        background: #ffe7df;
      }
      &:hover {
        box-shadow: 0px 0px 8px 0px #e8dfd7;
      }
    }
    .block-3 {
      background: #f6f5ff;
      .block-left {
        background: #e9e6fc;
      }
      &:hover {
        box-shadow: 0px 0px 8px 0px #dfddf6;
      }
    }
    .block-4 {
      background: #f2fcf9;
      .block-left {
        background: #ddfbf3;
      }
      &:hover {
        box-shadow: 0px 0px 8px 0px #d0eee5;
      }
    }
    &-block {
      width: calc((100% - 4.2857rem) / 4);
      height: 10.8571rem;
      border-radius: 1.1429rem;
      display: flex;
      align-items: center;
      padding: 1.5714rem;
      .block-left {
        width: 3.5714rem;
        height: 3.5714rem;
        border-radius: 0.5714rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1.4286rem;
        img {
          width: 1.4286rem;
          height: 1.4286rem;
        }
      }
      .block-right {
        display: flex;
        flex-direction: column;
      }
      .block-title {
        font-size: 1.1429rem;
        color: #aaaaaa;
      }
      .block-money {
        font-size: 2rem;
        font-family: "prefer-bold";
        margin-top: 0.8571rem;
      }
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    margin-top: 1.4286rem;
    &-left {
      width: calc(75% - 0.3571rem);
      margin-right: 1.4286rem;
      height: calc(100vh - 20rem);
      background: #ffffff;
      border-radius: 1.1429rem;
      .left-heders {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 24px 16px 24px;
      }
    }
    &-headers {
      font-size: 1.4286rem;
      font-family: "prefer-bold";
      color: #040b32;
    }
    &-list {
      margin-top: 0.8571rem;
    }
    &-item {
      font-size: 1.1429rem;
      margin-bottom: 1.2857rem;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        color: #3762d6;
      }
    }
    &-right {
      width: calc((100% - 4.2857rem) / 4);
      height: calc(100vh - 20rem);
      background: #ffffff;
      border-radius: 1.1429rem;
      padding: 1.7143rem 0;
      .right-headers {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1.7143rem;
        .right-title {
          font-size: 1.4286rem;
          font-family: "prefer-bold";
          color: #040b32;
        }
      }
      .right-list {
        overflow-y: auto;
        height: calc(100% - 2rem);
        padding: 0 1.7143rem 1.7143rem;
        .right-item {
          width: 100%;
          //height: 13.4286rem;
          background: #ffffff;
          box-shadow: 0px 1px 17px 0px rgba(102, 118, 186, 0.15);
          border-radius: 1.1429rem;
          margin-top: 1.2857rem;
          transition: all 0.3s;
          cursor: pointer;
          &:hover {
            //   transform: translateY(-6px);
            box-shadow: 0px 1px 17px 0px rgba(102, 118, 186, 0.3);
          }
          .right-item-icon {
            height: 6.8571rem;
            background: #f9fbfe;
            border-radius: 1.1429rem 1.1429rem 0 0;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 11.4286rem;
              height: 4.2857rem;
              object-fit: contain;
            }
          }
          .right-sublist {
            padding: 0.8571rem 1.1429rem;
            font-size: 1rem;
            color: #333333;
            .right-subitem {
              display: flex;
              gap: 10px;
              justify-content: space-between;
              line-height: 1.7143rem;
              &-title {
                color: #8b8e99;
              }
              &-rate {
                width: fit-content;
              }
              &-val {
                color: #040b32;
              }
              &-bold {
                font-family: "prefer-bold";
                color: #3762d6;
              }
            }
            .right-column {
              flex-direction: column;
              gap: unset;
            }
          }
        }
      }
    }
  }
}
.right-more {
  font-size: 1rem;
  color: #3762d6;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.question-img {
  height: 14px;
  margin-left: 5px;
}
</style>
