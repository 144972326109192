<template>
  <Modal
    :isVisible="isShow"
    :isShowCancel="true"
    title="Creatives Text"
    @close-popup="hideSelect"
    width="834px"
    submitText="Save"
    cancelText="Discard"
    @submit="submitSelect"
  >
    <template #content>
      <div class="camptlist">
        <div class="camptlist-head">
          <a-button type="primary" @click="showAdd">
            <a-icon type="plus" />
            Add
          </a-button>
        </div>
        <a-spin :spinning="isLoading">
          <a-empty v-if="texts.length == 0 && !isLoading" />
          <div v-else class="camptlist-list">
            <TextItem
              v-for="(item, index) in texts"
              :key="`texts-${item.id}-${index}`"
              isEdit="1"
              @text-check="checkItem"
              :text="item.texts[0].text"
              :cid="item.id"
              :title="item.title"
              :toChecked="item.toChecked"
            />
          </div>
          <div class="footer-pagination">
            <a-pagination
              v-model="currentPage"
              :total="totalSize"
              :pageSize="pageSize"
              @change="paginationChange"
            />
          </div>
        </a-spin>
        <Modal
          :isVisible="isShowAdd"
          :isShowCancel="true"
          title="Add Creatives Text"
          @close-popup="hideAdd"
          width="920px"
          submitText="Save"
          cancelText="Discard"
          @submit="submitAdd"
        >
          <template #content>
            <a-spin :spinning="addLoading">
              <div class="camptadd">
                <p>Title</p>
                <a-input placeholder="Please enter title" v-model="addTitle" />
                <p>Text</p>
                <a-textarea
                  :auto-size="{ minRows: 8, maxRows: 10 }"
                  v-model="addText"
                />
              </div>
            </a-spin>
          </template>
        </Modal>
      </div>
    </template>
  </Modal>
</template>

<script>
import axios from "@/utils/request";
import Modal from "@/components/Modal.vue";
import TextItem from "./TextItem.vue";
import APIS from "@/api/const";
import _ from "lodash";

export default {
  components: { Modal, TextItem },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    cid: {
      type: String | Number,
      default: "0",
    },
  },
  computed: {},
  data() {
    return {
      title: "",
      text: "",
      isLoadingModal: false,
      isShowAdd: false,
      texts: [],
      totalSize: 1,
      currentPage: 1,
      pageSize: 9,
      isLoading: false,
      addTitle: "",
      addText: "",
      addLoading: false,
      selectedList: _.cloneDeep(this.$store.state.campaignTexts),
    };
  },
  watch: {
    isShow: {
      handler: function(newVal, oldVal) {
        if (newVal) {
          this.getDataList();
        }
      },
    },
    "$store.state.campaignTexts": {
      handler: function(newVal, oldVal) {
        this.selectedList = _.cloneDeep(newVal);
      },
    },
  },
  methods: {
    retsetData() {
      this.addTitle = "";
      this.addText = "";
    },
    submitAdd() {
      if (!this.addText || !this.addTitle) {
        this.$message.warning("Data can't be null");
        return;
      }
      const addData = {
        content: this.addText,
        title: this.addTitle,
        start_time: Date.parse(new Date()) / 1000,
        end_time: Date.parse(new Date("2050-12-31")) / 1000,
        privacy: "PUBLIC",
        link: "creatives",
        type: "TEXT",
      };
      this.addLoading = true;
      axios.post(APIS.CREATIVE.CREATE, addData).then((res) => {
        this.addLoading = false;
        if (res.code === 0) {
          this.$emit("refreshStats");
          this.isShowAdd = false;
          this.retsetData();
          this.$message.success("Add Success");
          this.currentPage = 1;
          this.getDataList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    resetParams() {
      this.currentPage = 1;
      this.texts = [];
    },
    checkItem(item, ischeck) {
      if (ischeck) {
        this.selectedList.push(item);
      } else {
        for (let i = 0; i < this.selectedList.length; i++) {
          if (this.selectedList[i].cid === item.cid) {
            this.selectedList.splice(i, 1);
            break;
          }
        }
      }
    },
    getDataList() {
      this.isLoading = true;
      this.texts = [];
      axios
        .get(APIS.CREATIVE.LIST, {
          params: {
            type: "TEXT",
            page_num: this.currentPage,
            page_size: this.pageSize,
          },
        })
        .then((res) => {
          this.isLoading = false;
          if (res.code === 0) {
            for (let i = 0; i < res.data.data.length; i++) {
              let isfind = false;
              for (let j = 0; j < this.selectedList.length; j++) {
                if (+res.data.data[i].id === +this.selectedList[j].cid) {
                  isfind = true;
                }
              }
              if (isfind) {
                res.data.data[i].toChecked = true;
              } else {
                res.data.data[i].toChecked = false;
              }
            }
            this.texts = res.data.data;
            console.log("list:", this.texts);
            this.totalSize = parseInt(res.data.total_size);
          } else {
            this.$message.error(res.msg || "");
          }
        });
    },
    paginationChange(page, pageSize) {
      this.currentPage = page;
      this.getDataList();
    },
    submitSelect() {
      if (this.isEditing) {
        this.isLoading = true;
        axios
          .post(APIS.CREATIVE.BATCH_MODIFY, {
            id: this.cid,
            creatives: {
              text: this.selectedList,
            },
          })
          .then((res) => {
            this.isLoading = false;
            if (res.code === 0) {
              this.resetParams();
              this.$emit("on-refresh");
              this.$message.success("Save Success");
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        this.resetParams();
        this.$store.commit("setCampaignTexts", this.selectedList);
        this.$emit("on-close");
      }
    },
    hideSelect() {
      this.resetParams();
      this.$emit("on-close");
    },
    showAdd() {
      this.isShowAdd = true;
    },
    hideAdd() {
      this.isShowAdd = false;
      this.retsetData();
    },
  },
};
</script>

<style lang="less">
.camptlist {
  &-head {
    text-align: right;
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
  }
  .camptadd {
    p {
      font-size: 1.2857rem;
      font-weight: 600;
      color: #333333;
      margin-bottom: 3px;
      margin-top: 20px;
    }
  }
  .modal {
    z-index: 103;
  }
}
</style>
