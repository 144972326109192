<template>
  <li class="headers-item">
    <span style="">Access Token:</span>
    <div style="display:flex;align-items:center;margin-top: 10px;">
      <div>
        <a-select
          placeholder="Please Select a Channel"
          @change="(v) => (access_token = v)"
          style="width:200px"
        >
          <a-select-option
            :value="item.token"
            v-for="item in channels"
            :key="item.id"
          >
            {{ item.medium_id }}
          </a-select-option>
        </a-select>
      </div>
      <div v-show="!!access_token">
        <span style="margin:0 10px;">{{ access_token }}</span>
        <a-icon type="copy" @click="copyCode" />
      </div>
    </div>
  </li>
</template>

<script>
import APIS from "@/api/const";
import axios from "@/utils/request";

export default {
  data() {
    return {
      channels: [],
      access_token: "",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      axios
        .get(APIS.channel.index, {
          params: {},
        })
        .then((resp) => {
          if (resp.code === "0200") {
            this.channels = resp.payload?.filter((e) => e.status == "1");
          }
        });
    },
    copyCode() {
      this.$copyText(this.access_token).then(
        (e) => {
          this.$message.success("Copied");
        },
        (e) => {
          this.$message.error("Can not copy");
        }
      );
    },
  },
};
</script>

<style lang="less" scoped></style>
