<template>
  <div class="list scrollbar-none">
    <router-link
      v-for="item in datas"
      :key="item.n_id"
      :to="{ name: 'announcementDetail', params: { id: item.n_id } }"
    >
      <div class="list-item">
        <img
          class="list-item-left"
          v-if="item.type == '1'"
          src="@/assets/dashboard/reward.svg"
          alt="notice"
        />
        <img
          class="list-item-left"
          v-else-if="item.type == '2'"
          src="@/assets/dashboard/sales.svg"
          alt="notice"
        />
        <img
          class="list-item-left"
          v-else
          src="@/assets/dashboard/notice.svg"
          alt="notice"
        />
        <div class="list-item-right">
          <div class="title" v-if="item.name">{{ item.name }}</div>
          <div
            class="sub"
            :class="[
              item.title.startsWith('【') ? '_mar' : '',
              item.name ? '' : 'title',
            ]"
          >
            <div v-html="item.title"></div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "55haitaoNewsList",
  props: {
    datas: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.list {
  overflow-y: auto;
  height: calc(100% - 7rem);
  &-item {
    display: flex;
    align-items: center;
    height: 5rem;
    background: #f9fbfe;
    border-radius: 8px;
    margin: 0 1.7143rem;
    margin-bottom: 12px;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      background: #f6f9ff;
      margin-left: 16px;
      margin-right: 16px;
    }
    &-left {
      height: 4rem;
      margin-right: 10px;
      margin-left: 18px;
    }
    &-right {
      font-size: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 3.4286rem;
      .sub {
        font-size: 1rem;
        color: #8b8e99;
      }
      .title {
        font-size: 1rem;
        font-family: "prefer-bold";
        font-weight: bold;
        color: #040b32;
        margin-bottom: 6px;
      }
      ._mar {
        margin-left: -8px;
      }
    }
  }
}
</style>
