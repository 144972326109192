export const columns = [
  // { title: 'No.', dataIndex: 'no', key: 'no', scopedSlots: { customRender: 'no' }, width: 60, align: "center" },
  { title: 'Date/Time', dataIndex: 'time', key: 'time', scopedSlots: { customRender: 'time' }, width: 140, align: "center" },
  { title: 'Order ID', dataIndex: 'orderId', key: 'orderId', scopedSlots: { customRender: 'orderId' }, align: "center" },
  { title: 'Publisher', dataIndex: 'publisher', key: 'publisher', scopedSlots: { customRender: 'publisher' }, width: 100, align: "center" },
  { title: 'Coupon', dataIndex: 'couponcode', key: 'couponcode', scopedSlots: { customRender: 'couponcode' }, width: 80, align: "center" },
  { title: 'Sale Amount', dataIndex: 'amount', key: 'amount', scopedSlots: { customRender: 'amount' }, align: 'right', width: 100 },
  { title: 'Commission', dataIndex: 'commission', scopedSlots: { customRender: 'commission' }, align: 'right', width: 110 },
  { title: 'PBoost', dataIndex: 'PartnerBoost', key: 'PartnerBoost', scopedSlots: { customRender: 'PartnerBoost' }, align: 'right', width: 110 },
  { title: 'Total Spend', dataIndex: 'spend', key: 'spend', scopedSlots: { customRender: 'spend' }, align: 'right', width: 110 },
  { title: 'UID', dataIndex: 'UID', key: 'UID', scopedSlots: { customRender: 'UID' }, align: "center", width: 100 },
  { title: 'Action', dataIndex: 'action', key: 'action', scopedSlots: { customRender: 'action' }, align: 'center', width: 60 }
];

export const innerColumns = [
  { title: 'Status', dataIndex: 'Status', key: 'Status', scopedSlots: { customRender: 'Status' }, width: 81, align: "center" },
  { title: 'ID', dataIndex: 'ID', key: 'ID', scopedSlots: { customRender: 'ID' }, width: 140, align: "center" },
  { title: 'SKU ', dataIndex: 'SKU ', key: 'SKU', scopedSlots: { customRender: "SKU"}},
  { title: 'Unit Price', dataIndex: 'UnitPrice', key: 'UnitPrice', scopedSlots: { customRender: 'UnitPrice' }, align: 'right', width: 80 },
  { title: 'Quantity', dataIndex: 'Quantity', key: 'Quantity', scopedSlots: { customRender: 'Quantity' }, align: 'right', width: 100 },
  { title: 'Sale Amount', dataIndex: 'SaleAmount', key: 'SaleAmount', scopedSlots: { customRender: 'SaleAmount' }, align: 'right', width: 110 },
  { title: 'Commssion ', dataIndex: 'Commssion ', key: 'Commssion', scopedSlots: { customRender: 'Commssion' }, align: 'right', width: 110 },
  { title: 'PBoost', dataIndex: 'PartnerBoost', key: 'PartnerBoost', scopedSlots: { customRender: 'PartnerBoost' }, align: 'right', width: 110 },
  { title: 'Total Spend', dataIndex: 'TotalSpend', key: 'TotalSpend', scopedSlots: { customRender: 'TotalSpend' }, align: 'right', width: 100 },
  { title: '', dataIndex: 'Edit', key: 'Edit', scopedSlots: { customRender: 'Edit' }, align: 'center', width: 60 },
];
