<template>
  <div class="campimg" @click.stop="showView">
    <div
      v-if="isEdit === '1'"
      class="campimg-select"
      :class="isChecked ? '' : 'has-border'"
      @click="checkItem"
    >
      <i v-show="isChecked"></i>
    </div>
    <div v-else-if="isEdit === '2'" class="campimg-mask">
      <a-icon type="close" @click="deleteImage" />
      <p>
        <span>{{ title }}</span
        ><span>{{ width }}x{{ height }}</span>
      </p>
    </div>
    <img :src="imgUrl" />
    <div v-if="isShowModal" class="campimg-pop" @click.stop="hideView">
      <div class="campimg-pop-content">
        <img :src="imgUrl" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    cid: {
      type: [String, Number],
      default: "",
    },
    id: {
      type: [String, Number],
      default: "",
    },
    imgUrl: {
      type: String,
      default: "",
    },
    isEdit: {
      type: String,
      default: "",
    },
    toChecked: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "",
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    canPop: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  data() {
    return {
      isLoadingModal: false,
      isChecked: this.toChecked,
      isShowModal: false,
    };
  },
  watch: {},
  created() {},
  methods: {
    hideView() {
      this.isShowModal = false;
    },
    showView() {
      if (this.canPop) {
        this.isShowModal = true;
      }
    },
    deleteImage() {
      if (this.isEditing) {
        this.$emit("delete-image", this.id);
      } else {
        this.$store.dispatch("removeCampaignCreatives", {
          type: 2,
          cid: this.cid,
        });
      }
    },
    checkItem() {
      this.isChecked = !this.isChecked;
      this.$emit(
        "image-check",
        {
          creative_id: this.cid,
          cid: this.cid,
          imgUrl: this.imgUrl,
          title: this.title,
          width: this.width,
          height: this.height,
        },
        this.isChecked
      );
    },
  },
};
</script>

<style lang="less" scoped>
.campimg {
  border-radius: 12px;
  border: 1px solid #e6e6e6;
  position: relative;
  margin-right: 12px;
  margin-bottom: 16px;
  width: 238px;
  height: 144px;
  cursor: pointer;
  &-pop {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    &-content {
      width: 1000px;
      height: 680px;
      position: relative;
      background: #000;
      img {
        width: 1000px;
        height: 680px;
        object-fit: contain;
        object-position: center;
      }
    }
  }
  &-mask {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    border-radius: 12px;
    display: none;
    > i {
      color: #fff;
      position: absolute;
      right: 5px;
      top: 5px;
      font-size: 1.1429rem;
      cursor: pointer;
    }
    > p {
      color: #fff;
      position: absolute;
      bottom: 0;
      width: 100%;
      margin-bottom: 6px;
      span {
        margin-left: 16px;
      }
    }
  }
  &-select {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #fff;
    &.has-border {
      border: 1px solid #ccc;
    }
    > i {
      color: #2898ff;
      font-size: 1.2857rem;
      display: inline-block;
      width: 18px;
      height: 18px;
      background: url("~@/assets/check-box-checked.svg") center/contain
        no-repeat;
    }
  }
  &:hover {
    .campimg-mask {
      display: block;
    }
  }
  > img {
    width: 100%;
    object-fit: contain;
    object-position: center;
    height: 100%;
    border-radius: 12px;
  }
}
</style>
