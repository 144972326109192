<template>
  <div class="payments">
    <Header collection="Payments"></Header>
    <div class="container">
      <div class="headers">
        <div class="headers-block block-1">
          <div class="block-left">
            <img src="@/assets/payments/icon-goldcoins.svg" />
          </div>
          <div class="block-right">
            <span class="block-title">Account Balance</span>
            <span class="block-money"
              >${{ balance.left_balance | toThousandsAndFixed2 }}</span
            >
          </div>
        </div>
        <div class="headers-block block-3">
          <div class="block-left">
            <img src="@/assets/dashboard/icon-income-4.svg" />
          </div>
          <div class="block-right">
            <span class="block-title">In Progress</span>
            <span class="block-money"
              >${{ balance.frozen_balance | toThousandsAndFixed2 }}</span
            >
          </div>
        </div>
        <div class="headers-block block-4">
          <div class="block-left">
            <img src="@/assets/dashboard/icon-income-3.svg" />
          </div>
          <div class="block-right">
            <span class="block-title">Available for Withdrawal</span>
            <span class="block-money"
              >${{ balance.balance | toThousandsAndFixed2 }}</span
            >
          </div>
        </div>
      </div>
      <div class="content">
        <div class="comm-tables">
          <div class="comm-header">
            <div class="comm-header-left">
              <span>Commission Generated</span>
            </div>
            <div class="comm-header-rigth">
              <a-select
                :default-value="yearList[0]"
                :getPopupContainer="
                  (triggerNode) => {
                    return triggerNode.parentNode || document.body;
                  }
                "
                v-model="year"
                @change="handleYearChange"
              >
                <a-select-option
                  :value="item"
                  v-for="item in yearList"
                  :key="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="comm-list">
            <a-table
              class="comtable"
              :columns="columns"
              :data-source="financeList"
              :rowKey="(record, index) => index"
              :loading="financesLoading"
              :pagination="false"
            >
            </a-table>
            <!-- <div class="footer-pagination">
              <a-pagination
                v-model="currentPage"
                :total="102"
                show-less-items
                @change="paginationChange"
              />
            </div> -->
          </div>
        </div>
        <div class="comm-tables">
          <div class="comm-header">
            <div class="comm-header-left">
              <span>Commission Details</span>
            </div>
            <!-- <div class="comm-header-rigth">
              <span class="comm-header-more">more <a-icon type="right"/></span>
            </div> -->
          </div>
          <div class="comm-list">
            <a-table
              class="comtable"
              :columns="withdrawColumns"
              :data-source="withdrawList"
              :rowKey="(record, index) => record.id"
              :loading="withdrawsLoading"
              :pagination="false"
            >
              <a
                slot="id"
                v-if="record.id"
                :href="`${current_base_url}/payments/history?w_id=${record.id}`"
                slot-scope="text, record"
                >{{ text }}</a
              >
              <span v-else slot="id" slot-scope="text, record">{{
                record
              }}</span>
              <a
                slot="action"
                :href="`${current_base_url}/reporting/cps?w_id=${record.id}`"
                slot-scope="text, record"
                >View Orders</a
              >
            </a-table>
            <div class="tb-total">
              <span class="tb-total-text"
                >total: <span class="tb-total-money">${{ total }}</span></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/public/Header.vue";
import axios from "@/utils/request";
import apis from "@/api/const";

export default {
  filters: {
    filterStaus(status) {
      if (status == "new") {
        return "Unpaid";
      } else if (status == "paid") {
        return "Paid";
      } else {
        return "Void";
      }
    },
  },
  components: {
    Header,
    // Modal
  },
  data() {
    let yearList = ["2022", "2023"];
    let columns = [
      {
        title: "Date",
        dataIndex: "date",
        width: 160,
        align: "center",
      },
      {
        title: "Commission Generated",
        dataIndex: "cashback",
        width: 180,
        align: "center",
        customRender: (text) => `$${text}`,
      },
      {
        title: "Commission Applied",
        dataIndex: "amount",
        align: "center",
        customRender: (text) => `$${text}`,
      },
      {
        title: "Transaction Fee",
        dataIndex: "service_charge",
        align: "center",
        customRender: (text) => `$${text}`,
      },
      {
        title: "Balance",
        dataIndex: "balance",
        align: "center",
        customRender: (text) => `$${text}`,
      },
    ];
    let withdrawColumns = [
      {
        title: "Withdrawal ID",
        dataIndex: "id",
        align: "center",
        slots: { title: "id" },
        scopedSlots: { customRender: "id" },
      },
      {
        title: "Date",
        dataIndex: "created_date",
        align: "center",
      },
      {
        title: "Payment Date",
        dataIndex: "pay_time",
        align: "center",
      },
      {
        title: "Status",
        dataIndex: "status",
        align: "center",
      },
      {
        title: "Account Name",
        dataIndex: "username",
        align: "center",
      },
      {
        title: "Bank Name",
        dataIndex: "bankname",
        align: "center",
      },
      {
        title: "Withdraw Account",
        dataIndex: "accountcard",
        align: "center",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        align: "center",
        customRender: (text) => `$${text}`,
      },
      {
        title: "Transaction Fee",
        dataIndex: "service_charge",
        align: "center",
        customRender: (text) => `$${text}`,
      },
      {
        title: "Action",
        dataIndex: "action",
        align: "center",
        slots: { title: "action" },
        scopedSlots: { customRender: "action" },
      },
    ];
    return {
      year: yearList[0],
      yearList,
      total: 0,
      balance: {
        balance: 0,
        frozen_balance: 0,
        left_balance: 0,
      },
      columns,
      withdrawColumns,
      financesLoading: false,
      financeList: [],
      withdrawsLoading: false,
      withdrawList: [],
      currentPage: 1,
      current_base_url: window.location.origin,
    };
  },
  mounted() {
    this.getFinanceInfo();
    this.getFinanceList();
    this.getWithdrawList();
  },
  methods: {
    handleYearChange() {
      this.getFinanceList();
    },
    getFinanceInfo() {
      axios.get(apis.payments.user_finance_info, {}).then((res) => {
        if (res.code === "0200") {
          this.balance = res.payload.balance;
        } else {
          this.$message.error(res.msg || "");
        }
      });
    },
    getFinanceList() {
      this.financesLoading = true;
      axios
        .get(apis.payments.finance_list, { params: { year: this.year } })
        .then((res) => {
          this.financesLoading = false;
          if (res.code === "0200") {
            this.financeList = res.payload.list;
          } else {
            this.$message.error(res.msg || "");
          }
        });
    },
    getWithdrawList() {
      this.withdrawsLoading = true;
      axios.get(apis.payments.with_draw_list, {}).then((res) => {
        this.withdrawsLoading = false;
        if (res.code === "0200") {
          this.withdrawList = res.payload.list;
          this.total = res.payload.total;
        } else {
          this.$message.error(res.msg || "");
        }
      });
    },
  },
  beforeDestroy() {
    if (this.onWindowResize) {
      window.onresize = null;
    }
  },
};
</script>

<style lang="less" scoped>
.payments {
  .headers {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 0.8571rem;
    .block-1 {
      background: #f2f6fe;
      box-shadow: 0px 0px 8px 0px rgba(46, 84, 168, 0.1);
      .block-left {
        background: #dde9fe;
      }
      &:hover {
        box-shadow: 0px 0px 8px 0px #cfdeff;
      }
    }
    .block-3 {
      background: #f6f5ff;
      box-shadow: 0px 0px 8px 0px rgba(140, 130, 229, 0.15);
      .block-left {
        background: #e9e6fc;
      }
      &:hover {
        box-shadow: 0px 0px 8px 0px #dfddf6;
      }
    }
    .block-4 {
      background: #f2fcf9;
      box-shadow: 0px 0px 8px 0px rgba(113, 215, 181, 0.25);
      .block-left {
        background: #ddfbf3;
      }
      &:hover {
        box-shadow: 0px 0px 8px 0px #d0eee5;
      }
    }
    &-block {
      width: calc((100% - 2.8571rem) / 3);
      height: 10.8571rem;
      border-radius: 1.1429rem;
      display: flex;
      align-items: center;
      padding: 1.5714rem;
      .block-left {
        width: 3.5714rem;
        height: 3.5714rem;
        border-radius: 0.5714rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1.4286rem;
        img {
          width: 1.4286rem;
          height: 1.4286rem;
        }
      }
      .block-right {
        display: flex;
        flex-direction: column;
      }
      .block-title {
        font-size: 1.1429rem;
        color: #aaaaaa;
      }
      .block-money {
        font-size: 2rem;
        font-family: "prefer-bold";
        margin-top: 0.8571rem;
      }
    }
  }
  .content {
    margin-top: 1.4286rem;
    .comm-tables {
      margin-top: 1.4286rem;
    }
    .comm-header-left {
      font-size: 1.2857rem;
      font-family: "prefer-bold";
    }
    .comm-header-more {
      color: #333333;
      cursor: pointer;
      i {
        font-size: 0.7143rem;
      }
    }
    .tb-total {
      padding: 16px;
      display: flex;
      justify-content: flex-end;
      font-family: "prefer-bold";
      &-text {
        color: #666666;
      }
      &-money {
        color: #3762d6;
      }
    }
  }
  /deep/ .ant-select {
    width: 7.1429rem;
  }
  /deep/ .ant-select-selection--single {
    border-radius: 19px;
    border: 1px solid #3762d6;
    box-shadow: none !important;
  }
  /deep/ .ant-select-selection__rendered {
    margin-left: 1.8571rem;
  }
  /deep/ .ant-select-selection-selected-value,
  /deep/ .ant-select-arrow-icon {
    color: #3762d6;
  }
  /deep/ .ant-select-dropdown-menu-item {
    text-align: center;
  }
}
</style>
