<template>
  <div class="addbank">
    <Header collection="My Account"></Header>
    <Brands
      path="/account/payment-setting/select"
      collection="Payment Methods"
      subset="Add Payment Methods"
    ></Brands>
    <div class="container">
      <a-form
        :form="formInfo"
        :label-col="{ span: 12 }"
        :wrapper-col="{ span: 12 }"
        @submit="handleSubmit"
      >
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item label="Bank Location" labelAlign="left" :colon="false">
              <a-select
                :disabled="isDisabled"
                v-decorator="[
                  'source',
                  {
                    initialValue: init.source,
                    rules: [
                      {
                        required: true,
                        message: 'Please select a Bank Location',
                      },
                    ],
                  },
                ]"
                placeholder="Please select a Bank Location"
                @change="(v) => (source = v)"
              >
                <a-select-option value="0">
                  Within Mainland China
                </a-select-option>
                <a-select-option value="1">
                  Outside Mainland China
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item label="Account Type" labelAlign="left" :colon="false">
              <a-select
                :disabled="isDisabled"
                v-decorator="[
                  'mtype',
                  {
                    initialValue: init.mtype,
                    rules: [
                      {
                        required: true,
                        message: 'Please select a Account Type',
                      },
                    ],
                  },
                ]"
                placeholder="Please select a Account Type"
                @change="(v) => (mtype = v)"
              >
                <a-select-option value="0"> Personal </a-select-option>
                <a-select-option value="1"> Business </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item
              label="Withdraw Threshold"
              labelAlign="left"
              :colon="false"
            >
              <a-select
                v-decorator="[
                  'threshold',
                  {
                    initialValue: init.threshold,
                    rules: [
                      {
                        required: true,
                        message: 'Please select a Withdraw Threshold',
                      },
                    ],
                  },
                ]"
                placeholder="Please select a Withdraw Threshold"
              >
                <a-select-option value="50">$50</a-select-option>
                <a-select-option value="100">$100</a-select-option>
                <a-select-option value="500">$500</a-select-option>
                <a-select-option value="1000">$1000</a-select-option>
                <a-select-option value="2500">$2500</a-select-option>
                <a-select-option value="5000">$5000</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <!-- 国内个人 -->
        <a-row :gutter="24" v-if="source == 0 && mtype == 0">
          <a-col :span="12">
            <a-form-item label="Bank Name" labelAlign="left" :colon="false">
              <a-select
                :disabled="isDisabled"
                v-decorator="[
                  'bankname',
                  {
                    initialValue: init.bankname,
                    rules: [
                      { required: true, message: 'Please select a Bank Name' },
                    ],
                  },
                ]"
                placeholder="Please select a Bank Name"
              >
                <a-select-option
                  v-for="item in banks"
                  :key="item"
                  :value="item"
                  >{{ item }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <!-- 国内省市 -->
        <a-row :gutter="24" v-if="source == 0">
          <a-col :span="12">
            <a-form-item
              label="Location of Bank"
              labelAlign="left"
              :colon="false"
            >
              <a-select
                :disabled="isDisabled"
                v-decorator="[
                  'province',
                  {
                    initialValue: init.province,
                    rules: [
                      { required: true, message: 'Please select a province' },
                    ],
                  },
                ]"
                placeholder="Please select a province"
                @change="selectProvince"
              >
                <a-select-option
                  v-for="(item, index) in provinces"
                  :key="index"
                  :value="item.name"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12" class="form-rt">
            <a-form-item>
              <a-select
                :disabled="isDisabled"
                v-decorator="[
                  'city',
                  {
                    initialValue: init.city,
                    rules: [
                      { required: true, message: 'Please select a city' },
                    ],
                  },
                ]"
                placeholder="Please select a city"
                @change="selectCity"
              >
                <a-select-option
                  v-for="item in citys"
                  :key="item.name"
                  :value="item.name"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <!-- 国外省市 -->
        <a-row :gutter="24" v-if="source == 1">
          <a-col :span="12">
            <a-form-item
              label="Location of Bank"
              labelAlign="left"
              :colon="false"
            >
              <a-select
                :disabled="isDisabled"
                v-decorator="[
                  'country_id',
                  {
                    initialValue: init.country_id,
                    rules: [
                      { required: true, message: 'Please select a country' },
                    ],
                  },
                ]"
                @change="handleCountryChange"
                placeholder="Please select a country"
              >
                <a-select-option
                  v-for="(item, index) in countrys"
                  :key="index"
                  :value="item.id"
                  >{{ item.name_en }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12" class="form-rt" v-if="isRegionShow">
            <a-form-item>
              <!-- <a-input
                v-decorator="[
                  `region_id`,
                  {
                    initialValue: init.region_id,
                    rules: [{ required: true, message: 'State/Province' }],
                  },
                ]"
                placeholder="State/Province"
              /> -->
              <a-select
                :disabled="isDisabled"
                v-decorator="[
                  'region_id',
                  {
                    initialValue: init.country_id,
                    rules: [{ required: true, message: 'State/Province' }],
                  },
                ]"
                placeholder="State/Province"
              >
                <a-select-option
                  v-for="(item, index) in regions"
                  :key="index"
                  :value="item.id"
                  >{{ item.name_en }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row class="form-line" :gutter="24">
          <!-- 国内地区 -->
          <a-col class="form-col" :span="12" v-if="source == 0">
            <div class="left-label"></div>
            <a-form-item>
              <a-select
                :disabled="isDisabled"
                class="form-item-width"
                v-decorator="[
                  'cmbArea',
                  {
                    initialValue: init.cmbArea,
                    rules: [
                      { required: true, message: 'Please select a Area' },
                    ],
                  },
                ]"
                placeholder="Please select a Area"
              >
                <a-select-option
                  v-for="item in cmbAreas"
                  :key="item.name"
                  :value="item.name"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <!-- 国外地区 -->
          <a-col class="form-col" :span="12" v-else>
            <div class="left-label"></div>
            <a-form-item>
              <a-input
                :disabled="isDisabled"
                class="form-item-width"
                v-decorator="[
                  `bankcity`,
                  {
                    initialValue: init.bankcity,
                    rules: [{ required: true, message: 'City' }],
                  },
                ]"
                placeholder="City"
              />
            </a-form-item>
          </a-col>

          <!-- 国外 -->
          <a-col class="form-rt" :span="12" v-if="source == 1">
            <a-form-item>
              <a-input
                :disabled="isDisabled"
                v-decorator="[
                  `bankaddr`,
                  {
                    initialValue: init.bankaddr,
                    rule: {
                      required: true,
                      message: 'Please input Address',
                    },
                  },
                ]"
                placeholder="Address 1"
              />
            </a-form-item>
          </a-col>

          <!-- 以下字段存疑 -->
          <!-- <a-col :span="12">
            <a-auto-complete
              class="form-location"
              v-decorator="['country_id', {}]"
              style="width: 200px"
              placeholder="input here"
              @select="onLocationSelect"
              @search="onLocationSearch"
            >
              <template slot="dataSource">
                <a-select-option
                  v-for="item in showList"
                  :key="item.id"
                  :text="item.name_en"
                >
                  {{ item.name_en }}
                </a-select-option>
              </template></a-auto-complete
            >
          </a-col> -->
        </a-row>

        <!-- 国外 -->
        <!-- <a-row class="form-line" :gutter="24" v-if="source == 1">
          <a-col class="form-col " :span="12">
            <a-form-item>
              <a-input
                class="form-item-width"
                v-decorator="[`bankAddress2`]"
                placeholder="Address2"
              />
            </a-form-item>
          </a-col>
        </a-row> -->

        <!-- 国外 -->
        <a-row :gutter="24" v-if="source == 1">
          <a-col :span="12">
            <a-form-item
              label="Types of Account"
              labelAlign="left"
              :colon="false"
            >
              <a-select
                :disabled="isDisabled"
                v-decorator="[
                  'account_type',
                  {
                    initialValue: init.account_type,
                    rules: [
                      {
                        required: true,
                        message: 'Please select a Types of Account',
                      },
                    ],
                  },
                ]"
                placeholder="Please select a Types of Account"
              >
                <a-select-option value="2">Checking</a-select-option>
                <a-select-option value="1">Saving</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <!-- 国外个人 -->
        <a-row :gutter="24" v-if="source == 1 && mtype == 0">
          <a-col :span="12">
            <a-form-item
              label="Are you an United State tax payer?"
              labelAlign="left"
              :colon="false"
            >
              <a-select
                :disabled="isDisabled"
                v-decorator="[
                  'is_us_tax',
                  {
                    initialValue: init.is_us_tax,
                    rules: [{ required: true, message: 'Please select' }],
                  },
                ]"
                placeholder="Please select"
              >
                <a-select-option value="1">Yes</a-select-option>
                <a-select-option value="0">No</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <!-- 国外 -->
        <a-row :gutter="24" v-if="source == 1">
          <a-col :span="12">
            <a-form-item label="Swiftcode/ABA" labelAlign="left" :colon="false">
              <a-input
                :disabled="isDisabled"
                v-decorator="[
                  `swiftcode`,
                  {
                    initialValue: init.swiftcode,
                    rules: [
                      { required: true, message: 'Please input Swiftcode' },
                    ],
                  },
                ]"
                placeholder=""
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item
              label="Name of the Branch"
              labelAlign="left"
              :colon="false"
            >
              <a-input
                :disabled="isDisabled"
                v-decorator="[
                  `accountname`,
                  {
                    initialValue: init.accountname,
                    rules: [
                      {
                        required: true,
                        message: 'Please input Name of the Branch',
                      },
                    ],
                  },
                ]"
                placeholder=""
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <!-- 国内 Card No. 国外：Account No.  -->
            <a-form-item
              :label="`${source == 0 ? 'Card' : 'Account'} No.`"
              labelAlign="left"
              :colon="false"
            >
              <a-input
                :disabled="isDisabled"
                v-decorator="[
                  'accountcard',
                  {
                    initialValue: init.accountcard,
                    rules: [
                      {
                        required: true,
                        message: `Please input ${
                          source == 0 ? 'Card' : 'Account'
                        } No.`,
                      },
                    ],
                  },
                ]"
                placeholder=""
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item
              :label="`${
                mtype == 0 ? 'Name of Account Holder' : 'Business Account Name'
              }`"
              labelAlign="left"
              :colon="false"
            >
              <a-input
                :disabled="isDisabled"
                v-decorator="[
                  `username`,
                  {
                    initialValue: init.username,
                    rules: [
                      {
                        required: true,
                        message: `Please input ${
                          mtype == 0
                            ? 'Name of Account Holder'
                            : 'Business Account Name'
                        }`,
                      },
                    ],
                  },
                ]"
                placeholder=""
              />
            </a-form-item>
          </a-col>
        </a-row>

        <!-- 国外 -->
        <div v-if="source == 1">
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-item
                label="Billing Address"
                labelAlign="left"
                :colon="false"
              >
                <a-input
                  :disabled="isDisabled"
                  v-decorator="[
                    'nickname',
                    {
                      initialValue: init.nickname,
                      rules: [
                        { required: true, message: 'Please input nickname' },
                      ],
                    },
                  ]"
                  placeholder="name"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row class="form-line" :gutter="24">
            <a-col class="form-col" :span="12">
              <div class="left-label"></div>
              <a-form-item>
                <a-select
                  :disabled="isDisabled"
                  class="form-item-width"
                  v-decorator="[
                    'user_country_id',
                    {
                      initialValue: init.user_country_id,
                      rules: [
                        { required: true, message: 'Please select Country' },
                      ],
                    },
                  ]"
                  placeholder="Country"
                >
                  <a-select-option
                    v-for="(item, index) in countrys"
                    :key="index"
                    :value="item.id"
                    >{{ item.name_en }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12" class="form-rt">
              <a-form-item>
                <a-input
                  :disabled="isDisabled"
                  class="form-item-width"
                  v-decorator="[
                    `user_province`,
                    {
                      initialValue: init.user_province,
                      rules: [{ required: true, message: 'Please input city' }],
                    },
                  ]"
                  placeholder="State/Province"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row class="form-line" :gutter="24">
            <a-col class="form-col" :span="12">
              <div class="left-label"></div>
              <a-form-item>
                <a-input
                  :disabled="isDisabled"
                  class="form-item-width"
                  v-decorator="[
                    `user_city`,
                    {
                      initialValue: init.user_city,

                      rules: [{ required: true, message: 'Please input City' }],
                    },
                  ]"
                  placeholder="City"
                />
              </a-form-item>
            </a-col>
            <a-col class="form-rt" :span="12">
              <a-form-item>
                <a-input
                  :disabled="isDisabled"
                  class="form-item-width"
                  v-decorator="[
                    `user_addr`,
                    {
                      initialValue: init.user_addr,
                      rules: [
                        { required: true, message: 'Please input address' },
                      ],
                    },
                  ]"
                  placeholder="Address 1"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row class="form-line" :gutter="24">
            <a-col class="form-col" :span="12">
              <div class="left-label"></div>
              <a-form-item>
                <a-input
                  :disabled="isDisabled"
                  class="form-item-width"
                  v-decorator="[
                    `postal_code`,
                    {
                      initialValue: init.postal_code,
                      rules: [
                        { required: true, message: 'Please input Zip Code' },
                      ],
                    },
                  ]"
                  placeholder="Zip Code"
                />
              </a-form-item>
            </a-col>
          </a-row>
        </div>

        <!-- 国内个人 -->
        <a-row :gutter="24" v-if="source == 0 && mtype == 0">
          <a-col :span="12">
            <a-form-item
              label="Resident Identity Card No."
              labelAlign="left"
              :colon="false"
            >
              <a-input
                :disabled="isDisabled"
                v-decorator="[
                  `usernumber`,
                  {
                    initialValue: init.usernumber,
                    rules: [
                      {
                        required: true,
                        message: 'Please input Identity Card No.',
                      },
                    ],
                  },
                ]"
                placeholder=""
              />
            </a-form-item>
          </a-col>
        </a-row>

        <!-- 国内个人 -->
        <a-row :gutter="24" v-if="source == 0 && mtype == 0">
          <a-col :span="12">
            <a-form-item
              label="Resident Identity Card Expiration Date"
              labelAlign="left"
              :colon="false"
            >
              <a-date-picker
                :disabled="isDisabled"
                class="form-item-width"
                v-decorator="[
                  'idcard_end_time',
                  {
                    initialValue: init.idcard_end_time,
                    rules: [{ required: true, message: 'Please select  Date' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <!-- 国内个人 -->
        <a-row :gutter="24" v-if="source == 0 && mtype == 0">
          <div class="upload">
            <div class="upload-label">Image of Resident Identity Card</div>
            <div class="upload-right">
              <div class="upload-right-first">
                <a-form-item>
                  <a-upload
                    :disabled="isDisabled"
                    name="filename"
                    list-type="picture-card"
                    class="avatar-uploader"
                    :show-upload-list="false"
                    @change="(info) => handleChangeUpload(info, 'userpic1')"
                    :action="uploadUrl"
                    :headers="headers"
                    :data="{ type: 'image' }"
                    accept=".jpg,.png,.jpeg"
                  >
                    <img
                      class="upload-img"
                      v-if="userpic1"
                      :src="userpic1"
                      alt="avatar"
                    />
                    <div v-else>
                      <a-icon :type="loading ? 'loading' : 'plus'" />
                      <div class="ant-upload-text">front</div>
                    </div>
                  </a-upload>
                  <a-input
                    :disabled="isDisabled"
                    style="display: none"
                    v-decorator="[
                      'userpic1',
                      {
                        initialValue: init.userpic1,
                        rules: [
                          {
                            required: true,
                            message: 'Please upload front of Identity card',
                          },
                        ],
                      },
                    ]"
                  ></a-input>
                </a-form-item>
              </div>

              <div>
                <a-upload
                  :disabled="isDisabled"
                  name="filename"
                  list-type="picture-card"
                  class="avatar-uploader"
                  :show-upload-list="false"
                  @change="(info) => handleChangeUpload(info, 'userpic2')"
                  :action="uploadUrl"
                  :headers="headers"
                  :data="{ type: 'image' }"
                  accept=".jpg,.png,.jpeg"
                >
                  <img
                    class="upload-img"
                    v-if="userpic2"
                    :src="userpic2"
                    alt="avatar"
                  />
                  <div v-else>
                    <a-icon :type="loading ? 'loading' : 'plus'" />
                    <div class="ant-upload-text">back</div>
                  </div>
                </a-upload>
                <a-form-item>
                  <a-input
                    :disabled="isDisabled"
                    style="display: none"
                    v-decorator="[
                      'userpic2',
                      {
                        initialValue: init.userpic2,
                        rules: [
                          {
                            required: true,
                            message: 'Please upload back of Identity card',
                          },
                        ],
                      },
                    ]"
                  ></a-input>
                </a-form-item>
              </div>
            </div>
          </div>
        </a-row>

        <!-- 国内企业 -->
        <a-row :gutter="24" v-if="source == 0 && mtype == 1">
          <a-col :span="12">
            <a-form-item
              label="Business License No."
              labelAlign="left"
              :colon="false"
            >
              <a-input
                :disabled="isDisabled"
                v-decorator="[
                  `usernumber`,
                  {
                    initialValue: init.usernumber,
                    rules: [
                      {
                        required: true,
                        message: 'Pleace input Business License No.',
                      },
                    ],
                  },
                ]"
                placeholder=""
              />
            </a-form-item>
          </a-col>
        </a-row>

        <!-- 国内企业 -->
        <a-row :gutter="24" v-if="source == 0 && mtype == 1">
          <a-col :span="12">
            <a-form-item
              label="Image of Business License"
              labelAlign="left"
              :colon="false"
            >
              <a-upload
                :disabled="isDisabled"
                name="filename"
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                @change="(info) => handleChangeUpload(info, 'userpic1')"
                :action="uploadUrl"
                :headers="headers"
                :data="{ type: 'image' }"
                accept=".jpg,.png,.jpeg"
              >
                <img
                  class="upload-img"
                  v-if="userpic1"
                  :src="userpic1"
                  alt="avatar"
                />
                <div v-else>
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                </div>
              </a-upload>
              <a-input
                :disabled="isDisabled"
                style="display: none"
                v-decorator="[
                  'userpic1',
                  {
                    initialValue: init.userpic1,
                    rules: [
                      {
                        required: true,
                        message: 'Please upload Image of Business License',
                      },
                    ],
                  },
                ]"
              ></a-input>
            </a-form-item>
          </a-col>
        </a-row>

        <!-- 国内企业 -->
        <a-row :gutter="24" v-if="source == 0 && mtype == 1">
          <a-col :span="12">
            <a-form-item
              label="Image of Operation Certificate"
              labelAlign="left"
              :colon="false"
            >
              <a-upload
                :disabled="isDisabled"
                name="filename"
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                @change="(info) => handleChangeUpload(info, 'userpic2')"
                :action="uploadUrl"
                :headers="headers"
                :data="{ type: 'image' }"
                accept=".jpg,.png,.jpeg"
              >
                <img
                  class="upload-img"
                  v-if="userpic2"
                  :src="userpic2"
                  alt="avatar"
                />
                <div v-else>
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                </div>
              </a-upload>
              <a-input
                :disabled="isDisabled"
                style="display: none"
                v-decorator="[
                  'userpic1',
                  {
                    initialValue: init.userpic1,
                    rules: [
                      {
                        required: true,
                        message: 'Please upload Image of Operation Certificate',
                      },
                    ],
                  },
                ]"
              ></a-input>
            </a-form-item>
          </a-col>
        </a-row>

        <!-- 国外 -->
        <a-row :gutter="24" v-if="source == 1">
          <a-col :span="12">
            <a-form-item labelAlign="left" :colon="false">
              <div slot="label">
                <span
                  style="
                    color: #f5222d;
                    font-size: 14px;
                    font-family: SimSun, sans-serif;
                  "
                  >*</span
                >
                Tax Info
              </div>
              <div class="form-upload form-item-lg" @click="showUpload = true">
                Upload
              </div>
              <!-- 较验 -->
              <a-input style="display: none"></a-input>
            </a-form-item>
          </a-col>
        </a-row>

        <a-button class="btn-add" type="primary" html-type="submit"
          >Save</a-button
        >
      </a-form>
    </div>

    <UploadModel
      @onSubmit="onSubmit"
      :showUpload="showUpload"
      @onClose="showUpload = false"
      :init="detail"
    />
  </div>
</template>
<script>
import Header from "@/components/public/Header.vue";
import Brands from "@/components/Brands.vue";
import Modal from "@/components/Modal.vue";
import axios from "@/utils/request";
import moment from "moment-timezone";
import { uploadUrl } from "@/utils/config";
import apis from "@/api/const";
import UploadModel from "./UploadModel.vue";

export default {
  components: {
    Header,
    Brands,
    Modal,
    UploadModel,
  },
  data() {
    return {
      provinces: [], //省
      citys: [], // 市
      cmbAreas: [], //区
      countrys: [], //国家
      regions: [], //美国的州
      banks: [],
      loading: false,
      uploadUrl,
      headers: {
        "Lp-Authorization": this.$store.state.token,
      },
      userpic1: "",
      userpic2: "",
      imageUrl: "",
      source: "0", // 国内/外
      mtype: "0", //个人/企业
      tax_number: "", //说明：税号
      file: "", //税务文件
      name: "", //文件名
      formInfo: this.$form.createForm(this, { name: "form-bank" }),
      showList: [],
      eid: "",
      isRegionShow: false, //美国的州下拉框是否展示
      init: {
        source: "0",
        mtype: "0",
        threshold: "50",
      },
      detail: {},
      showUpload: false,
      fileInfo: null,
    };
  },
  computed: {
    isDisabled() {
      return this.detail.status == 1;
    },
  },
  mounted() {
    this.getBanck();
    this.getCity();
    this.getForeignCountry();
    this.getForeignArea("1");
    let { id } = this.$route.params;
    if (id) {
      this.eid = id;
      this.getAccountDetailById(id);
    }
  },
  methods: {
    onSubmit(val) {
      this.fileInfo = val;
      this.formInfo.setFieldsValue({ taxInfo: "1" });
    },
    validateFile() {
      console.log(this.fileInfo);
      if (this.source == 0) return true;
      if (
        !this.fileInfo?.file ||
        !this.fileInfo?.tax_number ||
        (!this.fileInfo?.tax_from_type && this.fileInfo.tax_from_type != 0)
      ) {
        this.$message.error("please input a tax info");
        return false;
      }
      return true;
    },
    handleCountryChange(id) {
      this.isRegionShow = false;
      if (id == "1") {
        this.isRegionShow = true;
      }
    },
    getAccountDetailById(id) {
      axios
        .get(apis.paymentSeting.account_detail, { params: { id } })
        .then((res) => {
          if (res.code === "0200") {
            this.handleEdit(res.payload);
          } else {
            this.$message.error(res.msg || "");
          }
        });
    },
    handleEdit(res) {
      this.detail = res;
      let {
        mtype,
        source,
        idcard_end_time,
        area,
        identityendfile,
        identityfrondfile,
        tax_number,
        tax_file,
        file_type,
        identitycard,
        province,
        city,
        country_id,
        licenseimage,
        businesslicence,
        businessimage,
      } = res;
      this.fileInfo = {
        tax_number: tax_number,
        file: tax_file,
        tax_from_type: file_type,
      };

      /* 出参对应关系
        tax_from_type:file_type 
        cmbArea: area
        file: tax_file
        国内个人：
        userpic1: identityfrondfile,
        userpic2: identityendfile,
        usernumber: identitycard,
        国内企业
        userpic1: licenseimage,
        userpic2: businessimage,
        usernumber: businesslicence,
      */
      //编辑处理
      this.source = source;
      this.mtype = mtype;
      let handle = {
        idcard_end_time: moment(idcard_end_time),
        cmbArea: area,
        userpic1: identityfrondfile,
        userpic2: identityendfile,
        usernumber: identitycard,
      };
      if (source == 0) {
        if (mtype == 0) {
          this.userpic1 = identityfrondfile;
          this.userpic2 = identityendfile;
          this.selectProvince(province, false);
          this.selectCity(city, false);
        } else {
          handle = {
            ...handle,
            userpic1: businessimage,
            userpic2: licenseimage,
            usernumber: businesslicence,
          };
          this.userpic1 = businessimage;
          this.userpic2 = licenseimage;
          console.log(handle);
        }
      }
      this.$nextTick(() => {
        this.isRegionShow = country_id === "1" ? true : false;
        this.init = { ...res, ...handle };
      });
    },
    handleChangeUpload(info, key) {
      if (info.file.status === "uploading") {
        this.logoLoading = true;
        this.uplaoding = true;
        return;
      }
      if (info.file.status === "done") {
        this.uplaoding = false;
        this.logoLoading = false;
        if (info.file.response.code === "0200") {
          let result = info.file.response.payload;
          let url = result.prefix + result.src;
          this.name = result.name;
          this[key] = result.prefix + result.src;
          if (key != "file") {
            this.formInfo.setFieldsValue({ [key]: url });
          }
        } else {
          this.$message.error(info.file.response.msg);
        }
      }
    },

    selectCity(val, select = true) {
      this.cmbAreas = this.citys.find((e) => e.name === val)?.areaList;
      if (select) {
        this.$nextTick(() => {
          this.formInfo.setFieldsValue({ cmbArea: this.cmbAreas[0]?.name });
        });
      }
    },
    selectProvince(val, select = true) {
      this.citys = this.provinces.find((e) => e.name === val)?.cityList;
      if (select) {
        this.$nextTick(() => {
          this.formInfo.setFieldsValue({ city: this.citys[0]?.name });
          if (this.citys[0]) {
            this.selectCity(this.citys[0]?.name);
          }
        });
      }
    },
    getBanck() {
      axios.get(apis.paymentSeting.bank).then((res) => {
        if (res.code === "0200") {
          this.banks = res.payload;
        } else {
          this.$message.error(res.msg || "");
        }
      });
    },
    getForeignCountry() {
      axios.get(apis.paymentSeting.foreign_country).then((res) => {
        if (res.code === "0200") {
          this.countrys = res.payload;
        } else {
          this.$message.error(res.msg || "");
        }
      });
    },
    getForeignArea(id) {
      axios
        .get(apis.paymentSeting.foreign_country_region, {
          params: { country_id: id },
        })
        .then((res) => {
          if (res.code === "0200") {
            this.regions = res.payload;
          } else {
            this.$message.error(res.msg || "");
          }
        });
    },
    getCity() {
      axios.get(apis.paymentSeting.city).then((res) => {
        if (res.code === "0200") {
          this.provinces = res.payload;
        } else {
          this.$message.error(res.msg || "");
        }
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      if (!this.validateFile()) return;
      this.formInfo.validateFields((err, values) => {
        if (!err) {
          this.addPayAccount(values);
        }
      });
    },
    addPayAccount(values) {
      let { source, mtype } = values;
      let { userpic1, userpic2 } = this;
      let params;

      if (source == 0) {
        let common = {
          ...values,
          wd_currency: "CNY",
          userpic1,
          userpic2,
        };
        if (mtype == 0) {
          //国内个人
          params = {
            ...common,
            atype: "0",
            idcard_end_time: values.idcard_end_time.format("YYYY-MM-DD"),
          };
        } else {
          //国内企业
          params = {
            ...common,
            atype: "7",
          };
        }
      } else {
        let common = {
          ...values,

          wd_currency: "USD",
          bankaddr: values.bankaddr,
          user_addr: values.user_addr,
          tax_number: this.fileInfo.tax_number,
          file: this.fileInfo.file,
          tax_from_type: this.fileInfo.tax_from_type,
        };
        if (mtype == 0) {
          //国外个人
          params = {
            ...common,
            atype: "0",
          };
        } else {
          //国外企业
          params = {
            ...common,
            atype: "7",
          };
        }
      }
      let data = this.formatFormData(params);
      let { id } = this.$route.params;
      if (id) {
        data.append("id", id);
      }
      axios.post(apis.paymentSeting.do_pay_account, data).then((res) => {
        if (res.code === "0200") {
          this.$message.success(res.msg);
          this.$router.push({ name: "my_payment_setting" });
        } else {
          this.$message.error(res.msg || "");
        }
      });
    },
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (imageUrl) => {
          this.imageUrl = imageUrl;
          this.loading = false;
        });
      }
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
    onLocationSearch(searchText) {
      this.showList = this.$store.state.commonConfig.countries.filter(
        (item) => {
          if (
            item.name_en.toUpperCase().indexOf(searchText.toUpperCase()) > -1
          ) {
            return item;
          }
        }
      );
    },
    onLocationSelect(value, option) {
      this.country_name = option.data.attrs.text;
    },
  },
  beforeDestroy() {
    if (this.onWindowResize) {
      window.onresize = null;
    }
  },
};
</script>

<style lang="less" scoped>
.addbank {
  .upload-img {
    max-width: 18rem;
  }
  .container {
    background: #ffffff;
    border-radius: 1.1429rem;
    padding: 32px;
  }
  .form-line {
    margin-bottom: 20px;
  }
  .form-col {
    display: flex;
  }
  .form-rt {
    width: 33.4286rem;
  }

  .form-upload {
    width: 29.9286rem;
    height: 2.8571rem;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      border: 1px solid #3762d6;
      color: #3762d6;
    }
  }
  .form-item-width {
    width: 21.2rem;
  }
  .form-item-lg {
    width: 43.9286rem;
  }
  .btn-add {
    margin-left: 21.2857rem;
  }
  &-upload {
    font-size: 1.1429rem;
    .upload-item {
      display: flex;
      height: 56px;
      align-items: center;
      > span {
        width: 8.5714rem;
        flex-shrink: 0;
      }
      .ant-btn {
        width: 529px;
        height: 2.8571rem;
      }
    }
    .upload-tmpl {
      display: flex;
      position: absolute;
      bottom: 3px;
      .tmpl-list {
        margin-left: 30px;
        width: 300px;
        display: flex;
        justify-content: space-around;
        > a {
          color: #3762d6;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .upload {
    padding-left: 12px;
    display: flex;
    &-label {
      width: 22.0714rem;
      color: #999999;
      &::before {
        display: inline-block;
        margin-right: 4px;
        color: #f5222d;
        font-size: 1rem;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: "*";
      }
    }
    &-right {
      display: flex;
      &-first {
        margin-right: 32px;
      }
    }
  }
}
.left-label {
  width: 50%;
  height: 1px;
}
/deep/ .modal > .modal-content {
  padding: 24px 32px;
}
/deep/ .modal > .modal-content > .close-modal {
  top: 16px;
  right: 16px;
}
/deep/ .ant-form-item-no-colon {
  color: #999999;
}
/deep/ .ant-input {
  height: 2.8571rem;
}

/deep/ .custom-upload {
  .ant-upload-list {
    display: none;
  }
}
</style>
