<template>
  <div class="payhistory">
    <Header collection="Payments"></Header>
    <div class="container">
      <div class="headers"></div>
      <div class="content">
        <div class="comm-tables">
          <div class="comm-header">
            <div class="comm-header-left">
              <a-range-picker separator="-" v-model="range" />
              <a-select placeholder="All" @change="handleSelect">
                <a-select-option value="order"> CPS </a-select-option>
                <a-select-option value="bonus"> Bonus </a-select-option>
                <!-- <a-select-option value="2"> Cash Advance </a-select-option>
                <a-select-option value="3"> Exchange </a-select-option>
                <a-select-option value="4"> Promotion Fee</a-select-option> -->
              </a-select>
              <div class="header-input">
                <span>Brand:</span><a-input v-model="brandId"></a-input>
              </div>
              <div class="header-input">
                <span>Withdrawal ID:</span><a-input v-model="w_id"></a-input>
              </div>
            </div>
            <div class="comm-header-rigth">
              <a-button type="primary" @click="hnadleSearch">Search</a-button>
            </div>
          </div>
          <div class="comm-list">
            <a-table
              class="comtable"
              :columns="columns"
              :data-source="dataLists"
              :rowKey="(record, index) => record.id"
              :loading="isLoading"
              :pagination="false"
            >
              <a
                slot="payment_sn"
                v-if="record.payment_sn && record.payment_sn !== '0'"
                :href="
                  `${current_base_url}/reporting/cps?p_id=${record.payment_sn}`
                "
                slot-scope="text, record"
                >{{ text }}</a
              >
              <span slot="payment_sn" slot-scope="text, record" v-else>{{
                text
              }}</span>
              <div class="comm-status" slot="status" slot-scope="text">
                <span
                  class="comm-status-verifying"
                  v-show="text === 'Verifying'"
                  >{{ text }}</span
                >
                <span
                  class="comm-status-approved"
                  v-show="text === 'Approved'"
                  >{{ text }}</span
                >
                <span
                  class="comm-status-rejected"
                  v-show="text === 'Rejected'"
                  >{{ text }}</span
                >
              </div>
              <div class="comm-actions" slot="actions" slot-scope="text, row">
                <img
                  src="@/assets/public/icon-edit.svg"
                  @click="openGiveBonus(row)"
                />
                <img
                  src="@/assets/public/icon-delete-1.svg"
                  @click="sendEmail(row)"
                />
              </div>
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/public/Header.vue";
import axios from "@/utils/request";
import apis from "@/api/const";

export default {
  filters: {
    filterStaus(status) {
      if (status == "new") {
        return "Unpaid";
      } else if (status == "paid") {
        return "Paid";
      } else {
        return "Void";
      }
    },
  },
  components: {
    Header,
    // Modal
  },
  data() {
    let columns = [
      {
        title: "Date",
        dataIndex: "date",
        width: 160,
        align: "center",
      },
      {
        title: "Payment ID",
        dataIndex: "payment_sn",
        width: 160,
        align: "center",
        slots: { title: "payment_sn" },
        scopedSlots: { customRender: "payment_sn" },
      },
      {
        title: "Withdrawal ID",
        dataIndex: "withdraw_id",
        width: 160,
        align: "center",
      },
      {
        title: "Details",
        dataIndex: "type",
        width: 160,
        align: "center",
      },
      {
        title: "Brand",
        dataIndex: "m_id",
        width: 160,
        align: "center",
      },
      {
        title: "Description",
        dataIndex: "note",
        width: 160,
        align: "center",
      },
      {
        title: "Commission",
        dataIndex: "amount",
        width: 160,
        align: "center",
      },
    ];
    return {
      type: null,
      brandId: null,
      range: [],
      columns,
      dataLists: [],
      currentPage: 1,
      isLoading: false,
      w_id: null,
      current_base_url: window.location.origin,
    };
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.query && this.$route.query.w_id) {
          this.w_id = this.$route.query.w_id;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getCashList();
  },
  methods: {
    handleSelect(val) {
      this.type = val;
    },
    hnadleSearch() {
      this.getCashList();
    },
    getCashList() {
      let params = {
        start_time: this.range[0]?.format("YYYY-MM-DD") || null,
        end_time: this.range[1]?.format("YYYY-MM-DD") || null,
        type: this.type,
        m_id: this.brandId,
        page_num: this.currentPage,
        withdraw_id: this.w_id,
      };
      this.isLoading = true;
      axios.get(apis.payments.cash_list, { params }).then((res) => {
        this.isLoading = false;
        if (res.code === "0200") {
          this.dataLists = res.payload.list;
        } else {
          this.$message.error(res.msg || "");
        }
      });
    },
  },
  beforeDestroy() {
    if (this.onWindowResize) {
      window.onresize = null;
    }
  },
};
</script>

<style lang="less" scoped>
.payhistory {
  .headers {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 0.8571rem;
  }
  .content {
    .comm {
      &-actions {
        display: flex;
        justify-content: center;
        img {
          width: 1.1429rem;
          height: 1.1429rem;
          cursor: pointer;
          &:hover {
            opacity: 0.85;
          }
          + img {
            margin-left: 1rem;
          }
        }
      }
      &-status {
        &-verifying {
          color: #999999;
          background: #eeeeee;
        }
        &-approved {
          color: #ffffff;
          background: #2ad57b;
        }
        &-rejected {
          color: #ffffff;
          background: #f64a47;
        }
        span {
          padding: 0.2857rem 0.5714rem;
          border-radius: 4px;
          font-size: 0.8571rem;
        }
      }
      &-platforms {
        display: flex;
        justify-content: center;
        img {
          width: 1.4286rem;
          height: 1.4286rem;
          margin-right: 0.2857rem;
        }
      }
    }
    .comm-header-left {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      align-items: flex-start;
      > div,
      > span {
        margin-right: 1.7143rem;
        margin-bottom: 1rem;
      }
      .header-input {
        width: 280px;
        display: flex;
        align-items: center;
        > span {
          margin-right: 0.8571rem;
          white-space: nowrap;
        }
      }
      .ant-input {
        height: 2.8571rem;
      }
    }
    .comm-header-more {
      color: #333333;
      cursor: pointer;
      i {
        font-size: 0.7143rem;
      }
    }
    .tb-total {
      padding: 16px;
      display: flex;
      justify-content: flex-end;
      &-text {
        color: #666666;
      }
      &-money {
        color: #3762d6;
      }
    }
  }
  /deep/ .ant-select {
    width: 19.2857rem;
  }
  /deep/.ant-calendar-picker-input.ant-input {
    height: 2.8571rem;
    width: 230px;
  }
}
</style>
