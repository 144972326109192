<template>
  <div class="child-details">
    <div class="account-detail-1 account-bg">
      <div class="account-avatar">
        <img :src="accountDetails.merchant.logo" alt="" />
        <img
          class="edit-icon"
          src="@/assets/edit2.svg"
          @click="showLogoEditPopup"
        />
      </div>
      <div class="account-item-title">
        <div>Brand Name</div>
        <div>Brand ID</div>
        <div>Website URL</div>
        <div>PartnerBoost Override</div>
        <div>Account Status</div>
      </div>
      <div class="account-item">
        <div>{{ accountDetails.merchant.sitename || "&nbsp;" }}</div>
        <div>{{ accountDetails.merchant.id || "" }}</div>
        <div>{{ accountDetails.merchant.site_url || "&nbsp;" }}</div>
        <div>20% (Affiliate), 0% (Influencer)</div>
        <div
          :class="
            'account-status account-status-' +
              accountDetails.merchant.program_level
          "
        >
          {{
            accountDetails.merchant.program_level == 2
              ? "Custom Plan"
              : accountDetails.merchant.program_level == 1
              ? "Standard Plan"
              : "Free Trail"
          }}
        </div>
      </div>
      <div class="account-edit-btn">
        <div @click="editbrandShow">Edit</div>
        <div></div>
        <div></div>
        <div></div>
        <div
          v-if="accountDetails.merchant.program_level < 1"
          @click="
            $router.push({
              name: 'program-settings',
              params: {
                upgrade: 1,
              },
            })
          "
        >
          Upgrade
        </div>
      </div>
    </div>
    <div id="account-detail-guide-3">
      <div class="account-detail-2 account-bg">
        <div class="flex-space-between-center">
          <div class="account-title">Program Description</div>
          <div class="account-edit-btn" @click="editProgramDescPopup">Edit</div>
        </div>
        <div class="account-program-description">
          {{ accountDetails.merchant.site_desc }}
        </div>
        <div class=""></div>
        <div
          v-if="accountDetails.merchant.site_desc_update_time"
          class="account-last-updated"
        >
          {{ programUpdateDate }}
        </div>
      </div>
      <div style="display: flex">
        <div style="width: 49%;margin-right: 2%;">
          <div class="account-detail-3 account-bg">
            <div class="flex-space-between-center">
              <div class="account-title">Program Agreement</div>
              <div
                class="account-edit-btn"
                @click="
                  () => {
                    programAgreementVisible = true;
                  }
                "
              >
                Update
              </div>
            </div>
            <div class="account-program-agreement">
              {{ accountDetails.merchant.program_agreement }}
            </div>
            <div class="account-file" v-if="accountDetails.user.file_name">
              <div class="account-file-name">
                {{ accountDetails.user.file_name }}
              </div>
              <a
                :href="accountDetails.user.file_url"
                :download="accountDetails.user.file_name"
              >
                <img
                  class="account-file-down-icon"
                  src="@/assets/upload.svg"
                  alt=""
                />
              </a>
            </div>
            <div
              v-if="accountDetails.merchant.program_agreement_update_time"
              class="account-last-updated"
            >
              {{ programAgreementUpdateDate }}
            </div>
          </div>
          <div class="account-detail-4 account-bg">
            <div class="flex-space-between-center">
              <div class="account-title">Contact Info</div>
              <div
                class="account-edit-btn"
                @click="showEditInformationPopup(1)"
              >
                Edit
              </div>
            </div>
            <div class="contact-info-list">
              <div>{{ accountDetails.address_info.address }}</div>
              <div>{{ accountDetails.address_info.address_1 }}</div>
              <div>{{ accountDetails.address_info.city }}</div>
              <div>{{ accountDetails.address_info.state }}</div>
              <div>{{ accountDetails.address_info.country }}</div>
              <div>{{ accountDetails.address_info.zipcode }}</div>
              <br />
              <div>Phone: {{ accountDetails.address_info.telephone }}</div>
              <div>Email: {{ accountDetails.user.email }}</div>
            </div>
          </div>
        </div>
        <div style="width: 49%;">
          <div class="account-detail-5 account-bg">
            <div class="flex-space-between-center">
              <div class="account-title">Keywords & Category</div>
              <div
                class="account-edit-btn"
                v-if="!KeywordsCategoryIsEdit"
                @click="keywordsCategoryEdit"
              >
                Edit
              </div>
            </div>
            <div class="account-keywords">
              <div class="account-keywords-title">Keywords</div>
              <div
                :class="
                  'account-keywords-list' +
                    (KeywordsCategoryIsEdit
                      ? ' account-keywords-list-edit'
                      : '')
                "
              >
                <span
                  v-for="(item, index) in accountDetails.keywords"
                  :key="index"
                >
                  {{ item }}
                  <img
                    v-if="KeywordsCategoryIsEdit"
                    src="@/assets/close.png"
                    alt=""
                    @click="delKeyword(index)"
                  />
                </span>
                <a-input
                  v-if="KeywordsCategoryIsEdit"
                  placeholder="Hit Enter to Add"
                  size="small"
                  style="width: 130px;border: none;"
                  @pressEnter="addKeyword"
                  v-model="addKeywordText"
                />
              </div>
            </div>
            <div class="account-category">
              <div class="account-category-title">Category</div>
              <div class="account-category-list">
                <span>{{ accountDetails.category || "" }}</span>
                <div
                  v-if="KeywordsCategoryIsEdit"
                  class="account-category-add"
                  @click="showChoosePopup"
                >
                  +&nbsp;{{ accountDetails.category ? "Edit" : "Add" }}
                </div>
              </div>
            </div>
            <div
              v-if="
                !KeywordsCategoryIsEdit && accountDetails.keywords_update_time
              "
              class="account-last-updated"
            >
              {{ keywordUpdateDate }}
            </div>
            <div
              v-else-if="KeywordsCategoryIsEdit"
              class="account-keyword-category-edit-btn"
            >
              <div @click="keywordsCategoryDiscard">Discard</div>
              <a-button
                @click="keywordsCategorySave"
                type="primary"
                size="small"
                style="width: 72px;height: 30px;margin-left: 24px;"
                >Save
              </a-button>
            </div>
          </div>
          <div class="account-detail-6 account-bg">
            <div class="flex-space-between-center">
              <div class="account-title">Billing Address</div>
              <div
                class="account-edit-btn"
                @click="showEditInformationPopup(2)"
              >
                Edit
              </div>
            </div>
            <div class="billing-address-list">
              <div>{{ accountDetails.billing_info.billing_address }}</div>
              <div>{{ accountDetails.billing_info.billing_address_1 }}</div>
              <div>{{ accountDetails.billing_info.billing_city }}</div>
              <div>
                {{ accountDetails.billing_info.billing_state }},
                {{ accountDetails.billing_info.billing_country }}
              </div>
              <div>{{ accountDetails.billing_info.billing_telephone }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a-modal
      title="Program Agreement"
      cancelText="Discard"
      okText="Save"
      :visible="programAgreementVisible"
      :centered="true"
      width="640px"
      @cancel="
        () => {
          programAgreementVisible = false;
        }
      "
      @ok="programAgreementModelOk"
    >
      <div class="program-agreement-model-head">
        <div
          v-for="(item, index) in programAgreementModelMenu"
          :class="
            programAgreementModelMenuChecked === index
              ? 'program-agreement-model-head-checked'
              : ''
          "
          @click="programAgreementModelMenuSelect(index)"
          :key="index"
        >
          {{ item }}
        </div>
      </div>
      <div class="program-agreement-model-body">
        <a-form :form="sitenameForm" v-if="programAgreementVisible">
          <a-form-item v-show="programAgreementModelMenuChecked === 0">
            <a-textarea
              v-decorator="[
                'program_agreement',
                { initialValue: accountDetails.merchant.program_agreement },
              ]"
              placeholder="Please input your Program Agreement!"
              :rows="10"
            />
          </a-form-item>
          <a-form-item v-show="programAgreementModelMenuChecked === 1">
            <div class="program-agreement-model-upload-file">
              <a-upload
                v-decorator="['upload_file']"
                name="imgFile"
                :multiple="true"
                action="https://app.partnerboost.com/api.php?mod=upload_file&dir=media_kit"
                @change="uploadFileChange"
                :default-file-list="defaultFileList"
              >
                <a-button type="primary">Choose File</a-button>
                <div class="program-agreement-model-upload-file-list"></div>
              </a-upload>
            </div>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
    <!-- brand name -->
    <Modal
      :isVisible="isShowBrand"
      title="Brand Name"
      width="394px"
      submitText="Update"
      @close-popup="hideBrandPopup"
      @submit="submitBrandPopup"
      >>
      <template #content>
        <a-form :form="sitenameForm">
          <a-form-item>
            <a-input
              v-decorator="[
                'sitename',
                {
                  initialValue: accountDetails.merchant.sitename,
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: 'Please input your Brand Name!',
                    },
                  ],
                },
              ]"
              placeholder="brand name"
            >
            </a-input>
          </a-form-item>
        </a-form>
      </template>
    </Modal>
    <!-- 编辑logo -->
    <Modal
      :isVisible="isShowLogoEdit"
      title="Company Logo"
      class="logo-edit-box"
      submitText="Confirm"
      width="500px"
      @close-popup="hideLogoEditPopup"
      @submit="submitLogoEditPopup"
    >
      <template #content>
        <div>
          <p>
            Your logo is used on various pages in the interface to promote your
            program. Please create your logo in the sizes listed below and then
            drag them to the drop zone below.
          </p>
          <div class="upload">
            <a-upload
              name="imgFile"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :before-upload="beforeUpload"
              @change="handleChangeUpload"
              :action="uploadUrl"
            >
              <img
                v-if="editImageUrl"
                :src="editImageUrl"
                alt="avatar"
                style="width: 100%;height: 100%;"
              />
              <div v-else>
                <a-icon :type="logoLoading ? 'loading' : 'plus'" />
              </div>
            </a-upload>
          </div>
          <h6>
            Accpeted File Type:
            <span>jpg, gif, png</span>
          </h6>
          <h6>
            File Max Size:
            <span>1M</span>
          </h6>
          <h6>
            Max Image Dimensions:
            <span>Width 500 x Height 500</span>
          </h6>
        </div>
      </template>
    </Modal>
    <!-- 编辑Program Description -->
    <Modal
      :isVisible="isShowProgramDesc"
      title="Program Description"
      width="694px"
      submitText="Update"
      @close-popup="hideProgramDescPopup"
      @submit="submitProgramPopup"
      >>
      <template #content>
        <a-form :form="sitenameForm">
          <a-form-item>
            <a-textarea
              v-decorator="[
                'sitedesc',
                {
                  initialValue: accountDetails.merchant.site_desc,
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: 'Please input your Program Description!',
                    },
                  ],
                },
              ]"
              placeholder="Please input your Program Description!"
              :rows="10"
            />
          </a-form-item>
        </a-form>
      </template>
    </Modal>
    <!-- 选择分类 -->
    <ChoosePopup
      title="List of Brand Categories (Choose One)"
      :lists="ctegoriesLists"
      @close-popup="closeChoosePopup"
      @choose-item="chooseCategories"
      :isChoosePopup="isChoosePopup"
      :isCtegories="true"
    />

    <!-- 更新联系信息 -->
    <Modal
      :isVisible="isShowInformationPopup"
      :title="informationTitle"
      width="394px"
      submitText="Update"
      @close-popup="hideInformationPopup"
      @submit="submitInformationPopup"
    >
      <template #content>
        <div class="edit-item">
          <h6>Organization</h6>
          <input type="text" v-model="informationObj.company_name" />
        </div>
        <div class="edit-item">
          <h6>Contact Name</h6>
          <input type="text" v-model="informationObj.contact_name" />
        </div>
        <div class="edit-item">
          <h6>Country</h6>
          <a-select
            @change="handleChangeEditCountry"
            class="select"
            :defaultValue="informationObj.country"
          >
            <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
            <a-select-option
              :value="item.name_en"
              v-for="(item, index) in getCountry"
              :key="index"
              >{{ item.name_en }}</a-select-option
            >
          </a-select>
        </div>
        <div class="edit-item">
          <h6>Address</h6>
          <input type="text" v-model="informationObj.address" />
        </div>
        <div class="edit-item">
          <h6>Address Cont.</h6>
          <input type="text" v-model="informationObj.address_1" />
        </div>
        <div class="edit-item">
          <h6>City</h6>
          <input type="text" v-model="informationObj.city" />
        </div>
        <div class="edit-item">
          <h6>State</h6>
          <a-select
            @change="handleChangeEditState"
            class="select"
            v-if="isSelectUs == 'us'"
            :defaultValue="informationObj.state"
          >
            <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
            <a-select-option
              :value="item.name"
              v-for="(item, index) in usStateLists"
              :key="index"
              >{{ item.name }}</a-select-option
            >
          </a-select>
          <input type="text" v-else v-model="informationObj.state" />
        </div>
        <div class="edit-item">
          <h6>Zip Code</h6>
          <input type="text" v-model="informationObj.zipcode" />
        </div>
        <div class="edit-item">
          <h6>Phone</h6>
          <input type="text" v-model="informationObj.telephone" />
        </div>
        <div class="edit-item" v-if="iscontactBill == 1">
          <h6>Phone Key Phrase</h6>
          <input type="text" v-model="informationObj.phone_phrase" />
        </div>
      </template>
    </Modal>
    <Loading v-if="isLoadingModal"></Loading>
  </div>
</template>

<script>
import Modal from "./../../../components/Modal.vue";
import axios from "@/utils/request";
import inspectUserPermissions from "@/utils/permissionsJudge";
import ChoosePopup from "@/components/ChoosePopup.vue";
import { uploadUrl } from "@/utils/config";

export default {
  name: "account-edit",
  components: {
    Modal,
    ChoosePopup,
  },
  data() {
    return {
      informationTitle: "Edit Contact",
      iscontactBill: 1,
      isLoadingModal: false,
      uploadUrl,
      sitenameForm: this.$form.createForm(this, { name: "coordinated" }),
      isShowBrand: false,
      isShowProgramDesc: false,
      KeywordsCategoryIsEdit: false,
      isChoosePopup: false,
      programAgreementModelMenu: ["Text", "Upload a File"],
      programAgreementModelMenuChecked: 0,
      programAgreementVisible: false,
      hasUpdateBrandInfo: true,
      isShowLogoEdit: false,
      editImageUrl: "",
      logoLoading: false,

      informationObj: {
        company_name: "",
        contact_name: "",
        country: "",
        address: "",
        address_1: "",
        state: "",
        city: "",
        telephone: "",
        zipcode: "",
        phone_phrase: "",
      },
      isShowInformationPopup: false,
      titleDetailsObj: {
        category: "",
      },
      selectCategoriesitem: null,
      addKeywordText: "",
    };
  },
  computed: {
    accountDetails() {
      console.log(this.$store.state.accountDetails);
      return this.$store.state.accountDetails;
    },
    ctegoriesLists() {
      if (
        this.$store.state.commonConfig &&
        this.$store.state.commonConfig.categories
      ) {
        return this.$store.state.commonConfig.categories;
      }
      return [];
    },
    programUpdateDate() {
      let updateDate = this.transformUpdateTime(
        this.$store.state.accountDetails.merchant.site_desc_update_time * 1000
      );
      return (
        updateDate.months +
        " " +
        updateDate.day +
        ", " +
        updateDate.year +
        "  Last Updated"
      );
    },
    programAgreementUpdateDate() {
      let updateDate = this.transformUpdateTime(
        this.$store.state.accountDetails.merchant
          .program_agreement_update_time * 1000
      );
      return (
        updateDate.months +
        " " +
        updateDate.day +
        ", " +
        updateDate.year +
        "  Last Updated"
      );
    },
    keywordUpdateDate() {
      let updateDate = this.transformUpdateTime(
        this.$store.state.accountDetails.keywords_update_time * 1000
      );
      return (
        updateDate.months +
        " " +
        updateDate.day +
        ", " +
        updateDate.year +
        "  Last Updated"
      );
    },
    defaultCommission() {
      if (this.$store.state.accountDetails.norm) {
        let norm = this.$store.state.accountDetails.norm;
        norm.aff_ba = Number(norm.aff_ba);
        if (norm.aff_ba_unit == "%") {
          return norm.aff_ba + "%";
        } else {
          return this.accountDetails.merchant.currency.sign + norm.aff_ba;
        }
      }
      return "0%";
    },
    getCountry() {
      if (
        this.$store.state.commonConfig &&
        this.$store.state.commonConfig.countries
      ) {
        return this.$store.state.commonConfig.countries;
      }
      return [];
    },
    defaultFileList() {
      if (this.accountDetails.user.file_name) {
        return [
          {
            uid: 1,
            name: this.accountDetails.user.file_name,
            status: "done",
            url: this.accountDetails.user.file_url,
          },
        ];
      } else {
        return [];
      }
    },
  },
  created() {
    let authorityJudgment = this.$store.state.authorityJudgment;
    this.hasUpdateBrandInfo = inspectUserPermissions(
      "user/updateMerchantInfo",
      authorityJudgment
    );
  },
  mounted() {},
  methods: {
    keywordsCategoryEdit() {
      this.KeywordsCategoryIsEdit = true;
    },
    keywordsCategoryDiscard() {
      this.refreshUserInfo();
      this.KeywordsCategoryIsEdit = false;
    },
    keywordsCategorySave() {
      let submit = {
        type: "keywords",
        keywords: this.$store.state.accountDetails.keywords.join(","),
      };
      this.isLoadingModal = true;
      axios
        .post("/api_admin.php?a=user/updateCommunicationsInfo", {
          ...submit,
        })
        .then((res) => {
          if (res.code === 0) {
            this.KeywordsCategoryIsEdit = false;
          } else {
            this.$message.error(
              res.msg || "Sorry, server is busy. please try later."
            );
          }
        })
        .catch((e) => {})
        .then(() => {
          this.isLoadingModal = false;
        });

      if (this.selectCategoriesitem !== null) {
        submit = {
          type: "category",
          c_id: this.selectCategoriesitem.id,
          c_name: this.selectCategoriesitem.en_title,
        };
        if (this.selectCategoriesitem.id == this.accountDetails.merchant.c_id) {
          this.isChoosePopup = false;
          return;
        }

        this.isLoading = true;
        axios
          .post("/api_admin.php?a=user/updateMerchantInfo", {
            ...submit,
          })
          .then((res) => {
            if (res.code === 0) {
              this.refreshUserInfo();
            } else {
              this.isLoading = false;
              this.$message.error(
                res.msg || "Sorry, server is busy. please try later."
              );
            }
          })
          .catch((e) => {
            this.isLoading = false;
          });

        this.isChoosePopup = false;
        this.selectCategoriesitem = null;
      }
    },
    programAgreementModelMenuSelect(index) {
      console.log(index);
      this.programAgreementModelMenuChecked = index;
    },
    programAgreementModelOk() {
      this.sitenameForm.validateFields((err, values) => {
        if (!err) {
          let submit = {
            type: "program_agreement",
            program_agreement: values.program_agreement.trim(),
          };
          this.isLoadingModal = true;
          axios
            .post("/api_admin.php?a=user/updateMerchantInfo", {
              ...submit,
            })
            .then((res) => {
              if (res.code === 0) {
                this.programAgreementVisible = false;
                this.refreshUserInfo();
              } else {
                this.$message.error(
                  res.msg || "Sorry, server is busy. please try later."
                );
              }
            })
            .catch((e) => {})
            .then(() => {
              this.isLoadingModal = false;
            });
          if (values.upload_file.file.status === "removed") {
            submit = {
              type: "file_data",
              file_url: "",
              file_name: "",
            };
          } else {
            submit = {
              type: "file_data",
              file_url:
                "https:" +
                values.upload_file.file.response.domain +
                "/" +
                values.upload_file.file.response.url,
              file_name: values.upload_file.file.name,
            };
          }

          this.isLoadingModal = true;
          axios
            .post("/api_admin.php?a=user/updateUserInfo", {
              ...submit,
            })
            .then((res) => {
              if (res.code === 0) {
                this.programAgreementVisible = false;
                this.refreshUserInfo();
              } else {
                this.$message.error(
                  res.msg || "Sorry, server is busy. please try later."
                );
              }
            })
            .catch((e) => {})
            .then(() => {
              this.isLoadingModal = false;
            });
        }
      });
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 1MB!");
      }
      return isJpgOrPng && isLt2M;
    },
    handleChangeUpload(info) {
      if (info.file.status === "uploading") {
        this.logoLoading = true;
        return;
      }
      if (info.file.status === "done") {
        this.logoLoading = false;
        this.editImageUrl = info.fileList[0].response.data.url;
      }
    },
    showLogoEditPopup() {
      if (!this.hasUpdateBrandInfo) {
        return;
      }
      this.isShowLogoEdit = true;
      this.editImageUrl = null;
    },
    hideLogoEditPopup() {
      this.isShowLogoEdit = false;
    },
    submitLogoEditPopup() {
      let submit = {
        type: "logo",
        logo: this.editImageUrl,
      };
      this.isLoadingModal = true;
      axios
        .post("/api_admin.php?a=user/updateMerchantInfo", {
          ...submit,
        })
        .then((res) => {
          if (res.code === 0) {
            this.isShowLogoEdit = false;
            this.$store.dispatch("updateAccountDetails", {
              merchant: {
                logo: submit.logo,
              },
            });
          } else {
            this.$message.error(
              res.msg || "Sorry, server is busy. please try later."
            );
          }
        })
        .catch((e) => {})
        .then(() => {
          this.isLoadingModal = false;
        });
    },
    editbrandShow() {
      this.isShowBrand = true;
    },
    hideBrandPopup() {
      this.isShowBrand = false;
    },
    submitBrandPopup() {
      this.sitenameForm.validateFields((err, values) => {
        if (!err) {
          let submit = {
            type: "sitename",
            sitename: values.sitename.trim(),
          };
          this.isLoadingModal = true;
          axios
            .post("/api_admin.php?a=user/updateMerchantInfo", {
              ...submit,
            })
            .then((res) => {
              if (res.code === 0) {
                this.isShowBrand = false;
                this.$store.dispatch("updateAccountDetails", {
                  merchant: {
                    sitename: submit.sitename,
                  },
                });
              } else {
                this.$message.error(
                  res.msg || "Sorry, server is busy. please try later."
                );
              }
            })
            .catch((e) => {})
            .then(() => {
              this.isLoadingModal = false;
            });
        }
      });
    },
    editProgramDescPopup() {
      this.isShowProgramDesc = true;
    },
    hideProgramDescPopup() {
      this.isShowProgramDesc = false;
    },
    submitProgramPopup() {
      this.sitenameForm.validateFields((err, values) => {
        if (!err) {
          let submit = {
            type: "site_desc",
            site_desc: values.sitedesc.trim(),
          };
          this.isLoadingModal = true;
          axios
            .post("/api_admin.php?a=user/updateMerchantInfo", {
              ...submit,
            })
            .then((res) => {
              if (res.code === 0) {
                this.isShowProgramDesc = false;
                this.refreshUserInfo();
              } else {
                this.$message.error(
                  res.msg || "Sorry, server is busy. please try later."
                );
              }
            })
            .catch((e) => {})
            .then(() => {
              this.isLoadingModal = false;
            });
        }
      });
    },
    hideInformationPopup() {
      this.isShowInformationPopup = false;
    },
    handleChangeEditCountry(value) {
      this.isSelectUs = value;
      this.informationObj.country = value;
    },
    handleChangeEditState(value) {
      this.informationObj.state = value;
    },
    showEditInformationPopup(index) {
      if (index == 1) {
        this.iscontactBill = 1;
        this.informationTitle = "Edit Contact";
        (this.informationObj.company_name = this.accountDetails.address_info.company_name),
          (this.informationObj.contact_name = this.accountDetails.address_info.contact_name),
          (this.informationObj.country = this.accountDetails.address_info.country),
          (this.informationObj.address = this.accountDetails.address_info.address),
          (this.informationObj.address_1 = this.accountDetails.address_info.address_1),
          (this.informationObj.state = this.accountDetails.address_info.state),
          (this.informationObj.city = this.accountDetails.address_info.city),
          (this.informationObj.telephone = this.accountDetails.address_info.telephone),
          (this.informationObj.zipcode = this.accountDetails.address_info.zipcode);
        if (this.informationObj.state != "us") {
          this.isSelectUs = this.informationObj.state;
        }
        this.isShowInformationPopup = true;
      } else if (index == 2) {
        this.iscontactBill = 2;
        this.informationTitle = "Billing Address";
        (this.informationObj.company_name = this.accountDetails.billing_info.company_name),
          (this.informationObj.contact_name = this.accountDetails.billing_info.contact_name),
          (this.informationObj.country = this.accountDetails.billing_info.billing_country),
          (this.informationObj.address = this.accountDetails.billing_info.billing_address),
          (this.informationObj.address_1 = this.accountDetails.billing_info.billing_address_1),
          (this.informationObj.state = this.accountDetails.billing_info.billing_state),
          (this.informationObj.city = this.accountDetails.billing_info.billing_city),
          (this.informationObj.telephone = this.accountDetails.billing_info.billing_telephone),
          (this.informationObj.zipcode = this.accountDetails.billing_info.billing_zipcode);
        if (this.informationObj.state != "us") {
          this.isSelectUs = this.informationObj.state;
        }
        this.isShowInformationPopup = true;
      }
    },
    showChoosePopup() {
      this.isChoosePopup = true;
    },
    closeChoosePopup() {
      this.isChoosePopup = false;
    },
    chooseCategories(item) {
      this.selectCategoriesitem = item;
      this.$store.state.accountDetails.category = item.en_title;
      this.closeChoosePopup();
    },

    submitInformationPopup() {
      this.isLoading = true;
      if (this.iscontactBill == 1) {
        axios
          .post("/api_admin.php?a=user/updateAddress", {
            ...this.informationObj,
          })
          .then((res) => {
            if (res.code === 0) {
              this.refreshUserInfo().then((data) => {
                this.isLoading = false;
                this.isShowInformationPopup = false;
              });
            } else {
              this.isLoading = false;
              this.$message.error(
                res.msg || "Sorry, server is busy. please try later."
              );
            }
          });
      } else if (this.iscontactBill == 2) {
        delete this.informationObj.phone_phrase;
        axios
          .post("/api_admin.php?a=user/updateBillingAddress", {
            contact_name: this.informationObj.contact_name,
            company_name: this.informationObj.company_name,
            billing_country: this.informationObj.country,
            billing_state: this.informationObj.state,
            billing_city: this.informationObj.city,
            billing_telephone: this.informationObj.telephone,
            billing_zipcode: this.informationObj.zipcode,
            billing_address: this.informationObj.address,
            billing_address_1: this.informationObj.address_1,
          })
          .then((res) => {
            if (res.code === 0) {
              this.refreshUserInfo().then((data) => {
                this.isShowInformationPopup = false;
                this.isLoading = false;
              });
            } else {
              this.isLoading = false;
              this.$message.error(
                res.msg || "Sorry, server is busy. please try later."
              );
            }
          });
      }
    },
    uploadFileChange(info) {
      // let fileList = this.sitenameForm.getFieldValue('upload_file')
      // console.log(fileList)
      // console.log(info)
      // return
      if (info.file.status === "removed") {
        this.sitenameForm.setFieldsValue({ upload_file: [] });
        return;
      }
      // 最大不能超过10M
      if (info.file.size > 10 * 1024 * 1024) {
        this.$message.error("The file size can't be greater than 10M");
        return;
      }
      console.log(info.file);
      if (info.file.response !== undefined && info.file.response.error === 1) {
        this.$message.error(info.file.response.message);
        return;
      }
      let fileList = this.sitenameForm.getFieldValue("upload_file");
      //限制只上传一个
      if (fileList !== undefined && fileList.fileList.length > 1) {
        this.sitenameForm.setFieldsValue({
          upload_file: [fileList.fileList.shift()],
        });
      }
    },
    uploadFileSuccess(info) {
      console.log(this.sitenameForm.getFieldValue("upload_file"));

      // this.form.setFieldsValue(detail)
    },
    delKeyword(index) {
      this.$store.state.accountDetails.keywords.splice(index, 1);
    },
    addKeyword(e) {
      this.$store.state.accountDetails.keywords.push(this.addKeywordText);
      this.addKeywordText = "";
    },
  },
};
</script>

<style lang="less" scoped>
.child-details {
  color: #333333;
  font-size: 1rem;

  .account-detail-1 {
    display: flex;
    line-height: 28px;

    .account-avatar {
      width: 248px;
      height: 136px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      border: 1px solid #eeeeee;
      padding: 8px 8px;
      position: relative;
      margin-right: 14px;

      img {
        max-height: 100%;
        max-width: 100%;
      }

      .edit-icon {
        position: absolute;
        bottom: 8px;
        right: 8px;
        width: 18px;
        cursor: pointer;
      }
    }

    .account-item-title {
      padding-right: 30px;

      div {
        color: #999999;
      }
    }

    .account-item {
      font-family: dtc-bold;
      padding-right: 24px;

      .account-status {
        width: 100px;
        height: 24px;
        border-radius: 4px;
        background: #2898ff;
        color: #ffffff;
        line-height: 24px;
        text-align: center;
        font-size: 0.9286rem;
        font-family: dtc;
        &-1 {
          background: #30cd89;
        }
      }
    }
  }

  .account-detail-2 {
    .account-program-description {
      line-height: 21px;
    }
  }

  .account-detail-3 {
    .account-program-agreement {
      line-height: 21px;
    }

    .account-file {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f2f9ff;
      border-radius: 4px;
      height: 30px;
      padding: 0 16px;
      margin-top: 5px;

      .account-file-name {
        font-size: 0.8571rem;
        color: #2898ff;
      }

      .account-file-down-icon {
        height: 16px;
      }
    }
  }

  .account-detail-4 {
    .contact-info-list {
      > div {
        height: 24px;
        line-height: 24px;
        margin-bottom: 4px;
      }
    }
  }

  .account-detail-5 {
    .account-keywords {
      .account-keywords-title {
        color: #999999;
        line-height: 24px;
        margin-bottom: 8px;
      }

      .account-keywords-list {
        display: flex;
        flex-wrap: wrap;

        > span {
          padding: 2px 12px;
          color: #fbc15b;
          background-color: #fff8ed;
          margin-right: 14px;
          margin-bottom: 10px;
          border-radius: 4px;
        }
      }

      .account-keywords-list-edit {
        border-radius: 12px;
        border: 1px solid #e6e6e6;
        padding: 12px;
        margin-bottom: 8px;

        > span {
          position: relative;

          > img {
            width: 15px;
            height: 15px;
            position: absolute;
            cursor: pointer;
            z-index: 1;
            top: -6px;
            right: -6px;
          }
        }
      }
    }

    .account-category {
      .account-category-title {
        color: #999999;
        line-height: 24px;
        margin-bottom: 8px;
      }

      .account-category-list {
        display: flex;
        flex-wrap: wrap;

        > span {
          height: 28px;
          line-height: 28px;
          font-weight: 600;
          color: #2898ff;
          margin-right: 14px;
          margin-bottom: 8px;
        }
      }

      .account-category-add {
        width: 76px;
        height: 28px;
        background: #f2f9ff;
        border-radius: 4px;
        color: #2898ff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    .account-keyword-category-edit-btn {
      width: 100%;
      display: flex;
      justify-content: right;
      align-items: center;

      > div {
        cursor: pointer;
      }
    }
  }

  .account-detail-6 {
    .billing-address-list {
      > div {
        height: 24px;
        line-height: 24px;
        margin-bottom: 4px;
      }
    }
  }

  .account-detail-3,
  .account-detail-4,
  .account-detail-5,
  .account-detail-6 {
    width: 49%;

    > div {
      width: 100%;
    }
  }

  .account-edit-btn {
    color: #2898ff;
    cursor: pointer;
    text-decoration: underline;
    font-size: 1rem;

    div {
      height: 28px;
    }
  }

  .account-bg {
    background: #ffffff;
    border-radius: 12px;
    width: 100%;
    padding: 16px 22px;
    margin-bottom: 22px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .account-title {
    font-weight: 600;
    font-size: 1.1429rem;
    height: 30px;
    line-height: 30px;
    margin-bottom: 8px;
    font-family: dtc-bold;
  }

  .flex-space-between-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .account-last-updated {
    font-size: 0.8571rem;
    color: #999999;
    width: 100%;
    display: flex;
    justify-content: right;
    margin-top: 10px;
    height: 21px;
    line-height: 21px;
  }
}

.reset-model-box {
  padding: 20px 0;
}

.program-agreement-model-head {
  display: flex;

  > div {
    padding: 6px 18px;
    font-weight: 600;
    cursor: pointer;
  }

  .program-agreement-model-head-checked {
    background: #f2f9ff;
    border-radius: 6px 6px 0px 0px;
  }
}

.program-agreement-model-body {
  background-color: #f2f9ff;
  border-radius: 0px 6px 6px 6px;
  width: 100%;
  padding: 12px;

  .program-agreement-model-upload-file {
    border-radius: 6px;
    border: 1px solid #e6e6e6;
    width: 100%;
    height: 100%;
    padding: 16px 14px;
  }
}

.logo-edit-box {
  .upload {
    padding-bottom: 8px;
  }
}

.edit-item {
  margin-bottom: 12px;

  h6 {
    font-size: 1.1429rem;
    font-family: "dtc-bold";
    color: #333333;
    line-height: 30px;
    margin-bottom: 6px;
  }

  input {
    width: 100%;
    height: 2.8571rem;
    border-radius: 6px;
    border: 1px solid #e6e6e6;
    padding-left: 10px;
    outline: none;
  }

  .select {
    width: 100%;
    height: 2.8571rem;

    /deep/ .ant-select-selection--single {
      height: 2.8571rem;
      border-radius: 6px;
      background: #fafafa;
    }

    /deep/ .ant-select-selection__rendered {
      line-height: 2.8571rem;
    }

    .icon-down {
      color: #333333;
      font-size: 1rem;
    }
  }
}
</style>
<style type="text/css">
.ant-modal-header {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  border-bottom: none !important;
}

.ant-modal-title {
  font-size: 18px !important;
  color: #333333 !important;
  font-weight: 600 !important;
}

.ant-modal-close-x > .anticon-close {
  display: none !important;
}

.ant-modal-close-x {
  background-image: url(~@/assets/close.svg);
  background-size: 30%;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
}

.ant-modal-footer {
  border: none !important;
  padding-bottom: 20px !important;
  padding-right: 20px !important;
}

.ant-modal-footer > div > button:first-child {
  border: none;
}

.program-agreement-model-upload-file > span {
  display: flex !important;
}

.ant-upload-list {
  width: 100%;
  margin-left: 16px !important;
}

.ant-upload-list-item {
  margin-top: 0 !important;
  height: 32px !important;
  background-color: #fff;
  padding: 5px 14px;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 15px;
}

.ant-upload-list-item-info .anticon-loading,
.ant-upload-list-item-info .anticon-paper-clip {
  top: 9px !important;
}
</style>
