<template>
  <div id="discovery-template">
    <div class="form">
      <div class="template-block-title">Create Invite Template</div>
      <a-form ref="form">
        <a-form-item
          label="Subject"
          :validate-status="rules.subject.validateStatus"
          :help="rules.subject.help"
        >
          <a-input
            v-model="form.subject"
            placeholder="Please enter subject"
            size="large"
            @blur="handleSubjectChange"
            @change="handleSubjectChange"
          />
        </a-form-item>
        <a-form-item
          label="Message"
          :validate-status="rules.message.validateStatus"
          :help="rules.message.help"
        >
          <a-input
            v-model="form.message"
            placeholder="Please enter message"
            size="large"
            type="textarea"
            :autoSize="{
              minRows: 8,
            }"
            @blur="handleMessageChange"
            @change="handleMessageChange"
          />
        </a-form-item>
        <a-form-item label=" " style="text-align:right">
          <a-button type="link" @click="reset" size="large"> Reset </a-button>
          <a-button
            type="primary"
            @click="submit"
            size="large"
            style="width:fit-content"
          >
            Save Template
          </a-button>
        </a-form-item>
      </a-form>
    </div>
    <div class="template-list">
      <div class="template-block-title">My Invite Template</div>
      <a-table
        :columns="columns"
        :data-source="data"
        :row-key="rowKey"
        :loading="loading"
        :pagination="false"
        size="middle"
      >
        <template slot="actions" slot-scope="text, row">
          <img
            class="action-edit"
            src="@/assets/icon-edit.svg"
            @click="edit(row)"
            alt="Edit"
            title="Edit"
          />
          <img
            class="action-copy"
            src="@/assets/icon-copy.svg"
            @click="copy(row)"
            alt="Duplicate"
            title="Duplicate"
          />
          <img
            class="action-del"
            src="@/assets/icon-del.svg"
            @click="del(row)"
            alt="Delete"
            title="Delete"
          />
        </template>
      </a-table>
    </div>
    <Modal
      :isVisible="!!modal.row"
      width="341px"
      submitText="Confirm"
      @close-popup="modal.row = null"
      title="Delete Template"
      class="modal"
      @submit="onConfirmDel"
    >
      <template #content>
        <div
          class="text"
          style="text-align:center;height:100px;padding-left:50px;padding-right:50px;padding-top:15px;font-size:16px;padding-bottom:5px"
        >
          Are you sure you want to delete this template
        </div>
      </template>
    </Modal>
    <Loading v-if="loading" />
  </div>
</template>

<script>
import axios from "@/utils/request";
import Modal from "../../../components/Modal.vue";
import MediumListItem from "./MediumListItem.vue";

export default {
  name: "discovery_list",
  components: {
    Modal,
    MediumListItem,
  },
  data() {
    return {
      form: {
        id: null,
        subject: "",
        message: "",
      },
      rules: {
        subject: {
          validateStatus: "success",
          help: null,
        },
        message: {
          validateStatus: "success",
          help: null,
        },
      },
      data: [],
      columns: [
        {
          title: "Subject",
          align: "left",
          dataIndex: "subject",
          width: "600px",
        },
        {
          title: "",
          align: "right",
          scopedSlots: { customRender: "actions" },
        },
      ],
      loading: false,
      rowKey: "id",
      modal: {
        row: null,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    edit(row) {
      this.form = {
        id: row.id,
        subject: row.subject,
        message: row.message,
      };
    },
    copy(row) {
      this.form.subject = row.subject;
      this.form.message = row.message;
      this.form.id = null;
    },
    del(row) {
      // 弹窗确认后删除
      this.modal.row = row;
    },
    onConfirmDel() {
      this.loading = true;
      axios
        .post("/api_admin.php?a=discovery/delete_invite_template", {
          id: this.modal.row.id,
        })
        .then((res) => {
          this.loading = false;
          if (res.code === 0) {
            this.$message.success("Delete successfully");
            if (this.form.id === this.modal.row.id) {
              this.form.id = null;
              this.form.subject = "";
              this.form.message = "";
            }
            this.data = this.data.filter(
              (item) => item.id !== this.modal.row.id
            );
            this.modal.row = null;
          } else {
            this.$message.error(res.msg || "Delete failed");
          }
        });
    },
    handleSubjectChange(e) {
      this.rules.subject.validateStatus = "success";
      this.rules.subject.help = null;
    },
    handleMessageChange(e) {
      this.rules.message.validateStatus = "success";
      this.rules.message.help = null;
    },
    // 获取我的模板列表
    getList() {
      this.loading = true;
      axios
        .get("/api_admin.php?a=discovery/invite_template_list")
        .then((res) => {
          this.loading = false;
          if (res.code === 0) {
            this.data = res.data.data;
          } else {
            this.$message.error(res.msg || "");
          }
        });
    },
    // 清除表单
    reset() {
      this.form.subject = "";
      this.form.message = "";
      this.form.id = null;
    },
    submit() {
      // 如果subject或message为空，则提示
      if (!this.form.subject || !this.form.message) {
        this.rules.subject.validateStatus = "error";
        this.rules.subject.help = "Subject and message cannot be empty";
        this.rules.message.validateStatus = "error";
        this.rules.message.help = "Subject and message cannot be empty";
        return;
      }

      // 如果有id则调用编辑接口，否则调用添加接口, 并且清空表单
      if (this.form.id) {
        this.loading = true;
        axios
          .post("/api_admin.php?a=discovery/update_invite_template", {
            id: this.form.id,
            subject: this.form.subject,
            message: this.form.message,
          })
          .then((res) => {
            this.loading = false;
            if (res.code === 0) {
              this.$message.success("Edit successfully");
              this.data = this.data.map((item) => {
                if (item.id === this.form.id) {
                  item.subject = this.form.subject;
                  item.message = this.form.message;
                }
                return item;
              });
              this.reset();
            } else {
              this.$message.error(res.msg || "Edit failed");
            }
          });
      } else {
        this.loading = true;
        axios
          .post("/api_admin.php?a=discovery/add_invite_template", {
            subject: this.form.subject,
            message: this.form.message,
          })
          .then((res) => {
            this.loading = false;
            if (res.code === 0) {
              this.$message.success("Add successfully");
              this.data.push({
                id: res.data,
                subject: this.form.subject,
                message: this.form.message,
              });
              this.reset();
            } else {
              this.$message.error(res.msg || "Add failed");
            }
          });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.ant-form {
  width: 746px;
  background: #f2f9ff;
  border-radius: 6px;
  padding: 16px 22px 22px 20px;
  box-sizing: border-box;

  /deep/ .ant-form-item {
    display: flex;
    justify-content: space-between;

    &:last-child {
      margin-bottom: 0;
    }

    input,
    textarea {
      border-color: #e6e6e6ff;
      font-size: 1rem;
    }

    input:focus,
    textarea:focus {
      border-color: #52b1ff;
    }

    .has-error .ant-input,
    .has-error .ant-input:hover {
      border-color: #f5222d !important;
    }

    .ant-form-item-label {
      width: 90px;
      font-size: 1.1429rem;
      font-family: dtc-bold;
      color: #333333;
      text-align: left;

      > label::after {
        display: none;
      }
    }

    .ant-form-item-control-wrapper {
      width: 600px;
    }
  }

  // /deep/ input {
  //   border-color: #E6E6E6FF;
  // }
}

.template-list {
  margin: 20px 0 2.8571rem;
  width: 746px;

  /deep/ .ant-table-thead {
    tr {
      th {
        text-align: left !important;
      }
    }
  }

  /deep/ tr {
    height: 3.4286rem;

    td,
    th {
      padding: 0 20px !important;
    }
  }

  .action-edit,
  .action-copy,
  .action-del {
    width: 22px;
    height: 22px;
    margin-left: 10px;
    cursor: pointer;
  }
}

.template-block-title {
  height: 30px;
  margin-bottom: 20px;
  font-size: 1.1429rem;
  font-family: Circular-Black, Circular;
  font-weight: 900;
  color: #333333;
  line-height: 30px;
}

.modal {
  /deep/ .ant-btn-primary {
    min-width: 173px;
  }
}
</style>
