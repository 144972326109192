<template>
  <div class="mychannel">
    <Header collection="My Account"></Header>
    <div class="container">
      <div class="headers"></div>
      <div class="content">
        <div class="comm-tables">
          <div class="comm-header">
            <div class="comm-header-left">
              <span>Channels</span>
            </div>
            <div class="comm-header-rigth">
              <a-dropdown>
                <a-button type="primary"><a-icon type="plus" />Add</a-button>
                <a-menu slot="overlay">
                  <a-menu-item @click="onAddItem({ id: '', site_type: '0' })">
                    Social Media
                  </a-menu-item>
                  <a-menu-item @click="onAddItem({ id: '', site_type: '1' })">
                    Website
                  </a-menu-item>
                  <a-menu-item @click="onAddItem({ id: '', site_type: '2' })">
                    App
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </div>
          <div class="comm-list">
            <a-table
              class="comtable"
              :columns="columns"
              :data-source="dataLists"
              :rowKey="(record, index) => record.id"
              :loading="isLoading"
              :pagination="false"
            >
              <div class="comm-img" slot="platforms" slot-scope="text, row">
                <template v-if="+row.site_type === 0">
                  <img
                    src="https://cdn.partnerboost.com/static/images/icon-instagram.svg"
                    alt=""
                    v-if="text === '1'"
                  />
                  <img
                    src="https://cdn.partnerboost.com/static/images/icon-youtube.svg"
                    alt=""
                    v-else-if="text === '2'"
                  />
                  <img
                    src="https://cdn.partnerboost.com/static/images/icon-facebook.svg"
                    alt=""
                    v-else-if="text === '3'"
                  />
                  <img
                    src="https://cdn.partnerboost.com/static/images/icon-tiktok.svg"
                    alt=""
                    v-else-if="text === '4'"
                  />
                  <img
                    src="https://cdn.partnerboost.com/static/images/icon-weibo.svg"
                    alt=""
                    v-else-if="text === '5'"
                  />
                  <img
                    src="https://cdn.partnerboost.com/static/images/icon-social-other.svg"
                    alt=""
                    v-else-if="text === '6'"
                  />
                  <span v-else>-</span>
                </template>
                <span v-else>-</span>
              </div>
              <div class="comm-status" slot="status" slot-scope="text, row">
                <span
                  class="comm-status-verifying"
                  v-show="row.status_msg === 'Verifying'"
                  >{{ row.status_msg }}</span
                >
                <span
                  class="comm-status-approved"
                  v-show="row.status_msg === 'Approved'"
                  >{{ row.status_msg }}</span
                >
                <span
                  class="comm-status-rejected"
                  v-show="row.status_msg === 'Rejected'"
                  >{{ row.status_msg }}</span
                >
              </div>

              <div class="comm-actions" slot="actions" slot-scope="text, row">
                <img
                  src="@/assets/public/icon-edit.svg"
                  @click="onAddItem(row)"
                />
                <a-popconfirm
                  title="Sure to delete?"
                  @confirm="onDeleteItem(row)"
                >
                  <img
                    v-if="row.status !== '1'"
                    src="@/assets/public/icon-delete-1.svg"
                  />
                </a-popconfirm>
              </div>
            </a-table>
            <div class="footer-pagination" v-if="false">
              <a-pagination
                v-model="currentPage"
                :total="102"
                show-less-items
                @change="paginationChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <a-modal
      v-model="socialModal"
      :title="`${socialForm.id ? 'Edit' : 'Add'} Social Media`"
      :footer="null"
      width="600px"
      class="channelModal"
    >
      <a-spin :spinning="formLoading">
        <a-form-model
          :model="socialForm"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :rules="socialRules"
          ref="socialForm"
        >
          <a-form-model-item
            label="Social Platform"
            prop="social_platform_type"
          >
            <a-select
              v-model="socialForm.social_platform_type"
              placeholder="Please Select..."
              :getPopupContainer="
                (triggerNode) => {
                  return triggerNode.parentNode || document.body;
                }
              "
            >
              <a-select-option v-for="i in platList" :key="i.id" :value="i.id">
                {{ i.en_title }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="Channel Name" prop="title">
            <a-input v-model="socialForm.title" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="Channel URL" prop="host">
            <a-input v-model="socialForm.host" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="Category" prop="c_id">
            <a-select
              mode="multiple"
              v-model="socialForm.c_id"
              disabled
              placeholder="Please Select..."
              showArrow
              :getPopupContainer="
                (triggerNode) => {
                  return triggerNode.parentNode || document.body;
                }
              "
            >
              <a-select-option v-for="i in cataList" :key="i.id" :value="i.id">
                {{ i.title_en }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="Brief Introduction" prop="brief">
            <a-textarea
              v-model="socialForm.brief"
              placeholder="Type"
              :auto-size="{ minRows: 5, maxRows: 10 }"
            />
          </a-form-model-item>
          <a-form-model-item
            :wrapper-col="{ span: 18, offset: 6 }"
            class="form-btn"
          >
            <a-button style="margin-right: 20px;" @click="back" class="b1">
              Cancel
            </a-button>
            <a-button type="primary" @click="onSubmit('socialForm')" class="b2">
              Save
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </a-spin>
    </a-modal>

    <a-modal
      v-model="websiteModal"
      :title="`${websiteForm.id ? 'Edit' : 'Add'} Website`"
      :footer="null"
      width="600px"
      class="channelModal"
    >
      <a-spin :spinning="formLoading">
        <a-form-model
          :model="websiteForm"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :rules="websiteRules"
          ref="websiteForm"
        >
          <a-form-model-item label="Website name" prop="title">
            <a-input v-model="websiteForm.title" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="Website URL" prop="host">
            <a-input v-model="websiteForm.host" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="Category" prop="c_id">
            <a-select
              mode="multiple"
              v-model="websiteForm.c_id"
              placeholder="Please Select..."
              showArrow
              :getPopupContainer="
                (triggerNode) => {
                  return triggerNode.parentNode || document.body;
                }
              "
            >
              <a-select-option v-for="i in cataList" :key="i.id" :value="i.id">
                {{ i.title_en }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="Brief Introduction" prop="brief">
            <a-textarea
              v-model="websiteForm.brief"
              placeholder="Type"
              :auto-size="{ minRows: 5, maxRows: 10 }"
            />
          </a-form-model-item>
          <a-form-model-item
            :wrapper-col="{ span: 18, offset: 6 }"
            class="form-btn"
          >
            <a-button style="margin-right: 20px;" @click="back" class="b1">
              Cancel
            </a-button>
            <a-button
              type="primary"
              @click="onSubmit('websiteForm')"
              class="b2"
            >
              Save
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </a-spin>
    </a-modal>

    <a-modal
      v-model="appModal"
      :title="`${appForm.id ? 'Edit' : 'Add'} App`"
      :footer="null"
      width="600px"
      class="channelModal"
    >
      <a-spin :spinning="formLoading">
        <a-form-model
          :model="appForm"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :rules="appRules"
          ref="appForm"
        >
          <a-form-model-item label="App name" prop="title">
            <a-input v-model="appForm.title" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="Platform URL" prop="host">
            <div class="form-compact">
              <a-select
                placeholder="Please Select..."
                style="width: 8.5714rem"
                v-model="appForm.app_platform_type"
              >
                <a-select-option value="0">
                  iOS
                </a-select-option>
                <a-select-option value="1">
                  Android
                </a-select-option>
              </a-select>
              <a-input v-model="appForm.host"></a-input>
            </div>
          </a-form-model-item>
          <a-form-model-item label="Category" prop="c_id">
            <a-select
              mode="multiple"
              v-model="appForm.c_id"
              placeholder="Please Select..."
              showArrow
              :getPopupContainer="
                (triggerNode) => {
                  return triggerNode.parentNode || document.body;
                }
              "
            >
              <a-select-option v-for="i in cataList" :key="i.id" :value="i.id">
                {{ i.title_en }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="Brief Introduction" prop="brief">
            <a-textarea
              v-model="appForm.brief"
              placeholder="Type"
              :auto-size="{ minRows: 5, maxRows: 10 }"
            />
          </a-form-model-item>
          <a-form-model-item
            :wrapper-col="{ span: 18, offset: 6 }"
            class="form-btn"
          >
            <a-button style="margin-right: 20px;" @click="back" class="b1">
              Cancel
            </a-button>
            <a-button type="primary" @click="onSubmit('appForm')" class="b2">
              Save
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </a-spin>
    </a-modal>
  </div>
</template>
<script>
import Header from "@/components/public/Header.vue";
import axios from "@/utils/request";
import APIS from "@/api/const";

export default {
  components: {
    Header,
    // Modal
  },
  data() {
    let columns = [
      {
        title: "Channel ID",
        dataIndex: "medium_id",
        align: "center",
      },
      {
        title: "Channel Name",
        dataIndex: "title",
        customRender: (text, row) => {
          return row.title || "-";
        },
      },
      {
        title: "Channel Type",
        dataIndex: "site_type",
        align: "center",
        customRender: (text, row) => {
          // 0 social 1 website 2 app
          switch (row.site_type) {
            case "0":
              return "Social Media";
            case "1":
              return "Website";
            case "2":
              return "App";
            default:
              return "-";
          }
        },
      },
      {
        title: "Platform",
        dataIndex: "social_platform_type",
        align: "center",
        scopedSlots: { customRender: "platforms" },
      },
      {
        title: "Channel URL",
        dataIndex: "host",
      },
      {
        title: "Time Added",
        dataIndex: "time_add",
        align: "center",
        width: "220px",
      },
      {
        title: "Status",
        dataIndex: "status",
        align: "center",
        scopedSlots: { customRender: "status" },
      },
      {
        title: "Action",
        dataIndex: "",
        align: "center",
        scopedSlots: { customRender: "actions" },
      },
    ];
    let validateHost = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("URL is required"));
      } else if (
        !/^(https?:)\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g.test(
          value
        )
      ) {
        callback(
          new Error("Please enter a valid URL, with http:// or https://")
        );
      } else {
        callback();
      }
    };
    return {
      columns,
      dataLists: [],
      cataList: [],
      platList: [],
      currentPage: 1,
      isLoading: false,
      formLoading: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      socialForm: {
        id: "",
        site_type: 0, // 0 social 1 website 2 app
        social_platform_type: undefined,
        host: "",
        c_id: ["23"],
        brief: "",
        title: "",
      },
      websiteForm: {
        id: "",
        site_type: 1, // 0 social 1 website 2 app
        title: "",
        host: "",
        c_id: [],
        brief: "",
      },
      appForm: {
        id: "",
        site_type: 2, // 0 social 1 website 2 app
        title: "",
        app_platform_type: "0",
        host: "",
        c_id: [],
        brief: "",
      },
      socialRules: {
        social_platform_type: [
          {
            required: true,
            message: "Social Platform is required",
            trigger: ["blur", "change"],
          },
        ],
        c_id: [
          {
            required: true,
            message: "Category is required",
            trigger: ["blur", "change"],
          },
        ],
        title: [
          {
            required: true,
            message: "Channel Name is required",
            trigger: ["blur", "change"],
          },
        ],
        host: [
          {
            required: true,
            validator: validateHost,
            trigger: ["blur", "change"],
          },
        ],
        brief: [
          {
            required: true,
            message: "Brief Introduction is required",
            trigger: ["blur", "change"],
          },
        ],
      },
      websiteRules: {
        title: [
          {
            required: true,
            message: "Website name is required",
            trigger: ["blur", "change"],
          },
        ],
        c_id: [
          {
            required: true,
            message: "Category is required",
            trigger: ["blur", "change"],
          },
        ],
        host: [
          {
            required: true,
            validator: validateHost,
            trigger: ["blur", "change"],
          },
        ],
        brief: [
          {
            required: true,
            message: "Brief Introduction is required",
            trigger: ["blur", "change"],
          },
        ],
      },
      appRules: {
        title: [
          {
            required: true,
            message: "App name is required",
            trigger: ["blur", "change"],
          },
        ],
        c_id: [
          {
            required: true,
            message: "Category is required",
            trigger: ["blur", "change"],
          },
        ],
        host: [
          {
            required: true,
            validator: validateHost,
            trigger: ["blur", "change"],
          },
        ],
        brief: [
          {
            required: true,
            message: "Brief Introduction is required",
            trigger: ["blur", "change"],
          },
        ],
      },
      socialModal: false,
      websiteModal: false,
      appModal: false,
    };
  },
  mounted() {
    this.getList();
    this.getCommonList();
  },
  methods: {
    onSubmit(ruleForm) {
      this.$refs[ruleForm].validate(async (valid) => {
        if (valid) {
          const resp = await axios.post(APIS.channel.site_create, {
            formData: true,
            ...Object.assign(
              {
                ...this[ruleForm],
              },
              {
                c_id: this[ruleForm].c_id.join(","),
              }
            ),
          });
          if (resp.code === "0200") {
            this.$message.success(resp.msg);
            this.getList();
            this.back();
          } else {
            this.$message.error(resp.msg);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onAddItem(row) {
      const { id } = row;
      if (id) {
        this.getItemDetail(row);
      }
      this.initModal(row);
      this.resetFormFields(row);
    },
    initModal({ site_type, id }) {
      if (site_type === "0") {
        this.socialModal = true;
        this.socialForm.id = id;
      } else if (site_type === "1") {
        this.websiteModal = true;
        this.websiteForm.id = id;
      } else if (site_type === "2") {
        this.appModal = true;
        this.appForm.id = id;
      }
    },
    resetFormFields({ site_type }) {
      try {
        if (site_type === "0") {
          this.$refs["socialForm"].resetFields();
        } else if (site_type === "1") {
          this.$refs["websiteForm"].resetFields();
        } else if (site_type === "2") {
          this.$refs["appForm"].resetFields();
        }
      } catch (e) {}
    },
    async getItemDetail({ id, site_type }) {
      this.formLoading = true;
      const resp = await axios.get(APIS.channel.site_detail, {
        params: { id },
      });
      if (resp.code === "0200") {
        this.pushFormFieldsBySiteType({ ...resp.payload }, site_type);
        this.formLoading = false;
      }
    },
    pushFormFieldsBySiteType(
      {
        id,
        social_platform_type,
        host,
        c_id,
        content,
        title,
        app_platform_type,
      },
      site_type
    ) {
      if (site_type === "0") {
        this.socialForm = {
          id,
          site_type: 0, // 0 social 1 website 2 app
          social_platform_type: social_platform_type || undefined,
          host,
          // c_id: c_id.map((item) => item.id),
          c_id: ["23"], //默认 social media
          brief: content,
          title,
        };
      } else if (site_type === "1") {
        this.websiteForm = {
          id,
          site_type: 1, // 0 social 1 website 2 app
          title,
          host,
          c_id: c_id.map((item) => item.id),
          brief: content,
        };
      } else if (site_type === "2") {
        this.appForm = {
          id,
          site_type: 2, // 0 social 1 website 2 app
          title,
          app_platform_type,
          social_platform_type: social_platform_type || undefined,
          host,
          c_id: c_id.map((item) => item.id),
          brief: content,
        };
      }
    },
    async onDeleteItem({ id }) {
      const resp = await axios.post(APIS.channel.site_delete, {
        formData: true,
        id,
      });
      this.$message.success(resp.msg);
      this.getList();
    },
    async getCommonList() {
      const resp = await axios.get(APIS.channel.cata_list);
      const { site_platfom, site_category } = resp.payload;
      this.platList = site_platfom;
      this.cataList = site_category;
    },
    getList() {
      this.isLoading = true;
      axios
        .get(APIS.channel.index, {
          params: {},
        })
        .then((resp) => {
          if (resp.code === "0200") {
            this.dataLists = resp.payload;
          }
          this.isLoading = false;
        });
    },
    back() {
      this.socialModal = false;
      this.websiteModal = false;
      this.appModal = false;
    },
  },
  beforeDestroy() {
    if (this.onWindowResize) {
      window.onresize = null;
    }
  },
};
</script>

<style lang="less" scoped>
.mychannel {
  .headers {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 0.8571rem;
  }
  .content {
    .comm {
      &-actions {
        display: flex;
        justify-content: center;
        img {
          width: 1.1429rem;
          height: 1.1429rem;
          cursor: pointer;
          &:hover {
            opacity: 0.85;
          }
          + img {
            margin-left: 1rem;
          }
        }
      }
      &-status {
        &-verifying {
          color: #999999;
          background: #eeeeee;
        }
        &-approved {
          color: #ffffff;
          background: #2ad57b;
        }
        &-rejected {
          color: #ffffff;
          background: #f64a47;
        }
        span {
          padding: 0.2857rem 0.5714rem;
          border-radius: 4px;
          font-size: 0.8571rem;
        }
      }
      &-platforms {
        display: flex;
        justify-content: center;
        img {
          width: 1.4286rem;
          height: 1.4286rem;
          margin-right: 0.2857rem;
        }
      }
    }
    .comm-header-left {
      font-size: 1.2857rem;
      font-family: "prefer-bold";
    }
    .comm-header-more {
      color: #333333;
      cursor: pointer;
      i {
        font-size: 0.7143rem;
      }
    }
    .tb-total {
      padding: 16px;
      display: flex;
      justify-content: flex-end;
      &-text {
        color: #666666;
      }
      &-money {
        color: #3762d6;
      }
    }
  }
  /deep/ .ant-select {
    width: 7.1429rem;
  }
  /deep/ .ant-select-selection--single {
    border-radius: 19px;
    border: 1px solid #3762d6;
    box-shadow: none !important;
  }
  /deep/ .ant-select-selection__rendered {
    margin-left: 1.8571rem;
  }
  /deep/ .ant-select-selection-selected-value,
  /deep/ .ant-select-arrow-icon {
    color: #3762d6;
  }
  .comm-img {
    img {
      width: 20px;
    }
  }
}

.channelModal {
  /deep/ .ant-modal-header {
    padding-top: 26px;
  }
  /deep/ .ant-modal-close {
    top: 10px;
  }
  /deep/ .ant-form-item-label {
    float: left;
    text-align: left;

    .ant-form-item-required {
      &::before {
        margin: 0;
        content: "" !important;
      }

      &::after {
        color: #f5222d;
        font-size: 1rem;
        content: "*" !important;
      }
    }
  }

  /deep/ .ant-select-selection {
    height: 42px !important;
    line-height: 42px !important;
    background: #f6f9ff !important;
    border-radius: 4px !important;
    border: 1px solid transparent;
    outline: none;
    box-shadow: none;
    &.ant-select-selection--multiple {
      height: auto !important;
    }
  }

  /deep/ .ant-select ul,
  .ant-select ol {
    margin-top: 3px;
    .ant-select-selection__choice__content {
      font-size: 0.8571rem;
    }
    li {
      margin-bottom: 5px;
    }
  }
  /deep/ .ant-input {
    height: 42px !important;
    line-height: 42px !important;
    background: #f6f9ff !important;
    border-radius: 4px !important;
    border: 1px solid transparent;
    outline: none;
    box-shadow: none;
  }
  /deep/ textarea.ant-input {
    line-height: 20px !important;
  }

  /deep/ .ant-select-selection--multiple .ant-select-selection__clear,
  /deep/ .ant-select-selection--multiple .ant-select-arrow {
    top: 50%;
  }
  .form-btn {
    .ant-btn {
      width: 8.5714rem;
      height: 2.8571rem;
      border-radius: 4px;
      border: 1px solid #3762d6;
      color: #3762d6;
      &.ant-btn-primary {
        color: #fff;
      }
    }
  }
  /deep/ .ant-select-selection--multiple {
    padding-bottom: 0;
  }
  /deep/ .has-error .ant-select-selection,
  /deep/ .has-error .ant-input,
  .has-error .ant-input:hover {
    box-shadow: none;
    background-color: #fff;
    border-color: #f5222d;
  }
  /deep/ .ant-select-selection--multiple .ant-select-selection__placeholder {
    margin-top: -14px;
  }

  /deep/ .ant-select-selection--single .ant-select-selection__rendered {
    margin-left: 16px;
  }
  /deep/ .ant-input-group-addon .ant-select-open .ant-select-selection,
  .ant-input-group-addon .ant-select-focused .ant-select-selection {
    color: #333;
  }
  /deep/ .has-error .ant-form-explain,
  .has-error .ant-form-split {
    padding-left: 16px;
    padding-bottom: 10px;
    padding-top: 5px;
  }
  /deep/ .ant-select-dropdown {
    background: #ffffff;
    box-shadow: 0px 0px 15px 0px rgba(102, 118, 186, 0.12);
    border-radius: 4px;
  }
  /deep/ .ant-select-dropdown .ant-select-dropdown-menu-item {
    text-align: left;
  }
  /deep/
    .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
  /deep/ .ant-select-dropdown-menu-item-selected {
    background: #f6f9ff;
    color: #3762d6;
  }
  /deep/ .ant-select-selection--multiple .ant-select-selection__choice {
    border-radius: 13px;
    border: 1px solid #3762d6;
    padding: 0 25px 0 15px;
  }
  /deep/ .ant-select-selection--multiple .ant-select-selection__choice__content,
  /deep/ .ant-select-selection--multiple .ant-select-selection__choice__remove {
    color: #3762d6;
  }
  /deep/ .ant-select-selection--multiple .ant-select-selection__choice__remove {
    margin-right: 4px;
  }
}
.form-compact {
  display: flex;
  .ant-select {
    margin-right: 10px;
    flex: 0 0 8.5714rem;
  }
}
</style>
