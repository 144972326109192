<template>
  <div class="child-details">
    <div class="tabs">
      <div
        class="tab"
        :class="{ active: tabActive == 'payment' }"
        @click="tabActive = 'payment'"
      >
        Payment Method
      </div>
      <div
        class="tab"
        :class="{ active: tabActive == 'auto' }"
        @click="tabActive = 'auto'"
      >
        Auto-refill
      </div>
    </div>
    <div class="tab-contents">
      <div class="tab-contents-item" v-if="tabActive == 'payment'">
        <div class="tab-contents-item-add">
          + Add Payment Method
        </div>
      </div>
      <div class="tab-contents-item" v-else-if="tabActive == 'auto'"></div>
    </div>
    <Loading v-if="isLoadingModal"></Loading>
  </div>
</template>

<script>
import Modal from "../../../components/Modal.vue";
import inspectUserPermissions from "@/utils/permissionsJudge";
import axios from "@/utils/request";

export default {
  name: "account-edit",
  components: {
    Modal,
  },
  data() {
    return {
      tabActive: "payment",
      isLoadingModal: false,
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.child-details {
  width: 948px;
  height: 650px;
  background: #ffffff;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: flex-start;
}

.tabs {
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;

  .tab {
    height: 2.8571rem;
    padding: 0 20px;
    color: #2898ff;
    line-height: 2.8571rem;
    font-size: 1.1429rem;
    user-select: none;
    cursor: pointer;

    &.active {
      background: #2898ff;
      border-radius: 4px;
      color: #fff;

      &:hover {
        background-color: #52b1ff;
      }
    }

    + .tab {
      margin-left: 20px;
    }
  }
}

.tab-contents-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;

  .tab-contents-item-add {
    width: 426px;
    height: 8.5714rem;
    border-radius: 12px;
    border: 1px dashed #d8d8d8;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2857rem;
    font-family: HarmonyOS_Sans_Bold;
    color: #2898ff;
    user-select: none;
    cursor: pointer;
  }
}
</style>
