<template>
  <a class="campaign-item" v-if="item" @click="gotoDetail">
    <div class="campaign-item-head">
      <img class="campaign-item-head__img" :src="item.image | getSourceUrl" />
      <i
        class="campaign-item-head__count"
        v-if="item.applied_count && !isExpired"
        >{{ item.applied_count }}</i
      >
    </div>
    <div class="campaign-item-content">
      <p class="campaign-item-content__title one-line">{{ item.name }}</p>
      <span class="campaign-item-content__info"
        >{{ item.start_time | timeToUsFormat }} -
        {{ item.end_time | timeToUsFormat }}</span
      >
      <span
        class="campaign-item-content__tag"
        :class="isExpired ? 'gray' : ''"
        >{{ isExpired ? "Expired" : "Ongoing" }}</span
      >
    </div>
  </a>
</template>

<script>
const defaultLogo = require("@/assets/default.png");
import Modal from "../../../../components/Modal.vue";

export default {
  components: { Modal },
  props: {
    item: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    isExpired() {
      if (
        this.item.start_time < this.item.deadline &&
        this.item.end_time > this.item.deadline
      ) {
        return false;
      }
      return true;
    },
    status() {
      return this.item.status
        ? {
            adopt: "Approved",
            refuse: "Declined",
            verifing: "Pending",
            terminated: "Removed",
          }[this.item.status]
        : "Not Yet";
    },
    logo() {
      let logo = this.item.logo;
      if (!logo) {
        logo = defaultLogo;
      } else if (
        this.item.logo.indexOf("http") !== 0 &&
        this.item.logo.indexOf("//") !== 0
      ) {
        logo = this.APP_CONFIG.OSS_HOST + logo;
      }
      return logo;
    },
    flag() {
      try {
        return require("@/assets/flag/" + this.item.country + ".png");
      } catch (e) {
        return null;
      }
    },
  },
  data() {
    return {
      countryMap: {},
      statByLocal: "today",
      errorPlaceHolder: null,
      isShowUpdate: false,
      newStatus: "",
      isLoadingModal: false,
    };
  },
  watch: {},
  created() {},
  methods: {
    gotoDetail() {
      this.$router.push({
        name: "campaign_overview",
        params: {
          id: this.item.id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.campaign-item {
  width: 238px;
  display: flex;
  flex-direction: column;
  border-radius: 12px 12px 0 0;
  margin-top: 24px;
  margin-left: calc((100% - 952px) / 3);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.03);
  transition: all 0.3s;
  &:nth-child(4n + 1) {
    margin-left: 0;
  }
  &:hover {
    transform: translateY(-8px);
  }
  &-head {
    position: relative;
    > img {
      width: 238px;
      height: 144px;
      border-radius: 12px 12px 0 0;
      object-fit: cover;
      object-position: center;
    }
    > i {
      width: 27px;
      height: 27px;
      background: #ff454c;
      color: #fff;
      position: absolute;
      border-radius: 50%;
      text-align: center;
      line-height: 27px;
      right: -12px;
      top: -8px;
      font-style: normal;
    }
  }
  &-content {
    width: 238px;
    height: 8.5714rem;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 0px 0px 12px 12px;
    padding: 12px;
    &__title {
      font-size: 1rem;
      font-family: Circular-Black, Circular;
      font-weight: 900;
      color: #333333;
      line-height: 18px;
      margin-bottom: 0;
    }
    &__info {
      font-size: 1rem;
      font-family: Circular-Book, Circular;
      font-weight: normal;
      color: #999999;
      line-height: 18px;
      margin-top: 8px;
      display: inline-block;
    }
    &__tag {
      height: 32px;
      background: #05c15f;
      box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
      border-radius: 6px;
      color: #fff;
      text-align: center;
      line-height: 32px;
      padding: 0px 16px;
      margin-top: 10px;
      display: inline-block;
      &.gray {
        background: #f5f5f5;
        color: #999999;
      }
    }
  }
}
</style>
