<template>
  <div class="list">
    <Brands collection="Publishers" subset="Pending Publishers"></Brands>
    <SubMenu :btnLists="subMenus"></SubMenu>

    <div class="content">
      <div class="content-title">
        <div class="items">
          <div class="item">
            <span>Country</span>
            <PSelector
              size="large"
              v-model="params.country_id"
              :options="
                countryList.map((c) => ({
                  value: c.id,
                  label: c.name_en,
                }))
              "
              multiple
              clearAble
            ></PSelector>
            <!-- <a-select v-model="params.country_id" class="select">
              <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
              <a-select-option value>All</a-select-option>
              <a-select-option :value="item.id" v-for="(item, index) in countryList" :key="index">{{ item.name_en }}</a-select-option>
            </a-select> -->
          </div>
          <div class="item">
            <span>Search Keywords</span>
            <a-input v-model="params.keyword" />
          </div>
        </div>
        <div class="search-reset">
          <button @click="search">Search</button>
          <button @click="reset">Reset</button>
        </div>
      </div>

      <a-spin
        class="content-list"
        :class="{ empty: mediumLists.length == 0 }"
        :spinning="isLoading"
      >
        <div class="list-prefix">
          <div class="total-size" style="margin-right:20px">
            {{ totalSize }} Results
          </div>
          <!-- <div class="auto-accept">
            <div class="total-size" style="margin-right:20px">{{ totalSize }} Results</div>
              Auto Approval
            <a-tooltip placement="rightTop" :overlayStyle="{ width: '410px', maxWidth: '410px' }">
              <template slot="title">
                <div class="tooltips-auto">
                  When auto approval is turned on,any publisher applies for cooperation, it will approves them instantly.
                  When auto approval is turned off,any publisher applies for cooperation, you need to manually choose to approve or decline.
                </div>
              </template>
              <a-button size="small" type="link">
                <img src="@/assets/icon-question.svg" style="margin-top:-3px" />
              </a-button>
            </a-tooltip>

            <a-switch :checked="isAutoApproval" size="small" @change="handleChangeAutoApproval" :loading="isAutoApprovalLoading" />
          </div> -->
          <div
            class="select-all"
            @click="selectAll"
            v-if="mediumLists.length > 0"
          >
            Select All
          </div>
        </div>

        <a-empty v-if="mediumLists.length == 0 && !isLoading" />
        <medium-card
          v-for="(item, idx) in mediumLists"
          :key="item.medium_id"
          :item="item"
          :tab-active="tabActive"
          :stat-by="params.stats_by"
          :checked="checkedList.includes(item.site_id)"
          @checkChange="handleChangeCheck"
          @statusChange="handleChangeStatus"
          @tabActiveChange="handleChangeTabActive"
          :showStatusSelect="true"
        ></medium-card>

        <div
          class="footer-pagination"
          v-if="totalSize > 0 && mediumLists.length > 0"
        >
          <a-pagination
            v-model="currentPage"
            :total="totalSize"
            :defaultPageSize="20"
            show-less-items
            @change="paginationChange"
          />
        </div>

        <div class="item-action" v-show="checkedList.length > 0">
          <div class="item-action-inside">
            <div class="number">
              <div>Publisher Mass Action</div>
              <div>Total: {{ checkedList.length }}</div>
            </div>
            <div class="status" id="status-box">
              <span>Change Status</span>
              <a-select
                placeholder="Select"
                size="large"
                v-model="batchStatus"
                :getPopupContainer="getStatusContainer"
              >
                <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
                <a-select-option value="Approved">Approved</a-select-option>
                <a-select-option value="Declined">Declined</a-select-option>
              </a-select>
            </div>
            <div class="options">
              <a-button
                size="large"
                type="primary"
                :disabled="!batchStatus"
                @click="isShowUpdate = true"
                >Update</a-button
              >&emsp;
              <a-button
                size="large"
                type="primary"
                ghost
                @click="checkedList = []"
                >Cancel</a-button
              >
            </div>
          </div>
          <!-- <div class="item-action-approve" @click="isShowUpdate = true">Terminate cooperation</div> -->
        </div>
        <!-- <div class="item-action" v-show="checkedList.length > 0">
          <div class="item-action-approve" @click="batchEdit('adopt')">Approve</div>
          <div class="item-action-decline" @click="batchEdit('refuse')">Decline</div>
        </div>-->
        <div class="clearboth"></div>
      </a-spin>
    </div>
    <Modal
      :isVisible="isShowUpdate"
      title="Mass Action Confirmation"
      :showBtn="false"
      @close-popup="isShowUpdate = false"
      width="365px"
      class="batch-update-modal"
    >
      <template #content>
        <div class="update-info">
          <div class="color-primary font-bold">
            {{ checkedList.length }} Publisher{{
              checkedList.length > 1 ? "s" : ""
            }}
          </div>
          <div v-for="item in batchStatusObjList" :key="item.id">
            {{ item.title }} - {{ item.medium_id }}
          </div>

          <div class="color-primary font-bold">Status Change</div>
          <div>Set status to: {{ batchStatus }}</div>
        </div>
        <div style="display: flex; justify-content: center;">
          <a-button
            type="default"
            @click="isShowUpdate = false"
            style="margin-right: 60px;"
            size="large"
            >Cancel</a-button
          >
          <a-button
            type="primary"
            size="large"
            style="font-size:16px"
            @click="batchEdit"
            >Confirm</a-button
          >
        </div>
      </template>
    </Modal>
    <Loading v-if="isLoadingModal"></Loading>

    <div class="clearboth"></div>
  </div>
</template>

<script>
import Brands from "../../components/Brands.vue";
import axios from "@/utils/request";
import MediumCard from "./components/MediumCard.vue";
import SubMenu from "../../components/SubMenu.vue";
import Modal from "../../components/Modal.vue";

export default {
  name: "publisher_list",
  components: {
    Brands,
    SubMenu,
    MediumCard,
    Modal,
  },
  data() {
    return {
      subMenus: [
        {
          name: "Publisher List",
          path: "publisher_list",
        },
        {
          name: "Pending Publishers",
          path: "publisher_pending",
          // }, {
          //   name: 'Search Publishers',
          //   path: 'search_publisher',
        },
      ],
      keyword: "",
      chatMediumId: null,
      checkedList: [],
      countryList: [],
      countryMap: {},
      mediumLists: [],
      isLoading: true,
      statByLoading: false,
      currentPage: 1,
      page_size: 10,
      totalSize: 0,
      totalPage: 0,
      tabActive: "info",
      // isAutoApproval: true,
      // isAutoApprovalLoading: false,
      params: {
        // adopt 已签约
        // refuse 已拒绝
        // verifing 待审核
        status: ["verifing"],
        country_id: [],
        keyword: null,
        has_private_norm: null,
        stats_by: "today",
      },
      isShowUpdate: false,
      batchStatus: undefined,
      isLoadingModal: false,
      batchStatusObjList: [],
    };
  },
  mounted() {
    this._getCoutry();
    this._getMediumLists();
    // this.refreshUserInfo().then((data) => {
    //   this.isAutoApproval = data.merchant.verify_type == 'auto'
    // })
  },
  watch: {
    checkedList(newV, oldV) {
      if (!newV || newV.length == 0) {
        this.batchStatus = undefined;
      }
      this.batchStatusObjList = this.mediumLists.filter((item) =>
        newV.includes(item.site_id)
      );
    },
  },
  methods: {
    _getCoutry() {
      this.countryList = this.$store.state.commonConfig.countries.map(
        (item) => {
          this.countryMap[item.id] = item;
          return item;
        }
      );
    },
    _getMediumLists() {
      axios
        .get("/api_admin.php?a=medium/list", {
          params: { ...this.params, page_num: this.currentPage },
        })
        .then((res) => {
          this.mediumLists = [];
          if (res.code === 0) {
            res.data.data.forEach((item) => {
              let newItem = {
                ...item,
                aff_ba: parseInt(item.norm.aff_ba) + item.norm.aff_ba_unit,
              };
              this.mediumLists.push(newItem);
            });
            this.isLoading = false;
            this.totalSize = parseInt(res.data.total_size);
            this.totalPage = parseInt(res.data.total_page);
            document.documentElement.scrollTop = 0;
          } else {
            this.$message.error(
              res.msg || "Sorry, server is busy. please try later."
            );
          }
        });
    },
    reset() {
      this.params = {
        status: ["verifing"],
        country_id: [],
        keyword: null,
        has_private_norm: null,
        stats_by: "today",
      };
      this.search();
    },
    search() {
      this.isLoading = true;
      this.currentPage = 1;
      this._getMediumLists();
    },
    paginationChange(page, pageSize) {
      this.isLoading = true;
      this.currentPage = page;
      this._getMediumLists();
    },
    handleChangeCountry(val) {
      this.params.country_id = val;
    },
    handleChangeDefaultPrivate(val) {
      this.params.has_private_norm = val;
    },
    // handleChangeAutoApproval(val) {
    //   this.isAutoApprovalLoading = true
    //   axios.post('/api_admin.php?a=user/updateMerchantInfo', {
    //     type: 'verify_type',
    //     verify_type: val ? 'auto' : ''
    //   }).then(res => {
    //     this.isAutoApprovalLoading = false
    //     if (res.code === 0) {
    //       this.isAutoApproval = val
    //     } else {
    //       this.$message.error(res.msg || 'Sorry, server is busy. please try later.')
    //     }
    //   }).catch(() => {
    //     this.isAutoApprovalLoading = false
    //   })
    // },

    handleChangeCheck(siteId, isCheck) {
      if (isCheck && !this.checkedList.includes(siteId)) {
        this.checkedList.push(siteId);
      } else if (!isCheck && this.checkedList.includes(siteId)) {
        this.checkedList.splice(this.checkedList.indexOf(siteId), 1);
      }
    },
    handleChangeTabActive(val) {
      this.tabActive = val;
    },

    checkedChange(site_id) {
      if (this.checkedList.includes(site_id)) {
        this.checkedList.splice(this.checkedList.indexOf(site_id), 1);
      } else {
        this.checkedList.push(site_id);
      }
    },
    selectAll() {
      if (this.mediumLists.length == this.checkedList.length) {
        this.checkedList = [];
        return;
      }
      this.mediumLists.forEach((item) => {
        if (!this.checkedList.includes(item.site_id)) {
          this.checkedList.push(item.site_id);
        }
      });
    },
    batchEdit(status) {
      let newStatus = {
        Approved: "adopt",
        Declined: "refuse",
        Pending: "verifing",
        Removed: "terminated",
      }[this.batchStatus];
      let finalSiteIds = this.batchStatusObjList
        .filter((item) => item.status != newStatus)
        .map((item) => item.site_id);

      if (finalSiteIds.length == 0) {
        this.$message.error("Nothing to change");
        this.checkedList = [];
        return;
      }

      this.isLoadingModal = true;
      axios
        .post("/api_admin.php?a=medium/edit", {
          site_ids: finalSiteIds.join(","),
          status: newStatus,
        })
        .then((res) => {
          this.isLoadingModal = false;
          this.isShowUpdate = false;
          if (res.code === 0) {
            this.mediumLists = this.mediumLists.filter(
              (item) => !finalSiteIds.includes(item.site_id)
            );
            this.totalSize--;
            if (this.mediumLists.length == 19) {
              this.search();
            }
          }
        })
        .catch((e) => {
          this.isLoadingModal = false;
        });
    },
    handleChangeStatus(item) {
      this.mediumLists = this.mediumLists.filter(
        (_item) => _item.site_id != item.site_id
      );
      this.totalSize--;
      if (this.mediumLists.length == 19) {
        this.search();
      }
    },
    getStatusContainer() {
      return document.getElementById("status-box");
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  margin-bottom: 100px;

  .content-title {
    width: 100%;
    padding: 20px 44px 30px 30px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;

    .items {
      display: flex;

      .item {
        width: 262px;
        margin-right: 26px;

        &:last-child {
          margin-right: 0px;
        }

        span {
          display: block;
          font-size: 1.1429rem;
          font-family: "dtc-bold";
          color: #333333;
          line-height: 30px;
          margin-bottom: 6px;
        }

        input {
          width: 100%;
          height: 2.8571rem;
          background: #ffffff;
          border-radius: 6px;
          border: 1px solid #e6e6e6;
        }

        .select {
          width: 100%;
          height: 2.8571rem;

          /deep/ .ant-select-selection--single {
            height: 2.8571rem;
            border-radius: 6px;
            background: #fafafa;
          }

          /deep/ .ant-select-selection__rendered {
            line-height: 2.8571rem;
          }

          .icon-down {
            color: #333333;
            font-size: 1rem;
          }
        }

        .date {
          width: 100%;
          height: 2.8571rem;

          /deep/ .ant-input {
            height: 2.8571rem;
            border-radius: 25px;
            background: #fafafa;
          }
        }
      }
    }

    .search-reset {
      margin-top: 20px;
      text-align: right;

      button {
        padding: 9px 32px;
        border-radius: 6px;
        cursor: pointer;
        border: none;

        &:first-child {
          background: #2898ff;
          font-size: 1.1429rem;
          color: #ffffff;
          line-height: 20px;
          margin-right: 20px;
        }

        &:last-child {
          border: 1px solid #dddddd;
          font-size: 1.1429rem;
          color: #333333;
          line-height: 20px;
        }
      }
    }
  }

  .content-list {
    width: 100%;
    padding: 70px 32px 20px 32px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    margin-top: 22px;
    position: relative;

    /deep/ .ant-spin-container {
      display: flex;
      flex-wrap: wrap;
      min-height: 300px;

      > .medium-card {
        margin-right: 29px;
        margin-bottom: 29px;

        &:nth-child(4n + 1) {
          margin-right: 0;
        }
      }
    }

    &.empty {
      /deep/ .ant-spin-container {
        justify-content: center;
        align-items: center;
      }
    }

    .item-action {
      position: fixed;
      width: 100%;
      min-width: 1200px;
      bottom: 0;
      left: 0;
      height: 114px;
      background: #f2f9ff;
      margin: 0;

      .item-action-inside {
        display: flex;
        width: 1200px;
        justify-content: space-evenly;
        align-items: center;
        height: 114px;
        margin: 0 auto;

        .number {
          div:first-child {
            width: 190px;
            height: 23px;
            font-size: 1.2857rem;
            font-family: dtc-bold;
            color: #2898ff;
            line-height: 23px;
          }

          div:last-child {
            width: 190px;
            height: 20px;
            font-size: 1.1429rem;
            color: #2898ff;
            line-height: 20px;
            margin-top: 16px;
            text-align: center;
          }
        }

        .status {
          position: relative;

          span {
            font-size: 1.1429rem;
            color: #333333;
            line-height: 30px;
            padding-right: 12px;
          }

          .ant-select {
            width: 250px;
          }
        }
      }

      .item-action-approve {
        user-select: none;
        text-align: center;
        width: 216px;
        height: 2.8571rem;
        background: #2898ff;
        border-radius: 6px;
        font-size: 1.1429rem;
        color: #ffffff;
        line-height: 2.8571rem;
        cursor: pointer;
      }
    }

    // .auto-accept {
    //   .question-zoom {
    //     transform: scale(0.8);
    //   }

    //   display: flex;
    //   align-items: center;
    //   align-self: flex-start;
    //   justify-self: flex-start;

    //   >span {
    //     margin-right: 10px;
    //     display: inline-block;
    //   }
    // }

    .list-prefix {
      height: 22px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: absolute;
      right: 0;
      top: -44px;

      .total-size {
        color: #ccc;
      }

      .select-all {
        user-select: none;
        color: rgba(40, 152, 255, 1);
        cursor: pointer;
      }
    }
  }
}

.tooltips-auto {
  padding: 3px;
}

.batch-update-modal {
  /deep/ .update-info {
    padding-bottom: 20px;

    > div {
      + div {
        margin-top: 4px;
      }

      font-size: 1rem;

      &.font-bold {
        font-size: 1.1429rem;
        margin-top: 20px;
      }

      &:first-child {
        margin-top: 0 !important;
      }
    }
  }
}
</style>
