<template>
  <div class="brands">
    <span v-if="!path">{{ collection }}</span>
    <router-link
      class="_link"
      v-else
      :to="{ path: path, query: { ...$route.query } }"
      >{{ collection }}</router-link
    >
    <img src="@/assets/brands-right.png" alt class="icon" />
    <span class="subset">{{ subset }}</span>
  </div>
</template>

<script>
export default {
  name: "brands",
  props: {
    collection: {
      type: String,
    },
    subset: {
      type: String,
    },
    path: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="less" scoped>
.brands {
  margin: 10px 0 16px;
  .icon {
    margin: 0px 5px;
    width: 16px;
    height: 16px;
    vertical-align: sub;
  }
  .subset {
    color: #333333;
  }
  span {
    font-size: 1rem;
    color: #999;
    line-height: 20px;
  }
  ._link {
    font-size: 1rem;
    color: #999;
    line-height: 20px;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
