<template>
  <div
    class="campgrid"
    :style="{ opacity: item.show_status === 'ended' ? '0.6' : 'unset' }"
  >
    <div class="campgrid-icon">
      <img :src="item.cover_image" />
      <span
        class="campgrid-icon-tips"
        :class="{
          avaliable: formatStatusText(item.show_status),
          expired: item.show_status === 'expired',
          pending: item.show_status === 'pending',
          declined: item.show_status === 'declined',
          approved: item.show_status === 'approved',
          cancelled: item.show_status === 'cancelled',
          ended: item.show_status === 'ended',
        }"
        >{{ item.show_status_text }}</span
      >
    </div>
    <ul class="campgrid-detail">
      <li>
        <span class="detail-title _ellipsis">Campaign Name</span>
        <a-tooltip placement="top">
          <template slot="title" v-if="item.campaign_name.length > 24">
            <span>{{ item.campaign_name }}</span>
          </template>
          <span class="detail-text">{{ item.campaign_name }}</span>
        </a-tooltip>
      </li>
      <li>
        <span class="detail-title _ellipsis" style="width:40%;"
          >Payout Type</span
        >
        <TypeItem :item="item" />
      </li>
      <li>
        <span class="detail-title">Platform</span>
        <span class="detail-platform">
          <img
            v-for="(item, index) in item.plat_list"
            :key="`plat-list-${index}`"
            :src="item.icon"
          />
        </span>
      </li>
      <li>
        <span class="detail-title">Task Format</span>
        <span class="detail-format">
          <a-tooltip
            v-for="(fot, index) in item.format_list"
            :key="`format-list-${index}`"
            :title="fot.full_name"
          >
            <img :src="fot.icon" />
          </a-tooltip>
        </span>
      </li>
      <li>
        <span class="detail-title">Incentive</span>
        <span class="detail-money"
          >{{ item.currency_sign }}{{ item.incentives }}</span
        >
      </li>
    </ul>
    <div class="campgrid-action">
      <a-button class="active-btn" type="primary" @click="gotoDetail">
        Apply Now
      </a-button>
      <a-button class="negative-btn" @click="gotoDetail">
        View Details
      </a-button>
    </div>
  </div>
</template>

<script>
const defaultLogo = require("@/assets/default.png");
import Modal from "@/components/Modal.vue";
import TypeItem from "./TypeItem.vue";

import _ from "lodash";
export default {
  components: { Modal, TypeItem },
  props: {
    item: {
      type: Object,
      default: () => null,
    },
    calcPage: {
      type: Number,
      default: 1,
    },
  },
  computed: {},
  data() {
    return {
      countryMap: {},
      statByLocal: "today",
      errorPlaceHolder: null,
      isShowUpdate: false,
      newStatus: "",
      isLoadingModal: false,
    };
  },
  watch: {},
  created() {},
  methods: {
    gotoDetail() {
      this.$router.push({
        name: this.formatStatusText(this.item.show_status)
          ? "campaign_detail"
          : "campaign_detail_preview",
        params: {
          id: this.item.id,
        },
        query: { page: this.calcPage },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.campgrid {
  background: #ffffff;
  box-shadow: 0px 0px 12px 0px rgba(102, 118, 186, 0.1);
  border-radius: 1.1429rem;
  padding: 0.5714rem;
  transition: all 0.3s;
  &:hover {
    z-index: 1000;
    transform: translateY(-6px);
    box-shadow: 0px 0px 12px 0px rgba(102, 118, 186, 0.2);
  }
  &-icon {
    position: relative;
    > img {
      width: 100%;
      height: 13.7143rem;
      object-fit: cover;
      border-radius: 1.1429rem;
    }
    &-tips {
      position: absolute;
      top: 0;
      right: 0;
      width: 5.5rem;
      height: 1.5714rem;
      border-radius: 0px 14px 0px 8px;
      text-align: center;
      color: #ffffff;
      line-height: 1.5714rem;
      background: #aaaaaa;
      &.avaliable {
        background: #2ad57b;
      }
      &.expired {
        background: #aaaaaa;
      }
      &.pending {
        background: #28acff;
      }
      &.declined {
        background: #ff5252;
      }
      &.approved {
        background: #ffc55a;
      }
      &.cancelled {
        background: #e6e6e6;
      }
      &.ended {
        background: #000;
      }
    }
  }
  &-detail {
    flex-shrink: 0;
    padding: 0px 0.2857rem;
    margin-top: 0.4286rem;
    > li {
      display: flex;
      height: 1.7857rem;
      align-items: center;
    }
    .detail-title {
      color: #666666;
      width: 40%;
      font-size: 0.8571rem;
    }
    .detail-text {
      font-size: 1rem;
      color: #333333;
      width: 60%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .detail-platform {
      display: flex;
      align-items: center;
      > img {
        width: 1.4286rem;
        height: 1.4286rem;
        object-fit: contain;
        margin-right: 0.4286rem;
      }
    }
    .detail-format {
      display: flex;
      align-items: center;
      > img {
        // width: 1.5rem;
        // height: 1.5rem;
        object-fit: contain;
        margin-right: 0.4286rem;
      }
    }
    .detail-money {
      color: #3762d6;
      font-family: "prefer-bold";
    }
  }
  &-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.7143rem;
    > button {
      margin-bottom: 1.4286rem;
      width: 40%;
    }
    .active-btn {
      background-color: #3762d6;
      border-color: #3762d6;
      &:hover {
        opacity: 0.85;
      }
    }
    .negative-btn {
      border: 1px solid #3762d6;
      color: #3762d6;
      &:hover {
        opacity: 0.7;
      }
    }
    .action-text {
      color: #999999;
      position: absolute;
      bottom: 0;
    }
  }
}
</style>
