<template>
  <div class="campaign">
    <Header collection="Reporting"></Header>
    <!-- <Brands collection="Campaigns" subset="Campaigns Name"></Brands> -->
    <div class="campaign-search">
      <Brands collection="Transaction Details" subset="Download Queue"></Brands>
    </div>
    <a-spin :class="{ empty: dataLists.length == 0 }" :spinning="isLoading">
      <div class="comm-list">
        <a-table
          class="comtable"
          :columns="columns"
          :data-source="dataLists"
          :rowKey="(record, index) => record.m_id"
          :loading="isLoading"
          :pagination="false"
        >
          <div class="comm-platforms" slot="slot-brand" slot-scope="text, row">
            <img :src="text" />
            <span>{{ row.sitename }}</span>
          </div>
          <div class="comm-status" slot="status" slot-scope="text">
            <span class="comm-status-joined" v-show="text === 'Joined'">{{
              text
            }}</span>
            <span
              class="comm-status-avaliable"
              v-show="formatStatusText(text)"
              >{{ text }}</span
            >
            <span class="comm-status-pending" v-show="text === 'Pending'">{{
              text
            }}</span>
            <span class="comm-status-declined" v-show="text === 'Declined'">{{
              text
            }}</span>
          </div>
          <div class="comm-actions" slot="actions" slot-scope="text, row">
            <!-- <a-button type="primary" @click="singleJoin(row)">Join</a-button> -->
            <a v-if="row.down_url" :href="row.down_url" target="_blank"
              >Download</a
            >
            <span class="comm-actions-pre" v-else>Preparing</span>
          </div>
        </a-table>
        <div class="footer-pagination">
          <a-pagination
            v-if="totalCount"
            :defaultPageSize="8"
            v-model="currentPage"
            :total="totalCount"
            show-less-items
            @change="paginationChange"
          />
        </div>
      </div>
    </a-spin>
  </div>
</template>
<script>
import SelectButton from "@/components/public/SelectButton.vue";
import Header from "@/components/public/Header.vue";
import axios from "@/utils/request";
import apis from "@/api/const";
import Brands from "@/components/Brands.vue";

export default {
  components: {
    Header,
    SelectButton,
    Brands,
  },
  data() {
    let columns = [
      {
        title: "Id",
        dataIndex: "id",
        width: 150,
        align: "center",
        // scopedSlots: { customRender: "slot-brand" },
      },
      {
        title: "Created time",
        dataIndex: "created_at",
        align: "center",
      },
      {
        title: "Updated time",
        dataIndex: "updated_at",
        align: "center",
      },
      {
        title: "Action",
        dataIndex: "",
        align: "center",
        width: 152,
        scopedSlots: { customRender: "actions" },
      },
    ];
    return {
      columns,
      dataLists: [],
      isLoading: false,
      totalCount: 0,
      currentPage: 1,
      page_size: 10,
      searchKey: "",
      isShowSearch: false,
      tableType: "grid",
      categoryList: [],
      countryList: [],
      platformList: [],
      formatList: [],
      payoutTypeList: [],
      statuList: [],
      sortBy: "",
      selectedRowKeys: [], // Check here to configure the default column
      btnLoading: false,
      selectedSite:
        this.$store.state.siteInfo.length > 0
          ? this.$store.state.siteInfo[0]
          : {},
    };
  },
  created() {},
  mounted() {
    this.getDataList();
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
    showKeysLine() {
      const list = [
        ...this.categoryList,
        ...this.countryList,
        ...this.platformList,
        ...this.formatList,
        ...this.payoutTypeList,
      ];
      return list.length > 0 || this.sortBy ? true : false;
    },
  },
  methods: {
    goToDetail(row) {
      this.$router.push({
        name: "brand_detail",
        params: {
          id: row.m_id, //+ "-" + this.selectedSite.id,
        },
      });
    },
    // 获取列表
    getDataList() {
      this.isLoading = true;
      axios.get(apis.reporting.cps_download_list, {}).then((res) => {
        this.isLoading = false;
        if (res.code === "0200") {
          this.dataLists = res.payload;
          //   this.totalCount = parseInt(res.payload.pagination.total);
        } else {
          this.$message.error(res.msg || "");
        }
      });
    },
    batchJoin() {
      this.btnLoading = true;
      //   const joinData = {
      //     site_id: this.selectedSite.id,
      //     m_id: this.selectedRowKeys.join(","),
      //   };
      const joinData = new FormData();
      joinData.append("site_id", this.selectedSite.id);
      joinData.append("m_id", this.selectedRowKeys.join(","));
      axios.post(apis.brands.join_site_bacth, joinData).then((res) => {
        this.btnLoading = false;
        if (res.code == "0200") {
          this.$message.success("Join Success.");
        } else {
          this.$message.error(res.msg);
        }
        this.isLoading = false;
      });
    },
    singleJoin(row) {
      //   const joinData = {
      //     site_id: this.selectedSite.id,
      //     m_id: this.selectedRowKeys.join(","),
      //   };
      const joinData = new FormData();
      joinData.append("site_id", this.selectedSite.id);
      joinData.append("m_id", row.m_id);
      axios.post(apis.brands.join_site, joinData).then((res) => {
        if (res.code == "0200") {
          this.$message.success("Join Success.");
        } else {
          this.$message.error(res.msg);
        }
        this.isLoading = false;
      });
    },
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    orderBySelected() {},
    siteSelected(value) {
      this.selectedSite = _.find(this.$store.state.siteInfo, (site) => {
        return site.id === value;
      });
    },
    resetSelect() {
      this.categoryList = [];
      this.countryList = [];
      this.platformList = [];
      this.formatList = [];
      this.payoutTypeList = [];
      this.statuList = [];
      this.sortBy = "";
    },
    switchTable(val) {
      this.tableType = val;
    },
    toSearch() {
      console.log("key is :", this.searchKey);
    },
    showSearchKey() {
      this.isShowSearch = true;
    },
    closeCountry(val) {
      const index = _.findIndex(this.countryList, (item) => {
        return item.value === val.value;
      });
      this.countryList.splice(index, 1);
    },
    closeCategory(val) {
      //   console.log("e:", e.target.value);
      const index = _.findIndex(this.categoryList, (item) => {
        return item.value === val.value;
      });
      this.categoryList.splice(index, 1);
    },
    // 切换下一页
    paginationChange(page, pageSize) {
      this.currentPage = page;
      this.getDataList();
    },
  },
};
</script>

<style lang="less" scoped>
.campaign {
  &-search {
    background: #ffffff;
    border-radius: 16px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    margin-top: 12px;
    .search-left {
      width: 800px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .search-reset {
        font-size: 1rem;
        color: #666666;
        cursor: pointer;
      }
      .search-keys {
        margin-top: 10px;
        /deep/ .ant-tag {
          font-size: 0.8571rem;
          color: #3762d6;
          background: #f6f9ff;
          border-radius: 11px;
          border: none;
          > i {
            color: #3762d6;
          }
        }
      }
      .campaign-orderby {
        width: 150px;
      }
      .search-btns {
        div {
          margin-right: 12px;
        }
      }
    }
    .search-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .search-input {
        display: flex;
        align-items: center;
        height: 26px;
        border-bottom: 1px solid #333;
        margin-right: 20px;
        padding-bottom: 2px;
        img {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
        .img-close {
          width: 10px;
          height: 10px;
        }
        input {
          border: none;
          outline: none;
          width: 260px;
          padding-left: 5px;
        }
      }
    }
    .search-btn {
      margin-right: 20px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .comm {
    &-selected {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 16px 16px;
      &-text {
        margin-right: 16px;
      }
    }
    &-actions {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      > a {
        // width: 100px;
        color: #3762d6;
      }
      &-pre {
        background: #ffc55a;
        border-radius: 4px;
        color: #fff;
        padding: 1px 6px;
      }
      //   .btn-link {
      //     margin-top: 12px;
      //   }
    }
    &-status {
      &-joined {
        background: #28acff;
      }
      &-avaliable {
        background: #15dcb3;
      }
      &-pending {
        background: #ffc55a;
      }
      &-declined {
        background: #f64a47;
      }
      span {
        padding: 0.2857rem 0.5714rem;
        border-radius: 4px;
        font-size: 1rem;
        color: #fff;
      }
    }
    &-platforms {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      img {
        width: 8.5714rem;
        height: 68px;
        object-fit: cover;
        border-radius: 8px;
      }
      span {
        font-size: 0.8571rem;
        margin-top: 8px;
      }
    }
  }
  &-rlist {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  &-glist {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  /deep/ .ant-select-selection {
    height: 2.8571rem;
  }
}
</style>
