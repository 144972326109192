import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment-timezone";
import axios from "./utils/request";
import "./utils/AntDesign";
import "./theme.less";
import "./utils/mixin";
import "./utils/global-component";
import VueCookies from "vue-cookies";
import VueClipboard from "vue-clipboard2";
import StringView from "@/components/StringView.vue";
import VueHighlightJS from "vue-highlightjs";
import "highlight.js/styles/lightfair.css";

Vue.use(VueHighlightJS);
Vue.component("StringView", StringView);
Vue.use(VueCookies);
Vue.use(VueClipboard);
var dayjs = require("dayjs");

moment.updateLocale("en", {
  longDateFormat: {
    // YMD: 'MM/DD/YYYY',
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "MM/DD/YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY HH:mm",
    LLLL: "dddd D MMMM YYYY HH:mm",
  },
});

window.moment = moment;
Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV == "development";
Vue.prototype.dayjs = dayjs;

new Promise((resolve, reject) => {
  var authtoken = Vue.$cookies.get("throw_www");

  if (authtoken) {
    store.commit("setToken", authtoken);
    axios
      .get("/api_admin.php?a=user/logininfo")
      .then((res) => {
        if (res.code === 0) {
          store.commit("setToken", res.data.auth_token);
          store.commit("setAccountInfo", res.data);
          axios.get("/api_admin.php?a=user/userPermissions").then((res) => {
            if (res.code === 0) {
              store.commit("setAuthority", res.data);
            }
          });
          Vue.$cookies.remove("throw_www");
        }
        resolve();
      })
      .catch((e) => {
        resolve();
      });
  } else {
    resolve();
  }
})
  .then(() => {
    window.$vue = new Vue({
      router,
      data() {
        return {
          displayModals: [],
        };
      },
      store,
      render: (h) => h(App),
    }).$mount("#app");
  })
  .catch((e) => {
    console.log(e);
  });
