<template>
  <div>
    <div class="logo-header">
      <a href="https://www.linkprefer.com/" target="_blank"
        ><img src="@/assets/login/logo.svg" alt
      /></a>
    </div>
    <div class="sign-box">
      <div class="login-back">
        <span @click="back">
          <a-icon type="left" />
        </span>
      </div>
      <div class="form">
        <div class="wrap">
          <h1 class="login-box__title login-font">
            {{ form.id ? "Edit" : "Add" }} Website
          </h1>
          <div class="form-list">
            <a-form-model
              :model="form"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
              :rules="rules"
              ref="ruleForm"
            >
              <a-form-model-item label="Website name" prop="title">
                <a-input v-model="form.title" placeholder=""></a-input>
              </a-form-model-item>
              <a-form-model-item label="Website URL" prop="host">
                <a-input v-model="form.host" placeholder=""></a-input>
              </a-form-model-item>
              <a-form-model-item label="Category" prop="c_id">
                <a-select
                  mode="multiple"
                  v-model="form.c_id"
                  placeholder="Please Select..."
                  showArrow
                  :getPopupContainer="
                    (triggerNode) => {
                      return triggerNode.parentNode || document.body;
                    }
                  "
                >
                  <a-select-option
                    v-for="i in cataList"
                    :key="i.id"
                    :value="i.id"
                  >
                    {{ i.title_en }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="Brief Introduction" prop="brief">
                <a-textarea
                  v-model="form.brief"
                  placeholder="Type"
                  :auto-size="{ minRows: 5, maxRows: 10 }"
                />
              </a-form-model-item>
              <a-form-model-item
                :wrapper-col="{ span: 18, offset: 6 }"
                class="form-btn"
              >
                <a-button style="margin-right: 20px;" @click="back" class="b1">
                  Cancel
                </a-button>
                <a-button type="primary" @click="onSubmit" class="b2">
                  Save
                </a-button>
              </a-form-model-item>
            </a-form-model>
          </div>
        </div>
      </div>
      <div class="pic">
        <img src="@/assets/login/sign-right.png" alt />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/utils/request";
import APIS from "@/api/const";

export default {
  name: "login",
  data() {
    let validateHost = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Website URL is required"));
      } else if (
        !/^(https?:)\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g.test(
          value
        )
      ) {
        callback(
          new Error("Please enter a valid URL, with http:// or https://")
        );
      } else {
        callback();
      }
    };
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      cataList: [],
      form: {
        id: "",
        site_type: 1, // 0 social 1 website 2 app
        title: "",
        host: "",
        c_id: [],
        brief: "",
      },
      rules: {
        title: [
          {
            required: true,
            message: "Website name is required",
            trigger: ["blur", "change"],
          },
        ],
        c_id: [
          {
            required: true,
            message: "Category is required",
            trigger: ["blur", "change"],
          },
        ],
        host: [
          {
            required: true,
            validator: validateHost,
            trigger: ["blur", "change"],
          },
        ],
        brief: [
          {
            required: true,
            message: "Brief Introduction is required",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  mounted() {
    this.form.id = this.$route.query.id || "";
    if (this.form.id) {
      this.getFormDetail();
    }
    this.getCommonList();
  },
  methods: {
    back() {
      this.$router.push({
        path: "/channel-add",
      });
    },
    async getFormDetail() {
      const resp = await axios.get(APIS.channel.site_detail, {
        params: {
          id: this.form.id,
        },
      });
      if (resp.code === "0200") {
        const { id, host, c_id, title, content } = resp.payload;
        this.form = {
          id,
          site_type: 1, // 0 social 1 website 2 app
          title,
          host,
          c_id: c_id.map((item) => item.id),
          brief: content,
        };
      } else {
        this.$message.error(resp.msg);
      }
    },
    async getCommonList() {
      const resp = await axios.get(APIS.channel.cata_list);
      const { site_category } = resp.payload;
      this.cataList = site_category;
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$refs.ruleForm.validate(async (valid) => {
            if (valid) {
              const resp = await axios.post(APIS.channel.site_create, {
                formData: true,
                ...Object.assign(
                  {
                    ...this.form,
                  },
                  {
                    c_id: this.form.c_id.join(","),
                  }
                ),
              });
              if (resp.code === "0200") {
                this.$message.success(resp.msg);
                this.$router.push({
                  path: "/channel-add",
                });
              } else {
                this.$message.error(resp.msg);
              }
            } else {
              console.log("error submit!!");
              return false;
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
