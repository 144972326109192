<template>
  <div class="list">
    <div class="content">
      <div class="content-title">
        <div class="items">
          <div class="item">
            <span>Country</span>
            <PSelector
              size="large"
              v-model="params.country_id"
              :options="
                countryList.map((c) => ({
                  value: c.id,
                  label: c.name_en,
                  code: c.code,
                }))
              "
              multiple
              clearAble
            ></PSelector>
          </div>
          <div class="item">
            <span>Category</span>
            <PSelector
              size="large"
              v-model="params.category_id"
              :options="
                categories.map((c) => ({
                  value: c.id,
                  label: c.en_title,
                }))
              "
              multiple
              clearAble
            ></PSelector>
          </div>
          <div class="item">
            <span>Promotional Method</span>
            <PSelector
              size="large"
              v-model="params.c_id"
              :options="
                promotion_c_id_types.map((c) => ({
                  value: c.id,
                  label: c.en_title,
                }))
              "
              multiple
              clearAble
            ></PSelector>
          </div>
          <div class="item">
            <span>Search Keywords</span>
            <a-input v-model="params.keyword" />
          </div>
        </div>
        <div class="search-reset">
          <button @click="search">Search</button>
          <button @click="reset">Reset</button>
        </div>
      </div>

      <a-spin
        class="content-list"
        :class="{ empty: mediumLists.length == 0 }"
        :spinning="isLoading"
      >
        <div class="stats-by-status">
          <!-- New/Wish List/Invited/Relationship -->
          <div
            class="stats-by-status-item"
            @click="changeStatus('new')"
            :class="{ active: params.status == 'new' }"
          >
            New
          </div>
          <div
            class="stats-by-status-item"
            @click="changeStatus('wish')"
            :class="{ active: params.status == 'wish' }"
          >
            Following{{ ` (${statsByStatus.wish})` }}
          </div>
          <div
            class="stats-by-status-item"
            @click="changeStatus('invited')"
            :class="{ active: params.status == 'invited' }"
          >
            Invited{{ ` (${statsByStatus.invited})` }}
          </div>
          <div
            class="stats-by-status-item"
            @click="changeStatus('relationship')"
            :class="{ active: params.status == 'relationship' }"
          >
            Relationship{{ ` (${statsByStatus.relationship})` }}
          </div>
        </div>
        <!-- 列表头部,用div实现,包含Images/Category/Media Type/Description/Actions  -->
        <div class="list-header">
          <div class="list-header-item" style="width:410px">
            <span></span>
          </div>
          <div class="list-header-item" style="width:170px">
            <span>Category</span>
          </div>
          <div class="list-header-item" style="width:170px">
            <span>Promotional Method</span>
          </div>
          <div class="list-header-item" style="width:236px">
            <span>Description</span>
          </div>
          <div class="list-header-item" style="width:150px">
            <span> </span>
          </div>
        </div>
        <div class="medium-list">
          <MediumListItem
            v-for="item in mediumLists"
            :key="item.medium_id"
            :item="item"
            :type="params.status"
            :isDisableAction="isDisableAction"
            @invite="
              (mediumId, medium, evt) => {
                if (isDisableAction > 0) {
                  actionDisabled(isDisableAction);
                  return;
                }
                inviteModal = {
                  visible: true,
                  mediumId,
                  medium,
                  evt,
                };
              }
            "
            @wished="statsByStatus.wish = parseInt(statsByStatus.wish) + 1"
            @actionDisabled="actionDisabled"
          ></MediumListItem>
        </div>
        <div class="footer-pagination" v-if="totalSize > 0 && !isLoading">
          <a-pagination
            v-model="currentPage"
            :total="totalSize"
            :defaultPageSize="10"
            show-less-items
            @change="paginationChange"
          />
        </div>
        <div class="clearboth"></div>
      </a-spin>
    </div>
    <DiscoverInviteModal
      :mediumId="inviteModal.mediumId"
      :medium="inviteModal.medium"
      :evt="inviteModal.evt"
      @close="closeInviteModal"
      @invited="onInvited"
      @actionDisabled="actionDisabled"
    />
    <Modal
      :isVisible="modal.show"
      width="341px"
      submitText="OK"
      @close-popup="modal.show = false"
      :showTitle="false"
      :closeAble="false"
      class="modal"
      @submit="modal.show = false"
    >
      <template #content>
        <img
          src="@/assets/icon-modal-success.svg"
          alt=""
          style="margin:0 auto;display:block"
        />
        <div
          class="text"
          style="text-align:center;height:70px;padding-left:50px;padding-right:50px;padding-top:25px;font-size:16px;"
        >
          {{ modal.tips }}
        </div>
      </template>
    </Modal>
    <Modal
      :isVisible="showDiscoveryDisabled"
      width="341px"
      :showBtn="false"
      @close-popup="showDiscoveryDisabled = false"
      :showTitle="false"
      class="modal"
    >
      <template #content>
        <img
          src="@/assets/icon-modal-warning.svg"
          alt=""
          style="margin:20px auto 0;display:block"
        />
        <div
          class="text"
          style="text-align:center;height:100px;padding-left:10px;padding-right:10px;padding-top:25px;font-size:16px;"
        >
          <p v-if="showDiscoveryDisabledType == 'lowfunds'">
            You have a low amount for more than 30 days,
            <a @click="goto('/account/balance_details?show_deposit=1')"
              >deposit money</a
            >
            to continue recruiting publishers.
          </p>
          <p v-else>
            <a @click="goto('/account/balance_details?show_deposit=1')"
              >Make the intital $100 deposit</a
            >
            into your account to recruit publishers
          </p>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import axios from "@/utils/request";
import Modal from "../../../components/Modal.vue";
import MediumListItem from "./MediumListItem.vue";
import DiscoverInviteModal from "./DiscoverInviteModal.vue";

export default {
  name: "discovery_list",
  components: {
    Modal,
    MediumListItem,
    DiscoverInviteModal,
  },
  data() {
    return {
      modal: {
        show: false,
        tips: "Invitation email sent",
      },
      statsByStatus: {
        new: 0,
        wish: 0,
        invited: 0,
        relationship: 0,
      },
      countryList: [],
      countryMap: {},
      mediumLists: [],
      isLoading: false,
      currentPage: 1,
      page_size: 10,
      totalSize: 0,
      totalPage: 0,
      showDiscoveryDisabled: false,
      showDiscoveryDisabledType: "lowfunds",
      isDisableAction: 0,
      params: {
        // adopt 已签约
        // refuse 已拒绝
        // verifing 待审核
        status: "new",
        country_id: [],
        keyword: null,
        c_id: [],
        category_id: [],
      },
      inviteModal: {
        visible: false,
        mediumId: null,
        medium: null,
        evt: "invite",
      },
    };
  },
  mounted() {
    this._getCoutry();
    this._getMediumLists();
  },
  computed: {
    promotion_c_id_types() {
      return this.$store.state.commonConfig.promotion_c_id_types;
    },
    categories() {
      return this.$store.state.commonConfig.categories;
    },
  },
  methods: {
    actionDisabled(type) {
      if (type == 10804) {
        this.$router.push({
          name: "program-settings",
          params: {
            upgrade: 1,
          },
        });
        return;
      }
      this.showDiscoveryDisabledType = type;
      this.showDiscoveryDisabled = true;
    },
    closeInviteModal() {
      this.inviteModal = {
        visible: false,
        mediumId: null,
        medium: null,
        evt: "invite",
      };
    },
    onInvited(mediumId) {
      this.modal = {
        show: true,
        tips:
          this.inviteModal.evt == "invite"
            ? "Invitation email sent"
            : "Successful follow up",
      };
      this._getMediumLists();
      this.closeInviteModal();
    },
    changeStatus(status) {
      this.params.status = status;
      this.currentPage = 1;
      this._getMediumLists();
    },
    _getCoutry() {
      this.countryList = this.$store.state.commonConfig.countries.map(
        (item) => {
          this.countryMap[item.id] = item;
          return item;
        }
      );
    },
    goto(path) {
      this.showDiscoveryDisabled = false;
      this.$nextTick(() => {
        this.$router.push(path);
      });
    },
    _getMediumLists() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.mediumLists = [];
      axios
        .get("/api_admin.php?a=discovery/search", {
          params: {
            ...this.params,
            country_id: this.params.country_id.join(","),
            c_id: this.params.c_id.join(","),
            category_id: this.params.category_id.join(","),
            page_num: this.currentPage,
            page_size: 10,
          },
        })
        .then((res) => {
          this.isLoading = false;
          if (res.code === 0) {
            this.isDisableAction = res.data.isDisableAction;
            res.data.data.forEach((item) => {
              let c_ids = item.c_id ? item.c_id.split(",") : [];
              if (item.main_c_id && c_ids.indexOf(item.main_c_id) == -1) {
                c_ids.unshift(item.main_c_id);
              }
              c_ids = c_ids.filter((c_id) => c_id > 0);
              item.c_ids = c_ids;
              item.c_id_types = c_ids
                .map((c_id) => {
                  return this.promotion_c_id_types.find((c) => {
                    return c.id == c_id;
                  });
                })
                .filter((c) => {
                  return !!c;
                });
              let category_ids = item.c_category_id
                ? item.c_category_id.split(",")
                : [];
              category_ids = category_ids.filter((c_id) => c_id > 0);
              item.category_ids = category_ids;
              item.category_names = category_ids
                .map((c_id) => {
                  return this.categories.find((c) => {
                    return c.id == c_id;
                  });
                })
                .filter((c) => {
                  return !!c;
                });
              this.mediumLists.push(item);
            });
            this.statsByStatus.relationship = res.data.stats.relationship || 0;
            this.statsByStatus.invited = res.data.stats.invite || 0;
            this.statsByStatus.wish = res.data.stats.wish || 0;

            this.dataLists = res.data.data;
            this.totalSize = parseInt(res.data.total_size);
            this.totalPage = parseInt(res.data.total_page);
            document.documentElement.scrollTop = 0;
          } else {
            this.$message.error(
              res.msg || "Sorry, server is busy. please try later."
            );
          }
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
    },
    reset() {
      this.params = {
        status: this.params.status,
        country_id: [],
        keyword: null,
        c_id: [],
        category_id: [],
      };
      this.search();
    },
    search() {
      this.currentPage = 1;
      this._getMediumLists();
    },
    paginationChange(page, pageSize) {
      this.currentPage = page;
      this._getMediumLists();
    },
    handleChangeCountry(val) {
      this.params.country_id = val;
    },
    checkedChange(site_id) {
      if (this.checkedList.includes(site_id)) {
        this.checkedList.splice(this.checkedList.indexOf(site_id), 1);
      } else {
        this.checkedList.push(site_id);
      }
    },
    selectAll() {
      if (this.mediumLists.length == this.checkedList.length) {
        this.checkedList = [];
        return;
      }
      this.mediumLists.forEach((item) => {
        if (!this.checkedList.includes(item.site_id)) {
          this.checkedList.push(item.site_id);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  margin-bottom: 100px;

  .content-title {
    width: 100%;
    padding: 20px 44px 30px 30px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;

    .items {
      display: flex;

      .item {
        width: 262px;
        margin-right: 26px;

        &:last-child {
          margin-right: 0px;
        }

        span {
          display: block;
          font-size: 1rem;
          font-family: "dtc-bold";
          color: #333333;
          line-height: 30px;
          margin-bottom: 2px;
        }

        input {
          width: 100%;
          height: 2.8571rem;
          background: #ffffff;
          border-radius: 6px;
          border: 1px solid #e6e6e6;
        }

        .select {
          width: 100%;
          height: 2.8571rem;

          /deep/ .ant-select-selection--single {
            height: 2.8571rem;
            border-radius: 6px;
            background: #fafafa;
          }

          /deep/ .ant-select-selection__rendered {
            line-height: 2.8571rem;
          }

          .icon-down {
            color: #333333;
            font-size: 1rem;
          }
        }

        .date {
          width: 100%;
          height: 2.8571rem;

          /deep/ .ant-input {
            height: 2.8571rem;
            border-radius: 25px;
            background: #fafafa;
          }
        }
      }
    }

    .search-reset {
      margin-top: 20px;
      text-align: right;

      button {
        padding: 9px 32px;
        border-radius: 6px;
        cursor: pointer;
        border: none;

        &:first-child {
          background: #2898ff;
          font-size: 1.1429rem;
          color: #ffffff;
          line-height: 20px;
          margin-right: 20px;
        }

        &:last-child {
          border: 1px solid #dddddd;
          font-size: 1.1429rem;
          color: #333333;
          line-height: 20px;
        }
      }
    }
  }

  .content-list {
    width: 100%;
    padding: 10px 32px 20px 32px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    margin-top: 22px;
    position: relative;

    /deep/ .ant-spin-container {
      display: flex;
      flex-direction: column;
      min-height: 300px;
    }

    &.empty {
      /deep/ .ant-spin-container {
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    .stats-by-status {
      height: 60px;
      border-bottom: 2px solid #eeeeeeff;
      width: 100%;
      padding: 0 12px;
      box-sizing: border-box;
      z-index: 1;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 20px;

      .stats-by-status-item {
        font-size: 1rem;
        color: #333333;
        line-height: 58px;
        height: 58px;
        margin-right: 25px;
        font-size: 1.1429rem;
        padding: 0 13px;
        border-bottom: 2px solid #fff;
        cursor: pointer;
        user-select: none;

        &.active,
        &:hover {
          color: #2898ff;
          border-bottom: 2px solid #2898ff;
        }
      }
    }

    .list-header {
      width: 1136px;
      height: 51px;
      background: #f9f9f9;
      display: flex;
      justify-content: flex-start;

      .list-header-item {
        line-height: 51px;
        height: 51px;
        text-align: center;
        font-size: 1.1429rem;
        font-family: dtc-bold;
      }
    }
  }
}

.modal {
  /deep/ .ant-btn-primary {
    min-width: 173px;
  }
}
</style>
