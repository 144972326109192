<template>
  <div class="Recruitment">
    <NavHeader v-if="$route.name !== 'tools_Recruitment'"></NavHeader>
    <div class="content">
      <div class="head_imgbox">
        <img src="@/assets/logo-v2.svg" alt />
        <img :src="data.company_logo" alt="" />
      </div>
      <div class="item_info">
        <a-row class="list_title"
          >Welcome to {{ data.company_name }}'s affiliate program!
          <a href="https://app.partnerboost.com/publisher?mod=reg">APPLY NOW</a>
        </a-row>
        <a-row class="list_text"
          >If you already have an account,
          <a href="https://app.partnerboost.com/publisher?mod=login">login </a
          >here.</a-row
        >
        <a-row class="list_text"
          >Do you have a website or blog related to {{ data.category }}? If so,
          join the {{ data.company_name }} affiliate program and earn
          {{ data.commission_rule }} to every qualified buyer you send our way.
          We have worked hard to optimize our site for high conversion, which
          means more income in your pocket!</a-row
        >
        <a-row class="list_title" v-if="data.pics">{{ data.brief }}</a-row>
        <a-row
          type="flex"
          v-if="data.pics"
          style="justify-content:space-between; flex-wrap: wrap;"
        >
          <div class="imgBox" v-for="(it, index) in data.pics" :key="index">
            <img class="img_item" :src="it" alt="" />
          </div>
        </a-row>
        <a-row class="list_text"
          >We've partnered with PartnerBoost, the leader in affiliate marketing
          technology, to offer you a robust set of marketing tools free of
          charge, and the best customer support. PartnerBoost will help you
          maintain accurate campaign information and work more
          efficiently.</a-row
        >
        <a-row class="list_title"
          >What {{ data.company_name }} offers affiliates:</a-row
        >
        <div v-for="(it, index) in data.highlights" :key="index">
          <a-row class="list_text bulletpoint" v-if="it"> {{ it }}</a-row>
        </div>

        <div v-if="data.offers">
          <a-row
            class="list_title"
            v-if="!(data.offers.length == 1 && !data.offers[0])"
            >What {{ data.company_name }} offers customers:</a-row
          >
          <div v-for="(it, index) in data.offers" :key="index">
            <a-row class="list_text bulletpoint" v-if="it"> {{ it }}</a-row>
          </div>
        </div>
        <a-row class="list_title">Validations:</a-row>
        <a-row class="list_text"
          >All commissions will be valiated within a {{ data.tracking_gap }}-day
          period and {{ data.company_name }}reserves the right to decline
          commission on all returned goods. Publishers are only allowed to
          promote authorised codes that have been communicated by the
          {{ data.company_name }} or PartnerBoost, otherwise the commissions
          will be declined.</a-row
        >
        <a-row class="list_title">PPC:</a-row>
        <a-row class="list_text"
          >Please note {{ data.company_name }} will not pay commission to
          affiliates carrying out any unauthorised brand bidding and brand +
          product bidding for the duration the bidding takes place. Publishers
          are not permitted to bid on the brand or brand + product terms.</a-row
        >
        <a-row class="list_title">Contacts:</a-row>
        <a-row class="list_text"
          >We will do everything we can to help you make money!</a-row
        >
        <a-row class="list_text"
          >If you need assistance, please feel free to contact
          <span class="dataValue">{{ data.email }} </span>!</a-row
        >
      </div>
    </div>
    <a-modal v-model="isShowCopy" width="400px" title="" :footer="null">
      <div class="DeletedModel">
        <p>Your page is published successfully!</p>
      </div>
      <a-row justify="space-around" type="flex" class="model_but_box">
        <a-button @click="onCopy" type="primary"
          >Copy link to clipboard</a-button
        >
      </a-row>
    </a-modal>
    <nav-footer v-if="$route.name !== 'tools_Recruitment'"></nav-footer>
  </div>
</template>

<script>
import NavHeader from "../components/NavHeader.vue";
import axios from "@/utils/request.js";
import NavFooter from "../components/NavFooter.vue";
import CONFIG from "@/utils/config";
export default {
  name: "Recruitment",
  components: {
    NavHeader,
    NavFooter,
  },
  data() {
    return {
      url_flag: this.$route.path.substring(13),
      data: this.$route.query.createData
        ? JSON.parse(this.$route.query.createData)
        : {},
      isShowCopy: false,
      isPublish: sessionStorage.getItem("isPublish"),
    };
  },
  created() {},
  mounted() {
    if (JSON.stringify(this.data) == "{}") {
      this.getData();
    } else {
      this.data = {
        ...this.data,
        commission_rule:
          this.data.aff_ba_unit == "ORIC"
            ? this.data.currency.sign + this.data.aff_ba
            : this.data.aff_ba + "%",
      };
    }
  },
  methods: {
    onCopy() {
      this.$copyText(`${CONFIG.AFF_HOST}/brand/recruitment/` + this.url_flag)
        .then(() => {
          this.$message.success("Copy Success");
        })
        .catch(() => {
          this.$message.error("Copy Failed");
        });
    },
    getData() {
      axios
        .get("/api_admin.php?a=tools/recruitment_url", {
          params: {
            url_flag: this.url_flag,
          },
        })
        .then((res) => {
          if (res.code === 0) {
            this.data = {
              ...res.data,
              commission_rule:
                res.data.aff_ba_unit == "ORIC"
                  ? res.data.currency.sign + res.data.aff_ba
                  : res.data.aff_ba + "%",
            };
            if (this.isPublish) {
              this.isShowCopy = true;
              sessionStorage.setItem("isPublish", "");
              setTimeout(() => {
                this.isShowCopy = false;
              }, 3000);
            }
          } else {
            this.$message.error(
              res.msg || "Sorry, server is busy. please try later."
            );
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.Recruitment {
  overflow: hidden;

  .content {
    box-sizing: border-box;
    width: 1200px;
    margin: 20px auto;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 2.8571rem 73px;

    .head_imgbox {
      display: flex;
      justify-content: center;

      img {
        height: 110px;
        width: 292px;
        max-width: 50%;
        border: 1px solid #f3f3f3;
        border-radius: 6px;

        &:first-child {
          padding: 20px;
        }

        &:last-child {
          object-fit: contain;
        }
      }

      img + img {
        margin-left: 80px;
      }
    }

    .list_title {
      font-size: 1.2857rem;
      font-family: dtc-bold;
      color: #333333;
      line-height: 16px;
      margin-top: 30px;
      margin-bottom: 20px;
    }

    .list_text {
      font-size: 1.1429rem;
      color: #333333;
      line-height: 24px;
    }

    .imgBox {
      position: relative;
      width: 516px;
      height: 386px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #f3f3f3;
      border-radius: 6px;
      box-sizing: border-box;
      margin-bottom: 20px;
      overflow: hidden;
    }

    .img_item {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .dataValue {
      color: #2898ff;
    }
  }

  .bulletpoint {
    position: relative;
    padding-left: 30px;
  }

  .bulletpoint::after {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #333;
    position: absolute;
    top: 10px;
    left: 14px;
  }
}

.DeletedModel {
  h3 {
    width: 340px;
    font-size: 1.4286rem;
    font-family: dtc-bold;
    color: #333333;
    line-height: 30px;
    margin-bottom: 18px;
  }

  p {
    font-size: 1.1429rem;
    color: #333333;
    line-height: 30px;
    margin-bottom: 18px;
  }
}
</style>
