<template>
  <div>
    <div class="logo-header">
      <a href="https://www.linkprefer.com/" target="_blank"
        ><img src="@/assets/login/logo.svg" alt
      /></a>
    </div>
    <div class="login">
      <div class="login-box">
        <h1 class="login-box__title login-font">Log In</h1>
        <div class="login-form">
          <div class="login-form__item">
            <div class="form-item__label">Email <span>*</span></div>
            <div class="form-item__input">
              <input type="text" v-model="uname" />
            </div>
          </div>
          <div class="login-form__item">
            <div class="form-item__label">Password <span>*</span></div>
            <div class="form-item__input">
              <input :type="pwdShow ? 'text' : 'password'" v-model="password" />
              <div class="eye">
                <a-icon type="eye" v-if="pwdShow" @click="onPwdVisiable" />
                <a-icon type="eye-invisible" v-else @click="onPwdVisiable" />
              </div>
            </div>
          </div>

          <div class="login-form__item">
            <div class="form-item__input">
              <a-checkbox v-model="remember">
                Remember me on this computer
              </a-checkbox>
            </div>
          </div>
          <div class="login-form__btn">
            <a-button type="primary" @click="submit">Log in</a-button>
          </div>
          <div class="login-tips">
            <p class="p1">
              <router-link to="/forgot">Forgot your password?</router-link>
            </p>
            <p class="p2">— or —</p>
            <p class="p3">
              Are you a brand? <a href="mailto:am@linkprefer.com">Click here</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/utils/request";
import APIS from "@/api/const";

export default {
  name: "login",
  data() {
    return {
      pwdShow: false,
      uname: "",
      password: "",
      remember: "",
    };
  },
  components: {},
  methods: {
    onPwdVisiable() {
      this.pwdShow = !this.pwdShow;
    },
    submit() {
      const { uname, password, remember } = this;

      const params = {
        uname,
        password,
        remember: remember ? 1 : 0,
      };
      console.log(1111, "resp");

      axios
        .post(APIS.auth.login, {
          formData: true,
          ...params,
        })
        .then((resp) => {
          console.log(resp, "resp");
          if (resp.code === "0200") {
            const token = resp.payload.auth_token;
            const email = resp.payload.email;
            localStorage.setItem("saveToken", token);
            this.$store.commit("setToken", token);
            this.$message.success(resp.msg);
            if (resp.payload.step == 1) {
              this.$router.push({
                name: "email-verification",
                query: {
                  email: email,
                },
              });
            } else if (resp.payload.step == 2) {
              this.$router.push({
                path: "/channel-add",
              });
            } else {
              this.$router.push("/");
            }
          } else {
            this.$message.error(resp.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./user.less");
</style>
