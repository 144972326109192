<template>
  <div class="payset">
    <Header collection="My Account"></Header>
    <div class="payset-container">
      <a-tabs default-active-key="1">
        <a-tab-pane key="1" tab="Payment Methods">
          <div class="payset-content">
            <a-spin v-if="isloading">
              <a-empty :image="simpleImage" />
            </a-spin>
            <div v-for="item in payAccounts" :key="item.id">
              <div class="payset-item">
                <div class="payset-info">
                  <div class="payset-info-left">
                    <img
                      v-if="item.atype == '10'"
                      class="payset-info-icon"
                      src="@/assets/public/icon-paypal.svg"
                    />
                    <img
                      v-else-if="item.atype == '11'"
                      class="payset-info-icon"
                      style="width: 56px; height: 64px"
                      src="@/assets/payoneer.png"
                    />
                    <img
                      v-else
                      class="payset-info-icon"
                      src="@/assets/public/icon-express.svg"
                    />
                    <ul class="payset-info-list">
                      <li class="payset-info-item">
                        <span class="payset-info-title">{{
                          item.atype == "10" ? "Paypal Address:" : "Card No."
                        }}</span>
                        <span
                          :title="item.accountcard"
                          class="payset-info-text _ellipsis"
                          >{{ item.accountcard }}</span
                        >
                      </li>
                      <li class="payset-info-item">
                        <span class="payset-info-title"
                          >Withdraw Threshold:</span
                        >
                        <span class="payset-info-text"
                          >${{ item.threshold }}</span
                        >
                      </li>
                      <li class="payset-info-item" v-if="!!item.tax_file">
                        <span class="payset-info-title">Tax Filing:</span>
                        <a
                          target="_blank"
                          :href="item.tax_file"
                          class="payset-info-text pcolor"
                          >{{
                            item.tax_file.split("/")[
                              item.tax_file.split("/").length - 1
                            ]
                          }}</a
                        >
                      </li>
                      <li v-if="item.atype == '11' && !item.tax_file">
                        <span class="payset-info-title">Tax Filing:</span>
                        <div
                          class="payset-info-text _cursor"
                          style="color: #f64a47"
                          @click="() => toEdit(item)"
                        >
                          Please fill the tax info
                        </div>
                      </li>
                    </ul>
                  </div>
                  <a-button
                    v-if="item.set_defaut_autowithdraw == 1"
                    class="payset-info-button"
                    type="primary"
                    >Default</a-button
                  >
                  <a-button
                    v-else
                    class="payset-info-button"
                    @click="() => setDefault({ id: item.id })"
                    type="link"
                    >Set as Default</a-button
                  >
                </div>
                <div class="payset-bottom">
                  <span
                    class="payset-status status"
                    :class="item.status_show.name"
                  >
                    {{ item.status_show.name }}
                    <a-tooltip
                      v-if="item.status_show.reason"
                      :title="item.status_show.reason"
                    >
                      <img src="@/assets/question.png" alt="" />
                    </a-tooltip>
                  </span>
                  <div class="payset-action">
                    <span
                      class="payset-action-remove"
                      @click="() => deleteAccount(item.id)"
                      >Remove</span
                    >
                    <span class="payset-action-edit" @click="() => toEdit(item)"
                      >Edit</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="payset-add" @click="gotoAddPayment">
              <a-icon type="plus-circle" theme="filled" />Add Payment Method
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="Payment Password" force-render>
          <div class="payset-pwd">
            <a-form
              :form="formInfo"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
              @submit="handleSubmit"
            >
              <a-form-item
                label="Old Password"
                labelAlign="left"
                :colon="false"
                v-if="is_set"
              >
                <a-input
                  v-decorator="[
                    `pay_password`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please input your Old Password!',
                        },
                      ],
                    },
                  ]"
                  placeholder="Please input your Old Password"
                />
              </a-form-item>
              <a-form-item
                label="New Password"
                labelAlign="left"
                :colon="false"
              >
                <a-input
                  v-decorator="[
                    `new_password`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please input your New Password!',
                        },
                      ],
                    },
                  ]"
                  placeholder="Please input your New Password"
                />
              </a-form-item>
              <a-form-item
                label="Confirm Password"
                labelAlign="left"
                :colon="false"
              >
                <a-input
                  v-decorator="[
                    `re_new_password`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please Confirm your Password!',
                        },
                      ],
                    },
                  ]"
                  placeholder="Please Confirm your Password"
                />
              </a-form-item>
              <a-form-item
                label="Login Password"
                labelAlign="left"
                :colon="false"
              >
                <a-input
                  v-decorator="[
                    `login_password`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please input your Login Password!',
                        },
                      ],
                    },
                  ]"
                  placeholder="Please input your Login Password"
                />
              </a-form-item>
              <a-button type="primary" html-type="submit"
                >Renew Password</a-button
              >
            </a-form>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import Header from "@/components/public/Header.vue";
import axios from "@/utils/request";
import apis from "@/api/const";
import { webRoot } from "@/utils/config";
import { Empty } from "ant-design-vue";

export default {
  components: {
    Header,
  },
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  },
  data() {
    return {
      isloading: false,
      webRoot,
      payAccounts: [],
      is_set: false,
      formInfo: this.$form.createForm(this, { name: "form-pwd" }),
    };
  },
  mounted() {
    this.getPayAccount();
    this.getCheckPayPassword();
  },
  methods: {
    getCheckPayPassword() {
      axios.get(apis.paymentSeting.check_pay_password, {}).then((res) => {
        if (res.code === "0200") {
          this.is_set = res.payload.is_set;
        } else {
          this.$message.error(res.msg || "");
        }
      });
    },
    toEdit(item) {
      if (item.atype == 10) {
        //paypal
        this.$router.push({
          name: "add_payment_paypal_edit",
          params: { id: item.id },
        });
      } else if (item.atype == 11) {
        this.$router.push({
          name: "add_payment_paypal_edit",
          params: { id: item.id },
          query: { type: "payoneer" },
        });
      } else {
        //银行卡
        this.$router.push({
          name: "add_payment_bank_edit",
          params: { id: item.id },
        });
      }
    },
    deleteAccount(id) {
      let data = new FormData();
      data.append("id", id);
      axios.post(apis.paymentSeting.delete_account, data).then((res) => {
        if (res.code === "0200") {
          this.$message.success(res.msg);
          this.getPayAccount();
        } else {
          this.$message.error(res.msg || "");
        }
      });
    },
    setDefault({ id }) {
      let data = new FormData();
      data.append("id", id);
      axios.post(apis.paymentSeting.set_default, data).then((res) => {
        if (res.code === "0200") {
          this.$message.success(res.msg);
          this.getPayAccount();
        } else {
          this.$message.error(res.msg || "");
        }
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.formInfo.validateFields((err, values) => {
        if (!err) {
          this.changePayPassword(values);
        }
      });
    },
    changePayPassword(values) {
      let data = new FormData();
      Object.entries(values).map(([key, val]) => {
        data.append(key, val);
      });
      axios.post(apis.paymentSeting.change_pwd, data).then((res) => {
        if (res.code === "0200") {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg || "");
        }
      });
    },
    getPayAccount() {
      this.isloading = true;
      axios.get(apis.paymentSeting.pay_account, {}).then((res) => {
        this.isloading = false;
        if (res.code === "0200") {
          this.payAccounts = res.payload;
        } else {
          this.$message.error(res.msg || "");
        }
      });
    },
    gotoAddPayment() {
      this.$router.push({
        name: "add_payment_select",
      });
    },
  },
  beforeDestroy() {
    if (this.onWindowResize) {
      window.onresize = null;
    }
  },
};
</script>

<style lang="less" scoped>
.payset {
  &-container {
    padding: 1rem 2.2857rem 3rem;
    background: #ffffff;
    box-shadow: 0px 0px 12px 0px rgba(102, 118, 186, 0.1);
    border-radius: 1.1429rem;
    margin-top: 0.8571rem;
  }
  &-content {
    margin-top: 8px;
  }
  &-item {
    height: 11.1429rem;
    border-radius: 1.1429rem;
    border: 1px solid #e6e6e6;
    margin-bottom: 1.1429rem;
  }
  &-info {
    display: flex;
    justify-content: space-between;
    padding: 1.7143rem 2.2857rem;
    &-left {
      display: flex;
    }
    &-list {
      margin-left: 4.2857rem;
      display: flex;
    }
    &-item {
      display: flex;
      flex-direction: column;
      font-size: 1.1429rem;
      width: 16rem;
      + li {
        margin-left: 3rem;
      }
    }
    &-title {
      color: #999999;
    }
    &-text {
      margin-top: 1.1429rem;
    }
  }
  &-bottom {
    border-top: 1px solid #e6e6e6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3rem;
    padding: 0 2.2857rem;
  }
  &-status {
    font-size: 1.1429rem;
  }
  &-action {
    font-size: 1.1429rem;
    &-remove {
      color: #999999;
      margin-right: 1.7143rem;
      cursor: pointer;
    }
    &-edit {
      color: #3762d6;
      cursor: pointer;
    }
  }
  &-add {
    height: 11.1429rem;
    background: #f5f9fe;
    border-radius: 1.1429rem;
    border: 1px dashed #c9cdd3;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3762d6;
    font-size: 1.5714rem;
    cursor: pointer;
    &:hover {
      opacity: 0.85;
    }
    > i {
      margin-right: 8px;
    }
  }
  &-pwd {
    width: 60%;
    margin-top: 1.1429rem;
    > button {
      margin: 1.1429rem 0 0 13rem;
    }
  }
}

.status {
  color: #f64a47;
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  img {
    height: 14px;
  }
  &::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #f64a47;
    top: 0.375rem;
    left: -16px;
  }
}
.Pending {
  color: #ffc960;
  &::after {
    background: #ffc960;
  }
}
.Approved {
  color: #2ad57b;
  &::after {
    background: #2ad57b;
  }
}
/deep/ .ant-form-item-no-colon {
  color: #999999;
}
/deep/ .ant-input {
  height: 2.8571rem;
}
</style>
