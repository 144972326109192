<template>
  <div class="p-selector" :class="classStr" @click="onOpen">
    <div class="display">
      <div class="display-value">{{ displayValue }}</div>
      <div class="placeholder" v-show="isNull">{{ placeholder }}</div>
      <div class="clear-able" v-show="clearAble && !isNull" @click="onClearAll">
        x
      </div>
    </div>
    <div class="p-selector-dropdown" v-show="open">
      <span v-if="multiple" class="p-selector-all" @click="onSelectAll"
        >Select All</span
      >
      <div
        class="p-selector-option"
        v-for="opt in options"
        :key="opt.value"
        @click="(e) => onOptionSelect(e, opt)"
      >
        <span
          v-if="multiple"
          class="p-selector-checkbox"
          :class="{
            'p-selector-checkbox__checked':
              lodash.isArray(selectedValue) &&
              selectedValue.indexOf(opt.value) > -1,
          }"
        ></span>
        {{ customOptionDisplay(opt) }}
      </div>
    </div>
  </div>
</template>

<script>
import lodash from "lodash";

export default {
  name: "PSelector",
  model: {
    prop: "selected",
    event: "selected",
  },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    selected: {
      default: null,
      type: String | Array | Number,
    },
    placeholder: {
      default: "Please select",
      type: String,
    },
    size: {
      default: "middle",
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    options: {
      default: () => [],
      type: Array,
    },
    clearAble: {
      default: false,
      type: Boolean,
    },
    displayFunc: {
      default: null,
      type: Function,
    },
    optionDisplayFunc: {
      default: null,
      type: Function,
    },
  },
  watch: {
    selected: {
      handler(val) {
        this.selectedValue = val || [];
        this.onSeletedValueChanged();
      },
      deep: true,
      immediate: true,
    },
    selectedValue: {
      handler(val) {
        if (this.multiple) {
          if (!lodash.isArray(val)) {
            this.selectedValue = [];
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      lodash,
      open: false,
      selectedValue: null,
      displayValue: "",
    };
  },
  computed: {
    classStr() {
      return [
        "p-selector__" + this.size,
        "p-selector__" + (this.multiple ? "multiple" : "single"),
        "p-selector__" + (this.disabled ? "disabled" : "enabled"),
        "p-selector__" + (this.isNull ? "null" : "not-null"),
        "p-selector__" + (this.open ? "open" : "close"),
        "p-selector__" + (this.clearAble ? "clear-able" : "not-clear-able"),
      ].join(" ");
    },
    isNull() {
      if (this.multiple) {
        return !this.selectedValue || this.selectedValue?.length === 0;
      } else {
        return !this.selectedValue;
      }
    },
  },
  methods: {
    onSeletedValueChanged() {
      if (this.multiple) {
        if (this.selectedValue?.length === 0) {
          this.displayValue = this.placeholder;
        } else if (this.selectedValue?.length === this.options?.length) {
          this.displayValue = "All";
        }
        let result = this.options
          .filter((opt) => this.selectedValue.indexOf(opt.value) > -1)
          .map((opt) => this.customDisplay(opt))
          .join(", ");
        this.displayValue = result;
      } else {
        if (!this.selectedValue) {
          this.displayValue = this.placeholder;
        }

        this.displayValue = this.customDisplay(
          this.options.find((opt) => opt.value === this.selectedValue)
        );
      }
    },
    onOpen() {
      if (this.disabled) return;
      this.open = !this.open;
      document.body.addEventListener("click", this.onBodyClick);
    },
    onBodyClick(e) {
      if (this.$el.contains(e.target)) return;
      this.open = false;
      document.body.removeEventListener("click", this.onBodyClick);
    },
    onClearAll(e) {
      e.stopPropagation();
      if (this.disabled) return;

      if (this.multiple) {
        this.selectedValue = [];
      } else {
        this.selectedValue = null;
      }
      this.$emit("selected", this.selectedValue);
    },
    onSelectAll(e) {
      e.stopPropagation();
      if (this.disabled) return;
      if (!this.lodash.isArray(this.selectedValue)) {
        this.selectedValue = [];
      }

      if (this.selectedValue?.length != this.options.length) {
        this.selectedValue = this.options.map((opt) => opt.value);
      } else {
        this.selectedValue = [];
      }
      this.$emit("selected", this.selectedValue);
    },
    onOptionSelect(e, opt) {
      if (this.disabled) return;
      if (this.multiple) {
        if (this.lodash.isArray(this.selectedValue)) {
          if (this.selectedValue.indexOf(opt.value) > -1) {
            this.selectedValue = this.selectedValue.filter(
              (v) => v != opt.value
            );
          } else {
            this.selectedValue.push(opt.value);
          }
        } else {
          this.selectedValue = [opt.value];
        }
        e.stopPropagation();
      } else {
        this.selectedValue = opt.value;
      }
      this.$emit("selected", this.selectedValue);
    },
    customDisplay(opt) {
      if (this.displayFunc) {
        return this.displayFunc(opt);
      } else {
        return opt.label;
      }
    },
    customOptionDisplay(opt) {
      if (this.optionDisplayFunc) {
        return this.optionDisplayFunc(opt);
      } else {
        return opt.label;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.p-selector {
  border: 1px solid #ddd;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  font-size: 1.1429rem;
  transition: all 0.3s;
  // z-index: 100;

  .display {
    padding: 0 32px 0 10px;

    &::after {
      content: "";
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #666;
      transition: all 0.3s;
    }
  }

  &__open {
    border-color: #52b1ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgb(40 152 255 / 20%);

    .display {
      &::after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  &__disabled {
    cursor: not-allowed;
  }

  &__small {
    height: 24px;
    line-height: 22px;
  }

  &__middle {
    height: 32px;
    line-height: 30px;
  }

  &__large {
    height: 2.8571rem;
    line-height: 36px;
  }

  .placeholder {
    display: none;
    user-select: none;
  }

  .display-value {
    user-select: none;
    // background-color: #52b1ff;
    // 超出显示省略号
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__null {
    // background: #f5f5f5;
    color: #999;

    .display-value {
      display: none;
    }

    .placeholder {
      display: block;
    }
  }

  &__not-null {
    &.p-selector__clear-able {
      .display {
        .clear-able {
          color: #999;
          font-size: 1rem;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          transition: all 0.3s;
          display: none;
          height: 18px;
          width: 18px;
          text-align: center;
          z-index: 1;
          justify-content: center;
          align-items: center;
          background-color: #00000001;
          border-radius: 99px;
          user-select: none;

          &:hover {
            background-color: #00000011;
          }
        }

        &:hover {
          &::after {
            display: none;
          }

          .clear-able {
            display: flex;
          }
        }
      }
    }
  }
}

.p-selector-dropdown {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  min-width: 100%;
  width: fit-content;
  background: #fff;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  z-index: 1;
  overflow: hidden;
  transition: all 0.3s;
  padding: 7px 0 14px;
  box-sizing: border-box;
  max-height: 250px;
  overflow-y: auto;
  color: #333;

  .p-selector-all {
    color: #3762d6;
    display: block;
  }

  .p-selector-option,
  .p-selector-all {
    font-size: 1rem;
    line-height: 20px;
    padding: 6px 14px;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:hover {
      background-color: #2898ff11;
    }

    .p-selector-checkbox {
      background: url(~@/assets/icon-selector.svg) no-repeat center center;
      background-size: contain;
      height: 16px;
      width: 16px;
      display: inline-block;
      cursor: pointer;
      margin-right: 5px;

      &__checked {
        background: url(~@/assets/icon-selector-checked.svg) no-repeat center
          center;
        background-size: contain;
      }
    }
  }
}
</style>
