<template>
  <div class="flowrep">
    <Header collection="Reporting"></Header>
    <div class="container">
      <div class="headers"></div>
      <div class="content">
        <div class="comm-tables">
          <div class="comm-header">
            <div class="comm-header-left">
              <a-range-picker separator="-" @change="onDateChange" />
              <div class="header-input">
                <span>Brand:</span>
                <BrandsSearch width="280px" v-model="m_id"></BrandsSearch>
              </div>
              <div class="header-input">
                <span>Order ID:</span><a-input v-model="orderId"></a-input>
              </div>
              <a-select placeholder="Status" v-model="status_new">
                <a-select-option value="all"> All </a-select-option>
                <a-select-option value="pending"> Pending </a-select-option>
                <a-select-option value="expired"> Expired </a-select-option>
                <a-select-option value="approved"> Approved </a-select-option>
              </a-select>
              <a-select
                style="width:16.3857rem;"
                placeholder="Channels"
                v-model="site_id"
              >
                <a-select-option
                  :value="item.id"
                  v-for="item in channels"
                  :key="`sites-${item.id}`"
                >
                  {{ item.title }} ({{ item.medium_id }})
                </a-select-option>
              </a-select>
              <div class="header-input">
                <span>Cross-device:</span
                ><a-select
                  style="width:14.2857rem;"
                  placeholder="Select cross device"
                  v-model="is_cross_device"
                >
                  <a-select-option value="all"> All </a-select-option>
                  <a-select-option value="1"> Supported </a-select-option>
                  <a-select-option value="0"> Not Supported </a-select-option>
                </a-select>
              </div>
              <div class="header-input">
                <span>Payment ID:</span><a-input v-model="p_id"></a-input>
              </div>
              <div class="header-input">
                <span>Withdrawal ID:</span><a-input v-model="w_id"></a-input>
              </div>
            </div>
            <div class="comm-header-rigth">
              <a-button type="primary" @click="getDataList">Search</a-button>
              <a-button type="primary" @click="toDownload"
                >Export Excel</a-button
              >
              <a-button type="primary" @click="gotoDownList"
                >Download Queue</a-button
              >
            </div>
          </div>
          <div class="comm-list">
            <a-table
              class="comtable"
              :columns="columns"
              :data-source="dataLists"
              :rowKey="(record, index) => record.id"
              :loading="isLoading"
              :pagination="false"
              :scroll="{ x: '100%' }"
              @change="handleTableChange"
            >
              <div slot="c_quantity_down"></div>
              <div slot="c_quantity">
                <a-popover>
                  <template slot="content">
                    Number of items packages included in this order
                  </template>
                  <span>
                    Items
                    <img class="question-img" src="@/assets/question.png" />
                  </span>
                </a-popover>
              </div>
              <div slot="unit_items">
                <a-popover>
                  <template slot="content">
                    Transaction amount of a single item
                  </template>
                  <span
                    >Unit Price
                    <img class="question-img" src="@/assets/question.png" />
                  </span>
                </a-popover>
              </div>
              <div slot="refuseStatus">
                <a-popover>
                  <template slot="content">
                    The rejection reason returned by the network when the order
                    status is rejected.
                  </template>
                  <span
                    >Refusal Reason
                    <img class="question-img" src="@/assets/question.png" />
                  </span>
                </a-popover>
              </div>
              <div slot="rate">
                <a-popover>
                  <template slot="content">
                    When the base commission is displayed as "Rev. Share: 70%,"
                    it means that LinkPrefer will pay you 70% of the sales
                    commission we receive from the merchant.
                  </template>
                  <span
                    >Default Commission
                    <img class="question-img" src="@/assets/question.png" />
                  </span>
                </a-popover>
              </div>
            </a-table>
            <div class="footer-pagination" v-if="totalCount > 0">
              <a-pagination
                v-model="currentPage"
                :total="totalCount"
                :pageSize="20"
                show-less-items
                @change="paginationChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/public/Header.vue";
import axios from "@/utils/request";
import apis from "@/api/const";
import BrandsSearch from "@/components/BrandsSearch.vue";

export default {
  components: {
    Header,
    BrandsSearch,
  },
  data() {
    let columns = [
      {
        title: "Transaction Date",
        dataIndex: "ori_transaction_date",
        sortKey: "ori_transaction_date",
        width: 150,
        sorter: true,
        sortOrder: false,
        align: "center",
        customRender: (text, row, index) => {
          if (index === 0) {
            return {
              children: <div class="first-row-1">Total</div>,
            };
          }
          return text;
        },
      },
      {
        title: "Advertiser",
        dataIndex: "m_id",
        width: 140,
        align: "center",
      },
      {
        title: "Channel ID",
        dataIndex: "medium_id",
        width: 140,
        align: "center",
      },
      {
        title: "Order ID",
        dataIndex: "c_order_id",
        width: 140,
        align: "center",
      },
      {
        dataIndex: "c_quantity",
        sortKey: "c_quantity",
        width: 100,
        align: "center",
        sortOrder: false,
        sorter: true,
        slots: { title: "c_quantity" },
      },
      {
        dataIndex: "single_price",
        sortKey: "per_price",
        width: 120,
        align: "center",
        sorter: true,
        sortOrder: false,
        slots: { title: "unit_items" },
      },
      {
        title: "Sale Amount (USD)",
        dataIndex: "amount",
        sortKey: "amount",
        width: 160,
        sorter: true,
        sortOrder: false,
        align: "center",
      },
      {
        title: "Commission (USD)",
        dataIndex: "aff_brokerage",
        sortKey: "aff_brokerage",
        sorter: true,
        sortOrder: false,
        width: 160,
        align: "center",
      },
      {
        dataIndex: "rate",
        width: 190,
        align: "center",
        slots: { title: "rate" },
      },
      {
        title: "Status",
        dataIndex: "status",
        width: 140,
        align: "center",
      },
      {
        dataIndex: "aff_raw_declined_reason",
        width: 140,
        align: "center",
        slots: { title: "refuseStatus" },
      },
      {
        title: "U_ID",
        dataIndex: "tag",
        width: 140,
        align: "center",
      },
      {
        title: "Payment Date",
        dataIndex: "settlement_updated_date",
        sortKey: "updated_date",
        width: 140,
        sorter: true,
        sortOrder: false,
        align: "center",
      },
      {
        title: "Operating System",
        dataIndex: "client_type",
        width: 140,
        align: "center",
      },
      {
        title: "Device type",
        dataIndex: "is_cross_device",
        width: 140,
        align: "center",
      },
      {
        title: "Product ID",
        dataIndex: "commission_id",
        width: 140,
        align: "center",
      },
      {
        title: "Voucher Code",
        dataIndex: "vcode",
        width: 140,
        align: "center",
      },
      {
        title: "Click IP",
        dataIndex: "ip",
        width: 140,
        align: "center",
      },
      {
        title: "Referer URL",
        dataIndex: "refer",
        width: 140,
        align: "center",
      },
      {
        title: "Payment ID",
        dataIndex: "payment_sn",
        width: 140,
        align: "center",
      },
      {
        title: "Withdrawal ID",
        dataIndex: "withdraw_id",
        width: 140,
        align: "center",
      },
    ];
    return {
      columns,
      channels: [],
      dataLists: [],
      isLoading: false,
      totalCount: 0,
      currentPage: 1,
      page_size: 10,
      order_sort: "ori_transaction_date",
      order_desc: "asc",
      export: "0",
      start_time: undefined,
      end_time: undefined,
      site_id: undefined,
      status_new: "all",
      is_cross_device: "all",
      orderId: undefined,
      m_id: undefined,
      w_id: null,
      p_id: null,
    };
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.query.w_id || this.$route.query.p_id) {
          this.w_id = this.$route.query.w_id;
          this.p_id = this.$route.query.p_id;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getDataList();
    this.getChannelList();
  },
  methods: {
    getChannelList() {
      axios.get(apis.channel.index).then((resp) => {
        if (resp.code === "0200") {
          this.channels = resp.payload?.filter((e) => e.status == "1");
        }
      });
    },
    gotoDownList() {
      this.$router.push({ name: "reporting_cps_downlist" });
    },
    toDownload() {
      this.isLoading = true;
      let configs = {
        params: {
          start_time: this.start_time || undefined,
          end_time: this.end_time || undefined,
          site_id: this.site_id,
          order_id: this.orderId,
          is_cross_device:
            this.is_cross_device === "all" ? undefined : this.is_cross_device,
          page: this.currentPage,
          export: this.export,
          order_sort: this.order_sort,
          order_desc: this.order_desc,
          status_new: this.status_new === "all" ? undefined : this.status_new,
          m_id: this.m_id,
          withdraw_id: this.w_id,
          payment_id: this.p_id,
        },
      };
      axios.get(apis.reporting.cps_download, configs).then((res) => {
        this.isLoading = false;
        if (res.code === "0200") {
          this.$message.success("Download success!");
        } else {
          this.$message.error(res.msg || "");
        }
      });
    },
    onDateChange(value, dateString) {
      if (dateString.length === 2) {
        this.start_time = dateString[0];
        this.end_time = dateString[1];
      }
    },
    findOrder(sorter, order) {
      let columns = this.columns;
      let list = columns.map((item) => {
        return item?.dataIndex == sorter?.columnKey
          ? { ...item, sortOrder: order }
          : { ...item, sortOrder: false };
      });
      this.columns = [...list];
    },
    setOrder(sorter) {
      let order = sorter?.order || false;
      this.findOrder(sorter, order);
    },
    handleTableChange(pagination, filters, sorter) {
      this.setOrder(sorter); //自定义排序
      console.log(sorter);
      if (sorter.column) {
        this.order_sort = sorter.column?.sortKey;
        this.order_desc = sorter?.order == "ascend" ? "asc" : "desc";
      } else {
        this.order_sort = "ori_transaction_date";
        this.order_desc = "asc";
      }
      this.getDataList();
    },
    // 获取列表
    getDataList() {
      this.isLoading = true;
      let configs = {
        params: {
          start_time: this.start_time || undefined,
          end_time: this.end_time || undefined,
          site_id: this.site_id,
          order_id: this.orderId,
          is_cross_device:
            this.is_cross_device === "all" ? undefined : this.is_cross_device,
          page: this.currentPage,
          export: this.export,
          order_sort: this.order_sort,
          order_desc: this.order_desc,
          status_new: this.status_new === "all" ? undefined : this.status_new,
          m_id: this.m_id,
          withdraw_id: this.w_id,
          payment_id: this.p_id,
        },
      };
      axios.get(apis.reporting.cps, configs).then((res) => {
        this.isLoading = false;
        if (res.code === "0200") {
          if (res.payload.list.length > 0) {
            this.dataLists = [res.payload.total, ...res.payload.list];
          } else {
            this.dataLists = res.payload.list;
          }
          console.log(this.dataLists, "this.dataLists");
          this.totalCount = parseInt(res.payload.pagination.total, 10);
        } else {
          this.$message.error(res.msg || "");
        }
      });
    },
    // 切换下一页
    paginationChange(page, pageSize) {
      this.currentPage = page;
      this.getDataList();
    },
  },
  beforeDestroy() {
    if (this.onWindowResize) {
      window.onresize = null;
    }
  },
};
</script>

<style lang="less" scoped>
.flowrep {
  .headers {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 0.8571rem;
  }
  .content {
    .comm {
      &-header {
        flex-direction: column;
      }
    }
    .comm-header-left {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      align-items: flex-start;
      > div,
      > span {
        margin-right: 1.7143rem;
        margin-bottom: 1.7143rem;
      }
      .header-input {
        width: 280px;
        display: flex;
        align-items: center;
        > span {
          white-space: nowrap;
          margin-right: 0.7143rem;
        }
      }
      .ant-input {
        height: 2.8571rem;
      }
    }
    .comm-header-rigth {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      > button {
        margin-left: 1.1429rem;
      }
    }
    .first-row-1 {
      text-align: left;
      padding-left: 2.1429rem;
    }
    .first-row-2 {
      text-align: left;
      padding-left: 1.4286rem;
    }
    .first-row-3 {
      text-align: left;
      padding-left: 2.1429rem;
    }
    .first-row-4 {
      text-align: left;
      padding-left: 2.1429rem;
    }
  }
  //   /deep/ .ant-table-thead > tr > th {
  //     border: 1px solid #fff;
  //     font-size: 0.9286rem;
  //   }
  //   /deep/ .ant-table-thead > tr:first-child > th:first-child,
  //   /deep/ .ant-table-thead > tr:last-child > th:last-child {
  //     border-left: none;
  //   }
  /deep/ .ant-select {
    width: 14.2857rem;
  }
  /deep/.ant-calendar-picker-input.ant-input {
    height: 2.8571rem;
    width: 230px;
  }
}
.question-img {
  height: 14px;
  margin-left: 2px;
}
</style>
