<template>
  <div class="balance">
    <span>{{ url }}</span>
    <span>ID#{{ balancedId }}</span>
    <span>Balance: ${{ balance }}</span>
  </div>
</template>

<script>
export default {
  name: "balance",
  props: {
    url: String,
    balancedId: String,
    balance: String,
  },
};
</script>

<style lang="less" scoped>
.balance {
  width: 200px;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  background-color: rgb(6, 155, 255);
  span {
    font-style: 14px;
    font-family: "dtc-bold";
    color: #fff;
    display: block;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>