<template>
  <div>
    <Loading v-if="isLoading"></Loading>
    <div class="logo-header">
      <a href="https://www.linkprefer.com/" target="_blank"><img src="@/assets/login/logo.svg" alt /></a>
    </div>
    <div class="sign-box">
      <div class="login-back">
        <span>
          <a-icon type="left" />
        </span>
      </div>
      <div class="form">
        <div class="wrap">
          <h1 class="login-box__title">Loading...</h1>
          <div class="veri-box">
            <p>Loading...</p>
          </div>
        </div>
      </div>
      <div class="pic">
        <img src="@/assets/login/sign-right.png" alt />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/utils/request";
import APIS from "@/api/const";

export default {
  name: "login",
  data() {
    return {
      code: '',
      isLoading: true,
    };
  },
  mounted () {
    const { code } = this.$route.query;
    if (code) {
      this.code = code;
      this.checkEmailCode();
    }
  },
  methods: {
    async checkEmailCode() {
      const { code } = this;
      const resp = await axios.get(APIS.auth.email_verify, {
        params: {
          code,
        }
      })
      if (resp.code === '0200') {
        const token = resp.payload.token;
        localStorage.setItem("saveToken", token);
        this.$store.commit('setToken', token);
        location.href = '/channel-add'
        // this.$router.replace({
        //   name: "channel-add",
        // });
      } else {
        if (resp.code === '0501') {
          this.$router.push({
            name: "channel-add",
            query: {
              email: resp.payload.email,
            }
          });
        } else {
          this.$message.error(resp.msg);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./signup.less");
</style>
