<template>
  <ul class="campprev" v-if="item">
    <li class="campprev__item">
      <span class="campprev__item__title">Content Format(s)</span>
      <div class="campprev__item__content">
        <span
          class="tag"
          v-for="(contentf, index) in item.content_format"
          :key="`contentf-${index}`"
          >{{ contentf }}</span
        >
      </div>
    </li>
    <li class="campprev__item">
      <span class="campprev__item__title">Platform(s)</span>
      <div class="campprev__item__content">
        <span
          class="campprev__item__platform"
          v-for="(contentp, index) in platforms"
          :key="`contentp-${index}`"
          ><img :src="contentp.icon" />{{ contentp.name }}</span
        >
      </div>
    </li>
    <li class="campprev__item">
      <span class="campprev__item__title">Deadline</span>
      <div class="campprev__item__content">
        {{ item.deadline | formatTimestamp }}
      </div>
    </li>
    <li class="campprev__item" v-if="hastags.length > 0">
      <span class="campprev__item__title">#Hashtags</span>
      <div class="campprev__item__content">
        <span
          class="campprev__item__content__link"
          v-for="(tag, index) in hastags"
          :key="`tag-${index}`"
          >#{{ tag }}</span
        >
      </div>
    </li>
    <li class="campprev__item" v-if="attags.length > 0">
      <span class="campprev__item__title">@Mentions</span>
      <div class="campprev__item__content">
        <span
          class="campprev__item__content__link"
          v-for="(tag, index) in attags"
          :key="`tag-${index}`"
          >@{{ tag }}</span
        >
      </div>
    </li>
    <li class="campprev__item" v-if="item.donts">
      <span class="campprev__item__title">Caption Description</span>
      <div class="campprev__item__content">
        <span class="campprev__item__content__normal">{{
          item.postcaption
        }}</span>
      </div>
    </li>
  </ul>
</template>

<script>
import { PLATFORMS } from "@/api/const";
export default {
  props: {
    item: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {};
  },
  methods: {},
  watch: {},
  computed: {
    platforms() {
      let res = [];
      for (let p of this.item.content_platforms) {
        for (let ps of PLATFORMS) {
          if (p === ps.name) {
            res.push(ps);
          }
        }
      }
      return res;
    },
    hastags() {
      let tags = [];
      if (this.item.hashtags) {
        tags = this.item.hashtags.split(",");
      }
      return tags;
    },
    attags() {
      let tags = [];
      if (this.item.at) {
        tags = this.item.at.split(",");
      }
      return tags;
    },
  },
};
</script>

<style lang="less" scoped>
.campprev {
  margin-top: 16px;
  &__item {
    display: flex;
    margin-top: 20px;
    &__title {
      width: 200px;
      color: #999999;
      .red {
        color: red;
      }
    }
    &__content {
      width: calc(100% - 200px);
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      .tag {
        margin-right: 12px;
      }
      &__sm {
        font-size: 0.8571rem;
        color: #333333;
        font-weight: lighter;
        margin-right: 10px;
      }
      &__normal {
        margin-right: 10px;
      }
      &__bg {
        margin-right: 10px;
        font-weight: 600;
      }
      &__link {
        color: #4a90e2;
        margin-right: 10px;
      }
    }
    &__platform {
      margin-right: 20px;
      display: flex;
      align-items: center;
      width: 96px;
      margin-bottom: 8px;
      img {
        width: 18px;
        margin-right: 6px;
      }
    }
  }
}
</style>
