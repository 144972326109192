<template>
  <div class="child-details">
    <Loading v-if="isLoadingModal"></Loading>
    <ChildManagement></ChildManagement>
  </div>
</template>

<script>
import Modal from '../../../components/Modal.vue'
import inspectUserPermissions from '@/utils/permissionsJudge'
import axios from '@/utils/request'
import ChildManagement from "./ChildManagement.vue"

export default {
  name: 'account-edit',
  components: {
    Modal,
    ChildManagement
  },
  data() {
    return {
      
      isLoadingModal: false
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.child-details {
  width: 948px;
  height: 650px;
  background: #FFFFFF;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: flex-start;
}

</style>
