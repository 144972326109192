<template>
  <div class="deposit-history-wrap">
    <Brands collection="Reports" subset="Performance"></Brands>
    <div class="content">
      <div class="content-title">
        <div class="items">
          <div class="item" style="width: 210px">
            <span>Date Range</span>
            <a-range-picker
              class="date"
              v-model="dateRange"
              @change="handleChangeDate"
              format="L"
            />
          </div>
          <div class="item" style="width: 190px">
            <span>Arrangement</span>
            <a-select
              class="select"
              placeholder="Select"
              v-model="params.group_by"
              @change="handlerArrangementChange"
            >
              <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
              <a-select-option value="day">Daily</a-select-option>
              <a-select-option value="weekly">Weekly</a-select-option>
              <a-select-option value="month">Monthly</a-select-option>
              <a-select-option value="year">Yearly</a-select-option>
              <a-select-option value="medium_id">Publisher ID</a-select-option>
              <a-select-option value="category"
                >Publisher Category</a-select-option
              >
              <a-select-option value="affiliate_country"
                >Publisher Country</a-select-option
              >
            </a-select>
          </div>
          <div class="item" style="width: 190px">
            <span>Publisher ID</span>
            <a-input v-model="params.medium_id" />
          </div>
          <div class="item" style="width: 210px">
            <span>Publisher Category</span>
            <PSelector
              size="large"
              v-model="params.cid"
              :options="
                $store.state.commonConfig.categories.map((c) => ({
                  value: c.id,
                  label: c.en_title,
                }))
              "
              multiple
              clearAble
            ></PSelector>
          </div>
          <div class="item" style="width: 220px">
            <span>Publisher Country</span>
            <PSelector
              size="large"
              v-model="params.country_code"
              :options="
                $store.state.commonConfig.countries.map((c) => ({
                  value: c.code,
                  label: c.name_en,
                }))
              "
              multiple
              clearAble
            ></PSelector>
          </div>
        </div>
        <div class="search-reset">
          <button @click="search">Search</button>
          <button @click="reset">Reset</button>
        </div>
      </div>

      <div class="table-data">
        <a-table
          :columns="columns"
          :data-source="reportList"
          bordered
          :rowKey="(record, index) => index"
          :pagination="false"
          :loading="isLoading"
        >
          <template slot="conv_rate" slot-scope="text, record">
            <span>{{
              record.total_click > 0
                ? ((100 * record.order_count) / record.total_click).toFixed(2) +
                  "%"
                : "-"
            }}</span>
          </template>
          <template slot="aov" slot-scope="text, record">
            <span>{{
              record.order_count > 0
                ? (record.total_cmsn / record.order_count).toFixed(2) + "%"
                : "-"
            }}</span>
          </template>
          <template slot="avg_payout" slot-scope="text, record">
            <span>{{
              record.order_count > 0
                ? (record.aff_cmsn / record.order_count).toFixed(2) + "%"
                : "-"
            }}</span>
          </template>
          <template slot="effective_rate" slot-scope="text, record">
            <span>{{ record.effective_rate }}%</span>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import Brands from "./../../components/Brands.vue";
import axios from "@/utils/request";
import moment from "moment-timezone";
moment.defaultFormat = "L";
import Modal from "./../../components/Modal.vue";
var paramsGroupBy = "day";
var countryMap = {};
var cateMap = {};

const columns = [
  {
    title: "No",
    key: "",
    dataIndex: "",
    customRender: (text, record, index) => {
      if (index < 1) {
        return {
          children: "Total",
          attrs: {
            colSpan: 2,
            align: "right",
          },
        };
      }
      return record.idx;
    },
  },
  {
    title: "Date",
    key: "",
    dataIndex: "",
    colSpan: 1,
    align: "right",
    customRender: (text, record, index) => {
      if (index < 1) {
        return {
          children: "",
          attrs: {
            colSpan: 0,
          },
        };
      }
      let val;
      switch (paramsGroupBy) {
        case "day":
          val = record.day.split("-");
          val = val[1] + "/" + val[2] + "/" + val[0];
          break;
        case "weekly":
          val = record.date.split("~");
          val = val[0].split("-").concat(val[1].split("-"));
          val =
            val[1] +
            "/" +
            val[2] +
            "/" +
            val[0] +
            "~" +
            val[4] +
            "/" +
            val[5] +
            "/" +
            val[3];
          break;
        case "month":
          val = record.month.substr(4) + "/" + record.month.substr(0, 4);
          break;
        case "year":
          val = record.year;
          break;
        case "medium_id":
          val = record.medium_id;
          break;
        case "category":
          val = cateMap[record.category] || "Not Set";
          break;
        case "affiliate_country":
          val = countryMap[record.affiliate_country] || "Not Set";
      }
      return {
        children: val,
        attrs: {
          colSpan: 1,
        },
      };
    },
  },
  {
    title: "Clicks",
    key: "total_click",
    dataIndex: "total_click",
    align: "right",
    customRender: (text, record, index) => {
      return window.$vue.toThousands(record.total_click, true);
    },
  },
  {
    title: "Orders",
    key: "",
    dataIndex: "",
    customRender: (text, record, index) => {
      return window.$vue.toThousands(record.order_count, true);
    },
    align: "right",
  },
  {
    title: "Conversion Rate",
    key: "",
    dataIndex: "",
    customRender: (text, record, index) => {
      return record.total_click > 0
        ? ((100 * record.order_count) / record.total_click).toFixed(2) + "%"
        : "-";
    },
    align: "right",
  },
  {
    title: "Sale Amount",
    key: "",
    dataIndex: "cps_cmsn",
    align: "right",
    customRender: (text, record, index) => {
      return window.$vue.toThousands(record.cps_cmsn.toFixed(2));
    },
  },
  {
    title: "AOV",
    key: "",
    dataIndex: "",
    customRender: (text, record, index) => {
      return record.order_count > 0
        ? window.$vue.toThousands(
            (record.total_cmsn / record.order_count).toFixed(2)
          )
        : "-";
    },
    align: "right",
  },
  {
    title: "Commission",
    key: "",
    dataIndex: "aff_cmsn",
    align: "right",
    customRender: (text, record, index) => {
      return window.$vue.toThousands(record.aff_cmsn.toFixed(2));
    },
  },
  {
    title: "Avg Payout",
    key: "",
    dataIndex: "",
    customRender: (text, record, index) => {
      return record.order_count > 0
        ? (record.aff_cmsn / record.order_count).toFixed(2) + "%"
        : "-";
    },
    align: "right",
  },
  {
    title: "PartnerBoost ",
    key: "",
    dataIndex: "lh_cmsn",
    align: "right",
    customRender: (text, record, index) => {
      return window.$vue.toThousands(record.lh_cmsn.toFixed(2));
    },
  },
  {
    title: "Total Commission",
    key: "",
    dataIndex: "total_cmsn",
    align: "right",
    customRender: (text, record, index) => {
      return window.$vue.toThousands(record.total_cmsn.toFixed(2));
    },
  },
];

const defaultDateRange = [
  moment().subtract(80, "days"),
  moment().subtract(1, "days"),
];

export default {
  name: "report-summary",
  components: {
    Modal,
    Brands,
  },
  data() {
    return {
      reportList: [],
      stats: {},
      columns,
      currentPage: 1,
      page_size: 10,
      totalPage: 10,
      isLoading: false,

      dateRange: [].concat(defaultDateRange),
      params: {
        group_by: "day",
        start_date: defaultDateRange[0].format("MM/DD/YYYY"),
        end_date: defaultDateRange[1].format("MM/DD/YYYY"),
        medium_id: null,
        country_code: [],
        cid: [],
      },
    };
  },
  created() {
    paramsGroupBy = "day";
    this._getCoutry();
    this.getReport();
  },
  methods: {
    _getCoutry() {
      countryMap = {};
      cateMap = {};
      this.$store.state.commonConfig.countries.forEach((item) => {
        countryMap[item.code] = item.name_en;
      });
      this.$store.state.commonConfig.categories.forEach((item) => {
        cateMap[item.id] = item.en_title;
      });
    },
    handlerArrangementChange(val) {
      paramsGroupBy = val;
      let title;
      switch (val) {
        case "day":
          title = "Date";
          break;
        case "weekly":
          title = "Date Range";
          break;
        case "month":
          title = "Month";
          break;
        case "year":
          title = "Year";
          break;
        case "medium_id":
          title = "Publisher ID";
          break;
        case "category":
          title = "Publisher Category";
          break;
        case "affiliate_country":
          title = "Publisher Country";
          break;
      }
      let columns1 = this.columns[1];
      columns1.title = title;
      this.$set(this.columns, 1, columns1);
      this.getReport();
    },
    handleCountryChange() {
      this.getReport();
    },
    handleCateChange() {
      this.getReport();
    },
    search() {
      this.currentPage = 1;
      this.getReport();
    },
    reset() {
      paramsGroupBy = "day";
      this.dateRange = [].concat(defaultDateRange);
      this.params = {
        group_by: "day",
        start_date: defaultDateRange[0].format("MM/DD/YYYY"),
        end_date: defaultDateRange[1].format("MM/DD/YYYY"),
        medium_id: null,
        country_code: [],
        cid: [],
      };
    },
    getReport() {
      this.isLoading = true;
      this.reportList = [];
      this.stats = {};
      axios
        .get("api_admin.php?a=report/call", {
          params: {
            report_name: "reportForDTCMid",
            ...this.params,
            country_code: this.params.country_code.join(","),
            cid: this.params.cid.join(","),
          },
        })
        .then((res) => {
          if (res.code === 0) {
            let i = 0;
            this.reportList = [res.data.data.summary].concat(
              res.data.data.list.map((item) => ({
                ...item,
                idx: ++i,
              }))
            );
            this.totalPage = res.data.total;
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.$message.error(res.msg);
          }
        });
    },
    paginationChange(page, pageSize) {
      this.currentPage = page;
      this._getDepositHistory();
    },
    handleChangeDate(_, dates) {
      this.params.start_date = _[0].format("MM/DD/YYYY");
      this.params.end_date = _[1].format("MM/DD/YYYY");
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  margin-bottom: 100px;

  .content-title {
    width: 100%;
    padding: 20px 44px 30px 30px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;

    .items {
      display: flex;

      .item {
        width: 262px;
        margin-right: 26px;

        &:last-child {
          margin-right: 0px;
        }

        span {
          display: block;
          font-size: 1rem;
          font-family: "dtc-bold";
          color: #333333;
          line-height: 30px;
          margin-bottom: 2px;
        }

        input {
          width: 100%;
          height: 2.8571rem;
          background: #ffffff;
          border-radius: 6px;
          border: 1px solid #e6e6e6;
        }

        .select {
          width: 100%;
          height: 2.8571rem;

          /deep/ .ant-select-selection--single {
            height: 2.8571rem;
            border-radius: 6px;
            background: #fafafa;
          }

          /deep/ .ant-select-selection__rendered {
            line-height: 2.8571rem;
          }

          .icon-down {
            color: #333333;
            font-size: 1rem;
          }
        }

        .date {
          width: 100%;
          height: 2.8571rem;

          /deep/ .ant-input {
            height: 2.8571rem;
            border-radius: 6px;
            background: #fafafa;
          }
        }
      }
    }

    .search-reset {
      margin-top: 20px;
      text-align: right;

      button {
        padding: 9px 32px;
        border-radius: 6px;
        cursor: pointer;
        border: none;

        &:first-child {
          background: #2898ff;
          font-size: 1.1429rem;
          color: #ffffff;
          line-height: 20px;
          margin-right: 20px;
        }

        &:last-child {
          border: 1px solid #dddddd;
          font-size: 1.1429rem;
          color: #333333;
          line-height: 20px;
        }
      }
    }
  }

  .table-data {
    margin-top: 36px;
    margin-bottom: 76px;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;

    /deep/ .ant-spin-nested-loading.ant-table-spin-holder .ant-empty {
      opacity: 0;
    }
  }
}

.deposit-history-wrap {
  margin-top: 20px;

  p {
    font-size: 1.1429rem;
    color: #999999;
    line-height: 20px;
    margin-bottom: 22px;
  }

  .export {
    margin-bottom: 22px;
    text-align: right;

    button {
      width: 116px;
      height: 2.8571rem;
      border-radius: 6px;
      font-size: 1.1429rem;
      line-height: 36px;
      text-align: center;
      cursor: pointer;

      &:first-child {
        background: #2898ff;
        color: #ffffff;
        border: none;
        margin-right: 16px;
      }

      &:last-child {
        border: 1px solid #2898ff;
        color: #2898ff;
        background-color: #fff;
      }
    }
  }

  .footer-pagination {
    margin-top: 22px;
    text-align: right;
  }

  .input {
    width: 100%;
    height: 2.8571rem;
    background: #fafafa;
    position: relative;

    input {
      width: 100%;
      height: 36px;
      border: 1px solid #e6e6e6;
      outline: none;
      border-radius: 6px;
      padding-left: 26px;
    }

    span {
      font-size: 1.1429rem;
      color: #333333;
      line-height: 20px;
      position: absolute;
      top: 8px;
      left: 14px;
    }
  }
}

/deep/ .ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0px;
}
</style>
