<template>
  <Modal
    :isVisible="isShow"
    :isShowCancel="true"
    title="Creatives Images"
    @close-popup="hideSelect"
    width="834px"
    submitText="Save"
    cancelText="Discard"
    @submit="submitSelect"
  >
    <template #content>
      <a-spin :spinning="uplaoding">
        <div class="campilist">
          <div class="campilist-head">
            <a-upload
              name="imgFile"
              class="avatar-uploader"
              :show-upload-list="false"
              :before-upload="beforeUpload"
              @change="handleChangeUpload"
              :action="uploadUrl"
            >
              <a-button type="primary">
                <a-icon type="upload" /> Upload
              </a-button>
            </a-upload>
          </div>
          <div class="campilist-list">
            <ImageItem
              v-for="(item, index) in images"
              :key="`images-${item.id}-${index}`"
              :imgUrl="item.images[0].image_url"
              :cid="item.id"
              :title="item.title"
              :width="item.images[0].width"
              :height="item.images[0].height"
              :toChecked="item.toChecked"
              is-edit="1"
              @image-check="checkItem"
            />
          </div>
          <div class="footer-pagination">
            <a-pagination
              v-model="currentPage"
              :total="totalSize"
              :pageSize="pageSize"
              @change="paginationChange"
            />
          </div>
        </div>
      </a-spin>
    </template>
  </Modal>
</template>

<script>
import axios from "@/utils/request";
import Modal from "../../../../components/Modal.vue";
import ImageItem from "./ImageItem.vue";
import { uploadUrl } from "@/utils/config";
import APIS from "@/api/const";

export default {
  components: { Modal, ImageItem },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    cid: {
      type: String | Number,
      default: "0",
    },
  },
  computed: {},
  data() {
    return {
      isLoadingModal: false,
      uploadUrl,
      images: [],
      uplaoding: false,
      selectedList: _.cloneDeep(this.$store.state.campaignImages),
      totalSize: 1,
      currentPage: 1,
      pageSize: 9,
      imgWidth: 100,
      imgHeight: 100,
    };
  },
  watch: {
    isShow: {
      handler: function(newVal, oldVal) {
        if (newVal) {
          this.getDataList();
        }
      },
    },
    "$store.state.campaignImages": {
      handler: function(newVal, oldVal) {
        this.selectedList = _.cloneDeep(newVal);
      },
    },
  },
  created() {},
  methods: {
    checkItem(item, ischeck) {
      if (ischeck) {
        this.selectedList.push(item);
      } else {
        for (let i = 0; i < this.selectedList.length; i++) {
          if (this.selectedList[i].cid === item.cid) {
            this.selectedList.splice(i, 1);
            break;
          }
        }
      }
    },
    getDataList() {
      this.uplaoding = true;
      this.images = [];
      axios
        .get(APIS.CREATIVE.LIST, {
          params: {
            type: "IMAGE",
            page_num: this.currentPage,
            page_size: this.pageSize,
          },
        })
        .then((res) => {
          this.uplaoding = false;
          if (res.code === 0) {
            for (let i = 0; i < res.data.data.length; i++) {
              let isfind = false;
              for (let j = 0; j < this.selectedList.length; j++) {
                if (+res.data.data[i].id === +this.selectedList[j].cid) {
                  isfind = true;
                }
              }
              if (isfind) {
                res.data.data[i].toChecked = true;
              } else {
                res.data.data[i].toChecked = false;
              }
            }
            this.images = res.data.data;
            this.totalSize = parseInt(res.data.total_size);
          } else {
            this.$message.error(res.msg || "");
          }
        });
    },
    paginationChange(page, pageSize) {
      this.currentPage = page;
      this.getDataList();
    },
    submitSelect() {
      if (this.isEditing) {
        this.uplaoding = true;
        axios
          .post(APIS.CREATIVE.BATCH_MODIFY, {
            id: this.cid,
            creatives: {
              image: this.selectedList,
            },
          })
          .then((res) => {
            this.uplaoding = false;
            if (res.code === 0) {
              this.resetParams();
              this.$emit("on-refresh");
              this.$message.success("Save Success");
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        this.resetParams();
        this.$store.commit("setCampaignImages", this.selectedList);
        this.$emit("on-close");
      }
    },
    hideSelect() {
      this.resetParams();
      this.$emit("on-close");
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 3MB!");
      }
      let image = new Image();
      image.src = (
        window.createObjectURL ||
        window.URL?.createObjectURL ||
        window.webkitURL?.createObjectURL
      )(file);
      image.onload = () => {
        this.imgWidth = image.width;
        this.imgHeight = image.height;
      };
      return isJpgOrPng && isLt2M;
    },
    resetParams() {
      this.currentPage = 1;
      this.images = [];
    },
    handleChangeUpload(info) {
      if (info.file.status === "uploading") {
        this.logoLoading = true;
        this.uplaoding = true;
        return;
      }
      if (info.file.status === "done") {
        this.logoLoading = false;
        // this.uplaoding = false;
        const addData = {
          images: [
            {
              width: this.imgWidth,
              height: this.imgHeight,
              url: info.file.response.data.url,
              size: info.file.size,
            },
          ],
          start_time: Date.parse(new Date()) / 1000,
          end_time: Date.parse(new Date("2050-12-31")) / 1000,
          privacy: "PUBLIC",
          link: "creatives",
          type: "IMAGE",
        };
        axios.post(APIS.CREATIVE.CREATE, addData).then((res) => {
          this.uplaoding = false;
          if (res.code === 0) {
            this.$emit("refreshStats");
            this.$message.success("Add Success");
            this.currentPage = 1;
            this.getDataList();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
  },
};
</script>

<style lang="less">
.campilist {
  min-height: 100px;
  &-head {
    text-align: right;
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
  }
  .camptadd {
    p {
      font-size: 1.2857rem;
      font-weight: 600;
      color: #333333;
      margin-bottom: 3px;
      margin-top: 20px;
    }
  }
  .modal {
    z-index: 103;
  }
}
</style>
