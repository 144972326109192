<template>
  <div class="addpay">
    <Header collection="My Account"></Header>
    <Brands
      collection="Payment Methods"
      path="/account/payment-setting"
      subset="Add Payment Methods"
    ></Brands>
    <div class="container">
      <div class="addpay-item" @click="gotoAdd('add_payment_paypal')">
        <img src="@/assets/public/icon-paypal.svg" />
        <span>Paypal</span>
      </div>
      <div class="addpay-item" @click="gotoAdd('add_payment_bank')">
        <img src="@/assets/public/icon-express.svg" />
        <span>Bank Transfer</span>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/public/Header.vue";
import Brands from "@/components/Brands.vue";
import axios from "@/utils/request";
import moment from "moment-timezone";

export default {
  filters: {
    filterStaus(status) {
      if (status == "new") {
        return "Unpaid";
      } else if (status == "paid") {
        return "Paid";
      } else {
        return "Void";
      }
    },
  },
  components: {
    Header,
    Brands,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    gotoAdd(route) {
      this.$router.push({
        name: route,
      });
    },
  },
  beforeDestroy() {
    if (this.onWindowResize) {
      window.onresize = null;
    }
  },
};
</script>

<style lang="less" scoped>
.addpay {
  .container {
    height: 15.1429rem;
    background: #ffffff;
    border-radius: 1.1429rem;
    margin-top: 0.8571rem;
    padding: 3.2857rem 2.8571rem;
    display: flex;
    justify-content: space-around;
  }
  &-item {
    width: 36.4286rem;
    height: 8.5714rem;
    border-radius: 1.1429rem;
    border: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    span {
      font-size: 1.5714rem;
      font-family: "prefer-bold";
      margin-left: 1.2857rem;
    }
    &.active,
    &:hover {
      background: #f6f9ff;
      border: 1px solid #3762d6;
    }
  }
}
</style>
