<template>
  <a-spin :spinning="uplaoding">
    <div class="fileup">
      <div
        class="fileup-item"
        v-for="(file, index) in fileList"
        :key="`file-${index}`"
      >
        <span class="item-name">{{ file.name }}</span>
        <img src="@/assets/public/icon-delete.svg" @click="deleteFile(file)" />
      </div>

      <a-upload
        name="filename"
        :show-upload-list="false"
        :before-upload="beforeUpload"
        @change="handleChangeUpload"
        :action="uploadUrl"
        :headers="headers"
        :data="datas"
        accept=".doc,.docx,.txt,.pdf,.jpg,.png,jpeg"
      >
        <div class="fileup-up">
          <span class="fileup-tip">Upload Media Kit Max file size: 10mb</span>
          <img src="@/assets/public/icon-upload.svg" />
        </div>
      </a-upload>
      <div class="disabled" v-if="disabled"></div>
    </div>
  </a-spin>
</template>

<script>
import _ from "lodash";
import { uploadUrl } from "@/utils/config";
import apis from "@/api/const";

export default {
  components: {},
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  data() {
    return {
      uplaoding: false,
      uploadUrl,
      headers: {
        "Lp-Authorization": this.$store.state.token,
      },
      datas: {
        type: "common",
      },
      fileList: [],
    };
  },
  watch: {
    fileList(newVal, oldVal) {
      //   this.triggerChange(newVal);
      this.$emit("fileChange", newVal);
    },
  },
  created() {},
  methods: {
    deleteFile(file) {
      const delIndex = _.findIndex(this.fileList, (item) => {
        return item.src === file.src;
      });
      this.fileList.splice(delIndex, 1);
    },
    beforeUpload(file) {
      console.log("file:", file.type);
    },
    handleChangeUpload(info) {
      if (info.file.status === "uploading") {
        this.logoLoading = true;
        this.uplaoding = true;
        return;
      }
      if (info.file.status === "done") {
        this.uplaoding = false;
        this.logoLoading = false;
        if (info.file.response.code === "0200") {
          this.fileList.push(info.file.response.payload);
        } else {
          this.$message.error(info.file.response.msg);
        }
      }
    },
    // triggerChange(changedVal) {
    //   this.$emit("fileChange", changedVal);
    // },
  },
};
</script>

<style lang="less" scoped>
.fileup {
  margin-top: 12px;
  position: relative;
  .disabled {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  &-item {
    background: #defce1;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    padding: 0 14px;
    height: 3.4286rem;
    align-items: center;
    + div {
      margin-top: 12px;
    }
    span {
      color: #333333;
    }
    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  &-up {
    height: 3.4286rem;
    border-radius: 8px;
    border: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px;
    margin-top: 12px;
    cursor: pointer;
    span {
      color: #999999;
    }
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
      &:hover {
        opacity: 0.85;
      }
    }
  }
  /deep/.ant-upload {
    width: 100%;
  }
}
</style>
