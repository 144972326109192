<template>
  <div class="taginput">
    <div class="taginput-list">
      <a-tag
        v-for="(item, index) in tagList"
        :key="`tag-input-${item}-${index}`"
        closable
        class="taginput-item"
        @close="deleteTag(item)"
      >
        <span>{{ tagText }}</span> {{ item }}
      </a-tag>
    </div>
    <a-input
      @keyup.enter="addTag"
      v-model="textValue"
      placeholder="Enter to confirm"
    />
  </div>
</template>

<script>
import _ from "lodash";

export default {
  components: {},
  props: {
    value: {
      type: String,
      default: "",
    },
    tagText: {
      type: String,
      default: "",
    },
  },
  computed: {},
  data() {
    return {
      tagList: this.value ? this.value.split(",") : [],
      textValue: "",
    };
  },
  watch: {},
  created() {},
  methods: {
    addTag() {
      if (this.textValue) {
        this.tagList.push(this.textValue);
        this.textValue = "";
        this.triggerChange(this.tagList);
      }
    },
    triggerChange(changedValue) {
      // Should provide an event to pass value to Form.
      this.$emit("change", changedValue.join(","));
    },
    deleteTag(tag) {
      this.tagList.splice(this.tagList.indexOf(tag), 1);
      this.triggerChange(this.tagList);
    },
  },
};
</script>

<style lang="less" scoped>
.taginput {
  border: 1px solid #d9d9d9;
  display: flex;
  width: 100%;
  border-radius: 4px;
  flex-wrap: wrap;
  &-list {
    display: flex;
    flex-wrap: wrap;
    overflow-x: auto;
    align-items: center;
    padding: 5px 0 0;
  }
  &-item {
    margin-left: 8px;
    margin-right: 0;
    margin-bottom: 5px;
    > span {
      color: #2898ff;
    }
  }
  input {
    width: 200px;
    height: 32px;
    line-height: 32px;
    border: none !important;
    outline: none !important;
    background: none !important;
    &:focus {
      box-shadow: none;
    }
  }
}
</style>
