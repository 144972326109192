<template>
  <div class="mybasic">
    <Header collection="My Account"></Header>
    <div class="container">
      <a-spin :spinning="isLoading">
        <div class="headers">
          <div class="headers-left">
            <img
              v-if="!isEditTop && basicInfo.avatar"
              :src="basicInfo.avatar"
            />
            <div
              v-else-if="!isEditTop && !basicInfo.avatar"
              class="avatar-upload"
              @click="isEditTop = true"
            >
              <img class="avatar-upload-img" v-if="avator" :src="avator" />
              <div class="avatar-upload-btn">
                <img src="@/assets/public/icon-plus.svg" />
              </div>
            </div>
            <div v-else class="avatar-upload">
              <a-spin :spinning="uplaoding">
                <a-upload
                  name="filename"
                  :show-upload-list="false"
                  @change="handleChangeUpload"
                  :action="uploadUrl"
                  :headers="headers"
                  :data="{ type: 'image' }"
                  accept=".jpg,.png,.jpeg"
                >
                  <img
                    class="avatar-upload-img"
                    v-if="avator || basicInfo.avatar"
                    :src="avator || basicInfo.avatar"
                  />
                  <div v-else class="avatar-upload-btn">
                    <img src="@/assets/public/icon-plus.svg" />
                  </div>
                </a-upload>
              </a-spin>
            </div>
          </div>
          <div class="headers-right">
            <ul v-if="isEditTop" class="right-info">
              <li>
                <span class="info-title">Display Name</span>
                <div class="info-title-left">
                  <a-input
                    class="info-title-input"
                    v-model="basicInfo.display_name"
                  ></a-input>
                  <div class="info-title-edits">
                    <span class="info-title-cancel" @click="isEditTop = false"
                      >Cancel</span
                    >
                    <span class="info-title-save" @click="saveTopInfo"
                      >Save</span
                    >
                  </div>
                </div>
              </li>
              <li>
                <span class="info-title">Personal Bio</span>
                <a-textarea
                  class="info-title-area"
                  :auto-size="{ minRows: 5, maxRows: 5 }"
                  v-model="basicInfo.personal_bio"
                />
              </li>
            </ul>
            <ul v-else class="right-info">
              <li>
                <span class="info-title">Display Name</span>
                <div class="info-title-left">
                  <span class="info-title-text">{{
                    basicInfo.display_name
                  }}</span
                  ><span class="info-title-edit" @click="handleEdit">Edit</span>
                </div>
              </li>
              <li>
                <span class="info-title">Personal Bio</span>
                <span class="info-text">{{ basicInfo.personal_bio }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="content">
          <div class="content-title">
            <span class="content-title-text">Basic Info</span>
            <div v-if="isEditInfo" class="basic-edit">
              <span class="basic-cancel" @click="isEditInfo = false"
                >Cancel</span
              >
              <a-button class="btn-sm" type="primary" @click="saveBasicInfo"
                >Save</a-button
              >
            </div>
            <span v-else class="content-edit" @click="isEditInfo = true"
              >Edit</span
            >
          </div>
          <div v-if="isEditInfo" class="content-form">
            <a-form
              :form="formInfo"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 20 }"
            >
              <a-row :gutter="24">
                <a-col :span="12">
                  <a-form-item
                    label="First Name"
                    labelAlign="left"
                    :colon="false"
                  >
                    <a-input
                      v-decorator="[
                        `first_name`,
                        {
                          initialValue: basicInfo.first_name,
                          rules: [
                            {
                              required: true,
                              message: 'Please enter first name',
                            },
                          ],
                        },
                      ]"
                      placeholder=""
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item
                    label="Last Name"
                    labelAlign="left"
                    :colon="false"
                  >
                    <a-input
                      v-decorator="[
                        `last_name`,
                        {
                          initialValue: basicInfo.last_name,
                          rules: [
                            {
                              required: true,
                              message: 'Please enter last Name ',
                            },
                          ],
                        },
                      ]"
                      placeholder=""
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row :gutter="24">
                <a-col :span="12">
                  <a-form-item label="Gender" labelAlign="left" :colon="false">
                    <a-select
                      v-decorator="[
                        'gender',
                        {
                          initialValue: basicInfo.gender,
                          rules: [
                            {
                              required: true,
                              message: 'Please choose your gender',
                            },
                          ],
                        },
                      ]"
                      placeholder="Select gender"
                    >
                      <a-select-option value="1"> Male </a-select-option>
                      <a-select-option value="0"> Female </a-select-option>
                      <a-select-option value="2">
                        I prefer not to disclose
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item
                    label="Date of Birth"
                    labelAlign="left"
                    :colon="false"
                  >
                    <a-date-picker
                      style="width:35rem;"
                      v-decorator="[
                        'birthday',
                        {
                          initialValue: basicInfo.birthday
                            ? moment(basicInfo.birthday)
                            : undefined,

                          rules: [
                            {
                              required: true,
                              message: 'Please choose your birthday',
                            },
                          ],
                        },
                      ]"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row :gutter="24">
                <a-col :span="12">
                  <a-form-item
                    label="Location"
                    labelAlign="left"
                    :colon="false"
                  >
                    <a-auto-complete
                      class="form-location"
                      style="width: 200px"
                      placeholder="input here"
                      @select="onLocationSelect"
                      @search="onLocationSearch"
                      v-decorator="[
                        'country_id',
                        {
                          initialValue: country_id,
                          rules: [
                            {
                              required: true,
                              message: 'Please choose your country',
                            },
                          ],
                        },
                      ]"
                    >
                      <template slot="dataSource">
                        <a-select-option
                          v-for="item in showList"
                          :key="item.id"
                          :text="item.name_en"
                        >
                          {{ item.name_en }}
                        </a-select-option>
                      </template></a-auto-complete
                    >
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="" labelAlign="left" :colon="false">
                    <a-input
                      style="width:42.01rem"
                      placeholder="State/Province/Region"
                      v-decorator="[
                        `state`,
                        {
                          initialValue: basicInfo.state,
                          rules: [
                            {
                              required: true,
                              message: 'Please input State/Province/Region',
                            },
                          ],
                        },
                      ]"
                    ></a-input>
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row :gutter="24">
                <a-col class="form-line" :span="12">
                  <a-input
                    placeholder="City"
                    v-model="basicInfo.city"
                  ></a-input>
                </a-col>
                <a-col :span="12">
                  <a-input
                    placeholder="zip code"
                    v-model="basicInfo.zip_code"
                  ></a-input>
                </a-col>
              </a-row>
              <a-row :gutter="24">
                <a-col class="form-line from-alone" :span="24">
                  <a-input placeholder="Address 1" v-model="addr_1"></a-input>
                </a-col>
              </a-row>
              <a-row :gutter="24">
                <a-col class="form-line from-alone" :span="24">
                  <a-input placeholder="Address 2" v-model="addr_2"></a-input>
                </a-col>
              </a-row>
              <a-row class="from-alone" :gutter="24">
                <a-col :span="24">
                  <a-spin :spinning="uplaoding">
                    <a-form-item
                      label="Media Kit"
                      labelAlign="left"
                      :colon="false"
                      :labelCol="{ span: 2, offset: 0 }"
                    >
                      <div
                        class="form-download"
                        v-for="(item, index) in mediaList"
                        :key="`media-edit-${index}`"
                      >
                        <div style="display:flex;align-items: center;">
                          <a
                            class="download-left"
                            :href="basicInfo.cdn_pre + item.file_path"
                            target="_blank"
                          >
                            <span>{{ item.file_name }}</span>
                            <img src="@/assets/my/icon-download.svg" />
                          </a>
                          <a-icon
                            type="delete"
                            @click="handleDelete(item)"
                            style="color:#000;margin-left: 16px;margin-top: 3px;"
                          />
                        </div>
                        <span class="download-right">
                          {{ item.up_time }} Uploaded
                        </span>
                      </div>
                      <div class="form-add">
                        <a-upload
                          name="filename"
                          :show-upload-list="false"
                          @change="handleMediaChangeUpload"
                          :action="uploadUrl"
                          :headers="headers"
                          :data="{ type: 'common' }"
                          accept=".pdf,.txt,.doc,.docx,.jpg,.png,.jpeg"
                        >
                          <span class="add-btn">+ Add New</span>
                        </a-upload>
                      </div>
                      <a-input
                        style="display:none"
                        v-decorator="[
                          'upload',
                          {
                            initialValue:
                              basicInfo.media_kit &&
                              basicInfo.media_kit.length > 0
                                ? '1'
                                : undefined,
                            rules: [
                              {
                                required: true,
                                message: 'Please upload file',
                              },
                            ],
                          },
                        ]"
                      ></a-input>
                    </a-form-item>
                  </a-spin>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <div v-else class="basic-info">
            <ul class="basic-list">
              <li class="basic-item">
                <span class="item-left">First Name</span>
                <span class="item-right">
                  <span>{{ basicInfo.first_name }}</span>
                  <span></span>
                </span>
              </li>
              <li class="basic-item">
                <span class="item-left">Last Name</span>
                <span class="item-right">
                  <span>{{ basicInfo.last_name }}</span>
                  <span></span>
                </span>
              </li>
              <li class="basic-item">
                <span class="item-left">Gender</span>
                <span class="item-right">
                  <span>{{ basicInfo.gender | renderGender }}</span>
                  <span></span>
                </span>
              </li>
              <li class="basic-item">
                <span class="item-left">Date of Birth</span>
                <span class="item-right">
                  <span>{{ basicInfo.birthday }}</span>
                  <span></span>
                </span>
              </li>
              <li class="basic-item all-line">
                <span class="item-left">Location</span>
                <span class="item-right item-right-loc">
                  <div class="flex_">
                    <div class="flex_first">{{ basicInfo.country }}</div>
                    <div>{{ basicInfo.city }}</div>
                  </div>
                  <div class="flex_">
                    <div class="flex_first">{{ basicInfo.state }}</div>
                    <div>{{ basicInfo.zip_code }}</div>
                  </div>
                  <div class="flex_">{{ addr_1 }}</div>
                  <div>{{ addr_2 }}</div>
                </span>
              </li>
              <li class="basic-item">
                <span class="item-left">Media Kit</span>
                <span class="item-right">
                  <div
                    v-for="(item, index) in basicInfo.media_kit"
                    :key="`media-${index}`"
                    class="item-down"
                  >
                    <div class="item-icon">
                      <a
                        :href="basicInfo.cdn_pre + item.file_path"
                        target="_blank"
                        >{{ item.file_name
                        }}<img src="@/assets/public/icon-download.svg"
                      /></a>
                    </div>
                    <span>{{ item.up_time }}</span>
                  </div>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="footer">
          <div class="footer-left">
            Invite Code
          </div>
          <div class="footer-right">
            {{ basicInfo.invite_code }}
            <a-button @click="copyCode" type="primary">
              Copy My Code
            </a-button>
          </div>
        </div>
      </a-spin>
    </div>
  </div>
</template>
<script>
import Header from "@/components/public/Header.vue";
import { uploadUrl } from "@/utils/config";
import apis from "@/api/const";
import axios from "@/utils/request";
import _ from "lodash";
import moment from "moment-timezone";

export default {
  components: {
    Header,
  },
  data() {
    return {
      moment,
      formInfo: this.$form.createForm(this, { name: "form-info" }),
      showList: this.$store.state.countryList,
      isEditTop: false,
      isEditInfo: false,
      uploadUrl,
      headers: {
        "Lp-Authorization": this.$store.state.token,
      },
      avator: "",
      avator_src: "",
      uplaoding: false,
      isLoading: false,
      userInfo: {},
      basicInfo: {
        gender: null,
      },
      country_name: "",
      mediaList: [],
      mediaSrcs: [],
      addr_1: "",
      addr_2: "",
      country_id: "",
    };
  },
  mounted() {
    this.getBasicInfo();
  },
  methods: {
    handleEdit() {
      this.isEditTop = true;
    },
    handleDelete(item) {
      this.mediaList = this.mediaList.filter(
        (e) => e.file_name !== item.file_name
      );
      this.mediaSrcs = this.mediaSrcs.filter((path) => path !== item.file_path);
      if (this.mediaList.length < 1) {
        this.formInfo.setFieldsValue({ upload: "" }); //表示已填
      }
    },
    getBasicInfo() {
      this.isLoading = true;
      axios.get(apis.my.basic_info, {}).then((res) => {
        this.isLoading = false;
        if (res.code === "0200") {
          this.basicInfo = {
            ...res.payload,
            birthday: res.payload.birthday ? res.payload.birthday : null,
          };
          console.log(this.basicInfo);
          this.mediaList = this.basicInfo.media_kit || [];
          this.mediaSrcs =
            this.basicInfo.media_kit?.map((file) => {
              return file.file_path;
            }) || [];
          const countryObj = _.find(this.$store.state.countryList, (item) => {
            return item.name_en === this.basicInfo.country;
          });
          if (countryObj) {
            this.country_id = countryObj.id;
            this.country_name = countryObj.name_en;
          }
          this.addr_1 = this.basicInfo.addr?.[0] || "";
          this.addr_2 = this.basicInfo.addr?.[1] || "";
        } else {
          this.$message.error(res.msg || "");
        }
      });
    },
    getUserInfo() {
      axios.get(apis.my.user_info, {}).then((res) => {
        if (res.code === "0200") {
          this.$store.commit("setUserInfo", res.payload);
        } else {
          this.$message.error(res.msg || "");
        }
      });
    },
    saveTopInfo() {
      this.isLoading = true;
      const topData = {
        display_name: this.basicInfo.display_name,
        personal_bio: this.basicInfo.personal_bio,
        formData: true,
      };
      let initurl = "/data" + this.basicInfo.avatar?.split("/data")?.[1];
      topData.avatar = initurl || "";
      if (this.avator_src) {
        topData.avatar = "/" + this.avator_src;
      }
      axios.post(apis.my.edit_top, topData).then((res) => {
        this.isLoading = false;
        if (res.code == "0200") {
          this.isEditTop = false;
          this.getBasicInfo();
          this.getUserInfo();
          this.$message.success("Saved Successfully");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    saveBasicInfo() {
      this.isLoading = true;
      this.formInfo.validateFields((err, formDatas) => {
        if (err) {
          this.isLoading = false;
          console.log(err, "err");
          return;
        }
        const infoData = {
          ...formDatas,
          province: formDatas.state,
          region: formDatas.state,
          country: this.country_name,
          city: this.basicInfo.city,
          zip_code: this.basicInfo.zip_code,
          addr: JSON.stringify([this.addr_1, this.addr_2]),
          media_kit: JSON.stringify(this.mediaSrcs),
          birthday: formDatas.birthday
            ? this.dayjs(formDatas.birthday).format("YYYY-MM-DD")
            : undefined,
          formData: true,
        };
        console.log("basic:", infoData);
        axios.post(apis.my.edit_basic, infoData).then((res) => {
          this.isLoading = false;
          if (res.code == "0200") {
            this.isEditInfo = false;
            this.getBasicInfo();
            this.$message.success("Saved Successfully");
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    handleChangeUpload(info) {
      if (info.file.status === "uploading") {
        this.logoLoading = true;
        this.uplaoding = true;
        return;
      }
      if (info.file.status === "done") {
        this.uplaoding = false;
        this.logoLoading = false;
        if (info.file.response.code === "0200") {
          this.avator =
            info.file.response.payload.prefix + info.file.response.payload.src;
          this.avator_src = info.file.response.payload.src;
        } else {
          this.$message.error(info.file.response.msg);
        }
      }
    },
    handleMediaChangeUpload(info) {
      if (info.file.status === "uploading") {
        this.logoLoading = true;
        this.uplaoding = true;
        return;
      }
      if (info.file.status === "done") {
        this.uplaoding = false;
        this.logoLoading = false;
        if (info.file.response.code === "0200") {
          this.mediaList.push({
            file_path: info.file.response.payload.src,
            file_name: info.file.response.payload.name,
            up_time: "",
          });
          this.mediaSrcs.push(info.file.response.payload.src);
          this.formInfo.setFieldsValue({ upload: "1" }); //表示已填
        } else {
          this.$message.error(info.file.response.msg);
        }
      }
    },
    onDateChange() {},
    onLocationSearch(searchText) {
      this.showList = this.$store.state.countryList.filter((item) => {
        if (item.name_en.toUpperCase().indexOf(searchText.toUpperCase()) > -1) {
          return item;
        }
      });
    },
    onLocationSelect(value, option) {
      this.country_name = option.data.attrs.text;
    },
    copyCode() {
      if (!this.basicInfo.personal_bio || !this.basicInfo.display_name) {
        this.$message.warning(
          "Please fill in your Display Name and Personal Bio first"
        );
        return;
      }
      this.$copyText(this.basicInfo.invite_code).then(
        (e) => {
          this.$message.success("Copied");
        },
        (e) => {
          this.$message.error("Can not copy");
        }
      );
    },
  },
  beforeDestroy() {
    if (this.onWindowResize) {
      window.onresize = null;
    }
  },
};
</script>

<style lang="less" scoped>
.mybasic {
  .headers {
    // height: 14.2857rem;
    background: #ffffff;
    box-shadow: 0px 0px 12px 0px rgba(102, 118, 186, 0.1);
    padding: 1.7143rem 2.2857rem;
    border-radius: 1.1429rem;
    margin-top: 0.8571rem;
    display: flex;
    &-left {
      > img {
        width: 10.8571rem;
        height: 10.8571rem;
        border-radius: 50%;
        object-fit: cover;
      }
      .avatar-upload {
        width: 10.8571rem;
        height: 10.8571rem;
        background: #f5f9fe;
        border: 1px dashed #c9cdd3;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
        &-img {
          width: 10.8571rem;
          height: 10.8571rem;
          object-fit: cover;
          border-radius: 50%;
        }
        &-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 10.8571rem;
          height: 10.8571rem;
        }
      }
    }
    &-right {
      margin-left: 1.8571rem;
      width: 100%;
    }
    .right-info {
      li {
        margin-bottom: 12px;
        display: flex;
        min-height: 2rem;
        .info-title {
          color: #999999;
          width: 7.3571rem;
          flex-shrink: 0;
          &-left {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
        .info-title-text {
          color: #333333;
          font-family: "prefer-bold";
        }
        .info-title-edit {
          color: #3762d6;
          cursor: pointer;
        }

        .info-title-edits {
          display: flex;
          align-items: center;
          .info-title-cancel {
            cursor: pointer;
            color: #999999;
          }
          .info-title-save {
            height: 1.8571rem;
            background: #3762d6;
            border-radius: 0.2857rem;
            width: 4.4286rem;
            text-align: center;
            line-height: 1.8571rem;
            cursor: pointer;
            color: #fff;
            margin-left: 20px;
          }
        }

        .info-text {
          color: #333333;
          line-height: 1.4286rem;
          font-family: "prefer-bold";
        }
        .info-title-input {
          width: 14.2857rem;
        }
      }
      li:first-child {
        align-items: center;
      }
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    margin-top: 1.4286rem;
    background: #ffffff;
    box-shadow: 0px 0px 12px 0px rgba(102, 118, 186, 0.1);
    border-radius: 1.1429rem;
    flex-direction: column;
    padding: 1.7143rem 2.2857rem;
    &-title {
      display: flex;
      justify-content: space-between;
      &-text {
        font-size: 1.2857rem;
        color: #333333;
        font-family: "prefer-bold";
      }
      .content-edit {
        color: #3762d6;
        cursor: pointer;
      }
    }
    .basic-edit {
      display: flex;
      align-items: center;
      .basic-cancel {
        cursor: pointer;
        color: #999999;
      }
      .btn-sm {
        height: 2.1429rem;
        margin-left: 20px;
      }
    }
    &-form {
      margin-top: 1.1429rem;
      .form-line {
        padding-left: 7.8571rem !important;
      }
      .from-alone {
        margin-top: 24px;
      }
      .form-download {
        display: flex;
        justify-content: space-between;
        padding: 0 1.2857rem;
        align-items: center;
        width: 79.1429rem;
        margin-left: -2px;
        height: 2.8571rem;
        border-radius: 0.2857rem;
        border: 1px solid #d9d9d9;
        margin-bottom: 0.8571rem;
        .download-left {
          display: flex;
          align-items: center;
          span {
            color: #3762d6;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
          img {
            width: 1.0714rem;
            height: 1.0714rem;
            margin-left: 0.4286rem;
          }
        }
      }
      .form-add {
        height: 2.8571rem;
        border-radius: 4px;
        border: 1px solid #d9d9d9;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 79.1429rem;
        margin-left: -2px;
        > span {
          cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
        }
        .add-btn {
          display: block;
          width: 1108px;
          text-align: center;
          height: 2.8571rem;
          line-height: 2.8571rem;
        }
      }
    }

    .basic-info {
      .basic-list {
        display: flex;
        flex-wrap: wrap;
      }
      .basic-item {
        width: 50%;
        display: flex;
        margin-top: 1.4286rem;
        .item-left {
          color: #999999;
          width: 8.5714rem;
          flex-shrink: 0;
        }
        .item-right {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          > span {
            width: 50%;
            margin-bottom: 9px;
            font-family: "prefer-bold";
            &.sm-line {
              width: 30%;
            }
          }
          .item-down {
            display: flex;
            justify-content: space-between;
            width: 35.7143rem;
            margin-bottom: 0.8571rem;
            > div {
              a {
                font-family: "prefer-bold";
                color: #3762d6;
              }
              img {
                margin-left: 12px;
              }
            }
            span {
              color: #999999;
            }
          }
          .item-icon {
            display: flex;
            align-items: center;
          }
        }
        .item-right-loc {
          display: flex;
          flex-direction: column;
        }
        .flex_ {
          display: flex;
          margin-bottom: 12px;
          .flex_first {
            width: 12.8571rem;
          }
        }
        &.all-line {
          width: 100%;
        }
      }
    }
  }
  .footer {
    background: #ffffff;
    box-shadow: 0px 0px 12px 0px rgba(102, 118, 186, 0.1);
    padding: 1.7143rem 2.2857rem;
    border-radius: 1.1429rem;
    margin-top: 1.1429rem;
    display: flex;
    .footer-left {
      color: #999999;
      width: 8.5714rem;
      flex-shrink: 0;
      display: flex;
      align-items: center;
    }
    .footer-right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
    }
  }
  /deep/ .ant-form-item-no-colon {
    color: #999999;
  }
  /deep/ .ant-input {
    height: 2.8571rem;
  }

  /deep/.ant-btn-primary {
    min-width: 70px;
  }
  /deep/.ant-select-search__field {
    height: 2.8571rem !important;
    width: 35.2143rem;
  }
}
</style>
