<template>
  <ul class="campprev" v-if="item">
    <li class="campprev__item">
      <span class="campprev__item__title"
        >Name <span :class="isOver ? 'red' : ''">*</span></span
      >
      <div class="campprev__item__content">{{ item.name }}</div>
    </li>
    <li class="campprev__item">
      <span class="campprev__item__title"
        >Platforms <span :class="isOver ? 'red' : ''">*</span></span
      >
      <div class="campprev__item__content">
        <span
          class="campprev__item__platform"
          v-for="(platform, index) in platforms"
          :key="`platform-${index}`"
          ><img :src="platform.icon" />{{ platform.name }}</span
        >
      </div>
    </li>
    <li class="campprev__item">
      <span class="campprev__item__title"
        >Budget({{ item.currency_sign }} {{ item.currency_name }})
        <span :class="isOver ? 'red' : ''">*</span></span
      >
      <div class="campprev__item__content">
        {{ item.currency_sign }} {{ item.amount_total | toThousandsAndFixed2 }}
      </div>
    </li>
    <li class="campprev__item">
      <span class="campprev__item__title"
        >Duration <span :class="isOver ? 'red' : ''">*</span></span
      >
      <div class="campprev__item__content">
        <span class="campprev__item__content__sm">From</span
        ><span class="campprev__item__content__normal">{{
          item.start_time | formatTimestamp
        }}</span
        ><span class="campprev__item__content__sm">To</span
        ><span class="campprev__item__content__normal">{{
          item.end_time | formatTimestamp
        }}</span>
      </div>
    </li>
    <li class="campprev__item">
      <span class="campprev__item__title"
        >Description <span :class="isOver ? 'red' : ''">*</span></span
      >
      <div class="campprev__item__content">
        <span class="campprev__item__content__normal">{{
          item.description
        }}</span>
      </div>
    </li>
    <li class="campprev__item">
      <span class="campprev__item__title"
        >Goal <span :class="isOver ? 'red' : ''">*</span></span
      >
      <div class="campprev__item__content">
        <span class="campprev__item__content__normal">{{
          item.objective
        }}</span>
      </div>
    </li>
  </ul>
</template>

<script>
import { PLATFORMS } from "@/api/const";
export default {
  props: {
    item: {
      type: Object,
      default: () => null,
    },
    isOver: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {},
  watch: {},
  computed: {
    platforms() {
      let res = [];
      for (let p of this.item.platforms) {
        for (let ps of PLATFORMS) {
          if (p === ps.name) {
            res.push(ps);
          }
        }
      }
      return res;
    },
  },
};
</script>

<style lang="less" scoped>
.campprev {
  margin-top: 16px;
  &__item {
    display: flex;
    margin-top: 20px;
    &__title {
      width: 200px;
      color: #999999;
      .red {
        color: red;
      }
    }
    &__content {
      width: calc(100% - 200px);
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      &__sm {
        font-size: 0.8571rem;
        color: #333333;
        font-weight: lighter;
        margin-right: 10px;
      }
      &__normal {
        margin-right: 10px;
      }
      &__bg {
        margin-right: 10px;
        font-weight: 600;
      }
    }
    &__platform {
      margin-right: 20px;
      display: flex;
      align-items: center;
      width: 96px;
      margin-bottom: 8px;
      img {
        width: 18px;
        margin-right: 6px;
      }
    }
  }
}
</style>
