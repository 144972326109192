<template>
  <div class="emailset">
    <Header collection="My Account"></Header>
    <div class="container">
      <div class="headers"></div>
      <div class="content">
        <div class="comm-tables">
          <div class="comm-header">
            <div class="comm-header-left">
              <span>Email Settings</span>
              <div class="emailText">
                <div class="emailText-tips">
                  Accepting
                  <span>Status Update Emails</span>
                  will allow you to receive notification emails regarding
                  brand’s status and adjust your promotional strategy
                  accordingly.
                </div>
              </div>
              <ul class="recieve-list">
                <li>
                  <span>Receive email at</span>
                  <div class="recieve-emails">
                    <a-input
                      size="large"
                      v-for="(item, index) in emailList"
                      :key="`email-${index}`"
                      v-model="item.email"
                    ></a-input>
                    <!-- <a-input size="large"></a-input> -->
                  </div>
                </li>
                <li style="margin-top:4px">
                  <span></span>
                  <a-button class="recieve-add" @click="addEmail"
                    ><a-icon type="plus" />Add</a-button
                  >
                </li>
              </ul>
            </div>
            <div class="comm-header-right">
              <!-- <a-input placeholder="D"></a-input> -->
              <a-auto-complete
                class="form-location"
                style="width: 200px"
                placeholder="Search for Brand Name or ID"
                @select="onAdverSelect"
                @search="onAdverSearch"
                v-model="m_id"
                @blur="getDataList"
              >
                <template slot="dataSource">
                  <a-select-option
                    v-for="item in adverList"
                    :key="item.m_id"
                    :text="item.sitename"
                  >
                    {{ item.sitename }}
                  </a-select-option>
                </template></a-auto-complete
              >
              <div>
                <span> {{ selectedRowKeys.length }} Brands Selected </span
                ><a-button
                  type="primary"
                  :loading="btnLoading"
                  @click="batchSave"
                  >Save</a-button
                >
              </div>
            </div>
          </div>
          <div class="comm-list">
            <a-table
              class="comtable"
              :columns="columns"
              :data-source="dataLists"
              :rowKey="(record, index) => record.m_id"
              :loading="isLoading"
              :pagination="false"
              :row-selection="{
                columnTitle: 'Status Update Emails',
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
                columnWidth: 200,
              }"
            >
              <div class="comm-platforms" slot="platforms" slot-scope="text">
                <img
                  src="https://cdn.partnerboost.com/static/images/icon-facebook.svg"
                />
              </div>
              <div class="comm-status" slot="status" slot-scope="text">
                <span
                  class="comm-status-verifying"
                  v-show="text === 'Verifying'"
                  >{{ text }}</span
                >
                <span
                  class="comm-status-approved"
                  v-show="text === 'Approved'"
                  >{{ text }}</span
                >
                <span
                  class="comm-status-rejected"
                  v-show="text === 'Rejected'"
                  >{{ text }}</span
                >
              </div>
              <!-- <div class="comm-actions" slot="actions" slot-scope="text, row">
                <a-checkbox @change="onTbSelectChange"> </a-checkbox>
              </div> -->
            </a-table>
            <div class="footer-pagination">
              <a-pagination
                v-model="currentPage"
                :total="totalCount"
                show-less-items
                @change="paginationChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/public/Header.vue";
import axios from "@/utils/request";
import apis from "@/api/const";

export default {
  components: {
    Header,
  },
  data() {
    let columns = [
      {
        title: "Brand Name",
        dataIndex: "sitename",
        width: 460,
        align: "center",
      },
      {
        title: "Brand ID",
        dataIndex: "id",
        // width: 310,
        align: "center",
      },
    ];
    return {
      columns,
      dataLists: [],
      isLoading: false,
      totalCount: 0,
      currentPage: 1,
      page_size: 20,
      selectedRowKeys: [],
      originalKeys: [],
      deletedKeys: [],
      emailList: [],
      isFirst: true,
      btnLoading: false,
      m_id: "",
      adverList: [],
    };
  },
  mounted() {
    this.getDataList();
  },
  computed: {},
  methods: {
    onAdverSearch(searchText) {
      let adData = {
        params: {
          keywords: searchText,
        },
      };
      axios.get(apis.my.email_search, adData).then((res) => {
        // this.isLoading = false;
        if (res.code === "0200") {
          this.adverList = res.payload;
        } else {
          this.$message.error(res.msg || "");
        }
      });
    },
    onAdverSelect(value, option) {
      this.getDataList();
    },
    addEmail() {
      this.emailList.push({
        email: "",
      });
    },
    batchSave() {
      this.btnLoading = true;
      const noNeed = this.originalKeys.filter((key) => {
        return this.selectedRowKeys.indexOf(key) < 0;
      });
      const emals = this.emailList.filter((em) => {
        if (em.email.trim() !== "") {
          return em;
        }
      });
      const emailStrs = emals.map((em) => {
        return em.email;
      });
      const emailData = {
        email: emailStrs.join(","),
        need_mid: this.selectedRowKeys.join(","),
        no_need_mid: noNeed.join(","),
        formData: true,
      };
      console.log("数据：", emailData);
      axios.post(apis.my.email_save, emailData).then((res) => {
        this.btnLoading = false;
        if (res.code == "0200") {
          this.$message.success("Save Success.");
          this.isFirst = true;
          this.getDataList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    // onTbSelectChange() {},
    // 获取列表
    getDataList() {
      this.isLoading = true;
      axios
        .get(apis.my.email_list, {
          params: {
            ids: this.m_id,
            page_size: this.page_size,
            page: this.currentPage,
          },
        })
        .then((res) => {
          this.isLoading = false;
          if (res.code === "0200") {
            this.dataLists = res.payload.all_merchant;
            this.originalKeys = res.payload.merchant.map((item) => {
              return item.m_id;
            });
            this.selectedRowKeys = this.originalKeys;
            this.totalCount = parseInt(res.payload.total);
            if (this.isFirst) {
              this.isFirst = false;
              this.emailList = res.payload.base_info || [
                {
                  email: "",
                },
              ];
            }
          } else {
            this.$message.error(res.msg || "");
          }
        });
    },
    // 切换下一页
    paginationChange(page, pageSize) {
      this.currentPage = page;
      this.getDataList();
    },
  },
  beforeDestroy() {
    if (this.onWindowResize) {
      window.onresize = null;
    }
  },
};
</script>

<style lang="less" scoped>
.emailset {
  .emailText {
    background: url(~@/assets/my/email-setting-tips.png) no-repeat;
    background-size: 100% 100%;
    // width: 75.7143rem;
    height: 7.8571rem;
    border-radius: 8px;
    margin: 1.2857rem 0;
    color: #fff;
    display: flex;
    padding-left: 2.8571rem;
    align-items: center;
    &-tips {
      width: 55.3571rem;
      font-size: 1.2857rem;
      font-family: "Helvetica";
      font-weight: 200;
      span {
        font-family: "prefer-bold";
      }
    }
    &-icon {
      width: 8.5714rem;
      height: 6.4286rem;
    }
  }
  .headers {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 0.8571rem;
  }
  .content {
    .comm {
      &-actions {
        display: flex;
        justify-content: center;
        img {
          width: 1.1429rem;
          height: 1.1429rem;
          cursor: pointer;
          &:hover {
            opacity: 0.85;
          }
          + img {
            margin-left: 1rem;
          }
        }
      }
      &-status {
        &-verifying {
          color: #999999;
          background: #eeeeee;
        }
        &-approved {
          color: #ffffff;
          background: #2ad57b;
        }
        &-rejected {
          color: #ffffff;
          background: #f64a47;
        }
        span {
          padding: 0.2857rem 0.5714rem;
          border-radius: 4px;
          font-size: 0.8571rem;
        }
      }
      &-platforms {
        display: flex;
        justify-content: center;
        img {
          width: 1.4286rem;
          height: 1.4286rem;
          margin-right: 0.2857rem;
        }
      }
    }
    .comm-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      background: #fff;
      padding: 1.1429rem 2.2857rem;
      border-radius: 16px 16px 0 0;
      flex-direction: column;
      .recieve-list {
        width: 600px;
        li {
          display: flex;

          justify-content: space-between;
          margin-top: 20px;
          span {
            width: 8.5714rem;
            flex-shrink: 0;
            margin-top: 10px;
          }
          .recieve-emails {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            > input {
              margin-bottom: 12px;
              //   width: 480px;
            }
          }
        }
      }
      .recieve-add {
        width: 100%;
        height: 2.8571rem;
        background: #f5f9fe;
        border-radius: 4px;
        border: 1px solid #c9cdd3;
        color: #3762d6;
      }
      .ant-btn:hover,
      .ant-btn:focus {
        color: #577ee8;
        background-color: #fff;
        border-color: #577ee8;
      }
    }
    .comm-header-left {
      width: 100%;
      > span {
        font-size: 1.2857rem;
        font-family: "prefer-bold";
      }
    }
    .comm-header-right {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 20px;
      input {
        width: 35.7143rem;
        height: 2.8571rem;
      }
      > div {
        span {
          margin-right: 12px;
        }
      }
    }
    .comm-header-more {
      color: #333333;
      cursor: pointer;
      i {
        font-size: 0.7143rem;
      }
    }
    .tb-total {
      padding: 16px;
      display: flex;
      justify-content: flex-end;
      &-text {
        color: #666666;
      }
      &-money {
        color: #3762d6;
      }
    }
  }
  /deep/ .ant-select {
    width: 7.1429rem;
  }
  /deep/ .ant-select-selection--single {
    border-radius: 19px;
    border: 1px solid #3762d6;
    box-shadow: none !important;
  }
  /deep/ .ant-select-selection__rendered {
    margin-left: 1.8571rem;
  }
  /deep/ .ant-select-selection-selected-value,
  /deep/ .ant-select-arrow-icon {
    color: #3762d6;
  }
  /deep/.ant-select-search__field {
    height: 2.8571rem !important;
    width: 21.4286rem;
  }
}
</style>
