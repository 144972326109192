import { render, staticRenderFns } from "./PreviewCard3.vue?vue&type=template&id=0f8b66cc&scoped=true&"
import script from "./PreviewCard3.vue?vue&type=script&lang=js&"
export * from "./PreviewCard3.vue?vue&type=script&lang=js&"
import style0 from "./PreviewCard3.vue?vue&type=style&index=0&id=0f8b66cc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f8b66cc",
  null
  
)

export default component.exports