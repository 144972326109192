<template>
  <div class="order-details">
    <Brands collection="Reports" subset="Transaction"></Brands>
    <div class="content">
      <div class="content-title">
        <div class="items">
          <div class="item" style="width:220px">
            <span>Date Range</span>
            <a-range-picker
              @change="onChangeDate"
              class="date"
              format="L"
              :value="[trans_time_start, trans_time_end]"
            />
          </div>
          <div class="item" style="width:220px">
            <span>Publisher</span>
            <a-select
              @change="handleChangePublisher"
              class="select"
              v-model="medium_id"
            >
              <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
              <a-select-option
                :value="item.medium_id"
                v-for="(item, index) in mediumLists"
                :key="index"
                >{{ item.title }} ({{ item.medium_id }})</a-select-option
              >
            </a-select>
          </div>
          <div class="item" style="width:250px">
            <span>Transaction Status</span>
            <PSelector
              size="large"
              v-model="status"
              :options="[
                {
                  value: 'new',
                  label: 'New',
                },
                {
                  value: 'void',
                  label: 'Void',
                },
                {
                  value: 'effective',
                  label: 'Locked',
                },
              ]"
              multiple
              clearAble
            ></PSelector>
          </div>
          <div class="item" style="width:330px">
            <span>Search by Order ID or Publisher ID</span>
            <a-input v-model="oid" />
          </div>
        </div>
        <div class="search-reset">
          <button @click="search">Search</button>
          <button @click="reset">Reset</button>
        </div>
      </div>
      <div class="table-data">
        <div class="list-prefix">
          <button @click="isShowManualOrder = true">Manual Transaction</button>
        </div>
        <a-table
          :columns="columns"
          :data-source="dataLists"
          class="components-table-demo-nested"
          :row-selection="rowSelection"
          bordered
          :rowKey="(record, index) => record.id"
          :loading="isLoading"
          :pagination="false"
          @expand="handleRowExpand"
        >
          <template slot="time" slot-scope="text, record">
            <img
              src="@/assets/suo.svg"
              alt
              v-if="record.status == 'effective'"
              class="row-status-icon"
            />
            <img
              src="@/assets/locked.svg"
              alt
              v-if="record.status == 'void'"
              class="row-status-icon row-status-icon__locked"
            />
            <span>{{ record.trans_time | filterTime }}</span>
          </template>
          <template slot="orderId" slot-scope="text, record">
            <span class="row-oid">{{ record.oid }}</span>
          </template>
          <template slot="publisher" slot-scope="text, record">
            <span>{{ record.medium_id }}</span>
          </template>
          <template slot="couponcode" slot-scope="text, record">
            <div :style="{ textAlign: record.couponcode ? 'left' : 'center' }">
              <span>{{ record.couponcode || "-" }}</span>
            </div>
          </template>
          <template slot="amount" slot-scope="text, record">
            <span>{{ record.amount | toThousands }}</span>
          </template>
          <template slot="commission" slot-scope="text, record">
            <span>{{ record.medium_commission | toThousands }}</span>
          </template>
          <template slot="PartnerBoost" slot-scope="text, record">
            <span>{{ record.local_profit | toThousands }}</span>
          </template>
          <template slot="spend" slot-scope="text, record">
            <span>{{ record.total_spend | toThousands }}</span>
          </template>
          <template slot="UID" slot-scope="text, record">
            <div :style="{ textAlign: record.ori_uid ? 'left' : 'center' }">
              <span>{{ record.ori_uid || "-" }}</span>
            </div>
          </template>
          <template slot="action" slot-scope="text, record">
            <img
              src="@/assets/locked.svg"
              alt
              style="width: 16px;height: 16px;cursor: pointer;"
              @click="voidOrderShow(record)"
              v-if="record.is_locked != 1"
            />
          </template>
          <template slot="expandedRowRender" slot-scope="record">
            <a-table
              :columns="innerColumns"
              :data-source="record.keyChildren"
              :pagination="false"
              :rowKey="(record2, index) => record2.id"
              class="inner-table"
            >
              <template slot="Status" slot-scope="text, record2">
                <span>{{ record2.status | skuStatusFilter }}</span>
              </template>
              <template slot="ID" slot-scope="text, record2">
                <span>{{ record2.id }}</span>
              </template>
              <!-- <template slot="Categary" slot-scope="text, record2">
                <span>{{record2.norm_id}}</span>
              </template>-->
              <template slot="SKU" slot-scope="text, record2">
                <div :style="{ textAlign: record2.sku_id ? 'left' : 'center' }">
                  <span>{{ record2.sku_id || "-" }}</span>
                </div>
              </template>
              <template slot="UnitPrice" slot-scope="text, record2">
                <span
                  >${{ (record2.amount / record2.quantity).toFixed(2) }}</span
                >
              </template>
              <template slot="Quantity" slot-scope="text, record2">
                <span>{{ record2.quantity }}</span>
              </template>
              <template slot="SaleAmount" slot-scope="text, record2">
                <span>{{ record2.amount | toThousands }}</span>
              </template>
              <template slot="Commssion" slot-scope="text, record2">
                <span>{{ record2.medium_commission | toThousands }}</span>
              </template>
              <template slot="PartnerBoost" slot-scope="text, record2">
                <span>{{ record2.local_profit | toThousands }}</span>
              </template>
              <template slot="TotalSpend" slot-scope="text, record2">
                <span>{{ record2.total_spend | toThousands }}</span>
              </template>
              <template slot="Edit" slot-scope="text, record2">
                <img
                  src="@/assets/edit.svg"
                  v-if="record2.status == 'new'"
                  alt
                  style="width: 16px;height: 16px;cursor: pointer;margin-right: 5px;"
                  @click="
                    editOrderShow({ ...record2, currency: record.currency })
                  "
                />
                <img
                  v-if="record2.status == 'new'"
                  src="@/assets/locked.svg"
                  alt
                  style="width: 16px;height: 16px;cursor: pointer;"
                  @click="voidSkuOrderShow(record2)"
                />
              </template>
            </a-table>
          </template>
        </a-table>
        <div class="footer-pagination">
          <a-pagination
            v-model="currentPage"
            :total="totalPage"
            show-less-items
            :defaultPageSize="20"
            @change="paginationChange"
          />
        </div>
      </div>
    </div>
    <!-- void order -->
    <Modal
      :isVisible="voidOrderVisible"
      title="Void Order"
      submitText="Void Order"
      @close-popup="voidOrderClose"
      @submit="voidOrderSubmit"
      width="360px"
      class="void-order-modal"
    >
      <template #content>
        <p>
          You are about to VOID the selected order on this page. Please choose a
          void reason.
        </p>
        <a-select @change="handleChangeVoid" class="select">
          <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
          <a-select-option value="Returned Merchandise"
            >Returned Merchandise</a-select-option
          >
          <a-select-option value="Violation of Brand Publisher Policy"
            >Violation of Brand Publisher Policy</a-select-option
          >
          <a-select-option value="Fraudulent Order"
            >Fraudulent Order</a-select-option
          >
          <a-select-option value="Repeat Order">Repeat Order</a-select-option>
          <a-select-option value="Test Order">Test Order</a-select-option>
          <a-select-option value="Cancelled Order"
            >Cancelled Order</a-select-option
          >
          <a-select-option value="Other">Other</a-select-option>
        </a-select>
      </template>
    </Modal>

    <!-- order edit -->
    <Modal
      :isVisible="editOrderVisible"
      title="Order Edit"
      submitText="Update Order"
      @close-popup="editOrderClose"
      width="528px"
      class="edit-order-modal"
      @submit="editOrderSubmit"
    >
      <template #content>
        <h4>editable items:</h4>
        <ul>
          <li>1.Edit the status of the order.</li>
          <li>2.Select a Percentage or Flat commission.</li>
          <li>3.Edit the Sale Amount.</li>
          <li>4.Enter a commission percentage or flat commission rate.</li>
        </ul>
        <h4>Original Order Details</h4>
        <div class="item">
          <span>Order ID:</span>
          <b>{{ editShowData.oid }}</b>
        </div>
        <div class="item">
          <span>Order Amount:</span>
          <b>{{ editShowData.currency.sign }}{{ editShowData.ori_amount }}</b>
        </div>
        <div class="item">
          <span>Order Date:</span>
          <b>{{ editShowData.created_time | filterTime }}</b>
        </div>
        <div class="item">
          <span>Commission:</span>
          <b
            >{{ editShowData.currency.sign
            }}{{ editShowData.ori_commission }}</b
          >
        </div>
        <div class="item">
          <span>SKU ID:</span>
          <b>{{ editShowData.sku_id }}</b>
        </div>
        <h4>Order Updates</h4>
        <div class="update">
          <span>Edit Status</span>
          <a-select
            @change="handleChangeEditStatus"
            default-value="new"
            class="select"
          >
            <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
            <a-select-option value="new">New</a-select-option>
            <a-select-option value="void">Void</a-select-option>
            <a-select-option value="effective">Locked</a-select-option>
          </a-select>
          <a-select
            @change="handleChangeEditStatusVoidReason"
            class="select"
            v-if="isSelectVoid"
            style="margin-left: 10px;width: 200px;"
          >
            <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
            <a-select-option value="Returned Merchandise"
              >Returned Merchandise</a-select-option
            >
            <a-select-option value="Violation of Brand Publisher Policy"
              >Violation of Brand Publisher Policy</a-select-option
            >
            <a-select-option value="Fraudulent Order"
              >Fraudulent Order</a-select-option
            >
            <a-select-option value="Repeat Order">Repeat Order</a-select-option>
            <a-select-option value="Test Order">Test Order</a-select-option>
            <a-select-option value="Cancelled Order"
              >Cancelled Order</a-select-option
            >
            <a-select-option value="Other">Other</a-select-option>
          </a-select>
        </div>
        <div class="update" v-if="!isSelectVoid">
          <span>New Commission Type</span>
          <a-radio-group
            name="radioGroup"
            :default-value="1"
            @change="changeRadio"
          >
            <a-radio :value="1">Percentage</a-radio>
            <a-radio :value="2">Flat Rate</a-radio>
          </a-radio-group>
        </div>
        <div class="update" v-if="!isSelectVoid">
          <span>New Sale Amount</span>
          <div class="input">
            <input type="text" v-model="NewSaleAmount" />
            <i>{{ editShowData.currency.sign }}</i>
          </div>
        </div>
        <div class="update" v-if="isSelectPercentageOrFlat && !isSelectVoid">
          <span>Percentage</span>
          <div class="new">
            <input type="text" v-model="NewCommissionPercentage" />
            <b>%</b>
          </div>
        </div>
        <div class="update" v-if="!isSelectPercentageOrFlat && !isSelectVoid">
          <span>Flat Rate</span>
          <div class="new">
            <input
              type="text"
              v-model="NewCommissionPercentage"
              class="inputFlat"
            />
            <i>{{ editShowData.currency.sign }}</i>
          </div>
        </div>
      </template>
    </Modal>

    <!-- 手工订单 -->
    <Modal
      :isVisible="isShowManualOrder"
      title="Manual Transaction"
      submitText="Submit"
      @close-popup="hideManualOrder"
      width="400px"
      class="manual-order-modal"
      @submit="submitManualOrder"
    >
      <template #content>
        <h4>Publisher</h4>
        <a-select
          v-model="manulOrderObj.medium_id"
          size="large"
          placeholder="Please choose publisher"
          style="width: 100%"
        >
          <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
          <a-select-option
            :value="item.medium_id"
            v-for="(item, index) in mediumLists"
            :key="index"
            >{{ item.title }} ({{ item.medium_id }})</a-select-option
          >
        </a-select>
        <h4>Order ID</h4>
        <a-input
          v-model.trim="manulOrderObj.oid"
          size="large"
          :maxLength="45"
        />
        <h4>Commission Type</h4>
        <a-radio-group v-model="manulOrderObj.type">
          <a-radio value="percent">Percentage</a-radio>
          <a-radio value="flat_rate">Flat Rate</a-radio>
        </a-radio-group>
        <h4>Sale Amount</h4>
        <div style="display: flex;justify-content:center;">
          <a-select
            class="select"
            v-model="manulOrderObj.currency"
            size="large"
            style="width: 160px;margin-right:20px"
          >
            <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
            <a-select-option
              :value="currency.simple_name"
              v-for="currency in $store.state.commonConfig.currencies.filter(
                (c) => c.simple_name != 'MIX'
              )"
              :key="currency.simple_name"
              >{{ currency.simple_name }}</a-select-option
            >
          </a-select>
          <a-input v-model="manulOrderObj.amount" size="large" />
        </div>
        <h4>
          {{ manulOrderObj.type == "percent" ? "Percentage" : "Flat Rate" }}
        </h4>
        <div class="commision-value">
          <a-input
            type="text"
            :addon-before="
              manulOrderObj.type == 'percent' ? null : manulOrderObj.currency
            "
            :addon-after="manulOrderObj.type == 'percent' ? '%' : null"
            v-model="manulOrderObj.commission"
            size="large"
          />
        </div>
        <h4>Comment</h4>
        <a-input v-model.trim="manulOrderObj.remark" size="large" />
      </template>
    </Modal>
    <Loading v-if="isLoadingModal"></Loading>
  </div>
</template>

<script>
import Brands from "./../../components/Brands.vue";
import Balance from "./../../components/Balance.vue";
import Modal from "./../../components/Modal.vue";
import moment from "moment-timezone";
import axios from "@/utils/request";
import { columns, innerColumns } from "./TableColumns";
export default {
  name: "report_orders",
  data() {
    return {
      isLoadingModal: false,
      dataLists: [],
      columns,
      innerColumns,
      innerDataLlists: [],
      // selectedRowKeys: [],
      // expandedRowKeys: [],
      // 媒体ID
      medium_id: "",
      // 商家原始订单号
      oid: "",
      // 状态
      status: [],
      // 开始时间
      trans_time_start: null,
      // 结束时间
      trans_time_end: null,
      isLoading: false,
      voidOrderVisible: false,
      editOrderVisible: false,
      // 编辑时展示的数据
      editShowData: {},
      currentPage: 1,
      page_size: 20,
      totalPage: 0,
      voidReason: "",
      // 提交无效
      orderSubmitVoid: {},
      // sku提交无效
      skuOrderSubmitVoid: {},
      distinguishSku: 0,
      mediumLists: [],
      isSelectVoid: false,
      editStatusVoid: "",
      radioCommissionType: 1,
      NewSaleAmount: "",
      NewCommissionPercentage: "",
      voidReasonSelect: "",
      isSelectPercentageOrFlat: true,
      isShowManualOrder: false,
      manulOrderObj: {
        medium_id: null,
        oid: null,
        type: "percent",
        currency: "USD",
        amount: null,
        commission: null,
        remark: null,
      },
    };
  },
  components: {
    Brands,
    Balance,
    Modal,
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
          );
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.status === "void" || record.status === "effective",
          },
        }),
      };
    },
    currenciesSimpleNameMap() {
      let map = {};
      if (
        this.$store.state.commonConfig &&
        this.$store.state.commonConfig.currencies
      ) {
        this.$store.state.commonConfig.currencies.forEach((item) => {
          map[item.simple_name] = item;
        });
      }
      return map;
    },
  },
  created() {
    if (this.$route.params.medium_id) {
      this.medium_id = this.$route.params.medium_id;
    }
    this._getOrderLists();
    this._getMediumLists();
  },
  methods: {
    submitManualOrder() {
      let amount = parseFloat(this.manulOrderObj.amount);
      if (isNaN(amount) || amount == 0) {
        this.$message.error("Please input valid amount number");
        return;
      }
      let commission = parseFloat(this.manulOrderObj.commission);
      if (isNaN(commission)) {
        this.$message.error("Please input valid commission number");
        return;
      }
      if (!this.manulOrderObj.remark || this.manulOrderObj.remark.length == 0) {
        this.$message.error("Please input comment");
        return;
      }
      if (this.manulOrderObj.medium_id === null) {
        this.$message.error("Please select Publisher");
        return;
      }
      if (!this.manulOrderObj.oid || this.manulOrderObj.oid.length == 0) {
        this.$message.error("Please input Order ID");
        return;
      }
      this.isLoadingModal = true;
      axios
        .post("/api_admin.php?a=transaction/manual_order", this.manulOrderObj)
        .then((res) => {
          this.isLoadingModal = false;
          if (res.code === 0) {
            this.hideManualOrder();
            this.$message.success("Submit Manual Transaction Success");
          } else {
            this.$message.error(
              "Submit Manual Transaction Failed, " + res.message
            );
          }
        })
        .catch((e) => {
          console.log(e);
          this.isLoadingModal = false;
        });
    },
    hideManualOrder() {
      this.manulOrderObj = {
        medium_id: null,
        oid: null,
        type: "percent",
        currency: "USD",
        amount: null,
        commission: null,
        remark: null,
      };
      this.isShowManualOrder = false;
    },
    handleRowExpand(expanded, record) {
      if (expanded) {
        console.log(
          record,
          ".ant-table-expanded-row[data-row-key='" + record.id + "-extra-row']"
        );
        this.$nextTick(() => {
          document.querySelector(
            ".ant-table-expanded-row[data-row-key='" +
              record.id +
              "-extra-row'] td:first-child"
          ).style.height =
            document.querySelector(
              ".ant-table-expanded-row[data-row-key='" +
                record.id +
                "-extra-row'] .inner-table"
            ).clientHeight +
            1 +
            "px";
        });
      } else {
      }
    },
    handleChangePublisher(value) {
      this.medium_id = value;
    },
    handleChangeStatus(value) {
      this.status = value;
    },
    onChangeDate(date, dateString) {
      console.log(dateString, date);
      this.trans_time_start = date[0];
      this.trans_time_end = date[1];
    },
    // 获取订单
    _getOrderLists() {
      this.isLoading = true;
      axios
        .get("/api_admin.php?a=transaction/list", {
          params: {
            medium_id: this.medium_id,
            oid: this.oid,
            status: this.status,
            trans_time_start:
              this.trans_time_start && this.trans_time_start.format("L"),
            trans_time_end:
              this.trans_time_end && this.trans_time_end.format("L"),
            page_num: this.currentPage,
            page_size: this.page_size,
          },
        })
        .then((res) => {
          if (res.code === 0) {
            this.isLoading = false;
            this.dataLists = res.data.data;
            this.innerDataLlists = res.data.skus;
            this.totalPage = parseInt(res.data.total_size);
            for (let i = 0; i < this.dataLists.length; i++) {
              // 添加排序key
              this.dataLists[i].key = (this.currentPage - 1) * 10 + i + 1;
              // 添加oid ，主要是编辑时需要展示oid
              for (
                let y = 0;
                y < this.innerDataLlists[this.dataLists[i].id].length;
                y++
              ) {
                this.innerDataLlists[this.dataLists[i].id][
                  y
                ].oid = this.dataLists[i].oid;
              }
              // 将sku添加到data中，方便表格渲染
              this.dataLists[i].keyChildren = this.innerDataLlists[
                this.dataLists[i].id
              ];
              this.dataLists[i].currency = this.currenciesSimpleNameMap[
                this.dataLists[i].ori_currency
              ];
            }
          } else {
            this.$message.error(
              res.msg || "Sorry, server is busy. please try later."
            );
          }
        });
    },
    // 搜索
    search() {
      this._getOrderLists();
    },
    // 重置
    reset() {
      this.oid = "";
      this.status = [];
      this.medium_id = "";
      this.trans_time_start = null;
      this.trans_time_end = null;
      this._getOrderLists();
    },
    // 切换下一页
    paginationChange(page, pageSize) {
      this.currentPage = page;
      this._getOrderLists();
    },
    voidOrderShow(record) {
      this.distinguishSku = 1;
      this.orderSubmitVoid = record;
      this.voidOrderVisible = true;
    },
    voidSkuOrderShow(record) {
      this.distinguishSku = 2;
      this.skuOrderSubmitVoid = record;
      this.voidOrderVisible = true;
    },
    voidOrderClose() {
      this.voidOrderVisible = false;
    },
    editOrderShow(record) {
      this.editShowData = record;
      this.NewSaleAmount = record.ori_amount;
      this.editOrderVisible = true;
    },
    editOrderClose() {
      this.editOrderVisible = false;
      this.isSelectPercentageOrFlat = true;
    },
    handleChangeEditStatus(value) {
      this.editStatusVoid = value;
      if (value == "void") {
        this.isSelectVoid = true;
      } else {
        this.isSelectVoid = false;
      }
    },
    handleChangeVoid(value) {
      this.voidReason = value;
      console.log(value);
    },
    // 无效理由提交
    voidOrderSubmit() {
      if (this.distinguishSku == 1) {
        axios
          .post("/api_admin.php?a=transaction/batch_edit", {
            msg: this.voidReason,
            ids: this.orderSubmitVoid.id,
            status: "void",
          })
          .then((res) => {
            if (res.code === 0) {
              this.$message.success("submit success");
              this.voidOrderVisible = false;
              this._getOrderLists();
            } else {
              this.$message.error(
                res.msg || "Sorry, server is busy. please try later."
              );
            }
          });
      } else if (this.distinguishSku == 2) {
        axios
          .post("/api_admin.php?a=transaction/sku_void", {
            msg: this.voidReason,
            id: this.skuOrderSubmitVoid.trans_id,
            skuid: this.skuOrderSubmitVoid.id,
          })
          .then((res) => {
            if (res.code === 0) {
              this.$message.success("submit success");
              this.voidOrderVisible = false;
              this._getOrderLists();
            } else {
              this.$message.error(
                res.msg || "Sorry, server is busy. please try later."
              );
            }
          });
      }
    },
    // /api_admin.php?a=medium/list
    // 获取媒体
    _getMediumLists() {
      axios
        .get("/api_admin.php?a=medium/list", {
          params: {
            status: ["adopt"],
            simple: 1,
          },
        })
        .then((res) => {
          if (res.code === 0) {
            this.mediumLists = res.data.data;
          } else {
            this.$message.error(
              res.msg || "Sorry, server is busy. please try later."
            );
          }
        });
    },
    changeRadio(e) {
      let value = e.target.value;
      this.radioCommissionType = value;
      if (value == 1) {
        this.isSelectPercentageOrFlat = true;
      } else {
        this.isSelectPercentageOrFlat = false;
      }
    },
    // 编辑提交
    editOrderSubmit() {
      if (!this.isSelectVoid) {
        let type = this.radioCommissionType == 1 ? "percent" : "flatrate";
        let edit = {
          id: this.editShowData.trans_id,
          skuid: this.editShowData.id,
          amount: this.NewSaleAmount,
          commission_value: this.NewCommissionPercentage,
          commission_type: type,
          status: this.editStatusVoid || "new",
          msg: this.voidReasonSelect,
        };
        console.log(edit);
        axios
          .post("/api_admin.php?a=transaction/sku_edit", {
            ...edit,
          })
          .then((res) => {
            if (res.code == 0) {
              this.editOrderVisible = false;
              this._getOrderLists();
              this.isSelectPercentageOrFlat = true;
            } else {
              this.$message.error(
                res.msg || "Sorry, server is busy. please try later."
              );
            }
          });
      } else {
        axios
          .post("/api_admin.php?a=transaction/sku_void", {
            msg: this.voidReasonSelect,
            id: this.editShowData.trans_id,
            skuid: this.editShowData.id,
          })
          .then((res) => {
            if (res.code === 0) {
              this.editOrderVisible = false;
              this._getOrderLists();
              this.isSelectPercentageOrFlat = true;
            } else {
              this.$message.error(
                res.msg || "Sorry, server is busy. please try later."
              );
            }
          });
      }
    },
    handleChangeEditStatusVoidReason(value) {
      this.voidReasonSelect = value;
    },
  },
  filters: {
    // 时间转换
    filterTime(time) {
      let momentTime = moment.unix(time).format("L LT");
      return momentTime;
    },
    skuStatusFilter(str) {
      if (str == "effective") {
        str = "locked";
      }
      return str[0].toUpperCase() + str.substr(1);
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  .content-title {
    width: 100%;
    padding: 20px 44px 30px 30px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    .items {
      display: flex;
      .item {
        width: 262px;
        margin-right: 26px;
        &:last-child {
          margin-right: 0px;
        }
        span {
          display: block;
          font-size: 1rem;
          font-family: "dtc-bold";
          color: #333333;
          line-height: 30px;
          margin-bottom: 2px;
        }
        input {
          width: 100%;
          height: 2.8571rem;
          background: #ffffff;
          border-radius: 6px;
          border: 1px solid #e6e6e6;
        }
        .select {
          width: 100%;
          height: 2.8571rem;
          /deep/ .ant-select-selection--single {
            height: 2.8571rem;
            border-radius: 6px;
            background: #fafafa;
          }
          /deep/ .ant-select-selection__rendered {
            line-height: 2.8571rem;
          }
          .icon-down {
            color: #333333;
            font-size: 1rem;
          }
        }
        .date {
          width: 100%;
          height: 2.8571rem;
          /deep/ .ant-input {
            height: 2.8571rem;
            border-radius: 6px;
            background: #fafafa;
          }
        }
      }
    }
    .search-reset {
      margin-top: 20px;
      text-align: right;
      button {
        padding: 9px 32px;
        border-radius: 6px;
        cursor: pointer;
        border: none;
        &:first-child {
          background: #2898ff;
          font-size: 1.1429rem;
          color: #ffffff;
          line-height: 20px;
          margin-right: 20px;
        }
        &:last-child {
          border: 1px solid #dddddd;
          font-size: 1.1429rem;
          color: #333333;
          line-height: 20px;
        }
      }
    }
  }
  .table-data {
    margin-top: 36px;
    margin-bottom: 76px;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;

    .list-prefix {
      height: 2.8571rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      button {
        margin-right: 24px;
        cursor: pointer;
        padding: 0 16px;
        height: 2.8571rem;
        text-align: center;
        background: #2898ff;
        border-radius: 6px;
        font-size: 1.1429rem;
        color: #ffffff;
        line-height: 2.8571rem;
        border: none;
      }
    }

    .row-status-icon {
      height: 17px;
      width: 17px;
      position: absolute;
      left: 47px;
      top: 50%;
      margin-top: -8.5px;
      &__locked {
        height: 21px;
        width: 21px;
        left: 45px;
        top: 50%;
        margin-top: -10.5px;
      }
    }
    /deep/ .ant-table-thead > tr > th {
      padding: 12px 6px;
    }
    /deep/ .row-oid {
      display: inline-block;
      max-width: 150px;
    }
    /deep/ .ant-checkbox-indeterminate .ant-checkbox-inner::after {
      background-color: #2898ff;
    }
    /deep/ .ant-checkbox-inner {
      border-color: #999;
    }
    /deep/ .ant-checkbox-checked .ant-checkbox-inner {
      border-color: #2898ff;
      background-color: #2898ff;
    }
    /deep/ .ant-checkbox-checked::after {
      border-color: #2898ff;
    }
    /deep/ .ant-checkbox-wrapper {
      position: relative;
      top: 1px;
      &:hover .ant-checkbox-inner {
        border-color: #2898ff;
      }
    }
    /deep/ .ant-table-row {
      position: relative;
    }
    /deep/ .ant-table-selection-column {
      padding-left: 0 !important;
      padding-right: 16px !important;
    }
    /deep/ .ant-table-selection-col,
    /deep/ .ant-table-expand-icon-col {
      width: 32px;
    }
    /deep/ .ant-table-expand-icon-th {
      border-right: unset;
      width: 3.4286rem !important;
      min-width: 3.4286rem !important;
      padding-right: 0 !important;
      padding-left: 0px !important;
    }
    /deep/ .ant-table-row-expand-icon-cell {
      border-right: unset;
      width: 3.4286rem !important;
      min-width: 3.4286rem !important;
      padding-right: 0 !important;
      padding-left: 0px !important;
      .ant-table-row-expand-icon {
        border-radius: 9999px;
        color: #2898ff;
        border-color: #2898ff;
      }
    }
    /deep/ .ant-table-thead {
      background-color: rgba(249, 249, 249, 1);
    }
    /deep/ .ant-table-expanded-row {
      position: relative;
      .inner-table {
        width: 1158px;
        position: absolute;
        left: 8px;
        top: 8px;
        .ant-table-thead > tr > th {
          background-color: rgba(255, 251, 242, 1);
        }
        .ant-table-tbody > tr > td {
          background-color: rgba(255, 251, 242, 1);
        }
      }
    }
    /deep/ .ant-checkbox.ant-checkbox-disabled {
      display: none;
    }
    .table {
      border-top: 1px solid #eee;
      border-left: 1px solid #eee;
      border-spacing: 0;
      td,
      th {
        padding: 10px 14px;
        border-bottom: 1px solid #eee;
        border-right: 1px solid #eee;
      }
      th {
        background-color: #f9f9f9;
      }
    }
    .footer-pagination {
      margin-top: 20px;
      text-align: right;
    }
    /deep/ .ant-spin-nested-loading.ant-table-spin-holder .ant-empty {
      opacity: 0;
    }
  }
  .void-order-modal {
    p {
      font-size: 1rem;
      color: #999999;
      line-height: 24px;
      margin-bottom: 12px;
    }
    .select {
      width: 100%;
      height: 2.8571rem;
      /deep/ .ant-select-selection--single {
        height: 2.8571rem;
        border-radius: 6px;
        background: #fafafa;
      }
      /deep/ .ant-select-selection__rendered {
        line-height: 2.8571rem;
      }
      .icon-down {
        color: #333333;
        font-size: 1rem;
      }
    }
  }
  .edit-order-modal {
    h4 {
      font-size: 1.1429rem;
      font-family: "dtc-bold";
      color: #333333;
      line-height: 30px;
      margin-bottom: 10px;
    }
    ul {
      padding: 0px;
      li {
        list-style-type: none;
        margin-bottom: 10px;
        font-size: 1.1429rem;
        color: #999999;
        line-height: 18px;
        &:last-child {
          margin-bottom: 16px;
        }
      }
    }
    .item {
      margin-bottom: 10px;
      span {
        font-size: 1.1429rem;
        color: #999999;
        line-height: 18px;

        &:first-child {
          padding-right: 5px;
        }
      }
      b {
        font-size: 1.1429rem;
        color: #333;
        line-height: 18px;
      }
      &:last-child {
        margin-bottom: 16px;
      }
    }
    .update {
      margin-bottom: 16px;
      span {
        font-size: 1.1429rem;
        color: #333333;
        line-height: 18px;
        margin-right: 12px;
      }
      .select {
        width: 135px;
        height: 2.8571rem;
        /deep/ .ant-select-selection--single {
          height: 2.8571rem;
          border-radius: 6px;
          background: #fafafa;
        }
        /deep/ .ant-select-selection__rendered {
          line-height: 2.8571rem;
        }
        .icon-down {
          color: #333333;
          font-size: 1rem;
        }
      }
      .input {
        display: inline-block;
        width: 135px;
        height: 2.8571rem;
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid #e6e6e6;
        position: relative;
        input {
          width: 100%;
          height: 100%;
          outline: none;
          border: none;
          padding-left: 45px;
          border-radius: 6px;
          box-sizing: border-box;
        }
        i {
          font-style: normal;
          position: absolute;
          top: 8px;
          left: 8px;
          font-size: 1.1429rem;
          color: #333333;
          line-height: 18px;
          height: 18px;
          display: block;
          width: 33px;
          text-align: right;
        }
      }
      .new {
        display: inline-block;
        position: relative;
        input {
          width: 135px;
          height: 2.8571rem;
          background: #ffffff;
          border-radius: 6px;
          border: 1px solid #e6e6e6;
          outline: none;
          padding-left: 20px;
          border-radius: 6px;
          box-sizing: border-box;
          margin-right: 8px;
        }
        b {
          font-size: 1.1429rem;
          color: #333333;
          line-height: 18px;
        }
        .inputFlat {
          width: 135px;
          height: 2.8571rem;
          background: #ffffff;
          border-radius: 6px;
          border: 1px solid #e6e6e6;
          outline: none;
          padding-left: 45px;
          border-radius: 6px;
          box-sizing: border-box;
          margin-right: 8px;
        }
        i {
          position: absolute;
          top: 10px;
          left: 8px;
          font-size: 1.1429rem;
          color: #333333;
          line-height: 18px;
          font-style: normal;
          height: 18px;
          display: block;
          width: 33px;
          text-align: right;
        }
      }
    }
  }
  .manual-order-modal {
    &.modal /deep/ .modal-content > .items {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      margin-top: 0;
    }
    h4 {
      font-size: 1.1429rem;
      font-family: "dtc-bold";
      color: #333333;
      line-height: 30px;
      margin-bottom: 10px;
      &:first-child {
        margin-top: 0;
      }
      margin-top: 16px;
    }
    .commision-value {
      /deep/ .ant-input-group-addon {
        width: 60px;
      }
    }
    .ant-input.ant-input-lg {
      height: 2.8571rem !important;
      min-height: 2.8571rem;
    }
  }
  .voidReasonSelect {
    height: 2.8571rem;
    border-radius: 6px;
    outline: none;
    border: 1px solid #e6e6e6;
    padding-left: 10px;
    box-sizing: border-box;
    margin-left: 10px;
  }
}

.inner-table {
  border: none;
  border-radius: 0;
  /deep/ table,
  /deep/ .ant-table-wrapper,
  /deep/ .ant-table {
    border: none;
    border-radius: 0;
  }
  /deep/ .ant-table-content .ant-table-body {
    margin: 0;
  }
  /deep/ .ant-table .ant-table-content {
    border-right: none;
  }
}
/deep/ span.ant-radio + * {
  padding-left: 4px;
  padding-top: 2px;
}
/deep/.ant-table > .ant-table-content > .ant-table-body {
  margin: 0px;
}
</style>
