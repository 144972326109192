<template>
  <div class="management-wrap">
    <p>
      The table below shows the different preset rules and their permissions:
    </p>
    <table class="permissions-table">
      <tr>
        <th></th>
        <th style="text-align:center">Operator</th>
        <th style="text-align:center">Business Personnel</th>
        <th style="text-align:center">Bookkeeper</th>
      </tr>

      <tr>
        <td>Manage publishers</td>
        <td></td>
        <td>
          <div><img src="@/assets/message-accept.svg" alt="" /></div>
        </td>
        <td></td>
      </tr>
      <tr>
        <td>Set and modify commission rules</td>
        <td>
          <div><img src="@/assets/message-accept.svg" alt="" /></div>
        </td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>View report summary, view and modify orders</td>
        <td>
          <div><img src="@/assets/message-accept.svg" alt="" /></div>
        </td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>View deposit and debit history</td>
        <td>
          <div><img src="@/assets/message-accept.svg" alt="" /></div>
        </td>
        <td></td>
        <td>
          <div><img src="@/assets/message-accept.svg" alt="" /></div>
        </td>
      </tr>
      <tr>
        <td>Make a deposit</td>
        <td></td>
        <td></td>
        <td>
          <div><img src="@/assets/message-accept.svg" alt="" /></div>
        </td>
      </tr>
    </table>

    <ul class="toggle-li" v-if="hasSubAccounts">
      <li
        v-for="(item, index) in toggleBtnLists"
        :key="index"
        :class="index === act ? 'active' : ''"
        @click="toggleAct(index)"
      >
        {{ item.name }}
      </li>
    </ul>
    <managementSubAccount :currentAct="act"></managementSubAccount>
  </div>
</template>

<script>
import managementSubAccount from "./managementSubAccount.vue";
import inspectUserPermissions from "@/utils/permissionsJudge";
export default {
  name: "management",
  data() {
    return {
      toggleBtnLists: [
        {
          id: 0,
          name: "Operator",
        },
        {
          id: 1,
          name: "Business Personnel",
        },
        {
          id: 2,
          name: "Bookkeeper",
        },
      ],
      act: 0,
      // 是否有获取子账户信息的权限
      hasSubAccounts: true,
    };
  },
  components: {
    managementSubAccount,
  },
  created() {},
  mounted() {
    let authorityJudgment = this.$store.state.authorityJudgment;
    this.hasSubAccounts = inspectUserPermissions(
      "user/subAccounts",
      authorityJudgment
    );
  },
  methods: {
    toggleAct(item) {
      this.act = item;
    },
  },
};
</script>

<style lang="less" scoped>
.management-wrap {
  width: 100%;

  p {
    font-size: 1.1429rem;
    color: #333333;
    line-height: 20px;
    margin-bottom: 24px;
  }

  .permissions-table {
    width: 100%;
    box-sizing: border-box;
    border-spacing: 0;
    border-radius: 4px;
    border: 1px solid #eee;
    border-collapse: separate;

    td,
    th {
      padding: 7px 14px;
      border-right: 1px solid #eee;
    }

    th {
      background: #f9f9f9;
      font-size: 1rem;
      font-family: "dtc-bold";
      color: #666;
      line-height: 20px;

      &:first-child {
        border-top-left-radius: 3px;
      }

      &:last-child {
        border-top-right-radius: 3px;
      }
    }

    td {
      font-size: 1rem;
      color: #333333;
      line-height: 20px;
      border-top: 1px solid #eee;

      div {
        text-align: center;
      }
    }

    td:last-child,
    th:last-child {
      border-right-color: transparent;
    }
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: #eeeeee;
    margin: 42px auto 2.8571rem;
  }

  .toggle-li {
    display: flex;
    padding: 0px;
    margin: 0px;
    padding-bottom: 16px;
    border-bottom: 1px solid #eee;
    margin-top: 2.8571rem;

    li {
      list-style-type: none;
      font-size: 1.1429rem;
      color: #333333;
      line-height: 23px;
      cursor: pointer;
      margin: 0 16px;
    }

    .active {
      color: #2898ff;
      position: relative;

      &::before {
        content: "";
        width: 100%;
        height: 2px;
        background: #2898ff;
        position: absolute;
        bottom: -16px;
        left: 0;
      }
    }
  }
}
</style>
