<template>
  <div id="datafeed">
    <div class="content">
      <div class="datafeed_card">
        <h3>Product Overview</h3>
        <a-row justify="space-between" type="flex">
          <a-col class="Product-row">
            <p class="p_name">Number of Products</p>
            <p class="p_value">{{ info.num_products }}</p>
          </a-col>
          <a-col class="Product-row">
            <p class="p_name">Last Updated</p>
            <p class="p_value">
              {{ info.last_uploaded_time | formatMDYHI("-") }}
            </p>
          </a-col>
          <a-col class="Product-row">
            <p class="p_name">Pending Upload Files</p>
            <p class="p_value">{{ info.parsing_files_num }}</p>
          </a-col>
          <a-col class="Product-row">
            <p class="p_name">Remaining uploads this month</p>
            <p class="p_value">
              {{ info.max_upload_num_per_month - info.uploaded_this_month }}
            </p>
          </a-col>
        </a-row>
      </div>
      <div class="datafeed_card">
        <h3>Auto Upload</h3>
        <div class="Auto_box">
          <a-radio-group
            v-model="type"
            class="Auto_box_type"
            @change="typeChange"
          >
            <a-radio-button :value="1">Fetch file form XML</a-radio-button>
            <a-radio-button :value="2">Fetch from API</a-radio-button>
          </a-radio-group>
          <div class="clickM" v-if="isEdit" @click="setTooltip"></div>
          <a-form
            v-if="type === 1"
            layout="horizontal"
            :model="formState"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-item labelAlign="left" label="Fetch file form">
              <a-input
                v-model="info.fetch_url"
                class="fetch_url"
                :disabled="isEdit"
                placeholder="Enter XML Link"
              />
            </a-form-item>
            <a-form-item labelAlign="left" label="Frequency">
              <a-radio-group v-model="info.fetch_period" :disabled="isEdit">
                <a-row class="Auto_box_radio">
                  <a-radio value="NEVER">Never</a-radio>
                </a-row>
                <a-row class="Auto_box_radio">
                  <a-radio value="DAILY">Daily</a-radio>
                </a-row>
                <a-row class="Auto_box_radio">
                  <a-radio value="WEEKLY">Weekly</a-radio>
                </a-row>
                <a-row class="Auto_box_radio">
                  <a-radio value="MONTH">Month</a-radio>
                </a-row>
              </a-radio-group>
            </a-form-item>

            <a-row justify="space-between" type="flex">
              <a-col class="needhelp"
                >Need help uploading products to our system? Please read our
                <a
                  class="decorationBut"
                  :href="`${APP_CONFIG.WWW_HOST}/help/datafeed`"
                >
                  product upload guide</a
                >
              </a-col>
              <a-col>
                <a-button
                  class="Submitbut ant-tooltip-open"
                  type="primary"
                  @click="submit"
                >
                  {{ isEdit ? "Edit" : "Update" }}
                  <div v-if="isTooltip" class="SubmitbutTooltip" @click.stop>
                    Click the "Edit" button to <br />
                    update the settings
                  </div>
                </a-button>
              </a-col>
            </a-row>
          </a-form>
          <a-row justify="space-between" type="flex" class="Nofiles_box">
            <a-col
              >Click
              <router-link
                class="decorationBut"
                :to="{
                  name: 'tools_api',
                  params: {
                    active: 'datafeed',
                  },
                }"
                >here</router-link
              >
              to transfer data via the API
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="datafeed_card">
        <h3>Manual Upload</h3>
        <div class="Manual_box">
          <a-row justify="space-between" type="flex">
            <a-col :span="11" class="Manual_box_left">
              <h4>Upload File(s)</h4>
              <a-row justify="start" type="flex" align="middle">
                <a-col class="Browse">Browse for File</a-col>
                <a-col>
                  <a-upload
                    :fileList="fileList"
                    name="file"
                    class="upload-box-but"
                    :remove="handleRemove"
                    @change="handleFilesChange"
                    :before-upload="beforeUpload"
                    accept=".xml, .csv"
                  >
                    <a-button>Choose File</a-button>
                  </a-upload>
                </a-col>
              </a-row>
              <a-row>
                <a-upload-dragger
                  :fileList="fileList"
                  name="file"
                  class="upload-box"
                  :remove="handleRemove"
                  @change="handleFilesChange"
                  :before-upload="beforeUpload"
                  accept=".xml, .csv"
                >
                  <p class="ant-upload-text">Please upload CSV or XML file</p>
                </a-upload-dragger>
              </a-row>
              <a-row justify="end" type="flex" style="margin-bottom:0;">
                <a-col>
                  <a-button
                    :disabled="fileList.length === 0"
                    :loading="uploading"
                    type="primary"
                    class="UploadBut"
                    @click="handleUpload"
                    >{{ uploading ? "Uploading" : "Upload" }}</a-button
                  >
                </a-col>

                <a-col>
                  <a-button @click="handleReset" class="resetbut"
                    >Reset</a-button
                  >
                </a-col>
              </a-row>
              <a-row>
                <a-col class="needhelp"
                  >Need help uploading products to our system? Please read our
                  <a
                    class="decorationBut"
                    :href="`${APP_CONFIG.WWW_HOST}/help/datafeed`"
                  >
                    product upload guide</a
                  >
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="11" class="Manual_box_right">
              <h4>Uploaded file history</h4>
              <div class="historyList">
                <a-row
                  style="align-items: center;"
                  justify="start"
                  type="flex"
                  v-for="(item, index) in info.fetch_recent_upload_records"
                  :key="index"
                >
                  <a-col :span="8">{{ item.created_time | formatMDYHI }}</a-col>
                  <a-col
                    :span="9"
                    style="word-break: break-all;margin-right: 8px;"
                    >{{ item.file_name }}</a-col
                  >
                  <a-col
                    :span="4"
                    v-if="item.status === 'SUCCESS'"
                    class="upload-status-btn upload-status-btn-success"
                    >Success</a-col
                  >
                  <a-col
                    :span="4"
                    v-else-if="item.status === 'FAIL'"
                    class="upload-status-btn upload-status-btn-fail"
                    >Failed</a-col
                  >
                  <a-col
                    :span="4"
                    v-else-if="item.status === 'PARSING'"
                    class="upload-status-btn upload-status-btn-parsing"
                    >Pending</a-col
                  >
                  <a-col
                    :span="4"
                    v-else-if="item.status === 'WAITING'"
                    class="upload-status-btn upload-status-btn-parsing"
                    >Pending</a-col
                  >
                  <a-col :span="2">
                    <a :href="item.file_url" download target="_blank">
                      <img src="@/assets/xz.svg" alt="" />
                    </a>
                  </a-col>
                </a-row>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>

    <a-modal
      v-model="successModel"
      width="500px"
      title=""
      :footer="null"
      :closable="false"
    >
      <a-row justify="space-around" type="flex" class="successModel_img">
        <img src="@/assets/success.svg" alt="" />
      </a-row>
      <a-row justify="space-around" type="flex" class="successModel_text">
        Successfully Updated
      </a-row>
    </a-modal>
    <Loading
      v-if="progressPercent > -1"
      :percent="progressPercent"
      type="progress"
    ></Loading>
  </div>
</template>

<script>
import Modal from "../../components/Modal.vue";
import axios from "@/utils/request";
import moment from "moment-timezone";
import { uploadUrl } from "@/utils/config";

export default {
  name: "datafeed",
  components: {
    Modal,
  },
  data() {
    return {
      progressPercent: -1,
      labelCol: { span: 3 },
      wrapperCol: { span: 14 },
      type: 1,
      formState: {
        fieldA: "",
        fieldB: "",
      },
      fileList: [],
      uploading: false,
      info: {
        num_products: 0,
        max_upload_num_per_month: 0,
        uploaded_this_month: 0,
      },
      originInfo: {},
      successModel: false,

      isEdit: true,
      isTooltip: false,
    };
  },
  mounted() {
    axios.get("api_admin.php?a=creative/datafeed_info").then((res) => {
      this.info = res.data;
      this.originInfo = Object.assign({}, res.data);
    });
  },
  methods: {
    typeChange(val) {
      if (this.type === 1) {
        this.isEdit = true;
      } else {
        this.isEdit = false;
      }
    },
    setTooltip() {
      if (this.isEdit) {
        this.isTooltip = true;
        setTimeout(() => {
          this.isTooltip = false;
        }, 2000);
      }
    },
    submit() {
      if (this.isEdit) {
        this.isEdit = false;
        return;
      }
      let data = {
        fetch_url: this.info.fetch_url,
        fetch_period: this.info.fetch_period,
      };
      if (
        data.fetch_period == this.originInfo.fetch_period &&
        data.fetch_url == this.originInfo.fetch_url
      ) {
        this.$message.error("No change");
        return;
      }
      axios
        .post("api_admin.php?a=creative/datafeed_update_fetch", data)
        .then((res) => {
          if (res.code == 0) {
            // this.$message.success('Update success')
            this.successModel = true;
            this.isEdit = true;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
      console.log(file, this.fileList);
    },
    handleFilesChange(fileInfo) {
      console.log(fileInfo.file.webkitRelativePath);
    },

    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      // console.log(file, this.fileList)
      return false;
    },
    handleReset() {
      this.fileList = [];
    },
    handleUpload() {
      if (this.fileList.length == 0) {
        return;
      }
      this.progressPercent = 1;
      let maxCount = this.fileList.length;
      let successCount = 0;
      let failCount = 0;
      while (true) {
        let file = this.fileList.pop();
        if (!file) {
          break;
        }
        let formData = new FormData();
        formData.append("dir", "file");
        formData.append("imgFile", file);
        axios
          .post(uploadUrl, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => res.data.url || null)
          .then((url) => {
            failCount += url ? 0 : 1;
            if (url) {
              return axios
                .post("api_admin.php?a=creative/datafeed_upload", {
                  file_name: file.name,
                  file_url: url,
                })
                .then((res) => {
                  this.progressPercent = Math.round(
                    ((successCount + failCount) * 100) / maxCount
                  );
                  if (res.code == 0) {
                    successCount++;
                    this.info.fetch_recent_upload_records.unshift({
                      status: "WAITING",
                      created_time: moment().unix(),
                      file_url: url,
                      file_name: file.name,
                    });
                  } else {
                    failCount++;
                  }
                });
            } else {
              this.progressPercent = Math.round(
                ((successCount + failCount) * 100) / maxCount
              );
              return 1;
            }
          })
          .then(() => {
            if (successCount + failCount == maxCount) {
              this.$message.info(
                "Uploaded " +
                  successCount +
                  " files, " +
                  failCount +
                  " files failed"
              );
              this.info.uploaded_this_month += successCount;
              this.info.parsing_files_num += successCount;
              this.progressPercent = -1;
            }
          });
      }
    },
  },
  filters: {},
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  margin-bottom: 100px;

  .datafeed_card {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 0 32px 30px 32px;
    margin-bottom: 22px;
    overflow: hidden;

    h3 {
      margin: 22px 0;
      font-size: 1.2857rem;
      font-family: "prefer-bold";
      font-family: Circular-Black, Circular;
    }

    .Product-row {
      width: 260px;
      height: 160px;
      border-radius: 8px;
      border: 1px solid #eeeeee;
      text-align: center;
      overflow: hidden;

      .p_name {
        width: 200px;
        margin: 0 auto;
        height: 20px;
        font-size: 1.1429rem;
        font-family: Circular-Book, Circular;
        font-weight: normal;
        color: #333333;
        line-height: 20px;
        margin-top: 32px;
      }

      .p_value {
        width: 200px;
        margin: 0 auto;
        line-height: 2.8571rem;
        font-size: 1.4286rem;
        font-family: Circular-Bold, Circular;
        font-family: "prefer-bold";
        color: #2898ff;
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 108px;
      }
    }

    .Auto_box {
      width: 100%;
      background: #fffaf2;
      border-radius: 8px;
      padding: 22px;
      height: 370px;
      position: relative;

      .clickM {
        position: absolute;
        width: 100%;
        height: 220px;
        top: 66px;
        left: 0;
        // background: rgba(0, 0, 0, 0.06);
        z-index: 10;
      }

      .Auto_box_type {
        margin-bottom: 20px;

        .ant-radio-button-wrapper {
          background-color: rgba(0, 0, 0, 0);
          border-radius: 4px;
          border: 1px solid #999;
          color: #999;
          box-shadow: 0 0 0 0;
          width: 160px;
          text-align: center;
        }

        .ant-radio-button-wrapper:first-child {
          margin-right: 30px;
        }

        .ant-radio-button-wrapper-checked {
          border: 1px solid #2898ff;
          color: #2898ff;
          box-shadow: 0 0 0 0;
        }

        .ant-radio-button-wrapper:not(:first-child)::before {
          display: none;
        }
      }

      .fetch_url {
        width: 800px;
      }

      .Auto_box_radio {
        line-height: 2.8571rem;

        .ant-radio-wrapper {
          color: #999 !important;
          position: relative;
          padding-left: 100px;
        }

        /deep/ .ant-radio-wrapper > span:nth-of-type(2) {
          position: absolute;
          left: 0;
          padding: 0;
        }
      }

      .Submitbut {
        margin: 0 8.5714rem;
        position: relative;
        width: 100px;

        .SubmitbutTooltip {
          position: absolute;
          display: inline-block;
          text-align: center;
          color: #fff;
          font-size: 0.8571rem;
          line-height: 18px;
          padding: 10px;
          border-radius: 4px;
          background: rgba(62, 61, 64, 0.9);
          left: -64px;
          top: -63px;
          width: 200px;
          word-break: normal;
          word-wrap: break-word;
        }

        .SubmitbutTooltip::after {
          content: "";
          display: inline-block;
          border: 4px solid rgba(0, 0, 0, 0);
          border-top: 4px solid rgba(62, 61, 64, 0.9);
          position: absolute;
          bottom: -8px;
          left: 50%;
        }
      }

      .Nofiles_box {
        height: 250px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
      }
    }

    .Manual_box {
      width: 100%;
      background: #f6fbff;
      border-radius: 8px;
      padding: 22px;

      .Manual_box_left {
        // height: 255px;
        // background: #FFFFFF;
        border-radius: 8px;
        padding: 22px 0 0 0;

        h4 {
          font-size: 1.1429rem;
          font-family: Circular-Bold, Circular;
          font-family: "prefer-bold";
          color: #333333;
          line-height: 20px;
          margin-bottom: 15px;
        }

        .Browse {
          font-size: 1rem;
          font-family: Circular-Book, Circular;
          font-weight: normal;
          color: #666666;
          margin-right: 30px;
        }

        div {
          margin-bottom: 15px;
        }

        .upload-box-but {
          /deep/ .ant-upload-list {
            display: none;
          }
        }

        .upload-box {
          width: 100%;
          display: inline-block;
          height: 100px;

          /deep/ .ant-upload.ant-upload-drag {
            border-radius: 8px;
            border: 1px solid #eeeeee !important;
            background: rgba(0, 0, 0, 0) !important;
          }

          /deep/ .ant-upload-text {
            font-size: 1rem;
            font-family: Circular-Book, Circular;
            font-weight: normal;
            color: #666666;
            line-height: 18px;
          }
        }
      }

      .Manual_box_right {
        height: 270px;
        background: #fff;
        border-radius: 8px;
        padding: 22px 32px;

        h4 {
          font-size: 1.1429rem;
          font-family: Circular-Bold, Circular;
          font-family: "prefer-bold";
          color: #333333;
          line-height: 20px;
          margin-bottom: 20px;
        }

        .historyList {
          height: 170px;
          overflow: auto;

          & > div {
            margin-bottom: 14px;
            font-size: 1rem;
          }

          & > div:last-child {
            margin-bottom: 0px;
          }
        }
      }

      .UploadBut {
        margin-right: 30px;
        background: #2898ff;
        color: #fff;
        width: 100px;
        border: #2898ff;
      }

      .resetbut {
        background: rgba(0, 0, 0, 0);
        color: #2898ff;
        border: 1px solid #2898ff;
        width: 100px;
      }

      .historyList::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 6px;
        /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
      }

      .historyList::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 10px;
        background-color: #bbb;
      }

      .historyList::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        // box-shadow: inset 0 0 5px rgba(87, 175, 187, 0.1);
        border-radius: 10px;
        background: rgba(0, 0, 0, 0);
      }
    }
  }
}

.successModel_img {
  margin: 50px;
}

.successModel_text {
  margin-bottom: 50px;
}

.decorationBut {
  text-decoration: underline;
}

.needhelp {
  font-size: 0.8571rem;
}

.upload-status-btn {
  width: 70px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 8px;

  margin-right: 8px;
}

.upload-status-btn-success {
  background-color: rgb(179, 255, 166);
  color: rgba(33, 179, 107);
}

.upload-status-btn-fail {
  background-color: rgb(240, 170, 170);
  color: rgb(240, 0, 0);
}

.upload-status-btn-parsing {
  background-color: rgb(181, 206, 208);
  color: rgb(93, 131, 132);
}
</style>
