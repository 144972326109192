<template>
  <div class="choose-popup" v-if="isChoosePopup">
    <div class="mask"></div>
    <div class="content">
      <div class="title">
        <h2>{{ title }}</h2>
        <img src="@/assets/close.svg" alt @click="close" />
      </div>
      <ul class="items" v-if="isCtegories">
        <li
          class="item"
          v-for="(item, index) in lists"
          :key="index"
          @click="chooseItem(item, index)"
        >
          <div class="name">
            <img src="@/assets/select-act.svg" alt v-if="active === index" />
            {{ item.en_title }}
          </div>
          <div class="line"></div>
        </li>
      </ul>
      <ul class="items" v-else>
        <li
          class="item"
          v-for="(item, index) in lists"
          :key="index"
          @click="chooseItem(item, index)"
        >
          <div class="name">
            <img src="@/assets/select-act.svg" alt v-if="active === index" />
            {{ item.simple_name }} ({{ item.sign }})
          </div>
          <div class="line"></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "choose-popup",
  props: {
    isChoosePopup: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    lists: {
      type: Array,
    },
    isCtegories: {
      type: Boolean,
      default: true,
    },
    defaultActive: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      active: -1,
    };
  },
  watch: {
    defaultActive(newV, oldV) {
      console.log(newV);
      if (newV && newV > -1) {
        this.active = newV;
      }
    },
    isChoosePopup(newV, oldV) {
      this.active = -1;
    },
  },
  methods: {
    close() {
      this.$emit("close-popup");
    },
    chooseItem(item, index) {
      this.active = index;
      this.$emit("choose-item", item);
    },
  },
};
</script>

<style lang="less" scoped>
.choose-popup {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 99;
  .mask {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .content {
    width: 650px;
    background: #ffffff;
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.09);
    border-radius: 12px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px 45px 0px;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        font-size: 22px;
        font-family: "dtc-bold";
        color: #333333;
        line-height: 30px;
      }
      img {
        cursor: pointer;
        transition: transform 0.3s;
        position: relative;
        width: 14px;
        height: 14px;
        top: -28px;
        right: -32px;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
    .items {
      display: flex;
      flex-wrap: wrap;
      padding: 1px 0;
      margin: 2.8571rem 0px 0px;
      height: 400px;
      overflow: auto;
      .item {
        width: 242px;
        margin-right: 50px;
        list-style-type: none;
        margin-bottom: 18px;
        cursor: pointer;
        &:nth-of-type(2n) {
          margin-right: 0px;
        }
        .name {
          font-size: 1.1429rem;
          color: #333333;
          line-height: 30px;
          margin-bottom: 10px;
          img {
            width: 14px;
            height: 14px;
            margin-right: 4px;
            margin-top: -3px;
          }
        }
        .line {
          width: 100%;
          height: 1px;
          background-color: #dddddd;
        }
      }
    }
  }
}
</style>
