<template>
  <div class="settlement-order-wrap">
    <p>
      After the order takes effect, an order to be paid will be generated when
      the amount reaches $100 or within 30 days, and the platform will
      automatically deduct the payment
    </p>
    <div class="export">
      <button>Export</button>
    </div>
    <div
      class="table-content"
      v-for="(item, index) in settlementOrderLists"
      :key="index"
    >
      <div class="left-content">
        <div
          class="paid"
          :style="
            item.status == 'paid'
              ? 'background: #2898ff;color: #fff;'
              : 'background: #EEEEEE;'
          "
        >
          {{ item.status | filterStaus }}
        </div>
        <div class="money">{{ item.amount | toThousands }}</div>
      </div>
      <div class="right-content">
        <div class="title">
          <span>{{ item.created_time | filterTime }}</span>
          <span>
            Withdraw ID:
            <b>{{ item.id }}</b>
          </span>
        </div>
        <a-table
          :columns="columns"
          :data-source="item.children"
          bordered
          :scroll="{ y: 205 }"
          :pagination="false"
          :loading="isLoading"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          class="withdraw-order"
        >
          <template slot="order" slot-scope="text, record">
            <span>{{ record.oid }}</span>
          </template>
          <template slot="commssion" slot-scope="text, record">
            <span>{{ record.medium_commission | toThousands }}</span>
          </template>
          <template slot="PartnerBoost" slot-scope="text, record">
            <span>{{ record.local_profit | toThousands }}</span>
          </template>
          <template slot="time" slot-scope="text, record">
            <span>{{ record.trans_time | filterTime }}</span>
          </template>
          <template slot="id" slot-scope="text, record">
            <span>{{ record.medium_id }}</span>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "Order ID",
    dataIndex: "order",
    key: "order",
    scopedSlots: { customRender: "order" },
  },
  {
    title: "Commssion",
    dataIndex: "commssion",
    key: "commssion",
    scopedSlots: { customRender: "commssion" },
    width: 120,
  },
  {
    title: "PartnerBoost",
    dataIndex: "PartnerBoost",
    key: "PartnerBoost",
    scopedSlots: { customRender: "PartnerBoost" },
    width: 120,
  },
  {
    title: "Order Time",
    dataIndex: "time",
    key: "time",
    ellipsis: true,
    scopedSlots: { customRender: "time" },
    width: 180,
  },
  {
    title: "Publisher ID",
    dataIndex: "id",
    key: "id",
    ellipsis: true,
    scopedSlots: { customRender: "id" },
    width: 200,
  },
];
import axios from "@/utils/request";
import moment from "moment-timezone";
export default {
  name: "settlement-order",
  props: {
    time_start: {
      type: String,
    },
    time_end: {
      type: String,
    },
  },
  data() {
    return {
      data: [],
      columns,
      settlementOrderLists: [],
      isLoading: false,
    };
  },
  created() {
    this._getWithdrawLists();
  },
  methods: {
    _getWithdrawLists() {
      this.isLoading = true;
      axios
        .get("api_admin.php?a=withdraw/list", {
          params: {
            time_start: this.time_start,
            time_end: this.time_end,
          },
        })
        .then((res) => {
          if (res.code === 0) {
            this.isLoading = false;
            this.settlementOrderLists = res.data.data.map((item) => ({
              ...item,
              children: res.data.transactions[item.id] || [],
            }));
            // for (let i = 0; i < this.settlementOrderLists.length; i++) {
            //   // 将sku添加到data中，方便表格渲染
            //   this.settlementOrderLists[i].children = res.data.transactions[this.settlementOrderLists[i].id].map(item => ({
            //     ...item,
            //     // siteTitle: res.data.sites[item.medium_id]?.title || item.medium_id
            //     // siteTitle: item.medium_id
            //   }))
            // }
          } else {
            this.isLoading = false;
            this.$message.error(
              res.msg || "Sorry, server is busy. please try later."
            );
          }
        });
    },
  },
  filters: {
    filterStaus(status) {
      if (status == "new") {
        return "Unpaid";
      } else if (status == "paid") {
        return "Paid";
      } else {
        return "Void";
      }
    },
    filterTime(time) {
      let momentTime = moment.unix(time).format("L LT");
      return momentTime;
    },
  },
};
</script>

<style lang="less" scoped>
.settlement-order-wrap {
  margin-top: 22px;

  p {
    font-size: 1.1429rem;
    color: #999999;
    line-height: 20px;
    margin-bottom: 22px;
  }

  .export {
    margin-bottom: 22px;
    text-align: right;

    button {
      width: 116px;
      height: 32px;
      background: #fff;
      border-radius: 6px;
      font-size: 1rem;
      color: #2898ff;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      border: 1px solid #2898ff;
      vertical-align: middle;
      box-sizing: border-box;
      padding: 0;
    }
  }

  .table-content {
    display: flex;
    margin-bottom: 22px;

    .left-content {
      width: 182px;

      .paid {
        height: 44px;
        background: #eeeeee;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2857rem;
        font-family: "dtc-bold";
        color: #999999;
      }

      .money {
        height: 245px;
        background: #f2f9ff;
        border: 1px solid #eeeeee;
        border-right: none;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        font-family: "dtc-bold";
        color: #333;
      }
    }

    .right-content {
      width: calc(100% - 182px);
      border: 1px solid #eeeeee;

      .title {
        height: 44px;
        border-bottom: 1px solid #eeeeee;
        border-left: 0px;
        line-height: 44px;
        padding-left: 14px;

        span {
          margin-right: 14px;
          font-size: 1.1429rem;
          color: #999999;

          b {
            color: #333;
          }
        }
      }
    }
  }
}

.withdraw-order {
  /deep/ .ant-table table {
    border-radius: 0 !important;
  }

  /deep/ .ant-table-small {
    border-radius: 0 !important;
    border: none !important;

    // border-left: none !important;
    // border-right: none !important;
    // border-top: none !important;
    &.ant-table-bordered .ant-table-content {
      border-right: none !important;
    }

    > .ant-table-content .ant-table-header {
      border-radius: 0 !important;
      border-right: none !important;
      // overflow: hidden !important;
      // margin-bottom: 0 !important;
    }
  }

  /deep/
    .ant-table-bordered.ant-table-fixed-header
    .ant-table-scroll
    .ant-table-header::-webkit-scrollbar {
    border-top: none;
    border-right: none;
  }

  /deep/ .ant-table-thead > tr:first-child > th:first-child {
    border-top-left-radius: 0 !important;
  }

  /deep/ .ant-table-thead > tr:first-child > th:last-child {
    border-top-right-radius: 0 !important;
  }

  /deep/ .ant-table-bordered.ant-table-fixed-header .ant-table-header > table {
    border: none !important;
  }
}
</style>
