<template>
  <div class="campdetail">
    <Header collection="Campaigns"></Header>
    <a-spin :spinning="isLoading">
      <Brands
        collection="Campaigns"
        :subset="campaignObj.campaign_name"
        :link="true"
        path="/campaigns"
      ></Brands>
      <div class="campdetail-container">
        <div class="detail-left">
          <div class="left-title">Proposal</div>
          <PSelector
            class="mt10"
            size="large"
            style="font-size: 1rem;"
            v-model="channels"
            :options="channelList"
            multiple
            clearAble
            placeholder="Please select your channel name"
          />
          <PSelector
            class="mt10"
            size="large"
            style="font-size: 1rem;"
            v-model="payTypes"
            :options="campaignObj.payTypeList"
            multiple
            clearAble
            placeholder="Select your Compensation"
          />
          <TextWithCount
            class="mt10"
            :maxRow="5"
            :textMaxNum="100"
            v-model="contents"
            placeholder="A brief introduction on why you're a good fit for this campaign."
            @textChange="setContents"
          />
          <FileUplaod v-model="fileList" @fileChange="setFiles" />
          <div class="left-agreement">
            <img
              v-if="isAgreed"
              @click="agreeTo(false)"
              src="@/assets/public/icon-select.svg"
            />
            <img
              v-else
              @click="agreeTo(true)"
              src="@/assets/icon-selector.svg"
            />
            <span>
              I agree to the
              <a @click="showTermsTips = true">Terms & Agreements</a>
            </span>
          </div>
          <a-button class="submit-btn" type="primary" @click="applySubmit"
            >Apply Now</a-button
          >
        </div>
        <div class="detail-right">
          <div class="right-top">
            <div class="top-icon">
              <img :src="campaignObj.cover_image" />
            </div>
            <div class="top-info">
              <div class="top-info-title">
                {{ campaignObj.campaign_name }}
                <span style="margin-left:2px;"
                  >(MID: {{ campaignObj.mcid }})</span
                >
              </div>
              <div class="top-info-site">
                {{ campaignObj.site_url.replace("https://", "") }}
              </div>
              <div class="top-info-text" v-html="campaignObj.site_desc"></div>
            </div>
          </div>
          <div class="right-content">
            <div class="content-title">Campaign Info</div>
            <!-- 第一部分 -->
            <div class="payout-type content-info">
              <div class="payout-type-left info-title">
                Payout Type and Incentive
              </div>
              <div class="payout-type-right">
                <div
                  v-for="(ptype, index) in campaignObj.payout_type_enum"
                  :key="ptype.name"
                  class="payout-type-right-item payout-type-right-item1"
                >
                  <div class="payout-type-right-item1-name">
                    <span class="paytype-tags" :class="`${ptype.name}`">{{
                      ptype.value
                    }}</span>
                  </div>
                  <span>{{ ptype.rate }}</span>
                </div>
              </div>
            </div>
            <!-- 第二部分 -->
            <div class="payout-type content-info">
              <div class="payout-type-left info-title">
                Platform
              </div>
              <div class="payout-type-right">
                <div class="payout-type-right-item">
                  <img
                    v-for="(plat, index) in campaignObj.plat_list"
                    :key="`plat-list-${index}`"
                    :src="plat.icon"
                  />
                </div>
                <div class="payout-type-right-item">
                  <span class="info-title">Location</span>
                  <span class="info-text">{{
                    Array.isArray(campaignObj.region)
                      ? campaignObj.region.join(", ")
                      : campaignObj.region
                  }}</span>
                </div>
              </div>
            </div>
            <!-- 第三部分 -->
            <div class="payout-type content-info">
              <div class="payout-type-left info-title">
                Task Format
              </div>
              <div class="payout-type-right">
                <div class="payout-type-right-item">
                  <a-tooltip
                    v-for="(fot, index) in campaignObj.format_list"
                    :key="`format-list-${index}`"
                    :title="fot.full_name"
                  >
                    <img :src="fot.icon" />
                  </a-tooltip>
                </div>
                <div class="payout-type-right-item">
                  <span class="info-title">Deadline</span>
                  <span class="info-text">{{
                    dayjs(campaignObj.deadline_at).format("ll")
                  }}</span>
                </div>
              </div>
            </div>

            <div class="content-title">Campaign Time Line</div>
            <CampaignTimeLine
              :date1="dayjs(campaignObj.start_at).format('ll')"
              :date2="dayjs(campaignObj.deadline_at).format('ll')"
              :date3="dayjs(campaignObj.end_at).format('ll')"
              :stepIndex="stepIndex"
              :progress1="progress1"
              :progress2="progress2"
            />
            <div
              class="content-title"
              v-if="campaignObj.image_files && campaignObj.image_files.length"
            >
              Images
            </div>
            <div class="content-imgs">
              <img
                v-for="(img, index) in campaignObj.image_files"
                :key="`plat-list-${index}`"
                :src="img"
              />
            </div>
            <div class="content-title">Campaign Details</div>
            <div class="content-text" v-html="campaignObj.content"></div>
          </div>
        </div>
      </div>
    </a-spin>
    <Modal
      :isVisible="showTermsTips"
      :isShowCancel="true"
      title="Terms and Agreements"
      @close-popup="showTermsTips = false"
      width="50.42rem"
      submitText="I agree"
      cancelText="Disagree"
      @submit="agreeToSubmit"
    >
      <template #content>
        <p class="center">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
          euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan
          et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis
          natoque penatibus et magnis dis parturient montes, nascetur ridiculus
          mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus
          mollis orci, sed rhoncus pronin sapien nunc accuan eget.Lorem ipsum
          dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum
          laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo
          commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus
          et magnis dis parturient montes, nascetur ridiculus mus. Nam
          fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci,
          sed rhoncus pronin sapien nunc accuan eget. Aenean euismod bibendum
          laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo
          commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus
          et magnis dis parturient montes, nascetur ridiculus mus. Nam
          fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci,
          sed rhoncus pronin sapien nunc accuan eget.Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin
          gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin
          sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis
          parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus
          pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin
          sapien nunc accuan eget.
        </p>
      </template>
    </Modal>
    <Modal
      :isVisible="showInfoTips && isShowInfo"
      :isShowCancel="false"
      title=""
      @close-popup="closeTips"
      width="400px"
      submitText="OK"
      :isShowSubmit="false"
    >
      <template #content>
        <p class="center">
          If you want to apply campaign, please click
          <router-link :to="`/account/`">here</router-link> to complete your
          basic info first.
        </p>
      </template>
    </Modal>
  </div>
</template>
<script>
import Header from "@/components/public/Header.vue";
import Brands from "../../components/Brands.vue";
import TextWithCount from "@/components/TextWithCount.vue";
import FileUplaod from "@/components/public/FileUplaod.vue";
import CampaignTimeLine from "./components/CampaignTimeLine.vue";

import axios from "@/utils/request";
import Modal from "@/components/Modal.vue";
import apis from "@/api/const";
import { PAY_TYPES_SELECT } from "@/api/const";

export default {
  components: {
    Header,
    Brands,
    TextWithCount,
    FileUplaod,
    CampaignTimeLine,
    Modal,
  },
  data() {
    return {
      showTermsTips: false,
      isLoading: false,
      channels: [],
      isAgreed: false,
      payTypes: [],
      contents: "",
      PAY_TYPES_SELECT,
      campaignObj: {
        platform: [],
        payout_type: [],
        formats: [],
        image_files: [],
        pay_types: {},
        site_url: "",
      },
      channelList: [],
      fileList: [],
      isShowInfo: false,
      isApplied: false,
      //   subObj: {},
      platList: [],
    };
  },
  mounted() {
    this.getDetail();
    this.getChannels();
    this.getCommonList();
  },
  computed: {
    showInfoTips() {
      return this.$store.state.userInfo.is_check_account;
    },
    stepIndex() {
      if (this.dayjs() >= this.dayjs(this.campaignObj.end_at)) {
        return 3;
      } else if (this.dayjs() >= this.dayjs(this.campaignObj.deadline_at)) {
        return 2;
      }
      return 1;
    },
    progress1() {
      if (
        this.dayjs().format("YYYY-MM-DD") >=
        this.dayjs(this.campaignObj.deadline_at).format("YYYY-MM-DD")
      ) {
        return 100;
      }
      let result = 0;
      const totalNum = this.DateDaysDiff(
        this.dayjs(this.campaignObj.start_at).format("YYYY-MM-DD"),
        this.dayjs(this.campaignObj.deadline_at).format("YYYY-MM-DD")
      );
      const passedNum = this.DateDaysDiff(
        this.dayjs(this.campaignObj.start_at).format("YYYY-MM-DD"),
        this.dayjs().format("YYYY-MM-DD")
      );
      if (totalNum) {
        result = parseInt((passedNum * 100) / totalNum);
      }
      return result;
    },
    progress2() {
      if (
        this.dayjs().format("YYYY-MM-DD") >=
        this.dayjs(this.campaignObj.end_at).format("YYYY-MM-DD")
      ) {
        return 100;
      } else if (
        this.dayjs().format("YYYY-MM-DD") <=
        this.dayjs(this.campaignObj.deadline_at).format("YYYY-MM-DD")
      ) {
        return 0;
      }
      let result = 0;
      const totalNum = this.DateDaysDiff(
        this.dayjs(this.campaignObj.deadline_at).format("YYYY-MM-DD"),
        this.dayjs(this.campaignObj.end_at).format("YYYY-MM-DD")
      );
      const passedNum = this.DateDaysDiff(
        this.dayjs(this.campaignObj.deadline_at).format("YYYY-MM-DD"),
        this.dayjs().format("YYYY-MM-DD")
      );
      if (totalNum) {
        result = parseInt((passedNum * 100) / totalNum);
      }
      return result;
    },
  },
  methods: {
    getAppDetail() {
      axios
        .get(apis.campaign.app_detail, {
          params: {
            campaign_id: this.$route.params.id,
          },
        })
        .then((res) => {
          if (res.code === "0200" && res.payload.length !== 0) {
            this.channels = res.payload.channels;
            this.payTypes = res.payload.payout_type;
            this.fileList = res.payload.files;
            this.contents = res.payload.content;
          }
        });
    },
    closeTips() {
      this.isShowInfo = false;
    },
    setContents(text) {
      this.contents = text;
    },
    setFiles(files) {
      this.fileList = files;
    },
    postCreateData() {
      //   if (this.showInfoTips) {
      //     this.isShowInfo = true;
      //     return;
      //   }
      this.isLoading = true;
      console.log("fileList:", this.fileList);
      const files = this.fileList.map((item) => {
        return {
          path: item.prefix + item.src,
          file_name: item.name,
        };
      });
      const createData = {
        campaign_id: this.$route.params.id,
        channels: this.channels,
        payout_type: this.payTypes,
        content: this.contents,
        files,
        is_agree: 1,
      };

      console.log("数据:", createData);
      axios.post(apis.campaign.app_create, createData).then((res) => {
        if (res.code == "0200") {
          this.$message.success("Create Success.");
          let params = this.$route.params;
          let query = this.$route.query;
          this.$router.push({ name: "campaign_detail_preview", params, query });
        } else {
          this.$message.error(res.msg);
        }
        this.isLoading = false;
      });
    },
    applySubmit() {
      //   if (this.showInfoTips) {
      //     this.isShowInfo = true;
      //     return;
      //   }
      if (!this.isAgreed) {
        this.showTermsTips = true;
        return;
      }
      this.postCreateData();
    },
    // 获取详情
    getDetail() {
      this.isLoading = true;
      axios
        .get(apis.campaign.camp_detail, {
          params: {
            id: this.$route.params.id,
          },
        })
        .then((res) => {
          this.getAppDetail();
          this.isLoading = false;
          if (res.code === "0200") {
            this.campaignObj = {
              ...res.payload,
              plat_list: this.filterPlatforms(res.payload.platform),
              format_list: this.filterFormats(res.payload.formats),
              pay_types: this.filterPayTypes(res.payload.payout_type),
              payTypeList: res.payload.payout_type_enum.map((item) => {
                return {
                  value: item.name,
                  label: item.value,
                };
              }),
            };
            console.log(res, this.campaignObj, "this.campaignObj");
          } else {
            this.$message.error(res.msg || "");
          }
        });
    },
    // 获取chanel
    getChannels() {
      axios
        .get(apis.campaign.app_type, {
          params: {
            campaign_id: this.$route.params.id,
          },
        })
        .then((res) => {
          if (res.code === "0200") {
            this.channelList = res.payload.channel.map((item) => {
              let title =
                item.title +
                (this.getFlat(item) ? ` (${this.getFlat(item)})` : "");
              return {
                label: title,
                value: item.id,
              };
            });
          } else {
            this.$message.error(res.msg || "");
          }
        });
    },
    async getCommonList() {
      const resp = await axios.get(apis.channel.cata_list);
      const { site_platfom } = resp.payload;
      this.platList = site_platfom;
    },
    getFlat(item) {
      let res = "";
      if (item.site_type == "0") {
        res = this.platList.find((e) => e.id == item.social_platform_type)
          ?.title;
      } else if (item.site_type == "2") {
        res = item.app_platform_type == "0" ? "iOS" : "Android";
      }
      return res;
    },
    agreeToSubmit() {
      this.isAgreed = true;
      this.showTermsTips = false;
      //   this.postCreateData();
    },

    agreeTo(val) {
      this.isAgreed = val;
    },
  },
};
</script>

<style lang="less" scoped>
.campdetail {
  &-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    min-width: 75rem;
  }
  .detail-left {
    width: 29.8%;
    background: #ffffff;
    box-shadow: 0px 0px 12px 0px rgba(102, 118, 186, 0.1);
    border-radius: 1.1429rem;
    padding: 1.1429rem;
    .left-title {
      font-size: 1.4286rem;
      color: #333333;
      font-family: "prefer-bold";
    }
    .left-agreement {
      margin-top: 0.8571rem;
      display: flex;
      align-items: center;
      img {
        margin-right: 0.7143rem;
        cursor: pointer;
      }
      a {
        color: #3762d6;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .submit-btn {
      width: 100%;
      margin-top: 0.8571rem;
      background: #3762d6;
      box-shadow: 0px 0px 5px 0px rgba(55, 98, 214, 0.36);
      border-radius: 0.5714rem;
      color: #fff;
      height: 3.1429rem;
      border: #3762d6;
      &[disabled="disabled"] {
        background-color: #e0e0e0;
        box-shadow: none;
      }
      &:hover {
        opacity: 0.85;
      }
    }
  }
  .detail-right {
    width: 68%;
    .right-top {
      min-height: 16.2857rem;
      background: #ffffff;
      box-shadow: 0px 0px 12px 0px rgba(102, 118, 186, 0.1);
      border-radius: 1.1429rem;
      display: flex;
      padding: 0.8571rem 1.1429rem;
    }
    .top-icon {
      display: flex;
      align-items: center;
      img {
        width: 21.4286rem;
        height: 14.5714rem;
        object-fit: cover;
        border-radius: 1.1429rem;
      }
    }
    .top-info {
      margin-left: 1.1429rem;
      &-title {
        color: #333333;
        font-size: 1.1429rem;
        font-family: "prefer-bold";
      }
      &-site {
        font-size: 1.1429rem;
        color: #333333;
        font-family: "prefer-bold";
        margin-top: 0.5714rem;
      }
      &-text {
        color: #666666;
        line-height: 1.4286rem;
        margin-top: 0.5714rem;
        p {
          margin-bottom: 0;
        }
      }
    }
    .right-content {
      background: #ffffff;
      box-shadow: 0px 0px 12px 0px rgba(102, 118, 186, 0.1);
      border-radius: 1.1429rem;
      padding: 0.8571rem 1.1429rem;
      margin-top: 1.4286rem;
      .content-title {
        font-size: 1.1429rem;
        font-family: "prefer-bold";
        color: #333333;
        margin-top: 1.1429rem;
      }
      .content-info {
        display: flex;
        flex-wrap: wrap;
        > li {
          width: 50%;
          height: 3.7143rem;
          display: flex;
          align-items: center;
        }
        .info-title {
          color: #666666;
          width: 17.8571rem;
          flex-shrink: 0;
        }
        .info-paytype {
          display: flex;
          flex-wrap: wrap;
          span {
            margin-bottom: 3px;
          }
        }
        .info-format {
          display: flex;
          align-items: center;
          img {
            margin-right: 0.2857rem;
          }
        }
        .paytype-tags {
          border-radius: 0.2857rem;
          color: #fff;
          font-size: 0.8571rem;
          padding: 0.2143rem 0.5714rem;
          line-height: 1rem;
          &.cps {
            background: #28acff;
          }
          &.sponsored_fee {
            background: #8e67ff;
          }
          &.coupon_code {
            background: #15dcb3;
          }
          &.free_products {
            background: #ffc55a;
          }
          &.gift_cards {
            background: #ff7745;
          }
          &.other {
            background: #1567dc;
          }
        }
        .info-money {
          color: #3762d6;
          font-family: "prefer-bold";
        }
        .info-text {
          font-family: "prefer-bold";
          color: #333333;
        }
        .info-platform {
          display: flex;
          align-items: center;
          > img {
            width: 1.4286rem;
            height: 1.4286rem;
            object-fit: contain;
            margin-right: 0.4286rem;
          }
        }
      }
      .content-imgs {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1.1429rem;
        > img {
          width: 19rem;
          height: 10.7857rem;
          object-fit: cover;
          border-radius: 0.5714rem;
          margin-bottom: 1.1429rem;
          margin-left: 1.1429rem;
          &:nth-child(3n + 1) {
            margin-left: 0;
          }
        }
      }
      .content-text {
        color: #333333;
        margin-top: 0.4286rem;
      }
    }
  }
  .payout-type {
    display: flex;
    margin-top: 16px;
    &-left {
      width: 17.8571rem;
    }
    &-right {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.1429rem 0;
      &-item {
        span:first-child {
          margin-right: 2.5rem;
        }
        span:last-child {
          color: #3762d6;
          font-family: "prefer-bold";
        }
        > img {
          object-fit: contain;
          margin-right: 0.4286rem;
        }
      }
      &-item1 {
        display: flex;
        &-name {
          span {
            font-family: "prefer" !important;
          }
        }
      }
    }
  }
  /deep/ .top-info-text {
    p {
      margin-bottom: 0;
    }
  }
  /deep/ .content-text {
    p,
    span {
      font-family: prefer !important;
    }
  }
}
</style>
