<template>
  <ul class="campprev" v-if="item">
    <li class="campprev__item">
      <span class="campprev__item__title"
        >Location(s) <span :class="isOver ? 'red' : ''">*</span></span
      >
      <div class="campprev__item__content">
        {{ item.city ? upperFirst(item.city) + ", " : ""
        }}{{ item.country_name }}
      </div>
    </li>
    <li class="campprev__item">
      <span class="campprev__item__title"
        >Gender <span :class="isOver ? 'red' : ''">*</span></span
      >
      <div class="campprev__item__content">{{ item.gender }}</div>
    </li>
    <li class="campprev__item">
      <span class="campprev__item__title"
        >Age <span :class="isOver ? 'red' : ''">*</span></span
      >
      <div class="campprev__item__content">
        <span class="campprev__item__content__sm">From</span
        ><span class="campprev__item__content__normal">{{
          item.age_lower
        }}</span
        ><span class="campprev__item__content__sm">To</span
        ><span class="campprev__item__content__normal">{{
          item.age_upper
        }}</span>
      </div>
    </li>
    <li class="campprev__item">
      <span class="campprev__item__title"
        >Interests <span :class="isOver ? 'red' : ''">*</span></span
      >
      <div class="campprev__item__content">
        <span class="campprev__item__content__normal">{{
          item.interests
        }}</span>
      </div>
    </li>
    <li class="campprev__item" v-if="item.target_description">
      <span class="campprev__item__title">Target Description</span>
      <div class="campprev__item__content">
        <span class="campprev__item__content__normal">{{
          item.target_description
        }}</span>
      </div>
    </li>
  </ul>
</template>

<script>
import { upperFirst } from "lodash";
export default {
  props: {
    item: {
      type: Object,
      default: () => null,
    },
    isOver: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: { upperFirst },
  watch: {},
};
</script>

<style lang="less" scoped>
.campprev {
  margin-top: 16px;
  &__item {
    display: flex;
    margin-top: 20px;
    &__title {
      width: 200px;
      color: #999999;
      .red {
        color: red;
      }
    }
    &__content {
      width: calc(100% - 200px);
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      &__sm {
        font-size: 0.8571rem;
        color: #333333;
        font-weight: lighter;
        margin-right: 10px;
      }
      &__normal {
        margin-right: 10px;
      }
      &__bg {
        margin-right: 10px;
        font-weight: 600;
      }
    }
    &__platform {
      margin-right: 20px;
      display: flex;
      align-items: center;
      img {
        width: 18px;
        margin-right: 6px;
      }
    }
  }
}
</style>
