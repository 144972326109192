<template>
  <a-spin :spinning="uplaoding">
    <div class="textfile">
      <a-textarea
        class="textfile-text"
        v-model="textStr"
        :maxLength="textMaxNum"
        :auto-size="{ minRows: minRow, maxRows: maxRow }"
      />
      <div class="textfile-num">
        <span>{{ currentNum }}/{{ textMaxNum }}</span>
      </div>
      <div class="textfile-file">
        <div class="textfile-left">
          <div v-if="fileStr" class="textfile-left-file">
            <span class="textfile-left-left one-line">{{
              fileStr.split("/")[fileStr.split("/").length - 1]
            }}</span>
            <div class="textfile-left-right">
              <a-icon type="download" @click="downloadFile" />
              <a-icon type="close" @click="deleteFile" />
            </div>
          </div>
        </div>
        <a-upload
          name="imgFile"
          class="textfile-right"
          :show-upload-list="false"
          :before-upload="beforeUpload"
          @change="handleChangeUpload"
          :action="uploadUrl"
          accept=".doc, .docx, .txt"
        >
          <a-button type="primary"> <a-icon type="upload" /> Upload </a-button>
        </a-upload>
      </div>
    </div>
  </a-spin>
</template>

<script>
import _ from "lodash";
import { uploadUrl } from "@/utils/config";

export default {
  components: {},
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    textMaxNum: {
      type: Number,
      default: 8000,
    },
    minRow: {
      type: Number,
      default: 5,
    },
    maxRow: {
      type: Number,
      default: 15,
    },
  },
  computed: {},
  data() {
    return {
      uplaoding: false,
      uploadUrl: uploadUrl + "&check_file=1",
      currentNum: this.value.text ? this.value.text.length : 0,
      textStr: this.value.text,
      fileStr: this.value.file,
    };
  },
  watch: {
    textStr(newVal, oldVal) {
      this.currentNum = newVal.length;
      this.triggerChange({
        text: newVal,
        file: this.fileStr,
      });
    },
    fileStr(newVal, oldVal) {
      this.triggerChange({
        text: this.textStr,
        file: newVal,
      });
    },
  },
  created() {},
  methods: {
    downloadFile() {
      window.open(this.fileStr, "_blank");
    },
    deleteFile() {
      this.fileStr = "";
    },
    beforeUpload(file) {
      console.log("file:", file.type);
    },
    handleChangeUpload(info) {
      if (info.file.status === "uploading") {
        this.logoLoading = true;
        this.uplaoding = true;
        return;
      }
      if (info.file.status === "done") {
        this.uplaoding = false;
        this.logoLoading = false;
        this.fileStr = info.file.response.data.url;
      }
    },
    triggerChange(changedVal) {
      this.$emit("change", changedVal);
    },
  },
};
</script>

<style lang="less" scoped>
.textfile {
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 16px;
  background: #fff;
  &-text {
    border: none;
    background: none;
    resize: none;
    &:focus {
      border: none;
      box-shadow: none;
    }
  }
  &-file {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-left {
    width: calc(100% - 160px);
    &-file {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 2.8571rem;
      background: #f5f5f5;
      padding: 0 16px;
    }
    &-left {
      width: 500px;
    }
    &-right {
      width: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        font-size: 1.2857rem;
        cursor: pointer;
        :hover {
          opacity: 0.8;
        }
      }
    }
  }
  &-num {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    color: #999;
  }
}
</style>
