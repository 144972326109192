<template>
  <div class="reportapi">
    <Header collection="Tools"></Header>
    <div class="container">
      <div class="headers">
        <div class="headers-title">Request Information</div>
        <ul class="headers-list">
          <li class="headers-item">
            <span>URL:</span>
            <div>https://www.linkprefer.com/api.php?mod=medium&op=cashback</div>
          </li>
          <li class="headers-item">
            <span>Return Format:</span>
            <div>Json/xml</div>
          </li>
          <li class="headers-item">
            <span>HTTP request method:</span>
            <div>GET/POST</div>
          </li>
          <CopyToken />
        </ul>
      </div>
      <div class="content">
        <div class="comm-tables">
          <div class="comm-header">
            <div class="comm-header-left">
              <div>Request Parameter</div>
            </div>
          </div>
          <div class="comm-list">
            <a-table
              class="comtable"
              :columns="columns"
              :data-source="dataList1"
              :rowKey="(record, index) => record.name"
              :loading="isLoading"
              :pagination="false"
            >
              <div class="api-title" slot="apititle" slot-scope="text">
                {{ text }}
              </div>
            </a-table>
          </div>
        </div>
        <div class="comm-tables">
          <div class="comm-header">
            <div class="comm-header-left">
              <div>Return Parameter</div>
            </div>
          </div>
          <div class="comm-list">
            <a-table
              class="comtable"
              :columns="columns"
              :data-source="dataList2"
              :rowKey="(record, index) => record.name"
              :loading="isLoading"
              :pagination="false"
            >
              <div class="api-title" slot="apititle" slot-scope="text">
                {{ text }}
              </div>
            </a-table>
          </div>
        </div>
        <div class="comm-tables">
          <div class="comm-header">
            <div class="comm-header-left">
              <div>Result Code</div>
            </div>
          </div>
          <div class="comm-list">
            <a-table
              class="comtable"
              :columns="columns"
              :data-source="dataList3"
              :rowKey="(record, index) => record.name"
              :loading="isLoading"
              :pagination="false"
            >
              <div class="api-title" slot="apititle" slot-scope="text">
                {{ text }}
              </div>
            </a-table>
          </div>
        </div>
        <div class="json-list">
          <div class="json-title">Return Format</div>
          <JsonPretty :data="jsonStr" />
          <XmlPretty :dataSource="xmlStr" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/public/Header.vue";
import axios from "@/utils/request";
import CopyToken from "@/components/CopyToken.vue";
import JsonPretty from "@/components/JsonPretty.vue";
import XmlPretty from "@/components/XmlPretty.vue";

export default {
  filters: {
    filterStaus(status) {
      if (status == "new") {
        return "Unpaid";
      } else if (status == "paid") {
        return "Paid";
      } else {
        return "Void";
      }
    },
  },
  components: {
    Header,
    CopyToken,
    JsonPretty,
    XmlPretty,
  },
  data() {
    let columns = [
      {
        title: "Name",
        dataIndex: "name",
        width: 160,
        align: "left",
        scopedSlots: { customRender: "apititle" },
      },
      {
        title: "Description",
        dataIndex: "description",
        width: 1110,
        align: "left",
      },
    ];
    return {
      columns,
      dataList1: [
        {
          name: "token",
          description:
            "The unique identifier of the website to obtain information {required}",
        },
        {
          name: "type",
          description:
            "Return data format: json,xml {optional, the default is json}",
        },
        {
          name: "begin_date",
          description: "Begin date Format: 2015-01-01 {Required}",
        },
        {
          name: "end_date",
          description: "End date Format: 2015-01-01 {Required}",
        },
        {
          name: "datetype",
          description:
            "Date selection: eventupdate (order update time)|transactiondate (order transaction time) {optional, the default default is transactiondate}",
        },
        {
          name: "order_id",
          description:
            "Exists to query the order details {optional, default query list}",
        },
        {
          name: "status",
          description:
            "@value untreated| expired | effective |pre_effective: preliminary effective | pre_expired: preliminary expiration| all：all states {optional, default is untreated}",
        },
        {
          name: "tag",
          description: "Custom tag",
        },
      ],
      dataList2: [
        {
          name: "sign_id",
          description: "Record unique identifier",
        },
        {
          name: "m_id",
          description: "Merchant ID",
        },
        {
          name: "order_id",
          description: "Order number",
        },
        {
          name: "order_time",
          description: "Order transaction time",
        },
        {
          name: "report_time",
          description: "Order report time",
        },
        {
          name: "sale_amount",
          description: "Sale amount",
        },
        {
          name: "cashback",
          description: "Cashback",
        },
        {
          name: "status",
          description: "Order status",
        },
        {
          name: "tagcode",
          description: "Custom tag",
        },
        {
          name: "rebate",
          description: "Rebate rate",
        },
        {
          name: "maketool",
          description:
            "Launch tool: 0 default, 1 LinkMiner; 2: single product; 3: deal",
        },
        {
          name: "productID",
          description: "Product ID",
        },
        {
          name: "BID",
          description: "Material ID",
        },
        {
          name: "num",
          description: "Order item quantity",
        },
        {
          name: "ip",
          description: "IP",
        },
        {
          name: "referer_url",
          description: "Referer URL",
        },
        {
          name: "cross_device",
          description: "Whether cross-device: 1 yes, 0 no",
        },
      ],
      dataList3: [
        {
          name: "0",
          description: "Success",
        },
        {
          name: "1000",
          description: "Users do not exist",
        },
        {
          name: "1001",
          description: "Invalid token",
        },
        {
          name: "1002",
          description: "Call frequency is too high",
        },
        {
          name: "1003",
          description: "The input parameter is incorrect",
        },
        {
          name: "1004",
          description: "Too many input parameter entries",
        },
        {
          name: "1005",
          description: "The length of the logo cannot be greater than 50",
        },
        {
          name: "1006",
          description: "The query time span cannot exceed 62 days",
        },
      ],
      dataList4: [
        {
          name: "xxxxx",
          description: "xxxxxxx",
        },
      ],
      currentPage: 1,
      isLoading: false,
      jsonStr: {
        offset: 0,
        pageSize: 40000,
        status: {
          code: 0,
          msg: "success",
        },
        data: [
          {
            sign_id: "39d97d6a8df5dee6d0c58759968bf5d7",
            m_id: "6pm",
            order_id: "03338636639105",
            order_time: "1418313600",
            report_time: "1418313600",
            sale_amount: "12.99",
            cashback: "2.60",
            status: "untreated",
            tagcode: "",
            BID: "0",
            num: "",
            ip: "",
            referer_url: "",
            cross_device: "",
          },
        ],
      },
      xmlStr: `<xml version="1.0" encoding="utf-8">
  <root>
    <offset>0</offset>
    <pageSize>40000</pageSize>
    <status>
      <code>0</code>
      <msg>success</msg>
      <data>
        <item>
          <sign_id>39d97d6a8df5dee6d0c58759968bf5d7</sign_id>
          <m_id>6pm</m_id>
          <order_id>03338636639105 *Order number</order_id>
          <order_time>1418313600 *Order transaction time</order_time>
          <report_time>1418313600 *Order report time</report_time>
          <sale_amount>1299 *Sale amount</sale_amount>
          <cashback>260 *Cashback</cashback>
          <status>4 *Order status</status>
          <tagcode>*Custom tag</tagcode>
          <BID>*Material ID</BID>
          <num>*Order item quantity</num>
          <ip>*IP</ip>
          <referer_url>*Referer URL</referer_url>
          <cross_device>*Yes</cross_device>
        </item>
      </data>
    </status>
  </root>`,
    };
  },
  mounted() {},
  methods: {
    html2Escape(sHtml) {
      return sHtml.replace(/[<>&"]/g, function (c) {
        return { "<": "&lt;", ">": "&gt;", "&": "&amp;", '"': "&quot;" }[c];
      });
    },
    escape2Html(str) {
      var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
      return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function (all, t) {
        return arrEntities[t];
      });
    },
    onSelectChange() {},
    // 切换下一页
    paginationChange(page, pageSize) {
      //   this.currentPage = page;
      //   this.getDataList();
    },
  },
  beforeDestroy() {
    if (this.onWindowResize) {
      window.onresize = null;
    }
  },
};
</script>

<style lang="less" scoped>
.reportapi {
  .headers {
    display: flex;
    width: 100%;
    margin-top: 0.8571rem;
    flex-direction: column;
    background: #ffffff;
    border-radius: 16px;
    padding: 1.7143rem;
    &-title {
      font-size: 1.1429rem;
      color: #3762d6;
      font-family: "prefer-bold";
    }
    &-list {
    }
    &-item {
      margin-top: 16px;
      span {
        color: #999999;
      }
      div {
        margin-top: 3px;
        font-family: "prefer-bold";
      }
    }
  }
  .content {
    margin-top: 1.4286rem;
    .comm-tables {
      margin-top: 1.4286rem;
    }
    .comm-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      background: #fff;
      padding: 1.1429rem;
      border-radius: 16px 16px 0 0;
      flex-direction: column;
    }
    .comm-header-left {
      > div {
        font-size: 1.1429rem;
        color: #3762d6;
        font-family: "prefer-bold";
      }
    }
    .api-title {
      font-family: "prefer-bold";
    }
    .json-list {
      background: #ffffff;
      border-radius: 16px;
      padding: 1.7143rem;
      margin-top: 20px;
    }
    .json-title {
      color: #3762d6;
      font-size: 1.1429rem;
      font-family: "prefer-bold";
    }
  }
}

/deep/ .ant-table-tbody > tr > td {
  padding-left: 24px;
}
/deep/ .ant-table-thead > tr > th {
  padding-left: 24px;
}
.comm-header {
  padding-left: 24px !important;
}
</style>
