<template>
  <div class="list">
    <Brands collection="Publishers" subset="Publisher Discovery"></Brands>
    <SubMenu :btnLists="subMenus" mode="link" :current="active" @go="(val) => active = val"></SubMenu>

    <DiscoveryList v-if="active == 'list'"></DiscoveryList>
    <DiscoveryTemplate v-else-if="active == 'template'"></DiscoveryTemplate>
  </div>
</template>

<script>
import Brands from "../../components/Brands.vue";
import DiscoveryList from "./components/DiscoveryList.vue";
import DiscoveryTemplate from "./components/DiscoveryTemplate.vue";
import SubMenu from '../../components/SubMenu.vue'

export default {
  name: "discovery",
  components: {
    Brands,
    SubMenu,
    DiscoveryList,
    DiscoveryTemplate
  },
  data() {
    return {
      active: "list",
      subMenus: [{
        name: 'Recruit Publishers',
        path: 'list',
      }, {
        name: 'Invite Template',
        path: 'template',
      }],
    };
  },
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  margin-bottom: 100px;
}
</style>