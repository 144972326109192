<template>
    <div id="sort-icon-box" @click="toggleSort">
        <slot></slot>
        <div id="sort-icon">
            <div class="sorted" :class="`sorted-${sort}`"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        sort: {
            default: null,
            type: String
        },
        next: {
            default: 'desc',
            type: String
        },
        enableAsc: {
            default: false,
            type: Boolean
        }
    },
    methods: {
        toggleSort() {
            if (this.sort == null) {
                this.$emit('change', this.next)
            } else if (this.sort != this.next) {
                this.$emit('change', null);
            } else {
                this.$emit('change', this.next == 'desc' ? (this.enableAsc ? 'asc' : null) : 'desc');
            }
        }
    }
}
</script>
<style lang="less" scoped>
#sort-icon-box {
    display: inline-block;
    width: auto;
    cursor: pointer;
    user-select: none;
    #sort-icon {
        display: inline-block;
        height: 1em;
        width: 10px;
        vertical-align: middle;
        margin-left: 2px;
        .sorted {
            height: 12px;
            width: 10px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            position: relative;
            &::before {
                transition: all 0.2s ease;
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                content: " ";
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 5px solid #333;
                border-top: unset;
            }
            &::after {
                transition: all 0.2s ease;
                position: absolute;
                bottom: 0;
                left: 0;
                display: block;
                content: " ";
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid #333;
                border-bottom: unset;
            }
            &.sorted-desc::before {
                opacity: 0;
            }
            &.sorted-asc::after {
                opacity: 0;
            }
        }
    }
}
</style>