<template>
  <div class="payment-error">
    <NavHeader></NavHeader>
    <div class="error-content">
      payment-error-content
    </div>
  </div>
</template>

<script>
import NavHeader from './../components/NavHeader.vue'
export default {
  name: 'payment-error',
  components: {
    NavHeader
  },
  created() {
    this.paymentCallback()
  },
  methods: {
    paymentCallback() {
      let { PayerID, token } = this.$route.query
      axios.post('/api_admin.php?a=payment/callback', {
        token: token,
        PayerID: PayerID,
        state: 'cancel'
      }).then(res => {
        console.log(res)
      })
    }
  }
}
</script>