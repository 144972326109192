<template>
  <div class="flowrep">
    <Header collection="Reporting"></Header>
    <div class="container">
      <div class="headers"></div>
      <div class="content">
        <div class="comm-tables">
          <div class="comm-header">
            <div class="comm-header-left">
              <div>Batch upload rules:</div>
              <div>
                1. The order of submitted lost order parameters is: Sn,
                transaction time, advertiser, lid, order number, uid, order
                amount, currency, and submission reason.
              </div>
              <div>
                2. The date format is January 11, 2016. It can't be January 11,
                2016 or January 11, 2016.
              </div>
              <div>
                3. There are nine currencies: RMB, US dollar, British pound, new
                Taiwan dollar, euro, Japanese yen, Australian dollar, Korean won
                and Hong Kong dollar.
              </div>
              <div>
                4. There are three reasons for submission: paid orders are not
                tracked, valid orders are wrongly judged as invalid, and the
                amount of valid orders is inconsistent with the actual.
              </div>
              <div>
                5. Uid is optional. You can download the template first and
                import the data according to the prompts.
              </div>
            </div>
            <div class="comm-header-rigth">
              <div>
                The format of importing batch lost order data is as follows:
              </div>
              <a-button type="primary">Download</a-button>
            </div>
          </div>
          <div class="comm-list">
            <a-table
              class="comtable"
              :columns="columns"
              :data-source="dataLists"
              :rowKey="(record, index) => record.id"
              :loading="isLoading"
              :pagination="false"
              :scroll="{ x: '100%' }"
            >
            </a-table>
            <div class="footer">
              <a-button>Select the file to import</a-button>
              <a-button type="primary">Batch import</a-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/public/Header.vue";
import axios from "@/utils/request";

export default {
  filters: {
    filterStaus(status) {
      if (status == "new") {
        return "Unpaid";
      } else if (status == "paid") {
        return "Paid";
      } else {
        return "Void";
      }
    },
  },
  components: {
    Header,
    // Modal
  },
  data() {
    let columns = [
      {
        title: "No",
        dataIndex: "uname",
        width: 40,
        align: "center",
      },
      {
        title: "Date Initiate",
        dataIndex: "uname",
        width: 110,
        align: "center",
      },
      {
        title: "Advertiser",
        dataIndex: "uname",
        width: 110,
        align: "center",
      },
      {
        title: "LID",
        dataIndex: "uname",
        width: 110,
        align: "center",
      },
      {
        title: "Order No",
        dataIndex: "uname",
        width: 110,
        align: "center",
      },
      {
        title: "UID(optional)",
        dataIndex: "uname",
        width: 110,
        align: "center",
      },
      {
        title: "Order Amount",
        dataIndex: "uname",
        width: 110,
        align: "center",
      },
      {
        title: "Currency",
        dataIndex: "uname",
        width: 110,
        align: "center",
      },
      {
        title: "Reason To Submit",
        dataIndex: "uname",
        width: 110,
        align: "center",
      },
      {
        title: "Comment",
        dataIndex: "uname",
        width: 110,
        align: "center",
      },
    ];
    return {
      columns,
      dataLists: [
        {
          uname: "xxxxq",
          plat_list: "xxxxq",
          country: "xxxxq",
          contents_total: "contents_total",
          followers: "2020-11-28",
          status: "Verifying",
        },
        {
          uname: "xxxxq",
          plat_list: "xxxxq",
          country: "xxxxq",
          contents_total: "contents_total",
          followers: "2020-11-28",
          status: "Approved",
        },
        {
          uname: "xxxxq",
          plat_list: "xxxxq",
          country: "xxxxq",
          contents_total: "contents_total",
          followers: "2020-11-28",
          status: "Rejected",
        },
      ],
      currentPage: 1,
      isLoading: false,
    };
  },
  mounted() {},
  methods: {
    // 切换下一页
    paginationChange(page, pageSize) {
      //   this.currentPage = page;
      //   this.getDataList();
    },
  },
  beforeDestroy() {
    if (this.onWindowResize) {
      window.onresize = null;
    }
  },
};
</script>

<style lang="less" scoped>
.flowrep {
  .headers {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 0.8571rem;
  }
  .content {
    .comm {
      &-header {
        flex-direction: column;
      }
    }
    .comm-header-left {
      width: 100%;
      > div {
        margin-right: 1.7143rem;
        color: #999999;
        line-height: 1.6429rem;
      }
    }
    .comm-header-rigth {
      margin-top: 1.4286rem;
      display: flex;
      width: 100%;
      align-items: center;
      > div {
        font-family: "prefer-bold";
      }
      > button {
        margin-left: 1.1429rem;
      }
    }
    .footer {
      display: flex;
      margin: 20px;
      > button {
        margin-right: 20px;
      }
    }
  }
  //   /deep/ .ant-table-thead > tr > th {
  //     border: 1px solid #fff;
  //     font-size: 0.9286rem;
  //   }
  //   /deep/ .ant-table-thead > tr:first-child > th:first-child,
  //   /deep/ .ant-table-thead > tr:last-child > th:last-child {
  //     border-left: none;
  //   }
  /deep/ .ant-select {
    width: 14.2857rem;
  }
  /deep/.ant-calendar-picker-input.ant-input {
    height: 2.8571rem;
    width: 260px;
  }
}
</style>
