<template>
  <div class="branddet">
    <Header collection="Brands"></Header>
    <Brands
      collection="Brands"
      :link="true"
      path="/brands"
      :subset="brandObj.merchant.sitename"
    />
    <a-spin :spinning="isLoading">
      <div class="branddet-container">
        <div class="branddet-left">
          <div class="left-top">
            <img :src="brandObj.merchant.logo" />
            <div class="ltop-right">
              <ul class="ltop-right-info">
                <li class="ltop-right-info-name">
                  {{ brandObj.merchant.sitename }}
                </li>
                <li class="ltop-right-info-id">
                  ID:{{ brandObj.merchant.m_id }}
                </li>
                <li class="ltop-right-info-url">
                  <a
                    style="color: #3762d6;"
                    :href="brandObj.merchant.site_url"
                    target="_blank"
                    rel="noopener noreferrer"
                    >{{ brandObj.merchant.site_url }}</a
                  >
                </li>
                <li class="ltop-right-info-nation">
                  <img :src="webRoot + brandObj.merchant.country_logo" />
                  {{ brandObj.merchant.country }}
                </li>
              </ul>
              <div class="ltop-right-button">
                <a-button
                  v-if="formatStatusText(brandObj.join_type)"
                  type="primary"
                  @click="singleJoin"
                  >Join</a-button
                >
                <span v-else>{{ brandObj.join_type }}</span>
              </div>
            </div>
          </div>
          <div class="left-content">
            <div class="left-content-title">Brand Description</div>
            <div
              class="left-content-text"
              v-if="brandObj.description.normal && brandObj.description.special"
            >
              <div v-html="brandObj.description.normal"></div>
              <div v-html="brandObj.description.special"></div>
            </div>
          </div>
          <div class="left-table">
            <div class="comm-header" style="padding-bottom:16px;">
              <div class="comm-header-left">
                <span>Commission Rate</span>
              </div>
              <div class="comm-header-rigth">
                <a-radio-group
                  default-value="a"
                  button-style="solid"
                  @change="rateSelect"
                >
                  <a-radio-button value="a">
                    Current
                  </a-radio-button>
                  <a-radio-button value="b">
                    History
                  </a-radio-button>
                </a-radio-group>
              </div>
            </div>
            <div class="comm-list">
              <a-table
                class="comtable"
                :columns="columns"
                :data-source="dataLists"
                :rowKey="(record, index) => index"
                :loading="isLoading"
                :pagination="false"
              >
                <template slot="period" slot-scope="text">
                  <div>{{ text }}</div>
                </template>
                <template slot="status" slot-scope="text, row">
                  <div v-if="!text">-</div>
                  <div
                    v-else-if="text.includes(':')"
                    style="color: #3762d6;cursor: pointer"
                    @click="viewDetail(row)"
                  >
                    More Details
                  </div>
                  <div v-else>
                    <div v-if="text.length > 50">
                      <div v-if="row.show" @click="toggle(row)">
                        <div>{{ text }}</div>
                        <div style="color: #3762d6;cursor: pointer">
                          View Less
                        </div>
                      </div>
                      <div
                        style="color: #3762d6;cursor: pointer"
                        v-else
                        @click="toggle(row)"
                      >
                        View More
                      </div>
                    </div>
                    <div v-else>
                      {{ text }}
                    </div>
                  </div>
                </template>
                <div slot="customCommission">
                  <a-tooltip>
                    <template slot="title">
                      When the base commission is displayed as "Rev. Share:
                      70%," it means that LinkPrefer will pay you 70% of the
                      sales commission we receive from the merchant.
                    </template>
                    <div style="white-space: nowrap;">
                      Default Commission
                      <img
                        class="question-img"
                        src="@/assets/question.png"
                        alt=""
                      />
                    </div>
                  </a-tooltip>
                </div>
                <template slot="commission" slot-scope="text, row">
                  <span v-if="row.aff_name == 'Rev. Share'"
                    >{{ row.aff_name }}:&nbsp;</span
                  >
                  <div>{{ row.aff_ba }}</div>
                </template>
              </a-table>
            </div>
          </div>
          <div class="left-desc">
            <a-collapse expand-icon-position="right">
              <a-collapse-panel key="1" header="Shipping Destination">
                <ul class="country-list">
                  <li
                    v-for="(item, index) in brandObj.mailing_country"
                    :key="`dest-list-${index}`"
                  >
                    {{ item.area }}
                  </li>
                </ul>
              </a-collapse-panel>
            </a-collapse>
          </div>
          <div
            class="left-desc"
            v-if="JSON.stringify(brandObj.flow_restrict) !== '[]'"
          >
            <a-collapse expand-icon-position="right">
              <a-collapse-panel
                key="1"
                :header="
                  brandObj.flow_restrict.model_type == 0
                    ? 'Traffic Allowed Area'
                    : 'Traffic Restricted Area'
                "
              >
                <ul class="country-list">
                  <li
                    v-for="(item, index) in brandObj.flow_restrict.country"
                    :key="`country-list-${index}`"
                  >
                    {{ item }}
                  </li>
                </ul>
              </a-collapse-panel>
            </a-collapse>
          </div>
          <div
            class="left-desc"
            v-if="brandObj.policies && brandObj.policies.length > 0"
          >
            <a-collapse v-model="activeKey" expand-icon-position="right">
              <a-collapse-panel key="1" header="Promotion Guidelines">
                <ul class="policies">
                  <li v-for="item in brandObj.policies" :key="item.policyId">
                    <div class="policies-title">
                      {{ item.policyTitle }}
                      <a-popover>
                        <template slot="content">
                          {{ item.policyTooltip }}
                        </template>
                        <img src="@/assets/ques-icon1.svg" />
                      </a-popover>
                    </div>
                    <div class="policies-desc">
                      {{ item.policyText }}
                    </div>
                  </li>
                </ul>
              </a-collapse-panel>
            </a-collapse>
          </div>
        </div>
        <div class="branddet-right">
          <div class="right-title" style="margin-top: 8px;">
            Affiliate Links
          </div>
          <a-select
            placeholder="Please Select your Channel"
            :getPopupContainer="
              (triggerNode) => {
                return triggerNode.parentNode || document.body;
              }
            "
            v-model="siteId"
          >
            <a-select-option
              v-for="(site, index) in $store.state.siteInfo"
              :key="`site-${index}`"
              :value="site.id"
            >
              {{ site.title }} ({{ site.medium_id }})
            </a-select-option>
          </a-select>
          <a-input placeholder="Target URL" v-model="siteUrl"></a-input>
          <a-input placeholder="u_id" v-model="uTag"></a-input>
          <a-button type="primary" @click="getLink">Get Links</a-button>
          <div class="right-links" v-show="showLinks">
            <div class="links-tile">
              <span :class="{ active: linkNum == 0 }" @click="swithcLink(0)"
                >Default</span
              >
              <span :class="{ active: linkNum == 2 }" @click="swithcLink(2)"
                >Short URL</span
              >
            </div>
            <div v-if="linkNum == 0" class="links-text">
              {{ linkObj.link }}
            </div>
            <div v-else-if="linkNum == 1" class="links-text">
              {{ linkObj.weibo }}
            </div>
            <div v-else-if="linkNum == 2" class="links-text">
              {{ linkObj.short_link }}
            </div>
          </div>
        </div>
      </div>
    </a-spin>
    <Modal
      :isVisible="showInfoTips && isShowInfo"
      :isShowCancel="false"
      title=""
      @close-popup="closeTips"
      width="400px"
      submitText="OK"
      :isShowSubmit="false"
    >
      <template #content>
        <p class="center">
          If you want to apply campaign, please click
          <router-link :to="`/account/`">here</router-link> to complete your
          basic info first.
        </p>
      </template>
    </Modal>

    <a-modal
      :visible="showDetail"
      :showBtn="false"
      width="600px"
      :closable="false"
      :footer="null"
      :bodyStyle="{
        padding: 0,
        borderRadius: 4,
      }"
      centered
    >
      <div class="head _flex1">
        <div class="_flex">
          <div style="font-family: prefer-bold">
            Details - Basic Commission
          </div>
        </div>
        <div class="_flex">
          <div class="_flex _cursor" @click="copyCode">
            <img src="@/assets/copy.svg" alt="img" />
            <div style="color:#585F82;font-size:14px">Copy Details</div>
          </div>
          <img
            style="cursor:pointer"
            @click="showDetail = false"
            src="@/assets/close-icon.svg"
            alt="img"
          />
        </div>
      </div>
      <div class="body">
        <div class="content">
          <div class="item" v-for="item in details" :key="item.title">
            <div class="_ellipsis" :title="item.title">{{ item.title }}</div>
            <div>{{ item.rate }}</div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import Header from "@/components/public/Header.vue";
import Brands from "@/components/Brands.vue";
import TextWithCount from "@/components/TextWithCount.vue";
import FileUplaod from "@/components/public/FileUplaod.vue";

import axios from "@/utils/request";
import Modal from "@/components/Modal.vue";
import apis from "@/api/const";
import { webRoot } from "@/utils/config";

export default {
  components: {
    Header,
    Brands,
    TextWithCount,
    FileUplaod,
    // CampaignTimeLine,
    Modal,
  },
  data() {
    let columns = [
      {
        title: "Name",
        dataIndex: "name",
        width: 160,
        align: "center",
      },
      {
        dataIndex: "aff_ba",
        width: 100,
        align: "center",
        scopedSlots: { customRender: "commission" },
        slots: { title: "customCommission" },
      },
      {
        title: "Commission period",
        dataIndex: "period",
        align: "center",
        scopedSlots: { customRender: "period" },
      },
      {
        title: "Available Products",
        dataIndex: "contents_total",
        width: 160,
        align: "center",
      },
      {
        title: "More Details",
        dataIndex: "desc",
        align: "center",
        scopedSlots: { customRender: "status" },
      },
    ];
    return {
      webRoot,
      columns,
      dataLists: [],
      showTermsTips: false,
      isLoading: false,
      isAgreed: false,
      now_list: [],
      history_list: [],
      brandObj: {
        merchant: {},
        description: {},
        flow_restrict: {},
      },
      siteId: undefined,
      siteUrl: "",
      uTag: "",
      showLinks: false,
      linkNum: 0,
      linkObj: {},
      isShowInfo: false,
      showDetail: false,
      currentRow: {},
      activeKey: ["1"],
    };
  },
  mounted() {
    this.getDetail();
  },
  computed: {
    showInfoTips() {
      return this.$store.state.userInfo.is_check_account;
    },
    details() {
      if (!this.currentRow.desc) return [];
      const list = this.currentRow.desc.split("; ");
      return list
        .map((e) => {
          return {
            title: e.split(":")?.[0],
            rate: e.split(":")?.[1],
          };
        })
        .filter((e) => !!e.title);
    },
  },
  methods: {
    toggle(row) {
      this.dataLists = this.dataLists.map((e) => {
        if (e.id == row.id) {
          return { ...e, show: !e.show };
        }
        return e;
      });
    },
    copyCode() {
      this.$copyText(this.currentRow.desc).then(
        (e) => {
          this.$message.success("Copied");
        },
        (e) => {
          this.$message.error("Can not copy");
        }
      );
    },
    viewDetail(row) {
      this.currentRow = row;
      this.showDetail = true;
    },
    singleJoin() {
      console.log(this.$route);
      const joinData = new FormData();

      joinData.append("site_id", this.$route.params.sid);
      joinData.append("m_id", this.$route.params.id);
      axios.post(apis.brands.join_site, joinData).then((res) => {
        if (res.code == "0200") {
          this.$message.success("Join Success.");
        } else {
          this.$message.error(res.msg);
        }
        this.isLoading = false;
      });
    },
    closeTips() {
      this.isShowInfo = false;
    },
    swithcLink(val) {
      this.linkNum = val;
    },
    rateSelect(e) {
      if (e.target.value === "a") {
        this.dataLists = this.now_list;
      } else if (e.target.value === "b") {
        this.dataLists = this.history_list;
      }
    },
    // 获取链接
    getLink() {
      if (this.showInfoTips) {
        this.isShowInfo = true;
        return;
      }
      const linkData = {
        site_id: this.siteId,
        m_id: this.$route.params.id,
        site_url: this.siteUrl,
        u_tag: this.uTag,
        is_short_link: 1,
        formData: true,
      };
      this.isLoading = true;
      axios.post(apis.common.get_link, linkData).then((res) => {
        this.isLoading = false;
        if (res.code == "0200") {
          this.showLinks = true;
          this.linkObj = res.payload.link_list;
          this.$message.success("Get Link Success.");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 获取详情
    getDetail() {
      this.isLoading = true;
      axios
        .get(apis.brands.store_detail, {
          params: {
            m_id: this.$route.params.id,
            site_id: this.$route.params.sid,
          },
        })
        .then((res) => {
          this.isLoading = false;
          if (res.code === "0200") {
            this.brandObj = {
              ...res.payload,
            };
            this.now_list = this.brandObj.now_commission;
            this.history_list = this.brandObj.history_commission;
            this.dataLists = this.now_list;
            this.siteUrl = this.brandObj.merchant.site_url;
            this.activeKey = res.payload?.policies?.length > 0 ? ["1"] : [];
          } else {
            this.$message.error(res.msg || "");
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.branddet {
  &-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 16px;
    min-width: 75rem;
  }
  &-left {
    width: 68%;
    .left-top {
      display: flex;
      height: 12.8571rem;
      background: #ffffff;
      border-radius: 16px;
      padding: 16px;
      align-items: center;
      > img {
        width: 14.17rem;
        height: 8.45rem;
        border-radius: 8px;
        flex-shrink: 0;
        object-fit: contain;
      }
      .ltop-right {
        width: 100%;
        margin-left: 16px;
        display: flex;
        justify-content: space-between;
        &-info {
          font-size: 1.1429rem;
          &-name {
            font-size: 1.4286rem;
            font-family: "prefer-bold";
            margin-top: 6px;
          }
          &-id {
            margin-top: 10px;
            font-family: "prefer-bold";
          }
          &-url {
            margin-top: 10px;
            font-family: "prefer-bold";
          }
          &-nation {
            margin-top: 10px;
            display: flex;
            align-items: center;
            img {
              width: 16px;
              width: 16px;
              margin-right: 6px;
            }
          }
        }
        &-button {
          margin-top: 110px;
        }
      }
    }
    .left-content {
      background: #ffffff;
      border-radius: 16px;
      padding: 16px;
      margin: 20px 0;
      &-title {
        font-family: "prefer-bold";
        font-size: 1.2857rem;
      }
      &-text {
        margin-top: 12px;
        line-height: 21px;
      }
    }
    .left-desc {
      margin-top: 20px;
    }
  }
  &-right {
    width: 29.8%;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-radius: 16px;
    padding: 16px;
    .right-title {
      font-family: "prefer-bold";
      font-size: 1.2857rem;
      margin-bottom: 4px;
    }
    > div,
    > input {
      margin-top: 12px;
    }
    > button {
      margin-top: 22px;
    }
    .right-links {
      margin-top: 20px;
      .links-tile {
        > span {
          margin-right: 12px;
          cursor: pointer;
          font-family: "prefer-bold";
          font-size: 1.1429rem;
          &:hover {
            opacity: 0.8;
          }
          &.active {
            color: #3762d6;
          }
        }
      }
      .links-text {
        margin-top: 12px;
        border-radius: 8px;
        border: 1px solid #e6e6e6;
        padding: 1.1429rem;
        min-height: 11.4286rem;
        word-break: break-all;
      }
    }
  }
  .dest-list {
    display: flex;
    flex-wrap: wrap;
    li {
      width: calc(100% / 9);
      margin-bottom: 0.8571rem;
      text-align: center;
    }
  }
  .comm-header-left {
    font-size: 1.2857rem;
    font-family: "prefer-bold";
  }
  .comm-header-more {
    color: #333333;
    cursor: pointer;
    i {
      font-size: 0.7143rem;
    }
  }
  .country-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    li {
      padding: 10px 20px;
      border-radius: 4px;
      background: #f6f9ff;
    }
  }
}
/deep/ .ant-collapse {
  border: none;
  background: #fff;
  border-radius: 8px;
}
/deep/.ant-collapse-content {
  border-top: none;
}
/deep/.ant-collapse > .ant-collapse-item {
  border-bottom: none;
}
/deep/.ant-collapse-header {
  font-size: 1.2857rem;
  font-family: "prefer-bold";
}
/deep/ .ant-input {
  height: 2.8571rem;
}
/deep/ .ant-select-dropdown-menu-item {
  text-align: left;
}
/deep/ .ant-collapse-header {
  height: 60px;
  display: flex;
  align-items: center;
}

/deep/ .ant-radio-button-wrapper {
  border-color: #3762d6;
  color: #3762d6;
}

/deep/ .ant-radio-button-wrapper:first-child {
  border-color: #3762d6;
}
.question-img {
  height: 14px;
  margin-left: 2px;
}

._flex1 {
  display: flex;
  justify-content: space-between;
}
._flex {
  display: flex;
  align-items: center;
  gap: 8px;
}
.head {
  padding: 24px 32px;
  border-bottom: 1px solid #eaebef;
  font-size: 16px;
}
.body {
  padding: 24px 32px;
  font-size: 16px;
  max-height: 536px;
  overflow-x: hidden;
  overflow-y: auto;
  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    .item {
      border-radius: 5px;
      background: #f1f3ff;
      padding: 10px 20px;
      overflow: hidden;
      div {
        &:first-child {
          color: #585f82;
        }
        &:nth-of-type(2) {
          color: #3f65cf;
        }
      }
    }
  }
}
.policies {
  &-title {
    color: #333;
    font-family: "prefer-bold";
    font-size: 14px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 12px;
  }
  &-desc {
    color: #676767;
    font-family: "prefer";
    font-size: 16px;
    margin-bottom: 20px;
  }
}
</style>
