import Vue from "vue";
import moment from "moment-timezone";
import axios from "./request";
import config from "./config";
import { DEFAULT_LOGO, PLATFORMS, PAY_TYPES, FORMATS } from "@/api/const";
const dayjs = require("dayjs");
const localizedFormat = require("dayjs/plugin/localizedFormat");
const duration = require("dayjs/plugin/duration");
dayjs.extend(localizedFormat);
dayjs.extend(duration);

function formatFormData(object) {
  let data = new FormData();
  if (Object.prototype.toString.call(object).slice(8, -1) === "Object") {
    Object.entries(object).map(([key, val]) => {
      data.append(key, val);
    });
  }
  return data;
}

function toThousands(number, ignoreCurrency, showPrefix) {
  let num = number;
  if (number.includes(",")) {
    num = Number(number.replaceAll(",", ""));
    if (Number.isNaN(num) || !num) {
      num = 0;
    }
  }
  let currency = "$"; // num != 0 ? "$" : "";
  if (ignoreCurrency) {
    currency = "";
  }
  let greatthan0 = num > 0 ? true : false;
  num = Math.abs(num || 0).toString();
  let st1 = num.includes(".") ? num.slice(0, num.indexOf(".")) : num;
  let st2 = num.includes(".") ? num.slice(num.indexOf(".")) : "";

  let result = "";
  while (st1.length > 3) {
    result = "," + st1.slice(-3) + result;
    st1 = st1.slice(0, st1.length - 3);
  }
  if (st1) {
    result = st1 + result;
  }
  let prefix = "";
  if (showPrefix) {
    prefix = greatthan0 ? "+" : "-";
  }
  return prefix + currency + result + st2;
}

// 数字转为千分位，并保留两个小数位
function toThousandsAndFixed2(oldNum, isFixed = false) {
  // 例（123456.78）
  if (oldNum === "" || oldNum === undefined) return "0"; // 传入的数值为空直接返回空对象
  let newNum = Number(oldNum).toLocaleString("en-US"); // 数字转成千分位 = 123,456.78
  const numArr = newNum.split("."); // 按小数点吧数字拆分 = [123,456, 78]
  if (!isFixed) {
    // 如果传了第二个参数，如果有小数位直接返回，否则向下执行
    if (!numArr[1]) {
      // 如果数组没有下标1的元素，就加.00，例：123,456 = 123,456.00
      newNum = newNum + ".00";
    } else if (numArr[1].length === 1) {
      // 如果数组下标1的元素只有一位小数，就加个0，例：123,456.7 = 123,456.70
      newNum = newNum + "0";
    } else if (numArr[1].length >= 2) {
      // // 如果数组下标1的元素小数位大于等于2位，就截取前两位，例：123,456.789 = 123,456.78
      newNum = numArr[0] + "." + numArr[1].substr(0, 2);
    }
  }
  return newNum;
}

function formatMDY(timestamp, defaultValue) {
  return parseInt(timestamp)
    ? moment.unix(timestamp).format("L")
    : defaultValue || null;
}

function formatMDYHI(timestamp, defaultValue) {
  return parseInt(timestamp)
    ? moment.unix(timestamp).format("L LT")
    : defaultValue || null;
}

function ucfirst(text) {
  return text ? text[0].toUpperCase() + text.substr(1).toLowerCase() : "";
}

function getUUID() {
  let s = [];
  const hexDigits = "0123456789abcdef";
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4";
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
  s[8] = s[13] = s[18] = s[23] = "-";
  let uuid = s.join("");
  return uuid;
}

function refreshUserInfo() {
  return axios.get("/api_admin.php?a=user/userInfo").then((res) => {
    if (res.code === 0) {
      this.$store.commit("setAccountDetails", res.data);
      return res.data;
    } else {
      if (res.message == "user does not exist") {
        this.$store.commit("clearToken");
        this.$router.replace({ name: "login" });
      }
      return null;
    }
  });
}

function siteIdToMediumId(siteId) {
  if (!siteId) {
    return null;
  } else {
    return "PB" + (siteId + "").padStart(8, "0");
  }
}
function getSourceUrl(str) {
  if (!str) {
    str = DEFAULT_LOGO;
  } else if (str.indexOf("http") !== 0 && str.indexOf("//") !== 0) {
    str = config.OSS_HOST + str;
  }
  return str;
}
function timeToUsFormat(timestamp) {
  let date = new Date(timestamp * 1000);
  return dayjs(date).format("ll");
}
function formatTimeDuration(seconds) {
  return dayjs.duration(seconds * 1000).format("mm:ss");
}

function formatTimestamp(timestamp) {
  return dayjs(new Date(timestamp * 1000)).format("MM/DD/YYYY");
}

function filterPlatforms(list) {
  let res = [];
  for (let item of list) {
    for (let plt of PLATFORMS) {
      if (item === plt.name) {
        res.push(plt);
      }
    }
  }
  return res;
}
function getPlatformObj(name) {
  let res = PLATFORMS.find((item) => {
    return item.name === name;
  });
  return res || {};
}

function filterFormats(list) {
  let res = [];
  for (let item of list) {
    for (let plt of FORMATS) {
      if (item === plt.name) {
        res.push(plt);
      }
    }
  }
  res = res.sort((a, b) => {
    return a.order - b.order;
  });
  return res;
}
function getFormatObj(name) {
  let res = FORMATS.find((item) => {
    return item.name === name;
  });
  return res || {};
}

function filterCommonConfig(list, id) {
  let res = list.find((item) => {
    return item.id === id;
  });
  return res || {};
}

function formatBigNumber(num) {
  if (isNaN(num)) {
    return num;
  }
  if (num < 1000) {
    return num;
  } else if (num < 1000000) {
    num = (num / 1000).toFixed(1);
    return num + "K";
  } else {
    num = (num / 1000000).toFixed(2);
    return num + "M";
  }
}

function transformUpdateTime(time) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = new Date(time);
  return {
    months: months[date.getMonth()],
    day: date.getDate(),
    year: date.getFullYear(),
  };
}
/**
 * 支付方式
 * @param {*} list
 * @returns
 */
function filterPayTypes(list, wid) {
  let res = {
    shows: [],
    hides: [],
    isEq: true,
  };
  let width = 0;
  let hasCps = false;
  for (let item of list) {
    for (let plt of PAY_TYPES) {
      if (item === plt.name) {
        res.hides.push(plt);
        width = width + plt.width;
        if (width <= wid) {
          res.shows.push(plt);
        } else {
          res.isEq = false;
        }
      }
    }
  }
  res.hides = res.hides.sort((a, b) => {
    return a.order - b.order;
  });
  return res;
}
/**
 * 计算天数差的函数，通用
 * @param {*} startDate
 * @param {*} enDate
 * @returns
 */
function DateDaysDiff(startDate, enDate) {
  if (!startDate || !enDate) {
    return -2;
  }
  const sDate = Date.parse(startDate);
  const eDate = Date.parse(enDate);
  if (sDate > eDate) return -1; //开始日期大于结束日期，返回0
  if (sDate === eDate) return 0; //如果日期相同 返回一天
  return (eDate - sDate) / (24 * 60 * 60 * 1000);
}
/**
 * 导出文件
 * @param {*} downloadData
 * @param {*} filename
 */
const fileDownloader = (downloadData, filename) => {
  try {
    let data = null;
    let binaryData = [];
    // let blob = new Blob([downloadData], { type: "application/vnd.ms-excel" });
    let blob = new Blob([downloadData]);
    binaryData.push(blob);
    data = window.URL.createObjectURL(new Blob(binaryData));

    const tag = document.createElement("a");
    tag.href = data;
    tag.target = "_blank";
    tag.download = filename;
    document.body.appendChild(tag);
    tag.click();
    tag.remove();
  } catch (e) {
    console.log("error:", e);
    // console.log("文件数据和文件名:", downloadData, filename);
  }
};

const renderGender = (num) => {
  if (+num === 0) {
    return "Female";
  } else if (+num === 1) {
    return "Male";
  } else if (+num === 2) {
    return "I prefer not to disclose";
  } else {
    return "";
  }
};

const formatStatusText = function(text) {
  let array = [
    "Avaliable",
    "Available",
    "available",
    "avaliable",
    "active",
    "Active",
  ];
  return array.includes(text);
};

Vue.mixin({
  methods: {
    formatFormData,
    toThousands,
    formatMDY,
    formatMDYHI,
    ucfirst,
    getUUID,
    siteIdToMediumId,
    refreshUserInfo,
    filterPlatforms,
    filterCommonConfig,
    getPlatformObj,
    toThousandsAndFixed2,
    formatBigNumber,
    transformUpdateTime,
    filterPayTypes,
    DateDaysDiff,
    filterFormats,
    getFormatObj,
    fileDownloader,
    renderGender,
    formatStatusText,
  },
  filters: {
    toThousands,
    formatMDY,
    formatMDYHI,
    ucfirst,
    siteIdToMediumId,
    getSourceUrl,
    timeToUsFormat,
    formatTimeDuration,
    formatTimestamp,
    toThousandsAndFixed2,
    formatBigNumber,
    renderGender,
  },
});
Vue.prototype.APP_CONFIG = config;
