export const useModifyStatus =( it )=>{
  //判断current物料
  //状态。active，expired，beReady
  let status = "beReady" 
  const { start_time , end_time } = it
  const next = moment().diff(moment.unix(end_time),"second")
  const pre = moment().diff(moment.unix(start_time),"second")
  if (pre > 0 && next <= 0) {
    status = "Active"
  }else if (pre<0) {
     status = "Scheduled"
  }else{
    status = "Expired"
  }
    return status
}
