var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"width":"940px","closable":false,"title":"Campaign","centered":"","destroyOnClose":"","maskClosable":false},model:{value:(_vm.showCampaignEdit),callback:function ($$v) {_vm.showCampaignEdit=$$v},expression:"showCampaignEdit"}},[_c('a-icon',{staticClass:"camp-edit-close",attrs:{"type":"close"},on:{"click":_vm.hideCampaignEdit}}),_c('a-spin',{attrs:{"spinning":_vm.isLoading}},[(_vm.item)?_c('div',{staticClass:"campedit"},[_c('a-form',{attrs:{"form":_vm.formCamEdit,"label-col":{ span: 5 },"wrapper-col":{ span: 18 }}},[_c('a-form-item',{attrs:{"label":"Campaign Name","labelAlign":"left"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'name',
              {
                initialValue: _vm.item.name,
                rules: [
                  {
                    required: true,
                    message:
                      'Subject is a required field. Please enter a Campaign Name',
                  } ],
              } ]),expression:"[\n              'name',\n              {\n                initialValue: item.name,\n                rules: [\n                  {\n                    required: true,\n                    message:\n                      'Subject is a required field. Please enter a Campaign Name',\n                  },\n                ],\n              },\n            ]"}],attrs:{"maxLength":100}})],1),_c('a-form-item',{attrs:{"label":"Platforms","labelAlign":"left"}},[_c('div',_vm._l((_vm.PLATFORMS),function(pl,idx){return _c('a-checkbox',{key:("platforms-edit1-" + idx),attrs:{"defaultChecked":_vm.isPlatChecked(pl.name)},on:{"change":function($event){return _vm.platformsCheckChange(pl.name)}}},[_c('img',{staticClass:"platform-icon",attrs:{"src":pl.icon}}),_vm._v(" "+_vm._s(pl.name)+" ")])}),1),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'platforms',
              {
                initialValue: _vm.item.platforms.join(','),
                rules: [
                  {
                    required: true,
                    message:
                      'platforms is a required field. Please select at least one platform',
                  } ],
              } ]),expression:"[\n              'platforms',\n              {\n                initialValue: item.platforms.join(','),\n                rules: [\n                  {\n                    required: true,\n                    message:\n                      'platforms is a required field. Please select at least one platform',\n                  },\n                ],\n              },\n            ]"}],staticStyle:{"display":"none"}})],1),_c('a-form-item',{attrs:{"label":"Budget ($ USD) ","labelAlign":"left"}},[_c('div',{staticClass:"campedit-amount"},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'amount_total',
                {
                  initialValue: _vm.item.amount_total,
                  rules: [
                    {
                      required: true,
                      message:
                        'Budget is a required field. Please enter amount',
                    } ],
                } ]),expression:"[\n                'amount_total',\n                {\n                  initialValue: item.amount_total,\n                  rules: [\n                    {\n                      required: true,\n                      message:\n                        'Budget is a required field. Please enter amount',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"min":0,"max":999999999}})],1)]),_c('a-form-item',{attrs:{"label":"Duration","labelAlign":"left"}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['duration', _vm.rangeConfig]),expression:"['duration', rangeConfig]"}],attrs:{"disabled-date":_vm.disabledDate,"default-value":[
              _vm.dayjs(new Date(_vm.item.start_time * 1000)).format('MM/DD/YYYY'),
              _vm.dayjs(new Date(_vm.item.end_time * 1000)).format('MM/DD/YYYY') ]}})],1),_c('a-form-item',{attrs:{"label":"Description","labelAlign":"left"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'description',
              {
                initialValue: _vm.item.description,
                rules: [
                  {
                    required: true,
                    message:
                      'Subject is a required field. Please enter description',
                  } ],
              } ]),expression:"[\n              'description',\n              {\n                initialValue: item.description,\n                rules: [\n                  {\n                    required: true,\n                    message:\n                      'Subject is a required field. Please enter description',\n                  },\n                ],\n              },\n            ]"}],attrs:{"auto-size":{ minRows: 3, maxRows: 5 }}})],1),_c('a-form-item',{attrs:{"label":"Objective","labelAlign":"left"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'objective',
              {
                initialValue: _vm.item.objective,
                rules: [
                  {
                    required: true,
                    message:
                      'Subject is a required field. Please enter objective',
                  } ],
              } ]),expression:"[\n              'objective',\n              {\n                initialValue: item.objective,\n                rules: [\n                  {\n                    required: true,\n                    message:\n                      'Subject is a required field. Please enter objective',\n                  },\n                ],\n              },\n            ]"}],attrs:{"auto-size":{ minRows: 3, maxRows: 5 }}})],1)],1)],1):_vm._e()]),_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":_vm.hideCampaignEdit}},[_vm._v(" Discard ")]),_c('a-button',{key:"submit",attrs:{"type":"primary"},on:{"click":_vm.submitCampaign}},[_vm._v(" Save ")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }