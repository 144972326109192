<template>
  <div id="creative-layout">
    <Brands collection="Creatives" :subset="subset"></Brands>
    <SubMenu v-if="subset != 'Datafeed'" :btnLists="subMenus"></SubMenu>
    <router-view @refreshStats="refreshStats" />
  </div>
</template>

<script>
import SubMenu from "../../components/SubMenu.vue";
import Brands from "../../components/Brands.vue";
import axios from "@/utils/request";
export default {
  components: {
    SubMenu,
    Brands,
  },
  computed: {
    subset() {
      return {
        creative_banner: "Banners/Images",
        creative_text: "Texts/Emails",
        creative_coupon: "Coupons",
        creative_datafeed: "Datafeed",
      }[this.$route.name];
    },
  },
  watch: {
    subset(newVal, oldVal) {
      if (newVal != "Datafeed" && !this.subMenusLoaded) {
        this.refreshStats();
      }
    },
  },
  mounted() {
    if (this.subset != "Datafeed") {
      this.refreshStats();
    }
  },
  methods: {
    refreshStats() {
      console.log("refreshStats");
      axios.get("/api_admin.php?a=creative/common_info").then((res) => {
        this.subMenusLoaded = true;
        this.subMenus = [
          {
            name: `Banners/Images (${res.data.IMAGE})`,
            path: "creative_banner",
          },
          {
            name: `Texts/Emails (${res.data.TEXT})`,
            path: "creative_text",
          },
          {
            name: `Coupons (${res.data.COUPON})`,
            path: "creative_coupon",
          },
        ];
      });
    },
  },
  data() {
    return {
      subMenusLoaded: false,
      subMenus: [
        {
          name: "Banners/Images (?)",
          path: "creative_banner",
        },
        {
          name: "Texts/Emails (?)",
          path: "creative_text",
        },
        {
          name: "Coupons (?)",
          path: "creative_coupon",
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
#creative-layout {
  /deep/ .text-batch-add,
  /deep/ .text-batch-edit,
  /deep/ .text-single-edit,
  /deep/ .coupon-batch-add,
  /deep/ .coupon-batch-edit,
  /deep/ .coupon-single-edit,
  /deep/ .banner-single-edit,
  /deep/ .banner-batch-edit,
  /deep/ .banner-batch-add {
    .modal-content > .title::after {
      content: " ";
      display: block;
      width: calc(100% + 2.8571rem);
      height: 1px;
      background-color: #eeeeee;
      position: absolute;
      top: 2.8571rem;
      left: -20px;
    }
  }
}
</style>
