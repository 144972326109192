<template>
  <div id="creative-banners">
    <div class="content">
      <div class="content-title">
        <div class="items">
          <div class="item" style="width: 16.875rem">
            <span>Date Range</span>
            <a-range-picker
              class="date"
              v-model="dateRange"
              @change="handleChangeDate"
              format="L"
            />
          </div>
          <div class="item" style="width: 16.875rem">
            <span>Status</span>
            <PSelector
              size="large"
              v-model="params.status"
              :options="[
                {
                  value: 'Scheduled',
                  label: 'Scheduled',
                },
                {
                  value: 'Active',
                  label: 'Active',
                },
                {
                  value: 'Expired',
                  label: 'Expired',
                },
              ]"
              multiple
              clearAble
            ></PSelector>
          </div>
          <div class="item" style="width: 16.875rem">
            <span>Permission</span>
            <PSelector
              size="large"
              v-model="params.privacy"
              :options="[
                {
                  value: 'PUBLIC',
                  label: 'Public for all publishers',
                },
                {
                  value: 'PRIVATE',
                  label: 'Private',
                },
              ]"
              multiple
              clearAble
            ></PSelector>
          </div>
          <div class="item" style="width: 16.875rem">
            <span>Search keywords</span>
            <a-input v-model="params.keyword" />
          </div>
        </div>
        <div class="search-reset">
          <button @click="search">Search</button>
          <button @click="reset">Reset</button>
        </div>
      </div>
      <a-spin
        class="content-list"
        :class="{ empty: list.length == 0 }"
        :spinning="isLoading"
      >
        <a-empty v-if="list.length == 0 && !isLoading" />
        <button class="addone" @click="isShowAdd = true">Add Coupon</button>
        <button
          class="batch-edit"
          @click="showEdit()"
          v-if="checkedList.length > 0"
        >
          Edit Selected
        </button>
        <div class="table" v-if="list.length > 0">
          <div class="thead">
            <div class="td">ID</div>
            <div class="td">Title</div>
            <div class="td">Description</div>
            <div class="td">Start Date</div>
            <div class="td">End Date</div>
            <div class="td">Permission</div>
            <div class="td">Action</div>
          </div>
          <div class="tr" v-for="item in list" :key="item.id">
            <div class="td">
              <a-checkbox
                :class="{
                  Active: item.status == 'Active',
                  expired: item.status == 'Expired',
                  scheduled: item.status == 'Scheduled',
                }"
                :checked="checkedList.includes(item)"
                @change="checkHandle(item)"
              />
              <div class="id">{{ item.id }}</div>
            </div>
            <div class="td">
              <div>{{ item.title }}</div>
            </div>
            <div class="td">
              <div>{{ item.coupons[0].description }}</div>
            </div>
            <div class="td">{{ item.start_time | formatMDY }}</div>
            <div class="td">{{ item.end_time | formatMDY }}</div>
            <div class="td">{{ item.privacy | ucfirst }}</div>
            <div class="td">
              <img
                src="@/assets/text-edit.svg"
                @click="showEdit(item)"
                alt
                class="text-edit-icon"
              />
              <img
                src="@/assets/text-view.svg"
                @click="showCopy(item)"
                alt
                class="text-view-icon"
              />
            </div>
          </div>
        </div>

        <div class="footer-pagination">
          <a-pagination
            v-model="currentPage"
            :total="totalSize"
            :pageSize="pageSize"
            show-less-items
            @change="paginationChange"
          />
        </div>
      </a-spin>
    </div>

    <!-- BatchEdit -->
    <Modal
      :isVisible="isShowBatchEdit"
      width="47.5rem"
      submitText="Confirm"
      @close-popup="closeBatchEditModal"
      :showBtn="false"
      :title="modifyBatchStatus === 'Expired' ? 'Coupon Expire' : 'Coupon Edit'"
      class="coupon-batch-edit"
    >
      <template #content>
        <div class="left">
          <template v-if="isShowBatchEdit">
            <div class="name">Title</div>
            <div class="item" v-for="item in checkedList" :key="item.id">
              {{ item.title }}
            </div>
          </template>
        </div>
        <div class="right">
          <div class="title">Batch Edit Time</div>
          <div class="name">Start Time</div>
          <a-date-picker
            :disabled="modifyBatchStatus === 'Expired'"
            :show-time="{
              format: 'HH:mm',
              minuteStep: 5,
            }"
            format="L HH:mm"
            placeholder="Select Start Time"
            v-model="batchEditFormData.start_time"
          />
          <div class="name">End Time</div>
          <a-date-picker
            :disabled="modifyBatchStatus === 'Expired'"
            :show-time="{
              format: 'HH:mm',
              minuteStep: 5,
            }"
            format="L HH:mm"
            placeholder="Select End Time"
            v-model="batchEditFormData.end_time"
          />

          <div class="tips">
            ({{ $store.state.timezone.gmt.replace(" ", "") }}) Cook lslands
            Standard Time
          </div>
          <div class="options" v-if="modifyBatchStatus !== 'Expired'">
            <a-button class="delete" @click="isShowBatchAction = true">
              {{ modifyBatchStatus === "Active" ? "Expire" : "Delete" }}
            </a-button>
            <a-button class="update" @click="batchEdit">Update</a-button>
          </div>
        </div>
      </template>
    </Modal>

    <!-- BatchDelete -->
    <Modal
      :isVisible="isShowBatchAction"
      width="33rem"
      submitText="Confirm"
      @close-popup="isShowBatchAction = false"
      :title="modifyBatchStatus === 'Active' ? 'Expire' : 'Delete'"
      class="coupon-batch-delete"
      @submit="modifyBatchHandle()"
    >
      <template #content>
        <div class="text">
          Are you sure you want to
          {{ modifyBatchStatus === "Active" ? "expire" : "delete" }} this
          text/emails? <br />(After cliking “confirm,” this text/emails will be
          {{ modifyBatchStatus === "Active" ? "expired" : "deleted" }}
          permanently, and the publishers won’t be able to see them anymore.)
        </div>
      </template>
    </Modal>

    <!-- BatchAdd -->
    <Modal
      :isVisible="isShowAdd"
      width="47.5rem"
      submitText="Confirm"
      @close-popup="closeAddModal"
      title="Add Coupon"
      class="coupon-batch-add"
      :showBtn="false"
    >
      <template #content>
        <div class="left">
          <div class="name">Image Preview</div>
          <div class="item" v-if="file1">
            <img :src="file1.src" class="preview" />
            <img
              src="@/assets/banner-add-del.svg"
              class="coupon-icon-del"
              @click="file1 = null"
            />
            <div class="size">{{ file1.width }} x {{ file1.height }}</div>
          </div>
          <div>
            <label class="upload-big" v-show="!file1" for="upload-small">
              <div class="upload-big-img"></div>
              <span>Choose a File</span>
            </label>
            <input
              ref="upload-small"
              type="file"
              accept=".png, .jpg"
              id="upload-small"
              @change="handleChange($event, 1)"
              style="display:none"
            />
            <!-- <label class="upload-small" for="upload-small"  v-show="file1">choose your files</label> -->
          </div>

          <div class="name">Thumbnail Preview</div>
          <div class="item" v-if="file2">
            <img :src="file2.src" class="preview" />
            <img
              src="@/assets/banner-add-del.svg"
              class="coupon-icon-del"
              @click="file2 = null"
            />
            <div class="size">{{ file2.width }} x {{ file2.height }}</div>
          </div>

          <label class="upload-big" v-show="!file2" for="upload-small-2">
            <div class="upload-big-img"></div>
            <span>Choose a File</span>
          </label>
          <input
            ref="upload-small-2"
            type="file"
            accept=".png, .jpg"
            id="upload-small-2"
            @change="handleChange($event, 2)"
            style="display:none"
          />
          <label class="upload-small" for="upload-small-2" v-show="file2"
            >choose your files</label
          >
          <div class="size">File Max Size: 1M; Accpeted File Type: jpg,png</div>
        </div>
        <div class="right">
          <div class="title">Infomation</div>
          <div class="name">Title</div>
          <a-input v-model.trim="addFormData.title"></a-input>
          <div class="name desc">
            Coupon Code
            <span>?</span>
            <span>
              <span
                >Coupon code can be composed by letters, numbers, or both.
                Special characters are not supported.</span
              >
            </span>
          </div>
          <a-input v-model.trim="addFormData.coupon_code"></a-input>
          <div class="name">Link</div>
          <a-input v-model.trim="addFormData.link"></a-input>
          <div class="name desc">
            Description
            <span>?</span>
            <span>
              <span
                >Publishers see this information. Please write a short
                description of this Coupon. (250 characters max)</span
              >
            </span>
          </div>
          <a-textarea v-model="addFormData.description"></a-textarea>
          <div class="name desc">
            Category
            <span>?</span>
            <span>
              <span>Feel free to name your own category.</span>
            </span>
          </div>
          <a-input v-model="addFormData.category"></a-input>

          <div class="name desc">
            Restrictions
            <span>?</span>
            <span>
              <span
                >Describe any restrictions to participation in the use of this
                coupon to your Publishers.</span
              >
            </span>
          </div>
          <a-input v-model.trim="addFormData.restrictions"></a-input>
          <div class="name">Keywords</div>
          <a-input v-model.trim="addFormData.keywords"></a-input>
          <div class="name">Permission</div>
          <a-select placeholder="Select" v-model="addFormData.privacy">
            <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
            <a-select-option value="PUBLIC"
              >Public for all publishers</a-select-option
            >
            <a-select-option value="PRIVATE">Private</a-select-option>
          </a-select>

          <template v-if="addFormData.privacy == 'PRIVATE'">
            <div class="name">Publisher</div>
            <a-select
              mode="multiple"
              v-model="addFormData.site_ids"
              placeholder="Please select"
              :filter-option="
                (input, option) =>
                  option.componentOptions.children[0].text
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
              "
            >
              <a-select-option
                v-for="medium in mediumLists"
                :key="`${medium.title} (${medium.site_id})`"
                :value="medium.site_id"
                >{{ `${medium.title} (${medium.site_id})` }}</a-select-option
              >
            </a-select>
          </template>

          <div class="name">Start Time</div>
          <a-date-picker
            :show-time="{
              format: 'HH:mm',
              minuteStep: 5,
            }"
            format="L HH:mm"
            placeholder="Select Start Time"
            v-model="addFormData.start_time"
          />
          <div class="name">End Time</div>
          <a-date-picker
            :show-time="{
              format: 'HH:mm',
              minuteStep: 5,
            }"
            format="L HH:mm"
            placeholder="Select End Time"
            v-model="addFormData.end_time"
          />

          <div class="tips">
            ({{ $store.state.timezone.gmt.replace(" ", "") }}) Cook lslands
            Standard Time
          </div>
          <div class="options">
            <button class="add" @click="doSubmitAdd">Add</button>
          </div>
        </div>
      </template>
    </Modal>

    <!-- SingleEdit -->
    <!-- modifyBatchStatus -->
    <!-- :title="singleEditData.status ==='Expired'?' Coupon Expire':'Coupon Edit'" -->
    <Modal
      :isVisible="singleEditData != null"
      width="47.5rem"
      submitText="Confirm"
      :title="
        singleModifyStatus === 'Expired' ? 'Coupon Expire' : 'Coupon Edit'
      "
      @close-popup="singleEditData = null"
      class="coupon-single-edit"
      :showBtn="false"
    >
      <template #content v-if="singleEditData">
        <div class="left">
          <div class="name">Image Preview</div>
          <div class="item" v-if="file1">
            <img :src="file1.src" class="preview" />
            <div class="size">{{ file1.width }} x {{ file1.height }}</div>
          </div>
          <div class="item" v-else-if="singleEditData.coupons[0].image_url_1">
            <img :src="singleEditData.coupons[0].image_url_1" class="preview" />
            <div class="size">
              {{ singleEditData.coupons[0].width_1 }} x
              {{ singleEditData.coupons[0].height_1 }}
            </div>
          </div>

          <label
            class="upload-big"
            v-show="!file1 && !singleEditData.coupons[0].image_url_1"
            for="upload-small-edit-1"
          >
            <a-empty
              v-if="
                singleEditData.status === 'Expired' &&
                  !singleEditData.coupons[0].image_url_1
              "
            ></a-empty>
            <template v-else>
              <div class="upload-big-img"></div>
              <span>Choose a File</span>
            </template>
          </label>
          <input
            v-if="singleEditData.status !== 'Expired'"
            ref="upload-small-edit-1"
            type="file"
            accept=".png, .jpg"
            id="upload-small-edit-1"
            @change="handleChange($event, 1)"
            style="display:none"
          />
          <label
            v-if="singleEditData.status !== 'Expired'"
            class="upload-small"
            for="upload-small-edit-1"
            v-show="file1 || singleEditData.coupons[0].image_url_1"
            >choose a file</label
          >

          <div class="name">Thumbnail Preview</div>
          <div class="item" v-if="file2">
            <img :src="file2.src" class="preview" />
            <div class="size">{{ file2.width }} x {{ file2.height }}</div>
          </div>
          <div class="item" v-else-if="singleEditData.coupons[0].image_url_2">
            <img :src="singleEditData.coupons[0].image_url_2" class="preview" />
            <div class="size">
              {{ singleEditData.coupons[0].width_2 }} x
              {{ singleEditData.coupons[0].height_2 }}
            </div>
          </div>

          <label
            class="upload-big"
            v-show="!file2 && !singleEditData.coupons[0].image_url_2"
            for="upload-small-edit-2"
          >
            <a-empty
              v-if="
                singleEditData.status === 'Expired' &&
                  !singleEditData.coupons[0].image_url_2
              "
            ></a-empty>
            <template v-else>
              <div class="upload-big-img"></div>
              <span>Choose a File</span>
            </template>
          </label>
          <input
            v-if="singleEditData.status !== 'Expired'"
            ref="upload-small-edit-2"
            type="file"
            accept=".png, .jpg"
            id="upload-small-edit-2"
            @change="handleChange($event, 2)"
            style="display:none"
          />
          <!-- todo show change -->
          <label
            v-if="singleEditData.status === 'Active'"
            class="upload-small"
            for="upload-small-edit-2"
            v-show="file2 || singleEditData.coupons[0].image_url_2"
            >choose a file</label
          >
          <div v-if="singleEditData.status !== 'Expired'" class="size">
            File Max Size: 1M; Accpeted File Type: jpg,png
          </div>
        </div>
        <div class="right">
          <div class="title">Infomation</div>
          <div class="name">Title</div>
          <a-input
            :disabled="singleEditData.status === 'Expired'"
            v-model.trim="singleEditData.title"
          ></a-input>
          <div class="name desc">
            Coupon Code
            <span>?</span>
            <span>
              <span
                >Coupon code can be composed by letters, numbers, or both.
                Special characters are not supported.</span
              >
            </span>
          </div>
          <a-input
            :disabled="singleEditData.status === 'Expired'"
            v-model.trim="singleEditData.coupon_code"
          ></a-input>
          <div class="name">Link</div>
          <a-input
            :disabled="singleEditData.status === 'Expired'"
            v-model.trim="singleEditData.link"
          ></a-input>
          <div class="name desc">
            Description
            <span>?</span>
            <span>
              <span
                >Publishers see this information. Please write a short
                description of this Coupon. (250 characters max)</span
              >
            </span>
          </div>
          <a-textarea
            :disabled="singleEditData.status === 'Expired'"
            v-model="singleEditData.description"
          ></a-textarea>
          <div class="name desc">
            Category
            <span>?</span>
            <span>
              <span>Feel free to name your own category.</span>
            </span>
          </div>
          <a-input
            :disabled="singleEditData.status === 'Expired'"
            v-model="singleEditData.category"
          ></a-input>

          <div class="name desc">
            Restrictions
            <span>?</span>
            <span>
              <span
                >Describe any restrictions to participation in the use of this
                coupon to your Publishers.</span
              >
            </span>
          </div>
          <a-input
            :disabled="singleEditData.status === 'Expired'"
            v-model.trim="singleEditData.restrictions"
          ></a-input>
          <div class="name">Keywords</div>
          <a-input
            :disabled="singleEditData.status === 'Expired'"
            v-model.trim="singleEditData.keywords"
          ></a-input>
          <div class="name">Permission</div>
          <a-select
            :disabled="singleEditData.status === 'Expired'"
            placeholder="Select"
            v-model="singleEditData.privacy"
          >
            <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
            <a-select-option value="PUBLIC"
              >Public for all publishers</a-select-option
            >
            <a-select-option value="PRIVATE">Private</a-select-option>
          </a-select>

          <template v-if="singleEditData.privacy == 'PRIVATE'">
            <div class="name">Publisher</div>

            <a-select
              :disabled="singleEditData.status === 'Expired'"
              mode="multiple"
              v-model="singleEditData.site_ids"
              placeholder="Please select"
            >
              <a-select-option
                v-for="medium in mediumLists"
                :key="`${medium.title} (${medium.site_id})`"
                :value="medium.site_id"
                >{{ `${medium.title} (${medium.site_id})` }}</a-select-option
              >
            </a-select>
          </template>

          <div class="name">Start Time</div>
          <a-date-picker
            :disabled="singleEditData.status === 'Expired'"
            :show-time="{
              format: 'HH:mm',
              minuteStep: 5,
            }"
            format="L HH:mm"
            placeholder="Select Start Time"
            v-model="singleEditData.start_time"
          />
          <div class="name">End Time</div>
          <a-date-picker
            :disabled="singleEditData.status === 'Expired'"
            :show-time="{
              format: 'HH:mm',
              minuteStep: 5,
            }"
            format="L HH:mm"
            placeholder="Select End Time"
            v-model="singleEditData.end_time"
          />

          <div class="tips">
            ({{ $store.state.timezone.gmt.replace(" ", "") }}) Cook lslands
            Standard Time
          </div>
          <div class="options" v-if="singleEditData.status !== 'Expired'">
            <a-button class="delete" @click="isShowSingleHandle = true">
              {{ singleEditData.status === "Active" ? "Expire" : "Delete" }}
            </a-button>
            <a-button class="update" @click="doSingleEdit">Update</a-button>
          </div>
        </div>
      </template>
    </Modal>

    <!-- SingleDelete -->
    <Modal
      :isVisible="isShowSingleHandle"
      width="33rem"
      submitText="Confirm"
      @close-popup="isShowSingleHandle = false"
      :title="singleModifyStatus === 'Active' ? 'Expire' : 'Delete'"
      class="coupon-batch-delete"
      @submit="doShowSingleHandle(singleEditData)"
    >
      <template #content>
        <div class="text" v-if="singleEditData">
          Are you sure you want to
          {{ singleModifyStatus === "Active" ? "expire" : "delete" }} this
          coupon? <br />(After cliking “confirm,” this coupon will be
          {{ singleEditData.status === "Scheduled" ? "deleted" : "expired" }}
          permanently, and the publishers won’t be able to see it anymore.)
        </div>
      </template>
    </Modal>

    <Loading
      v-if="progressPercent > -1"
      :percent="progressPercent"
      type="progress"
    ></Loading>
    <Loading v-if="isOpLoading"></Loading>
    <div ref="temp" style="display:none"></div>
  </div>
</template>

<script>
import Modal from "../../components/Modal.vue";
import axios from "@/utils/request";
import moment from "moment-timezone";
import { uploadUrl } from "@/utils/config";
import { useDebounceMessage } from "@/utils/hooks";
import { useModifyStatus } from "./utils";

export default {
  name: "creative_banner",
  components: {
    Modal,
  },
  data() {
    return {
      modifyBatchStatus: "",
      singleModifyStatus: "",
      progressPercent: -1,
      isOpLoading: false,
      dateRange: [],
      list: [],
      params: {
        start_time: null,
        end_time: null,
        status: [],
        privacy: [],
        keyword: null,
        type: "COUPON",
      },
      addFormData: {
        title: "",
        coupon_code: "",
        description: "",
        category: "",
        privacy: "PUBLIC",
        site_ids: [],
        start_time: moment(),
        end_time: moment().add(1, "M"),
        type: "COUPON",
        restrictions: "",
        keywords: "",
        link: "",
      },
      file1: null,
      file2: null,
      checkedList: [],
      currentPage: 1,
      pageSize: 20,
      totalSize: 1,
      isLoading: false,
      isShowBatchEdit: false,
      isShowBatchAction: false,
      isShowSingleHandle: false,
      isShowAdd: false,
      singleEditData: null,
      batchEditFormData: {
        start_time: null,
        end_time: null,
      },
      fileList: [],
      mediumLists: [],
    };
  },
  mounted() {
    this._getMediumLists();
    this.getData();
  },
  methods: {
    modifyBatchHandle() {
      if (this.modifyBatchStatus === "Active") {
        this.batchExpire(); // 过期
      } else {
        this.doBatchDelete(); // 删除
      }
    },
    doShowSingleHandle(row) {
      if (row.status === "Scheduled") {
        this.doBatchDelete(row.id);
      } else {
        this.singleEditData.end_time = moment();
        this.doSingleEdit();
      }
    },
    batchExpire() {
      let cids = this.checkedList.map((item) => item.id);
      this.isOpLoading = true;
      axios
        .post("/api_admin.php?a=creative/batch_expire", {
          cids,
        })
        .then((res) => {
          this.isOpLoading = false;
          if (res.code === 0) {
            this.$message.success("Batch Edit Success");
            this.closeBatchEditModal();
            this.getData();
          }
        })
        .catch((e) => {
          this.isOpLoading = false;
        });
    },
    // checkedList.includes(item) ? checkedList.splice(checkedList.indexOf(item), 1) : checkedList.push(item)
    checkHandle(item) {
      const status = useModifyStatus(item);
      const checkedList = this.checkedList;
      if (checkedList.length > 0) {
        const currentStatus = useModifyStatus(checkedList[0]);
        if (status !== currentStatus)
          return useDebounceMessage.call(this, {
            message:
              "You can only select and edit creatives of the same status at the same time",
            type: "error",
          });
      }
      checkedList.includes(item)
        ? checkedList.splice(checkedList.indexOf(item), 1)
        : checkedList.push(item);
    },
    async doSingleEdit() {
      // 检查表单
      if (this.singleEditData.title.length == 0) {
        this.$message.error("Please input Title");
        return;
      }
      if (
        this.singleEditData.privacy == "PRIVATE" &&
        (!this.singleEditData.site_ids ||
          this.singleEditData.site_ids.length == 0)
      ) {
        this.$message.error("Please select publishers on private mode");
        return;
      }
      if (!this.singleEditData.start_time || !this.singleEditData.end_time) {
        this.$message.error("Please select time");
        return;
      }
      if (
        this.singleEditData.start_time.unix() >=
        this.singleEditData.end_time.unix()
      ) {
        this.$message.error("Please check the time you select");
        return;
      }

      let data = {
        id: this.singleEditData.id,
        title: this.singleEditData.title,
        description: this.singleEditData.description,
        coupon_code: this.singleEditData.coupon_code,
        restrictions: this.singleEditData.restrictions,
        keywords: this.singleEditData.keywords,
        category: this.singleEditData.category,
        link: this.singleEditData.link,
        privacy: this.singleEditData.privacy,
        site_ids: this.singleEditData.site_ids,
        start_time: this.singleEditData.start_time.unix(),
        end_time: this.singleEditData.end_time.unix(),
      };
      if (this.file1 || this.file2) {
        this.progressPercent = 0;
        let count = (this.file1 ? 1 : 0) + (this.file2 ? 1 : 0);
        let nowCount = 0;
        if (this.file1) {
          try {
            this.file1.url = await this.doUpload(this.file1.file);
            this.progressPercent = (100 * ++nowCount) / count;
            data.image1 = {
              width: this.file1.width,
              height: this.file1.height,
              url: this.file1.url,
              size: this.file1.size,
            };
          } catch (e) {
            console.log("Upload fail", e);
          }
        }
        if (this.file2) {
          try {
            this.file2.url = await this.doUpload(this.file2.file);
            this.progressPercent = (100 * ++nowCount) / count;
            data.image2 = {
              width: this.file2.width,
              height: this.file2.height,
              url: this.file2.url,
              size: this.file2.size,
            };
          } catch (e) {
            console.log("Upload fail", e);
          }
        }
      } else {
        this.isOpLoading = true;
      }
      // 创建物料
      axios.post("/api_admin.php?a=creative/modify", data).then((res) => {
        this.progressPercent = -1;
        this.isOpLoading = false;
        if (res.code === 0) {
          this.singleEditData = null;
          this.isShowBatchAction = false;
          this.isShowSingleHandle = false;
          this.$message.success("Edit Success");
          this.currentPage = 1;
          this.getData();
        } else {
          this.$message.error(res.msg);
        }
      });
      // 显示成功
    },
    showEdit(item) {
      if (item || this.checkedList.length == 1) {
        item = item || this.checkedList[0];
        this.singleModifyStatus = useModifyStatus(item);
        this.singleEditData = Object.assign({}, item, {
          start_time: moment.unix(item.start_time),
          end_time: moment.unix(item.end_time),
          site_ids: item.private_site_ids,
          coupon_code: item.coupons[0].coupon_code,
          description: item.coupons[0].description,
          restrictions: item.coupons[0].restrictions,
          keywords: item.coupons[0].keywords,
          status: this.singleModifyStatus,
        });
      } else {
        this.isShowBatchEdit = true;
        this.modifyBatchStatus = useModifyStatus(this.checkedList[0]);
      }
    },
    showCopy(item) {
      this.addFormData = {
        title: item.title,
        coupon_code: item.coupon_code,
        description: item.coupons[0].description,
        category: item.category,
        privacy: "PUBLIC",
        site_ids: [],
        start_time: moment(),
        end_time: moment().add(1, "M"),
        type: "COUPON",
        restrictions: item.coupons[0].restrictions,
        keywords: item.coupons[0].keywords,
        link: item.link,
      };
      if (item.coupons[0].image_url_1) {
        this.file1 = {
          hash:
            new Date().getTime() +
            "_" +
            Math.random() +
            "_" +
            item.coupons[0].image_url_1,
          file: null,
          src: item.coupons[0].image_url_1,
          height: item.coupons[0].height_1,
          width: item.coupons[0].width_1,
          size: item.coupons[0].size_1,
          url: item.coupons[0].image_url_1,
        };
      }
      if (item.coupons[0].image_url_2) {
        this.file2 = {
          hash:
            new Date().getTime() +
            "_" +
            Math.random() +
            "_" +
            item.coupons[0].image_url_2,
          file: null,
          src: item.coupons[0].image_url_2,
          height: item.coupons[0].height_2,
          width: item.coupons[0].width_2,
          size: item.coupons[0].size_2,
          url: item.coupons[0].image_url_2,
        };
      }
      this.isShowAdd = true;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
    },
    _getMediumLists() {
      axios
        .get("/api_admin.php?a=medium/list", {
          params: {
            status: ["adopt"],
            simple: 1,
          },
        })
        .then((res) => {
          if (res.code === 0) {
            this.mediumLists = res.data.data;
          }
        });
    },
    handleChange(event, num) {
      let file = event.target.files[0];
      event.target.value = null;
      if (!file) {
        return;
      }
      if (file.size / 1024 / 1024 >= 1) {
        this.$message.error("Please select a picture with a size less than 1m");
        return;
      }
      let image = new Image();
      image.src = (
        window.createObjectURL ||
        window.URL?.createObjectURL ||
        window.webkitURL?.createObjectURL
      )(file);
      image.onload = () => {
        let fileInfo = {
          hash: new Date().getTime() + "_" + Math.random() + "_" + file.name,
          file: file,
          src: image.src,
          height: image.height,
          width: image.width,
          size: file.size,
          url: null,
        };
        if (num == 1) {
          this.file1 = fileInfo;
        } else {
          this.file2 = fileInfo;
        }
        this.$refs.temp.innerHTML = "";
      };
      this.$refs.temp.appendChild(image);
    },
    handleChangeSingle(event) {
      this.handleChange(event, true);
    },
    getData() {
      this.checkedList = [];
      this.isLoading = true;
      axios
        .get("/api_admin.php?a=creative/list", {
          params: {
            ...this.params,
            page_num: this.currentPage,
            page_size: this.pageSize,
          },
        })
        .then((res) => {
          this.isLoading = false;
          if (res.code === 0) {
            this.list = res.data.data;
            this.totalSize = parseInt(res.data.total_size);
          } else {
            this.$message.error(
              res.msg || "Sorry, server is busy. please try later."
            );
          }
        });
    },
    search() {
      this.getData();
    },
    paginationChange(page, pageSize) {
      this.currentPage = page;
      this.getData();
    },
    batchEdit() {
      let cids = this.checkedList.map((item) => item.id);
      if (
        !this.batchEditFormData.start_time ||
        !this.batchEditFormData.end_time
      ) {
        this.$message.error("Please select time");
        return;
      }
      if (
        this.batchEditFormData.start_time.unix() >=
        this.batchEditFormData.end_time.unix()
      ) {
        this.$message.error("Please check the time you select");
        return;
      }
      this.isOpLoading = true;
      axios
        .post("/api_admin.php?a=creative/batch_modify", {
          cids,
          start_time: this.batchEditFormData.start_time.unix(),
          end_time: this.batchEditFormData.end_time.unix(),
        })
        .then((res) => {
          this.isOpLoading = false;
          if (res.code === 0) {
            this.$message.success("Batch Edit Success");
            this.progressPercent = -1;
            this.closeBatchEditModal();
            this.getData();
          }
        })
        .catch((e) => {
          this.isOpLoading = false;
        });
    },
    reset() {
      this.dateRange = [];
      this.params = {
        start_time: null,
        end_time: null,
        status: [],
        privacy: [],
        keyword: null,
        type: "COUPON",
      };
    },
    async doSubmitAdd() {
      // 检查表单
      if (this.addFormData.title.length == 0) {
        this.$message.error("Please input Title");
        return;
      }
      if (
        this.addFormData.privacy == "PRIVATE" &&
        (!this.addFormData.site_ids || this.addFormData.site_ids.length == 0)
      ) {
        this.$message.error("Please select publishers on private mode");
        return;
      }
      if (!this.addFormData.start_time || !this.addFormData.end_time) {
        this.$message.error("Please select time");
        return;
      }
      if (
        this.addFormData.start_time.unix() >= this.addFormData.end_time.unix()
      ) {
        this.$message.error("Please check the time you select");
        return;
      }

      // 显示进度
      if (this.file1 || this.file2) {
        this.progressPercent = 0;
        let count = (this.file1 ? 1 : 0) + (this.file2 ? 1 : 0);
        let nowCount = 0;
        if (this.file1 && !this.file1.url) {
          try {
            this.file1.url = await this.doUpload(this.file1.file);
            this.progressPercent = (100 * ++nowCount) / count;
          } catch (e) {
            console.log("Upload fail", e);
          }
        }
        if (this.file2 && !this.file2.url) {
          try {
            this.file2.url = await this.doUpload(this.file2.file);
            this.progressPercent = (100 * ++nowCount) / count;
          } catch (e) {
            console.log("Upload fail", e);
          }
        }
      } else {
        this.isOpLoading = true;
      }
      // 创建物料
      axios
        .post("/api_admin.php?a=creative/create", {
          image1: this.file1
            ? {
                width: this.file1.width,
                height: this.file1.height,
                url: this.file1.url,
                size: this.file1.size,
              }
            : null,
          image2: this.file2
            ? {
                width: this.file2.width,
                height: this.file2.height,
                url: this.file2.url,
                size: this.file2.size,
              }
            : null,
          ...this.addFormData,
          start_time: this.addFormData.start_time.unix(),
          end_time: this.addFormData.end_time.unix(),
        })
        .then((res) => {
          this.progressPercent = -1;
          this.isOpLoading = false;
          if (res.code === 0) {
            this.$emit("refreshStats");
            this.closeAddModal();
            this.$message.success("Add Success");
            this.currentPage = 1;
            this.getData();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((e) => {
          this.progressPercent = -1;
          this.isOpLoading = false;
        });
      // 显示成功
    },
    closeBatchEditModal() {
      this.isShowBatchAction = false;
      this.isShowBatchEdit = false;
      this.singleEditData = null;
      this.isShowSingleHandle = false;
      this.batchEditFormData = {
        start_time: null,
        end_time: null,
      };
    },
    closeAddModal() {
      this.file1 = null;
      this.file2 = null;
      this.isShowAdd = false;
      this.addFormData = {
        title: "",
        coupon_code: "",
        description: "",
        category: "",
        privacy: "PUBLIC",
        site_ids: [],
        start_time: moment(),
        end_time: moment().add(1, "M"),
        type: "COUPON",
        restrictions: "",
        keywords: "",
        link: "",
      };
    },
    doUpload(file) {
      var forms = new FormData();
      forms.append("imgFile", file);
      return axios
        .post(uploadUrl, forms, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          console.log(res);
          return res.data.url || null;
        });
    },
    doBatchDelete(id) {
      let cids = [];
      if (id) {
        cids.push(id);
      } else {
        cids = this.checkedList.map((item) => item.id);
      }
      this.isOpLoading = true;
      axios
        .post("/api_admin.php?a=creative/batch_delete", {
          cids,
        })
        .then((res) => {
          this.isOpLoading = false;
          if (res.code === 0) {
            this.$emit("refreshStats");
            this.$message.success("Delete Success");
            this.progressPercent = -1;
            this.closeBatchEditModal();
            this.getData();
          }
        })
        .catch((e) => {
          this.isOpLoading = false;
        });
    },
    handleChangeDate(_, dates) {
      this.params.start_time = _[0]?.unix() || null;
      this.params.end_time = _[1] ? _[1].unix() + 86399 : null;
    },
  },
  filters: {
    formatSize: (size) => {
      if (!size) {
        return "";
      } else {
        return (size / 1024).toFixed(0) + "k";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  margin-bottom: 6.25rem;
  .content-title {
    width: 100%;
    padding: 1.25rem 2.75rem 1.875rem 1.875rem;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0rem 0.125rem 0.75rem 0rem rgba(0, 0, 0, 0.06);
    border-radius: 0.75rem;
    .items {
      display: flex;
      .item {
        width: 16.375rem;
        margin-right: 1.625rem;
        &:last-child {
          margin-right: 0rem;
        }
        // span {
        //   display: block;
        //   font-size: 1rem;
        //   font-family: "dtc-bold";
        //   color: #333333;
        //   line-height: 1.875rem;
        //   margin-bottom: .375rem;
        // }
        span {
          display: block;
          font-size: 0.875rem;
          font-family: "dtc-bold";
          color: #333333;
          line-height: 1.875rem;
          margin-bottom: 0.125rem;
        }
        input {
          width: 100%;
          height: 2.375rem;
          background: #ffffff;
          border-radius: 0.375rem;
          border: 0.0625rem solid #e6e6e6;
        }
        .select {
          width: 100%;
          height: 2.375rem;
          /deep/ .ant-select-selection--single {
            height: 2.375rem;
            border-radius: 0.375rem;
            background: #fafafa;
          }
          /deep/ .ant-select-selection__rendered {
            line-height: 2.375rem;
          }
          .icon-down {
            color: #333333;
            font-size: 0.875rem;
          }
        }
        .date {
          width: 100%;
          height: 2.375rem;
          /deep/ .ant-input {
            height: 2.375rem;
            border-radius: 0.375rem;
            background: #fafafa;
          }
        }
      }
    }
    .search-reset {
      margin-top: 1.25rem;
      text-align: right;
      button {
        padding: 0.5625rem 2rem;
        border-radius: 0.375rem;
        cursor: pointer;
        border: none;
        &:first-child {
          background: #2898ff;
          font-size: 1rem;
          color: #ffffff;
          line-height: 1.25rem;
          margin-right: 1.25rem;
        }
        &:last-child {
          border: 0.0625rem solid #dddddd;
          font-size: 1rem;
          color: #333333;
          line-height: 1.25rem;
        }
      }
    }
  }

  .content-list {
    width: 100%;
    padding: 5rem 1.5625rem 1.25rem 1.5625rem;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0rem 0.125rem 0.75rem 0rem rgba(0, 0, 0, 0.06);
    border-radius: 0.75rem;
    margin-top: 1.375rem;
    position: relative;
    /deep/ .ant-spin-container {
      display: flex;
      flex-wrap: wrap;
      min-height: 18.75rem;
      justify-content: flex-start;

      .table {
        border: 0.0625rem solid #eee;
        border-radius: 0.375rem;
        width: 100%;
        box-sizing: border-box;
        height: max-content;
        .thead {
          background-color: #f9f9f9;
          height: 2.75rem;
          box-sizing: border-box;
          width: 100%;
          display: flex;
          align-items: center;
          text-align: center;
          border-bottom: 0.0625rem solid #eee;
          border-radius: 0.375rem 0.375rem 0 0;
          .td {
            font-size: 1rem;
            font-family: "dtc-bold";
            font-family: "prefer-bold";
            align-items: center !important;
          }
        }
        .tr {
          min-height: 2.75rem;
          height: max-content;
          box-sizing: border-box;
          width: 100%;
          display: flex;
          align-items: stretch;
          text-align: center;
          border-bottom: 0.0625rem solid #eee;
          &:last-child {
            border-bottom: unset;
            min-height: 2.6875rem;
          }
          &:hover {
            background-color: #f2f9ff;
          }
        }
        .td {
          font-size: 0.875rem;
          display: flex;
          min-height: 2.6875rem;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          border-right: 0.0625rem solid #eee;
          position: relative;
          a,
          url {
            color: #2898ff;
          }
          .text-edit-icon,
          .text-view-icon {
            cursor: pointer;
          }
          &:nth-child(1) {
            width: 8.5rem;
            align-items: stretch;
            .ant-checkbox-wrapper {
              width: 1.375rem;
              text-align: center;
              position: relative;
              top: calc(50% - 0.6875rem);
              box-sizing: border-box;
              margin: 0 0.5625rem;
              height: 1.375rem;
              // background-color: #2898ff;

              &.Active {
                .ant-checkbox {
                  .ant-checkbox-inner {
                    border-color: #19c42c !important;
                  }
                  &.ant-checkbox-checked {
                    .ant-checkbox-inner {
                      background-color: #19c42c !important;
                      border-color: #19c42c !important;
                    }
                    &::after {
                      border-color: #19c42c !important;
                    }
                  }
                }
                &:hover .ant-checkbox-inner {
                  border-color: #19c42c !important;
                }
                .ant-checkbox-input:focus + .ant-checkbox-inner {
                  border-color: #19c42c !important;
                }
              }
              &.scheduled {
                .ant-checkbox {
                  .ant-checkbox-inner {
                    border-color: #ffd163 !important;
                  }
                  &.ant-checkbox-checked {
                    .ant-checkbox-inner {
                      background-color: #ffd163 !important;
                      border-color: #ffd163 !important;
                    }
                    &::after {
                      border-color: #ffd163 !important;
                    }
                  }
                }
                &:hover .ant-checkbox-inner {
                  border-color: #ffd163 !important;
                }
                .ant-checkbox-input:focus + .ant-checkbox-inner {
                  border-color: #ffd163 !important;
                }
              }
              &.expired {
                .ant-checkbox {
                  .ant-checkbox-inner {
                    border-color: #999999 !important;
                  }
                  &.ant-checkbox-checked {
                    .ant-checkbox-inner {
                      background-color: #999999 !important;
                      border-color: #999999 !important;
                    }
                    &::after {
                      border-color: #999999 !important;
                    }
                  }
                }
                &:hover .ant-checkbox-inner {
                  border-color: #999999 !important;
                }
                .ant-checkbox-input:focus + .ant-checkbox-inner {
                  border-color: #999999 !important;
                }
              }
            }

            .id {
              width: 5.6875rem;
              display: flex;
              box-sizing: border-box;
              align-items: center;
              justify-content: center;
              border-left: 0.0625rem solid #eee;
            }
          }
          &:nth-child(2) {
            width: 10.375rem;
            padding: 0.5rem;
            div {
              height: max-content;
              width: 9.3125rem;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
          }
          &:nth-child(3) {
            width: 29.125rem;
            padding: 0.5rem;
            div {
              height: max-content;
              width: 28.0625rem;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
          }
          &:nth-child(4) {
            width: 6.25rem;
          }
          &:nth-child(5) {
            width: 6.25rem;
          }
          &:nth-child(6) {
            width: 6.25rem;
          }
          &:nth-child(7) {
            width: 5rem;
            padding: 0 0.5rem;
            border-right: unset;
            justify-content: space-evenly;
          }
        }
      }
    }

    .addone {
      padding: 0.5625rem 2rem;
      border-radius: 0.375rem;
      cursor: pointer;
      border: none;
      background: #2898ff;
      font-size: 1rem;
      color: #ffffff;
      line-height: 1.25rem;
      position: absolute;
      top: -3.625rem;
      left: 0rem;
    }
    .batch-edit {
      padding: 0.5rem 1.875rem;
      border-radius: 0.375rem;
      cursor: pointer;
      background: #fff;
      border: 0.0625rem solid #2898ff;
      font-size: 1rem;
      color: #2898ff;
      line-height: 1.25rem;
      position: absolute;
      top: -3.625rem;
      right: 0rem;
      box-sizing: border-box;
    }

    &.empty {
      /deep/ .ant-spin-container {
        justify-content: center;
        align-items: center;
      }
    }

    &::after {
      content: " ";
      clear: both;
    }

    .footer-pagination {
      margin-top: 1.375rem;
      text-align: right;
      width: 100%;
    }
  }
}
.coupon-view {
  /deep/ .items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      height: 8.625rem;
      width: 8.625rem;
      object-fit: contain;
      margin-bottom: 0.5rem;
    }
    .item-info {
      font-size: 0.875rem;
      color: #333333;
      line-height: 1.125rem;
      text-align: center;
    }
  }
}

.coupon-batch-edit {
  /deep/ .items {
    .left {
      width: 21.25rem;
      height: max-content;
      float: left;
      .name {
        height: 1.5rem;
        font-size: 1rem;
        font-family: "dtc-bold";
        color: #333333;
        line-height: 1.25rem;
        margin-bottom: 0.75rem;
        text-align: left;
        align-self: flex-start;
        margin-top: 1.25rem;
        &:first-child {
          margin-top: 0;
        }
      }
      .item {
        margin-bottom: 1.25rem;
        position: relative;
        padding: 0.5rem;
        box-sizing: border-box;
        border-radius: 0.5rem;
        border: 0.0625rem solid #eeeeee;
        width: 21.25rem;
        // max-height: 21.25rem;
        height: auto;
        min-height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .right {
      width: 18.75rem;
      padding-right: 1.25rem;
      box-sizing: border-box;
      float: right;
      .title {
        height: 1.5rem;
        font-size: 1.125rem;
        font-family: "prefer-bold";
        font-family: "dtc-bold";
        color: #333333;
        line-height: 1.4375rem;
        margin-bottom: 1.375rem;
      }
      .name {
        height: 1.5rem;
        font-size: 1rem;
        font-family: "dtc-bold";
        color: #333333;
        line-height: 1.25rem;
        margin-bottom: 0.25rem;
        margin-top: 0.5rem;
        align-self: flex-start;
      }
      .ant-calendar-picker {
        width: 17.5rem;
        margin-bottom: 0.75rem;
      }
      .tips {
        height: 1.125rem;
        font-size: 0.875rem;
        font-weight: normal;
        color: #2898ff;
        line-height: 1.125rem;
        margin-bottom: 2rem;
        margin-top: 0.375rem;
      }
      .options {
        display: flex;
        justify-content: flex-start;
        .delete {
          width: 8.3125rem;
          height: 2.375rem;
          font-size: 1rem;
          line-height: 2.125rem;
          box-sizing: border-box;
          vertical-align: middle;
          border-radius: 0.375rem;
          margin-right: 0.875rem;
        }
        .update {
          width: 8.3125rem;
          height: 2.375rem;
          cursor: pointer;
          background: #2898ff;
          border: 0.0625rem solid #2898ff;
          vertical-align: middle;
          font-size: 1rem;
          color: #fff;
          line-height: 2.125rem;
          box-sizing: border-box;
          border-radius: 0.375rem;
        }
      }
    }

    &::after {
      display: block;
      content: " ";
      clear: both;
    }
  }
}

.coupon-batch-delete {
  .text {
    width: 29.875rem;
    height: 5.625rem;
    font-size: 1rem;
    color: #333333;
    line-height: 1.25rem;
    text-align: center;
    margin-top: 0.625rem;
  }
}
.coupon-batch-add {
  /deep/ .items {
    .left {
      width: 21.25rem;
      height: max-content;
      float: left;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .item {
        margin-bottom: 1.25rem;
        position: relative;
        .coupon-icon-view,
        .coupon-icon-del {
          position: absolute;
          top: 0.5rem;
          right: 0.5rem;
          height: 1.5625rem;
          width: 1.5625rem;
          cursor: pointer;
        }
        .coupon-icon-view {
          right: 1.75rem;
        }
        .preview {
          padding: 0.5rem;
          box-sizing: border-box;
          border-radius: 0.5rem;
          border: 0.0625rem solid #eeeeee;
          width: 21.25rem;
          max-height: 21.25rem;
          height: auto;
          object-fit: contain;
        }
      }
      .size {
        height: 1.25rem;
        font-size: 0.75rem;
        color: #999999;
        line-height: 1.25rem;
        text-align: center;
        margin-top: 0.5rem;
      }
      .name {
        height: 1.5rem;
        font-size: 1rem;
        font-family: "dtc-bold";
        color: #333333;
        line-height: 1.25rem;
        margin-bottom: 0.75rem;
        text-align: left;
        align-self: flex-start;
        margin-top: 1.25rem;
        &:first-child {
          margin-top: 0;
        }
      }
      .upload-small {
        width: 10.125rem;
        height: 2.375rem;
        margin: 0rem auto;
        cursor: pointer;
        background: #2898ff;
        border: 0.0625rem solid #2898ff;
        vertical-align: middle;
        font-size: 1rem;
        color: #fff;
        line-height: 2.125rem;
        box-sizing: border-box;
        border-radius: 0.375rem;
        text-align: center;
      }

      .upload-big {
        width: 21.25rem;
        height: 8.75rem;
        border-radius: 0.5rem;
        border: 0.0625rem solid #eeeeee;
        margin-bottom: 1.25rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #333;
        cursor: pointer;
        transition: all 0.2s ease;
        &:hover {
          border-color: #ccc;
          .upload-big-img {
            &:after {
              background: #c8c8c8;
            }
            &:before {
              background: #c8c8c8;
            }
          }
        }
        > span {
          position: relative;
          top: 0.625rem;
        }
        .upload-big-img {
          height: 3.625rem;
          width: 3.625rem;
          position: relative;
          transition: all 0.2s ease;
          &:after {
            transition: all 0.2s ease;
            content: "";
            height: 0.3125rem;
            width: 3.625rem;
            background: #d8d8d8;
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -0.1563rem;
            border-radius: 0.25rem;
          }
          &:before {
            transition: all 0.2s ease;
            content: "";
            height: 3.625rem;
            width: 0.3125rem;
            background: #d8d8d8;
            display: block;
            position: absolute;
            left: 50%;
            top: 0;
            margin-left: -0.1563rem;
            border-radius: 0.25rem;
          }
        }
      }
    }
    .right {
      width: 18.75rem;
      padding-right: 1.25rem;
      box-sizing: border-box;
      float: right;
      .title {
        height: 1.5rem;
        font-size: 1.125rem;
        font-family: "prefer-bold";
        font-family: "dtc-bold";
        color: #333333;
        line-height: 1.4375rem;
        margin-bottom: 1.375rem;
      }
      .name {
        height: 1.5rem;
        font-size: 1rem;
        font-family: "dtc-bold";
        color: #333333;
        line-height: 1.25rem;
        margin-bottom: 0.25rem;
        margin-top: 0.5rem;
        &.desc {
          cursor: default;
          position: relative;
          > span:first-child {
            display: inline-block;
            height: 1.25rem;
            width: 1.25rem;
            transform: scale(0.6);
            font-size: 0.875rem;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 400;
            background-color: #2898ff;
            border-radius: 62.4375rem;
            text-align: center;
            line-height: 1.25rem;
            vertical-align: middle;
            color: #fff;
            &:hover + span > span {
              display: block;
            }
          }
          > span:last-child {
            position: relative;
            height: 0rem;
            width: 0rem;
            display: inline-block;
            span {
              font-family: dtc;
              color: #2898ff;
              background-color: rgba(62, 61, 64, 0.9);
              position: absolute;
              left: -2.5rem;
              top: 0.8125rem;
              padding: 0.5rem;
              width: 12.5rem;
              box-sizing: border-box;
              border-radius: 0.25rem;
              color: #fff;
              font-size: 0.75rem;
              line-height: 0.9375rem;
              z-index: 1;
              font-weight: 400;
              height: max-content;
              display: none;

              &::before {
                display: block;
                content: " ";
                border: 0.3125rem solid rgba(62, 61, 64, 0.9);
                border-top-color: rgba(0, 0, 0, 0);
                border-right-color: rgba(0, 0, 0, 0);
                height: 0.625rem;
                box-sizing: border-box;
                width: 0.625rem;
                border-bottom-left-radius: 0.0625rem;
                position: absolute;
                top: -0.3125rem;
                left: 1.5625rem;
                transform: rotate(135deg);
              }
            }
          }
        }
      }
      .ant-calendar-picker {
        width: 17.5rem;
        margin-bottom: 0.75rem;
      }
      > .ant-input,
      .ant-select {
        width: 17.5rem;
        margin-bottom: 0.75rem;
      }
      .tips {
        height: 1.125rem;
        font-size: 0.875rem;
        font-weight: normal;
        color: #2898ff;
        line-height: 1.125rem;
        margin-bottom: 2rem;
        margin-top: 0.375rem;
      }
      .options {
        display: flex;
        justify-content: flex-end;
        .add {
          width: 8.3125rem;
          height: 2.375rem;
          cursor: pointer;
          background: #2898ff;
          border: 0.0625rem solid #2898ff;
          vertical-align: middle;
          font-size: 1rem;
          color: #fff;
          line-height: 2.125rem;
          box-sizing: border-box;
          border-radius: 0.375rem;
        }
      }
    }

    &::after {
      display: block;
      content: " ";
      clear: both;
    }
  }
}
.coupon-single-edit {
  /deep/ .items {
    .left {
      width: 21.25rem;
      height: max-content;
      float: left;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .name {
        height: 1.5rem;
        font-size: 1rem;
        font-family: "dtc-bold";
        color: #333333;
        line-height: 1.25rem;
        margin-bottom: 0.75rem;
        text-align: left;
        align-self: flex-start;
        margin-top: 1.25rem;
        &:first-child {
          margin-top: 0;
        }
      }
      .item {
        margin-bottom: 0.625rem;
        position: relative;
        .coupon-icon-view,
        .coupon-icon-del {
          position: absolute;
          top: 0.5rem;
          right: 0.5rem;
          height: 1.5625rem;
          width: 1.5625rem;
          cursor: pointer;
        }
        .coupon-icon-view {
          right: 1.75rem;
        }
        .preview {
          padding: 0.5rem;
          box-sizing: border-box;
          border-radius: 0.5rem;
          border: 0.0625rem solid #eeeeee;
          width: 21.25rem;
          max-height: 21.25rem;
          height: auto;
          object-fit: contain;
        }
      }
      .size {
        height: 1.25rem;
        font-size: 0.75rem;
        color: #999999;
        line-height: 1.25rem;
        text-align: center;
        margin-top: 0.5rem;
      }
      .upload-small {
        width: 10.125rem;
        height: 2.375rem;
        margin: 0rem auto;
        cursor: pointer;
        background: #2898ff;
        border: 0.0625rem solid #2898ff;
        vertical-align: middle;
        font-size: 1rem;
        color: #fff;
        line-height: 2.125rem;
        box-sizing: border-box;
        border-radius: 0.375rem;
        text-align: center;
      }

      .upload-big {
        width: 21.25rem;
        height: 12.75rem;
        border-radius: 0.5rem;
        border: 0.0625rem solid #eeeeee;
        margin-bottom: 1.25rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #333;
        cursor: pointer;
        transition: all 0.2s ease;
        &:hover {
          border-color: #ccc;
          .upload-big-img {
            &:after {
              background: #c8c8c8;
            }
            &:before {
              background: #c8c8c8;
            }
          }
        }
        > span {
          position: relative;
          top: 2.5rem;
        }
        .upload-big-img {
          height: 3.625rem;
          width: 3.625rem;
          position: relative;
          transition: all 0.2s ease;
          &:after {
            transition: all 0.2s ease;
            content: "";
            height: 0.3125rem;
            width: 3.625rem;
            background: #d8d8d8;
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -0.1563rem;
            border-radius: 0.25rem;
          }
          &:before {
            transition: all 0.2s ease;
            content: "";
            height: 3.625rem;
            width: 0.3125rem;
            background: #d8d8d8;
            display: block;
            position: absolute;
            left: 50%;
            top: 0;
            margin-left: -0.1563rem;
            border-radius: 0.25rem;
          }
        }
      }
    }
    .right {
      width: 18.75rem;
      padding-right: 1.25rem;
      box-sizing: border-box;
      float: right;
      .title {
        height: 1.5rem;
        font-size: 1.125rem;
        font-family: "prefer-bold";
        font-family: "dtc-bold";
        color: #333333;
        line-height: 1.4375rem;
        margin-bottom: 1.375rem;
      }
      .name {
        height: 1.5rem;
        font-size: 1rem;
        font-family: "dtc-bold";
        color: #333333;
        line-height: 1.25rem;
        margin-bottom: 0.25rem;
        margin-top: 0.5rem;
        &.desc {
          cursor: default;
          position: relative;
          > span:first-child {
            display: inline-block;
            height: 1.25rem;
            width: 1.25rem;
            transform: scale(0.6);
            font-size: 0.875rem;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 400;
            background-color: #2898ff;
            border-radius: 62.4375rem;
            text-align: center;
            line-height: 1.25rem;
            vertical-align: middle;
            color: #fff;
            &:hover + span > span {
              display: block;
            }
          }
          > span:last-child {
            position: relative;
            height: 0rem;
            width: 0rem;
            display: inline-block;
            span {
              font-family: dtc;
              color: #2898ff;
              background-color: rgba(62, 61, 64, 0.9);
              position: absolute;
              left: -2.5rem;
              top: 0.8125rem;
              padding: 0.5rem;
              width: 12.5rem;
              box-sizing: border-box;
              border-radius: 0.25rem;
              color: #fff;
              font-size: 0.75rem;
              line-height: 0.9375rem;
              z-index: 1;
              font-weight: 400;
              height: max-content;
              display: none;

              &::before {
                display: block;
                content: " ";
                border: 0.3125rem solid rgba(62, 61, 64, 0.9);
                border-top-color: rgba(0, 0, 0, 0);
                border-right-color: rgba(0, 0, 0, 0);
                height: 0.625rem;
                box-sizing: border-box;
                width: 0.625rem;
                border-bottom-left-radius: 0.0625rem;
                position: absolute;
                top: -0.3125rem;
                left: 1.5625rem;
                transform: rotate(135deg);
              }
            }
          }
        }
      }
      .ant-calendar-picker {
        width: 17.5rem;
        margin-bottom: 0.75rem;
      }
      > .ant-input,
      .ant-select {
        width: 17.5rem;
        margin-bottom: 0.75rem;
      }
      .tips {
        height: 1.125rem;
        font-size: 0.875rem;
        font-weight: normal;
        color: #2898ff;
        line-height: 1.125rem;
        margin-bottom: 2rem;
        margin-top: 0.375rem;
      }
      .options {
        display: flex;
        justify-content: flex-start;
        .delete {
          width: 8.3125rem;
          height: 2.375rem;
          font-size: 1rem;
          line-height: 2.125rem;
          box-sizing: border-box;
          vertical-align: middle;
          border-radius: 0.375rem;
          margin-right: 0.875rem;
        }
        .update {
          width: 8.3125rem;
          height: 2.375rem;
          cursor: pointer;
          background: #2898ff;
          border: 0.0625rem solid #2898ff;
          vertical-align: middle;
          font-size: 1rem;
          color: #fff;
          line-height: 2.125rem;
          box-sizing: border-box;
          border-radius: 0.375rem;
        }
      }
    }

    &::after {
      display: block;
      content: " ";
      clear: both;
    }
  }
}
</style>
