<template>
  <div id="creative-banners">
    <div class="content">
      <div class="content-title">
        <div class="items">
          <div class="item" style="width: 270px">
            <span>Date Range</span>
            <a-range-picker
              class="date"
              v-model="dateRange"
              @change="handleChangeDate"
              format="L"
            />
          </div>
          <div class="item" style="width: 270px">
            <span>Status</span>
            <PSelector
              size="large"
              v-model="params.status"
              :options="[
                {
                  value: 'Scheduled',
                  label: 'Scheduled',
                },
                {
                  value: 'Active',
                  label: 'Active',
                },
                {
                  value: 'Expired',
                  label: 'Expired',
                },
              ]"
              multiple
              clearAble
            ></PSelector>
          </div>
          <div class="item" style="width: 270px">
            <span>Permission</span>
            <PSelector
              size="large"
              v-model="params.privacy"
              :options="[
                {
                  value: 'PUBLIC',
                  label: 'Public for all publishers',
                },
                {
                  value: 'PRIVATE',
                  label: 'Private',
                },
              ]"
              multiple
              clearAble
            ></PSelector>
          </div>
          <div class="item" style="width: 270px">
            <span>Search keywords</span>
            <a-input v-model="params.keyword" />
          </div>
        </div>
        <div class="search-reset">
          <button @click="search">Search</button>
          <button @click="reset">Reset</button>
        </div>
      </div>
      <a-spin
        class="content-list"
        :class="{ empty: list.length == 0 }"
        :spinning="isLoading"
      >
        <a-empty v-if="list.length == 0 && !isLoading" />
        <button class="addone" @click="isShowAdd = true">Add Banner</button>
        <button
          class="batch-edit"
          @click="showEdit()"
          v-if="checkedList.length > 0"
        >
          Edit Selected
        </button>
        <div
          class="item"
          v-for="item in list"
          :key="item.id"
          :class="{
            checked: checkedList.includes(item),
          }"
          @click="showEdit(item)"
        >
          <img
            :src="item.images[0].image_url"
            :alt="item.images[0].alternate"
          />
          <div
            class="checkbox"
            :class="{
              checked: checkedList.includes(item),
            }"
            @click.stop="checkHandle(item)"
          >
            <img src="@/assets/check-box-checked.svg" alt />
          </div>
          <img
            class="icon-policy"
            src="@/assets/banner-private.svg"
            alt="private"
            v-if="item.privacy == 'PRIVATE'"
          />
          <img
            class="icon-view"
            src="@/assets/banner-view.svg"
            :class="{ checked: showViewInfo.id == item.id }"
            alt
            @click.stop="
              showViewInfo = { id: item.id, data: item };
              isShowView = true;
            "
          />

          <div
            class="item-title"
            :class="{
              Active: item.status == 'Active',
              expired: item.status == 'Expired',
              scheduled: item.status == 'Scheduled',
            }"
          >
            {{ item.images[0].width }}x{{ item.images[0].height }}
          </div>
        </div>
        <div class="footer-pagination">
          <a-pagination
            v-model="currentPage"
            :total="totalSize"
            :pageSize="pageSize"
            @change="paginationChange"
          />
        </div>
      </a-spin>
    </div>

    <!-- View -->
    <Modal
      :isVisible="isShowView"
      width="365px"
      submitText="Confirm"
      @close-popup="
        isShowView = false;
        showViewInfo = { id: null, data: null };
      "
      :showBtn="false"
      :showTitle="false"
      class="banner-view"
    >
      <template #content>
        <a :href="showViewInfo.data.images[0].image_url" target="_blank">
          <img :src="showViewInfo.data.images[0].image_url" alt />
        </a>
        <div class="item-info" v-if="showViewInfo.id">
          Banner ID: {{ showViewInfo.id }}
          <br />
          {{ showViewInfo.data.images[0].width }}x{{
            showViewInfo.data.images[0].height
          }}
          <br />
          {{ showViewInfo.data.images[0].size | formatSize }}
          <br />
          {{ showViewInfo.data.start_time | formatMDY }}~{{
            showViewInfo.data.end_time | formatMDY
          }}
          <br />
          {{ showViewInfo.data.link }}
        </div>
      </template>
    </Modal>

    <!-- BatchEdit -->
    <Modal
      :isVisible="isShowBatchEdit"
      width="760px"
      submitText="Confirm"
      @close-popup="closeBatchEditModal"
      :showBtn="false"
      :title="
        modifyBatchStatus === 'Expired' ? ' Banner Expire' : 'Banner Edit'
      "
      class="banner-batch-edit"
    >
      <template #content>
        <div class="left">
          <div class="name">Edit this file in your creative inventory.</div>
          <template v-if="isShowBatchEdit">
            <div class="item" v-for="item in checkedList" :key="item.id">
              <img
                :src="item.images[0].image_url"
                :alt="item.images[0].alternate"
              />
              <div class="size">
                {{ item.images[0].width }}x{{ item.images[0].height }}
              </div>
              <div class="tips">{{ item.link }}</div>
            </div>
          </template>
        </div>
        <div class="right">
          <div class="title">Batch Edit Time</div>
          <div class="name">Start Time</div>
          <a-date-picker
            :disabled="modifyBatchStatus === 'Expired'"
            :show-time="{
              format: 'HH:mm',
              minuteStep: 5,
            }"
            format="L HH:mm"
            placeholder="Select Start Time"
            v-model="batchEditFormData.start_time"
          />
          <div class="name">End Time</div>
          <a-date-picker
            :disabled="modifyBatchStatus === 'Expired'"
            :show-time="{
              format: 'HH:mm',
              minuteStep: 5,
            }"
            format="L HH:mm"
            placeholder="Select End Time"
            v-model="batchEditFormData.end_time"
          />

          <div class="tips">
            ({{ $store.state.timezone.gmt.replace(" ", "") }}) Cook lslands
            Standard Time
          </div>
          <div class="options" v-if="modifyBatchStatus !== 'Expired'">
            <a-button class="delete" @click="isShowBatchAction = true">
              {{ modifyBatchStatus === "Active" ? "Expire" : "Delete" }}
            </a-button>
            <a-button class="update" @click="batchEdit">Update</a-button>
          </div>
        </div>
      </template>
    </Modal>

    <!-- BatchDelete -->
    <Modal
      :isVisible="isShowBatchAction"
      width="528px"
      submitText="Confirm"
      @close-popup="isShowBatchAction = false"
      :title="modifyBatchStatus === 'Active' ? 'Expire' : 'Delete'"
      class="banner-batch-delete"
      @submit="modifyBatchHandle()"
    >
      <template #content>
        <div class="text">
          Are you sure you want to
          {{ modifyBatchStatus === "Active" ? "expire" : "delete" }} the
          banners? <br />(After cliking “confirm,” they will be
          {{ modifyBatchStatus === "Active" ? "expired" : "deleted" }}
          permanently, and the publishers won’t be able to see them anymore.)
        </div>
      </template>
    </Modal>

    <!-- BatchAdd -->
    <Modal
      :isVisible="isShowAdd"
      width="760px"
      submitText="Confirm"
      @close-popup="closeAddModal"
      title="Add Banner"
      class="banner-batch-add"
      :showBtn="false"
    >
      <template #content>
        <div class="left">
          <div class="name">Add file(s) in your creative inventory</div>
          <div class="item" v-for="(file, idx) in fileList" :key="file.hash">
            <img :src="file.src" class="preview" />
            <img
              src="@/assets/banner-add-del.svg"
              class="banner-icon-del"
              @click="fileList.splice(idx, 1)"
            />
            <div class="size">{{ file.width }} x {{ file.height }}</div>
          </div>
          <label
            class="upload-big"
            v-show="fileList.length == 0"
            for="upload-small"
            :class="{ error: needUpload }"
          >
            <div class="upload-big-img"></div>
            <span>Choose a File</span>
            <span>You must upload an image file</span>
          </label>
          <div class="size">File Max Size: 1M; Accpeted File Type: jpg,png</div>
          <input
            ref="upload-small"
            type="file"
            accept=".png, .jpg"
            id="upload-small"
            @change="handleChange"
            style="display:none"
            multiple
          />
          <label
            class="upload-small"
            for="upload-small"
            v-show="fileList.length > 0 && fileList.length < 20"
            >choose your files</label
          >
        </div>
        <div class="right">
          <div class="title">Infomation</div>
          <div class="name">Name</div>
          <a-input v-model.trim="addFormData.title"></a-input>
          <div class="name desc">
            Category
            <span>?</span>
            <span>
              <span>Feel free to name your own category.</span>
            </span>
          </div>
          <a-input v-model="addFormData.category"></a-input>

          <div class="name">Link</div>
          <a-input v-model.trim="addFormData.link"></a-input>
          <div class="name">Alternate Text</div>
          <a-input v-model.trim="addFormData.alternate"></a-input>
          <div class="name">Permission</div>
          <a-select placeholder="Select" v-model="addFormData.privacy">
            <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
            <a-select-option value="PUBLIC"
              >Public for all publishers</a-select-option
            >
            <a-select-option value="PRIVATE">Private</a-select-option>
          </a-select>

          <template v-if="addFormData.privacy == 'PRIVATE'">
            <div class="name">Publisher</div>

            <a-select
              mode="multiple"
              v-model="addFormData.site_ids"
              placeholder="Please select"
              :filter-option="
                (input, option) =>
                  option.componentOptions.children[0].text
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
              "
            >
              <a-select-option
                v-for="medium in mediumLists"
                :key="`${medium.title} (${medium.site_id})`"
                :value="medium.site_id"
                >{{ `${medium.title} (${medium.site_id})` }}</a-select-option
              >
            </a-select>
          </template>

          <div class="name">Start Time</div>
          <a-date-picker
            :show-time="{
              format: 'HH:mm',
              minuteStep: 5,
            }"
            format="L HH:mm"
            placeholder="Select Start Time"
            v-model="addFormData.start_time"
          />
          <div class="name">End Time</div>
          <a-date-picker
            :show-time="{
              format: 'HH:mm',
              minuteStep: 5,
            }"
            format="L HH:mm"
            placeholder="Select End Time"
            v-model="addFormData.end_time"
          />

          <div class="tips">
            ({{ $store.state.timezone.gmt.replace(" ", "") }}) Cook lslands
            Standard Time
          </div>
          <div class="options">
            <button class="add" @click="doSubmitAdd">Add</button>
          </div>
        </div>
      </template>
    </Modal>

    <!-- SingleEdit -->
    <Modal
      :isVisible="singleEditData != null"
      width="760px"
      submitText="Confirm"
      @close-popup="singleEditData = null"
      :title="
        singleModifyStatus === 'Expired' ? 'Banner Expire' : 'Banner Edit'
      "
      class="banner-single-edit"
      :showBtn="false"
    >
      <template #content v-if="singleEditData">
        <div class="left">
          <div class="name">Edit your banner information.</div>
          <div class="item" v-if="singleEditData.file">
            <img :src="singleEditData.file.src" class="preview" />
            <div class="size">
              {{ singleEditData.file.width }} x {{ singleEditData.file.height }}
            </div>
          </div>
          <div class="item" v-else>
            <img :src="singleEditData.images[0].image_url" class="preview" />
            <div class="size">
              {{ singleEditData.images[0].width }} x
              {{ singleEditData.images[0].height }}
            </div>
          </div>
          <div class="size">File Max Size: 1M; Accpeted File Type: jpg,png</div>
          <input
            :disabled="singleEditData.status === 'Expired'"
            ref="upload-small-2"
            type="file"
            accept=".png, .jpg"
            id="upload-small-2"
            @change="handleChangeSingle"
            style="display:none"
          />
          <label
            v-if="singleEditData.status !== 'Expired'"
            class="upload-small"
            for="upload-small-2"
            >Edit</label
          >
        </div>
        <div class="right">
          <div class="title">Infomation</div>
          <div class="name">Name</div>
          <a-input
            :disabled="singleEditData.status === 'Expired'"
            v-model.trim="singleEditData.title"
          ></a-input>
          <div class="name desc">
            Category
            <span>?</span>
            <span>
              <span>Feel free to name your own category.</span>
            </span>
          </div>
          <a-input
            :disabled="singleEditData.status === 'Expired'"
            v-model.trim="singleEditData.category"
          ></a-input>
          <div class="name">Link</div>
          <a-input
            :disabled="singleEditData.status === 'Expired'"
            v-model.trim="singleEditData.link"
          ></a-input>
          <div class="name">Alternate Text</div>
          <a-input
            :disabled="singleEditData.status === 'Expired'"
            v-model.trim="singleEditData.alternate"
          ></a-input>

          <div class="name">Permission</div>
          <a-select
            :disabled="singleEditData.status === 'Expired'"
            placeholder="Select"
            v-model="singleEditData.privacy"
          >
            <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
            <a-select-option value="PUBLIC"
              >Public for all publishers</a-select-option
            >
            <a-select-option value="PRIVATE">Private</a-select-option>
          </a-select>

          <template v-if="singleEditData.privacy == 'PRIVATE'">
            <div class="name">Publisher</div>

            <a-select
              :disabled="singleEditData.status === 'Expired'"
              mode="multiple"
              v-model="singleEditData.site_ids"
              placeholder="Please select"
            >
              <a-select-option
                v-for="medium in mediumLists"
                :key="`${medium.title} (${medium.site_id})`"
                :value="medium.site_id"
                >{{ `${medium.title} (${medium.site_id})` }}</a-select-option
              >
            </a-select>
          </template>

          <div class="name">Start Time</div>
          <a-date-picker
            :disabled="singleEditData.status === 'Expired'"
            :show-time="{
              format: 'HH:mm',
              minuteStep: 5,
            }"
            format="L HH:mm"
            placeholder="Select Start Time"
            v-model="singleEditData.start_time"
          />
          <div class="name">End Time</div>
          <a-date-picker
            :disabled="singleEditData.status === 'Expired'"
            :show-time="{
              format: 'HH:mm',
              minuteStep: 5,
            }"
            format="L HH:mm"
            placeholder="Select End Time"
            v-model="singleEditData.end_time"
          />

          <div class="tips">
            ({{ $store.state.timezone.gmt.replace(" ", "") }}) Cook lslands
            Standard Time
          </div>
          <div class="options" v-if="singleEditData.status !== 'Expired'">
            <a-button class="delete" @click="isShowSingleHandle = true">
              {{ singleEditData.status === "Active" ? "Expire" : "Delete" }}
            </a-button>
            <a-button class="update" @click="doSingleEdit">Update</a-button>
          </div>
        </div>
      </template>
    </Modal>

    <!-- SingleDelete -->
    <Modal
      :isVisible="isShowSingleHandle"
      width="528px"
      submitText="Confirm"
      @close-popup="isShowSingleHandle = false"
      :title="singleModifyStatus === 'Active' ? 'Expire' : 'Delete'"
      class="banner-batch-delete"
      @submit="doShowSingleHandle(singleEditData)"
    >
      <template #content>
        <div class="text" v-if="singleEditData">
          Are you sure you want to
          {{ singleModifyStatus === "Active" ? "expire" : "delete" }} this
          banner? <br />(After cliking “confirm,” this banner will be
          {{ singleEditData.status === "Scheduled" ? "deleted" : "expired" }}
          permanently, and the publishers won’t be able to see it anymore.)
        </div>
      </template>
    </Modal>

    <Loading
      v-if="progressPercent > -1"
      :percent="progressPercent"
      type="progress"
    ></Loading>
    <Loading v-if="isOpLoading"></Loading>
    <div ref="temp" style="display:none"></div>
  </div>
</template>

<script>
import Modal from "../../components/Modal.vue";
import axios from "@/utils/request";
import moment from "moment-timezone";
import { uploadUrl } from "@/utils/config";
import { useDebounceMessage } from "@/utils/hooks";
import { useModifyStatus } from "./utils";

export default {
  name: "creative_banner",
  components: {
    Modal,
  },
  data() {
    return {
      singleModifyStatus: "",
      needUpload: false,
      progressPercent: -1,
      isOpLoading: false,
      dateRange: [],
      list: [],
      params: {
        start_time: null,
        end_time: null,
        status: [],
        privacy: [],
        keyword: null,
        type: "IMAGE",
      },
      addFormData: {
        title: "",
        category: "",
        privacy: "PUBLIC",
        site_ids: [],
        start_time: moment(),
        end_time: moment().add(1, "M"),
        type: "IMAGE",
        alternate: "",
        link: "",
      },
      checkedList: [],
      currentPage: 1,
      totalSize: 1,
      pageSize: 28,
      isLoading: false,
      isShowView: false,
      showViewInfo: {
        id: null,
        data: null,
      },
      isShowBatchEdit: false,
      isShowBatchAction: false,
      isShowSingleHandle: false,
      modifyBatchStatus: "",
      isShowAdd: false,
      singleEditData: null,
      batchEditFormData: {
        start_time: null,
        end_time: null,
      },
      fileList: [],
      mediumLists: [],
    };
  },
  mounted() {
    this._getMediumLists();
    this.getData();
  },
  methods: {
    modifyBatchHandle() {
      if (this.modifyBatchStatus === "Active") {
        this.batchExpire(); // 过期
      } else {
        this.doBatchDelete(); // 删除
      }
    },
    doShowSingleHandle(row) {
      if (row.status === "Scheduled") {
        this.doBatchDelete(row.id);
      } else {
        this.singleEditData.end_time = moment();
        this.doSingleEdit();
      }
    },
    async doSingleEdit() {
      // 检查表单
      if (
        this.singleEditData.privacy == "PRIVATE" &&
        (!this.singleEditData.site_ids ||
          this.singleEditData.site_ids.length == 0)
      ) {
        this.$message.error("Please select publishers on private mode");
        return;
      }
      if (!this.singleEditData.start_time || !this.singleEditData.end_time) {
        this.$message.error("Please select time");
        return;
      }
      if (
        this.singleEditData.start_time.unix() >=
        this.singleEditData.end_time.unix()
      ) {
        this.$message.error("Please check the time you select");
        return;
      }

      let data = {
        id: this.singleEditData.id,
        title: this.singleEditData.title,
        alternate: this.singleEditData.alternate,
        category: this.singleEditData.category,
        link: this.singleEditData.link,
        privacy: this.singleEditData.privacy,
        site_ids: this.singleEditData.site_ids,
        start_time: this.singleEditData.start_time.unix(),
        end_time: this.singleEditData.end_time.unix(),
      };
      if (this.singleEditData.file) {
        // 显示进度
        this.progressPercent = 0;
        // 上传文件
        try {
          this.singleEditData.file.url = await this.doUpload(
            this.singleEditData.file.file
          );
          this.progressPercent = 100;
          data.image = {
            width: this.singleEditData.file.width,
            height: this.singleEditData.file.height,
            url: this.singleEditData.file.url,
            size: this.singleEditData.file.size,
          };
        } catch (e) {
          console.log("Upload fail", e);
        }
      } else {
        this.isOpLoading = true;
      }
      // 创建物料
      axios.post("/api_admin.php?a=creative/modify", data).then((res) => {
        this.progressPercent = -1;
        this.isOpLoading = false;
        if (res.code === 0) {
          this.singleEditData = null;
          this.isShowBatchAction = false;
          this.isShowSingleHandle = false;
          this.$message.success("Edit Success");
          this.currentPage = 1;
          this.getData();
        } else {
          this.$message.error(res.msg);
        }
      });
      // 显示成功
    },
    checkHandle(item) {
      const status = useModifyStatus(item);
      const checkedList = this.checkedList;
      if (checkedList.length > 0) {
        const currentStatus = useModifyStatus(checkedList[0]);
        if (status !== currentStatus) {
          return useDebounceMessage.call(this, {
            message:
              "You can only select and edit creatives of the same status at the same time",
            type: "error",
          });
        }
      }
      checkedList.includes(item)
        ? checkedList.splice(checkedList.indexOf(item), 1)
        : checkedList.push(item);
    },
    showEdit(item) {
      if (item || this.checkedList.length == 1) {
        item = item || this.checkedList[0];
        this.singleModifyStatus = useModifyStatus(item);
        this.singleEditData = Object.assign({}, item, {
          start_time: moment.unix(item.start_time),
          end_time: moment.unix(item.end_time),
          site_ids: item.private_site_ids,
          alternate: item.images[0].alternate,
          status: this.singleModifyStatus,
          file: null,
        });
      } else if (this.checkedList.length > 1) {
        this.isShowBatchEdit = true;
        this.modifyBatchStatus = useModifyStatus(this.checkedList[0]);
      }
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
    },
    _getMediumLists() {
      axios
        .get("/api_admin.php?a=medium/list", {
          params: {
            status: ["adopt"],
            simple: 1,
          },
        })
        .then((res) => {
          if (res.code === 0) {
            this.mediumLists = res.data.data;
          }
        });
    },
    handleChange(event, isSingle) {
      this.needUpload = false;
      let overSizeCount = 0;
      console.log(event.target.files);
      for (let i = 0; i < event.target.files.length; i++) {
        console.log(this.fileList.length);
        if (this.fileList.length >= 100) {
          this.$message.error("Select a maximum of 100 pictures at a time");
          break;
        }
        let file = event.target.files[i];
        if (file.size / 1024 / 1024 >= 1) {
          overSizeCount++;
          continue;
        }
        let image = new Image();
        image.src = (
          window.createObjectURL ||
          window.URL?.createObjectURL ||
          window.webkitURL?.createObjectURL
        )(file);
        image.onload = () => {
          let fileInfo = {
            hash: new Date().getTime() + "_" + Math.random() + "_" + file.name,
            file: file,
            src: image.src,
            height: image.height,
            width: image.width,
            size: file.size,
            url: null,
          };
          if (isSingle) {
            this.singleEditData.file = fileInfo;
          } else {
            if (this.fileList.length < 20) {
              this.fileList.push(fileInfo);
            }
          }
          this.$refs.temp.removeChild(image);
        };
        this.$refs.temp.appendChild(image);
      }
      event.target.value = null;
      if (overSizeCount > 0) {
        this.$message.error(`${overSizeCount} pictures is over 1m`);
      }
    },
    handleChangeSingle(event) {
      this.handleChange(event, true);
    },
    getData() {
      this.checkedList = [];
      this.isLoading = true;
      axios
        .get("/api_admin.php?a=creative/list", {
          params: {
            ...this.params,
            page_num: this.currentPage,
            page_size: this.pageSize,
          },
        })
        .then((res) => {
          this.isLoading = false;
          if (res.code === 0) {
            this.list = res.data.data;
            this.totalSize = parseInt(res.data.total_size);
          } else {
            this.$message.error(
              res.msg || "Sorry, server is busy. please try later."
            );
          }
        });
    },
    search() {
      this.getData();
    },
    paginationChange(page, pageSize) {
      this.currentPage = page;
      this.getData();
    },
    batchEdit() {
      let cids = this.checkedList.map((item) => item.id);
      if (
        !this.batchEditFormData.start_time ||
        !this.batchEditFormData.end_time
      ) {
        this.$message.error("Please select time");
        return;
      }
      if (
        this.batchEditFormData.start_time.unix() >=
        this.batchEditFormData.end_time.unix()
      ) {
        this.$message.error("Please check the time you select");
        return;
      }
      this.isOpLoading = true;
      axios
        .post("/api_admin.php?a=creative/batch_modify", {
          cids,
          start_time: this.batchEditFormData.start_time.unix(),
          end_time: this.batchEditFormData.end_time.unix(),
        })
        .then((res) => {
          this.isOpLoading = false;
          if (res.code === 0) {
            this.$message.success("Batch Edit Success");
            this.progressPercent = -1;
            this.closeBatchEditModal();
            this.getData();
          }
        })
        .catch((e) => {
          this.isOpLoading = false;
        });
    },
    batchExpire() {
      let cids = this.checkedList.map((item) => item.id);
      this.isOpLoading = true;
      axios
        .post("/api_admin.php?a=creative/batch_expire", {
          cids,
        })
        .then((res) => {
          this.isOpLoading = false;
          if (res.code === 0) {
            this.$message.success("Batch Edit Success");
            this.progressPercent = -1;
            this.closeBatchEditModal();
            this.getData();
          }
        })
        .catch((e) => {
          this.isOpLoading = false;
        });
    },
    reset() {
      this.dateRange = [];
      this.params = {
        start_time: null,
        end_time: null,
        status: [],
        privacy: [],
        keyword: null,
        type: "IMAGE",
      };
    },
    async doSubmitAdd() {
      // 检查表单
      if (this.fileList.length < 1) {
        this.$message.error("Please select a picture");
        this.needUpload = true;
        return;
      }
      if (
        this.addFormData.privacy == "PRIVATE" &&
        (!this.addFormData.site_ids || this.addFormData.site_ids.length == 0)
      ) {
        this.$message.error("Please select publishers on private mode");
        return;
      }
      if (!this.addFormData.start_time || !this.addFormData.end_time) {
        this.$message.error("Please select time");
        return;
      }
      if (
        this.addFormData.start_time.unix() >= this.addFormData.end_time.unix()
      ) {
        this.$message.error("Please check the time you select");
        return;
      }
      // 显示进度
      this.progressPercent = 0;
      // 上传文件
      for (let i = 0; i < this.fileList.length; i++) {
        try {
          this.fileList[i].url = await this.doUpload(this.fileList[i].file);
          this.progressPercent = (100 * (i + 1)) / this.fileList.length;
        } catch (e) {
          console.log("Upload fail", e);
        }
      }
      // 创建物料
      axios
        .post("/api_admin.php?a=creative/create", {
          images: this.fileList.map((f) => ({
            width: f.width,
            height: f.height,
            url: f.url,
            size: f.size,
          })),
          ...this.addFormData,
          start_time: this.addFormData.start_time.unix(),
          end_time: this.addFormData.end_time.unix(),
        })
        .then((res) => {
          this.progressPercent = -1;
          if (res.code === 0) {
            this.$emit("refreshStats");
            this.closeAddModal();
            this.$message.success("Add Success");
            this.currentPage = 1;
            this.getData();
          } else {
            this.$message.error(res.msg);
          }
        });
      // 显示成功
    },
    closeBatchEditModal() {
      this.isShowBatchAction = false;
      this.isShowBatchEdit = false;
      this.singleEditData = null;
      this.isShowSingleHandle = false;
      this.batchEditFormData = {
        start_time: null,
        end_time: null,
      };
    },
    closeAddModal() {
      this.needUpload = false;
      this.isShowAdd = false;
      this.fileList = [];
      this.addFormData = {
        title: "",
        category: "",
        privacy: "PUBLIC",
        site_ids: [],
        start_time: moment(),
        end_time: moment().add(1, "M"),
        type: "IMAGE",
        alternate: "",
        link: "",
      };
    },
    doUpload(file) {
      var forms = new FormData();
      forms.append("imgFile", file);
      return axios
        .post(uploadUrl, forms, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          console.log(res);
          return res.data.url || null;
        });
    },
    doBatchDelete(id) {
      let cids = [];
      if (id) {
        cids.push(id);
      } else {
        cids = this.checkedList.map((item) => item.id);
      }
      this.isOpLoading = true;
      axios
        .post("/api_admin.php?a=creative/batch_delete", {
          cids,
        })
        .then((res) => {
          this.isOpLoading = false;
          if (res.code === 0) {
            this.$emit("refreshStats");
            this.$message.success("Delete Success");
            this.progressPercent = -1;
            this.closeBatchEditModal();
            this.getData();
          }
        })
        .catch((e) => {
          this.isOpLoading = false;
        });
    },
    handleChangeDate(_, dates) {
      this.params.start_time = _[0]?.unix() || null;
      this.params.end_time = _[1] ? _[1].unix() + 86399 : null;
    },
  },
  filters: {
    formatSize: (size) => {
      if (!size) {
        return "";
      } else {
        return (size / 1024).toFixed(0) + "k";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  margin-bottom: 100px;

  .content-title {
    width: 100%;
    padding: 20px 44px 30px 30px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;

    .items {
      display: flex;

      .item {
        width: 262px;
        margin-right: 26px;

        &:last-child {
          margin-right: 0px;
        }

        span {
          display: block;
          font-size: 1rem;
          font-family: "dtc-bold";
          color: #333333;
          line-height: 30px;
          margin-bottom: 2px;
        }

        input {
          width: 100%;
          height: 2.8571rem;
          background: #ffffff;
          border-radius: 6px;
          border: 1px solid #e6e6e6;
        }

        .select {
          width: 100%;
          height: 2.8571rem;

          /deep/ .ant-select-selection--single {
            height: 2.8571rem;
            border-radius: 6px;
            background: #fafafa;
          }

          /deep/ .ant-select-selection__rendered {
            line-height: 2.8571rem;
          }

          .icon-down {
            color: #333333;
            font-size: 1rem;
          }
        }

        .date {
          width: 100%;
          height: 2.8571rem;

          /deep/ .ant-input {
            height: 2.8571rem;
            border-radius: 6px;
            background: #fafafa;
          }
        }
      }
    }

    .search-reset {
      margin-top: 20px;
      text-align: right;

      button {
        padding: 9px 32px;
        border-radius: 6px;
        cursor: pointer;
        border: none;

        &:first-child {
          background: #2898ff;
          font-size: 1.1429rem;
          color: #ffffff;
          line-height: 20px;
          margin-right: 20px;
        }

        &:last-child {
          border: 1px solid #dddddd;
          font-size: 1.1429rem;
          color: #333333;
          line-height: 20px;
        }
      }
    }
  }

  .content-list {
    width: 100%;
    padding: 80px 25px 20px 25px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    margin-top: 22px;
    position: relative;

    /deep/ .ant-spin-container {
      display: flex;
      flex-wrap: wrap;
      min-height: 300px;
      justify-content: flex-start;

      .item {
        height: 178px;
        width: 154px;
        border-radius: 8px;
        border: 1px solid #eeeeee;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        margin-top: 12px;
        position: relative;
        overflow: hidden;

        &.checked {
          border-color: rgba(40, 152, 255, 0.5);
        }

        &:hover {
          .checkbox,
          .icon-policy,
          .icon-view {
            display: block;
          }
        }

        .checkbox {
          cursor: pointer;
          width: 20px;
          height: 20px;
          background: #ffffff;
          border-radius: 3px;
          border: 1px solid #dddddd;
          position: absolute;
          right: 8px;
          top: 8px;
          user-select: none;
          display: none;

          img {
            display: none;
          }

          &.checked {
            display: block;
            border: none;

            img {
              width: 20px;
              height: 20px;
              display: block;
            }
          }
        }

        .icon-policy {
          display: none;
          width: 14px;
          height: 14px;
          position: absolute;
          left: 8px;
          bottom: 0px;
          user-select: none;
        }

        .icon-view {
          display: none;
          width: 18px;
          height: 18px;
          position: absolute;
          right: 8px;
          bottom: -3px;
          user-select: none;
          cursor: pointer;

          &.checked {
            right: -80px;
            filter: drop-shadow(#2898ff -88px 0);
          }
        }

        &:nth-of-type(-n + 7) {
          margin-top: 0;
        }

        &:nth-of-type(7n) {
          margin-right: 0;
        }

        img {
          height: 138px;
          width: 138px;
          object-fit: contain;
          margin-bottom: 8px;
        }

        .item-title {
          font-size: 0.8571rem;
          text-align: center;
          height: 16px;
          width: 100px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          &.Active {
            color: #19c42c;
          }

          &.scheduled {
            color: #ffd163;
          }

          &.expired {
            color: #999999;
          }
        }
      }
    }

    .addone {
      padding: 9px 32px;
      border-radius: 6px;
      cursor: pointer;
      border: none;
      background: #2898ff;
      font-size: 1.1429rem;
      color: #ffffff;
      line-height: 20px;
      position: absolute;
      top: -58px;
      left: 0px;
    }

    .batch-edit {
      padding: 8px 30px;
      border-radius: 6px;
      cursor: pointer;
      background: #fff;
      border: 1px solid #2898ff;
      font-size: 1.1429rem;
      color: #2898ff;
      line-height: 20px;
      position: absolute;
      top: -58px;
      right: 0px;
      box-sizing: border-box;
    }

    &.empty {
      /deep/ .ant-spin-container {
        justify-content: center;
        align-items: center;
      }
    }

    &::after {
      content: " ";
      clear: both;
    }

    .footer-pagination {
      margin-top: 22px;
      text-align: right;
      width: 100%;
    }
  }
}

.banner-view {
  /deep/ .items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      width: auto;
      max-height: 325px;
      max-width: 325px;
      width: auto;
      object-fit: contain;
      margin-bottom: 18px;
    }

    .item-info {
      font-size: 1rem;
      color: #333333;
      line-height: 18px;
      text-align: center;
    }
  }
}

.banner-batch-edit {
  /deep/ .items {
    .left {
      width: 340px;
      height: max-content;
      float: left;

      .name {
        height: 24px;
        font-size: 1.1429rem;
        font-family: "dtc-bold";
        color: #333333;
        line-height: 20px;
        margin-bottom: 12px;
        text-align: left;
        align-self: flex-start;
        margin-top: 20px;

        &:first-child {
          margin-top: 0;
        }
      }

      .item {
        margin-bottom: 10px;
        margin-top: 10px;

        &:first-child {
          margin-top: 0;
        }

        img {
          padding: 8px;
          box-sizing: border-box;
          border-radius: 8px;
          border: 1px solid #eeeeee;
          width: 340px;
          max-height: 340px;
          object-fit: contain;
        }

        .size {
          height: 20px;
          font-size: 1.1429rem;
          color: #999999;
          line-height: 20px;
          text-align: center;
        }

        .tips {
          height: 20px;
          font-size: 1.1429rem;
          color: #333333;
          line-height: 20px;
          text-align: center;
        }
      }
    }

    .right {
      width: 300px;
      padding-right: 20px;
      box-sizing: border-box;
      float: right;

      .title {
        height: 24px;
        font-size: 1.2857rem;
        font-family: "prefer-bold";
        font-family: "dtc-bold";
        color: #333333;
        line-height: 23px;
        margin-bottom: 22px;
      }

      .name {
        height: 24px;
        font-size: 1.1429rem;
        font-family: "dtc-bold";
        color: #333333;
        line-height: 20px;
        margin-bottom: 4px;
        margin-top: 8px;
      }

      .ant-calendar-picker {
        width: 280px;
        margin-bottom: 12px;
      }

      .tips {
        height: 18px;
        font-size: 1rem;
        font-weight: normal;
        color: #2898ff;
        line-height: 18px;
        margin-bottom: 32px;
        margin-top: 6px;
      }

      .options {
        display: flex;
        justify-content: flex-start;

        .delete {
          width: 133px;
          height: 2.8571rem;
          font-size: 1.1429rem;
          line-height: 34px;
          box-sizing: border-box;
          vertical-align: middle;
          border-radius: 6px;
          margin-right: 14px;
        }

        .update {
          width: 133px;
          height: 2.8571rem;
          cursor: pointer;
          background: #2898ff;
          border: 1px solid #2898ff;
          vertical-align: middle;
          font-size: 1.1429rem;
          color: #fff;
          line-height: 34px;
          box-sizing: border-box;
          border-radius: 6px;
        }
      }
    }

    &::after {
      display: block;
      content: " ";
      clear: both;
    }
  }
}

.banner-batch-delete {
  .text {
    width: 478px;
    height: 90px;
    font-size: 1.1429rem;
    color: #333333;
    line-height: 20px;
    text-align: center;
    margin-top: 10px;
  }
}

.banner-batch-add {
  /deep/ .items {
    .left {
      width: 340px;
      height: max-content;
      float: left;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .name {
        height: 24px;
        font-size: 1.1429rem;
        font-family: "dtc-bold";
        color: #333333;
        line-height: 20px;
        margin-bottom: 12px;
        text-align: left;
        align-self: flex-start;
        margin-top: 20px;

        &:first-child {
          margin-top: 0;
        }
      }

      .item {
        margin-top: 10px;

        &:first-child {
          margin-top: 0;
        }

        margin-bottom: 10px;
        position: relative;

        .banner-icon-view,
        .banner-icon-del {
          position: absolute;
          top: 8px;
          right: 8px;
          height: 25px;
          width: 25px;
          cursor: pointer;
        }

        .banner-icon-view {
          right: 28px;
        }

        .preview {
          padding: 8px;
          box-sizing: border-box;
          border-radius: 8px;
          border: 1px solid #eeeeee;
          width: 340px;
          max-height: 340px;
          height: auto;
          object-fit: contain;
        }
      }

      .size {
        height: 20px;
        font-size: 0.8571rem;
        color: #999999;
        line-height: 20px;
        text-align: center;
      }

      .upload-small {
        width: 162px;
        height: 2.8571rem;
        margin: 10px auto;
        cursor: pointer;
        background: #2898ff;
        border: 1px solid #2898ff;
        vertical-align: middle;
        font-size: 1.1429rem;
        color: #fff;
        line-height: 34px;
        box-sizing: border-box;
        border-radius: 6px;
        text-align: center;
      }

      .upload-big {
        width: 340px;
        height: 204px;
        border-radius: 8px;
        border: 1px solid #eeeeee;
        margin-bottom: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #333;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          border-color: #ccc;

          .upload-big-img {
            &:after {
              background: #c8c8c8;
            }

            &:before {
              background: #c8c8c8;
            }
          }
        }

        > span {
          position: relative;
          top: 2.8571rem;

          &:last-child {
            display: none;
          }
        }

        &.error {
          border-color: #fe5a58;

          > span {
            display: none;

            &:last-child {
              display: block;
              position: relative;
              top: 19px;
              color: #fe5a58;
            }
          }
        }

        .upload-big-img {
          height: 58px;
          width: 58px;
          position: relative;
          transition: all 0.2s ease;

          &:after {
            transition: all 0.2s ease;
            content: "";
            height: 5px;
            width: 58px;
            background: #d8d8d8;
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -2.5px;
            border-radius: 4px;
          }

          &:before {
            transition: all 0.2s ease;
            content: "";
            height: 58px;
            width: 5px;
            background: #d8d8d8;
            display: block;
            position: absolute;
            left: 50%;
            top: 0;
            margin-left: -2.5px;
            border-radius: 4px;
          }
        }
      }
    }

    .right {
      width: 300px;
      padding-right: 20px;
      box-sizing: border-box;
      float: right;

      .title {
        height: 24px;
        font-size: 1.2857rem;
        font-family: "prefer-bold";
        font-family: "dtc-bold";
        color: #333333;
        line-height: 23px;
        margin-bottom: 22px;
      }

      .name {
        height: 24px;
        font-size: 1.1429rem;
        font-family: "dtc-bold";
        color: #333333;
        line-height: 20px;
        margin-bottom: 4px;
        margin-top: 8px;

        &.desc {
          cursor: default;
          position: relative;

          > span:first-child {
            display: inline-block;
            height: 20px;
            width: 20px;
            transform: scale(0.6);
            font-size: 1rem;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 400;
            background-color: #2898ff;
            border-radius: 999px;
            text-align: center;
            line-height: 20px;
            vertical-align: middle;
            color: #fff;

            &:hover + span > span {
              display: block;
            }
          }

          > span:last-child {
            position: relative;
            height: 0px;
            width: 0px;
            display: inline-block;

            span {
              font-family: dtc;
              color: #2898ff;
              background-color: rgba(62, 61, 64, 0.9);
              position: absolute;
              left: -2.8571rem;
              top: 13px;
              padding: 8px;
              width: 200px;
              box-sizing: border-box;
              border-radius: 4px;
              color: #fff;
              font-size: 0.8571rem;
              line-height: 15px;
              z-index: 1;
              font-weight: 400;
              height: max-content;
              display: none;

              &::before {
                display: block;
                content: " ";
                border: 5px solid rgba(62, 61, 64, 0.9);
                border-top-color: rgba(0, 0, 0, 0);
                border-right-color: rgba(0, 0, 0, 0);
                height: 10px;
                box-sizing: border-box;
                width: 10px;
                border-bottom-left-radius: 1px;
                position: absolute;
                top: -5px;
                left: 25px;
                transform: rotate(135deg);
              }
            }
          }
        }
      }

      .ant-calendar-picker {
        width: 280px;
        margin-bottom: 12px;
      }

      > .ant-input,
      .ant-select {
        width: 280px;
        margin-bottom: 12px;
      }

      .tips {
        height: 18px;
        font-size: 1rem;
        font-weight: normal;
        color: #2898ff;
        line-height: 18px;
        margin-bottom: 32px;
        margin-top: 6px;
      }

      .options {
        display: flex;
        justify-content: flex-end;

        .add {
          width: 133px;
          height: 2.8571rem;
          cursor: pointer;
          background: #2898ff;
          border: 1px solid #2898ff;
          vertical-align: middle;
          font-size: 1.1429rem;
          color: #fff;
          line-height: 34px;
          box-sizing: border-box;
          border-radius: 6px;
        }
      }
    }

    &::after {
      display: block;
      content: " ";
      clear: both;
    }
  }
}

.banner-single-edit {
  /deep/ .items {
    .left {
      width: 340px;
      height: max-content;
      float: left;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .name {
        height: 24px;
        font-size: 1.1429rem;
        font-family: "dtc-bold";
        color: #333333;
        line-height: 20px;
        margin-bottom: 12px;
        text-align: left;
        align-self: flex-start;
        margin-top: 20px;

        &:first-child {
          margin-top: 0;
        }
      }

      .item {
        margin-bottom: 20px;
        position: relative;

        .banner-icon-view,
        .banner-icon-del {
          position: absolute;
          top: 8px;
          right: 8px;
          height: 25px;
          width: 25px;
          cursor: pointer;
        }

        .preview {
          padding: 8px;
          box-sizing: border-box;
          border-radius: 8px;
          border: 1px solid #eeeeee;
          width: 340px;
          max-height: 340px;
          height: auto;
          object-fit: contain;
        }
      }

      .size {
        height: 20px;
        font-size: 0.8571rem;
        color: #999999;
        line-height: 20px;
        text-align: center;
      }

      .upload-small {
        width: 162px;
        height: 2.8571rem;
        margin: 10px auto;
        cursor: pointer;
        background: #2898ff;
        border: 1px solid #2898ff;
        vertical-align: middle;
        font-size: 1.1429rem;
        color: #fff;
        line-height: 34px;
        box-sizing: border-box;
        border-radius: 6px;
        text-align: center;
      }

      .upload-big {
        width: 340px;
        height: 204px;
        border-radius: 8px;
        border: 1px solid #eeeeee;
        margin-bottom: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #333;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          border-color: #ccc;

          .upload-big-img {
            &:after {
              background: #c8c8c8;
            }

            &:before {
              background: #c8c8c8;
            }
          }
        }

        > span {
          position: relative;
          top: 2.8571rem;
        }

        .upload-big-img {
          height: 58px;
          width: 58px;
          position: relative;
          transition: all 0.2s ease;

          &:after {
            transition: all 0.2s ease;
            content: "";
            height: 5px;
            width: 58px;
            background: #d8d8d8;
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -2.5px;
            border-radius: 4px;
          }

          &:before {
            transition: all 0.2s ease;
            content: "";
            height: 58px;
            width: 5px;
            background: #d8d8d8;
            display: block;
            position: absolute;
            left: 50%;
            top: 0;
            margin-left: -2.5px;
            border-radius: 4px;
          }
        }
      }
    }

    .right {
      width: 300px;
      padding-right: 20px;
      box-sizing: border-box;
      float: right;

      .title {
        height: 24px;
        font-size: 1.2857rem;
        font-family: "prefer-bold";
        font-family: "dtc-bold";
        color: #333333;
        line-height: 23px;
        margin-bottom: 22px;
      }

      .name {
        height: 24px;
        font-size: 1.1429rem;
        font-family: "dtc-bold";
        color: #333333;
        line-height: 20px;
        margin-bottom: 4px;
        margin-top: 8px;

        &.desc {
          cursor: default;
          position: relative;

          > span:first-child {
            display: inline-block;
            height: 20px;
            width: 20px;
            transform: scale(0.6);
            font-size: 1rem;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 400;
            background-color: #2898ff;
            border-radius: 999px;
            text-align: center;
            line-height: 20px;
            vertical-align: middle;
            color: #fff;

            &:hover + span > span {
              display: block;
            }
          }

          > span:last-child {
            position: relative;
            height: 0px;
            width: 0px;
            display: inline-block;

            span {
              font-family: dtc;
              color: #2898ff;
              background-color: rgba(62, 61, 64, 0.9);
              position: absolute;
              left: -2.8571rem;
              top: 13px;
              padding: 8px;
              width: 200px;
              box-sizing: border-box;
              border-radius: 4px;
              color: #fff;
              font-size: 0.8571rem;
              line-height: 15px;
              z-index: 1;
              font-weight: 400;
              height: max-content;
              display: none;

              &::before {
                display: block;
                content: " ";
                border: 5px solid rgba(62, 61, 64, 0.9);
                border-top-color: rgba(0, 0, 0, 0);
                border-right-color: rgba(0, 0, 0, 0);
                height: 10px;
                box-sizing: border-box;
                width: 10px;
                border-bottom-left-radius: 1px;
                position: absolute;
                top: -5px;
                left: 25px;
                transform: rotate(135deg);
              }
            }
          }
        }
      }

      .ant-calendar-picker {
        width: 280px;
        margin-bottom: 12px;
      }

      > .ant-input,
      .ant-select {
        width: 280px;
        margin-bottom: 12px;
      }

      .tips {
        height: 18px;
        font-size: 1rem;
        font-weight: normal;
        color: #2898ff;
        line-height: 18px;
        margin-bottom: 32px;
        margin-top: 6px;
      }

      .options {
        display: flex;
        justify-content: flex-start;

        .delete {
          width: 133px;
          height: 2.8571rem;
          font-size: 1.1429rem;
          line-height: 34px;
          box-sizing: border-box;
          vertical-align: middle;
          border-radius: 6px;
          margin-right: 14px;
        }

        .update {
          width: 133px;
          height: 2.8571rem;
          cursor: pointer;
          background: #2898ff;
          border: 1px solid #2898ff;
          vertical-align: middle;
          font-size: 1.1429rem;
          color: #fff;
          line-height: 34px;
          box-sizing: border-box;
          border-radius: 6px;
        }
      }
    }

    &::after {
      display: block;
      content: " ";
      clear: both;
    }
  }
}
</style>
