<template>
  <Modal
    :isVisible="showUpload"
    :isShowCancel="false"
    title="Upload Tax Info"
    @close-popup="onClose"
    width="713px"
    submitText="Add"
    @submit="onSubmit"
  >
    <template #content>
      <div class="addbank-upload">
        <ul class="upload-list">
          <li class="upload-item" v-if="isShowType">
            <span>Tax form</span>
            <div class="form-list">
              <a-radio-group v-model="tax_from_type">
                <a-radio :value="'1'">
                  W9
                </a-radio>
                <a-radio :value="'2'">
                  W8-BEN
                </a-radio>
                <a-radio :value="'3'">
                  W8-BEN-E
                </a-radio>
              </a-radio-group>
            </div>
          </li>
          <li class="upload-item">
            <span>Tax No.</span>
            <a-input v-model="tax_number"></a-input>
          </li>
          <li class="upload-item">
            <span>Upload Form</span>
            <a-spin :spinning="upLoading">
              <a-upload
                class="custom-upload"
                name="filename"
                :action="uploadUrl"
                :headers="headers"
                :data="{ type: 'common' }"
                accept=".pdf,.txt,.doc,.docx,.jpg,.png,.jpeg"
                @change="(info) => handleChangeUpload(info, 'file')"
              >
                <a-button> Upload </a-button>
              </a-upload>
            </a-spin>
          </li>
          <li class="upload-item">
            <span>File Uploaded</span>
            <div>{{ name || "-" }}</div>
          </li>
        </ul>
        <div class="upload-tmpl">
          <span>Tax Filing Template</span>
          <div class="tmpl-list">
            <a target="_black" href="/W9.pdf">W9</a>
            <a target="_black" href="/W-8BEN.pdf">W8-BEN</a>
            <a target="_black" href="/W-8BEN-E.pdf">W8-BEN-E</a>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import { uploadUrl } from "@/utils/config";
import axios from "@/utils/request";

export default {
  props: {
    showUpload: {
      type: Boolean,
      default: false,
    },
    init: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    Modal,
  },
  watch: {
    init: function(nv) {
      this.tax_from_type = nv.file_type;
      this.tax_number = nv.tax_number;
      this.file = nv.tax_file;
      this.name = nv?.tax_file.split("/")[nv?.tax_file.split("/").length - 1];
    },
  },
  data() {
    return {
      uploadUrl,
      headers: {
        "Lp-Authorization": this.$store.state.token,
      },
      upLoading: false,
      tax_from_type: null, //税务文件类型
      tax_number: null, //说明：税号
      name: "", // 文件名
      file: null, //  文件路径
      isShowType: false,
    };
  },
  methods: {
    onClose() {
      this.$emit("onClose");
    },
    onSubmit() {
      if (this.upLoading) {
        this.$message.error("File is loading");
        return;
      }
      const data = {
        tax_from_type: this.tax_from_type,
        tax_number: this.tax_number,
        file: this.file,
      };
      console.log(data);

      if (!this.tax_number) {
        this.$message.error("Please input Tax No.");
        return;
      }
      if (!this.file) {
        this.$message.error("Please upload a file");
        return;
      }
      if (!this.tax_from_type) {
        this.$message.error("Tax type is not empty, please select a tax type");
        return;
      }
      this.$emit("onSubmit", data);
      this.$emit("onClose");
    },
    checkPdf() {
      if (!this.file) {
        this.$message.error("File is not empty");
        return;
      }
      const data = this.formatFormData({
        url: this.file,
      });
      axios
        .post("/m/user/c/index/a/doCheckPdf", data)
        .then((res) => {
          this.upLoading = false;
          if (res.code === "0200") {
            this.tax_from_type = res.payload?.file_type;
            if (!this.tax_from_type) {
              this.isShowType = true;
            }
          } else {
            this.isShowType = true;
            this.$message.error(res.msg || "");
          }
        })
        .catch(() => {
          this.isShowType = true;
          this.upLoading = false;
        });
    },
    handleChangeUpload(info, key) {
      if (info.file.status === "uploading") {
        this.upLoading = true;
        return;
      }
      if (info.file.status === "done") {
        if (info.file.response.code === "0200") {
          let result = info.file.response.payload;
          let url = result.prefix + result.src;
          this.name = result.name;
          this.file = url;
          this.checkPdf();
        } else {
          this.$message.error(info.file.response.msg);
          this.upLoading = false;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.addbank {
  &-upload {
    font-size: 1.1429rem;
    .upload-item {
      display: flex;
      height: 56px;
      align-items: center;
      > span {
        width: 8.5714rem;
        flex-shrink: 0;
      }
      .ant-btn {
        width: 529px;
        height: 2.8571rem;
      }
    }
    .upload-tmpl {
      display: flex;
      position: absolute;
      bottom: 3px;
      .tmpl-list {
        margin-left: 30px;
        width: 300px;
        display: flex;
        justify-content: space-around;
        > a {
          color: #3762d6;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .upload {
    padding-left: 12px;
    display: flex;
    &-label {
      width: 22.0714rem;
      color: #999999;
      &::before {
        display: inline-block;
        margin-right: 4px;
        color: #f5222d;
        font-size: 1rem;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: "*";
      }
    }
    &-right {
      display: flex;
      &-first {
        margin-right: 32px;
      }
    }
  }
}
.left-label {
  width: 50%;
  height: 1px;
}

/deep/ .custom-upload {
  .ant-upload-list {
    display: none;
  }
}
</style>
