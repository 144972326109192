<template>
  <div class="signup-select" :class="{ error, disabled }">
    <input
      type="text"
      v-model="displayValue"
      ref="input"
      @focus="showDropdown"
    />
    <div class="signup-select-title" v-if="title && title.length > 0">
      {{ title }}
    </div>
    <div class="signup-select-error" v-show="error">
      {{ errorMsg }}
    </div>
    <div class="dropdown-list" :class="{ show }">
      <div class="dropdown-list-scroll">
        <div
          class="dropdown-item"
          v-for="item in optionsFilter"
          @click="handleItemClick(item.label)"
          :key="item.value"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    value: {
      type: String | Number,
      default: "",
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMsg: {
      type: String,
      default: "Please complete this.",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      displayValue: "",
      show: false,
    };
  },
  watch: {
    value: {
      handler(newValue) {
        let label = "";
        this.options.forEach((item) => {
          if (item.value == newValue) {
            label = item.label;
          }
        });
        this.displayValue = label;
      },
      immediate: true,
    },
  },
  computed: {
    optionsFilter() {
      return this.options.filter((item) => {
        if (this.displayValue.trim().length > 0) {
          return (
            item.label
              .toLowerCase()
              .indexOf(this.displayValue.trim().toLowerCase()) > -1
          );
        } else {
          return true;
        }
      });
    },
  },
  methods: {
    handleItemClick(label) {
      this.displayValue = label;
      let val = null;
      this.options.forEach((item) => {
        if (item.label === label) {
          val = item.value;
        }
      });
      this.$emit("input", val);
      this.$emit("change", val);
      this.show = false;
    },
    showDropdown() {
      this.show = true;
      window.addEventListener("click", this.handleWindowClick);
    },
    handleWindowClick(e) {
      if (this.$el.contains(e.target)) {
        if (e.target.classList.contains("dropdown-item")) {
          this.show = false;
          window.removeEventListener("click", this.handleWindowClick);
        }
        return;
      }
      this.show = false;
      window.removeEventListener("click", this.handleWindowClick);
      this.handleItemClick(this.displayValue);
    },
  },
};
</script>
<style lang="less" scoped>
.signup-select {
  position: relative;
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  padding-top: 12px;

  .signup-select-title {
    position: absolute;
    top: 0;
    left: 0;
    width: max-content;
    height: 24px;
    font-family: HarmonyOS_Sans_Medium;
    line-height: 24px;
    font-size: 0.8571rem;
    color: #828282;
    text-align: left;
    background: linear-gradient(
      180deg,
      #ffffff 0%,
      #ffffff 14px,
      #ffffff00 14px
    );
    margin-left: 16px;
    padding: 0 5px;
    user-select: none;
    transition: all 0.3s ease;
  }

  input {
    width: 100%;
    cursor: pointer;
    height: 44px;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    padding: 0 20px;
    color: #333;
    font-family: HarmonyOS_Sans_Medium;
    font-size: 1rem;
    transition: all 0.3s ease;

    &:focus {
      outline: none;
      border: 1px solid #2898ff;

      & ~ .signup-select-title {
        color: #2898ff;
      }
    }

    &:-internal-autofill-previewed,
    &:-internal-autofill-selected {
      -webkit-text-fill-color: #ffffff !important;
      transition: background-color 5000s ease-in-out 0s !important;
    }
  }

  &.error {
    input {
      border: 1px solid #ee0a24;
      background: rgba(238, 10, 36, 0.05);
      padding-right: 50px;
    }

    &::after {
      position: absolute;
      bottom: 15px;
      right: 20px;
      width: 18px;
      height: 18px;
      background: url("~@/assets/input-error.svg") center/contain no-repeat;
      content: " ";
      display: block;
    }

    .signup-select-title {
      color: #ee0a24 !important;
    }
  }

  .signup-select-error {
    position: absolute;
    top: 56px;
    left: 0;
    width: 100%;
    height: 16px;
    font-family: HarmonyOS_Sans_Medium;
    line-height: 16px;
    font-size: 0.8571rem;
    color: #ee0a24;
    padding: 0 20px;
  }

  .dropdown-list {
    width: 100%;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    display: none;
    height: 0;
    z-index: 1;
    position: absolute;
    top: 61px;
    left: 0;
    overflow: hidden;

    .dropdown-list-scroll {
      overflow-y: scroll;
      overflow-x: clip;
      padding-right: 20px;
      height: 162px;
      width: calc(100% - 5px);
      margin-top: 5px;

      &::-webkit-scrollbar {
        position: relative;
        width: 6px;
        background-color: #f2f9ff;
      }

      &::-webkit-scrollbar-thumb {
        background: #2896ff80;
        border-radius: 6px;
      }
    }

    &.show {
      display: block;
      height: 174px;
    }

    .dropdown-item {
      width: calc(100% + 20px);
      height: 30px;
      line-height: 30px;
      font-family: HarmonyOS_Sans_Medium;
      font-size: 1rem;
      color: #333;
      text-align: left;
      padding-left: 20px;
      cursor: pointer;
      user-select: none;
      transition: all 0.3s ease;
      margin: 5px 0;

      &:hover {
        background: #f2f9ff;
      }
    }
  }
}
</style>
